import React from 'react';
import Dialog from '@manakin/core/Dialog';
import MuiDialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { showSnackbarMessage } from '@manakin/core/actions';
import { connect } from 'react-redux';
import { graphql } from 'react-apollo';
import { getGroup } from '@manakin/app-core/GroupsDropdown/selectors';
import {
  GQL_FETCH_SCHOOL_CLASS,
  GQL_UPDATE_SCHOOLCLASS,
  GQL_UPDATE_SCHOOL
} from '../graphql';
import { compose, withHandlers } from 'recompose';
import { withForm } from '@manakin/core';
import { MultiSelect, SelectField, TextField } from '@manakin/core';
import { isInArray } from '@manakin/app-core/lib';

const form = {};

class UpdateDialog extends React.PureComponent {
  state = {
    teachers: [],
    students: [],
    _loading: true
  };

  componentDidUpdate(prevProps) {
    if (prevProps.data.loading && !this.props.data.loading) {
      const { data } = this.props;
      const { schoolClass = {} } = data;
      const { schools = [] } = schoolClass

      let arr = []
      let newStudents = [];
      let students = [];
      let teachers = [];
      let managers = [];
      let newProducts = [];

      schools &&
        schools.forEach(school => {
          if (school.classes && school.classes.length) {
            school.classes.forEach(sc => {
              sc.students && sc.students.forEach(student => {
                if (school.students.some(i => i.id === student.id)) {
                  arr.push({ id: student.id })
                }
              })
            })
          }

          students = [...students, ...school.students];
          managers = [...managers, ...school.managers];
          newProducts = [...newProducts, ...school.products];
        });


      students.forEach(s => {
        if (!arr.some(i => i.id == s.id)) {
          newStudents.push(s)
        }
      })

      managers.forEach(manager => {
        manager.roles.forEach(i => {
          if (i.name == 'TEACHER') teachers.push(manager);
        });
      });

      this.setState({
        students: [...newStudents],
        teachers: [...teachers],
        products: [...newProducts],
        _loading: false
      });
    }
  }

  handleSubmit = onClose => event => {
    const { form, onCreate, group } = this.props;
    const { onSubmit } = form;

    form.onValidate().then(() =>
      onSubmit().then(formData => {
        onCreate({ ...formData, id: group }).then(() => {
          onClose();
          setTimeout(() => {
            location.reload();
          }, 500);
        });
      })
    );
  };

  render() {
    const { form, data, loading: load } = this.props;
    const { schoolClass = {} } = data;
    const { students, teachers, products, _loading } = this.state;

    const _schoolClass =
      schoolClass.students &&
      schoolClass.students.map(item => ({
        id: item.id,
        name: item.fullName
      }));

    const _schoolClassTeachers =
      schoolClass.teachers &&
      schoolClass.teachers.map(item => ({
        id: item.id,
        name: item.fullName
      }));

    const _product = schoolClass.product && {
      id: schoolClass.product.id,
      name: schoolClass.product.name
    };
    const loading = _loading && load;

    if (loading) {
      return 'loading';
    }
    return (
      <Dialog
        name="appClassUpdateDialog"
        render={({ open, onClose }) => (
          <MuiDialog open={open} onClose={onClose} fullWidth>
            <DialogTitle>Klas aanpassen</DialogTitle>
            <DialogContent>
              <TextField
                name="name"
                label="Naam"
                placeholder="Naam van de klas"
                form={form}
                initialValue={schoolClass.name || ''}
              />
              <MultiSelect
                options={students}
                label="Studenten toevoegen"
                placeholder={'Kies studenten'}
                form={form}
                name="students"
                initialValue={_schoolClass}
              />
              <MultiSelect
                options={teachers}
                label="Docenten toevoegen"
                placeholder={'Kies docenten'}
                form={form}
                name="teachers"
                initialValue={_schoolClassTeachers}
              />
              <SelectField
                options={products}
                label="Product"
                placeholder={'Kies een products'}
                form={form}
                name="product"
                initialValue={_product}
                noLoading={true}
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={this.handleSubmit(onClose)}
                color="primary"
                variant="contained"
              >
                Aanpassen
              </Button>
              <Button onClick={onClose} color="primary">
                Annuleren
              </Button>
            </DialogActions>
          </MuiDialog>
        )}
      />
    );
  }
}

export default compose(
  withForm(form),
  connect(
    state => ({
      group: getGroup(state)
    }),
    dispatch => ({
      processSuccess: result => {
        dispatch(
          showSnackbarMessage({
            text: 'Klas aangepast.',
            variant: 'success'
          })
        );
      },
      processFailure: () => {
        dispatch(
          showSnackbarMessage({
            text: 'Klas aanpassen mislukt',
            variant: 'error'
          })
        );
      }
    })
  ),
  graphql(GQL_UPDATE_SCHOOLCLASS, { name: 'createSchoolClass' }),
  graphql(GQL_UPDATE_SCHOOL, { name: 'updateSchool' }),
  graphql(GQL_FETCH_SCHOOL_CLASS, {
    options: props => ({
      fetchPolicy: "no-cache",
      variables: {
        id: props.group
      }
    })
  }),
  withHandlers({
    onCreate: ({
      createSchoolClass,
      processSuccess,
      processFailure
    }) => event => {
      return createSchoolClass({
        variables: {
          ...event
        }
      }).then(result => {
        if (!result.errors) {
          processSuccess(result);
        } else {
          processFailure();
        }
      });
    }
  })
)(UpdateDialog);
