import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import { graphql } from "react-apollo";
import { compose } from "recompose";
import { TransitionRouter, withWorkforms, Loader } from "@manakin/app-core";
import { GQL_FETCH_MASTERCLASS, GQL_FETCH_GLOBAL_SETTINGS } from "./graphql";
import { Route, withRouter } from "react-router-dom";
import { DefaultFrontPage } from "../DefaultPages";
import Questions from "./Questions";

const MASTERCLASS_ROOT_PATH = "MasterClass";

const styles = theme => ({});

const Masterclass = props => {
  const { data, workformsData, classes } = props;
  const { loading = true, masterClass = {} } = data;
  const { rawData = {} } = workformsData;

  //State hooks
  const [factCards, setFactCards] = useState([]);

  //Effect hooks
  useEffect(() => {
    if (!loading) {
      window.scrollTo(0, 0);
      const options = {
        showFeedback: true,
        nextButton: true
      };

      if (masterClass.workforms) {
        workformsData.loadWorkforms({
          ...masterClass,
          options: options,
          elementId: props.match.params.elementId
        });
        workformsData.loadElementResult(props.match.params.elementId);
      }
    }
  }, [props.data.loading]);

  useEffect(() => {
    if (!workformsData.loading) {
      if (
        workformsData.rawData &&
        workformsData.rawData.factCards &&
        workformsData.rawData.factCards.length
      ) {
        setFactCards([...workformsData.rawData.factCards]);
      }
    }
  }, [workformsData]);

  //functions
  const handleClick = () => {
    props.history.push(`${props.match.url}/questions`);
  };

  const handleFinish = () => {
    workformsData
      .saveElement({
        elementId: props.match.params.elementId,
        finished: true,
        correct: true
      })
      .then(() => {
        props.history.push(`${props.match.url}/outro`);
      });
  };

  if (loading || workformsData.loading) {
    return (
      <div className={classes.loading}>
        <Loader fullScreen={true} />
      </div>
    );
  }
  return (
    <React.Fragment>
      <TransitionRouter>
        <Route
          exact
          path={`/${MASTERCLASS_ROOT_PATH}/:elementId`}
          render={() => (
            <DefaultFrontPage
              playButton={true}
              {...masterClass}
              factCards={factCards}
              onClick={handleClick}
            />
          )}
        />
        <Route
          exact
          path={`/${MASTERCLASS_ROOT_PATH}/:elementId/questions`}
          render={() => (
            <Questions
              {...workformsData}
              onFinish={handleFinish}
              location={props.location}
              background={rawData.borderColor || ""}
            />
          )}
        />
        <Route
          exact
          path={`/${MASTERCLASS_ROOT_PATH}/:elementId/outro`}
          render={() => (
            <DefaultFrontPage
              buttonText="Opnieuw afspelen"
              {...masterClass}
              factCards={factCards}
              onClick={handleClick}
            />
          )}
        />
      </TransitionRouter>
    </React.Fragment>
  );
};

export default compose(
  graphql(GQL_FETCH_MASTERCLASS, {
    options: props => ({ variables: { id: props.match.params.elementId } })
  }),
  graphql(GQL_FETCH_GLOBAL_SETTINGS, {
    name: "SETTINGS"
  }),
  withRouter,
  withWorkforms(),
  withStyles(styles)
)(Masterclass);
