import React from "react";
import LessonView from "./LessonView";
import { GQL_FETCH_LESSON, GQL_FETCH_CMS_BOX } from "./graphql";
import { graphql } from "react-apollo";
import { compose } from "recompose";
import { ElementOverlay, withWorkforms } from "@manakin/app-core";
import { getBoxId } from "@manakin/app-core/Boxes/selectors";
import { connect } from "react-redux";

const mapStateToProps = state => ({
  config: state.config,
  boxId: getBoxId(state)
});

class LessonContainer extends React.PureComponent {
  state = {};

  componentDidMount() {
    window.addEventListener('scroll', this.listenToScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.listenToScroll)
  }

  listenToScroll = () => {
    const winScroll =
      window.pageYOffset || document.documentElement.scrollTop

    const scrolled = winScroll

    this.setState({
      theposition: scrolled,
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.workformsData && prevProps.workformsData.loading && !this.props.workformsData.loading) {
      const { location = {} } = this.props;
      if (location.state && location.state.idx) {
        setTimeout(() => {
          window.scrollTo(0, location.state.idx);
        }, 1000)
      }
    }
    if (prevProps.data.loading && !this.props.data.loading) {
      const { data, workformsData } = this.props;

      const options = {
        showFeedback: true,
        nextButton: false
      };

      if (data.lesson) {
        data.lesson.workforms &&
          workformsData.loadWorkforms({
            ...data.lesson,
            options: options,
            elementId: this.props.match.params.elementId
          });
      }
    }
  }

  handleFinish = data => {
    const { config } = this.props;
    this.props.workformsData.saveWorkform({
      ...data,
      elementId: this.props.match.params.elementId,
      config: config,
      element: "Lesson"
    });
  };

  handleReset = id => {
    this.props.workformsData.resetElement(id);
  };

  render() {
    const { workformsData, data, CMSBOX, config = {} } = this.props;
    const { literals = {} } = config;
    const { theposition } = this.state;
    const { box = {} } = CMSBOX;
    const { lesson = {} } = data;
    const { generalSettings = {} } = workformsData;

    const showTitle =
      config.pages && config.pages.lesson
        ? config.pages.lesson.showNavTitle == undefined
          ? true
          : config.pages.lesson.showNavTitle
        : true;
    const title = showTitle
      ? lesson.preTitle || generalSettings.lessonPrefix || literals.APP_LESSON || "Les"
      : "";

    return (
      <ElementOverlay fullWidth={true} title={title}>
        <LessonView
          {...workformsData}
          lessonData={lesson}
          onFinish={this.handleFinish}
          box={box}
          onReset={this.handleReset}
          preview={false}
          title={title}
          theposition={theposition}
        />
      </ElementOverlay>
    );
  }
}

export default compose(
  connect(mapStateToProps),
  graphql(GQL_FETCH_LESSON, {
    options: props => ({ variables: { id: props.match.params.elementId } })
  }),
  graphql(GQL_FETCH_CMS_BOX, {
    name: "CMSBOX",
    options: props => ({ variables: { id: props.boxId } })
  }),
  withWorkforms()
)(LessonContainer);
