import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarMessage from './SnackbarMessage';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { getCurrent, isOpen } from './selectors';
import { processSnackbarMessage, closeSnackbar } from './actions';
import { compose } from 'recompose';

const styles = theme => ({
  root: {},
  close: {}
});

const mapStateToProps = state => ({
  message: getCurrent(state),
  open: isOpen(state)
});

const mapDispatchToProps = dispatch => ({
  onProcess: () => dispatch(processSnackbarMessage()),
  onClose: () => dispatch(closeSnackbar())
});

class SnackbarMessenger extends React.Component {
  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.props.onClose();
  };

  handleExited = () => {
    this.props.onProcess();
  };

  render() {
    const { classes, message, open } = this.props;

    return (
      <Snackbar
        classes={{ root: classes.root }}
        key={message.key}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        open={open}
        autoHideDuration={6000}
        onClose={this.handleClose}
        onExited={this.handleExited}
      >
        <SnackbarMessage onClose={this.handleClose} message={message} />
      </Snackbar>
    );
  }
}

export default compose(
  withStyles(styles, { name: 'CoreSnackbarMessenger' }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(SnackbarMessenger);
