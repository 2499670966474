'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toggleBoxesView = toggleBoxesView;
exports.setBoxId = setBoxId;
var TOGGLE_BOXES_VIEW = exports.TOGGLE_BOXES_VIEW = '@manakin/app-core/TOGGLE_BOXES_VIEW';
var SET_BOX_ID = exports.SET_BOX_ID = '@manakin/app-core/SET_BOX_ID';

function toggleBoxesView() {
  return {
    type: TOGGLE_BOXES_VIEW,
    payload: {}
  };
}

function setBoxId(id) {
  return {
    type: SET_BOX_ID,
    payload: { id: id }
  };
}