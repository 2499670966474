import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { graphql } from 'react-apollo';
import { compose } from "recompose";
import { TransitionRouter, withWorkforms, Loader } from '@manakin/app-core';
import { GQL_FETCH_FILM, GQL_FETCH_GLOBAL_SETTINGS } from './graphql';
import { Route, withRouter } from 'react-router-dom';
import { DefaultFrontPage } from '../DefaultPages';
import Questions from './Questions';

const FILM_ROOT_PATH = 'Film';

const styles = theme => ({});

const FilmContainer = props => {
  const { data, workformsData, classes } = props;
  const { loading = true, film = {} } = data;

  const [factCards, setFactCards] = useState([]);
  //Effect hooks
  useEffect(() => {
    if (!loading) {
      window.scrollTo(0, 0);
      const options = {
        showFeedback: true,
        nextButton: true
      };
      if (film.workforms) {
        workformsData.loadWorkforms({
          ...film,
          options: options,
          elementId: props.match.params.elementId
        });
        workformsData.loadElementResult(props.match.params.elementId);
      }
    }
  }, [props.data.loading]);

  useEffect(() => {
    if (!workformsData.loading) {
      if (
        workformsData.rawData &&
        workformsData.rawData.factCards &&
        workformsData.rawData.factCards.length
      ) {
        setFactCards([...workformsData.rawData.factCards]);
      }
    }
  }, [workformsData]);

  //functions
  const handleClick = () => {
    props.history.push(`${props.match.url}/questions`);
  };

  const handleFinish = workforms => {
    workformsData
      .saveElement({
        elementId: props.match.params.elementId,
        finished: true,
        correct: true
      })
      .then(() => {
        props.history.push(`${props.match.url}/outro`);
      });
  };

  if (loading || workformsData.loading) {
    return (
      <div className={classes.loading}>
        <Loader fullScreen={true} />
      </div>
    );
  }
  return (
    <React.Fragment>
      <TransitionRouter>
        <Route
          exact
          path={`/${FILM_ROOT_PATH}/:elementId`}
          render={() => (
            <DefaultFrontPage
              playButton={true}
              {...film}
              factCards={factCards}
              onClick={handleClick}
            />
          )}
        />
        <Route
          exact
          path={`/${FILM_ROOT_PATH}/:elementId/questions`}
          render={() => (
            <Questions {...workformsData} onFinish={handleFinish} />
          )}
        />
        <Route
          exact
          path={`/${FILM_ROOT_PATH}/:elementId/outro`}
          render={() => (
            <DefaultFrontPage
              buttonText="Opnieuw afspelen"
              {...film}
              factCards={factCards}
              onClick={handleClick}
              scenes={workformsData.workforms}
            />
          )}
        />
      </TransitionRouter>
    </React.Fragment>
  );
};

export default compose(
  graphql(GQL_FETCH_FILM, {
    options: props => ({ variables: { id: props.match.params.elementId } })
  }),
  graphql(GQL_FETCH_GLOBAL_SETTINGS, {
    name: 'SETTINGS'
  }),
  withRouter,
  withWorkforms(),
  withStyles(styles)
)(FilmContainer);
