export const styles = theme => ({
	root: {
		width: '100%',
	},
	statistics: {
		textAlign: "center",
		marginBottom: '3rem',
		[theme.breakpoints.up("md")]: {
			display: 'flex',
			width: '100%',
			border: `1px solid ${theme.manakin.defaultBorderColor[500]}`,
			padding: '3.5rem 3rem 4.5rem',
			marginBottom: '4rem'
		}
	},
	row: {
		display: 'flex',
		width: "100%",
		marginBottom: '1rem',
		flexWrap: 'wrap',
		[theme.breakpoints.up("md")]: {
			margin: 0
		}
	},
	rowTeacher: {
		width: "100%",
		marginBottom: '1rem',
		[theme.breakpoints.up("md")]: {
			margin: 0,
			display: 'flex',
		}
	},
	specificStatisticItem: {
		border: `1px solid ${theme.manakin.defaultBorderColor[500]}`,
		width: '50%',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
		padding: '1rem 1rem 2rem',
		
		[theme.breakpoints.up("md")]: {
			border: 0,
			width: 'auto',
			margin: 0,
			padding: '0 2rem',
			width: '25%',
		}
	},

	specificStatisticItemTeacher: {
		border: `1px solid ${theme.manakin.defaultBorderColor[500]}`,
		width: '100%',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
		padding: '2rem 3rem 2rem',
		marginRight: '.5rem',

		[theme.breakpoints.up("md")]: {
			border: 0,
			width: 'auto',
			margin: 0,
			padding: '0 2rem',
			width: '33.3%',
		}
	},

	statisticsTitle: {
		marginBottom: '.5rem',
		[theme.breakpoints.up("md")]: {
			marginBottom: '0'
		}
	},
	stasticsBody: {
		textAlign: 'center',
		fontFamily: theme.manakin.defaultTitleFont,
		fontSize: '1.4rem',
		lineHeight: '2rem',
		letterSpacing: '.4rem',
		margin: 0
    },
    title: {},
	headingContent: {
		[theme.breakpoints.up("md")]: {
			width: '100%',
			display: 'flex',
			justifyContent: 'space-between'
        },
        '& $title': {
            marginBottom: '2.6rem'
        }
	},
	link: {
		textDecoration: 'none'
	},
	linkContent: {
        textDecoration: 'none',
        fontWeight: 'bold',
        '&>span': {
            position: 'relative',
            top: '0.6rem',
            left: '4px',
            transition: 'left .2s'
        },
        '&:hover': {
            '&>span': {
                left: '6px'
            }
        }
	}
})

