import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';
import { Loader, AccessControl } from '@manakin/app-core';
import StatisticsBox from './StatisticsBox';

const styles = theme => ({
  root: {
    width: '100%',
    minHeight: '500px',
    height: 'auto',
    backgroundColor: theme.manakin.primaryColor[500],
    padding: '15rem 0 51rem'
  },
  wrapper: {
    ...theme.manakin.defaultWrapper
  },
  content: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '5.8rem'
    }
  },
  title: {
    [theme.breakpoints.up('md')]: {
      maxWidth: '410px',
      width: '100%'
    }
  },
  body: {
    color: theme.palette.getContrastText(theme.manakin.primaryColor[500]),
    marginBottom: '2.8rem'
  },
  heading: {
    color: theme.palette.getContrastText(theme.manakin.primaryColor[500]),
    hyphens: 'auto',
    wordBreak: 'break-word'
  },
  introduction: {
    [theme.breakpoints.up('md')]: {
      maxWidth: '450px',
      width: '100%'
    }
  }
});

class TeacherHeader extends React.PureComponent {
  render() {
    const { classes, user, data, loading } = this.props;

    return (
      <React.Fragment>
        {!loading ? (
          <div className={classes.root}>
            <div className={classes.wrapper}>
              <div className={classes.content}>
                <div className={classes.title}>
                  <Typography
                    component="h1"
                    variant="h2"
                    classes={{ h2: classes.heading }}
                    className={classes.contentColor}
                  >
                    Welkom {user.firstName || ''}
                  </Typography>
                </div>
                <div className={classes.introduction}>
                  {data.description && (
                    <Typography
                      component="p"
                      variant="body2"
                      classes={{ body2: classes.body }}
                      className={classes.contentColor}
                    >
                      {data.description}
                    </Typography>
                  )}
                </div>
              </div>
              <AccessControl role={['SCHOOL_MANAGER']}>
                <StatisticsBox role="SCHOOL_MANAGER" />
              </AccessControl>
              <AccessControl role={['TEACHER']} not={['SCHOOL_MANAGER']}>
                <StatisticsBox role="TEACHER" />
              </AccessControl>
            </div>
          </div>
        ) : (
          <Loader />
        )}
      </React.Fragment>
    );
  }
}

export default compose(withStyles(styles, { name: 'AppTeacherHeader' }))(
  TeacherHeader
);
