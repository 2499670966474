import React from 'react';
import ImageWithSubtitle from './ImageWithSubtitle';

class ImageWithSubtitleContainer extends React.PureComponent {
	componentDidMount() {
		this.setState({
			...this.props.data
		})
	}

	handleNext = event => {
		if (this.props.onFinished) this.props.onFinished(this.state.correct);
	};

	handleClick = () => {
		this.setState(prevState => ({
			fullscreen: !prevState.fullscreen
		}))
	}

	render() {
		const {buttonText = ''} = this.props
		return <ImageWithSubtitle buttonText={buttonText} data={{ ...this.state }} onNext={this.handleNext} onClick={this.handleClick} />
	}
}

export default ImageWithSubtitleContainer