'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.GQL_FETCH_ELEMENT_RESULTS = undefined;

var _templateObject = _taggedTemplateLiteral(['\n    query(\n        $box: ID!\n        $program: ID!\n        $user: ID!\n    ) {\n        elementResults(\n            box: $box\n            program: $program\n            user: $user\n        ) {\n            finished\n            element {\n                id\n                title\n            }\n        }\n    }\n'], ['\n    query(\n        $box: ID!\n        $program: ID!\n        $user: ID!\n    ) {\n        elementResults(\n            box: $box\n            program: $program\n            user: $user\n        ) {\n            finished\n            element {\n                id\n                title\n            }\n        }\n    }\n']);

var _graphqlTag = require('graphql-tag');

var _graphqlTag2 = _interopRequireDefault(_graphqlTag);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteral(strings, raw) { return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var GQL_FETCH_ELEMENT_RESULTS = exports.GQL_FETCH_ELEMENT_RESULTS = (0, _graphqlTag2.default)(_templateObject);