'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Dialog = require('@manakin/core/Dialog');

var _Dialog2 = _interopRequireDefault(_Dialog);

var _Dialog3 = require('@material-ui/core/Dialog');

var _Dialog4 = _interopRequireDefault(_Dialog3);

var _DialogTitle = require('@material-ui/core/DialogTitle');

var _DialogTitle2 = _interopRequireDefault(_DialogTitle);

var _DialogActions = require('@material-ui/core/DialogActions');

var _DialogActions2 = _interopRequireDefault(_DialogActions);

var _recompose = require('recompose');

var _Button = require('@material-ui/core/Button');

var _Button2 = _interopRequireDefault(_Button);

var _styles = require('@material-ui/core/styles');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var styles = function styles(theme) {
	return {
		input: {
			marginBottom: '4rem!important',
			marginTop: '0!important'
		}
	};
};

var CreateDialog = function (_React$PureComponent) {
	_inherits(CreateDialog, _React$PureComponent);

	function CreateDialog() {
		var _ref;

		var _temp, _this, _ret;

		_classCallCheck(this, CreateDialog);

		for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
			args[_key] = arguments[_key];
		}

		return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = CreateDialog.__proto__ || Object.getPrototypeOf(CreateDialog)).call.apply(_ref, [this].concat(args))), _this), _this.handleSubmit = function (onClose) {
			if (_this.props.onDelete) _this.props.onDelete();
			onClose();
		}, _temp), _possibleConstructorReturn(_this, _ret);
	}

	_createClass(CreateDialog, [{
		key: 'render',
		value: function render() {
			var _this2 = this;

			return _react2.default.createElement(_Dialog2.default, {
				name: 'appDeleteMessageDialog',
				render: function render(_ref2) {
					var open = _ref2.open,
					    onClose = _ref2.onClose;
					return _react2.default.createElement(
						_Dialog4.default,
						{ open: open, onClose: onClose },
						_react2.default.createElement(
							_DialogTitle2.default,
							null,
							'Bericht verwijderen?'
						),
						_react2.default.createElement(
							_DialogActions2.default,
							null,
							_react2.default.createElement(
								_Button2.default,
								{
									onClick: function onClick() {
										return _this2.handleSubmit(onClose);
									},
									color: 'primary',
									variant: 'contained'
								},
								'Verwijderen'
							),
							_react2.default.createElement(
								_Button2.default,
								{
									onClick: onClose,
									color: 'primary'
								},
								'Annuleren'
							)
						)
					);
				}
			});
		}
	}]);

	return CreateDialog;
}(_react2.default.PureComponent);

exports.default = (0, _recompose.compose)((0, _styles.withStyles)(styles))(CreateDialog);