import React, { useEffect, useState } from "react";
import { ElementOverlay, WorkformViewer } from "@manakin/app-core";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core";
import { styles } from "./styles";
import { PointsInfo } from "../../parts";
import { connect } from "react-redux";

const Questions = props => {
  const {
    data,
    stepper,
    controls = true,
    classes,
    points,
    config = {}
  } = props;
  const { literals = {} } = config;
  const { loading } = data;

  //state hooks
  const [step, setStep] = useState(-1);
  const [type, setType] = useState("");
  const [item, setItem] = useState({});

  //effect hooks
  useEffect(() => {
    stepper.startCounting();
  }, []);

  useEffect(() => {
    if (stepper.step != step && stepper.items.length && stepper.step >= 0) {
      if (stepper.items[stepper.step].type != type)
        setType(stepper.items[stepper.step].type);
      setStep(stepper.step);
      setItem(stepper.items[stepper.step]);
    }
  }, [stepper]);

  //functions
  const handleNext = correct => {
    const _correct = correct.correct != undefined ? correct.correct : correct;
    if (props.onNext) props.onNext(_correct);
  };

  if (loading) {
    return "loading";
  }
  return (
    <div className={classes.root}>
      <ElementOverlay
        variant="timer"
        title={`${literals.APP_QUESTION || "Question"} ${stepper.step + 1} / ${
          stepper.items.length
        }`}
        items={stepper.items}
        timer={stepper.count}
        controls={controls}
        background="TRANSPARENT"
      >
        <WorkformViewer type={type} item={item} onFinish={handleNext} />
      </ElementOverlay>
      <div className={classes.points}>
        <PointsInfo points={points} />
      </div>
    </div>
  );
};

export default compose(
  connect(state => ({
    config: state.config
  })),
  withStyles(styles, { name: "AppBettingGameQuestions" })
)(Questions);
