'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styles = require('@material-ui/core/styles');

var _reactRouterDom = require('react-router-dom');

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _Typography = require('@material-ui/core/Typography');

var _Typography2 = _interopRequireDefault(_Typography);

var _Button = require('@material-ui/core/Button');

var _Button2 = _interopRequireDefault(_Button);

var _ = require('..');

var _selectors = require('@manakin/authentication/selectors');

var _reactRedux = require('react-redux');

var _VideoCard = require('@manakin/app-core/ElementCard/VideoCard');

var _VideoCard2 = _interopRequireDefault(_VideoCard);

var _graphql = require('./graphql');

var _reactApollo = require('react-apollo');

var _recompose = require('recompose');

var _reactRenderHtml = require('react-render-html');

var _reactRenderHtml2 = _interopRequireDefault(_reactRenderHtml);

var _Done = require('@material-ui/icons/Done');

var _Done2 = _interopRequireDefault(_Done);

var _Clear = require('@material-ui/icons/Clear');

var _Clear2 = _interopRequireDefault(_Clear);

var _ArrowForward = require('@material-ui/icons/ArrowForward');

var _ArrowForward2 = _interopRequireDefault(_ArrowForward);

var _ArrowRightAlt = require('@material-ui/icons/ArrowRightAlt');

var _ArrowRightAlt2 = _interopRequireDefault(_ArrowRightAlt);

var _indexGrid = require('./styles/index-grid');

var _appCore = require('@manakin/app-core');

var _downloadjs = require('downloadjs');

var _downloadjs2 = _interopRequireDefault(_downloadjs);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var mapStateToProps = function mapStateToProps(state) {
	return {
		config: state.config,
		appUser: (0, _selectors.getAppUser)(state)
	};
};

var ElementGridCard = function (_React$Component) {
	_inherits(ElementGridCard, _React$Component);

	function ElementGridCard() {
		var _ref;

		var _temp, _this, _ret;

		_classCallCheck(this, ElementGridCard);

		for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
			args[_key] = arguments[_key];
		}

		return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = ElementGridCard.__proto__ || Object.getPrototypeOf(ElementGridCard)).call.apply(_ref, [this].concat(args))), _this), _this._isMounted = false, _this.state = {
			show: false,
			bridgeText: '',
			bookCheckData: {}
		}, _this.hasMargin = function (type) {
			switch (type) {
				case 'BookCheck':
					return true;
				default:
					return false;
			}
		}, _this.fetchData = function (type, id) {
			var client = _this.props.client;

			return client.query({
				query: type,
				variables: {
					id: id
				}
			});
		}, _this.hasBackground = function (type) {
			return type === "Bridge";
		}, _this.download = function (workform) {
			if (workform) {
				_this.fetchData(_graphql.GQL_FETCH_FILE, workform.id).then(function (result) {
					if (!result.errors && _this._isMounted) {
						var _result$data$fileDown = result.data.fileDownload,
						    fileDownload = _result$data$fileDown === undefined ? {} : _result$data$fileDown;

						if (fileDownload.openAction && fileDownload.openAction == 'current') {
							(0, _downloadjs2.default)(data.file && data.file.url);
						} else if (fileDownload.file && fileDownload.file.url) {
							window.open(fileDownload.file.url, '_blank');
						}
					}
				});
			}
		}, _this.handleLinkClick = function (e, item) {
			e && e.preventDefault();
			var _this$props = _this.props,
			    _this$props$disabled = _this$props.disabled,
			    disabled = _this$props$disabled === undefined ? false : _this$props$disabled,
			    history = _this$props.history;

			if (disabled) {
				if (_this.props.onDisabledClick) _this.props.onDisabledClick();
			} else {
				history.push('/' + item.type + '/' + item.id);
			}
		}, _temp), _possibleConstructorReturn(_this, _ret);
	}

	_createClass(ElementGridCard, [{
		key: 'componentWillUnmount',
		value: function componentWillUnmount() {
			this._isMounted = false;
		}
	}, {
		key: 'componentDidMount',
		value: function componentDidMount() {
			var _this2 = this;

			this._isMounted = true;
			var _props = this.props,
			    item = _props.item,
			    client = _props.client,
			    _props$config = _props.config,
			    config = _props$config === undefined ? {} : _props$config;
			var _config$literals = config.literals,
			    literals = _config$literals === undefined ? {} : _config$literals;


			setTimeout(function () {
				if (_this2._isMounted) {
					_this2.setState({ show: true });
				}
			}, 500);

			if (item.type == 'BettingGame') {
				this.fetchData(_graphql.GQL_FETCH_BETTING_GAME, item.id).then(function (result) {
					if (!result.errors && _this2._isMounted) _this2.setState({ data: _extends({}, result.data.trainer, { pre: literals.APP_BETTING_GAME || 'Betting game' }) });
				});
			}

			if (item.type == 'Trainer') {
				this.fetchData(_graphql.GQL_FETCH_TRAINER, item.id).then(function (result) {
					if (!result.errors && _this2._isMounted) _this2.setState({ data: _extends({}, result.data.trainer, { pre: literals.APP_TRAINER || 'Trainer' }) });
				});
			}

			if (item.type == 'MasterClass') {
				this.fetchData(_graphql.GQL_FETCH_MASTERCLASS, item.id).then(function (result) {
					if (!result.errors && _this2._isMounted) _this2.setState({ data: _extends({}, result.data.masterClass, { pre: literals.APP_MASTERCLASS || 'Masterclass' }) });
				});
			}

			if (item.type == 'Film') {
				this.fetchData(_graphql.GQL_FETCH_FILM, item.id).then(function (result) {
					if (!result.errors && _this2._isMounted) _this2.setState({ data: _extends({}, result.data.film, { pre: literals.APP_MOVIE || 'Film' }) });
				});
			}

			if (item.type == 'Case') {
				this.fetchData(_graphql.GQL_FETCH_CASE, item.id).then(function (result) {
					if (!result.errors && _this2._isMounted) _this2.setState({ data: _extends({}, result.data.case, { pre: literals.APP_CASE || 'Case' }) });
				});
			}

			if (item.type == 'Lesson') {
				this.fetchData(_graphql.GQL_FETCH_LESSON, item.id).then(function (result) {
					if (!result.errors && _this2._isMounted) _this2.setState({ data: _extends({}, result.data.lesson, { pre: literals.APP_LESSON || 'Les' }) });
				});
			}

			if (item.type == 'Bridge') {
				this.fetchData(_graphql.GQL_FETCH_BRIDGE, item.id).then(function (result) {
					if (!result.errors && _this2._isMounted) {
						_this2.setState({ bridgeText: result.data.bridge.text, bridgeVideo: result.data.bridge.vimeoId });
					}
				});
			}

			if (item.type == 'BookCheck') {
				this.fetchData(_graphql.GQL_FETCH_BOOKCHECK, item.id).then(function (result) {
					if (!result.errors && _this2._isMounted) {
						_this2.setState({ bookCheckData: result.data.bookCheck });
					}
				});
			}

			if (item.type == 'BookDownload') {
				this.fetchData(_graphql.GQL_FETCH_BOOK_DOWNLOAD, item.id).then(function (result) {
					if (!result.errors && _this2._isMounted) {
						_this2.setState({ bookDownloadData: result.data.bookDownload });
					}
				});
			}

			if (item.type == 'SlideshowCreator') {
				this.fetchData(_graphql.GQL_FETCH_SLIDESHOW, item.id).then(function (result) {
					if (!result.errors && _this2._isMounted) {
						_this2.setState({ slideshowData: result.data.slideshowCreator });
					}
				});
			}
		}
	}, {
		key: 'componentDidUpdate',
		value: function componentDidUpdate(prevProps) {
			if (prevProps.data.loading && !this.props.data.loading) {
				var _data = this.props.data;
				var settings = _data.settings;


				if (settings && settings.settings && settings.settings.length) {
					var newSettings = {};
					settings.settings.forEach(function (setting) {
						if (setting.name == 'file') {
							newSettings[setting.name] = setting.file;
						} else {
							newSettings[setting.name] = setting.value;
						}
					});

					this.setState({
						settings: _extends({}, newSettings)
					});
				}
			}
		}
	}, {
		key: 'render',
		value: function render() {
			var _this3 = this,
			    _classNames2,
			    _classNames11,
			    _classNames14,
			    _classNames17,
			    _classNames19;

			var _props2 = this.props,
			    item = _props2.item,
			    classes = _props2.classes,
			    idx = _props2.idx,
			    _props2$boxStyle = _props2.boxStyle,
			    boxStyle = _props2$boxStyle === undefined ? 'default' : _props2$boxStyle,
			    appUser = _props2.appUser,
			    _props2$disabled = _props2.disabled,
			    disabled = _props2$disabled === undefined ? false : _props2$disabled,
			    _props2$config = _props2.config,
			    config = _props2$config === undefined ? {} : _props2$config;
			var _config$literals2 = config.literals,
			    literals = _config$literals2 === undefined ? {} : _config$literals2;

			var imageUrl = item.image ? item.image.url : '';
			var placeholder = item.image ? item.image.thumbnailUrl : '';
			var _state = this.state,
			    show = _state.show,
			    bridgeText = _state.bridgeText,
			    bridgeVideo = _state.bridgeVideo,
			    bookCheckData = _state.bookCheckData,
			    _state$data = _state.data,
			    data = _state$data === undefined ? {} : _state$data,
			    _state$slideshowData = _state.slideshowData,
			    slideshowData = _state$slideshowData === undefined ? {} : _state$slideshowData,
			    _state$bookDownloadDa = _state.bookDownloadData,
			    bookDownloadData = _state$bookDownloadDa === undefined ? {} : _state$bookDownloadDa,
			    _state$settings = _state.settings,
			    settings = _state$settings === undefined ? {} : _state$settings;

			var noMargin = this.hasMargin(item.type);
			var noBackground = this.hasBackground(item.type);

			return _react2.default.createElement(
				_react2.default.Fragment,
				null,
				item.type != 'Bridge' && item.type != 'SlideshowCreator' && item.type != 'BookDownload' && _react2.default.createElement(
					'div',
					{ className: (0, _classnames2.default)(classes.link, _defineProperty({}, classes.disabled, disabled)), onClick: function onClick(e) {
							return _this3.handleLinkClick(e, item);
						} },
					_react2.default.createElement(
						'div',
						{
							className: (0, _classnames2.default)(classes.root, classes[item.type], (_classNames2 = {}, _defineProperty(_classNames2, classes.fullWidth, item.fullWidth), _defineProperty(_classNames2, classes.minHeight, item.type != 'BookCheck'), _defineProperty(_classNames2, classes.noMargin, noMargin), _defineProperty(_classNames2, classes.noBackground, noBackground), _defineProperty(_classNames2, classes.bookCheck, item.type == 'BookCheck'), _defineProperty(_classNames2, classes.listView, boxStyle == 'list' && item.type != 'BookCheck'), _defineProperty(_classNames2, classes.show, show), _defineProperty(_classNames2, classes.disabled, disabled), _classNames2)),
							style: { transition: 'transform .5s .' + (idx + 1) + 's, opacity .5s .' + (idx + 1) + 's' }
						},
						item.type == 'Lesson' && item.done != undefined && _react2.default.createElement(
							'div',
							{ className: (0, _classnames2.default)(classes.done, _defineProperty({}, classes.inCorrect, !item.correct), _defineProperty({}, classes.continue, !item.done)) },
							_react2.default.createElement(
								'span',
								{ className: classes.doneIcon },
								item.done && item.correct && _react2.default.createElement(_Done2.default, { classes: { root: classes.iconRoot } }),
								item.done && !item.correct && _react2.default.createElement(_Clear2.default, { classes: { root: classes.iconRoot } }),
								!item.done && _react2.default.createElement(_ArrowForward2.default, { classes: { root: classes.iconRoot } })
							)
						),
						item.done != undefined && item.type != 'Lesson' && item.type != 'BookCheck' && _react2.default.createElement(
							'div',
							{ className: (0, _classnames2.default)(classes.done, _defineProperty({}, classes.continue, !item.done)) },
							_react2.default.createElement(
								'span',
								{ className: classes.doneIcon },
								item.done && _react2.default.createElement(_Done2.default, { classes: { root: classes.iconRoot } }),
								!item.done && _react2.default.createElement(_ArrowForward2.default, { classes: { root: classes.iconRoot } })
							)
						),
						_react2.default.createElement(
							'div',
							{ className: classes.backgroundImage },
							_react2.default.createElement(_.LoadImage, { src: imageUrl, placeholder: placeholder })
						),
						_react2.default.createElement(
							'div',
							{ className: classes.content },
							_react2.default.createElement(
								'span',
								{ className: (0, _classnames2.default)(classes.type, _defineProperty({}, classes.secondaryPrefixColor, data.prefixColor == 'SECONDARY'), _defineProperty({}, classes.primaryPrefixColor, data.prefixColor == 'PRIMARY')) },
								data.preTitle || settings[item.type.toLowerCase() + 'Prefix'] || data.pre || ''
							),
							_react2.default.createElement(
								_Typography2.default,
								{ variant: 'h3', className: (0, _classnames2.default)(classes.name, _defineProperty({}, classes.secondaryTitleColor, data.titleColor == 'SECONDARY'), _defineProperty({}, classes.primaryTitleColor, data.titleColor == 'PRIMARY')) },
								item.title
							)
						),
						item.type == 'BookCheck' && _react2.default.createElement(
							'div',
							{ className: (0, _classnames2.default)(classes.bookCheckFlex, _defineProperty({}, classes.bookCheckFlexDone, item.done)) },
							_react2.default.createElement(
								'div',
								{ className: classes.bookCheckContent },
								_react2.default.createElement(
									_Typography2.default,
									{ className: classes.bodytitle, component: 'h5', variant: 'h5' },
									bookCheckData.cardTitle || ''
								),
								_react2.default.createElement(
									_Typography2.default,
									{ component: 'p', variant: 'body1', className: classes.body },
									bookCheckData.cardText || ''
								)
							),
							_react2.default.createElement(
								_Button2.default,
								{ variant: 'contained', color: 'primary', fullWidth: true, className: classes.button },
								literals.APP_START_BOOKCHECK || "Doe de Boekcheck",
								item.done && _react2.default.createElement(
									'div',
									{ className: (0, _classnames2.default)(classes.done, classes.bookCheckDone) },
									_react2.default.createElement(
										'span',
										{ className: classes.doneIcon },
										item.done && _react2.default.createElement(_Done2.default, { classes: { root: classes.iconRoot } })
									)
								)
							)
						)
					)
				),
				item.type == 'Bridge' && _react2.default.createElement(
					_react2.default.Fragment,
					null,
					bridgeVideo ? _react2.default.createElement(_VideoCard2.default, {
						show: show,
						onDisabledClick: this.handleLinkClick,
						disabled: disabled,
						item: item,
						bridgeVideo: bridgeVideo
					}) : _react2.default.createElement(
						'div',
						{
							className: (0, _classnames2.default)(classes.root, (_classNames11 = {}, _defineProperty(_classNames11, classes.fullWidth, item.fullWidth), _defineProperty(_classNames11, classes.noBackground, noBackground), _defineProperty(_classNames11, classes.show, show), _classNames11), _defineProperty({}, classes.primaryBackgroundColor, item.backgroundColor == 'SECONDARY'), _defineProperty({}, classes.secondaryBackgroundColor, item.backgroundColor == 'PRIMARY')),
							style: {
								transition: 'all .5s .' + (idx + 1) + 's'
							}
						},
						_react2.default.createElement(
							'div',
							{ className: classes.bridgeContent },
							_react2.default.createElement(
								_Typography2.default,
								{ variant: 'h3', className: (0, _classnames2.default)(classes.contentColor, classes.contentTitle) },
								item.title
							),
							_react2.default.createElement(
								_Typography2.default,
								{
									component: 'div',
									variant: 'body1',
									className: classes.contentColor
								},
								(0, _reactRenderHtml2.default)(bridgeText || "")
							)
						)
					)
				),
				item.type == 'SlideshowCreator' && _react2.default.createElement(
					_appCore.AccessControl,
					{ role: ['TEACHER', 'SCHOOL_MANAGER'] },
					_react2.default.createElement(
						'div',
						{
							className: (0, _classnames2.default)(classes.root, classes.slideshowCard, (_classNames14 = {}, _defineProperty(_classNames14, classes.fullWidth, item.fullWidth), _defineProperty(_classNames14, classes.noBackground, noBackground), _defineProperty(_classNames14, classes.show, show), _classNames14), _defineProperty({}, classes.primaryBackgroundColor, item.backgroundColor == 'SECONDARY'), _defineProperty({}, classes.secondaryBackgroundColor, item.backgroundColor == 'PRIMARY')),
							style: {
								transition: 'all .5s .' + (idx + 1) + 's'
							}
						},
						_react2.default.createElement(
							'div',
							{ className: classes.slideshowBox },
							_react2.default.createElement(
								_Typography2.default,
								{ component: 'h3', variant: 'h3' },
								slideshowData.introTitle || ''
							),
							_react2.default.createElement(
								_Typography2.default,
								{
									component: 'div',
									variant: 'body1' },
								(0, _reactRenderHtml2.default)(slideshowData.text || "")
							),
							slideshowData.slideshows && slideshowData.slideshows.map(function (slide) {
								return _react2.default.createElement(
									_react2.default.Fragment,
									{ key: slide.id },
									(slide.author && appUser.id == slide.author.id || slide.isPublic) && _react2.default.createElement(
										'div',
										{ className: classes.slideshowLinks, key: slide.id },
										_react2.default.createElement(
											'div',
											{ className: classes.slideshowLinksItem },
											_react2.default.createElement(
												_reactRouterDom.Link,
												{ className: classes.link, to: '/slideshow/' + slide.id },
												_react2.default.createElement(
													_Typography2.default,
													{
														component: 'strong',
														variant: 'subtitle1',
														className: classes.slideshowTitle
													},
													slide.title
												),
												_react2.default.createElement(
													_Typography2.default,
													{ className: classes.slideshowAuthor },
													'Auteur: ',
													slide.author && slide.author.fullName
												)
											),
											_react2.default.createElement(
												_reactRouterDom.Link,
												{
													to: '/' + item.type + '/' + item.id + '/' + slide.id,
													className: classes.linkToSlideShowEdit
												},
												_react2.default.createElement(
													_Button2.default,
													{ color: 'primary', component: 'div' },
													_react2.default.createElement(_ArrowRightAlt2.default, { className: classes.linkToSlideShowEditIcon }),
													' ',
													literals.APP_CHANGE_SLIDESHOW || "Slideshow aanpassen"
												)
											)
										)
									)
								);
							}),
							_react2.default.createElement(
								_reactRouterDom.Link,
								{
									to: '/' + item.type + '/' + item.id + '/new',
									className: (0, _classnames2.default)(classes.link, classes.newSlideshowButton)
								},
								_react2.default.createElement(
									_Button2.default,
									{
										variant: 'contained',
										color: 'primary',
										fullWidth: true,
										className: classes.button
									},
									literals.APP_NEW_SLIDESHOW || "Nieuwe slideshow maken"
								)
							)
						)
					)
				),
				item.type == 'BookDownload' && bookDownloadData.workforms && bookDownloadData.workforms.length == 1 && _react2.default.createElement(
					'div',
					{
						className: (0, _classnames2.default)(classes.root, classes[item.type], classes.bookCheck, (_classNames17 = {}, _defineProperty(_classNames17, classes.fullWidth, item.fullWidth), _defineProperty(_classNames17, classes.noMargin, noMargin), _defineProperty(_classNames17, classes.noBackground, noBackground), _defineProperty(_classNames17, classes.bookCheck, true), _defineProperty(_classNames17, classes.show, show), _classNames17)),
						style: { transition: 'transform .5s .' + (idx + 1) + 's, opacity .5s .' + (idx + 1) + 's' }
					},
					_react2.default.createElement(
						'div',
						{ className: classes.backgroundImage },
						_react2.default.createElement(_.LoadImage, { src: imageUrl, placeholder: placeholder })
					),
					_react2.default.createElement(
						'div',
						{ className: (0, _classnames2.default)(classes.bookCheckFlex, _defineProperty({}, classes.bookCheckFlexDone, item.done)) },
						_react2.default.createElement(
							'div',
							{ className: classes.bookCheckContent },
							_react2.default.createElement(
								_Typography2.default,
								{ component: 'h5', variant: 'h5', className: classes.contentColor },
								bookDownloadData.introTitle || ''
							),
							_react2.default.createElement(
								_Typography2.default,
								{ component: 'div', variant: 'body1', className: classes.body },
								(0, _reactRenderHtml2.default)(bookDownloadData.text || '')
							)
						),
						_react2.default.createElement(
							_Button2.default,
							{ variant: 'contained', color: 'primary', fullWidth: true, className: classes.button, onClick: function onClick() {
									return _this3.download(bookDownloadData.workforms[0]);
								} },
							literals.APP_DOWNLOAD_FILE || "Download Bestand"
						)
					)
				),
				item.type == 'BookDownload' && bookDownloadData.workforms && bookDownloadData.workforms.length > 1 && _react2.default.createElement(
					'div',
					{
						className: (0, _classnames2.default)(classes.root, (_classNames19 = {}, _defineProperty(_classNames19, classes.fullWidth, item.fullWidth), _defineProperty(_classNames19, classes.noBackground, noBackground), _defineProperty(_classNames19, classes.show, show), _classNames19), _defineProperty({}, classes.primaryBackgroundColor, item.backgroundColor == 'SECONDARY'), _defineProperty({}, classes.secondaryBackgroundColor, item.backgroundColor == 'PRIMARY')),
						style: {
							transition: 'all .5s .' + (idx + 1) + 's'
						}
					},
					_react2.default.createElement(
						'div',
						{ className: classes.bridgeContent },
						_react2.default.createElement(
							_Typography2.default,
							{ component: 'h5', variant: 'h5', className: classes.contentColor },
							bookDownloadData.introTitle || ''
						),
						_react2.default.createElement(
							_Typography2.default,
							{
								component: 'div',
								variant: 'body1',
								className: classes.contentColor },
							(0, _reactRenderHtml2.default)(bookDownloadData.text || '')
						),
						_react2.default.createElement(
							'div',
							{ className: classes.downloadLinks },
							bookDownloadData.workforms.map(function (workform) {
								return _react2.default.createElement(
									_Typography2.default,
									{ key: workform.id, className: classes.downloadButton, onClick: function onClick() {
											return _this3.download(workform);
										} },
									_react2.default.createElement(
										'span',
										{ className: classes.iconRoot },
										_react2.default.createElement(_ArrowRightAlt2.default, null)
									),
									_react2.default.createElement(
										'span',
										null,
										workform.introduction || literals.APP_DOWNLOAD_FILE || 'Bestand downloaden'
									)
								);
							})
						)
					)
				)
			);
		}
	}]);

	return ElementGridCard;
}(_react2.default.Component);

exports.default = (0, _recompose.compose)((0, _reactRedux.connect)(mapStateToProps), _reactRouterDom.withRouter, _reactApollo.withApollo, (0, _reactApollo.graphql)(_graphql.GQL_FETCH_GLOBAL_SETTINGS), (0, _styles.withStyles)(_indexGrid.styles, { name: 'AppElementCard' }))(ElementGridCard);