import React from 'react';
import FeedbackBox from './FeedbackBox';
import { compose } from 'recompose';
import { connect } from 'react-redux';

class FeedbackBoxContainer extends React.PureComponent {
  state = {};

  componentDidMount() {
    if (this.props.data) {
      this.setState(
        {
          ...this.props.data
        },
        () => this.showFeedbackBox(this.props.data)
      );
    }
  }

  showFeedback = data => {
    const {
      correct,
      feedbackCorrect,
      feedbackInCorrect,
      options = {},
      done
    } = data;
    const { config = {} } = this.props;
    const { literals = {} } = config;

    const showButton =
      options.nextButton != undefined ? options.nextButton : true;
    const showTitle =
      options.showFeedbackTitle != undefined ? options.showFeedbackTitle : true;

    this.setState({
      title: showTitle
        ? correct
          ? literals.APP_FEEDBACK_SUCCES || 'Goed'
          : literals.APP_FEEDBACK_WRONG || 'Helaas'
        : '',
      hint: true,
      done: true,
      content: correct ? feedbackCorrect : feedbackInCorrect,
      buttonText: showButton ? literals.APP_RESUME_BUTTON || 'Ga verder' : ''
    });

    if (!showButton && !done) {
      this.props.onFinished();
    }
    if (showButton && !done && correct) {
      if (this.props.onCorrect) this.props.onCorrect()
    }
  };

  showHint = data => {
    const { config = {} } = this.props;
    const { literals = {} } = config;
    this.setState({
      title: 'Hint',
      hint: true,
      content: data.hintText || '',
      buttonText: data.buttonText || literals.APP_RESUME_BUTTON || 'Ga verder'
    });
  };

  handleClick = () => {
    if (!this.state.done) {
      this.showFeedback(this.props.data);
    } else {
      this.props.onFinished();
    }
  };

  showFeedbackBox = data => {
    const hasHint = data.hintText
      ? data.hintText == '<p><br></p>'
        ? false
        : true
      : false;

    if (data.correct || data.done) this.showFeedback(data);
    else if (hasHint && !this.state.hint) this.showHint(data);
    else this.showFeedback(data);
  };

  render() {
    return (
      <FeedbackBox {...this.props} {...this.state} onClick={this.handleClick} />
    );
  }
}

export default compose(connect(state => ({ config: state.config })))(
  FeedbackBoxContainer
);
