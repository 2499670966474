'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ROUTER_ROOT_PATH = undefined;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRouterDom = require('react-router-dom');

var _Account = require('../Account');

var _Account2 = _interopRequireDefault(_Account);

var _Licenses = require('../Licenses');

var _Licenses2 = _interopRequireDefault(_Licenses);

var _ModuleRouter = require('@manakin/core/ModuleRouter');

var _ModuleRouter2 = _interopRequireDefault(_ModuleRouter);

var _ChangePassword = require('../ChangePassword/ChangePassword');

var _ChangePassword2 = _interopRequireDefault(_ChangePassword);

var _Messages = require('../Messages');

var _Messages2 = _interopRequireDefault(_Messages);

var _News = require('../News');

var _News2 = _interopRequireDefault(_News);

var _Results = require('../Results');

var _Results2 = _interopRequireDefault(_Results);

var _Invoices = require('../Invoices');

var _Invoices2 = _interopRequireDefault(_Invoices);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ROUTER_ROOT_PATH = exports.ROUTER_ROOT_PATH = 'account';

var Router = function Router(props) {
  return _react2.default.createElement(
    _ModuleRouter2.default,
    null,
    _react2.default.createElement(_reactRouterDom.Route, { exact: true, path: '/' + ROUTER_ROOT_PATH, component: _Account2.default }),
    _react2.default.createElement(_reactRouterDom.Route, { exact: true, path: '/' + ROUTER_ROOT_PATH + '/licenses', component: _Licenses2.default }),
    _react2.default.createElement(_reactRouterDom.Route, {
      exact: true,
      path: '/' + ROUTER_ROOT_PATH + '/change-password',
      component: _ChangePassword2.default
    }),
    _react2.default.createElement(_reactRouterDom.Route, { exact: true, path: '/' + ROUTER_ROOT_PATH + '/messages', component: _Messages2.default }),
    _react2.default.createElement(_reactRouterDom.Route, { exact: true, path: '/' + ROUTER_ROOT_PATH + '/news', component: _News2.default }),
    _react2.default.createElement(_reactRouterDom.Route, { exact: true, path: '/' + ROUTER_ROOT_PATH + '/results', component: _Results2.default }),
    _react2.default.createElement(_reactRouterDom.Route, { exact: true, path: '/' + ROUTER_ROOT_PATH + '/invoices', component: _Invoices2.default })
  );
};

exports.default = (0, _reactRouterDom.withRouter)(Router);