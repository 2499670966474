import React from 'react';
import UiContext from '../UiContext';

function withUi(WrappedComponent) {
    return class WithUi extends React.Component {
        render() {
            return (
                <UiContext.Consumer>
                    {context => <WrappedComponent {...this.props } ui={context} />}
                </UiContext.Consumer>
            );
        }
    };
}

export default withUi;

