export function hasPermission(permission) {
  let token = document.cookie.match(
    `(?:(?:^|.*; *)jwt_bespeak *= *([^;]*).*$)|^.*$`
  )[1];
  if (token) {
    let base64Url = token.split('.')[1];
    if (base64Url) {
      let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      let decodedTokenBody = JSON.parse(window.atob(base64));
      let allTokenPrivileges = decodedTokenBody.privileges || [];

      return allTokenPrivileges.indexOf(permission) !== -1;
    }
  }

  return false;
}
