'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _core = require('@manakin/core');

var _reactBeautifulDnd = require('react-beautiful-dnd');

var _recompose = require('recompose');

var _styles = require('@material-ui/core/styles');

var _appCore = require('@manakin/app-core');

var _OpenWith = require('@material-ui/icons/OpenWith');

var _OpenWith2 = _interopRequireDefault(_OpenWith);

var _selectors = require('@manakin/app-core/AppBar/selectors');

var _actions = require('@manakin/app-core/AppBar/actions');

var _reactRedux = require('react-redux');

var _styles2 = require('./styles');

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var mapStateToProps = function mapStateToProps(state) {
  return {
    isSave: (0, _selectors.getSave)(state),
    isGoBack: (0, _selectors.getGoBack)(state)
  };
};
var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    onSaveProgram: function onSaveProgram(data) {
      return dispatch((0, _actions.saveAppBar)(data));
    }
  };
};

var ProgramOrder = function (_React$Component) {
  _inherits(ProgramOrder, _React$Component);

  function ProgramOrder() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, ProgramOrder);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = ProgramOrder.__proto__ || Object.getPrototypeOf(ProgramOrder)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
      data: [],
      loading: true,
      lastEdited: 0,
      lastSaved: 1
    }, _this.handleClick = function (data) {
      if (_this.props.onClick) _this.props.onClick(data);
    }, _this.handleSetItems = function (_items) {
      _this.setState({
        data: [].concat(_toConsumableArray(_items))
      });
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(ProgramOrder, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      this.props.onSaveProgram('empty');

      if (this.props.schoolClass.boxes) {
        this.setState({ data: this.props.schoolClass.boxes });
      } else {
        this.setState({ data: this.props.program.boxes });
      }
    }
  }, {
    key: 'componentDidUpdate',
    value: function componentDidUpdate(prevProps) {
      if (prevProps.schoolClass.id != this.props.schoolClass.id) {
        if (this.props.schoolClass.boxes) {
          this.setState({ data: this.props.schoolClass.boxes });
        } else {
          this.setState({ data: this.props.program.boxes });
        }
      }

      if (prevProps.isSave != this.props.isSave) {
        if (this.props.isSave === 'teacherProgram') {
          this.props.onSave(this.state.data);
          this.setState({
            lastSaved: Date.now()
          });
        }
        this.props.onSaveProgram('empty');
      }
    }
  }, {
    key: 'render',
    value: function render() {
      var _this2 = this;

      var _props = this.props,
          classes = _props.classes,
          _props$mileStones = _props.mileStones,
          mileStones = _props$mileStones === undefined ? {} : _props$mileStones,
          _props$disable = _props.disable,
          disable = _props$disable === undefined ? false : _props$disable;
      var data = this.state.data;


      return _react2.default.createElement(
        'div',
        { className: classes.root },
        _react2.default.createElement(
          _core.Dropable,
          { custom: true, items: data, onSetItems: this.handleSetItems },
          function (provided) {
            return _react2.default.createElement(
              _react2.default.Fragment,
              null,
              data && data.map(function (boxContainer, i) {
                return _react2.default.createElement(
                  _reactBeautifulDnd.Draggable,
                  {
                    draggableId: boxContainer[0].id,
                    index: i,
                    key: boxContainer[0].id
                  },
                  function (provided) {
                    return _react2.default.createElement(
                      'div',
                      _extends({
                        className: classes.boxCardRoot,
                        ref: provided.innerRef
                      }, provided.draggableProps, provided.dragHandleProps),
                      _react2.default.createElement(
                        'div',
                        {
                          className: (0, _classnames2.default)(classes.mileStone, _defineProperty({}, classes.activeMileStone, mileStones[boxContainer[0].id] && mileStones[boxContainer[0].id].text)),
                          onClick: function onClick() {
                            return _this2.handleClick(boxContainer);
                          }
                        },
                        _react2.default.createElement(
                          'span',
                          { className: classes.mileStoneText },
                          mileStones[boxContainer[0].id] ? mileStones[boxContainer[0].id].text || 'Klik hier om een periode toe te voegen' : 'Klik hier om een periode toe te voegen'
                        )
                      ),
                      boxContainer.length === 1 ? _react2.default.createElement(
                        'div',
                        { className: classes.wrapper },
                        _react2.default.createElement(
                          _appCore.SingleBoxCard,
                          { data: boxContainer[0] },
                          _react2.default.createElement(
                            'div',
                            { className: classes.move },
                            _react2.default.createElement(_OpenWith2.default, null)
                          )
                        )
                      ) : _react2.default.createElement(
                        'div',
                        { className: classes.smallWrapper },
                        _react2.default.createElement(
                          _appCore.MultiBoxCard,
                          { disable: disable, data: boxContainer },
                          _react2.default.createElement(
                            'div',
                            { className: classes.move },
                            _react2.default.createElement(_OpenWith2.default, null)
                          )
                        )
                      )
                    );
                  }
                );
              })
            );
          }
        )
      );
    }
  }]);

  return ProgramOrder;
}(_react2.default.Component);

exports.default = (0, _recompose.compose)((0, _styles.withStyles)(_styles2.styles), (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps))(ProgramOrder);