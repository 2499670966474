import thunk from "redux-thunk";
import { createLogger } from "redux-logger";
import createDebounce from "redux-debounced";

const localStorageMiddleware = store => next => action => {
  return next(action);
};

const middlewares = [createDebounce(), thunk, localStorageMiddleware];

if (process.env.NODE_ENV === "development") {
  middlewares.push(
    createLogger({ predicate: process.env.NODE_ENV === `development` })
  );
}

export default middlewares;
