import gql from 'graphql-tag';

export const GQL_FETCH_APP_USERS = gql`
    query {
        appUsers {
            users {
                id
                fullName
            }   
        }
    }
`;

export const GQL_FETCH_GROUPS = gql`
    query($filter: GroupFilter) {
        groups(filter: $filter) {
        	groups {
				id
				name
				type
        	}	
        }
    }
`;

export const GQL_FETCH_SCHOOL = gql`
    query($id: ID!) {
        school(
            id: $id
        ) {
            id
            name
            classes {
                id
                name
                product {
                    id
                    name
                    program {
                        id
                        name
                        description
                        boxes {
                            id
                            name
                            elements {
                                id
                                title
                                type
                            }
                            image {
                                id
                                bynderId
                                derivative
                                url
                                thumbnailUrl
                            }
                        }
                    }
                }
                students {
                    id
                    firstName
                    middleName
                    lastName
                    fullName
                    email
                    initials
                    group {
                        name
                    }
                }
                boxes {
                    id
                    name
                    image {
                        id
                        bynderId
                        url
                    }
                }
                fixedBoxes
                milestones {
                    id
                    box {
                        id
                    }
                    text
                }
            }
        }
    }
`;

export const GQL_UPDATE_BOXES = gql`
    mutation(
        $id: ID!
        $boxes: [[ID]]
        $milestones: [SchoolClassMilestoneInput]
        $fixedBoxes: Boolean
    ) {
        updateSchoolClass(
            input: {
                id: $id
                boxes: $boxes
                milestones: $milestones
                fixedBoxes: $fixedBoxes
            }
        ) {
            group {
                id
            }
        }
    }
`;

export const GQL_UPDATE_SCHOOLCLASS = gql`
    mutation(
        $id: ID!
        $students: [ID]
        $teachers: [ID]
        $boxes: [[ID]]
    ) {
        updateSchoolClass(
            input: {
                id: $id
                students: $students
                teachers: $teachers
                boxes: $boxes
            }
        ) {
            group {
                id
            }
        }
    }
`;

export const GQL_FETCH_PROGRAM = gql`
    query($id: ID!) {
        program(
            id: $id
        ) {
            name
            description
        }
    }
`;

export const GQL_FETCH_SCHOOL_CLASS = gql`
    query($id: ID!) {
        schoolClass(
            id: $id
        ) {
            id
            name
            product {
                id
                name
                program {
                    id
                    name
                    description
                    boxes {
                        id
                        name
                        elements {
                            id
                            title
                            type
                        }
                        image {
                            id
                            bynderId
                            derivative
                            url
                            thumbnailUrl
                        }
                    }
                }
            }
            students {
                id
                firstName
                middleName
                lastName
                fullName
                email
                initials
                group {
                    name
                }
            }
            boxes {
                id
                name
                image {
                    id
                    bynderId
                    url
                }
            }
            fixedBoxes
            milestones {
                id
                box {
                    id
                }
                text
            }
        }
    }
`;