import theme from '../../../apps/example-cms/src/styling/theme';

export const normalize = data => {
  return {
    byId: data.reduce((byIds, entity) => {
      byIds[entity.id] = entity;
      return byIds;
    }, {}),
    allIds: data.map(entity => entity.id)
  };
};

export const manakinTheme = theme;
