import { createSelector } from 'reselect';
import { getCoreState } from '../selectors';

export const LIB_STATE_ROOT = 'lib';

const getState = createSelector(
    [getCoreState],
    coreState => coreState[LIB_STATE_ROOT]
);

export const isRefresh = createSelector(
    [getState],
    state => state.trigger
);