import { createSelector } from 'reselect';

export const AUTHENTICATION_STATE_ROOT = '@manakin/authentication';

export const getAuthenticationState = state => state[AUTHENTICATION_STATE_ROOT];

export const isAuthenticated = createSelector(
  [getAuthenticationState],
  authenticationState =>
    authenticationState && authenticationState.authenticated
);

export const getAppUser = createSelector(
  [getAuthenticationState],
  appUser => appUser.user
);
