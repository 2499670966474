import React from "react";
import { Link } from "react-router-dom";
import Paper from "../Paper";
import Typography from "@material-ui/core/Typography";
import LoginForm from "../LoginForm";
import LinearProgress from "@material-ui/core/LinearProgress";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose, withHandlers } from "recompose";
import { login } from "../actions";
import { showSnackbarMessage } from "@manakin/core/actions";
import "url-search-params-polyfill";

const styles = theme => ({
  root: {
    display: "flex",
    flexFlow: "column nowrap"
  },
  link: {
    marginTop: theme.spacing(2),
    display: "inline-block",
    color: "inherit",
    textDecoration: "none",
    alignSelf: "center",
    "&:hover": {
      textDecoration: "underline"
    }
  },
  headline: {
    fontSize: "32px",
    marginBottom: theme.spacing(2)
  },
  loader: {
    position: "absolute",
    width: "100%",
    top: 0,
    left: 0
  }
});

class Login extends React.Component {
  state = {
    loading: false
  };

  handleSubmit = event => {
    const params = new URLSearchParams(this.props.location.search);
    const redirect = params.get("redirect") || "/";
    this.props.onLogin({ ...event, redirect });
    this.setState({
      loading: true
    });
  };

  render() {
    const { classes, styles } = this.props;
    const { loading } = this.state;

    return (
      <Paper className={classes.root}>
        {loading && <LinearProgress className={classes.loader} />}
        <Typography className={classes.headline} variant="h5" component="h1">
          Inloggen
        </Typography>
        <LoginForm styles={styles} onSubmit={this.handleSubmit} />
        <Link className={classes.link} to={`reset`}>
          Wachtwoord vergeten?
        </Link>
      </Paper>
    );
  }
}

export default compose(
  connect(null, dispatch => ({
    processSuccess: redirect => dispatch(login(redirect)),
    processLoginFailed: () =>
      dispatch(
        showSnackbarMessage({
          text:
            "Inloggen mislukt, mogelijk zijn er onjuiste gegevens ingevuld.",
          variant: "error"
        })
      )
  })),
  withHandlers({
    onLogin: ({ mutate, processSuccess, processLoginFailed }) => event =>
      mutate({
        variables: {
          email: event.email,
          password: event.password
        }
      })
        .then(data => {
          if (!data.errors) {
            processSuccess(event.redirect);
          } else processLoginFailed();
        })
        .catch(e => {
          processLoginFailed();
        })
  }),
  withRouter,
  withStyles(styles, { name: "AuthenticationLogin" })
)(Login);
