'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GQL_FETCH_BOX_RESULTS = exports.GQL_FETCH_APP_USER = undefined;

var _templateObject = _taggedTemplateLiteral(['\n  query($id: ID!) {\n    appUser(id: $id) {\n      id\n      userDataCbd {\n        trainingMandatory2016\n        trainingMandatory2017\n        trainingMandatory2018\n        trainingMandatory2019\n        trainingMandatory2020\n        trainingMandatory2021\n        trainingMandatory2022\n        trainingMandatory2023\n        function2016\n        function2017\n        function2018\n        function2019\n        function2020\n        function2021\n        function2022\n        function2023\n        function\n      }\n    }\n  }\n'], ['\n  query($id: ID!) {\n    appUser(id: $id) {\n      id\n      userDataCbd {\n        trainingMandatory2016\n        trainingMandatory2017\n        trainingMandatory2018\n        trainingMandatory2019\n        trainingMandatory2020\n        trainingMandatory2021\n        trainingMandatory2022\n        trainingMandatory2023\n        function2016\n        function2017\n        function2018\n        function2019\n        function2020\n        function2021\n        function2022\n        function2023\n        function\n      }\n    }\n  }\n']),
    _templateObject2 = _taggedTemplateLiteral(['\n  query($program: ID!, $user: ID!) {\n    boxResults(program: $program, user: $user) {\n      user {\n        id\n      }\n      id\n      lastModifiedOn\n      finishedOn\n      finished\n      rating\n      box {\n        id\n      }\n      text\n    }\n  }\n'], ['\n  query($program: ID!, $user: ID!) {\n    boxResults(program: $program, user: $user) {\n      user {\n        id\n      }\n      id\n      lastModifiedOn\n      finishedOn\n      finished\n      rating\n      box {\n        id\n      }\n      text\n    }\n  }\n']);

var _graphqlTag = require('graphql-tag');

var _graphqlTag2 = _interopRequireDefault(_graphqlTag);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteral(strings, raw) { return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var GQL_FETCH_APP_USER = exports.GQL_FETCH_APP_USER = (0, _graphqlTag2.default)(_templateObject);

var GQL_FETCH_BOX_RESULTS = exports.GQL_FETCH_BOX_RESULTS = (0, _graphqlTag2.default)(_templateObject2);