"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _styles = require("@material-ui/core/styles");

var _reactMedia = require("react-media");

var _reactMedia2 = _interopRequireDefault(_reactMedia);

var _appCore = require("@manakin/app-core");

var _recompose = require("recompose");

var _reactRedux = require("react-redux");

var _reduxFirstHistory = require("redux-first-history");

var _hooks = require("@manakin/hooks");

var _graphql = require("./graphql");

var _reactApollo = require("react-apollo");

var _selectors = require("@manakin/authentication/selectors");

var _MenuItem = require("./MenuItem");

var _MenuItem2 = _interopRequireDefault(_MenuItem);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var styles = function styles(theme) {
  return {
    smallWrapper: _extends({}, theme.manakin.smallWrapper, {
      marginTop: "5rem",
      width: "60%"
    }),
    list: {
      position: "absolute",
      marginTop: "20rem",
      zIndex: 99
    },
    dropDownWrapper: {
      marginBottom: theme.spacing(4) + "px"
    }
  };
};

var Menu = function Menu(props) {
  var handleMenuItemClick = function handleMenuItemClick(redirect) {
    props.goToRedirect(redirect);
  };

  var classes = props.classes,
      location = props.location,
      _props$config = props.config,
      config = _props$config === undefined ? {} : _props$config,
      _props$data = props.data,
      data = _props$data === undefined ? {} : _props$data;

  var _data$idealOrders = data.idealOrders,
      _idealOrders = _data$idealOrders === undefined ? {} : _data$idealOrders,
      loading = data.loading;

  var _idealOrders$idealOrd = _idealOrders.idealOrders,
      idealOrders = _idealOrders$idealOrd === undefined ? [] : _idealOrders$idealOrd;


  var path = location.pathname;
  var dropDownOptions = [{
    value: "/account",
    label: "Account"
  }, {
    value: "/account/messages",
    label: "Berichten"
  }];

  var studentOnlyOptions = [{
    value: "/account/licenses",
    label: "Licentie"
  }];

  var showNews = (0, _hooks.useGetConfig)("pages", "general", "showNews", config);
  if (showNews) {
    dropDownOptions.push({
      value: "/account/news",
      label: "Nieuws"
    });
    dropDownOptions.push({
      value: "/account/results",
      label: "Resultaten"
    });
    if (idealOrders && idealOrders.length) {
      dropDownOptions.push({
        value: "/account/invoices",
        label: "Facturen"
      });
    }
  }

  dropDownOptions.map(function (option) {
    option.selected = path === "" + option.value;
    return option;
  });

  studentOnlyOptions.map(function (option) {
    option.selected = path === "" + option.value;
    return option;
  });

  return _react2.default.createElement(
    _reactMedia2.default,
    { query: "(max-width: 1140px)" },
    function (matches) {
      return matches ? _react2.default.createElement(
        "div",
        { className: classes.smallWrapper },
        _react2.default.createElement(
          "div",
          { className: classes.dropDownWrapper },
          _react2.default.createElement(
            _appCore.AccessControl,
            { role: ["SCHOOL_MANAGER", "TEACHER"] },
            _react2.default.createElement(_appCore.AppBarDropDown, { dropDownOptions: dropDownOptions })
          ),
          _react2.default.createElement(
            _appCore.AccessControl,
            { not: ["SCHOOL_MANAGER", "TEACHER"] },
            _react2.default.createElement(_appCore.AppBarDropDown, {
              dropDownOptions: [].concat(dropDownOptions, studentOnlyOptions)
            })
          )
        )
      ) : _react2.default.createElement(
        "div",
        { className: classes.list },
        dropDownOptions && dropDownOptions.map(function (option, idx) {
          return _react2.default.createElement(_MenuItem2.default, {
            key: idx,
            idx: idx,
            onClick: handleMenuItemClick,
            option: option
          });
        }),
        _react2.default.createElement(
          _appCore.AccessControl,
          { not: ["SCHOOL_MANAGER", "TEACHER"] },
          studentOnlyOptions && studentOnlyOptions.map(function (option, idx) {
            return _react2.default.createElement(_MenuItem2.default, {
              key: idx,
              idx: idx,
              onClick: handleMenuItemClick,
              option: option
            });
          })
        )
      );
    }
  );
};

exports.default = (0, _recompose.compose)((0, _reactRedux.connect)(function (state) {
  return {
    appUser: (0, _selectors.getAppUser)(state),
    config: state.config
  };
}, function (dispatch) {
  return {
    goToRedirect: function goToRedirect(url) {
      return dispatch((0, _reduxFirstHistory.push)("" + url));
    }
  };
}), (0, _styles.withStyles)(styles), (0, _reactApollo.graphql)(_graphql.GQL_FETCH_ORDERS, {
  options: function options(props) {
    return {
      variables: {
        filter: { users: [props.appUser.id || ""] }
      }
    };
  }
}))(Menu);