import gql from 'graphql-tag';

export const GQL_FETCH_CASE = gql`
    query($id: ID!) {
        case(id: $id) {
            id
            preTitle
            image {
                id
                url
                thumbnailUrl
            }
            workforms {
                id
                type
            }
            introTitle
            introText
            introActionTitle
        }
    }
`;

export const GQL_CREATE_ELEMENT_RESULT = gql`
    mutation(
        $user: ID!
        $program: ID!
        $box: ID!
        $element: ID!
        $finished: Boolean
    ) {
        createElementResult(
            input: {
                user: $user
                program: $program
                box: $box
                element: $element
                finished: $finished
            }
        ) {
            result { 
                finished
            }
        }
    }
`;

export const GQL_FETCH_GLOBAL_SETTINGS = gql`
    query{
        settings {
            settings {
                name
                value
                file {
                    id
                    bynderId
                    derivative
                    url
                    thumbnailUrl
                    name
                }
            }
        }
    }
`;