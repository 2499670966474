import React from 'react';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    padding: theme.spacing(6),
    width: '420px',
    maxWidth: '100%'
  }
});

class PaperComp extends React.Component {
  render() {
    const { classes = {}, className: classNameProp, children } = this.props;
    const classNames = [classes.root, classNameProp].join(' ');

    return <Paper className={classNames}>{children}</Paper>;
  }
}

export default withStyles(styles, { name: 'AuthenticationPaper' })(PaperComp);
