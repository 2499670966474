import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { LoadImage } from '@manakin/app-core';
import Typography from '@material-ui/core/Typography';
import { ThemeIcon } from '@manakin/app-core';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

const styles = theme => ({
  image: {
    transition: 'transform .3s'
  },
  root: {
    overflow: 'hidden',
    width: '100%',
    height: '25rem',
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-end',
    padding: '4rem',
    textDecoration: 'none',
    marginBottom: '2rem',
    [theme.breakpoints.up('sm')]: {
      height: '29rem'
    },
    [theme.breakpoints.up('md')]: {
      height: '35rem',
      padding: '4rem 12rem 4rem 4rem'
    },
    [theme.breakpoints.up('lg')]: {
      height: '50rem',
      marginBottom: '5rem'
    },
    '&:hover': {
      '& $image': {
        transform: 'scale(1.1)'
      }
    }
  },
  themeIcon: {
    position: 'absolute',
    width: '7rem',
    height: '7rem',
    zIndex: 1,
    right: '3rem',
    top: '3rem'
  },
  content: {
    position: 'relative',
    zIndex: 1
  },
  contentStyle: {
    margin: 0,
    color: theme.manakin.defaultBoxColor[500],
    textDecoration: 'none'
  },
  secondaryColor: {
    color: theme.manakin.primaryColor[500]
  }
});

const ProgramBlock = props => {
  const {
    classes,
    image = {},
    name = '',
    id,
    preTitle = '',
    themes = []
  } = props;

  return (
    <Link className={classes.root} to={`/box/${id}`}>
      <LoadImage
        src={image ? image.url || '' : ''}
        placeholder={image ? image.thumbnailUrl || false : ''}
        className={classes.image}
      />
      {themes &&
        themes.map(theme => (
          <ThemeIcon
            key={theme.id}
            variant={theme.title}
            className={classes.themeIcon}
          />
        ))}
      <div className={classes.content}>
        <Typography
          variant="body1"
          className={classNames(classes.contentStyle, {
            [classes.secondaryColor]: props.prefixColor === 'SECONDARY'
          })}
        >
          {preTitle}
        </Typography>
        <Typography
          variant="h3"
          className={classNames(classes.contentStyle, {
            [classes.secondaryColor]: props.titleColor === 'SECONDARY'
          })}
        >
          {name}
        </Typography>
      </div>
    </Link>
  );
};

export default withStyles(styles)(ProgramBlock);
