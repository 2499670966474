import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "./styles";
import { WorkformViewer } from "@manakin/app-core";
import { Header, Introduction, Bottom } from "./parts";
import VisibilitySensor from "react-visibility-sensor";
import classNames from "classnames";
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

const Lesson = props => {
  const {
    classes,
    workforms,
    options,
    onFinish,
    onReset,
    lessonData,
    prefix,
    box,
    preview = false,
    theposition
  } = props;

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);

  const [itemsInView, setItemsInView] = useState({});

  const handleChange = (isVisible, item) => {
    const _temp = { ...itemsInView };
    _temp[item.id] = _temp[item.id] ? _temp[item.id] : isVisible;
    setItemsInView(_temp);
  };

  return (
    <div className={classes.root}>
      <Header data={lessonData} prefix={prefix} />
      <Introduction data={lessonData} />
      {workforms &&
        workforms.map(item => (
          <div key={item.id} className={classes.normalMargin}>
            <div
              className={classNames(classes.largeWrapper, {
                [classes.chatWrapper]: item.type === "ChatBubble"
              })}
            >
              {item.type === "ChatBubble" ? (
                <VisibilitySensor onChange={data => handleChange(data, item)}>
                  <WorkformViewer
                    type={item.type}
                    item={item}
                    onFinish={onFinish}
                    options={options}
                    animate={itemsInView[item.id] || false}
                  />
                </VisibilitySensor>
              ) : (
                  <WorkformViewer
                    type={item.type}
                    item={item}
                    onFinish={onFinish}
                    options={options}
                    animate={itemsInView[item.id] || false}
                    customLink={{
                      state: { url: props.location.pathname, idx: theposition }
                    }}
                  />
                )}
            </div>
          </div>
        ))}
      {!preview && (
        <Bottom box={box} lessonData={lessonData} onClick={onReset} />
      )}
    </div>
  );
};

export default compose(
  withRouter,
  withStyles(styles, { name: "AppLesson" })
)(Lesson);
