'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GQL_FETCH_ELEMENT_RESULTS = exports.GQL_FETCH_GROUPS = exports.GQL_FETCH_SCHOOL_CLASS = exports.GQL_FETCH_SCHOOL = undefined;

var _templateObject = _taggedTemplateLiteral(['\n  query($id: ID!) {\n    school(id: $id) {\n      id\n      name\n      classes {\n        id\n        name\n        managers {\n          id\n        }\n        teachers {\n          id\n        }\n        students {\n          id\n          firstName\n          roles {\n            id\n            name\n          }\n          schoolClasses {\n            id\n          }\n        }\n      }\n      students {\n        id\n      }\n    }\n  }\n'], ['\n  query($id: ID!) {\n    school(id: $id) {\n      id\n      name\n      classes {\n        id\n        name\n        managers {\n          id\n        }\n        teachers {\n          id\n        }\n        students {\n          id\n          firstName\n          roles {\n            id\n            name\n          }\n          schoolClasses {\n            id\n          }\n        }\n      }\n      students {\n        id\n      }\n    }\n  }\n']),
    _templateObject2 = _taggedTemplateLiteral(['\n  query($id: ID!) {\n    schoolClass(id: $id) {\n      id\n      name\n      product {\n        id\n        program {\n          id\n          boxes {\n            id\n            elements {\n              id\n              type\n            }\n          }\n        }\n      }\n      students {\n        id\n        sessionAverageDuration\n        sessionTotalDuration\n        sessionCount\n      }\n      schools {\n        id\n      }\n    }\n  }\n'], ['\n  query($id: ID!) {\n    schoolClass(id: $id) {\n      id\n      name\n      product {\n        id\n        program {\n          id\n          boxes {\n            id\n            elements {\n              id\n              type\n            }\n          }\n        }\n      }\n      students {\n        id\n        sessionAverageDuration\n        sessionTotalDuration\n        sessionCount\n      }\n      schools {\n        id\n      }\n    }\n  }\n']),
    _templateObject3 = _taggedTemplateLiteral(['\n  query($filter: GroupFilter) {\n    groups(filter: $filter) {\n      groups {\n        id\n        name\n        type\n      }\n    }\n  }\n'], ['\n  query($filter: GroupFilter) {\n    groups(filter: $filter) {\n      groups {\n        id\n        name\n        type\n      }\n    }\n  }\n']),
    _templateObject4 = _taggedTemplateLiteral(['\n  query($box: ID!, $program: ID!, $user: ID!) {\n    elementResults(box: $box, program: $program, user: $user) {\n      finished\n      element {\n        id\n        title\n      }\n    }\n  }\n'], ['\n  query($box: ID!, $program: ID!, $user: ID!) {\n    elementResults(box: $box, program: $program, user: $user) {\n      finished\n      element {\n        id\n        title\n      }\n    }\n  }\n']);

var _graphqlTag = require('graphql-tag');

var _graphqlTag2 = _interopRequireDefault(_graphqlTag);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteral(strings, raw) { return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var GQL_FETCH_SCHOOL = exports.GQL_FETCH_SCHOOL = (0, _graphqlTag2.default)(_templateObject);

var GQL_FETCH_SCHOOL_CLASS = exports.GQL_FETCH_SCHOOL_CLASS = (0, _graphqlTag2.default)(_templateObject2);

var GQL_FETCH_GROUPS = exports.GQL_FETCH_GROUPS = (0, _graphqlTag2.default)(_templateObject3);

var GQL_FETCH_ELEMENT_RESULTS = exports.GQL_FETCH_ELEMENT_RESULTS = (0, _graphqlTag2.default)(_templateObject4);