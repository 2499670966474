import { createSelector } from 'reselect';
import { getCoreState } from '../selectors';

export const APP_BAR_STATE_ROOT = 'appBar';

const getState = createSelector(
    [getCoreState],
    coreState => coreState[APP_BAR_STATE_ROOT]
);

export const getAppBarTitle = createSelector(
    [getState],
    state => state.title
);
