import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import classNames from "classnames";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Chip from "@material-ui/core/Chip";
import MenuItem from "@material-ui/core/MenuItem";
import CancelIcon from "@material-ui/icons/Cancel";
import { emphasize } from "@material-ui/core/styles/colorManipulator";
import { withApollo } from "react-apollo";
import { compose } from "recompose";
import { connect } from "react-redux";

const styles = theme => ({
  root: {
    flex: "1 0 auto",
    transition: "background-color 200ms linear",
    backgroundColor: "transparent",
    margin: theme.manakin.normalMargin,
    "& *": { opacity: 1, pointerEvents: "auto" }
  },
  loading: {
    height: theme.spacing(6),
    backgroundColor: theme.palette.grey[100],
    "& *": { opacity: 0, pointerEvents: "none" }
  },
  icon: {
    width: 24,
    height: 24
  },
  color: {
    boxShadow: "1px 1px 6px rgba(195,195,195,0.5)",
    borderRadius: 4
  },
  menuItem: {
    display: "flex"
  },
  input: {
    display: "flex"
  },
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center",
    lineHeight: "initial"
  },
  chip: {
    margin: theme.spacing(0.25)
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === "light"
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08
    )
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`
  },
  singleValue: {
    fontSize: 16
  },
  placeholder: {
    position: "absolute",
    left: 2,
    fontSize: 16
  },
  paper: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
    marginBottom: theme.spacing(2)
  },
  divider: {
    height: theme.spacing(2)
  },
  disabledLabel: {
    color: theme.manakin.disabledFieldLabelColor
  },
  disabledInput: {
    color: theme.manakin.disabledFieldTextColor
  },
  colorOption: {
    width: 20,
    height: 20,
    marginRight: 16
  }
});

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      Geen opties
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  const classes = props.selectProps.classes;

  return (
    <TextField
      fullWidth
      required={props.required || false}
      InputProps={{
        inputComponent,
        inputProps: {
          className: classNames(classes.input, {
            [classes.disabledInput]: props.isDisabled
          }),
          inputRef: props.innerRef,
          children: props.children,
          required: props.required || false,
          ...props.innerProps
        }
      }}
      {...props.selectProps.textFieldProps}
      disabled={props.isDisabled}
    />
  );
}

function Option(props) {
  return (
    <div {...props.innerProps} onClick={props.innerProps.onClick}>
      <MenuItem
        buttonRef={props.innerRef}
        selected={props.isFocused}
        component="div"
        style={{
          fontWeight: props.isSelected ? 500 : 400
        }}
        onMouseDown={props.innerProps.onClick}
      >
        {/*{props && props.data && props.data.color &&
                <div style={{backgroundColor: props.data.color}} className={props.selectProps.classes.colorOption}/>}*/}
        {props.children}
      </MenuItem>
    </div>
  );
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={classNames(props.selectProps.classes.placeholder, {
        [props.selectProps.classes.disabledInput]: props.isDisabled
      })}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function SingleValue(props) {
  return (
    <Typography
      className={props.selectProps.classes.singleValue}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return (
    <div className={props.selectProps.classes.valueContainer}>
      {props.children}
    </div>
  );
}

function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={classNames(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
}

function Menu(props) {
  return (
    <Paper
      square
      className={props.selectProps.classes.paper}
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  );
}

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  ValueContainer
};

class SelectField extends React.Component {
  state = {
    value: []
  };

  promiseOptions = inputValue => {
    const {
      query,
      queryName,
      queryType,
      filter,
      filterByKey,
      alpha = false
    } = this.props;

    if (query) {
      let variables = {
        page: 0,
        pagesize: 999,
        search: inputValue || ""
      };

      if (filter) {
        variables.filter = { ...filter };
      }

      return this.props.client
        .query({
          query: query,
          variables: { ...variables }
        })
        .then(result => {
          let _result = result.data[queryName][queryType || queryName]
            .filter(r => (filterByKey ? r[filterByKey] : true))
            .map(r => ({
              value: r.id,
              label: r.name || r.title || r.fullName
            }))
            .sort((a, b) => {
              if (alpha) {
                if (a.label < b.label) {
                  return -1;
                }
                if (a.label > b.label) {
                  return 1;
                }
              }
              return 0;
            });

          _result.unshift({ label: "Geen", value: "" });

          this.setState({
            aSyncResult: [..._result]
          });

          return _result;
        });
    }
  };

  handleChange = value => {
    const { form, name, onChange } = this.props;

    form.onFieldChange({
      key: name,
      value: value ? value.value : ""
    });

    this.setState({ value: value });
    if (onChange) onChange(value);
  };

  componentDidMount() {
    const { form, name, initialValue = "" } = this.props;
    form.onFieldChange({
      key: name,
      value: initialValue ? initialValue.id : ""
    });
  }

  componentDidUpdate(prevProps) {
    const wasLoading =
      (prevProps.loading && !this.props.loading) ||
      (!prevProps.loading && this.props.loading);
    const hasNoLoading =
      prevProps.noLoading &&
      this.props.noLoading &&
      !this.state.initialValueSet;

    if (wasLoading || (hasNoLoading && !this.props.isAsync)) {
      const { form, name, initialValue } = this.props;
      const _initialValue = initialValue
        ? {
            value: initialValue.id,
            label: initialValue.name || initialValue.label || initialValue.title
          }
        : null;

      this.setState({
        value: _initialValue,
        initialValueSet: true
      });
      form.onFieldChange({
        key: name,
        value: _initialValue ? _initialValue.value : ""
      });
    }
  }

  render() {
    const {
      classes,
      loading,
      label,
      form,
      name,
      readOnly = false,
      initialValue = {},
      options = [],
      placeholder = false,
      config = {},
      isAsync = false
    } = this.props;
    const { literals = {} } = config;
    const _placeholder = placeholder
      ? placeholder
      : literals.CMS_SELECT || "Selecteer";
    const { errors, schema } = form;
    const { value = {} } = this.state;
    const className = classNames(classes.root, { [classes.loading]: loading });

    const _options = options.map(item => {
      let label = item.name || item.label || item.title;
      return {
        label: label,
        value: item.id,
        ...(item.color && { color: item.color })
      };
    });

    const currentValue =
      value && value.length !== 0 ? value.value : "" || (initialValue || {}).id;

    const selectedOption =
      _options.find(option => {
        return (
          (option.value
            ? option.value.toString().toUpperCase()
            : option.value) ===
          (currentValue ? currentValue.toString().toUpperCase() : currentValue)
        );
      }) || [];

    if (isAsync && selectedOption.length === 0) {
      const { aSyncResult = [] } = this.state;
      let _val = "";
      let realVal = "";

      aSyncResult.forEach(item => {
        if (value && item.value == value.value) {
          _val = item.name || item.label;
          realVal = value.value;
        } else if (
          item &&
          initialValue &&
          item.value == initialValue.id &&
          !_val
        ) {
          _val = item.name || item.label;
          realVal = initialValue.id;
        }
      });

      const _rVal = realVal ? realVal : "";

      selectedOption.push({
        ...value,
        value: _rVal,
        label: _val
      });
    }

    return (
      <div className={className}>
        {!isAsync && (
          <Select
            required={(schema[name] || {}).required}
            classes={classes}
            defaultOptions
            isClearable
            isDisabled={readOnly}
            placeholder={_placeholder}
            textFieldProps={{
              ...(errors[name] &&
                errors[name].length > 0 && {
                  error: errors[name][0]
                }),
              label: label || "",
              required: (schema[name] || {}).required,
              InputLabelProps: {
                shrink: true,
                ...(readOnly && {
                  classes: {
                    root: classes.disabledLabel
                  }
                })
              },
              readOnly: readOnly
            }}
            options={_options}
            onChange={this.handleChange}
            components={components}
            value={selectedOption}
            menuPortalTarget={document.body}
            styles={{
              menuPortal: base => ({ ...base, zIndex: "1301 !important" })
            }}
          />
        )}
        {isAsync && (
          <AsyncSelect
            required={(schema[name] || {}).required}
            classes={classes}
            defaultOptions
            isClearable
            isDisabled={readOnly}
            placeholder={config}
            textFieldProps={{
              ...(errors[name] &&
                errors[name].length > 0 && {
                  error: errors[name][0]
                }),
              label: label || "",
              required: (schema[name] || {}).required,
              InputLabelProps: {
                shrink: true,
                ...(readOnly && {
                  classes: {
                    root: classes.disabledLabel
                  }
                })
              },
              readOnly: readOnly
            }}
            loadOptions={this.promiseOptions}
            onChange={this.handleChange}
            components={components}
            value={selectedOption}
            menuPortalTarget={document.body}
            styles={{
              menuPortal: base => ({ ...base, zIndex: "1301 !important" })
            }}
          />
        )}
      </div>
    );
  }
}

export default compose(
  connect(state => ({ config: state.config })),
  withStyles(styles, { withTheme: true }),
  withApollo
)(SelectField);
