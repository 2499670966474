import React from 'react';
import FactCards from './FactCards';
import { graphql } from 'react-apollo';
import { compose } from "recompose";
import { GQL_FETCH_FACT_CARDS } from './graphql';
import { Loader } from '@manakin/app-core';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    width: '100%'
  },
  loader: {
    backgroundColor: 'white',
    position: 'absolute',
    width: '100vw',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  }
});

class FactCardsContainer extends React.PureComponent {
  render() {
    const { data, classes } = this.props;
    const { loading, factCards = {} } = data;

    if (loading) {
      return (
        <div className={classes.loader}>
          <Loader />
        </div>
      );
    }
    return (
      <div>
        <FactCards FactCardsData={factCards} />
      </div>
    );
  }
}

export default compose(
  withStyles(styles),
  graphql(GQL_FETCH_FACT_CARDS, {
    options: props => ({
      variables: {
        page: 0,
        pagesize: 99
      }
    })
  })
)(FactCardsContainer);
