'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _styles = require('@material-ui/core/styles');

var _Typography = require('@material-ui/core/Typography');

var _Typography2 = _interopRequireDefault(_Typography);

var _recompose = require('recompose');

var _styles2 = require('./styles');

var _Button = require('@material-ui/core/Button');

var _Button2 = _interopRequireDefault(_Button);

var _reactRouterDom = require('react-router-dom');

var _appCore = require('@manakin/app-core');

var _reactRedux = require('react-redux');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var FactCardsCard = function (_React$PureComponent) {
  _inherits(FactCardsCard, _React$PureComponent);

  function FactCardsCard() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, FactCardsCard);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = FactCardsCard.__proto__ || Object.getPrototypeOf(FactCardsCard)).call.apply(_ref, [this].concat(args))), _this), _this.handleClick = function () {
      var _this$props$disabled = _this.props.disabled,
          disabled = _this$props$disabled === undefined ? false : _this$props$disabled;


      if (disabled && _this.props.onDisabledClick) {
        _this.props.onDisabledClick();
      }
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(FactCardsCard, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          classes = _props.classes,
          factCards = _props.factCards,
          image = _props.image,
          _props$disabled = _props.disabled,
          disabled = _props$disabled === undefined ? false : _props$disabled,
          _props$config = _props.config,
          config = _props$config === undefined ? {} : _props$config;


      return _react2.default.createElement(
        'div',
        {
          className: (0, _classnames2.default)(classes.root, classes.bookCheck, classes.fullWidth, _defineProperty({}, classes.disabled, disabled)),
          onClick: this.handleClick
        },
        _react2.default.createElement(
          'div',
          { className: classes.backgroundImage },
          _react2.default.createElement(_appCore.LoadImage, {
            src: image.url || '',
            placeholder: image.thumbnailUrl || ''
          })
        ),
        _react2.default.createElement(
          'div',
          { className: classes.bookCheckFlex },
          _react2.default.createElement(
            'div',
            { className: classes.bookCheckContent },
            _react2.default.createElement(
              _Typography2.default,
              {
                component: 'h5',
                variant: 'h5',
                className: classes.bodytitle
              },
              config.literals && config.literals.APP_BOX_FACTCARDS_BUTTON_TITLE
            ),
            _react2.default.createElement(
              _Typography2.default,
              { component: 'p', variant: 'body1', className: classes.body },
              config.literals && config.literals.APP_BOX_FACTCARDS_BUTTON_CONTENT
            )
          ),
          _react2.default.createElement(
            _Button2.default,
            {
              component: _reactRouterDom.Link,
              variant: 'contained',
              color: 'primary',
              fullWidth: true,
              className: classes.button,
              disabled: disabled,
              to: {
                pathname: '/boxCards',
                state: [].concat(_toConsumableArray(factCards))
              }
            },
            config.literals && config.literals.APP_BOX_FACTCARDS_BUTTON_BUTTONTEXT
          )
        )
      );
    }
  }]);

  return FactCardsCard;
}(_react2.default.PureComponent);

exports.default = (0, _recompose.compose)(_reactRouterDom.withRouter, (0, _styles.withStyles)(_styles2.styles, { name: 'AppFactcardsCard' }), (0, _reactRedux.connect)(function (_ref2) {
  var config = _ref2.config;
  return {
    config: config
  };
}))(FactCardsCard);