'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var showFeedback = exports.showFeedback = function showFeedback(obj) {
  var key = obj.dataKey;
  var data = obj.data.data[key];
  var variant = obj.data.variant;
  var oldFeedback = obj.feedback;
  var skipHint = obj.skipHint;

  var feedbackObj = {
    title: '',
    content: '',
    buttonText: '',
    done: false,
    hint: false,
    showFeedback: true
  };

  if (data) {
    var hasHint = false;
    if (!skipHint || skipHint == undefined) {
      hasHint = data.hintText ? data.hintText == '<p><br></p>' ? false : true : false;
    }

    if (obj.correct) {
      feedbackObj.title = 'Goed';
      feedbackObj.content = data.feedbackCorrect || '';
      feedbackObj.buttonText = variant == 'LESSON' ? '' : 'Ga verder';
      feedbackObj.done = true;
    } else {
      if (variant == 'trainer') {
        feedbackObj.done = true;
      } else if (oldFeedback == undefined || !oldFeedback.hint) {
        feedbackObj.title = hasHint ? 'Hint' : 'Helaas';
        feedbackObj.content = hasHint ? data.hintText : data.feedbackInCorrect || '';
        feedbackObj.done = hasHint ? false : true;
        feedbackObj.hint = true;
        if (hasHint) {
          feedbackObj.buttonText = data.buttonText || 'Check mijn antwoord';
        } else {
          feedbackObj.buttonText = variant == 'LESSON' ? '' : 'Ga verder';
        }
      } else {
        feedbackObj.title = 'Helaas';
        feedbackObj.content = data.feedbackInCorrect || '';
        feedbackObj.done = true;
        feedbackObj.hint = true;
        feedbackObj.buttonText = variant == 'LESSON' ? '' : 'Ga verder';
      }
    }
  }

  return feedbackObj;
};

var workformElements = exports.workformElements = function workformElements(arr) {
  var _arr = [];

  if (arr != undefined) {
    arr.forEach(function (e) {
      switch (e.type) {
        case 'BookCheck':
        case 'Case':
        case 'Film':
        case 'Lesson':
        case 'MasterClass':
        case 'EntryTest':
        case 'Trainer':
          _arr.push(e);
          break;
      }
    });
  }

  return _arr;
};

var forProgress = exports.forProgress = function forProgress(arr) {
  var _arr = [];

  if (arr != undefined) {
    arr.forEach(function (e) {
      switch (e.type) {
        case 'BookCheck':
        case 'Case':
        case 'Film':
        case 'Lesson':
        case 'MasterClass':
        case 'EntryTest':
        case 'Trainer':
        case 'Test':
          _arr.push(e);
          break;
      }
    });
  }

  return _arr;
};

var savable = exports.savable = function savable(arr) {
  var _arr = [];

  if (arr != undefined) {
    arr.forEach(function (e) {
      switch (e.type) {
        case 'StatementQuestion':
        case 'ImageAndTextCheckQuestion':
        case 'ImageCheckQuestion':
        case 'TextCheckQuestion':
        case 'OrderQuestion':
        case 'TextMPC':
        case 'WhatIsWhatQuestion':
        case 'ImageMPC':
        case 'ImageAndTextMPC':
          _arr.push(e);
          break;
      }
    });
  }

  return _arr;
};

var isInArray = exports.isInArray = function isInArray(val, arr) {
  var i = false;

  if (arr.indexOf(val) >= 0) i = true;
  return i;
};

var useWhiteOrBlackTextColor = exports.useWhiteOrBlackTextColor = function useWhiteOrBlackTextColor(hex) {
  if (hex && hex.length >= 7) {
    var hexToRGB = function hexToRGB(hex) {
      return ['0x' + hex[1] + hex[2] | 0, '0x' + hex[3] + hex[4] | 0, '0x' + hex[5] + hex[6] | 0];
    };
    var rgb = hexToRGB(hex);
    return (rgb[0] * 299 + rgb[1] * 587 + rgb[1] * 114) / 1000 > 125 ? '#000000' : '#FFFFFF';
  } else {
    return '#FFFFFF';
  }
};

var isTeacher = exports.isTeacher = function isTeacher(user) {
  var isTeacher = false;
  if (!user) {
    return isTeacher;
  }
  if (user.roles && user.roles.length) {
    user.roles.forEach(function (role) {
      if (role.name === 'TEACHER') isTeacher = true;
    });
  }

  return isTeacher;
};

var isManager = exports.isManager = function isManager(user) {
  var isManager = false;
  if (!user) {
    return isManager;
  }
  if (user.roles && user.roles.length) {
    user.roles.forEach(function (role) {
      if (role.name === 'SCHOOL_MANAGER') isManager = true;
    });
  }

  return isManager;
};

var isInArr = function isInArr(item, arr) {
  var isInArray = false;
  arr.forEach(function (i) {
    if (i.id === item) isInArray = true;
  });
  return isInArray;
};

var removedeprocated = function removedeprocated(arr1, arr2) {
  var newArr = [];
  arr1.forEach(function (item) {
    if (item.element && item.element.id) {
      if (isInArr(item.element.id, arr2)) newArr.push(item);
    }
  });

  return newArr;
};

var getPercentage = exports.getPercentage = function getPercentage(results, elements) {
  var _elementResults = forProgress(removedeprocated(results, elements).map(function (item) {
    return _extends({}, item, {
      type: item.element.type
    });
  }));

  var _elements = forProgress(elements);
  var finished = 0;

  _elementResults && _elementResults.forEach(function (r) {
    if (r.finished) finished++;
  });

  var percentage = finished / _elements.length * 100 || 0;
  if (finished === _elements.length) percentage = 100;

  return percentage;
};

var getTestPercentage = exports.getTestPercentage = function getTestPercentage(results, elements) {
  var _elementResults = workformElements(removedeprocated(results, elements).map(function (item) {
    return _extends({}, item, {
      type: item.element.type
    });
  }));

  var _elements = workformElements(elements);
  var finished = 0;

  _elementResults && _elementResults.forEach(function (r) {
    if (r.finished) finished++;
  });

  var percentage = finished / _elements.length * 100 || 0;
  if (finished === _elements.length) percentage = 100;

  return percentage;
};