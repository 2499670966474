import gql from 'graphql-tag';

export const GQL_FETCH_MASTERCLASS = gql`
  query($id: ID!) {
    masterClass(id: $id) {
      id
      type
      introTitle
      introText
      borderColor
      preTitle
      prefixColor
      titleColor
      image {
        id
        url
        thumbnailUrl
      }
      workforms {
        id
        title
        type
        split
      }
      factCards {
        title
        id
        text
        image {
          id
          thumbnailUrl
          url
        }
      }
    }
  }
`;

export const GQL_FETCH_FILM = gql`
  query($id: ID!) {
    film(id: $id) {
      id
      type
      introTitle
      introText
      borderColor
      preTitle
      prefixColor
      titleColor
      image {
        id
        url
        thumbnailUrl
      }
      workforms {
        id
        type
        split
      }
    }
  }
`;

export const GQL_FETCH_GLOBAL_SETTINGS = gql`
  query {
    settings {
      settings {
        name
        value
        file {
          id
          bynderId
          derivative
          url
          thumbnailUrl
          name
        }
      }
    }
  }
`;
