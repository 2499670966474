import gql from 'graphql-tag';

export const GQL_FETCH_BETTING_GAME = gql`
  query($id: ID!) {
    bettingGame(id: $id) {
      id
      type
      introTitle
      introText
      preTitle
      titleColor
      image {
        id
        url
        thumbnailUrl
      }
      workforms {
        id
        type
      }
    }
  }
`;

export const GQL_FETCH_ELEMENT_RESULTS = gql`
  query($box: ID!, $program: ID!, $user: ID!) {
    elementResults(box: $box, program: $program, user: $user) {
      finished
      element {
        id
        type
        title
      }
      correct
    }
  }
`;
