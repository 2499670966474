import React from "react";
import Typography from "@material-ui/core/Typography";
import News from "./News";
import { withStyles } from "@material-ui/core/styles";
import ProgramsBlocks from "./ProgramBlocks";

const styles = theme => ({
  root: {
    padding: "10rem 0",
    backgroundColor: theme.manakin.secondaryBackgroundColor[500],
    [theme.breakpoints.up("sm")]: {
      padding: "12rem 0 7rem"
    },
    [theme.breakpoints.up("md")]: {
      padding: "16rem 0 12rem"
    }
  },
  wrapper: {
    ...theme.manakin.defaultWrapper,
    marginBottom: "3rem",
    [theme.breakpoints.up("sm")]: {
      marginBottom: "4rem"
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: "4rem"
    }
  },
  top: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap"
  },
  news: {
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "50%"
    }
  },
  title: {
    width: "100%",
    marginBottom: "2rem",
    [theme.breakpoints.up("sm")]: {
      width: "50%",
      paddingRight: "3rem"
    }
  },
  bottom: {
    width: "100%",
    height: "100px"
  },
  header: {
    margin: 0
  }
});

const Header = props => {
  const { classes } = props;

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <div className={classes.top}>
          <div className={classes.title}>
            <Typography variant="h2" component="h1" className={classes.header}>
              Welkom bij nascholing
            </Typography>
          </div>
          <div className={classes.news}>
            <News />
          </div>
        </div>
      </div>
      <ProgramsBlocks />
    </div>
  );
};

export default withStyles(styles)(Header);
