import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import ArrowRightAlt from "@material-ui/icons/ArrowRightAlt";
import Done from "@material-ui/icons/Done";
import Typography from '@material-ui/core/Typography';
import Media from "react-media";

const styles = theme => ({
    container: {
        width: '100%',
        maxWidth: '643px',
    },
    containerSmall: {
        width: '100%',
        maxWidth: '444px'
    },
    button: {
        display: 'inline',
        width: '594px',
        height: '80px',
        backgroundColor: '#000000',
        color: '#FFFFFF',
        border: '1px solid #FDC228',
        textAlign: 'left',
        float: 'left',
        marginLeft: '24px'
    },
    buttonMobile: {
        display: 'inline',
        width: '313px',
        height: '80px',
        backgroundColor: '#000000',
        color: '#FFFFFF',
        border: '1px solid #FDC228',
        textAlign: 'left',
        marginTop: '16px',
        marginBottom: '32px !important'
    },
    buttonSmall: {
        display: 'inline',
        width: '388px',
        height: '80px',
        backgroundColor: '#000000',
        color: '#FFFFFF',
        border: '1px solid #FDC228',
        textAlign: 'left',
        float: 'left',
        marginLeft: '24px'
    },
    buttonMarkup: {
        color: '#FFFFFF',
        fontSize: '14px',
        fontWeight: 'bold',
        letterSpacing: '1px',
        lineHeight: '24px',
        marginLeft: '40px'
    },
    imageOut: {
        marginLeft: '8px',
        marginTop: '8px',
        float: 'left',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(1),
        width: '198px',
        height: '198px',
        padding: '0',
        margin: '0',
    },
    imageIn: {
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '100%',
        height: '100%'
    },
    imageOutMobile: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(1),
        width: '164px',
        height: '164px',
        float: 'left'
    },
    imageInMobile: {
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '100%',
        height: '100%'
    },
    imagesMobile: {
        display: 'block',
        margin: '0 auto',
        width: '328px'
    },
    images: {
        display: 'inline-block',
        paddingLeft: theme.spacing(1),
        width: '100%'
    },
    seperator: {
        height: theme.spacing(1)
    },
    imageText: {
        fontSize: '18px',
        fontWeight: 'bold',
        lineHeight: '36px',
        textAlign: 'center',
        marginTop: '-48px',
        userSelect: 'none'
    },
    mobileDone: {
        position: 'absolute',
        marginTop: '-100px',
        marginLeft: '47px',
        width: '50px',
        height: '50px',
        textAlign: 'center',
        lineHeight: '53px',
        backgroundColor: '#FFFFFF'
    },
    mobileDoneIcon: {
        width: '12px',
        height: '12px'
    }
});

class ImageMultipleChoice extends React.Component {
    state = {
        selectedAnswers: new Map()
    };

    handleClick = (id, isMobile) => {
        let element = document.getElementById(id);

        if(isMobile) {
            let elementDone = document.getElementById('done' + id);

            if(element.dataset.clicked === 'true') {
                element.dataset.clicked = false;
                elementDone.style.display = 'none';
                this.state.selectedAnswers.delete(id);
            }
            else {
                element.dataset.clicked = true;
                elementDone.style.display = 'inline-block';
                this.state.selectedAnswers.set(id, true);
            }
        }
        else {
            if(element.dataset.clicked === 'true') {
                element.style.outline = "0px";
                element.dataset.clicked = false;
                this.state.selectedAnswers.delete(id);
            }
            else {
                element.style.outline = "2px solid #000000";
                element.dataset.clicked = true;
                this.state.selectedAnswers.set(id, true);
            }
        }
    };

    handleSubmit = event => {
        let counter = 0;
        let correctAnswers = this.props.questions.filter(e => e.answer === true).length;

        this.props.questions.forEach(question => {
            const element = this.state.selectedAnswers.get(question.id);

            if(element) {
                if(element === this.props.questions.find(e => e.id === question.id).answer) {
                    counter++;
                }
                else {
                    counter--;
                }
            }
        });

        if(counter === correctAnswers) {
            return true; // Correct answers
        }
        else {
            return false; // Incorrect answers
        }
    };

    render() {
        const { classes, buttonText, questions, imageColor } = this.props;
        const { selectedAnswers } = this.state;

        return (
            <div className={questions.length > 4 ? classes.container : classes.containerSmall}>
                
                <Media query="(max-width: 860px)">
                {matches => matches ? (
                    // Mobile
                    <div className={classes.imagesMobile}>
                        {questions.map(e => {
                            return <div className={classes.imageOutMobile} id={e.id} data-clicked="false">
                                <div style={{backgroundImage: `url(${e.image})`}} className={classes.imageInMobile} onClick={() => this.handleClick(e.id, true)}></div>
                                
                                <div id={'done' + e.id} className={classes.mobileDone} onClick={() => this.handleClick(e.id, true)} style={{display: 'none'}}>
                                    <Done className={classes.mobileDoneIcon} />
                                </div>

                                <Typography className={classes.imageText} onClick={() => this.handleClick(e.id, true)} style={{color: imageColor}}>
                                    {e.question}
                                </Typography>
                            </div>
                        })}
                    </div>
                ) : (
                    <div className={classes.images}>
                        {questions.map(e => {
                            return <div className={classes.imageOut} id={e.id} data-clicked="false">
                                <div style={{backgroundImage: `url(${e.image})`}} className={classes.imageIn} onClick={() => this.handleClick(e.id, false)}></div>
                                
                                <Typography className={classes.imageText} onClick={() => this.handleClick(e.id, false)} style={{color: imageColor}}>
                                    {e.question}
                                </Typography>
                            </div>
                        })}
                    </div>
                )}
                </Media>

                <div className={classes.seperator}></div>

                <Media query="(max-width: 860px)">
                {matches => matches ? (
                    // Mobile
                    <div style={{textAlign: 'center'}}>
                        <button className={classes.buttonMobile} onClick={this.handleSubmit}>
                            <div style={{float: 'left'}}>
                                <p className={classes.buttonMarkup}>{buttonText.toUpperCase()}</p>
                            </div>
                            <div style={{float: 'right'}}>
                                <p style={{marginRight: '40px'}}><ArrowRightAlt /></p>
                            </div>
                        </button>
                    </div>
                ) : (
                    <div style={{textAlign: 'right'}}>
                        <button className={questions.length > 4 ? classes.button : classes.buttonSmall} onClick={this.handleSubmit}>
                            <div style={{float: 'left'}}>
                                <p className={classes.buttonMarkup}>{buttonText.toUpperCase()}</p>
                            </div>
                            <div style={{float: 'right'}}>
                                <p style={{marginRight: '40px'}}><ArrowRightAlt /></p>
                            </div>
                        </button>
                    </div>
                )}
                </Media>
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(ImageMultipleChoice);
