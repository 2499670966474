import React from 'react';
import { getAppUser } from '@manakin/authentication/selectors';
import Dialog from '@manakin/core/Dialog';
import MuiDialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { TextField } from '@manakin/core';
import { withForm } from '@manakin/core';
import { compose, withHandlers } from 'recompose';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import {
	GQL_SEND_MESSAGE
} from './graphql';
import {graphql} from 'react-apollo';
import {connect} from 'react-redux';
import {showSnackbarMessage} from '@manakin/core/actions';

const styles = theme => ({
	input: {
		marginBottom: '4rem!important',
		marginTop: '0!important'
	}
})

const form = {
	title: { required: true },
	text: { required: true },
};

class MessageDialog extends React.PureComponent {
	handleSubmit = onClose => {
        const {form, appUser, student} = this.props;
		const {onSubmit} = form;
		if(appUser.id && student) {
			form.onValidate().then(
				() => onSubmit().then(data => {
					if (data) {
						console.log('data', data);
						console.log('props', this.props)
						const obj = {
							...data,
							sender: appUser.id,
							receivers: student,
							send: true
						}
						this.props.onCreate({...obj}).then(onClose);
					}
				}),
				error => this.props.processFailure()
			);
		}
	}

	render() {
		const { form, classes, student = {}, name = null } = this.props;

		return ( 
			<Dialog
				name="appClassMessageDialog"
				render={({ open, onClose }) => (
					<MuiDialog open={open} onClose={onClose} fullWidth>
						<DialogTitle>Bericht versturen naar {name || 'student'}</DialogTitle>
						<DialogContent>
							<TextField
								name="title"
								label="Onderwerp"
								placeholder="Onderwerp van bericht"
								form={form}
								className={classes.input}
							/>
							<TextField
								name="text"
								label="Jouw bericht..."
                                placeholder="bericht"
                                multiline={true}
								form={form}
								className={classes.input}
							/>
						</DialogContent>
						<DialogActions>
							<Button
								onClick={() => this.handleSubmit(onClose)}
								color="primary"
								variant="contained"
							>
								Versturen
							</Button>
							<Button 
								onClick={onClose}
								color='primary'
							>
								Annuleren
							</Button>
						</DialogActions>
					</MuiDialog>
				)}
			/>
		);
	}
}

export default compose(
	withForm(form),
	withStyles(styles),
	graphql(GQL_SEND_MESSAGE),	
    connect(
		state => ({
			appUser: getAppUser(state),
		}),
        dispatch => ({
            processSuccess: () => {
                dispatch(
                    showSnackbarMessage({
                        text: 'Bericht verzonden',
                        variant: 'success'
                    })
                );
            },
            processFailure: () => {
                dispatch(
                    showSnackbarMessage({
                        text: "Bericht verzenden mislukt",
                        variant: "error"
                    })
                );
            },
        })
    ),
    withHandlers({
        onCreate: ({mutate, processSuccess, processFailure}) => (event) => {
            return mutate({
                variables: {
                    ...event
                }
            }).then(result => {
                if (!result.errors) {
                    processSuccess()
                } else {
                    processFailure();
                }
            });
        }
    }),
)(MessageDialog);
