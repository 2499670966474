import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { WorkformLayout, LoadImage, LongArrow } from "@manakin/app-core";
import { FeedbackBox } from "@manakin/app-workforms";
import classNames from "classnames";
import Button from "@material-ui/core/Button";
import { styles } from "./styles";
import { connect } from "react-redux";

class ImageMPC extends React.PureComponent {
  handleClick = index => {
    const done = this.props.data.done ? this.props.data.done : false;
    if (this.props.onChange && !done) this.props.onChange(index);
  };

  handleSubmit = () => {
    if (this.props.onSubmit) this.props.onSubmit();
  };

  render() {
    const {
      classes,
      data,
      onFinished,
      direction = "default",
      disabled = false,
      config = {}
    } = this.props;
    const { literals = {} } = config;
    const { answers = [] } = data;

    return (
      <WorkformLayout
        question={data.question || ""}
        instruction={
          data.introduction || literals.APP_MPC_QUESTION || "Meerkeuze vraag"
        }
        loading={false}
        direction={direction}
        renderAnswers={() => (
          <div className={classes.wrapper}>
            <div className={classes.answersRoot}>
              {answers &&
                answers.map((answer, i) => (
                  <div
                    key={answer.id}
                    className={classNames(
                      classes.card,
                      { [classes.col2]: answers.length <= 4 },
                      { [classes.col3]: answers.length > 4 }
                    )}
                    onClick={() => this.handleClick(i)}
                  >
                    <div className={classes.innerCard}>
                      <div className={classes.image}>
                        <LoadImage
                          src={(answer.image && answer.image.url) || ""}
                          placeholder={
                            (answer.image && answer.image.thumbnailUrl) || ""
                          }
                        />
                      </div>
                      <div
                        className={classNames(classes.checked, {
                          [classes.showIcon]: answer.selected
                        })}
                      />
                    </div>
                  </div>
                ))}
            </div>
            <div className={classes.buttons}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                className={classNames(classes.button, {
                  [classes.hide]: data.feedback || false
                })}
                onClick={this.handleSubmit}
                disabled={disabled}
              >
                {data.buttonText ||
                  literals.APP_CHECK_ANSWER ||
                  "Check mijn antwoord"}
                <LongArrow className={classes.longArrowRoot} />
              </Button>
              {data.feedback && (
                <div
                  className={classNames(classes.feedback, {
                    [classes.activeFeedback]: data.feedback
                  })}
                >
                  <FeedbackBox
                    data={data}
                    onFinished={onFinished}
                    outlined={true}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      />
    );
  }
}

export default compose(
  connect(state => ({ config: state.config })),
  withStyles(styles, { name: "AppImageMPC" })
)(ImageMPC);
