import gql from 'graphql-tag';

export const GQL_FETCH_TAGS = gql`
    query($page: Int, $pagesize: Int, $search: String ) {
      tags(page: $page, pagesize: $pagesize, search: $search) {
        tags {
          id
          name
        }
        count
      }
    }
`;

export const GQL_CREATE_TAG = gql`
    mutation(
        $name: String
    ) {
        createTag(
            input: {
                name: $name
            }
        ) {
            tag {
                id
                name
            }
        }
    }
`;