import { makeStyles, createStyles } from "@material-ui/core/styles";

export default makeStyles(
  theme =>
    createStyles({
      root: {
        backgroundColor: theme.manakin.primaryColor[500],
        height: "100vh",
        minHeight: "600px",
        display: "flex",
        [theme.breakpoints.down("md")]: {
          flexFlow: "row wrap",
          justifyContent: "center"
        }
      },
      logo: {
        position: 'absolute',
        right: '4rem',
        top: '3rem'
      },
      content: {
        width: "100%",
        backgroundImage: "url(" + theme.manakin.loginBackgroundImage + ")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "right center",
        padding: "80px",
        [theme.breakpoints.up("md")]: {
          display: "flex",
          "&>svg": {
            width: "100%",
            height: "100%"
          }
        },
        [theme.breakpoints.down("md")]: {
          position: "absolute",
          opacity: ".1",
          top: 0,
          left: 0,
          height: "100vh",
          width: "100vw",
          padding: "32px"
        }
      },
      visual: {
        ...theme.manakin.visual,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center"
      },
      login: {
        width: "120%",
        opacity: "0",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "center",
        overflow: "hidden",
        position: "relative",
        opacity: 0,
        transform: "translateY(32px)",
        transition: "transform .3s ease, opacity .3s ease",
        [theme.breakpoints.up("md")]: {
          maxWidth: "640px",
          width: "100%",
          display: "flex",
          flexDirection: "column"
        },
        [theme.breakpoints.down("md")]: {
          alignItems: "center"
        }
      },
      mounted: {
        width: "100%",
        opacity: "1",
        display: "flex",
        flexDirection: "column",
        transform: "none",
        alignItems: "center"
      },
      footNote: {
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        bottom: "4.6rem",
        position: "relative",
        marginTop: "5rem",
        [theme.breakpoints.up("md")]: {
          position: "absolute",
          margin: 0,
          left: "50%",
          transform: "translateX(-50%)"
        }
      },
      link: {
        marginTop: theme.spacing(1),
        textDecoration: "none",
        color: "#009AC3",
        cursor: "pointer"
      },
      headerTitle: {
        left: 0,
        fontSize: "3.8rem",
        paddingBottom: 0,
        lineHeight: "4.2rem",
        fontFamily: theme.manakin.logoFont || "'Ubuntu', sans-serif",
        fontWeight: "bold",
        width: "100%",
        maxWidth: 400,
        marginBottom: "1.8rem"
      },
      visualTitle: {
        color: theme.palette.secondary[500],
        fontFamily: theme.manakin.defaultTitleFont,
        fontWeight: "500",
        position: "absolute",
        bottom: "12rem",
        left: "9rem",
        display: "none",
        [theme.breakpoints.up("lg")]: {
          fontSize: "12rem",
          lineHeight: "13rem",
          display: "block"
        }
      },
      footerLogo: {}
    }),
  {
    name: "appLoginHeader"
  }
);
