import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import Button from "@material-ui/core/Button";
import classNames from "classnames";
import { WorkformLayout, AppRadioGroup, LongArrow } from "@manakin/app-core";
import { FeedbackBox } from "@manakin/app-workforms";
import { styles } from "./styles";
import { connect } from "react-redux";

class TextMPC extends React.Component {
  handleChange = event => {
    if (this.props.onChange) this.props.onChange(event);
  };

  handleSubmit = event => {
    const { data } = this.props;
    if (this.props.onSubmit) this.props.onSubmit(data);
  };

  render() {
    const {
      classes,
      data,
      onFinished,
      direction = "default",
      disabled,
      config = {}
    } = this.props;
    const { literals = {} } = config;
    const { answers = [], initialValue = "" } = data;

    return (
      <WorkformLayout
        question={data.question || ""}
        instruction={
          data.introduction || literals.APP_MPC_QUESTION || "Meerkeuze vraag"
        }
        loading={false}
        direction={direction}
        renderAnswers={() => (
          <div className={classes.answers}>
            <AppRadioGroup
              done={data.done || false}
              answers={answers}
              onChange={this.handleChange}
              initialValue={initialValue}
            />

            <div className={classes.buttons}>
              {!data.feedback ? (
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  className={classes.button}
                  onClick={() => this.handleSubmit()}
                  disabled={disabled}
                >
                  {data.buttonText ||
                    literals.APP_CHECK_ANSWER ||
                    "Check mijn antwoord"}
                  <LongArrow className={classes.longArrowRoot} />
                </Button>
              ) : (
                <div
                  className={classNames(classes.feedback, {
                    [classes.activeFeedback]: data.feedback
                  })}
                >
                  <FeedbackBox
                    data={data}
                    onFinished={onFinished}
                    outlined={true}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      />
    );
  }
}

export default compose(
  connect(state => ({ config: state.config })),
  withStyles(styles, { name: "AppTextMPC" })
)(TextMPC);
