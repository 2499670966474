import gql from 'graphql-tag';

export const GQL_FETCH_CMS_FACT_CARD = gql`
  query($id: ID!) {
    factCard(id: $id) {
      id
      title
      image {
        id
        url
      }
      file {
        id
        url
      }
    }
  }
`;
