import React from 'react';
import ChatBubble from './ChatBubble';
import scrollToComponent from 'react-scroll-to-component';

class ChatBubbleContainer extends React.PureComponent {
  componentDidUpdate(prevProps) {
    if (!prevProps.animate && this.props.animate) {
      const { data } = this.props;
      const chatLength = data.bubbles ? data.bubbles.length : 0;
      let characteristic = [];
      data.bubbles &&
        data.bubbles.forEach(item => {
          item.characteristic ? characteristic.push(item.characteristic) : null;
        });

      this.setState({
        characteristic: characteristic
      });

      if (this.props.onNext) {
        scrollToComponent(this.Bubbles, {
          offset: 100,
          align: 'bottom',
          duration: 500
        });
        setTimeout(() => {
          this.props.onNext({ ...this.state });
        }, chatLength * 1000 + 1000);
      }
    }
  }

  render() {
    return (
      <ChatBubble
        {...this.props}
        ref={section => {
          this.Bubbles = section;
        }}
      />
    );
  }
}

export default ChatBubbleContainer;
