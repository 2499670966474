'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var styles = exports.styles = function styles(theme) {
	var _headingContent;

	return {
		root: {
			width: '100%'
		},
		statistics: _defineProperty({
			textAlign: "center",
			marginBottom: '3rem'
		}, theme.breakpoints.up("md"), {
			display: 'flex',
			width: '100%',
			border: '1px solid ' + theme.manakin.defaultBorderColor[500],
			padding: '3.5rem 3rem 4.5rem',
			marginBottom: '4rem'
		}),
		row: _defineProperty({
			display: 'flex',
			width: "100%",
			marginBottom: '1rem',
			flexWrap: 'wrap'
		}, theme.breakpoints.up("md"), {
			margin: 0
		}),
		rowTeacher: _defineProperty({
			width: "100%",
			marginBottom: '1rem'
		}, theme.breakpoints.up("md"), {
			margin: 0,
			display: 'flex'
		}),
		specificStatisticItem: _defineProperty({
			border: '1px solid ' + theme.manakin.defaultBorderColor[500],
			width: '50%',
			justifyContent: 'center',
			alignItems: 'center',
			flexDirection: 'column',
			padding: '1rem 1rem 2rem'

		}, theme.breakpoints.up("md"), _defineProperty({
			border: 0,
			width: 'auto',
			margin: 0,
			padding: '0 2rem'
		}, 'width', '25%')),

		specificStatisticItemTeacher: _defineProperty({
			border: '1px solid ' + theme.manakin.defaultBorderColor[500],
			width: '100%',
			justifyContent: 'center',
			alignItems: 'center',
			flexDirection: 'column',
			padding: '2rem 3rem 2rem',
			marginRight: '.5rem'

		}, theme.breakpoints.up("md"), _defineProperty({
			border: 0,
			width: 'auto',
			margin: 0,
			padding: '0 2rem'
		}, 'width', '33.3%')),

		statisticsTitle: _defineProperty({
			marginBottom: '.5rem'
		}, theme.breakpoints.up("md"), {
			marginBottom: '0'
		}),
		stasticsBody: {
			textAlign: 'center',
			fontFamily: theme.manakin.defaultTitleFont,
			fontSize: '1.4rem',
			lineHeight: '2rem',
			letterSpacing: '.4rem',
			margin: 0
		},
		title: {},
		headingContent: (_headingContent = {}, _defineProperty(_headingContent, theme.breakpoints.up("md"), {
			width: '100%',
			display: 'flex',
			justifyContent: 'space-between'
		}), _defineProperty(_headingContent, '& $title', {
			marginBottom: '2.6rem'
		}), _headingContent),
		link: {
			textDecoration: 'none'
		},
		linkContent: {
			textDecoration: 'none',
			fontWeight: 'bold',
			'&>span': {
				position: 'relative',
				top: '0.6rem',
				left: '4px',
				transition: 'left .2s'
			},
			'&:hover': {
				'&>span': {
					left: '6px'
				}
			}
		}
	};
};