import React from "react";
import { SelectField } from "@manakin/core";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { compose } from "recompose";
import { GQL_FETCH_GROUPS, GQL_FETCH_GROUP } from "../graphql";
import { withApollo } from "react-apollo";

const styles = theme => ({
  root: {
    width: "100%"
  },
  groupElements: {
    "@media (min-width: 700px)": {
      display: "flex",
      justifyContent: "space-between",
      "&>*": {
        flex: "0 0 auto",
        width: "300px"
      }
    }
  },
  paragraph: {
    textAlign: "center",
    width: "100%",
    margin: "0 auto 3em",
    maxWidth: "560px"
  },
  heading: {}
});

class SchoolData extends React.Component {
  state = {
    school: null
  };

  componentDidMount() {
    const { client, form } = this.props;
    const { fields: fieldsData } = form;
    form.onRequiredChange("school", true);

    if (fieldsData.school) {
      this.setState(
        {
          loading: true
        },
        () =>
          client
            .query({
              query: GQL_FETCH_GROUP,
              variables: {
                id: fieldsData.school
              }
            })
            .then(result =>
              this.setState({
                ...(result &&
                  result.data &&
                  result.data.group &&
                  result.data.group.id &&
                  result.data.group.name && {
                    school: {
                      id: result.data.group.id,
                      name: result.data.group.name
                    }
                  }),
                loading: false
              })
            ),
        () => {
          this.setState({
            loading: false
          });
        }
      );
    }
  }

  render() {
    const { classes, form, config = {} } = this.props;
    const { fields: fieldsData } = form;
    const { school, loading } = this.state;

    const selectedSchool = school
      ? school
      : fieldsData.school
      ? { id: fieldsData.school }
      : [];

    return (
      <div className={classes.root}>
        <Typography variant="h2" className={classes.heading}>
          {config.literals.APP_CHOOSE_SCHOOL_TITLE || "School en leerbedrijf"}
        </Typography>
        <Typography
          classes={{ body1: classes.paragraph }}
          variant="body1"
          gutterBottom
        >
          {config.literals.APP_CHOOSE_SCHOOL_INTRO || ""}
        </Typography>
        <SelectField
          isAsync={true}
          alpha={true}
          loading={loading}
          query={GQL_FETCH_GROUPS}
          queryName="groups"
          label={config.literals.APP_SCHOOL || "School"}
          form={form}
          name="school"
          filter={{
            types: ["School"],
            visibleForRegistration: true
          }}
          initialValue={selectedSchool}
        />
      </div>
    );
  }
}

export default compose(
  withStyles(styles, { name: "AppRegistrationSchoolData" }),
  withApollo,
  connect(({ config }) => ({
    config
  }))
)(SchoolData);
