import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';
import renderHTML from 'react-render-html';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';

const styles = theme => ({
  wrapper: {
    ...theme.manakin.smallWrapper,
    maxWidth: '800px'
  },
  content: {
    margin: 0,
    color: theme.manakin.defaultContentColor[500],
    '@global ul': {
      paddingLeft: '2.25rem'
    }
  },
  lightContent: {
    '& $content': {
      color: 'white'
    }
  }
});

class ShortText extends React.PureComponent {
  render() {
    const { classes, data, contentStyle = 'default' } = this.props;

    return (
      <div
        className={classNames(classes.root, {
          [classes.lightContent]: contentStyle === 'light'
        })}
      >
        <div className={classes.wrapper}>
          <Typography
            component="div"
            variant="body1"
            classes={{ body1: classes.content }}
          >
            {renderHTML(data.text || '')}
          </Typography>
        </div>
      </div>
    );
  }
}

export default compose(withStyles(styles, { name: 'AppShortText' }))(ShortText);
