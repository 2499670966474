import { createMuiTheme } from "@material-ui/core/styles";
import { createTheme, createColor } from "@manakin/theme";
import green from "@material-ui/core/colors/green";
import amber from "@material-ui/core/colors/amber";
import blue from "@material-ui/core/colors/blue";
import grey from "@material-ui/core/colors/grey";

const contentWidthM = "1140px";
const contentWidthL = "1600";
const contentWidthS = "748px";
const contentWidthXS = "570px";

//colors
const primaryColor = "#282828";
const secondaryColor = "#EDEDF5";
const primaryThemeColor = "#FFECC2";
const secondaryThemeColor = "#C0E1D7";
const tertiaryColor = "#FFFFFF"; //TODO
const quaternaryColor = "#000000"; //TODO
const senaryColor = ""; //TODO
const septenaryColor = ""; //TODO
const octonaryColor = ""; //TODO
const nonaryColor = ""; //TODO
const denaryColor = ""; //TODO
const otherRedThemeColor = "#F44336";
const otherWhiteThemeColor = "#FFFFFF";
const otherBlackThemeColor = "#000000";
const otherWidgetThemeColor = "#5541C9";
const iconColor = "#242445";
const defaultTextColor = "#6E6E86";
const defaultTitleColor = "#242445";
const saveButton = "#34C279";
const saveButtonColor = "white";
const labelColor = "#242445";
const defaultBorderColor = "#C9CCE0";
const loginBackground = "#fff";
const disabledFieldLabelColor = "rgba(36, 36, 69, 0.5)";
const disabledFieldTextColor = "rgba(110, 110, 134, 0.5)";
const disabledFieldBorderColor = "rgba(151,151,151,0.54)";

//fonts
const primaryFont = "'HK Grotesk', sans-serif";

//margin
const normalMargin = "0 0 44px";
const normalMarginTop = "0";
const normalMarginBottom = "44px";

const theme = createMuiTheme({
  palette: {
    primary: createColor(primaryColor),
    secondary: createColor(secondaryColor),
    success: {
      light: green[200],
      main: green[400],
      dark: green[600],
      contrastText: "#ffffff"
    },
    info: {
      light: blue[300],
      main: blue[400],
      dark: blue[500],
      contrastText: "#ffffff"
    },
    warning: {
      light: amber[400],
      main: amber[600],
      dark: amber[700],
      contrastText: "#ffffff"
    }
  },
  manakin: createTheme({
    sizes: {
      appMenu: 300
    },
    iconColor: {
      color: createColor(iconColor)
    },
    primaryColor: createColor("#fff"),
    primaryThemeColor: createColor(primaryThemeColor),
    secondaryThemeColor: createColor(secondaryThemeColor),
    tertiaryThemeColor: createColor(tertiaryColor),
    quaternaryThemeColor: createColor(quaternaryColor),
    senaryThemeColor: createColor(senaryColor),
    septenaryThemeColor: createColor(septenaryColor),
    octonaryThemeColor: createColor(octonaryColor),
    nonaryThemeColor: createColor(nonaryColor),
    denaryThemeColor: createColor(denaryColor),
    otherRedThemeColor: createColor(otherRedThemeColor),
    otherWhiteThemeColor: createColor(otherWhiteThemeColor),
    otherBlackThemeColor: createColor(otherBlackThemeColor),
    otherDarkBlueThemeColor: createColor(labelColor),
    otherBlueThemeColor: createColor(otherWidgetThemeColor),
    defaultTextColor: createColor(defaultTextColor),
    defaultTitleColor: createColor(defaultTitleColor),
    defaultLabelColor: createColor(labelColor),
    defaultBordercolor: createColor(defaultBorderColor),
    disabledFieldLabelColor: disabledFieldLabelColor,
    disabledFieldTextColor: disabledFieldTextColor,
    disabledFieldBorderColor: disabledFieldBorderColor,
    loginBackground: loginBackground,
    normalMargin: normalMargin,
    defaultContentColor: "black",
    root: {
      width: "100%",
      height: "100%"
    },
    defaultPadding: {
      normalPadding: "25px"
    },
    wrapper: {
      extraSmall: contentWidthXS,
      small: contentWidthS,
      medium: contentWidthM,
      large: contentWidthL
    },
    buttonColors: {
      saveButton: saveButton,
      saveButtonColor: saveButtonColor
    },
    primaryFont: primaryFont
  }),
  overrides: {
    MuiTablePagination: {
      input: {
        padding: "7px 0 0"
      }
    },
    MuiCheckbox: {
      root: {
        color: defaultTextColor
      }
    },
    MuiButton: {
      root: {
        border: "0",
        padding: "12px 20px",
        borderRadius: "22px"
      },
      contained: {
        boxShadow: "none"
      },
      label: {
        fontFamily: primaryFont,
        fontSize: "18px",
        fontWeight: "600"
      }
    },
    MuiInputLabel: {
      root: {
        color: labelColor
      }
    },
    MuiInput: {
      input: {
        padding: "6px 0 12px"
      },
      root: {
        paddingTop: "14px"
      }
    },
    MuiFormLabel: {
      root: {
        fontSize: "24px",
        fontWeight: "600",
        color: labelColor
      }
    },
    MuiFormControl: {
      marginNormal: {
        marginTop: normalMarginTop,
        marginBottom: normalMarginBottom
      }
    },
    MuiFormControlLabel: {
      label: {
        color: labelColor,
        fontSize: "18px"
      }
    },
    MuiFormGroup: {
      root: {
        marginTop: normalMarginTop,
        marginBottom: normalMarginBottom
      }
    },
    MuiTypography: {
      h5: {
        fontFamily: primaryFont,
        fontSize: "20px",
        color: defaultTitleColor,
        fontWeight: 600
      },
      h2: {
        fontFamily: primaryFont,
        fontSize: "30px",
        fontWeight: "bold",
        color: "black"
      },
      h1: {
        fontFamily: primaryFont,
        fontSize: "18px",
        fontWeight: "bold",
        color: "black"
      },
      body1: {
        fontFamily: primaryFont
      },
      h6: {
        fontSize: "20px",
        fontFamily: primaryFont
      }
    },
    MuiSpeedDial: {
      actionsClosed: {
        height: 0
      }
    },
    MuiDialog: {
      paperWidthSm: {
        minWidth: "400px"
      }
    },
    MuiSwitch: {
      switchBase: {
        color: "red",
        "&$checked": {
          color: "green!important"
        }
      }
    },
    MuiDialogTitle: {
      root: {
        backgroundColor: grey["100"],
        borderBottom: "1px solid",
        borderColor: grey["300"] + " !important"
      }
    },
    MuiDialogActions: {
      root: {
        backgroundColor: grey["100"],
        margin: "0",
        borderTop: "1px solid",
        borderColor: grey["300"]
      }
    },
    MuiDialogContent: {
      root: {
        padding: "24px 24px 0",
        minHeight: "400px"
      }
    },
    AuthenticationConfirmReset: {
      root: {
        textAlign: "center"
      }
    }
  }
});

export default theme;
