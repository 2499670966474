import React from "react";
import { graphql } from "react-apollo";
import { compose } from "recompose";
import Trainer from "./Trainer";
import { GQL_FETCH_TRAINER, GQL_FETCH_GLOBAL_SETTINGS } from "./graphql";
import { withWorkforms } from "@manakin/app-core";

class TrainerContainer extends React.PureComponent {
  state = {};

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data.loading && !this.props.data.loading) {
      const { data, workformsData } = this.props;

      const options = {
        showFeedback: false,
        nextButton: false
      };

      if (data.trainer) {
        data.trainer.workforms &&
          workformsData.loadWorkforms({
            ...data.trainer,
            options: options,
            elementId: this.props.match.params.elementId
          });
        workformsData.loadElementResult(this.props.match.params.elementId);
      }
    }
    if (prevProps.SETTINGS.loading && !this.props.SETTINGS.loading) {
      const { SETTINGS } = this.props;
      const { settings = {} } = SETTINGS;

      settings.settings &&
        settings.settings.forEach(item => {
          if (item.name == "trainerPrefix") {
            this.setState({
              prefix: item.value
            });
          }
        });
    }
  }

  handleExit = count => {
    this.props.workformsData
      .saveElement({
        count: count,
        elementId: this.props.match.params.elementId
      })
      .then(result => {
        this.props.history.push(
          `${this.props.match.url}/outro/${this.props.match.params.elementId}/${count}`
        );
      });
  };

  handleClick = () => {
    this.props.history.push(`${this.props.match.url}/questions`);
  };

  render() {
    const { workformsData } = this.props;

    return (
      <React.Fragment>
        <Trainer
          {...workformsData}
          {...this.state}
          onExit={this.handleExit}
          onClick={this.handleClick}
        />
      </React.Fragment>
    );
  }
}

export default compose(
  graphql(GQL_FETCH_TRAINER, {
    options: props => ({ variables: { id: props.match.params.elementId } })
  }),
  graphql(GQL_FETCH_GLOBAL_SETTINGS, {
    name: "SETTINGS"
  }),
  withWorkforms()
)(TrainerContainer);
