import React from "react";
import Drawer from "@material-ui/core/Drawer";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { connect } from "react-redux";
import { toggleAppMenu } from "./actions";
import { isAppMenuOpen } from "./selectors";
import { compose } from "recompose";
import withUi from "../withUi";
import { GQL_FETCH_GLOBAL_SETTINGS } from "./graphql";
import { graphql } from "react-apollo";

const styles = theme => ({
  root: {},
  paperRoot: {
    backgroundColor: theme.palette.secondary["500"],
    maxWidth: 300,
    overflowX: "hidden"
  },
  link: {
    textDecoration: "none",
    color: "inherit"
  },
  logo: {
    width: theme.manakin.sizes.appMenu,
    paddingTop: theme.spacing(1.75),
    paddingBottom: theme.spacing(1.75),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(1),
    fill: theme.manakin.iconColor.dark,
    boxSizing: "border-box",
    marginBottom: -theme.spacing(1),
    height: 48,
    ...theme.mixins.toolbar,
    display: "flex",
    justifyContent: "space-between"
  },
  closeButton: {
    color: theme.manakin.iconColor.dark,
    marginTop: -0.5 * theme.spacing(1)
  },
  svg: {
    height: theme.spacing(4)
  }
});

const mapStateToProps = state => ({
  open: isAppMenuOpen(state)
});

const mapDispatchToProps = dispatch => ({
  onToggleDrawer: () => dispatch(toggleAppMenu())
});

class AppMenu extends React.Component {
  handleClose = () => {
    this.props.onToggleDrawer();
  };

  render() {
    const { classes, open, children, ui, data } = this.props;
    const { settings = {} } = data;

    let file;

    if (settings.settings) {
      settings.settings.forEach(setting => {
        if (setting.name === "cmsLogo") {
          if (setting.file && setting.file.url) {
            file = setting.file.url;
          }
        }
      });
    }

    return (
      <Drawer
        variant="persistent"
        className={classes.root}
        open={open}
        onClose={this.handleClose}
        classes={{ paper: classes.paperRoot }}
      >
        <div className={classes.logo}>
          {file ? (
            <img src={file} className={classes.svg} />
          ) : (
            <ui.Logo
              className={classes.svg}
              preserveAspectRatio="xMaxYMax meet"
            />
          )}

          {open && (
            <IconButton
              className={classes.closeButton}
              onClick={this.handleClose}
              color="inherit"
            >
              <ChevronLeftIcon />
            </IconButton>
          )}
        </div>
        {children}
      </Drawer>
    );
  }
}

export default compose(
  withUi,
  connect(mapStateToProps, mapDispatchToProps),
  graphql(GQL_FETCH_GLOBAL_SETTINGS),
  withStyles(styles)
)(AppMenu);
