import gql from 'graphql-tag';

export const GQL_FETCH_BOOKCHECK = gql`
    query($id: ID!) {
        bookCheck(id: $id) {
            id
            type
            introTitle
            introText
            image {
                id
                url
                thumbnailUrl
            }
            workforms {
                id
                type
            }
            backgroundColor
            borderColor
        }
    }
`;

export const GQL_CREATE_ELEMENT_RESULT = gql`
    mutation(
        $user: ID!
        $program: ID!
        $box: ID!
        $element: ID!
        $finished: Boolean
    ) {
        createElementResult(
            input: {
                user: $user
                program: $program
                box: $box
                element: $element
                finished: $finished
            }
        ) {
            result { 
                finished
            }
        }
    }
`;