import React from "react";
import {withStyles} from "@material-ui/core/styles";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import IconButton from '@material-ui/core/IconButton';
import MoreVerticalIcon from "@material-ui/icons/MoreVert";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {graphql, Query, Mutation} from "react-apollo"

const styles = theme => ({
    root: {},
    tileBar: {
        backgroundColor: "white",
        position: "relative"
    },
    tileBarTitle: {
        color: "black"
    },
    tileBarTitleNoPadding: {
        marginLeft: 0
    }
});

class AssetsViewerItemBar extends React.PureComponent {
    state = {
        anchorEl: null
    };

    handleClick = event => {
        this.setState({anchorEl: event.currentTarget});
    };

    handleClose = () => {
        const {menuClosed} = this.props;
        if (menuClosed) menuClosed();
        this.setState({anchorEl: null});
    };

    handlePickSize = size => {
        if (this.props.onSizePick) this.props.onSizePick(size);
        this.handleClose();
    };

    render() {
        const {classes, asset, id, menuOpen} = this.props;
        const {anchorEl} = this.state;

        return (
            <div className={classes.root}>
                <GridListTileBar
                    title={asset.name}
                    actionIcon={
                        <IconButton
                            id={"icon-button-" + id}
                            className={classes.icon}
                            aria-owns={anchorEl ? "variants-menu" : null}
                            aria-haspopup="true"
                            onClick={this.handleClick}>
                            <MoreVerticalIcon/>
                        </IconButton>
                    }
                    classes={{
                        root: classes.tileBar,
                        title: classes.tileBarTitle,
                        titleWrap: classes.tileBarTitleNoPadding
                    }}/>
                <Menu
                    id="variants-menu"
                    anchorEl={anchorEl || document.getElementById("icon-button-" + id)}
                    open={Boolean(anchorEl) || (menuOpen || false)}
                    anchorOrigin={{vertical: "top", horizontal: "right"}}
                    transformOrigin={{vertical: "top", horizontal: "right"}}
                    onClose={this.handleClose}>
                    {asset.thumbnails && Object.keys(asset.thumbnails).map(size => (
                        <MenuItem key={size} onClick={() => this.handlePickSize(size)}>{size}</MenuItem>
                    ))}
                    <MenuItem onClick={() => this.handlePickSize('none')}>{'geen'}</MenuItem>
                </Menu>
            </div>
        )
    }
}

export default withStyles(styles)(AssetsViewerItemBar)
