import React from 'react';
import ImageCheckQuestion from './ImageCheckQuestion';
import shuffle from 'shuffle-array';

class TextCheckQuestionContainer extends React.PureComponent {

  state = {
    buttonDisabled: false
  };

  componentDidMount() {
    this.setStartState();
  }

  componentDidUpdate(prevProps) {
    if (
      (!prevProps.data.done && this.props.data.done) ||
      prevProps.data.id != this.props.data.id
    ) {
      this.setStartState(true);
      this.setState({ buttonDisabled: false });
    }
  }

  setStartState = second => {
    const { answers = [], shuffleAnswers = true } = this.props.data;
    const { options = {}, data } = this.props;
    const _answers = answers.map(answer => ({
      ...answer,
      selected: answer.selected == undefined ? false : answer.selected
    }));

    if (shuffleAnswers && !second) {
      shuffle(_answers);
    }

    let value = null;
    _answers.forEach(answer => {
      if (answer.selected) value = answer.id;
    });

    this.setState(
      {
        ...this.props.data,
        answers: _answers,
        options: options
      },
      () => {
        this.setState({
          feedback:
            data.finished && options && options.showFeedback ? true : false,
          done: value ? true : false,
          correct: this.checkCorrect()
        });
      }
    );
  };

  checkCorrect = () => {
    let correct = true;
    const { answers } = this.state;

    answers.forEach(answer => {
      if (answer.selected && !answer.correct) correct = false;
      if (!answer.selected && answer.correct) correct = false;
    });

    return correct;
  };

  handleChange = idx => {
    let answers = [...this.state.answers];
    let item = { ...answers[idx] };

    item.selected = !item.selected;
    answers[idx] = item;

    this.setState(
      {
        answers: answers
      },
      () => {
        this.setState({
          correct: this.checkCorrect()
        });
      }
    );
  };

  handleSubmit = () => {
    const { options = {} } = this.props;

    if (options && options.showFeedback) {
      this.setState({ feedback: true, options: options });
    } else {
      this.setState({ buttonDisabled: true });
      this.handleFinish();
    }
  };

  handleFinish = () => {
    this.props.onFinished({
      correct: this.state.correct,
      workform: { ...this.state }
    });
  };

  render() {
    const { answers = [] } = this.state;

    return (
      <ImageCheckQuestion
        data={{ ...this.state }}
        onFinished={this.handleFinish}
        onChange={this.handleChange}
        onSubmit={this.handleSubmit}
        disabled={!answers.some(item => item.selected) || this.state.buttonDisabled}
      />
    );
  }
}

export default TextCheckQuestionContainer;
