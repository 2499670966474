import React from "react";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import CloseIcon from "@material-ui/icons/Close";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import WarningIcon from "@material-ui/icons/Warning";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  root: {},
  success: {
    backgroundColor: theme.palette.success.dark
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  info: {
    backgroundColor: theme.palette.info.dark
  },
  warning: {
    backgroundColor: theme.palette.warning.dark
  },
  icon: {
    fontSize: 22,
    opacity: 0.8,
    marginRight: theme.spacing(2)
  },
  message: {
    display: "flex",
    alignItems: "center",
    fontWeight: 300,
    fontSize: "1.6rem"
  },
  badge: {
    backgroundColor: "rgba(255,255,255,0.3)"
  },
  badgeRoot: {
    marginLeft: 3 * theme.spacing(1)
  }
});

class SnackbarMessage extends React.Component {
  render() {
    const { classes, onClose, message, ...other } = this.props;
    const { variant, text, key, batchCount } = message;

    return (
      <SnackbarContent
        className={[classes.root, classes[variant]].join(" ")}
        message={
          <span className={classes.message}>
            {variant === "success" && (
              <CheckCircleIcon className={classes.icon} />
            )}
            {variant === "error" && <ErrorIcon className={classes.icon} />}
            {variant === "info" && <InfoIcon className={classes.icon} />}
            {variant === "warning" && <WarningIcon className={classes.icon} />}
            {text}
            {batchCount > 1 && (
              <Badge
                classes={{ badge: classes.badge, root: classes.badgeRoot }}
                badgeContent={batchCount}
              ></Badge>
            )}
          </span>
        }
        action={[
          <IconButton key="close" color="inherit" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ]}
        {...other}
      />
    );
  }
}

export default withStyles(styles, { name: "CoreSnackbarMessage" })(
  SnackbarMessage
);
