import React from 'react';
import {
	GQL_LOGOUT
} from './graphql';
import { graphql } from 'react-apollo';
import { compose } from "recompose";
import {withRouter} from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import {Loader} from '@manakin/app-core';
import Cookies from 'js-cookie';

const styles = theme => ({
    root: {
        backgroundColor: theme.manakin.primaryColor[500],
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
})

class Logout extends React.PureComponent {
    componentDidMount() {
        Cookies.set('jwt-check', '1');
        this.props.mutate().then(result => {
            this.props.history.push('/login');   
        }).catch(e => {
            this.props.history.push('/login');   
        })
    }
    render() {
        const {classes} = this.props;
        return (
            <div className={classes.root}>
                <Loader />
            </div>
        )
    }
}

export default compose(
    withRouter,
    withStyles(styles, {name: 'AppLogout'}),
    graphql(GQL_LOGOUT)
)(Logout)