'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Button = require('@material-ui/core/Button');

var _Button2 = _interopRequireDefault(_Button);

var _Dialog = require('@material-ui/core/Dialog');

var _Dialog2 = _interopRequireDefault(_Dialog);

var _DialogActions = require('@material-ui/core/DialogActions');

var _DialogActions2 = _interopRequireDefault(_DialogActions);

var _DialogContent = require('@material-ui/core/DialogContent');

var _DialogContent2 = _interopRequireDefault(_DialogContent);

var _DialogContentText = require('@material-ui/core/DialogContentText');

var _DialogContentText2 = _interopRequireDefault(_DialogContentText);

var _DialogTitle = require('@material-ui/core/DialogTitle');

var _DialogTitle2 = _interopRequireDefault(_DialogTitle);

var _styles = require('@material-ui/core/styles');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var styles = function styles(theme) {
	return {
		paperRoot: {
			minWidth: '300px',
			padding: '2rem'
		}
	};
};

var AppDialog = function (_React$PureComponent) {
	_inherits(AppDialog, _React$PureComponent);

	function AppDialog() {
		var _ref;

		var _temp, _this, _ret;

		_classCallCheck(this, AppDialog);

		for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
			args[_key] = arguments[_key];
		}

		return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = AppDialog.__proto__ || Object.getPrototypeOf(AppDialog)).call.apply(_ref, [this].concat(args))), _this), _this.handleClose = function () {
			_this.props.onClose();
		}, _temp), _possibleConstructorReturn(_this, _ret);
	}

	_createClass(AppDialog, [{
		key: 'render',
		value: function render() {
			var _props = this.props,
			    classes = _props.classes,
			    title = _props.title,
			    content = _props.content,
			    _props$buttonText = _props.buttonText,
			    buttonText = _props$buttonText === undefined ? 'Verder' : _props$buttonText,
			    _props$open = _props.open,
			    open = _props$open === undefined ? false : _props$open;

			return _react2.default.createElement(
				'div',
				null,
				_react2.default.createElement(
					_Dialog2.default,
					{
						open: open,
						onClose: this.handleClose,
						'aria-labelledby': 'alert-dialog-title',
						'aria-describedby': 'alert-dialog-description',
						classes: { paper: classes.paperRoot }
					},
					_react2.default.createElement(
						_DialogTitle2.default,
						{ id: 'alert-dialog-title' },
						title
					),
					_react2.default.createElement(
						_DialogContent2.default,
						null,
						_react2.default.createElement(
							_DialogContentText2.default,
							{ id: 'alert-dialog-description' },
							content
						)
					),
					_react2.default.createElement(
						_DialogActions2.default,
						null,
						_react2.default.createElement(
							_Button2.default,
							{ onClick: this.handleClose, color: 'primary' },
							buttonText
						)
					)
				)
			);
		}
	}]);

	return AppDialog;
}(_react2.default.PureComponent);

exports.default = (0, _styles.withStyles)(styles)(AppDialog);