'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styles = require('@material-ui/core/styles');

var _appCore = require('@manakin/app-core');

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var styles = function styles(theme) {
  return {
    root: {
      width: '100%',
      opacity: 0,
      transform: 'translateX(-20px)'
    },
    show: {
      opacity: 1,
      transform: 'translateX(0)'
    },
    disabled: {
      pointerEvents: 'none',
      opacity: '.3'
    }
  };
};

var VideoCard = function VideoCard(props) {
  var _classNames;

  var classes = props.classes,
      idx = props.idx,
      _props$show = props.show,
      show = _props$show === undefined ? true : _props$show,
      _props$disabled = props.disabled,
      disabled = _props$disabled === undefined ? false : _props$disabled,
      _props$bridgeVideo = props.bridgeVideo,
      bridgeVideo = _props$bridgeVideo === undefined ? '' : _props$bridgeVideo;


  var handleClick = function handleClick() {
    if (disabled && props.onDisabledClick) {
      props.onDisabledClick();
    }
  };

  return _react2.default.createElement(
    'div',
    { onClick: handleClick },
    _react2.default.createElement(
      'div',
      {
        className: (0, _classnames2.default)(classes.root, (_classNames = {}, _defineProperty(_classNames, classes.show, show), _defineProperty(_classNames, classes.disabled, disabled), _classNames)),
        style: {
          transition: 'transform .5s ' + (idx + 1) + 's, opacity .5s ' + (idx + 1) + 's'
        }
      },
      _react2.default.createElement(_appCore.VideoPlayer, { videoId: bridgeVideo, playing: false })
    )
  );
};

exports.default = (0, _styles.withStyles)(styles, { name: 'AppVideoCard' })(VideoCard);