import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Media from "react-media";

const styles = theme => ({
    container: {
        width: '100%',
        maxWidth: '750px',
        border: '1px solid #E6E6E6',
        userSelect: 'none'
    },
    question: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '290px',
        backgroundColor: '#FFECC2',
        backgroundSize: 'cover',
        backgroundPosition: 'center'
    },
    questionMobile: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        backgroundColor: '#FFECC2',
        backgroundSize: 'cover',
        backgroundPosition: 'center'
    },
    questionText: {
        width: '318px',
        fontFamily: 'Zilla Slab',
        fontSize: '22px',
        fontWeight: '600',
        lineHeight: '40px',
        textAlign: 'center'
    },
    questionTextMobile: {
        marginTop: '66px',
        fontFamily: 'Zilla Slab',
        fontSize: '48px',
        textAlign: 'left',
        padding: '16px'
    },
    progress: {
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '80px',
        height: '80px',
        backgroundColor: 'lightblue',
        marginLeft: '670px',
        marginBottom: '211px',
        backgroundColor: '#FFFFFF'
    },
    progressMobile:{
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '80px',
        height: '80px',
        backgroundColor: 'lightblue',
        backgroundColor: '#FFFFFF'
    },
    progressText: {
        fontSize: '18px',
        fontWeight: 'bold',
        letterSpacing: '2px',
        lineHeight: '20px',
        textAlign: 'center'
    },
    buttonDiv: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    buttonDivMobile: {
        display: 'inline-block',
        textAlign: 'center',
        backgroundColor: '#FFECC2',
        width: '100%'
    },
    button: {
        height: '100px',
        width: '300px',
        fontSize: '14px',
        fontWeight: 'bold',
        letterSpacing: '4px',
        lineHeight: '20px',
        textAlign: 'center',
        textTransform: 'uppercase',
        marginTop: '50px',
        marginBottom: '51px',
        backgroundColor: 'transparent',
        border: '1px solid #000000'
    },
    buttonMobile: {
        height: '100px',
        width: '90%',
        fontSize: '14px',
        fontWeight: 'bold',
        letterSpacing: '4px',
        lineHeight: '20px',
        textAlign: 'center',
        textTransform: 'uppercase',
        backgroundColor: 'transparent',
        border: '1px solid #000000',
        margin: '16px'
    }
});

class IndicationQuestion extends React.Component {
    state = {
        questionCounter: 1
    };

    handleClick = answer => {
        if(this.props.questions.length > this.state.questionCounter) {
            this.setState({
                questionCounter: this.state.questionCounter + 1
            });
        }

        if(answer === this.props.questions[this.state.questionCounter - 1].answer) {
            return true; // indicatie
        }
        else {
            return false; // contra-indicatie
        }
    };

    render() {
        const { classes, image, questions } = this.props;
        const { questionCounter, counterLeftMargin } = this.state;

        const question = questions[questionCounter - 1];

        return (
            <div className={classes.container}>
                <Media query="(max-width: 750px)">
                {matches => matches ? (
                    <div id={'containerWidth'}>
                        <div className={classes.progressMobile} style={{right: '0'}}>
                            <Typography className={classes.progressText}>
                                {questionCounter}/{questions.length}
                            </Typography>
                        </div>
                        <div className={classes.questionMobile} style={{
                            backgroundImage: `url(${question.image})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            height: (question.image ? '384px' : 'auto')
                        }}>
                            {question.text &&
                                <Typography className={classes.questionTextMobile}>
                                    &#8220;{question.text}&#8221;
                                </Typography>
                            }
                        </div>
                    </div>
                ) : (
                    <div className={classes.question} style={{backgroundImage: `url(${question.image})`}}>
                        {question.text &&
                            <Typography className={classes.questionText}>
                                &#8220;{question.text}&#8221;
                            </Typography>
                        }

                        <div className={classes.progress}>
                            <Typography className={classes.progressText}>
                                {questionCounter}/{questions.length}
                            </Typography>
                        </div>
                    </div>
                )}
                </Media>

                <Media query="(max-width: 750px)">
                {matches => matches ? (
                    <div className={classes.buttonDivMobile}>
                        <button onClick={() => this.handleClick(false)} className={classes.buttonMobile}>Contra-Indicatie</button>
                        <button onClick={() => this.handleClick(true)} className={classes.buttonMobile}>Indicatie</button>
                    </div>
                ): (
                    <div className={classes.buttonDiv}>
                        <button onClick={() => this.handleClick(false)} className={classes.button}>Contra-Indicatie</button>
                        <button onClick={() => this.handleClick(true)} className={classes.button} style={{marginLeft: '50px'}}>Indicatie</button>
                    </div>
                )}
                </Media>
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(IndicationQuestion);
