import gql from 'graphql-tag';

export const GQL_FETCH_SCENE = gql`
    query($id: ID!) {
        scene(id: $id) {
            id
            video
        }
    }
`;
