import React from "react";
import { ElementOverlay, LoadImage, BestTimes } from "@manakin/app-core";
import { compose } from "recompose";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import scrollToComponent from "react-scroll-to-component";
import ArrowDropDownCircle from "@material-ui/icons/ArrowDropDownCircle";
import { connect } from "react-redux";
import { styles } from "./styles";
import classNames from "classnames";

class Outro extends React.PureComponent {
  state = {
    disabled: false
  };

  handleClick = () => {
    this.setState({ disabled: true });
    if (this.props.onClick) this.props.onClick();
  };

  handleScrollClick = () => {
    scrollToComponent(this.Header, { offset: 70, align: "top", duration: 500 });
  };

  render() {
    const {
      data,
      classes,
      match = {},
      config,
      controls = true,
      preview = false
    } = this.props;
    const { literals = {} } = config;
    const { disabled } = this.state;
    const imageUrl = data.image ? data.image.url : null;
    const placeHolderUrl = data.image ? data.image.thumbnailUrl : null;
    const elementId = match.params ? match.params.elementId : null;
    const trainerConfig = (config.pages && config.pages.trainer) || {};
    const showType =
      trainerConfig.showTrainerType != undefined
        ? trainerConfig.showTrainerType
        : true;

    return (
      <ElementOverlay
        title={showType ? data.preTitle || "Trainer" : ""}
        fullWidth={true}
        controls={controls}
      >
        <div className={classes.root}>
          <div className={classes.header}>
            <LoadImage
              src={imageUrl}
              placeholder={placeHolderUrl}
              withOverlay={true}
            />
            <div className={classes.wrapper}>
              <div className={classes.content}>
                <Typography
                  component="div"
                  variant="body1"
                  className={classNames(
                    classes.body,
                    {
                      [classes.secondaryTitleColor]:
                        data.titleColor == "SECONDARY"
                    },
                    {
                      [classes.primaryTitleColor]: data.titleColor == "PRIMARY"
                    }
                  )}
                >
                  {!config.general.hideTimer && match.params && (
                    <div>
                      {literals.YOUR_TIME || "Je tijd is"}{" "}
                      <span className={classes.bold}>
                        {moment()
                          .minute(0)
                          .second(match.params.count)
                          .format("mm:ss")}
                      </span>
                    </div>
                  )}
                </Typography>
                <div className={classes.title}>
                  <Typography
                    component="h1"
                    variant="h1"
                    className={classNames(
                      classes.titleRoot,
                      {
                        [classes.secondaryTitleColor]:
                          data.titleColor == "SECONDARY"
                      },
                      {
                        [classes.primaryTitleColor]:
                          data.titleColor == "PRIMARY"
                      }
                    )}
                  >
                    {data.introTitle || ""}
                  </Typography>
                </div>
                <Button
                  disabled={disabled}
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={this.handleClick}
                >
                  {literals.APP_TRY_AGAIN || "Probeer nog een keer"}
                </Button>
              </div>
            </div>
            {!trainerConfig.hideScores && (
              <Typography
                component="div"
                variant="body1"
                classes={{ body1: classes.topScores }}
                onClick={this.handleScrollClick}
                ref={section => {
                  this.Header = section;
                }}
              >
                <ArrowDropDownCircle className={classes.icon} />{" "}
                {literals.APP_SEE_SCORES || "Bekijk topScores"}
              </Typography>
            )}
          </div>
          {!preview && !trainerConfig.hideScores && (
            <BestTimes elementId={elementId} />
          )}
        </div>
      </ElementOverlay>
    );
  }
}

export default compose(
  withStyles(styles, { name: "AppFirstPage" }),
  connect(({ config }) => ({
    config
  }))
)(Outro);
