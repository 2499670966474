'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styles = require('@material-ui/core/styles');

var _Typography = require('@material-ui/core/Typography');

var _Typography2 = _interopRequireDefault(_Typography);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _recompose = require('recompose');

var _Item = require('./Item');

var _Item2 = _interopRequireDefault(_Item);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var styles = function styles(theme) {
  return {
    title: _defineProperty({
      marginBottom: '2rem'
    }, theme.breakpoints.up('md'), {
      marginBottom: '4.2rem'
    }),
    root: _defineProperty({
      width: '100%',
      maxWidth: '30rem',
      marginRight: theme.spacing(4),
      marginBottom: theme.spacing(4)
    }, theme.breakpoints.up('md'), {
      marginRight: '15rem'
    }),
    last: {
      marginRight: 0
    }
  };
};

var ResultItem = function ResultItem(props) {
  var classes = props.classes,
      title = props.title,
      results = props.results,
      _props$last = props.last,
      last = _props$last === undefined ? false : _props$last;


  var handleClick = function handleClick(result) {
    if (props.onClick) props.onClick(result);
  };

  return _react2.default.createElement(
    'div',
    { className: (0, _classnames2.default)(classes.root, _defineProperty({}, classes.last, last)) },
    _react2.default.createElement(
      _Typography2.default,
      { variant: 'h6', classes: { h6: classes.title } },
      title || ''
    ),
    results && results.splice(0, 10).map(function (result, idx) {
      return _react2.default.createElement(_Item2.default, { key: idx, onClick: handleClick, result: result, idx: idx });
    })
  );
};

exports.default = (0, _recompose.compose)((0, _styles.withStyles)(styles))(ResultItem);