import React from 'react';
import Dialog from '@manakin/core/Dialog';
import MuiDialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { selectGroup } from '@manakin/app-core/GroupsDropdown/actions';
import { TextField, MultiSelect, SelectField } from '@manakin/core';
import { withForm } from '@manakin/core';
import { compose, withHandlers } from 'recompose';
import {
  GQL_FETCH_SCHOOLS,
  GQL_CREATE_SCHOOLCLASS,
  GQL_UPDATE_SCHOOL,
  GQL_FETCH_SCHOOL
} from '../graphql';
import { graphql, withApollo } from 'react-apollo';
import { getGroup } from '@manakin/app-core/GroupsDropdown/selectors';
import { getAppUser } from '@manakin/authentication/selectors';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { showSnackbarMessage } from '@manakin/core/actions';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  input: {
    marginBottom: '4rem!important',
    marginTop: '0!important'
  }
});

const form = {
  name: { required: true },
  school: { required: true }
};

class CreateDialog extends React.Component {
  state = {
    students: [],
    teachers: [],
    schools: [],
    products: []
  };

  fetchSchool = id => {
    return this.props.client.query({
      query: GQL_FETCH_SCHOOL,
      variables: {
        id: id
      }
    });
  };

  fetchData = groups => {
    let idx = 0;
    let obj = {
      schools: []
    };

    return new Promise(resolve => {
      groups.forEach(item => {
        this.fetchSchool(item.id).then(result => {
          idx++;
          if (result.data.school) {
            obj.schools = [
              ...obj.schools,
              { id: result.data.school.id, name: result.data.school.name }
            ];
          }
          idx >= groups.length && resolve(obj);
        });
      });
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.SCHOOLS.loading && !this.props.SCHOOLS.loading) {
      const { SCHOOLS } = this.props;
      const { groups } = SCHOOLS;

      if (groups.groups) {
        this.fetchData(groups.groups).then(r => {
          this.setState({
            schools: [...r.schools]
          });
        });
      }
    }
    if (prevProps.form.fields.school != this.props.form.fields.school) {
      if (this.props.form.fields.school) {
        this.fetchSchool(this.props.form.fields.school).then(result => {
          if (result.data.school) {
            const { school = {} } = result.data;
            const { students = [] } = school;
            let arr = []
            let newStudents = []

            if (school.classes && school.classes.length) {
              school.classes.forEach(sc => {
                sc.students && sc.students.forEach(student => {
                  if (students.some(i => i.id === student.id)) {
                    arr.push({ id: student.id })
                  }
                })
              })
            }
            students.forEach(s => {
              if (!arr.some(i => i.id == s.id)) {
                newStudents.push(s)
              }
            })


            let obj = {
              products: [],
              students: [],
              managers: []
            };
            let teachers = [];

            obj.products = [...obj.products, ...result.data.school.products];
            obj.students = [...obj.students, ...newStudents];
            obj.managers = [...obj.managers, ...result.data.school.managers];

            obj.managers &&
              obj.managers.forEach(manager => {
                manager.roles.forEach(i => {
                  if (i.name == 'TEACHER') teachers.push(manager);
                });
              });

            this.props.form.onFieldChange({
              key: 'students',
              value: ''
            });

            this.setState({
              students: [...obj.students],
              products: [...obj.products],
              teachers: [...teachers]
            });
          }
        });
      }
    }
  }

  handleSubmit = onClose => event => {
    const { form, onCreate } = this.props;
    const { onSubmit } = form;
    form.onValidate().then(() =>
      onSubmit().then(data => {
        const createStudents = data.students || [];
        onCreate({
          ...data,
          schools: [data.school],
          students: [...createStudents]
        }).then(() => {
          onClose();
          setTimeout(() => {
            location.reload();
          }, 500);
        });
      })
    );
  };

  render() {
    const { form, classes } = this.props;
    const { students, teachers, schools, products } = this.state;
    return (
      <Dialog
        name="appClassCreateDialog"
        render={({ open, onClose }) => (
          <MuiDialog open={open} onClose={onClose} fullWidth>
            <DialogTitle>Maak een klas aan</DialogTitle>
            <DialogContent>
              <TextField
                name="name"
                label="Naam"
                placeholder="Naam van de klas"
                form={form}
                className={classes.input}
              />
              <SelectField
                options={schools}
                label="School"
                placeholder={'Kies een school'}
                form={form}
                name="school"
              />
              {form.fields.school && (
                <React.Fragment>
                  <SelectField
                    options={products}
                    label="Product"
                    placeholder={'Kies een products'}
                    form={form}
                    name="product"
                  />
                  <MultiSelect
                    options={students}
                    label="Studenten toevoegen"
                    placeholder={'Kies studenten'}
                    form={form}
                    name="students"
                  />
                  <MultiSelect
                    options={teachers}
                    label="Docenten toevoegen"
                    placeholder={'Kies docenten'}
                    form={form}
                    name="teachers"
                  />
                </React.Fragment>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                onClick={this.handleSubmit(onClose)}
                color="primary"
                variant="contained"
              >
                Aanmaken
              </Button>
              <Button onClick={onClose} color="primary">
                Annuleren
              </Button>
            </DialogActions>
          </MuiDialog>
        )}
      />
    );
  }
}

export default compose(
  withForm(form),
  withStyles(styles),
  withApollo,
  connect(
    state => ({
      group: getGroup(state),
      appUser: getAppUser(state)
    }),
    dispatch => ({
      selectGroup: group => dispatch(selectGroup(group)),
      processSuccess: result => {
        dispatch(
          showSnackbarMessage({
            text: 'Klas aangemaakt.',
            variant: 'success'
          })
        );
      },
      processFailure: () => {
        dispatch(
          showSnackbarMessage({
            text: 'Klas aanmaken mislukt',
            variant: 'error'
          })
        );
      }
    })
  ),

  graphql(GQL_CREATE_SCHOOLCLASS, { name: 'createSchoolClass' }),
  graphql(GQL_UPDATE_SCHOOL, { name: 'updateSchool' }),
  graphql(GQL_FETCH_SCHOOLS, {
    name: 'SCHOOLS',
    options: props => ({
      variables: {
        filter: {
          types: ['School'],
          manager: props.appUser.id
        }
      }
    })
  }),
  withHandlers({
    onCreate: ({
      createSchoolClass,
      processSuccess,
      processFailure,
      selectGroup
    }) => event => {
      return createSchoolClass({
        variables: {
          ...event
        }
      }).then(result => {
        if (!result.errors) {
          if (result.data.createSchoolClass.group && result.data.createSchoolClass.group.id) {
            selectGroup(result.data.createSchoolClass.group.id)
          }
          processSuccess(result);
        } else {
          processFailure();
        }
      });
    },
  })
)(CreateDialog);
