export const SHOW_SNACKBAR_MESSAGE = '@manakin/core/SHOW_SNACKBAR_MESSAGE';
export const PROCESS_SNACKBAR_MESSAGE =
    '@manakin/core/PROCESS_SNACKBAR_MESSAGE';
export const CLOSE_SNACKBAR = '@manakin/core/CLOSE_SNACKBAR';

export function showSnackbarMessage(message) {
    return {
        type: SHOW_SNACKBAR_MESSAGE,
        payload: { message: { ...message, key: new Date().getTime(), batchCount: 1 } }
    };
}

export function processSnackbarMessage() {
    return {
        type: PROCESS_SNACKBAR_MESSAGE,
        payload: {}
    };
}

export function closeSnackbar() {
    return {
        type: CLOSE_SNACKBAR,
        payload: {}
    };
}
