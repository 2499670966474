export const styles = theme => ({
  emptyRoot: {
    padding: '100px',
    textAlign: 'center'
  },
  wrapper: {
    ...theme.manakin.defaultWrapper
  },
  list: {
    borderTop: `1px solid ${theme.manakin.defaultBorderColor[500]}`
  },
  amount: {
    padding: '3rem 0',
    width: '100%',
    borderBottom: `1px solid ${theme.manakin.defaultBorderColor[900]}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.up('md')]: {
      padding: '3rem 0 4rem'
    }
  },
  students: {
    margin: 0
  },
  schoolClasses: {
    padding: '4rem 0 10rem'
  },
  button: {
    marginRight: '2rem',
    marginBottom: '6rem'
  },
  licenseName: {
    ...theme.manakin.defaultWrapper,
    fontFamily: theme.manakin.defaultTitleFont,
    color: 'black',
    fontSize: '1.4rem',
    letterSpacing: '.17rem',
    fontWeight: 'bold',
    textAlign: 'right'
  },
  topBar: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    cursor: 'pointer',
    transition: 'color .3s',
    color: theme.manakin.defaultContentColor,
    '&:hover': {
      color: theme.manakin.defaultContentHover[500]
    },
    [theme.breakpoints.up('md')]: {
      margin: '8px 24px 0 0'
    }
  },
  sortIcon: {
    width: '20px',
    height: '20px',
    opacity: 1
  },
  reportSelect: {
    backgroundColor: theme.manakin.secondaryTypeColor[500],
    color: theme.manakin.primaryTypeColor[500]
  },
  reportSelectInput: {
    padding:
      theme.spacing(1.5) +
      'px ' +
      theme.spacing(6) +
      'px ' +
      theme.spacing(1.5) +
      'px ' +
      theme.spacing(2) +
      'px',
    minHeight: 0,
    fontFamily: theme.manakin.defaultTitleFont,
    fontSize: '12px',
    lineHeight: 'normal',
    letterSpacing: '4px'
  },
  dropdownIcon: {
    color: 'white',
    right: theme.spacing(2)
  }
});
