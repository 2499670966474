import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { withStyles } from '@material-ui/core/styles';
import createUuid from 'uuid/v4';
import classNames from 'classnames';
import { Dropable } from '@manakin/core';
import Button from '@material-ui/core/Button';
import { styles } from './styles';
import Checkbox from '@material-ui/core/Checkbox';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import RedirectIcon from '@manakin/core/icons/Redirect';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from '@manakin/core/icons/Menu';
import Typography from '@material-ui/core/Typography';
import MoveIcon from '@material-ui/icons/DragHandle';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

const ProgramCard = props => {
  const {
    index,
    provided,
    classes,
    snapshot,
    item,
    style,
    grouping = false,
    checkedItems = [],
    readOnly = false,
    unGrouping: _unGrouping,
    config = {}
  } = props;
  const { literals = {} } = config;
  const idx = item.id ? item.id + 1 : item[0].id;
  const [checked, isChecked] = useState(false);
  const [unGrouping, setIsUngrouping] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  //effects
  useEffect(() => {
    if (snapshot.isDraggingOver) {
      if (props.onCheckboxReset) props.onCheckboxReset();
      isChecked(false);
    }
  }, [props.snapshot]);

  useEffect(() => {
    isChecked(checkedItems[index] || false);
  }, [props.checkedItems]);

  //functions
  const handleUngroupClick = index => {
    if (props.onUnGrouping) props.onUnGrouping(index, !unGrouping);
    setIsUngrouping(!unGrouping);
  };

  const handleChange = index => {
    if (props.onCheckboxChange) props.onCheckboxChange(index, !checked);
    isChecked(!checked);
  };

  const handleUnGroupCheckboxChange = index => {
    if (props.onCheckboxChange) props.onCheckboxChange(index, !checked);
    isChecked(!checked);
  };

  const handleUnGrouping = (index, checked) => {
    if (props.onUnGroupingChange) props.onUnGroupingChange(index, checked);
  };

  const handleRedirect = () => {
    const idx = item.id || item[0].id || false;
    if (idx) props.history.push(`/boxes/${idx}`);
  };

  const handleRemove = () => {
    setAnchorEl(null);
    const idx = item.id || item[0].id || false;
    if (props.onRemove) props.onRemove(index, idx);
  };

  const handleRemoveAlt = (index, idx) => {
    props.onRemoveAlt(index, idx);
  };

  let image = '/cms/images/dummy-image.png';
  if (item) {
    image = item.image ? item.image.url : '/cms/images/dummy-image.png';
  }
  if (item[0]) {
    image = item[0].image ? item[0].image.url : '/cms/images/dummy-image.png';
  }
  return (
    <Draggable draggableId={idx} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          className={classNames(classes.root, {
            [classes.alt]: style == 'programItem',
            [classes.container]: item.length > 1,
            [classes.grouping]: grouping
          })}
        >
          {item.length > 1 && (
            <div>
              <div className={classes.buttonContainer}>
                <Button
                  className={classes.groupingButton}
                  onClick={() => handleUngroupClick(index)}
                >
                  {unGrouping
                    ? literals.CMS_SAVE_DISCONNECT || 'Loskoppelen opslaan'
                    : literals.CMS_DISCONNECT || 'Loskoppelen'}
                </Button>
                <div
                  {...provided.dragHandleProps}
                  className={classes.moveButtonContainer}
                >
                  <div className={classes.moveButton}>
                    <MoveIcon className={classes.menuIcon} />
                  </div>
                </div>
              </div>
              <Dropable
                items={item}
                style="programItem"
                type={'innerDrop'}
                dropId={idx}
                unGrouping={unGrouping}
                onHandleUnGrouping={handleUnGrouping}
                onRemoveAlt={handleRemoveAlt}
              />
            </div>
          )}
          {(item.length <= 1 || item.id != undefined) && (
            <div
              className={classNames(classes.cardRoot, {
                [classes.cardDrag]: snapshot.isDraggingOver
              })}
            >
              {grouping && (
                <Checkbox
                  color="primary"
                  checked={checked}
                  onChange={() => handleChange(index)}
                  value={checked}
                  name="checkbox"
                />
              )}
              {_unGrouping && (
                <Checkbox
                  color="primary"
                  checked={checked}
                  onChange={() => handleUnGroupCheckboxChange(index)}
                  value={checked}
                  name="checkbox"
                />
              )}
              <Card className={classes.card}>
                <img
                  className={classes.thumbnail}
                  src={image || '/cms/images/dummy-image.png'}
                />
                <div className={classes.cardContentContainer}>
                  <CardHeader
                    className={classes.cardHeader}
                    action={
                      <div className={classes.icons}>
                        <IconButton onClick={handleRedirect}>
                          <RedirectIcon />
                        </IconButton>
                        {!readOnly && (
                          <div>
                            <IconButton
                              onClick={event =>
                                setAnchorEl(event.currentTarget)
                              }
                              aria-owns={anchorEl ? 'simple-menu' : null}
                              aria-haspopup="true"
                            >
                              <MenuIcon />
                            </IconButton>

                            <Menu
                              id="simple-menu"
                              anchorEl={anchorEl}
                              open={Boolean(anchorEl)}
                              onClose={event => setAnchorEl(null)}
                            >
                              <MenuItem onClick={handleRemove}>
                                {literals.CMS_DELETE || 'Verwijderen'}
                              </MenuItem>
                            </Menu>
                          </div>
                        )}
                        <div
                          {...provided.dragHandleProps}
                          className={classes.moveButtonContainer}
                        >
                          <div className={classes.moveButton}>
                            <MoveIcon className={classes.menuIcon} />
                          </div>
                        </div>
                      </div>
                    }
                    subheader="Box"
                  />
                  <CardContent className={classes.cardContent}>
                    <Typography
                      className={classNames(
                        classes.cardText,
                        classes.cardTitle
                      )}
                      variant="h5"
                    >
                      {item.name || item[0].name || ''}
                    </Typography>
                    <Typography
                      className={classNames(
                        classes.cardText,
                        classes.cardDescription
                      )}
                    >
                      {(item && item.description) ||
                        (item[0] && item[0].description) ||
                        ''}
                    </Typography>
                  </CardContent>
                </div>
              </Card>
            </div>
          )}
        </div>
      )}
    </Draggable>
  );
};

export default compose(
  connect(state => ({ config: state.config })),
  withStyles(styles),
  withRouter
)(ProgramCard);
