import React, { useState, useEffect } from "react";
import { DefaultFrontPage, DefaultResultsPage } from "../DefaultPages";
import {
  GQL_FETCH_TEST,
  GQL_UPDATE_BOX_RESULTS,
  GQL_FETCH_GLOBAL_SETTINGS,
  GQL_FETCH_BOX_RESULTS,
  GQL_FETCH_BOX,
  GQL_FETCH_APP_USER
} from "./graphql";
import { graphql } from "react-apollo";
import { connect } from "react-redux";
import { getProgram } from "@manakin/app-core/ProgramsDropdown/selectors";
import { getAppUser } from "@manakin/authentication/selectors";
import { getBoxId } from "@manakin/app-core/Boxes/selectors";
import { withWorkforms, Loader } from "@manakin/app-core";
import { changeBoxResults } from "@manakin/app-core/BoxService/actions";
import { withHandlers, compose } from "recompose";
import { useSetting } from "@manakin/hooks";
import { savable } from "@manakin/app-core/lib";
import Questions from "./Questions";

const mapStateToProps = state => ({
  boxId: getBoxId(state),
  appUser: getAppUser(state),
  program: getProgram(state)
});

const options = {
  showFeedback: false,
  nextButton: false
};

const TestContainer = props => {
  //global variables
  const {
    data = {},
    BOXRESULTS = {},
    boxId,
    workformsData,
    SETTINGS,
    BOX,
    setBoxResults,
    APPUSER
  } = props;
  const { loading, test } = data;
  const { settings = {} } = SETTINGS;
  const { loading: boxResultsLoading } = BOXRESULTS;
  const { loading: boxLoading } = BOX;
  const { loading: workformsLoading, hasReset } = workformsData;

  //custom hooks
  const minGrade = useSetting(settings, "minGrade") || 5.5;
  const cesuur = 80;
  //state hooks
  const [boxResults, setBoxResult] = useState([]);
  const [hasBoxResults, setHasBoxResults] = useState(false);
  const [startTest, setStartTest] = useState(false);
  const [finished, setFinished] = useState(null);
  const [years, setYears] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [progress, setProgress] = useState([]);
  const [drogistProgress, setdrogistProgressArray] = useState([]);
  const [functionObj, setFunctionObj] = useState({});
  const [disabledMessage, setDisabledMessage] = useState(false);

  //effect hooks
  useEffect(() => {
    if (!props.data.loading) {
      if (data.test && data.test.workforms) {
        props.workformsData.loadWorkforms({
          ...data.test,
          options: options,
          elementId: props.match.params.elementId
        });
      }

      props.workformsData.loadElementResult(props.match.params.elementId);
    }
  }, [props.data.loading]);

  useEffect(() => {
    if (!boxResultsLoading) {
      if (BOXRESULTS && BOXRESULTS.boxResults) {
        setBoxResult(BOXRESULTS.boxResults);
        setHasBoxResults(
          BOXRESULTS.boxResults.some(item => item.box.id === boxId)
        );
      }
    }
  }, [boxResultsLoading]);

  useEffect(() => {
    if (boxResults.some(item => item.redirect)) {
      setHasBoxResults(true);
    }
  }, [boxResults]);

  useEffect(() => {
    if (!BOXRESULTS.loading && !APPUSER.loading) {
      const { appUser } = APPUSER;
      let _years = [];
      let arr = [];
      let drogistArr = [];
      let _obj = {};

      const _finished =
        BOXRESULTS.boxResults &&
        BOXRESULTS.boxResults.filter(result => result.finished);

      _finished.forEach(result => {
        if (
          !arr.some(item => item.box.id === result.box.id) &&
          result.finished &&
          (result.text === "" || result.text === null)
        ) {
          arr.push(result);
        } else {
          drogistArr.push(result);
        }
      });

      for (let [key, value] of Object.entries(appUser.userDataCbd)) {
        if (key.indexOf("trainingMandatory") !== -1 && value) {
          _years.push({ year: getSecondPart(key, "trainingMandatory") });
        }
        if (key.indexOf("function20") !== -1 && value) {
          _obj[getSecondPart(key, "function")] =
            value || appUser.userDataCbd.function || "Assistent drogist";
        }
      }

      setFunctionObj(_obj);
      setdrogistProgressArray(drogistArr);
      setProgress(arr);
      setYears(_years);
      setFinished(_finished.length);
    }
  }, [boxResultsLoading, APPUSER.loading]);

  useEffect(() => {
    if (years !== null && finished !== null && !boxLoading) {
      const { appUser } = APPUSER;
      let drogistAmount = 0;
      let drogistLength = 0;
      const { box = {} } = BOX;

      drogistProgress.forEach(item => {
        if (
          functionObj[item.text] === "Drogist" &&
          appUser.userDataCbd[`trainingMandatory${item.text}`]
        ) {
          drogistLength++;
        }
      });

      years.forEach(year => {
        if (functionObj[year.year] === "Drogist") {
          drogistAmount++;
        }
      });

      if (drogistAmount) {
        if (
          progress.length >= years.length * 5 &&
          drogistLength >= drogistAmount
        ) {
          setDisabled(true);
        }
        if (progress.length >= years.length * 5 && !box.yearProgress) {
          setDisabled(true);
          if (drogistLength < drogistAmount) {
            setDisabledMessage(true);
          }
        }
        if (drogistLength >= drogistAmount && box.yearProgress) {
          setDisabled(true);
        }
      } else if (progress.length >= years.length * 5) {
        setDisabled(true);
      }
    }
  }, [years, finished, progress, drogistProgress, boxLoading]);

  useEffect(() => {
    if (hasReset) {
      workformsData.setHasResettet;
      if (data.test && data.test.workforms) {
        props.workformsData.loadWorkforms({
          ...data.test,
          options: options,
          elementId: props.match.params.elementId
        });
      }
      setTimeout(() => {
        setStartTest(true);
      }, 1000);
    }
  }, [hasReset]);

  //functions
  const getSecondPart = (str, firstPart) => {
    return str.split(firstPart)[1];
  };

  const handleClick = data => {
    workformsData.resetElement(workformsData.rawData.elementId, false, true);
  };

  const handleExit = () => {
    const { workforms = [] } = workformsData;
    const { box = {} } = BOX;
    let count = 0;
    const savableWorkforms = savable(workforms);
    savableWorkforms.forEach(item => {
      if (item.correct) count++;
    });

    const result = percentage(count, savableWorkforms.length);

    let rating = 0;
    let finished = false;
    const _minGrade = parseFloat(minGrade);

    if (result >= cesuur) {
      const diff = (10 - minGrade) / (100 - cesuur);
      rating = _minGrade + (result - cesuur) * diff;
      finished = true;
    } else {
      const diff = (_minGrade - 1) / (cesuur - 0);
      rating = 1 + result * diff;
      finished = false;
    }

    const obj = {
      user: props.appUser.id,
      program: props.program,
      box: props.boxId,
      finished: finished,
      rating:
        rating != undefined && rating > 0 ? parseFloat(rating).toFixed(1) : 0,
      text: BOX.box ? BOX.box.yearProgress || null : null
    };

    setBoxResults(obj);

    if (box.mandatory || box.mandatory == undefined) {
      props.onUpdateBoxResult({ ...obj }).then(result => {
        setBoxResult([
          {
            ...result.data.upsertBoxResult.result,
            redirect: true
          }
        ]);
      });
    }
  };

  const percentage = (partialValue, totalValue) => {
    return (100 * partialValue) / totalValue;
  };

  if (loading || boxResultsLoading || workformsLoading) {
    return (
      <div>
        <Loader fullScreen={true} />
      </div>
    );
  } else {
    return (
      <React.Fragment>
        {!hasBoxResults && !startTest && (
          <DefaultFrontPage
            buttonText="Start Toets"
            preTitle="Toets"
            disabled={disabled}
            disabledMessage={disabledMessage}
            {...test}
            onClick={handleClick}
          />
        )}
        {!hasBoxResults && startTest && (
          <Questions
            {...workformsData}
            onExit={handleExit}
            disabled={disabled}
          />
        )}
        {hasBoxResults && (
          <DefaultResultsPage
            {...workformsData}
            url={props.match.url}
            hasBoxResults={true}
            boxResults={boxResults}
            variant="test"
            preTitle="Toets"
          />
        )}
      </React.Fragment>
    );
  }
};

export default compose(
  connect(mapStateToProps, dispatch => ({
    processFailure: () => {
      dispatch(
        showSnackbarMessage({
          text:
            "Er ging iets mis met het opslaan van uw toets, neem contact op met een beheerder",
          variant: "error"
        })
      );
    },
    setBoxResults: program => dispatch(changeBoxResults(program))
  })),
  graphql(GQL_FETCH_BOX_RESULTS, {
    name: "BOXRESULTS",
    options: props => ({
      variables: {
        program: props.program,
        user: props.appUser && props.appUser.id
      }
    })
  }),
  graphql(GQL_FETCH_BOX, {
    name: "BOX",
    options: props => ({
      variables: {
        id: props.boxId
      }
    })
  }),
  graphql(GQL_UPDATE_BOX_RESULTS, { name: "UPDATEBOX" }),
  graphql(GQL_FETCH_TEST, {
    options: props => ({ variables: { id: props.match.params.elementId } })
  }),
  graphql(GQL_FETCH_GLOBAL_SETTINGS, { name: "SETTINGS" }),
  graphql(GQL_FETCH_APP_USER, {
    name: "APPUSER",
    options: props => ({
      variables: {
        id: props.appUser && props.appUser.id
      }
    })
  }),
  withHandlers({
    onUpdateBoxResult: ({ UPDATEBOX, processFailure }) => event => {
      return UPDATEBOX({
        variables: {
          ...event
        }
      });
    }
  }),
  withWorkforms()
)(TestContainer);
