import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import ArrowRightAlt from "@material-ui/icons/ArrowRightAlt";
import Done from "@material-ui/icons/Done";
import Media from "react-media";

const styles = theme => ({
    container: {
        width: '100%',
        maxWidth: '643px',
    },
    containerSmall: {
        width: '100%',
        maxWidth: '444px'
    },
    button: {
        display: 'inline',
        width: '594px',
        height: '80px',
        backgroundColor: '#000000',
        color: '#FFFFFF',
        border: '1px solid #FDC228',
        textAlign: 'left',
        float: 'left',
        marginLeft: '24px'
    },
    buttonMobile: {
        display: 'inline',
        width: '313px',
        height: '80px',
        backgroundColor: '#000000',
        color: '#FFFFFF',
        border: '1px solid #FDC228',
        textAlign: 'left',
        marginTop: '16px',
        marginBottom: '32px !important'
    },
    buttonSmall: {
        display: 'inline',
        width: '388px',
        height: '80px',
        backgroundColor: '#000000',
        color: '#FFFFFF',
        border: '1px solid #FDC228',
        textAlign: 'left',
        float: 'left',
        marginLeft: '24px'
    },
    buttonMarkup: {
        color: '#FFFFFF',
        fontSize: '14px',
        fontWeight: 'bold',
        letterSpacing: '1px',
        lineHeight: '24px',
        marginLeft: '40px'
    },
    imageOut: {
        marginLeft: '8px',
        marginTop: '8px',
        float: 'left',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(1),
        width: '198px',
        height: '198px',
        padding: '0',
        margin: '0',
    },
    imageOutBig: {
        marginLeft: '8px',
        marginTop: '8px',
        float: 'left',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(1),
        width: '300px',
        height: '300px',
        padding: '0',
        margin: '0',
    },
    imageIn: {
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '100%',
        height: '100%'
    },
    imageOutMobile: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(1),
        width: '164px',
        height: '164px',
        float: 'left'
    },
    imageInMobile: {
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '100%',
        height: '100%'
    },
    imagesMobile: {
        display: 'block',
        margin: '0 auto',
        width: '328px'
    },
    images: {
        display: 'inline-block',
        paddingLeft: theme.spacing(1),
        width: '100%',
    },
    imagesBig: {
        display: 'inline-block',
        paddingLeft: theme.spacing(1),
        width: '643px'
    },
    seperator: {
        height: theme.spacing(1)
    },
    imageText: {
        fontSize: '18px',
        fontWeight: 'bold',
        lineHeight: '36px',
        textAlign: 'center',
        marginTop: '-48px',
        userSelect: 'none'
    },
    mobileDone: {
        position: 'absolute',
        marginTop: '-100px',
        marginLeft: '47px',
        width: '50px',
        height: '50px',
        textAlign: 'center',
        lineHeight: '53px',
        backgroundColor: '#FFFFFF'
    },
    mobileDoneIcon: {
        width: '12px',
        height: '12px'
    }
});

class ImageSingleChoice extends React.Component {
    state = {
        value: 0,
        selectedAnswers: new Map()
    };

    handleClick = (id, isMobile) => {
        let element = document.getElementById(id);

        if(isMobile) {
            let elementDone = document.getElementById('done' + id);
            elementDone.style.display = "initial";

            this.props.questions.forEach(e => {
                if(e.id !== id) {
                    document.getElementById('done' + e.id).style.display = "none";
                }
            });
        }
        else {
            element.style.outline = "2px solid #000000";

            this.props.questions.forEach(e => {
                if(e.id !== id) {
                    document.getElementById(e.id).style.outline = "0px";
                }
            });
        }

        this.setState({
            value: id
        });
    };

    handleSubmit = event => {
        if(this.state.value !== 0) {
            const value = this.state.value - 1;

            if(this.props.questions[value].answer) {
                // Right answer
                return true;
            }
            else {
                // Wrong answer
                return false;
            }
        }
        else {
            // No answer
            return false;
        }
    };

    render() {
        const { classes, buttonText, questions } = this.props;

        return (
            <div className={questions.length > 4 ? classes.container : classes.containerSmall}>
                
                <Media query="(max-width: 860px)">
                {matches => matches ? (
                    // Mobile
                    <div className={classes.imagesMobile}>
                        {questions.map(e => {
                            return <div className={classes.imageOutMobile} id={e.id} data-clicked="false">
                                <div style={{backgroundImage: `url(${e.image})`}} className={classes.imageInMobile} onClick={() => this.handleClick(e.id, true)}></div>
                                
                                <div id={'done' + e.id} className={classes.mobileDone} onClick={() => this.handleClick(e.id, true)} style={{display: 'none'}}>
                                    <Done className={classes.mobileDoneIcon} />
                                </div>
                            </div>
                        })}
                    </div>
                ) : (
                    <div className={questions.length > 4 ? classes.images : classes.imagesBig }>
                        {questions.map(e => {
                            return <div className={questions.length > 4 ? classes.imageOut : classes.imageOutBig} id={e.id} data-clicked="false">
                                <div style={{backgroundImage: `url(${e.image})`}} className={classes.imageIn} onClick={() => this.handleClick(e.id, false)}></div>
                            </div>
                        })}
                    </div>
                )}
                </Media>

                <div className={classes.seperator}></div>

                <Media query="(max-width: 860px)">
                {matches => matches ? (
                    // Mobile
                    <div style={{textAlign: 'center'}}>
                        <button className={classes.buttonMobile} onClick={this.handleSubmit}>
                            <div style={{float: 'left'}}>
                                <p className={classes.buttonMarkup}>{buttonText.toUpperCase()}</p>
                            </div>
                            <div style={{float: 'right'}}>
                                <p style={{marginRight: '40px'}}><ArrowRightAlt /></p>
                            </div>
                        </button>
                    </div>
                ) : (
                    <div style={{textAlign: 'right'}}>
                        <button className={questions.length > 4 ? classes.button : classes.buttonSmall} style={questions.length === 4 ? {width: '592px'} : {}} onClick={this.handleSubmit}>
                            <div style={{float: 'left'}}>
                                <p className={classes.buttonMarkup}>{buttonText.toUpperCase()}</p>
                            </div>
                            <div style={{float: 'right'}}>
                                <p style={{marginRight: '40px'}}><ArrowRightAlt /></p>
                            </div>
                        </button>
                    </div>
                )}
                </Media>
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(ImageSingleChoice);