import React from 'react';
import ClassList from './parts/ClassList';
import Header from './parts/Header';
import { AccessControl } from '@manakin/app-core'
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { setBackButton } from '@manakin/app-core/AppBar/actions';

class Rapportage extends React.PureComponent {
	componentDidMount() {
		const { location = {} } = this.props;
		this.props.setBackButton({
			show: true,
			location: location.pathname || '/teacherProgram',
			url: '/dashboard/no-scroll'
		});
	}

	render() {
		return (
			<AccessControl role={['TEACHER', 'SCHOOL_MANAGER']}>
				<div>
					<Header />
					<ClassList />
				</div>
			</AccessControl>
		)
	}
}

export default compose(
	connect(
		null,
		dispatch => ({
			setBackButton: data => dispatch(setBackButton(data))
		})
	)
)(Rapportage)