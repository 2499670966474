"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _HelpButton = require("./HelpButton");

var _HelpButton2 = _interopRequireDefault(_HelpButton);

var _graphql = require("./graphql");

var _reactApollo = require("react-apollo");

var _recompose = require("recompose");

var _hooks = require("@manakin/hooks");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var HelpButtonContainer = function HelpButtonContainer(props) {
  var _props$locale = props.locale,
      locale = _props$locale === undefined ? "" : _props$locale,
      _props$data = props.data,
      data = _props$data === undefined ? {} : _props$data;

  var _useState = (0, _react.useState)(false),
      _useState2 = _slicedToArray(_useState, 2),
      show = _useState2[0],
      setIsShow = _useState2[1];

  var _useState3 = (0, _react.useState)("default"),
      _useState4 = _slicedToArray(_useState3, 2),
      storedLocale = _useState4[0],
      setLocale = _useState4[1];

  var _useState5 = (0, _react.useState)(null),
      _useState6 = _slicedToArray(_useState5, 2),
      expanded = _useState6[0],
      setIsExpanded = _useState6[1];

  var settings = (0, _hooks.useArrayToObject)(props.data.settings && props.data.settings.settings);

  (0, _react.useEffect)(function () {
    if (storedLocale == "default") setLocale(locale);else if (storedLocale != locale) {
      data.refetch();
      setLocale(locale);
    }
  }, [locale]);

  var handleClick = function handleClick() {
    setIsShow(!show);
  };

  var handleChange = function handleChange(panel) {
    return function (event, expanded) {
      setIsExpanded(expanded ? panel : false);
    };
  };

  if (!settings.helpTitle) {
    return "";
  }
  return _react2.default.createElement(_HelpButton2.default, {
    show: show,
    expanded: expanded,
    settings: settings,
    onClick: handleClick,
    onChange: handleChange
  });
};

exports.default = (0, _recompose.compose)((0, _reactApollo.graphql)(_graphql.GQL_FETCH_GLOBAL_SETTINGS))(HelpButtonContainer);