import { relative } from "path";

export const styles = theme => ({
    heading: {
        textTransform: 'uppercase'
    },
    body: {
        [theme.breakpoints.up("lg")]: {
            marginBottom: '3.8rem'
        }
    },
    container: {
        ...theme.manakin.largeWrapper,
        paddingTop: '7rem',
        paddingBottom: '7rem',
        backgroundColor: 'white',
        maxWidth: '120rem',
        [theme.breakpoints.up('md')]: {
            marginTop: '-22rem',
            padding: '10rem 6rem'
        },
    },
    singlecard: {
        [theme.breakpoints.up('lg')]: {
            padding: '0 6rem!important'
        }
    },
    hide: {
        display: 'none'
    },
    cardhover: {
        textDecoration: 'none',
    },
    imageWrapper: {
        backgroundColor: theme.manakin.secondaryColor[50],
        position: 'relative',
        height: 'auto',
        height: '250px',
        overflow: 'hidden',
        marginBottom: '2.6rem',
    },
    image: {
        display: 'inline-block',
        width: '100%',
        height: '100%',
        transform: 'scale(1)',
        transition: 'all .3s',
        '&:hover': {
            transform: 'scale(1.2)'
        }
    },
})