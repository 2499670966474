import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';
import { LoadImage } from '@manakin/app-core';
import { FeedbackBox } from '@manakin/app-workforms';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { styles } from './styles';
import { connect } from "react-redux";

const StatementQuestion = props => {
  const { classes, data, onFinished, disabled, direction = 'default', onSubmit, config = {} } = props;
  const { literals = {} } = config

  const handleSubmit = userAnswer => {
    if (onSubmit) onSubmit(userAnswer);
  };

  return (
    <div
      className={classNames(
        classes.root,
        { [classes.primaryColors]: data.backgroundColor == 'PRIMARY' },
        { [classes.secondaryColors]: data.backgroundColor == 'SECONDARY' }
      )}
    >
      <div
        className={classNames(classes.container, {
          [classes.columnDirection]: direction === 'column'
        })}
      >
        <div
          className={classNames(classes.wrapper, {
            [classes.noImage]: !data.image
          })}
        >
          <div
            className={classNames(classes.content, {
              [classes.noImageContent]: !data.image
            })}
          >
            <Typography
              component="p"
              variant="body1"
              className={classes.contentColor}
            >
              {data.introduction || ''}
            </Typography>

            <Typography
              component="p"
              variant="h5"
              className={classNames(classes.contentColor, classes.quote)}
            >
              {data.statement}
            </Typography>

            <div className={classes.buttonContainer}>
              <div className={classes.buttons}>
                <Button
                  className={classNames(
                    classes.button,
                    classes.contentColor,
                    { [classes.active]: data.agree }
                  )}
                  variant="outlined"
                  disabled={data.done || disabled}
                  color="primary"
                  fullWidth
                  onClick={() => handleSubmit(true)}
                >
                  {data.answerOne || literals.APP_AGREE || 'Eens'}
                </Button>
                <Button
                  className={classNames(
                    classes.contentColor,
                    classes.button,
                    { [classes.active]: data.agree != null && !data.agree }
                  )}
                  variant="outlined"
                  color="primary"
                  disabled={data.done || disabled}
                  fullWidth
                  onClick={() => handleSubmit(false)}
                >
                  {data.answerTwo || literals.APP_DISAGREE || 'Oneens'}
                </Button>
              </div>
              {data.feedback && (
                <div
                  className={classNames(classes.feedback, {
                    [classes.activeFeedback]: data.feedback
                  })}
                >
                  <FeedbackBox
                    data={data}
                    onFinished={onFinished}
                    outlined={true}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        {data.image && data.image.url && (
          <div className={classes.imageContainer}>
            <LoadImage
              src={(data.image && data.image.url) || ''}
              placeholder={(data.image && data.image.thumbnailUrl) || ''}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default compose(
  connect(state => ({ config: state.config })),
  withStyles(styles, { name: 'AppStatementQuestion' }))
  (StatementQuestion);
