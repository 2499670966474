import React from 'react';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    position: 'fixed',
    right: theme.spacing(4),
    bottom: theme.spacing(4)
  }
});

class _SpeedDial extends React.Component {
  state = {
    open: false
  };

  handleClose = event => {
    if (!this.isInstantClick())
      this.setState({
        open: false
      });
  };

  handleClick = event => {
    const { children, onClick } = this.props;
    if (!this.isInstantClick()) {
      this.setState(state => ({
        open: !state.open
      }));
    } else {
      onClick();
    }
  };

  handleOpen = event => {
    if (!this.isInstantClick())
      this.setState({
        open: true
      });
  };

  isInstantClick = () => {
    const { children, onClick = null } = this.props;
    const hasMultipleActions =
      children.length !== undefined && children.length > 1;
    return !hasMultipleActions && onClick !== null;
  };

  render() {
    const { classes, children } = this.props;
    const { open } = this.state;

    return (
      <SpeedDial
        ariaLabel=""
        className={classes.root}
        icon={<SpeedDialIcon />}
        onBlur={this.handleClose}
        onFocus={this.handleOpen}
        onClick={this.handleClick}
        onMouseEnter={this.handleOpen}
        onMouseLeave={this.handleClose}
        open={open}
      >
        {children}
      </SpeedDial>
    );
  }
}

export default withStyles(styles, { name: 'ManakinCoreSpeedDial' })(_SpeedDial);
