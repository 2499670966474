import React from "react";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { LoadImage } from "@manakin/app-core";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
  root: {
    width: "100%",
    maxWidth: "600px",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    marginBottom: "2.6rem"
  },
  rightPosition: {
    "& $avatar": {
      order: 2
    },
    "& $content": {
      order: 1,
      marginLeft: 0,
      marginRight: "1.8rem",
      backgroundColor: theme.manakin.primaryColor
    },
    [theme.breakpoints.up("md")]: {
      float: "right"
    }
  },
  avatar: {
    position: "relative",
    backgroundColor: "orange",
    width: "7rem",
    height: "7rem",
    borderRadius: "100%",
    overflow: "hidden",
    [theme.breakpoints.up("md")]: {
      width: "8rem",
      height: "8rem"
    }
  },
  content: {
    backgroundColor: "white",
    width: "calc(100% - 88px)",
    marginLeft: "1.8rem",
    padding: ".5rem 1.6rem",
    border: `1px solid ${theme.manakin.defaultBorderColor[500]}`,
    display: "inline-flex",
    alignItems: "center",
    minHeight: "6.6rem",
    [theme.breakpoints.up("md")]: {
      width: "calc(100% - 105px)",
      minHeight: "8rem",
      padding: "2rem 3.4rem",
      marginLeft: "2.4rem"
    }
  },
  bubbleContent: {
    margin: 0
  }
});

class Bubble extends React.PureComponent {
  render() {
    const { classes, children, position, data } = this.props;

    const leftImage = data.leftBubble ? data.leftBubble.url : "";
    const leftPlaceholder = data.leftBubble ? data.leftBubble.thumbnailUrl : "";
    const rightImage = data.rightBubble ? data.rightBubble.url : "";
    const rightPlaceholder = data.rightBubble
      ? data.rightBubble.thumbnailUrl
      : "";

    const image = position == "rechts" ? rightImage : leftImage;
    const placeholder =
      position == "rechts" ? rightPlaceholder : leftPlaceholder;

    return (
      <div
        className={classNames(classes.root, {
          [classes.rightPosition]: position == "rechts"
        })}
      >
        <div className={classes.avatar}>
          <LoadImage src={image} placeholder={placeholder} />
        </div>
        <div className={classes.content}>
          <Typography variant="body1" className={classes.bubbleContent}>
            {children}
          </Typography>
        </div>
      </div>
    );
  }
}

export default withStyles(styles, { name: "AppBubble" })(Bubble);
