import gql from 'graphql-tag';

export const GQL_FETCH_GLOBAL_SETTINGS = gql`
  query {
    settings {
      settings {
        name
        value
        file {
          id
          url
          thumbnailUrl
        }
      }
    }
  }
`;
