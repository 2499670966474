import { TOGGLE_APP_MENU } from './actions';

const initialState = {
    open: false
};

export const reducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case TOGGLE_APP_MENU: {
            return { ...state, open: !state.open };
        }
        default:
            return state;
    }
};

export default reducer;
