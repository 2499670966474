import React from "react";
import TableHead from "../TableHead";
import { withStyles } from "@material-ui/core/styles";
import MuiTable from "@material-ui/core/Table";
import TableBody from "../TableBody";
import TableToolbar from "../TableToolbar";
import TablePagination from "@material-ui/core/TablePagination";
import { compose } from "recompose";
import { connect } from "react-redux";

const styles = theme => ({
  root: {
    position: "relative",
    paddingBottom: "120px"
  },
  search: {
    flex: "0 0 auto"
  },
  loader: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
  },
  relative: {
    position: "relative"
  }
});

class Table extends React.Component {
  state = {
    selectAll: false,
    selection: []
  };

  handleSort = (event, property) => {
    const orderBy = property;
    let order = "desc";

    const { query, onQueryChange } = this.props;

    if (query.orderBy === property && query.order === "desc") {
      order = "asc";
    }

    onQueryChange({ ...query, order, orderBy, offset: 0 });
    this.resetSelection();
  };

  handleSelectVisible = event => {
    const { totalCount, rows } = this.props;
    this.setState({
      selection: [...rows.allIds],
      selectAll: totalCount === rows.allIds.length
    });
  };

  handleSelectAll = event => {
    this.setState({
      selection: [...this.props.rows.allIds],
      selectAll: true
    });
  };

  handleDeselectAll = event => {
    this.resetSelection();
  };

  handleToggleSelectRow = row => {
    const { selection } = this.state;
    const { totalCount } = this.props;
    const selectionIndex = selection.indexOf(row.id);

    if (selectionIndex === -1) {
      this.setState({
        selectAll: selection.length + 1 === totalCount,
        selection: [...selection, row.id]
      });
    } else {
      const newSelection = [...selection];
      newSelection.splice(selectionIndex, 1);

      this.setState({
        selectAll: false,
        selection: newSelection
      });
    }
  };

  handleRowClick = row => {
    this.props.onRowClick(row);
  };

  handleChangePage = (event, page) => {
    if (event === null) {
      return;
    } // dunno why but it fires event with page = 0 and event =null on startup
    const { query, onQueryChange } = this.props;
    onQueryChange({ ...query, offset: page * query.limit });
    this.resetSelection();
  };

  handleChangeRowsPerPage = event => {
    const { query, onQueryChange } = this.props;
    onQueryChange({ ...query, offset: 0, limit: event.target.value });
    this.resetSelection();
  };

  handleSearch = search => {
    const { query, onQueryChange } = this.props;
    onQueryChange({ ...query, search });
    this.resetSelection();
  };

  resetSelection = () => {
    this.setState({ selection: [], selectAll: false });
  };

  getLabelDisplayedRows = ({ from, to, count }) => {
    const { config = {} } = this.props;
    const { literals = {} } = config;

    return count > 0
      ? `${from}-${to} ${literals.CMS_TABLE_OF || "van"} ${count}`
      : "";
  };

  componentWillReceiveProps(newProps) {
    const oldRowsLength = this.props.rows.allIds.length;
    const newRowsLength = newProps.rows.allIds.length;
    if (oldRowsLength != newRowsLength) {
      this.resetSelection();
    }
  }

  render() {
    const {
      classes,
      rows,
      columns,
      totalCount,
      query,
      actions,
      loading,
      config = {},
      ui
    } = this.props;
    const { order, orderBy, selection, selectAll } = this.state;
    const { literals = {} } = config;

    const page = Math.max(0, Math.floor(query.offset / query.limit));
    const rowCount = rows.allIds.length;
    let allRows = rows;

    if (query.orderBy) {
      allRows = {
        allIds: [],
        byId: {}
      };

      Array.from(Object.keys(rows.byId || []), key => rows.byId[key])
        .sort((a, b) => {
          a = a[query.orderBy] || "";
          b = b[query.orderBy] || "";

          // If we receive an array of values, get the first object's first value
          const valueFromObjectInArray = value => {
            if (value && value[0] && Object.keys(value[0]).length > 0)
              return value[0][Object.keys(value[0])[0]] || "";
            else return "";
          };

          a = typeof a === "string" ? a.toLowerCase() : a;
          b = typeof b === "string" ? b.toLowerCase() : b;
          a =
            typeof a === "object" ? valueFromObjectInArray(a).toLowerCase() : a;
          b =
            typeof b === "object" ? valueFromObjectInArray(b).toLowerCase() : b;

          if (a > b) return query.order === "asc" ? 1 : -1;
          else if (a < b) return query.order === "asc" ? -1 : 1;
          else if (a === b) return 0;
        })
        .map(row => {
          allRows.allIds.push(row.id);
          return { ...row };
        })
        .forEach(row => (allRows.byId[row.id] = row));
    }

    return (
      <div className={classes.root}>
        <TableToolbar
          totalCount={totalCount}
          selectAll={selectAll}
          selection={selection}
          onSearch={this.handleSearch}
          onSelectAll={this.handleSelectAll}
          onDeselectAll={this.handleDeselectAll}
          defaultSearch={query.search}
          actions={actions}
        />
        <div className={classes.relative}>
          <MuiTable>
            <TableHead
              selectedCount={selection.length}
              order={query.order}
              orderBy={query.orderBy}
              rowCount={rows.allIds.length}
              columns={columns}
              onSelectAll={this.handleSelectAll}
              onDeselectAll={this.handleDeselectAll}
              onSelectVisible={this.handleSelectVisible}
              onSort={this.handleSort}
            />
            <TableBody
              totalCount={totalCount}
              rows={allRows}
              columns={columns}
              rowsPerPage={query.limit}
              selection={selection}
              onToggleSelect={this.handleToggleSelectRow}
              onRowClick={this.handleRowClick}
              loading={loading}
            />
          </MuiTable>
          <TablePagination
            component="div"
            count={totalCount}
            rowsPerPage={query.limit}
            page={page}
            rowsPerPageOptions={[12, 24, 48, 96]}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
            labelDisplayedRows={this.getLabelDisplayedRows}
            labelRowsPerPage={
              literals.CMS_TABLE_ROWS_PER_PAGE || "Rijen per pagina:"
            }
          />
        </div>
      </div>
    );
  }
}

export default compose(
  connect(state => ({
    config: state.config
  })),
  withStyles(styles, { name: "CoreTable" })
)(Table);
