import React from 'react';
import { styles } from '../styles';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import moment from 'moment';
import 'moment/min/locales';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

const Manager = props => {
  const { literals = {}, classes } = props;

  return (
    <div className={classes.rowTeacher}>
      <div className={classes.specificStatisticItemTeacher}>
        <Typography
          classes={{ h2: classNames(classes.statisticsTitle) }}
          component="p"
          variant="h2"
        >
          {props.classStudents || 0}
        </Typography>
        <Typography
          classes={{ body1: classNames(classes.stasticsBody) }}
          component="p"
          variant="body1"
        >
          Aantal leerlingen <br /> in klas
        </Typography>
      </div>
      <div className={classes.specificStatisticItemTeacher}>
        <Typography
          classes={{ h2: classNames(classes.statisticsTitle) }}
          component="p"
          variant="h2"
        >
          {moment
            .duration(props.sessionAverage || 0, 'milliseconds')
            .format('hh:mm', { trim: false })}
        </Typography>
        <Typography
          classes={{ body1: classNames(classes.stasticsBody) }}
          component="p"
          variant="body1"
        >
          Gemiddelde sessieduur
        </Typography>
      </div>
      <div className={classes.specificStatisticItemTeacher}>
        <Typography
          classes={{ h2: classNames(classes.statisticsTitle) }}
          component="p"
          variant="h2"
        >
          {props.schoolClasses || 0}
        </Typography>
        <Typography
          classes={{ body1: classNames(classes.stasticsBody) }}
          component="p"
          variant="body1"
        >
          Aantal klassen
        </Typography>
      </div>
    </div>
  );
};

export default withStyles(styles)(Manager);
