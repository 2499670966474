import gql from 'graphql-tag';

export const GQL_FETCH_SCHOOL = gql`
  query($id: ID!) {
    school(id: $id) {
      id
      name
      managers {
        id
        roles {
          id
          name
        }
      }
      classes {
        id
        name
        students {
          id
          firstName
          roles {
            id
            name
          }
          schoolClasses {
            id
          }
        }
      }
      students {
        id
      }
    }
  }
`;

export const GQL_FETCH_SCHOOL_CLASS = gql`
  query($id: ID!) {
    schoolClass(id: $id) {
      id
      name
      product {
        id
        program {
          id
          boxes {
            id
            elements {
              id
              type
            }
          }
        }
      }
      students {
        id
        sessionAverageDuration
        sessionTotalDuration
        sessionCount
      }
      schools {
        id
      }
    }
  }
`;

export const GQL_FETCH_GROUPS = gql`
  query($filter: GroupFilter) {
    groups(filter: $filter) {
      groups {
        id
        name
        type
      }
    }
  }
`;

export const GQL_FETCH_ELEMENT_RESULTS = gql`
  query($box: ID!, $program: ID!, $user: ID!) {
    elementResults(box: $box, program: $program, user: $user) {
      finished
      element {
        id
        title
      }
    }
  }
`;
