import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { ElementOverlay, Loader } from "@manakin/app-core";
import { GQL_FETCH_FAQ } from "./graphql";
import { graphql } from "react-apollo";
import { compose } from "recompose";
import { Header, Introduction } from "./parts";

const styles = theme => ({
  root: {
    width: "100%"
  },
  loading: {
    opacity: "0",
    "& $loader": {
      display: "none"
    }
  },
  loader: {
    backgroundColor: "white",
    position: "absolute",
    width: "100vw",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
  },
  hide: {
    display: "none"
  },
  wrapper: {
    [theme.breakpoints.up("md")]: {
      ...theme.manakin.largeWrapper
    }
  },
  largeWrapper: {
    [theme.breakpoints.up("md")]: {
      ...theme.manakin.extraLargeWrapper
    }
  },
  normalMargin: {
    margin: "3rem 0",
    [theme.breakpoints.up("md")]: {
      margin: "15rem 0"
    }
  },
  bottom: {
    width: "100%",
    padding: "10rem 2.5rem",
    color: "white",
    fontFamily: theme.manakin.defaultTitleFont,
    backgroundColor: "black",
    fontSize: "3rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textDecoration: "none",
    [theme.breakpoints.up("md")]: {
      padding: "17rem 2.5rem",
      fontSize: "6rem"
    }
  }
});

const Faq = props => {
  const { classes, data } = props;
  const { faq, loading = false } = data;

  return (
    <ElementOverlay
      closeLocation={`/`}
      fullWidth={true}
      color={"light"}
      title={"faq"}
    >
      {!loading ? (
        <React.Fragment>
          {faq && (
            <div className={classes.root}>
              <Header data={faq} />
              <Introduction data={faq} />
            </div>
          )}
        </React.Fragment>
      ) : (
        <div className={classes.loader}>
          <Loader />
        </div>
      )}
    </ElementOverlay>
  );
};

export default compose(
  graphql(GQL_FETCH_FAQ),
  withStyles(styles)
)(Faq);
