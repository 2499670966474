import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "./styles";
import { compose } from "recompose";
import Typography from "@material-ui/core/Typography";
import renderHTML from "react-render-html";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";

const DownloadButton = props => {
  const { classes, data = {}, withNext = false, config = {} } = props;
  const { literals = {} } = config

  const handleClick = () => {
    if (props.onClick) props.onClick();
  };

  const handleNext = () => {
    if (props.onFinished) props.onFinished();
  };

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <a onClick={handleClick} className={classes.link}>
          <div className={classes.content}>
            <div className={classes.icon} />
            <div className={classes.text}>
              <Typography
                component="p"
                variant="h6"
                classes={{ h6: classes.title }}
              >
                {data.introduction ||
                  data.workTitle ||
                  (data.file && data.file.name)}
              </Typography>
              <Typography
                component="div"
                variant="body1"
                classes={{ body1: classes.body }}
              >
                {renderHTML(data.text || "")}
              </Typography>
            </div>
          </div>
        </a>
        {withNext && (
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={handleNext}
          >
            {literals.APP_NEXT || "Volgende"}
          </Button>
        )}
      </div>
    </div>
  );
};

export default compose(
  connect(state => ({ config: state.config })),
  withStyles(styles, { name: "AppDownloadButton" }))
  (DownloadButton);
