import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { compose } from 'recompose';
import { StatisticsBox, AccessControl } from '@manakin/app-core';
import { getGroup } from '@manakin/app-core/GroupsDropdown/selectors';
import CreateDialog from '../CreateDialog';
import UpdateDialog from '../UpdateDialog';
import { openDialog } from '@manakin/core/actions';
import Button from '@material-ui/core/Button';

const styles = theme => ({
  root: {
    backgroundColor: theme.manakin.primaryColor[500],
    width: '100%',
    padding: '12rem 0 8rem',
    [theme.breakpoints.up('md')]: {
      padding: '18rem 0 8rem'
    }
  },
  heading: {
    textAlign: 'center'
  },
  content: {
    '& $heading': {
      marginBottom: '3.4rem',
      color: theme.palette.getContrastText(theme.manakin.primaryColor[500]),
      [theme.breakpoints.up('md')]: {
        marginBottom: '8.4rem'
      }
    }
  },
  wrapper: {
    ...theme.manakin.defaultWrapper
  },
  links: {
    textAlign: 'right'
  },
  button: {
    marginBottom: '1rem',
    [theme.breakpoints.up('md')]: {
      marginBottom: 0,
      marginLeft: '1rem'
    }
  }
});

class Header extends React.PureComponent {
  openDialog = dialog => {
    this.props.onOpenDialog(dialog);
  };

  render() {
    const { classes, group } = this.props;

    return (
      <div className={classes.root}>
        <AccessControl role={['SCHOOL_MANAGER']}>
          <CreateDialog />
          {group && <UpdateDialog />}
        </AccessControl>

        <div className={classes.wrapper}>
          <div className={classes.content}>
            <Typography component="h1" variant="h2" className={classes.heading}>
              Rapportage
            </Typography>
          </div>
          {typeof group == 'string' && (
            <div>
              <AccessControl role={['SCHOOL_MANAGER']}>
                <StatisticsBox variant="rapportage" role="SCHOOL_MANAGER" />
              </AccessControl>
              <AccessControl role={['TEACHER']} not={['SCHOOL_MANAGER']}>
                <StatisticsBox variant="rapportage" role="TEACHER" />
              </AccessControl>
            </div>
          )}
          <div className={classes.links}>
            <AccessControl role={['SCHOOL_MANAGER']}>
              <Button
                size="small"
                color="primary"
                variant="contained"
                onClick={() => this.openDialog('appClassCreateDialog')}
                className={classes.button}
              >
                Maak klas aan
              </Button>
              {group && (
                <Button
                  size="small"
                  color="primary"
                  variant="contained"
                  onClick={() => this.openDialog('appClassUpdateDialog')}
                  className={classes.button}
                >
                  Klas aanpassen
                </Button>
              )}
            </AccessControl>
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  withStyles(styles, { name: 'AppTeacherReport' }),
  connect(
    state => ({
      group: getGroup(state)
    }),
    dispatch => ({
      onOpenDialog: data => dispatch(openDialog(data))
    })
  )
)(Header);
