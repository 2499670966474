"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _styles = require("@material-ui/core/styles");

var _Typography = require("@material-ui/core/Typography");

var _Typography2 = _interopRequireDefault(_Typography);

var _recompose = require("recompose");

var _reactApollo = require("react-apollo");

var _appCore = require("@manakin/app-core");

var _graphql = require("../graphql");

var _ResultItem = require("./ResultItem");

var _ResultItem2 = _interopRequireDefault(_ResultItem);

var _reactRouterDom = require("react-router-dom");

var _actions = require("@manakin/app-core/actions");

var _reactRedux = require("react-redux");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var styles = function styles(theme) {
  return {
    root: {
      width: "100%",
      maxHeight: "100vh",
      overflow: "auto",
      WebkitOverflowScrolling: "touch",
      paddingBottom: theme.spacing(8)
    },
    wrapper: _extends({}, theme.manakin.smallWrapper, _defineProperty({
      maxWidth: "1200px",
      paddingTop: theme.manakin.defaultPadding,
      marginBottom: "3rem",
      opacity: 0,
      animation: "showBackwards .3s ease .15s forwards"
    }, theme.breakpoints.up("md"), {
      minHeight: "240px",
      marginBottom: "8rem"
    })),
    loader: {
      position: "absolute",
      zIndex: 99,
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)"
    },
    resultsParent: {
      width: "100%"
    },
    results: _defineProperty({
      display: "flex",
      width: "100%",
      flexDirection: "column"
    }, theme.breakpoints.up("md"), {
      flexDirection: "row"
    }),
    resultsText: _defineProperty({
      marginLeft: "2.4rem",
      marginRight: "2.4rem"
    }, theme.breakpoints.up("md"), {
      marginLeft: "5rem",
      marginRight: "5rem"
    })
  };
};

var Results = function (_React$PureComponent) {
  _inherits(Results, _React$PureComponent);

  function Results() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, Results);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = Results.__proto__ || Object.getPrototypeOf(Results)).call.apply(_ref, [this].concat(args))), _this), _this.handleClick = function (result) {
      if (result.type == "Box") {
        console.log("its the search!");
        _this.props.selectProgram(result.program);
        _this.props.onResultClick("/box/" + result.box);
      } else if (result.type == "FactCard") {
        _this.props.onResultClick("/factCard/" + result.factcard);
      } else {
        console.log("its the search!");
        _this.props.selectProgram(result.program);
        _this.props.setBoxId(result.box);
        _this.props.onResultClick("/" + result.type + "/" + result.element);
      }
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(Results, [{
    key: "render",
    value: function render() {
      var _props = this.props,
          classes = _props.classes,
          data = _props.data,
          _props$config = _props.config,
          config = _props$config === undefined ? {} : _props$config;
      var _config$literals = config.literals,
          literals = _config$literals === undefined ? {} : _config$literals;
      var appSearchResults = data.appSearchResults,
          loading = data.loading;

      var isLoading = loading || false;

      var amountOfResults = appSearchResults && appSearchResults.length;
      var boxes = [];
      var factCards = [];
      var elements = [];

      appSearchResults && appSearchResults.forEach(function (item) {
        if (item.type == "Box") boxes.push(item);else if (item.type == "FactCard") factCards.push(item);else elements.push(item);
      });

      return !isLoading ? _react2.default.createElement(
        "div",
        { className: classes.root },
        appSearchResults.length > 0 ? _react2.default.createElement(
          _react2.default.Fragment,
          null,
          amountOfResults && _react2.default.createElement(
            _Typography2.default,
            { variant: "subtitle2", className: classes.resultsText },
            amountOfResults + " " + (literals.APP_RESULTS_FOUND || "resultaten gevonden") || ""
          ),
          _react2.default.createElement(
            "div",
            { className: classes.wrapper },
            _react2.default.createElement(
              "div",
              { className: classes.resultsParent },
              _react2.default.createElement(
                "div",
                { className: classes.results },
                boxes.length > 0 && _react2.default.createElement(_ResultItem2.default, {
                  title: literals.APP_BOXES || "Boxen",
                  results: boxes,
                  onClick: this.handleClick
                }),
                elements.length > 0 && _react2.default.createElement(_ResultItem2.default, {
                  title: literals.APP_ELEMENTS || "Elementen",
                  results: elements,
                  onClick: this.handleClick
                }),
                factCards.length > 0 && _react2.default.createElement(_ResultItem2.default, {
                  title: literals.APP_FACTCARDS || "Factcards",
                  results: factCards,
                  last: true,
                  onClick: this.handleClick
                })
              )
            )
          )
        ) : _react2.default.createElement(
          _Typography2.default,
          { variant: "subtitle2", className: classes.resultsText },
          literals.APP_NO_RESULTS_FOUND || "Geen resultaten gevonden"
        )
      ) : _react2.default.createElement(
        "div",
        { className: classes.loader },
        _react2.default.createElement(_appCore.Loader, null)
      );
    }
  }]);

  return Results;
}(_react2.default.PureComponent);

exports.default = (0, _recompose.compose)((0, _reactRedux.connect)(function (state) {
  return {
    config: state.config
  };
}, function (dispatch) {
  return {
    selectProgram: function selectProgram(program) {
      return dispatch((0, _actions.selectProgram)(program));
    },
    setBoxId: function setBoxId(id) {
      return dispatch((0, _actions.setBoxId)(id));
    }
  };
}), (0, _reactApollo.graphql)(_graphql.GQL_FETCH_SEARCH_RESULTS, {
  options: function options(props) {
    return {
      variables: { search: props.query }
    };
  }
}), _reactRouterDom.withRouter, (0, _styles.withStyles)(styles, { name: "AppSearchResults" }))(Results);