import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import scrollToComponent from 'react-scroll-to-component';
import renderHTML from 'react-render-html';
import classNames from 'classnames';

const styles = theme => ({
  contentColor: {
    color: theme.manakin.defaultContentColor[500]
  },
  root: {
    backgroundColor: 'white',
    padding: '20px 32px',
    transform: 'translateY(-20px)',
    opacity: 0,
    transition: 'opacity .3s, transform .3s',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 'auto'
    },
    '& $contentColor': {
      color: theme.manakin.defaultContentColor[500]
    }
  },
  show: {
    opacity: 1,
    transform: 'translateY(0)'
  },
  feedbackBoxTitle: {
    marginBottom: theme.spacing(1)
  },
  content: {
    '& ol': {
      margin: '10px 0 20px 30px'
    },
    '& ul': {
      margin: '10px 0 20px 30px'
    }
  },
  outlined: {
    border: `1px solid ${theme.manakin.defaultBorderColor[500]}`
  },
  button: {}
});

class FeedbackBox extends React.PureComponent {
  state = {
    show: false
  };

  componentDidMount() {
    setTimeout(() => {
      this.setState({ show: true });
    }, 100);
  }

  componentDidUpdate(prevProps) {
    if (
      (!prevProps.hint && this.props.hint) ||
      (!prevProps.done && this.props.done)
    ) {
      if (!this.props.finished) {
        setTimeout(() => {
          scrollToComponent(this.FeedbackBox, {
            offset: 30,
            align: 'bottom',
            duration: 500
          });
        }, 10);
      }
    }
  }

  render() {
    const {
      classes,
      title,
      content,
      buttonText,
      onClick,
      outlined
    } = this.props;

    const { show } = this.state;

    return (
      <div
        className={classNames(
          classes.root,
          { [classes.show]: show },
          { [classes.outlined]: outlined }
        )}
        ref={section => {
          this.FeedbackBox = section;
        }}
      >
        {title && (
          <Typography
            component="h4"
            variant="h4"
            className={classNames(
              classes.contentColor,
              classes.feedbackBoxTitle
            )}
          >
            {title}
          </Typography>
        )}
        <Typography
          component="div"
          variant="body1"
          className={classNames(classes.contentColor, classes.content)}
        >
          {renderHTML(content || '')}
        </Typography>
        {buttonText && (
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            fullWidth
            onClick={onClick}
          >
            {buttonText}
          </Button>
        )}
      </div>
    );
  }
}

export default withStyles(styles, { name: 'AppFeedbackBox' })(FeedbackBox);
