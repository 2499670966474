import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';
import {
  DragSource,
  DropTarget,
  ConnectDragSource,
  ConnectDropTarget,
  DragSourceMonitor,
  DropTargetMonitor
} from 'react-dnd';
import { findDOMNode } from 'react-dom';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import MoveIcon from '@material-ui/icons/DragHandle';
import MenuIcon from '@manakin/core/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import RedirectIcon from '@manakin/core/icons/Redirect';
import classNames from 'classnames';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { push } from 'redux-first-history';

const styles = theme => ({
  cardContainer: {
    border: '1px solid #e7e8ee',
    cursor: 'pointer',
    backgroundColor: 'white',
    borderRadius: 10,
    height: '100%',
    marginBottom: '24px',
    width: '100%'
  },
  card: {
    display: 'flex',
    height: '120px',
    border: '1px solid',
    borderColor: theme.palette.grey['400'],
    borderRadius: '10px',
    boxShadow: 'none',
    position: 'relative'
  },
  cardContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  thumbnail: {
    width: '120px',
    minWidth: '120px',
    height: '100%',
    objectFit: 'cover',
    fontFamily: "'object-fit: cover'"
  },
  cardContainerChild: {
    margin: '5px 10px'
  },
  cardHeader: {
    paddingBottom: 0,
    fontSize: '16px',
    marginBottom: '.2rem'
  },
  cardContent: {
    paddingTop: 0
  },
  cardDrag: {
    opacity: 0
  },
  container: {
    textAlign: 'right'
  },
  icons: {
    display: 'flex',
    position: 'absolute',
    top: '10px',
    right: '10px'
  },
  cardDescription: {
    maxHeight: 40,
    overflow: 'hidden'
  },
  moveButtonContainer: {
    display: 'inline-flex',
    flex: '0 0 auto',
    width: 48,
    color: 'rgba(0, 0, 0, 0.54)',
    height: 48,
    padding: 0,
    fontSize: '1.5rem',
    textAlign: 'center',
    transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'move'
  },
  moveButton: {
    display: 'flex'
  }
});

const itemSource = {
  beginDrag(props) {
    return {
      id: props.id,
      index: props.index,
      source: props.source
    };
  },
  isDragging(props, monitor) {
    return props.id === monitor.getItem().id;
  }
};

const itemSpecs = {
  canDrop() {
    return false;
  },

  hover(props, monitor, component) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;
    const dragId = monitor.getItem().id;
    const source = props.source;
    if (dragIndex === hoverIndex) return;
    const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
    const clientOffset = monitor.getClientOffset();
    const hoverClientY = clientOffset.y - hoverBoundingRect.top;
    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;
    props.moveItem(dragIndex, hoverIndex, dragId, source);
    monitor.getItem().index = hoverIndex;
  }
};

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging()
  };
}

function collectDrop(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget()
  };
}

function source(props) {
  return props.source;
}

class DragItem extends React.Component {
  state = {
    ungroup: false,
    anchorEl: null
  };

  handleUngroupClick = index => {
    const { ungroup } = this.state;
    this.setState({ ungroup: !ungroup });
    this.props.onUngroupClick(index);
  };

  handleRemove = () => {
    const { index, idx, onRemove } = this.props;
    this.setState({ anchorEl: null });
    this.props.onRemove(index, idx);
  };

  handleMenuClick = event => {
    event.stopPropagation();
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = event => {
    event.stopPropagation();
    this.setState({ anchorEl: null });
  };

  handleItemClick = () => {
    const { onItemClicked, id } = this.props;
    if (onItemClicked) onItemClicked(id);
  };

  render() {
    const {
      category,
      classes,
      style,
      connectDragSource,
      connectDragPreview,
      connectDropTarget,
      title,
      wide,
      readOnly = false,
      children,
      type,
      isDragging,
      index,
      ungroup,
      image,
      source,
      description
    } = this.props;
    const { anchorEl } = this.state;

    let unGroupButtonLabel =
      ungroup != null && ungroup == index
        ? 'Loskoppelen opslaan'
        : 'Loskoppelen';

    return connectDragPreview(
      connectDropTarget(
        <div
          className={classNames(classes.cardContainer, {
            [classes.cardDrag]: isDragging,
            [classes.cardContainerChild]: source == 'subitem'
          })}
        >
          {type == 'container' && (
            <div className={classes.container}>
              {!readOnly && (
                <div>
                  <Button
                    className={classes.groupingButton}
                    onClick={() => this.handleUngroupClick(index)}
                  >
                    {unGroupButtonLabel}
                  </Button>
                  <IconButton>
                    {connectDragSource(
                      <div>
                        <MoveIcon className={classes.menuIcon} />
                      </div>
                    )}
                  </IconButton>
                </div>
              )}
            </div>
          )}
          {type == 'drag' && (
            <Card className={classes.card}>
              <img
                className={classes.thumbnail}
                src={image || '/cms/images/dummy-image.png'}
              />
              <div className={classes.cardContentContainer}>
                <CardHeader
                  className={classes.cardHeader}
                  action={
                    <div className={classes.icons}>
                      <IconButton onClick={this.handleItemClick}>
                        <RedirectIcon />
                      </IconButton>
                      {!readOnly && (
                        <div>
                          <IconButton
                            onClick={this.handleMenuClick}
                            aria-owns={anchorEl ? 'simple-menu' : null}
                            aria-haspopup="true"
                          >
                            <MenuIcon />
                          </IconButton>

                          <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={this.handleClose}
                          >
                            <MenuItem onClick={this.handleRemove}>
                              Verwijderen
                            </MenuItem>
                          </Menu>
                        </div>
                      )}

                      {!readOnly && (
                        <div>
                          {connectDragSource(
                            <div className={classes.moveButtonContainer}>
                              <div className={classes.moveButton}>
                                <MoveIcon className={classes.menuIcon} />
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  }
                  subheader={category}
                />
                <CardContent className={classes.cardContent}>
                  <Typography
                    className={classNames(classes.cardText, classes.cardTitle)}
                    variant="h5"
                  >
                    {title}
                  </Typography>
                  <Typography
                    className={classNames(
                      classes.cardText,
                      classes.cardDescription
                    )}
                  >
                    {description}
                  </Typography>
                </CardContent>
              </div>
            </Card>
          )}
          {children && <div className={classes.children}>{children}</div>}
        </div>
      )
    );
  }
}

export default compose(
  DragSource(source, itemSource, collect),
  DropTarget(source, itemSpecs, collectDrop),
  withStyles(styles)
)(DragItem);
