import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  listItem: {
    width: '100%',
    paddingLeft: 0
  },
  listItemTitle: {
    color: '#242445',
    fontWeight: 400,
    display: 'block'
  },
  listItemValue: {
    textAlign: 'right',
    marginRight: 10,
    color: '#242445',
    fontWeight: 600
  },
  listItemSecondary: {
    color: '#7A7A91'
  }
});

/**
 * List item that contains a title and description
 */
class InfoSideBarInfoField extends React.Component {
  render() {
    const { classes, key, title, description } = this.props;

    return (
      <ListItem key={key} dense className={classes.listItem}>
        <ListItemText
          classes={{
            primary: classes.listItemTitle,
            secondary: classes.listItemSecondary
          }}
          primary={title}
          secondaryTypographyProps={{
            component: 'span'
          }}
          secondary={
            typeof description === 'object' && description.length
              ? description.map(text => text)
              : description
          }
        />
      </ListItem>
    );
  }
}

export default withStyles(styles)(InfoSideBarInfoField);
