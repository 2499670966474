import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { graphql } from 'react-apollo';
import {
  GQL_CHANGE_PASSWORD,
  GQL_LOGIN_CMS,
  GQL_RESET_PASSWORD
} from '../graphql';
import ResetPassword from '../ResetPassword';
import Login from '../Login';
import ConfirmInvitation from '../ConfirmInvitation';
import ConfirmReset from '../ConfirmReset';
import { SnackbarMessenger } from '@manakin/core';
import { connect } from 'react-redux';

const styles = theme => ({
  root: {
    width: '100vw',
    height: '100vh',
    padding: theme.spacing(2),
    backgroundColor: 'whitesmoke'
  },
  paper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  nativeInput: {
    padding: `${theme.spacing(2)}px 0`
  },
  input: {
    paddingTop: 0
  },
  label: {
    fontSize: '18px'
  }
});

const CmsLogin = graphql(GQL_LOGIN_CMS)(Login);
const CmsResetPassword = graphql(GQL_RESET_PASSWORD)(ResetPassword);
const CmsConfirmInvitation = graphql(GQL_CHANGE_PASSWORD)(ConfirmInvitation);
const CmsConfirmReset = graphql(GQL_CHANGE_PASSWORD)(ConfirmReset);

class CmsAuthenticator extends React.PureComponent {
  render() {
    const { match, classes } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.paper}>
          <Switch>
            <Route
              path={`${match.url}/login`}
              component={() => <CmsLogin styles={classes} />}
            />
            <Route path={`${match.url}/reset`} component={CmsResetPassword} />
            <Route
              path={`${match.url}/confirm-invitation/:token`}
              component={CmsConfirmInvitation}
            />
            <Route
              path={`${match.url}/confirm-reset/:token`}
              component={CmsConfirmReset}
            />
            <Redirect from="/" to={`${match.url}/login`} />
          </Switch>
        </div>
        <SnackbarMessenger />
      </div>
    );
  }
}

export default withStyles(styles, { name: 'AuthenticationCmsAuthenticator' })(
  CmsAuthenticator
);
