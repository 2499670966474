import React from "react";
import { styles } from "./styles";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import SingleDot from "./SingleDot";
import { WorkformLayout } from "@manakin/app-core";
import Button from "@material-ui/core/Button";
import { ProgressiveImage } from "@manakin/app-core";
import { connect } from "react-redux";

class HotspotsMap extends React.PureComponent {
  state = {
    isVisible: false
  };

  openAll = () => {
    this.setState(prevState => ({
      isVisible: !prevState.isVisible
    }));
  };

  render() {
    const {
      classes,
      question = "",
      introduction: _introduction = false,
      image = {},
      config = {},
      mapSpots = []
    } = this.props;
    const { literals = {} } = config;

    const introduction = _introduction
      ? _introduction
      : literals.CMS_CLICK_PLUS_FOR_INFO ||
        "Klik op de plusjes voor meer uitleg";

    return (
      <div className={classes.container}>
        <WorkformLayout
          question={question}
          instruction={introduction}
          loading={false}
          bigAnswer={true}
          renderAnswers={() => (
            <div className={classes.HotspotWrapper}>
              <ProgressiveImage
                preview={image && image.thumbnailUrl ? image.thumbnailUrl : ""}
                image={image ? image.url : ""}
              />
              {mapSpots &&
                mapSpots.map(hotSpots => (
                  <SingleDot {...this.state} {...hotSpots} key={hotSpots.id} />
                ))}

              <Button
                variant="contained"
                className={classes.button}
                onClick={this.openAll}
                color="primary"
              >
                {this.state.isVisible
                  ? literals.APP_CLOSE_ALL || "Sluit alles"
                  : literals.APP_OPEN_ALL || "Open Alles"}
              </Button>
            </div>
          )}
        />
      </div>
    );
  }
}

export default compose(
  connect(state => ({ config: state.config })),
  withStyles(styles, { name: "HotspotsMap" })
)(HotspotsMap);
