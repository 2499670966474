import React, { useEffect } from 'react';
import { Header } from './parts';
import { NewsCarousel } from '@manakin/app-core';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => {};

const AltDashboard = props => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { classes } = props;
  return (
    <div className={classes.root}>
      <Header />
      <NewsCarousel category={['GENERAL']} />
    </div>
  );
};

export default withStyles(styles)(AltDashboard);
