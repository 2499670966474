"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _styles = require("@material-ui/core/styles");

var _appCore = require("@manakin/app-core");

var _Typography = require("@material-ui/core/Typography");

var _Typography2 = _interopRequireDefault(_Typography);

var _reactRouterDom = require("react-router-dom");

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

var _reactRenderHtml = require("react-render-html");

var _reactRenderHtml2 = _interopRequireDefault(_reactRenderHtml);

var _recompose = require("recompose");

var _Done = require("@material-ui/icons/Done");

var _Done2 = _interopRequireDefault(_Done);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var styles = function styles(theme) {
  var _root;

  return {
    root: (_root = {
      border: "1px solid " + theme.manakin.defaultBorderColor[500],
      backgroundColor: theme.manakin.secondaryBackgroundColor[500],
      width: "100%",
      display: "flex",
      alignItems: "flex-start",
      textDecoration: "none",
      opacity: 0,
      transform: "translateX(-20px)",
      padding: "3.6rem 3rem 3rem",
      cursor: "pointer"
    }, _defineProperty(_root, theme.breakpoints.up("md"), {
      padding: "10.6rem 5rem 5rem"
    }), _defineProperty(_root, theme.breakpoints.up("lg"), {
      padding: "10rem",
      minHeight: "50rem"
    }), _root),
    content: {
      maxWidth: "42rem"
    },
    show: {
      opacity: 1,
      transform: "translateX(0)"
    },
    fontStyle: {
      margin: 0
    },
    titleStyle: {
      marginBottom: "1.6rem"
    },
    themeIconRoot: _defineProperty({
      position: "absolute",
      width: "6rem",
      height: "6rem",
      right: "2.6rem",
      top: "2.6rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "white",
      borderRadius: "100%"
    }, theme.breakpoints.up("lg"), {
      width: "10rem",
      height: "10rem"
    }),
    themeIcon: {
      width: "5rem",
      height: "5rem"
    },
    button: _defineProperty({
      display: "flex",
      alignItems: "center",
      marginTop: "2rem"
    }, theme.breakpoints.up("lg"), {
      marginTop: "3rem"
    }),
    longArrow: {
      marginRight: "2.8rem",
      "& .arrow": {
        background: theme.palette.primary[500]
      }
    },
    secondaryColors: {
      backgroundColor: theme.palette.primary[500],
      "& $fontStyle": {
        color: "white"
      },
      "& $longArrow": {
        "& .arrow": {
          background: "white"
        }
      }
    },
    hide: {
      display: "none"
    },
    disabled: {
      opacity: ".3"
    },
    doneIcon: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "1.5rem",
      width: "1.5rem",
      color: theme.palette.getContrastText(theme.palette.secondary[500])
    },
    done: _defineProperty({
      marginLeft: theme.spacing(2),
      flexShrink: 0,
      width: "30px",
      height: "30px",
      borderRadius: "100%",
      backgroundColor: theme.palette.secondary[500],
      zIndex: "2",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "absolute",
      right: "1rem",
      top: "1rem"
    }, theme.breakpoints.up("md"), {
      width: "60px",
      height: "60px",
      right: "4rem",
      top: "4rem"
    })
  };
};

var TestCard = function TestCard(props) {
  var classes = props.classes,
      idx = props.idx,
      show = props.show,
      _props$secondaryColor = props.secondaryColors,
      secondaryColors = _props$secondaryColor === undefined ? false : _props$secondaryColor,
      _props$hideIcon = props.hideIcon,
      hideIcon = _props$hideIcon === undefined ? false : _props$hideIcon,
      _props$url = props.url,
      url = _props$url === undefined ? "/test/8441" : _props$url,
      _props$title = props.title,
      title = _props$title === undefined ? "Maak de eindtoets" : _props$title,
      _props$content = props.content,
      content = _props$content === undefined ? "Maak de eindtoets om te laten zien wat je geleerd hebt over dit onderwerp en behaal je certificaat" : _props$content,
      _props$buttonText = props.buttonText,
      buttonText = _props$buttonText === undefined ? "Start eindtoets" : _props$buttonText,
      _props$disabled = props.disabled,
      disabled = _props$disabled === undefined ? false : _props$disabled,
      history = props.history,
      boxFinished = props.boxFinished,
      item = props.item;


  var handleLinkClick = function handleLinkClick(e) {
    e.preventDefault();
    if (disabled) {
      if (props.onDisabledClick) props.onDisabledClick();
    } else {
      history.push(url);
    }
  };

  return _react2.default.createElement(
    "div",
    {
      className: (0, _classnames2.default)(classes.root, _defineProperty({}, classes.show, show), _defineProperty({}, classes.secondaryColors, secondaryColors), _defineProperty({}, classes.disabled, disabled)),
      onClick: handleLinkClick,
      style: {
        transition: "transform .5s ." + (idx + 1) + "s, opacity .5s ." + (idx + 1) + "s"
      }
    },
    _react2.default.createElement(
      "div",
      {
        className: (0, _classnames2.default)(classes.themeIconRoot, _defineProperty({}, classes.hide, hideIcon))
      },
      _react2.default.createElement(_appCore.ThemeIcon, { variant: "nose", className: classes.themeIcon })
    ),
    boxFinished && _react2.default.createElement(
      "div",
      { className: classes.done },
      _react2.default.createElement(
        "span",
        { className: classes.doneIcon },
        _react2.default.createElement(_Done2.default, { classes: { root: classes.iconRoot } })
      )
    ),
    _react2.default.createElement(
      "div",
      { className: classes.content },
      _react2.default.createElement(
        _Typography2.default,
        {
          variant: "h3",
          className: (0, _classnames2.default)(classes.fontStyle, classes.titleStyle)
        },
        title
      ),
      _react2.default.createElement(
        _Typography2.default,
        {
          variant: "body1",
          component: "div",
          className: classes.fontStyle
        },
        (0, _reactRenderHtml2.default)(content || "")
      ),
      _react2.default.createElement(
        "div",
        { className: classes.button },
        _react2.default.createElement(_appCore.LongArrow, { className: classes.longArrow }),
        _react2.default.createElement(
          _Typography2.default,
          {
            variant: "body1",
            className: (0, _classnames2.default)(classes.buttonStyle, classes.fontStyle)
          },
          buttonText
        )
      )
    )
  );
};

exports.default = (0, _recompose.compose)(_reactRouterDom.withRouter, (0, _styles.withStyles)(styles, { name: "AppTestCard" }))(TestCard);