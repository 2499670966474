import React from "react";
import { Header } from "./parts";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { compose } from "recompose";
import { LoadImage } from "@manakin/app-core";
import { Link } from "react-router-dom";
import { styles } from "./styles";

class FactCards extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { classes, FactCardsData = {}, fromBox = false } = this.props;

    return (
      <div className={classes.root}>
        <Header {...FactCardsData} fromBox={fromBox} />
        <div className={classes.container}>
          <Grid container spacing={4}>
            {FactCardsData.factCards &&
              FactCardsData.factCards.map((factCard, i) => (
                <React.Fragment key={factCard ? factCard.id : `null-${i}`}>
                  {factCard && (
                    <Grid
                      className={classes.singlecard}
                      item
                      xs={12}
                      sm={6}
                      md={4}
                    >
                      <Link
                        className={classes.cardhover}
                        to={`/factcard/${factCard.id}`}
                      >
                        <div className={classes.factCard}>
                          <div className={classes.imageWrapper}>
                            <div className={classes.image}>
                              <LoadImage
                                src={factCard.image ? factCard.image.url : ""}
                              />
                            </div>
                          </div>
                          <Typography
                            component="h6"
                            variant="h6"
                            className={classes.heading}
                          >
                            {factCard.title}
                          </Typography>
                          <Typography
                            classes={{ body1: classes.body }}
                            component="p"
                            variant="body1"
                          >
                            {factCard.text}
                          </Typography>
                        </div>
                      </Link>
                    </Grid>
                  )}
                </React.Fragment>
              ))}
          </Grid>
        </div>
      </div>
    );
  }
}

export default compose(withStyles(styles, { name: "FactCards" }))(FactCards);
