"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _ExpansionPanel = require("@material-ui/core/ExpansionPanel");

var _ExpansionPanel2 = _interopRequireDefault(_ExpansionPanel);

var _ExpansionPanelSummary = require("@material-ui/core/ExpansionPanelSummary");

var _ExpansionPanelSummary2 = _interopRequireDefault(_ExpansionPanelSummary);

var _ExpansionPanelDetails = require("@material-ui/core/ExpansionPanelDetails");

var _ExpansionPanelDetails2 = _interopRequireDefault(_ExpansionPanelDetails);

var _styles = require("@material-ui/core/styles");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var styles = function styles(theme) {
  return {
    expansionPanelRoot: {
      padding: 0,
      boxShadow: "none",
      "&:before": {
        display: "none"
      }
    },
    summaryRoot: {
      fontFamily: theme.manakin.defaultContentFont,
      padding: 0
    },
    detailRoot: {
      fontFamily: theme.manakin.defaultContentFont,
      padding: 0,
      margin: "0 0 0 3.4rem"
    },
    expanded: {
      margin: "0 0 16px 0"
    }
  };
};

var AppExpansionPanel = function (_React$PureComponent) {
  _inherits(AppExpansionPanel, _React$PureComponent);

  function AppExpansionPanel() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, AppExpansionPanel);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = AppExpansionPanel.__proto__ || Object.getPrototypeOf(AppExpansionPanel)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
      expanded: false
    }, _this.handleChange = function () {
      _this.setState(function (prevProps) {
        return {
          expanded: !prevProps.expanded
        };
      });
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(AppExpansionPanel, [{
    key: "render",
    value: function render() {
      var _props = this.props,
          classes = _props.classes,
          _props$summary = _props.summary,
          summary = _props$summary === undefined ? "" : _props$summary,
          _props$details = _props.details,
          details = _props$details === undefined ? "" : _props$details;
      var expanded = this.state.expanded;


      return _react2.default.createElement(
        _ExpansionPanel2.default,
        {
          expanded: expanded,
          onChange: this.handleChange,
          classes: {
            root: classes.expansionPanelRoot,
            expanded: classes.expanded
          }
        },
        _react2.default.createElement(
          _ExpansionPanelSummary2.default,
          { classes: { root: classes.summaryRoot } },
          summary
        ),
        _react2.default.createElement(
          _ExpansionPanelDetails2.default,
          { classes: { root: classes.detailRoot } },
          details
        )
      );
    }
  }]);

  return AppExpansionPanel;
}(_react2.default.PureComponent);

exports.default = (0, _styles.withStyles)(styles, { name: "AppExpansionPanel" })(AppExpansionPanel);