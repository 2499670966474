"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

var _styles = require("@material-ui/core/styles");

var _selectors = require("./selectors");

var _reactRedux = require("react-redux");

var _recompose = require("recompose");

var _appCore = require("@manakin/app-core");

var _reactScrollToComponent = require("react-scroll-to-component");

var _reactScrollToComponent2 = _interopRequireDefault(_reactScrollToComponent);

var _lib = require("../lib");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var styles = function styles(theme) {
  return {
    wrapper: _extends({}, theme.manakin.largeWrapper, {
      transition: "max-width .3s"
    }),
    smallWrapper: _extends({}, theme.manakin.defaultWrapper, {
      backgroundColor: theme.manakin.primaryColor[500],
      paddingBottom: "20rem"
    }),
    loading: {
      width: "100vw",
      height: "100vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },
    milestone: {
      width: "100%",
      fontFamily: theme.manakin.defaultTitleFont,
      zIndex: 1,
      fontSize: "12px",
      letterSpacing: ".4rem",
      lineHeight: "2rem",
      textAlign: "center",
      textTransform: "uppercase",
      position: "relative",
      marginBottom: "2.2rem"
    }
  };
};

var Boxes = function (_React$PureComponent) {
  _inherits(Boxes, _React$PureComponent);

  function Boxes(props) {
    _classCallCheck(this, Boxes);

    var _this = _possibleConstructorReturn(this, (Boxes.__proto__ || Object.getPrototypeOf(Boxes)).call(this, props));

    _this.ScrollEl = _react2.default.createRef();
    return _this;
  }

  _createClass(Boxes, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      var _this2 = this;

      var _props = this.props,
          _props$config = _props.config,
          config = _props$config === undefined ? {} : _props$config,
          _props$user = _props.user,
          user = _props$user === undefined ? {} : _props$user,
          _props$option = _props.option,
          option = _props$option === undefined ? false : _props$option;
      var _config$pages = config.pages,
          pages = _config$pages === undefined ? {} : _config$pages;

      var doScroll = pages.dashboard ? pages.dashboard.scrollTo || false : false;

      if (!option || option != 'no-scroll') {
        setTimeout(function () {
          if (_this2.ScrollEl && _this2.ScrollEl.current !== null && doScroll) {
            (0, _reactScrollToComponent2.default)(_this2.ScrollEl, { offset: -200, align: 'top', duration: 200 });
          }
        }, 1);
      }
    }
  }, {
    key: "render",
    value: function render() {
      var _this3 = this;

      var _props2 = this.props,
          listView = _props2.listView,
          classes = _props2.classes,
          data = _props2.data,
          _props2$percentage = _props2.percentage,
          percentage = _props2$percentage === undefined ? {} : _props2$percentage,
          _props2$listViewOnly = _props2.listViewOnly,
          listViewOnly = _props2$listViewOnly === undefined ? false : _props2$listViewOnly,
          _props2$resume = _props2.resume,
          resume = _props2$resume === undefined ? {} : _props2$resume;

      var boxes = data.boxes || [];
      var _milestones = data.milestones || [];
      var _boxes = [];
      var newest = null;

      boxes.forEach(function (e) {
        return e.forEach(function (box) {
          _boxes.push(box);
          box.percentage = percentage[box.id];
          box.new = false;
        });
      });

      if (_boxes.find(function (e) {
        return e.percentage !== 100;
      })) {
        newest = _boxes.find(function (e) {
          return e.percentage !== 100;
        }).id;
      }

      var milestones = {};
      _milestones && _milestones.forEach(function (item) {
        milestones[item.box.id] = {
          id: item.box.id,
          text: item.text
        };
      });

      return _react2.default.createElement(
        "div",
        {
          className: (0, _classnames2.default)(classes.wrapper, _defineProperty({}, classes.smallWrapper, listView || listViewOnly))
        },
        boxes.length > 0 && boxes.map(function (_item, i) {
          return _react2.default.createElement(
            _react2.default.Fragment,
            { key: i },
            _item[0] && _item[0].id && _react2.default.createElement(
              "div",
              { key: _item[0].id },
              milestones[_item[0].id] && _react2.default.createElement(
                "div",
                { className: classes.milestone },
                _react2.default.createElement(
                  "span",
                  null,
                  milestones[_item[0].id].text
                )
              ),
              _item.map(function (item, idx) {
                return _react2.default.createElement(
                  _react2.default.Fragment,
                  { key: idx },
                  item && item.id && _react2.default.createElement(_appCore.BoxCard, {
                    ref: resume && resume.id && item.id === resume.id ? function (section) {
                      return _this3.ScrollEl = section;
                    } : null,
                    key: item.id,
                    content: item,
                    listView: listView || listViewOnly,
                    idx: idx + i,
                    percentage: percentage[item.id]
                  })
                );
              })
            )
          );
        })
      );
    }
  }]);

  return Boxes;
}(_react2.default.PureComponent);

exports.default = (0, _recompose.compose)((0, _styles.withStyles)(styles, { name: "AppBoxes" }), (0, _reactRedux.connect)(function (state) {
  return {
    listView: (0, _selectors.isListView)(state),
    config: state.config
  };
}))(Boxes);