'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styles = require('@material-ui/core/styles');

var _recompose = require('recompose');

var _ = require('../');

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var styles = function styles(theme) {
  return {
    root: _defineProperty({
      width: '100%',
      height: '6.8rem',
      backgroundColor: 'white',
      position: 'relative',
      alignItems: 'center',
      display: 'flex',
      marginBottom: '3rem'
    }, theme.breakpoints.up('md'), {
      height: '10rem',
      marginBottom: '4rem'
    }),
    small: _defineProperty({
      marginBottom: '1rem'
    }, theme.breakpoints.up('md'), {
      marginBottom: '2rem'
    }),
    image: _defineProperty({
      width: '7rem',
      height: '100%',
      position: 'relative'
    }, theme.breakpoints.up('md'), {
      width: '10rem'
    }),
    content: _defineProperty({
      font: theme.manakin.defaultTitleFont,
      fontSize: '1.4rem',
      fontWeight: 'bold',
      letterSpacing: '0.17rem',
      lineHeight: '2rem',
      color: theme.manakin.defaultContentColor[500],
      paddingLeft: '1.6rem',
      width: '100%'
    }, theme.breakpoints.up('md'), {
      fontSize: '1.8rem',
      letterSpacing: '.2rem',
      paddingLeft: '4rem'
    }),
    children: {
      padding: '0 1rem'
    }
  };
};

var SingleBox = function (_React$PureComponent) {
  _inherits(SingleBox, _React$PureComponent);

  function SingleBox() {
    _classCallCheck(this, SingleBox);

    return _possibleConstructorReturn(this, (SingleBox.__proto__ || Object.getPrototypeOf(SingleBox)).apply(this, arguments));
  }

  _createClass(SingleBox, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          children = _props.children,
          classes = _props.classes,
          data = _props.data,
          _props$variant = _props.variant,
          variant = _props$variant === undefined ? 'default' : _props$variant;

      var imageUrl = data.image ? data.image.url : '';
      var placeholder = data.image ? data.image.thumbnailUrl : '';

      return _react2.default.createElement(
        'div',
        {
          className: (0, _classnames2.default)(classes.root, _defineProperty({}, classes.small, variant == 'small'))
        },
        _react2.default.createElement(
          'div',
          { className: classes.image },
          _react2.default.createElement(_.LoadImage, { align: 'right', src: imageUrl, placeholder: placeholder })
        ),
        _react2.default.createElement(
          'div',
          { className: classes.content },
          data.name
        ),
        _react2.default.createElement(
          'div',
          { className: classes.children },
          children
        )
      );
    }
  }]);

  return SingleBox;
}(_react2.default.PureComponent);

exports.default = (0, _recompose.compose)((0, _styles.withStyles)(styles))(SingleBox);