import React from 'react';
import { ContentExpansionPanel, MultiSelect } from '@manakin/core';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid/Grid';
import IconButton from '@material-ui/core/IconButton';
import FilterIcon from '@material-ui/icons/Tune';
import CloseIcon from '@material-ui/icons/Close';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import { compose } from 'recompose';
import { connect } from 'react-redux';

const styles = theme => ({
  root: {},
  list: {
    width: 350,
    marginTop: 50,
    marginBottom: 50,
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  drawer: {
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
    display: 'grid',
    height: '100%'
  },
  titleBar: {
    right: 0,
    width: 350 + theme.spacing(8),
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
    background: 'white',
    position: 'fixed',
    zIndex: 1,
    top: 0
  },
  titleBarIcon: {
    margin: `0 ${theme.spacing(4)}px`
  },
  titleBarClose: {
    width: 50,
    height: 50,
    backgroundColor: '#E74D66',
    borderRadius: 0,
    position: 'absolute',
    top: 0,
    right: 0
  },
  titleBarCloseIcon: {
    color: 'white',
    width: 20
  },
  checkboxHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: '#282828'
  },
  colorChecked: {
    '&$colorChecked': {
      color: '#34C279'
    }
  },
  filterSaveButton: {
    width: 350 + theme.spacing(8),
    border: 0,
    borderRadius: 0,
    backgroundColor: '#34C279',
    color: 'white',
    position: 'fixed',
    bottom: 0,
    height: 50,
    '&:hover': {
      backgroundColor: '#34C279'
    }
  },
  maximumContentHeight: {
    maxHeight: 'calc(100vh - 200px)',
    overflowY: 'auto'
  }
});

/**
 * Drawer containing an list of dropdowns that can be expanded to show filters
 */
class FilterDrawer extends React.PureComponent {
  handleProgramsChange = programs => {
    this.props.onProgramsChange(programs);
  };

  /**
   * Render a DropDown list item
   *
   * @param filterType Filter to use as item title
   * @returns {*}
   */
  renderDropDownListItem = filterType => {
    const { classes, onFilterExpansion, onFilterChange } = this.props;

    return (
      <div key={filterType.id}>
        <ContentExpansionPanel
          key={filterType.id}
          expanded={filterType.isExpanded}
          onExpansion={() => onFilterExpansion(filterType.id)}
          title={filterType.name}
          titleStyle={'uppercase'}
          content={
            <div className={classes.maximumContentHeight}>
              <FormGroup>
                {filterType &&
                  filterType.filters.map(filter => {
                    return (
                      <FormControlLabel
                        key={filter.id}
                        control={
                          <Checkbox
                            checked={filter.enabled}
                            onChange={() =>
                              onFilterChange(filterType.id, filter.id)
                            }
                            value={filter.id}
                            classes={{
                              checked: classes.colorChecked
                            }}
                          />
                        }
                        classes={{
                          label: classes.checkboxHeading
                        }}
                        label={filter.name}
                      />
                    );
                  })}
              </FormGroup>
            </div>
          }
        />
      </div>
    );
  };

  render() {
    const {
      classes,
      form,
      filters,
      open,
      onToggleDrawer,
      onSaveFilters,
      programs,
      selectedPrograms = [],
      programsLoading,
      config = {}
    } = this.props;
    const { literals = {} } = config;

    return (
      <div className={classes.root}>
        <Drawer anchor="right" open={open} onClose={() => onToggleDrawer()}>
          <div className={classes.drawer}>
            <Grid container alignItems="center" className={classes.titleBar}>
              <Typography variant="h6">
                {literals.CMS_DO_FILTER || 'Filteren'}
              </Typography>
              <FilterIcon className={classes.titleBarIcon} />
              <IconButton
                className={classes.titleBarClose}
                onClick={() => onToggleDrawer()}
              >
                <CloseIcon className={classes.titleBarCloseIcon} />
              </IconButton>
            </Grid>
            <List className={classes.list}>
              {filters &&
                filters.map(filterType => {
                  return this.renderDropDownListItem(filterType);
                })}

              {programs && (
                <MultiSelect
                  options={programs}
                  loading={programsLoading}
                  label={literals.CMS_PROGRAMS || "Programma's"}
                  form={form}
                  name="programs"
                  initialValue={selectedPrograms || []}
                  onChange={this.handleProgramsChange}
                />
              )}
            </List>
          </div>
        </Drawer>
      </div>
    );
  }
}

export default compose(
  connect(state => ({ config: state.config })),
  withStyles(styles)
)(FilterDrawer);
