import React, { useState, useEffect, useReducer } from 'react';
import Header from './parts/Header';
import TeacherHeader from './parts/TeacherHeader';
import BoxLayout from './parts/BoxLayout';
import { BoxService, Loader, AccessControl } from '@manakin/app-core';
import { getAppUser } from '@manakin/authentication/selectors';
import { isListView } from '@manakin/app-core/Boxes/selectors';
import { withQuery } from '@manakin/core';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import { useGetConfig } from '@manakin/hooks';
import { goBackAppBar } from '@manakin/app-core/AppBar/actions';

const mapStateToProps = state => ({
  config: state.config,
  appUser: getAppUser(state),
  listView: isListView(state)
});

const mapDispatchToProps = dispatch => ({
  goBackAppBar: data => dispatch(goBackAppBar(data))
});

const styles = theme => ({
  loading: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
});

const Dashboard = props => {
  const { appUser, listView, classes, config = {}, match = {} } = props;
  const { params = {} } = match;

  const [programData, setData] = useReducer(
    (state, data) => {
      return data.program || {}
    },
    {}
  )

  const [percentage, setPercentage] = useState({});
  const [loading, setLoading] = useState(true);
  const [roles, setRoles] = useState([]);
  const [resume, setResume] = useState(null);

  const handleLoadChange = data => {
    setLoading(data);
  };

  const handleData = (data, percentage, appUserData) => {
    setData(data);
    setPercentage(percentage);
    setResume(appUserData);
  };

  useEffect(() => {
    if (!loading) {
      props.goBackAppBar('/altDashboard');
    }
  }, [loading]);

  useEffect(() => {
    if (appUser && appUser.roles) {
      const roles = appUser.roles.map(item => item.name);
      setRoles(roles);
    }
  }, [appUser]);

  const listViewOnly = useGetConfig(
    'pages',
    'dashboard',
    'listViewOnly',
    config
  );

  return (
    <div className={classes.root}>
      <BoxService onNewData={handleData} onLoadChange={handleLoadChange}>
        {loading ? (
          <div className={classes.loading}>
            <Loader />
          </div>
        ) : (
            <React.Fragment>
              <AccessControl role={['SCHOOL_MANAGER', 'TEACHER']}>
                <TeacherHeader
                  user={appUser}
                  data={programData}
                  loading={loading}
                  roles={roles}
                />
              </AccessControl>
              <AccessControl not={['SCHOOL_MANAGER', 'TEACHER']}>
                <Header
                  user={appUser}
                  data={programData}
                  loading={loading}
                  roles={roles}
                />
              </AccessControl>
              <BoxLayout
                user={appUser}
                listView={listViewOnly ? listViewOnly : listView}
                data={programData}
                percentage={percentage}
                listViewOnly={listViewOnly}
                resume={resume}
                option={params.dashboardOption || false}
              />
            </React.Fragment>
          )}
      </BoxService>
    </div>
  );
};

export default compose(
  withStyles(styles),
  withQuery,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Dashboard);
