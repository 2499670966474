'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactApollo = require('react-apollo');

var _graphql = require('./graphql');

var _recompose = require('recompose');

var _core = require('@manakin/core');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var SchoolclassFromMainOrganisation = function SchoolclassFromMainOrganisation(props) {
  var data = props.data,
      form = props.form;

  //State hooke

  var _useState = (0, _react.useState)([]),
      _useState2 = _slicedToArray(_useState, 2),
      options = _useState2[0],
      setOptions = _useState2[1];

  //Effect hooks


  (0, _react.useEffect)(function () {
    if (!data.loading) {
      var mainOrganisation = data.mainOrganisation;

      var array = [];
      if (mainOrganisation && mainOrganisation.organisations && mainOrganisation.organisations.length) {
        mainOrganisation.organisations.forEach(function (organisation) {
          return organisation.schools && organisation.schools.forEach(function (school) {
            return school.classes && school.classes.forEach(function (schoolClass) {
              array.push(schoolClass);
            });
          });
        });
      }
      setOptions(array);
    }
  }, [data.loading]);

  return _react2.default.createElement(_core.SelectField, {
    loading: data.loading,
    options: options,
    name: 'affiliate',
    label: 'filialen',
    form: form,
    initialValue: { id: form.fields && form.fields.affiliate || '' }
  });
};

exports.default = (0, _recompose.compose)((0, _reactApollo.graphql)(_graphql.GQL_FETCH_MAIN_ORGANISATION, {
  options: function options(props) {
    return {
      variables: {
        id: props.id
      }
    };
  }
}))(SchoolclassFromMainOrganisation);