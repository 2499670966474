import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import { styles } from "./styles";
import { GQL_FETCH_CMS_FACT_CARD } from "./graphql";
import { withApollo } from "react-apollo";
import download from "downloadjs";
import { connect } from "react-redux";
import { showSnackbarMessage } from "@manakin/core/actions";

class FactCardReferral extends React.PureComponent {
  state = {
    factCards: [],
    loading: true
  };

  componentDidMount() {
    const { data } = this.props;
    if (data.referrals && data.referrals.length) {
      data.referrals.forEach((item, idx) => {
        this.fetchFactCard(item.factCardId).then(result => {
          this.setState(prevState => ({
            factCards: [...prevState.factCards, result.data.factCard]
          }));
        });
      });
    }
  }

  fetchFactCard = id => {
    return this.props.client.query({
      query: GQL_FETCH_CMS_FACT_CARD,
      variables: {
        id: id
      }
    });
  };

  handleClick = (id, factCard) => {
    if (factCard.file && factCard.file.url) {
      const { config = {}, processDownloadProgress } = this.props;
      const { literals = {} } = config;
      processDownloadProgress(literals.APP_DOWNLOAD_FACTCARD_IN_PROGRESS || "Factcard wordt gedownload");
      download(factCard.file.url);
    } else {
      const { customLink = false } = this.props;
      if (!customLink) {
        this.props.history.push(`/factcard/${id}`);
      } else {
        this.props.history.push({
          pathname: `/factcard/${id}`,
          state: { ...customLink.state }
        });
      }
    }
  };

  handleNext = () => {
    if (this.props.onFinished) this.props.onFinished();
  };

  render() {
    const { data, classes, config = {}, withNext = false } = this.props;
    const { literals = {} } = config;
    const { factCards = [] } = this.state;
    const factCardLength = data.referrals ? data.referrals.length : 0;

    if (factCards.length <= 0 || factCards[0] === null) {
      return "";
    }
    return (
      <div>
        <div className={classes.root}>
          {data.referrals &&
            factCards.length === data.referrals.length &&
            data.referrals.map((item, idx) => (
              <div
                className={classNames(classes.referralRoot, {
                  [classes.multi]: factCardLength == 2
                })}
                key={item.id}
              >
                <div className={classes.image}>
                  <img
                    className={classes.imageSrc}
                    src={factCards[idx].image ? factCards[idx].image.url : null}
                  />
                </div>
                <div className={classes.content}>
                  <div className={classes.textContent}>
                    <Typography
                      component="h4"
                      variant="subtitle1"
                      classes={{ subtitle1: classes.title }}
                    >
                      {item.title ||
                        `${literals.APP_LOOK_AT_FACTCARD_ABOUT ||
                        "bekijk deze factard over"} ${factCards[idx].title}`}
                    </Typography>
                    <Typography
                      component="h4"
                      variant="body1"
                      classes={{ body1: classes.body }}
                    >
                      {item.text ||
                        literals.APP_READ_MORE_FACTCARD ||
                        "lees meer over de inhoud van deze factcard"}
                    </Typography>
                  </div>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={() => this.handleClick(factCards[idx].id, factCards[idx])}
                  >
                    {item.actionTitle || literals.APP_TO_FACTCARD || "Naar de factcard"}
                  </Button>
                </div>
              </div>
            ))}
        </div>
        {withNext && (
          <Button
            variant="contained"
            color="primary"
            className={classes.nextButton}
            onClick={this.handleNext}
          >
            {literals.APP_NEXT || "Volgende"}
          </Button>
        )}
      </div>
    );
  }
}

export default compose(
  connect(
    state => ({ config: state.config }),
    dispatch => ({
      processDownloadProgress: content =>
        dispatch(
          showSnackbarMessage({
            text: content,
            variant: "success"
          })
        )
    })
  ),
  withRouter,
  withApollo,
  withStyles(styles, { name: "AppFactcardReferral" })
)(FactCardReferral);
