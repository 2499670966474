import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';
import renderHTML from 'react-render-html';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  root: {
    marginBottom: '-5rem',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexWrap: 'wrap'
    }
  },
  card: {
    minHeight: '30rem',
    padding: '2rem',
    display: 'flex',
    marginBottom: '1.5rem',
    backgroundSize: 'cover',
    '&:last-child': {
      marginBottom: 0,
      [theme.breakpoints.up('md')]: {
        marginBottom: '5rem'
      }
    },
    [theme.breakpoints.up('md')]: {
      width: 'calc(50% - 2.5rem)',
      marginBottom: '5rem',
      marginRight: '2.5rem',
      padding: '5rem'
    },
    '&:nth-child(even)': {
      [theme.breakpoints.up('md')]: {
        marginRight: 0,
        marginLeft: '2.5rem'
      }
    }
  },
  content: {
    backgroundColor: 'white',
    padding: '2rem',
    width: '100%',
    opacity: 0,
    transition: 'opacity .3s',
    [theme.breakpoints.up('md')]: {
      padding: '3.5rem 5rem 4rem'
    },
    '&:hover': {
      opacity: 1
    }
  },
  title: {
    fontFamily: theme.manakin.defaultContentFont,
    fontSize: '3rem',
    lineHeight: '4.4rem',
    fontWeight: '800',
    letterSpacing: 0
  },
  text: {
    fontFamily: theme.manakin.secondaryTitleFont,
    fontSize: '1.8rem',
    lineHeight: '3rem',
    [theme.breakpoints.up('md')]: {
      fontSize: '2.2rem',
      lineHeight: '4rem'
    },
    '& li': {
      margin: '0 0 0 3rem'
    }
  }
});

class Moodboard extends React.PureComponent {
  render() {
    const { data, classes } = this.props;

    return (
      <div className={classes.root}>
        {data.boards &&
          data.boards.map(item => (
            <div
              className={classes.card}
              style={{
                backgroundImage: `url('${item.image ? item.image.url : ''}')`
              }}
              key={item.id}
            >
              <div className={classes.content}>
                {item.title && (
                  <Typography
                    component="h4"
                    variant="h6"
                    classes={{ h6: classes.title }}
                    className="smallUnderline"
                  >
                    {item.title}
                  </Typography>
                )}
                <div className={classes.text}>
                  {renderHTML(item.text || '')}
                </div>
              </div>
            </div>
          ))}
      </div>
    );
  }
}

export default compose(withStyles(styles, { name: 'AppMoodboard' }))(Moodboard);
