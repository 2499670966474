import React from "react";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { LoadImage } from "@manakin/app-core";
import { styles } from "./styles";
import { getBoxId } from "@manakin/app-core/Boxes/selectors";
import { connect } from "react-redux";
import { useQuery } from "@apollo/react-hooks";
import { GQL_FETCH_BOX } from "../graphql";

const Header = props => {
  const {
    classes,
    headerImage: _headerImage,
    config = {},
    fromBox = false,
    boxId
  } = props;
  const { literals = {} } = config;
  const headerImage = _headerImage ? _headerImage.url : "";

  const { loading, error, data = {} } = useQuery(GQL_FETCH_BOX, {
    variables: { id: boxId }
  });

  let box = {};
  if (data) {
    box = data.box || {};
  }

  return (
    <div className={classes.root} color="light">
      <LoadImage src={headerImage} withOverlay={false} />
      <div className={classes.wrapper}>
        <div className={classes.content}>
          <Typography component="h1" variant="h2" className="underline">
            {fromBox
              ? literals.APP_BOXCARDS_OVERVIEW_TITLE
              : literals.APP_FACTCARDS_OVERVIEW_TITLE}
          </Typography>
          <Typography component="p" variant="body1">
            {fromBox
              ? literals.APP_BOXCARDS_OVERVIEW_CONTENT_FRONT +
                " " +
                (box.name || "") +
                "."
              : literals.APP_FACTCARDS_OVERVIEW_CONTENT}{" "}
            <br /> {literals.APP_BOXCARDS_OVERVIEW_CONTENT_BACK}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default compose(
  withStyles(styles, { name: "FactCardsHeader" }),
  connect(state => ({
    boxId: getBoxId(state),
    config: state.config
  }))
)(Header);
