"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _selectors = require("@manakin/authentication/selectors");

var _actions = require("./actions");

var _reactRedux = require("react-redux");

var _recompose = require("recompose");

var _FormControl = require("@material-ui/core/FormControl");

var _FormControl2 = _interopRequireDefault(_FormControl);

var _Select = require("@material-ui/core/Select");

var _Select2 = _interopRequireDefault(_Select);

var _MenuItem = require("@material-ui/core/MenuItem");

var _MenuItem2 = _interopRequireDefault(_MenuItem);

var _icons = require("@material-ui/icons");

var _selectors2 = require("./selectors");

var _graphql = require("./graphql");

var _reactApollo = require("react-apollo");

var _lib = require("../lib");

var _moment = require("moment");

var _moment2 = _interopRequireDefault(_moment);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

var ProgramsDropdown = function ProgramsDropdown(props) {
  var inputProps = props.inputProps,
      appUser = props.appUser,
      selectProgram = props.selectProgram,
      _props$config = props.config,
      config = _props$config === undefined ? {} : _props$config,
      _program = props.program;
  var _config$general = config.general,
      general = _config$general === undefined ? {} : _config$general;


  var showProgramDropdown = general.showProgramDropdown != undefined ? general.showProgramDropdown : true;

  //state hooks

  var _useState = (0, _react.useState)(_program || ""),
      _useState2 = _slicedToArray(_useState, 2),
      program = _useState2[0],
      setProgram = _useState2[1];

  var reducer = function reducer(state, action) {
    if (!action) return [].concat(_toConsumableArray(state));
    if (action.type && action.type === 'new') {
      if (!action.data.some(function (i) {
        return i.id === program;
      })) {
        setProgram(action.data[0].id);
      }
      return [].concat(_toConsumableArray(action.data));
    }
    if (state.some(function (i) {
      return i.id === action.id;
    })) {
      return [].concat(_toConsumableArray(state));
    } else {
      var arr = [].concat(_toConsumableArray(state));
      arr.push(action);
      return [].concat(_toConsumableArray(arr));
    }
  };

  var _useReducer = (0, _react.useReducer)(reducer, []),
      _useReducer2 = _slicedToArray(_useReducer, 2),
      options = _useReducer2[0],
      setOptions = _useReducer2[1];

  //effect hooks


  (0, _react.useEffect)(function () {
    if (!props.SERVER_INFO.loading) {
      var tempArr = [];
      appUser.licenses && appUser.licenses.forEach(function (item) {
        if (!item.licenseGroup || !item.licenseGroup.product || !item.licenseGroup.product.program) return;
        if ((0, _moment2.default)(item.validUntil).isAfter(props.SERVER_INFO.serverInfo.currentDate) && !tempArr.some(function (i) {
          return i.id === item.licenseGroup.product.program.id;
        }) || item.validUntil === null) {
          tempArr.push({
            id: item.licenseGroup.product.program.id,
            label: item.licenseGroup.product.program.name
          });
        }
      });

      if (tempArr && tempArr.length) {
        setOptions({
          type: 'new',
          data: tempArr
        });
      }
    }
  }, [props.SERVER_INFO.loading, appUser.licenses]);

  (0, _react.useEffect)(function () {
    if (!props.loading && props.data) {
      var _props$data = props.data,
          data = _props$data === undefined ? {} : _props$data;
      var _data$groups = data.groups,
          groups = _data$groups === undefined ? {} : _data$groups;

      if (groups.groups && groups.groups.length && (0, _lib.isTeacher)(appUser)) {
        addClassesToOptions(groups.groups);
      }
    }
  }, [props.loading, props.data]);

  (0, _react.useEffect)(function () {
    if (!props.MANAGERS.loading) {
      var MANAGERS = props.MANAGERS;
      var _MANAGERS$groups = MANAGERS.groups,
          groups = _MANAGERS$groups === undefined ? {} : _MANAGERS$groups;

      if (groups.groups && groups.groups.length && (0, _lib.isManager)(appUser)) {
        addSchoolToOptions(groups.groups);
      }
    }
  }, [props.MANAGERS.loading]);

  (0, _react.useEffect)(function () {
    if (program) {
      selectProgram(program);
    }
  }, [program]);

  //functions
  var addSchoolToOptions = function addSchoolToOptions(groups) {
    groups.forEach(function (group, index) {
      addToOptions(group, _graphql.GQL_FETCH_SCHOOL, false, true, index);
    });
  };
  var addClassesToOptions = function addClassesToOptions(groups) {
    groups.forEach(function (group, index) {
      addToOptions(group, _graphql.GQL_FETCH_SCHOOL_CLASS, true, false, index);
    });
  };

  var addToOptions = function addToOptions(group, query, teacher, manager, idx) {
    var client = props.client;


    client.query({
      query: query,
      variables: {
        id: group.id
      }
    }).then(function (r) {
      if (manager && r.data && !r.errors || teacher && r.data && !r.errors) {
        var _r$data = r.data,
            _r$data$school = _r$data.school,
            school = _r$data$school === undefined ? {} : _r$data$school,
            _r$data$schoolClass = _r$data.schoolClass,
            schoolClass = _r$data$schoolClass === undefined ? {} : _r$data$schoolClass;

        var _ref = school || {},
            _ref$products = _ref.products,
            products = _ref$products === undefined ? [] : _ref$products;

        var _schoolClass$program = schoolClass.program,
            programData = _schoolClass$program === undefined ? {} : _schoolClass$program;

        var _options = void 0;

        if (programData && programData.id && teacher) {

          if (!_program) {
            setProgram(programData.id);
          } else if (!program) {
            setProgram(_program);
          }

          _options = {
            id: programData.id,
            label: programData.name
          };
          setOptions(_options);
        }

        products && products.forEach(function (product, index) {
          var isNew = true;

          options.forEach(function (option) {
            if (option.id === product.program.id) isNew = false;
          });

          if (isNew && product.program.id) {
            if (index === 0) {
              setProgram(product.program.id);
            }

            _options = {
              id: product.program.id,
              label: product.program.name
            };
          }
          setOptions(_options);
        });
      }
    });
  };

  var handleChange = function handleChange(event) {
    if (event.target && event.target.value) {
      setProgram(event.target.value);
    }
  };

  if (!showProgramDropdown || !options.length) {
    return _react2.default.createElement(
      "div",
      null,
      "''"
    );
  }
  return _react2.default.createElement(
    _FormControl2.default,
    null,
    _react2.default.createElement(
      _Select2.default,
      {
        value: program,
        onChange: handleChange,
        name: "program",
        inputProps: inputProps,
        IconComponent: _icons.KeyboardArrowDown
      },
      options && options.map(function (item) {
        return _react2.default.createElement(
          _MenuItem2.default,
          { selected: item.selected, key: item.id, value: item.id },
          item.label
        );
      })
    )
  );
};

exports.default = (0, _recompose.compose)(_reactApollo.withApollo, (0, _reactRedux.connect)(function (state) {
  return {
    config: state.config,
    appUser: (0, _selectors.getAppUser)(state),
    program: (0, _selectors2.getProgram)(state)
  };
}, function (dispatch) {
  return {
    selectProgram: function selectProgram(program) {
      return dispatch((0, _actions.selectProgram)(program));
    }
  };
}), (0, _reactApollo.graphql)(_graphql.GQL_FETCH_SERVER_INFO, {
  name: "SERVER_INFO"
}), (0, _reactApollo.graphql)(_graphql.GQL_FETCH_GROUPS, {
  options: function options(props) {
    return {
      variables: {
        filter: {
          teacher: props.appUser.id
        }
      }
    };
  }
}), (0, _reactApollo.graphql)(_graphql.GQL_FETCH_GROUPS, {
  name: 'MANAGERS',
  options: function options(props) {
    return {
      variables: {
        filter: {
          manager: props.appUser.id
        }
      }
    };
  }
}))(ProgramsDropdown);