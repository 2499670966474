import React from 'react';
import SlideshowSetup from './SlideshowSetup';
import { graphql, withApollo } from "react-apollo";
import { GQL_FETCH_SLIDESHOW, GQL_SAVE_SLIDESHOW, GQL_DELETE_SLIDESHOW } from "./graphql";
import { withHandlers, compose } from "recompose";
import { showSnackbarMessage } from '@manakin/core/actions';
import { ElementOverlay } from '@manakin/app-core';
import { connect } from 'react-redux';
import createUuid from "uuid/v4";

const mapDispatchToProps = dispatch => ({
  processUpdateSuccess: () => {
    dispatch(
      showSnackbarMessage({
        text: "Slideshow opgeslagen.",
        variant: "success"
      })
    );
  },
  processNoTitle: () => {
    dispatch(
      showSnackbarMessage({
        text: "Opslaan mislukt, geen titel.",
        variant: "error"
      })
    );
  }
});

// little function to reorder the slides after drag and drop
const reorder = (previewList, startIndex, endIndex) => {
  const result = Array.from(previewList);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

class SlideshowSetupContainer extends React.PureComponent {
  state = {
    slides: [], // Array containing all slides including their data
    activeSlide: null, // Determines the activeSlide, for example this selects the new slide after it's added
    anchorEl: null, // Determines the popper.js offset based on this element
    popperPlacement: null, // Determines the popper placement
    imageDialogVisible: false,
    selectedDialogImage: false,
    title: '',
    isPublic: true
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data.loading && !this.props.data.loading) {
      const { data, match } = this.props;
      const { slideshowCreator = {} } = data;
      const slideShowId = match.params.slideId;
      let _slideshow = null;
      let _slides = [];

      slideshowCreator.slideshows && slideshowCreator.slideshows.forEach(slide => {
        if (slide.id == slideShowId) {
          _slides = [...slide.slides]
          _slideshow = {
            ...slide
          }
        }
      })

      if (_slideshow) {
        const { slides, ...otherProps } = _slideshow;
        let slideshow = { ...otherProps };

        slideshow.slides = []

        _slides && _slides.forEach(slide => {
          slideshow.slides.push({
            idx: createUuid(),
            type: slide.asset ? 'image' : 'text',
            image: {
              asset: { ...slide.asset }
            },
            data: {
              title: slide.title,
              text: slide.text,
            }
          })
        })

        if (slideshow) {
          this.setState({ ...slideshow, slideShowId })
        }
      }
    }
  }


  // Toggles Material UI's menu to add a new slide
  addSlideMenu = (event, placement) => {
    this.setState({
      anchorEl: event.currentTarget,
      popperPlacement: placement
    });
  }

  // Closes the above function
  addSlideMenuExit = () => {
    this.setState({ anchorEl: null });
  }

  // Handle new slides
  addSlide = (type, idx) => {
    let slides = [];
    this.addSlideMenuExit();
    slides.push(
      ...this.state.slides,
      {
        type: type,
        idx: idx,
        image: this.state.selectedDialogImage
      }
    );
    this.setState({
      slides: [...slides],
      activeSlide: slides.pop().idx
    }, () => {
      // Scroll to the activeSlide if the previewList has overflow
      const activeSlide = document.querySelector('.activeSlide');
      if (activeSlide) {
        activeSlide.scrollIntoView({ behavior: 'smooth', inline: 'end' })
      }
    })
  }

  // Function to open the images 
  addImageDialog = () => {
    this.setState({
      anchorEl: null,
      imageDialogVisible: true,
    })
  }

  closeImageDialog = () => {
    this.setState({
      imageDialogVisible: false,
      selectedDialogImage: null
    })
  }

  handleImageDialogClick = (image) => {
    this.setState({
      selectedDialogImage: image
    })
  }

  // Handle the data of a slide
  handleDataChange = (idx, prop, event) => {
    let slides = [...this.state.slides];
    slides.map((slide, slideIndex) => {
      if (slide.idx === idx) {
        slides[slideIndex] = {
          ...slides[slideIndex],
          data: {
            ...slides[slideIndex].data,
            [prop]: event.target.value
          }
        }
      }
    })
    this.setState({
      slides: [...slides]
    })
  }

  // Sets the clicked slide as activeSlide
  editSlide = (idx) => {
    this.setState({
      activeSlide: idx
    })
  }

  // Removes the slide
  deleteSlide = (idx) => {
    let updateSlides = this.state.slides.filter(slide => slide.idx !== idx);
    this.setState({
      slides: [...updateSlides]
    });
  }

  // Function which handles a drag/drop change (react-beautiful-dnd)
  onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const items = reorder(
      this.state.slides,
      result.source.index,
      result.destination.index
    );

    this.setState({
      slides: items
    });
  }

  handleSaveSlideshow = () => {
    const { match } = this.props;
    const { title, slides, slideShowId, isPublic } = this.state;

    const _slides = slides.map(slide => {
      if (slide.type == 'image') {
        const { url, __typename, ...otherProps } = slide.image.asset;
        return {
          asset: otherProps
        }
      } else if (slide.data) {
        return {
          title: slide.data.title || '',
          text: slide.data.text || ''
        }
      }
    })

    let obj = {
      title: title,
      slideshowCreator: match.params.elementId,
      isPublic,
      slides: _slides
    }

    if (slideShowId != 'new') obj.id = slideShowId

    if (title) {
      this.props.onUpdate(obj).then(result => {
        this.props.history.goBack()
      })
    } else {
      this.props.processNoTitle()
    }

  }

  handleChange = e => {
    this.setState({ title: e.target.value });
  }

  handleCheckboxChange = e => {
    this.setState(prevState => ({ isPublic: !prevState.isPublic }))
  }

  handleDelete = () => {
    if (this.state.slideShowId != 'new') {
      const { client } = this.props;
      client.mutate({
        mutation: GQL_DELETE_SLIDESHOW,
        variables: {
          id: this.state.slideShowId
        }
      }).then(result => {
        this.props.history.goBack()
      })
    }
  }

  render() {
    const { data } = this.props;
    const { slideshowCreator = {}, loading } = data;

    if (loading) {
      return <div>'loading'</div>
    } else {
      return (
        <ElementOverlay fullWidth={true} title='Slideshow maken'>
          <SlideshowSetup
            {...this.state}
            addSlide={this.addSlide}
            addSlideMenu={this.addSlideMenu}
            addSlideMenuExit={this.addSlideMenuExit}
            editSlide={this.editSlide}
            deleteSlide={this.deleteSlide}
            activeSlide={this.state.activeSlide}
            handleDataChange={this.handleDataChange}
            onDragEnd={this.onDragEnd}
            addImageDialog={this.addImageDialog}
            imageDialogState={this.state.imageDialogVisible}
            closeImageDialog={this.closeImageDialog}
            handleImageDialogClick={this.handleImageDialogClick}
            selectedDialogImage={this.state.selectedDialogImage}
            slideshowCreator={slideshowCreator}
            onSaveSlideshow={this.handleSaveSlideshow}
            onChange={this.handleChange}
            onCheckboxChange={this.handleCheckboxChange}
            onDeleteSlideshow={this.handleDelete}
          />
        </ElementOverlay>
      )
    }
  }
}

export default compose(
  graphql(GQL_SAVE_SLIDESHOW),
  graphql(GQL_FETCH_SLIDESHOW, {
    options: props => ({ variables: { id: props.match.params.elementId } })
  }),
  connect(
    null,
    mapDispatchToProps
  ),
  withHandlers({
    onUpdate: ({ mutate, processUpdateSuccess }) => event =>
      mutate({
        variables: {
          ...event
        }
      })
        .then(result => {
          if (!result.errors) {
            processUpdateSuccess()
          }
        })
        .catch(e => {
        })
  }),
  withApollo
)(SlideshowSetupContainer); 