'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var styles = exports.styles = function styles(theme) {
  return {
    root: _defineProperty({
      color: 'black',
      border: '0',
      zIndex: '99',
      top: 0,
      width: '100%'
    }, theme.breakpoints.down('md'), {
      position: 'absolute'
    }),
    appBarRoot: _defineProperty({
      backgroundColor: 'transparent',
      transition: 'background-color .3s, opacity .3s',
      boxShadow: 'none',
      opacity: 1
    }, theme.breakpoints.up('md'), {
      top: 0,
      left: 'auto',
      right: 0,
      position: 'fixed'
    }),
    appBarBackground: {
      backgroundColor: 'white',
      boxShadow: '0 0 ' + theme.spacing(1) + 'px 0 rgba(0,0,0,.1)'
    },
    hide: {
      pointerEvents: 'none',
      '& $appBarRoot': {
        pointerEvents: 'none',
        opacity: 0
      }
    },
    toolBarRoot: _defineProperty({
      display: 'flex',
      justifyContent: 'space-between',
      height: '6rem',
      paddingLeft: '.6rem'
    }, theme.breakpoints.up('md'), {
      height: '10rem',
      paddingLeft: '2.4rem'
    }),
    toolBarInner: {
      display: 'flex',
      flexFlow: 'row wrap',
      alignItems: 'center'
    },
    menuText: _defineProperty({
      fontFamily: theme.manakin.defaultContentFont,
      color: theme.manakin.navigationColor[500],
      display: 'none',
      cursor: 'pointer'
    }, theme.breakpoints.up('md'), {
      display: 'inline',
      fontSize: '1.8rem',
      lineHeight: '2.4rem',
      marginLeft: '1.6rem',
      fontweight: '500'
    }),
    buttonRoot: _defineProperty({
      padding: '0',
      marginLeft: '3.4rem',
      display: 'none',
      color: theme.manakin.navigationColor[500]
    }, theme.breakpoints.up('md'), {
      display: 'inline-flex'
    }),
    iconColor: {
      color: theme.manakin.navigationColor[500]
    },
    menuButton: {
      marginRight: '2rem'
    },
    buttonLabel: {
      fontSize: '18px',
      fontWeight: '500',
      letterSpacing: '0',
      color: theme.manakin.navigationColor[500]
    },
    menuButtonLabel: {
      cursor: 'pointer',
      fontSize: '18px',
      fontWeight: '600',
      letterSpacing: '0',
      color: theme.manakin.navigationColor[500]
    },
    link: {
      textDecoration: 'none',
      color: 'inherit'
    },
    avatar: {
      width: '40px',
      height: '40px',
      borderRadius: '100%',
      marginLeft: '15px',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      cursor: 'pointer'
    },
    menuOptionsRoot: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center'
    },
    iconButtonRoot: _defineProperty({
      width: 'auto'
    }, theme.breakpoints.up('md'), {
      width: '4.8rem'
    }),
    name: {
      marginRight: '1.4rem',
      fontFamily: theme.manakin.defaultContentFont
    },
    backButtonRoot: {
      border: '1px solid ' + theme.manakin.defaultBorderColor[500],
      borderRadius: 0,
      backgroundColor: 'transparent'
    },
    save: {
      width: '6rem',
      height: '6rem',
      backgroundColor: theme.manakin.secondaryColor[500],
      transform: 'translateX(1.6rem)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    saveIcon: {
      color: 'black',
      pointerEvents: 'none'
    },
    logo: {},
    programsDropdownRoot: _defineProperty({
      marginTop: '50px',
      position: 'absolute',
      left: '50%',
      transform: 'translateX(-50%)'

    }, theme.breakpoints.up('md'), {
      marginTop: '0px'
    }),
    dropdownInput: {
      fontFamily: theme.manakin.defaultTitleFont,
      letterSpacing: '4px',
      fontSize: '12px',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1)
    },
    dropdownIcon: {
      color: 'black'
    },
    secondaryColors: {
      '& $iconColor': {
        color: theme.manakin.secondaryNavigationColor[500]
      },
      '& $buttonLabel': {
        color: theme.manakin.secondaryNavigationColor[500]
      },
      '& $menuText': {
        color: theme.manakin.secondaryNavigationColor[500]
      }
    }
  };
};