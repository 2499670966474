'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var styles = exports.styles = function styles(theme) {
  return {
    root: {
      paddingBottom: '10rem'
    },
    wrapper: _extends({}, theme.manakin.defaultWrapper, {
      maxWidth: '800px'
    }),
    move: {
      cursor: 'move'
    },
    boxCardRoot: {
      position: 'relative',
      width: '100%'
    },
    mileStone: {
      position: 'absolute',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontFamily: theme.manakin.defaultTitleFont,
      zIndex: 1,
      fontSize: '12px',
      letterSpacing: '.4rem',
      lineHeight: '2rem',
      textAlign: 'center',
      top: '-3rem',
      opacity: 0,
      transition: 'opacity .2s',
      cursor: 'pointer',
      textTransform: 'uppercase',
      '&:after': {
        content: "''",
        width: '100%',
        zIndex: -1,
        height: '1px',
        backgroundColor: '#000',
        position: 'absolute',
        left: 0,
        top: '50%'
      },
      '&:hover': {
        opacity: 1
      }
    },
    activeMileStone: {
      opacity: 0.2
    },
    mileStoneText: {
      backgroundColor: theme.manakin.primaryColor[500],
      padding: '0 3rem'
    }
  };
};