import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { getAppUser } from "@manakin/authentication/selectors";
import { GQL_FETCH_SCHOOL_CLASS } from "../graphql";
import { getGroup } from "@manakin/app-core/GroupsDropdown/selectors";
import { withStyles } from "@material-ui/core/styles";
import { Student, SimpleTable, AccessControl, Loader } from "@manakin/app-core";
import { withApollo } from "react-apollo";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
import MessageDialog from "../MessageDialog";
import { openDialog } from "@manakin/core/actions";
import MailIcon from "@material-ui/icons/MailOutline";
import { KeyboardArrowDown } from "@material-ui/icons";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Button from "@material-ui/core/Button";
import { useLazyQuery } from '@apollo/react-hooks';

import { styles } from "./styles";
const studentLength = 10

const ClassList = props => {
  const { group, classes } = props;

  const [schoolClass, setSchoolClass] = useState({})
  const [student, setStudent] = useState("All");
  const [sortDirection, setSortDirection] = useState("desc");
  const [currentIdx, setCurrentIdx] = useState(1)
  const [students, setStudents] = useState(null);
  const [studentsToShow, setStudentsToShow] = useState(0);
  const [name, setName] = useState([])

  const [getSchoolClass, { loading, data, error }] = useLazyQuery(GQL_FETCH_SCHOOL_CLASS);


  useEffect(() => {
    if (group) {
      handleMount(group);
    }
  }, [group])

  useEffect(() => {
    if (!loading && data) {
      if (!error) {
        const _students = [...data.schoolClass.students];
        const _splicedStudents = _students.splice(0, studentLength);
        setSchoolClass(data.schoolClass);
        setStudents(data.schoolClass.students);
        setStudentsToShow(_splicedStudents);
      }
    }
  }, [loading, data])

  const handleMount = groupId => {
    getSchoolClass({
      variables: { id: groupId }
    })
  }


  const handleClick = student => {
    setStudent([student.id]);
    setName([student.fullName]);
    props.onOpenDialog();
  };

  const handleSendMessageAll = () => {
    const _students = students.map(student => {
      return student.id;
    });

    setStudent(_students);
    setName(schoolClass.name);
    props.onOpenDialog()
  };

  const sortTableRows = () => {
    const directionSwitch = sortDirection === "desc" ? "asc" : "desc";
    setSortDirection(directionSwitch);
  };

  const handleShowMore = () => {
    setCurrentIdx(currentIdx + 1)
  };

  useEffect(() => {
    if (currentIdx > 1) {
      const _students = [...students]
      setStudentsToShow(_students.splice(0, studentLength * currentIdx))
    }
  }, [currentIdx])

  if (!group && !loading) {
    return (
      <div className={classes.emptyRoot}>
        <div className={classes.wrapper}>
          <Typography component="h3" variant="h3">
            Geen klassen gekoppeld.
          </Typography>
        </div>
      </div>
    );
  } else {
    return (
      <React.Fragment>
        {!loading ? (
          <div className={classes.root}>
            <AccessControl role={["SCHOOL_MANAGER", "TEACHER"]}>
              <MessageDialog student={student} name={name} />
            </AccessControl>
            <div className={classes.wrapper}>
              <div className={classes.schoolClasses}>
                <div className={classes.topBar}>
                  {schoolClass &&
                    schoolClass.product &&
                    schoolClass.product.program && (
                      <div className={classes.licenseName}>
                        licentie van deze klas:{" "}
                        {schoolClass.product.program.name}
                      </div>
                    )}
                </div>
                <div className={classes.classList}>
                  {students && (
                    <div>
                      <div className={classes.amount}>
                        <Typography
                          component="p"
                          variant="subtitle1"
                          className={classes.students}
                        >
                          {students.length} Leerling(en) in groep
                        </Typography>
                        <span
                          className={classes.icon}
                          onClick={handleSendMessageAll}
                        >
                          <MailIcon />
                        </span>
                      </div>
                    </div>
                  )}
                  <SimpleTable
                    renderHead={() => (
                      <React.Fragment>
                        <TableCell alitn="left">Student</TableCell>
                        <TableCell align="left">Klas</TableCell>
                        <TableCell align="left">
                          Voortgang
                          <TableSortLabel
                            onClick={sortTableRows}
                            IconComponent={KeyboardArrowDown}
                            direction={sortDirection}
                            hideSortIcon={false}
                            classes={{ icon: classes.sortIcon }}
                          />
                        </TableCell>
                      </React.Fragment>
                    )}
                    renderBody={() => (
                      <React.Fragment>
                        {studentsToShow &&
                          studentsToShow.map(student => (
                            <Student
                              key={student.id}
                              userData={student}
                              classData={schoolClass}
                              onClick={handleClick}
                            />
                          ))}
                      </React.Fragment>
                    )}
                  />
                  {studentsToShow &&
                    students &&
                    studentsToShow.length < students.length && (
                      <Button
                        size="small"
                        color="primary"
                        variant="contained"
                        onClick={handleShowMore}
                        className={classes.button}
                      >
                        Meer studenten laden
                      </Button>
                    )}
                </div>
              </div>
            </div>
          </div>
        ) : (
            <div className="loadContainer">
              <Loader />
            </div>
          )}
      </React.Fragment>
    );
  }
}


export default compose(
  connect(
    state => ({
      group: getGroup(state),
      appUser: getAppUser(state)
    }),
    dispatch => ({
      onOpenDialog: data => dispatch(openDialog("appClassMessageDialog"))
    })
  ),
  withApollo,
  withStyles(styles)
)(ClassList);
