import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Search from '../Search';
import TableToolbarAction from '../TableToolbarAction';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';
import CloseIcon from '@material-ui/icons/Close';
import { compose } from "recompose";
import { connect } from 'react-redux';

const styles = theme => ({
  root: {
    position: 'relative',
    paddingRight: theme.spacing(1)
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  spacer: {
    flex: '1 1 100%'
  },
  actions: {
    display: 'flex',
    color: theme.palette.getContrastText(theme.palette.grey[800]),
    backgroundColor: theme.palette.grey[800]
  },
  selection: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    color: theme.palette.getContrastText(theme.palette.grey[800]),
    backgroundColor: theme.palette.grey[800]
  },
  link: {
    color: 'inherit',
    marginLeft: theme.spacing(1)
  }
});

class TableToolbar extends React.Component {
  handleSelectAll = event => {
    if (event) event.preventDefault();
    this.props.onSelectAll();
  };

  handleDeselectAll = event => {
    if (event) event.preventDefault();
    this.props.onDeselectAll();
  };

  handleActionClick = originalHandler => event => {
    if (originalHandler) {
      originalHandler(this.props.selection, this.props.selectAll);
    }
  };

  render() {
    const {
      selection,
      selectAll,
      totalCount,
      classes,
      onSearch,
      defaultSearch,
      theme,
      actions,
      config = {}
    } = this.props;
    const { literals = {} } = config;

    const transitionDuration = {
      enter: theme.transitions.duration.shorter,
      exit: theme.transitions.duration.shortest
    };

    return (
      <div className={classes.root}>
        <Toolbar className={classes.toolbar}>
          <Search
            defaultValue={defaultSearch}
            onSearch={onSearch}
            className={classes.search}
          />
          <div className={classes.spacer} />
          <div className={classes.actions} />
        </Toolbar>
        <Fade
          unmountOnExit
          in={selection.length > 0}
          timeout={transitionDuration}
        >
          <Toolbar className={[classes.toolbar, classes.selection].join(' ')}>
            {selectAll ? (
              <Typography color="inherit" variant="subtitle1">
                <span>
                  {literals.CMS_TABLE_ALL || 'Alle'} {totalCount}{' '}
                  {literals.CMS_TABLE_ROWS_SELECTED || 'rijen geselecteerd'}
                </span>
              </Typography>
            ) : (
              <Typography color="inherit" variant="subtitle1">
                <span>
                  {selection.length}{' '}
                  {selection.length === 1
                    ? `${literals.CMS_TABLE_ROW} ` || 'rij '
                    : ` ${literals.CMS_TABLE_ROWS}` || ' rijen '}{' '}
                  {literals.CMS_TABLE_SELECTED || 'geselecteerd'}
                </span>
                <a
                  onClick={this.handleSelectAll}
                  className={classes.link}
                  href="#"
                >
                  {literals.CMS_TABLE_ALL || 'Alle'} {totalCount}{' '}
                  {literals.CMS_TABLE_SELECT_ROWS || 'rijen selecteren'}
                </a>
              </Typography>
            )}

            <div className={classes.actions}>
              {actions &&
                actions.map(action =>
                  React.cloneElement(action, {
                    onClick: this.handleActionClick(
                      action.props ? action.props.onClick : ''
                    )
                  })
                )}
              <TableToolbarAction
                onClick={this.handleDeselectAll}
                tooltip={
                  literals.CMS_TABLE_DESELECT_ROWS || 'Alle rijen deselecteren'
                }
                icon={<CloseIcon />}
              />
            </div>
          </Toolbar>
        </Fade>
      </div>
    );
  }
}

export default compose(
  connect(state => ({ config: state.config })),
  withStyles(styles, {
    name: 'CoreTableToolbar',
    withTheme: true
  })
)(TableToolbar);
