import React from "react";
import {withStyles} from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Button from "@material-ui/core/Button/Button"
import IconButton from '@material-ui/core/IconButton';
import ViewIcon from '@material-ui/icons/RemoveRedEye';
import DropDownIcon from '@material-ui/icons/ArrowDropDown';

const styles = theme => ({
    toolbar: {
        backgroundColor: "#F8F8F9",
        display: "grid",
        gridTemplateColumns: "auto min-content"
    },
    tabsRoot: {
        marginTop: "auto",
        borderBottom: "1px solid #e8e8e8",
    },
    tabsIndicator: {
        backgroundColor: "#242445",
    },
    tabRoot: {
        textTransform: "initial",
        minWidth: 72,
        fontWeight: theme.typography.fontWeightRegular,
        "&:hover": {
            color: "#242445",
            opacity: 1,
        },
        "&:focus": {
            color: "#242445",
        },
    },
    toolbarActions: {
        display: "grid",
        gridTemplateColumns: "auto auto",
        padding: `0 ${theme.spacing(2)}px`,
        margin: theme.spacing(1),
        alignItems: "center"
    },
    toolBarButton: {
        backgroundColor: theme.manakin.buttonColors.saveButton,
        width: 120,
        margin: theme.spacing(1),
        color: "white", 
        borderRadius: 100,
        paddingLeft: theme.spacing(3),
        "&:hover": {
            backgroundColor: "#34C279",
        },
        "&:focus": {
            backgroundColor: "#34C279",
        },
    },
    toolBarRightIcon: {
        marginLeft: theme.spacing(1),
    }
});

/**
 * Toolbar containing customizable tabs and a eye and save button
 */
class TabToolBar extends React.Component {
    /**
     * {String} value Currently selected tab
     * {Array<Object>} tabs Array containing objects with the following structure:
     * {
     *     value: "general",
     *     label: "Algemeen"
     * }
     * {Callback} onEyeClick Callback when clicking on the eye
     * {Callback} onSaveClick Callback when clicking on the save button
     * {Callback} onTabChange Callback when switching tabs
     * @returns {*}
     */
    render() {
        const {classes, value, tabs, onEyeClick, onSaveClick, onTabChange} = this.props;

        return (
            <div className={classes.toolbar}>
                <Tabs
                    value={value}
                    classes={{root: classes.tabsRoot, indicator: classes.tabsIndicator}}
                    onChange={(event, value) => onTabChange(event, value)}>
                    {tabs && tabs.map(tab => {
                        return (<Tab
                            key={tab.value}
                            value={tab.value}
                            classes={{root: classes.tabRoot}}
                            label={tab.label}>
                            {tab.label}
                        </Tab>)
                    })}
                </Tabs>
                <div className={classes.toolbarActions}>
                    <IconButton
                        onClick={() => onEyeClick()}>
                        <ViewIcon/>
                    </IconButton>
                    <Button
                        variant="contained"
                        className={classes.toolBarButton}
                        onClick={() => onSaveClick()}>
                        Opslaan
                        <DropDownIcon className={classes.toolBarRightIcon}/>
                    </Button>
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(TabToolBar);
