"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var styles = exports.styles = function styles(theme) {
  return {
    root: {
      position: "fixed",
      bottom: "2rem",
      width: "100%",
      zIndex: "99",
      "& a": {
        color: theme.manakin.linkColor[500],
        "&:hover": {
          color: theme.manakin.defaultContentHover[500]
        }
      }
    },
    content: {
      width: "100%"
    },
    fab: {
      fontFamily: theme.manakin.defaultContentFont,
      fontSize: "3rem",
      position: "absolute",
      bottom: 0,
      right: "2rem",
      cursor: "pointer"
    },
    paper: {
      bottom: "8rem",
      right: 0,
      width: "calc(100% - 4rem)",
      maxWidth: "33rem",
      padding: "4rem",
      position: "absolute",
      margin: "0 2rem",
      opacity: 0,
      transform: "translateY(20%)",
      transition: "transform .3s, opacity .3s",
      pointerEvents: "none"
    },
    show: {
      transform: "translateY(0)",
      opacity: 1,
      pointerEvents: "visible"
    },
    body: {
      marginBottom: "1rem"
    },
    heading: {
      fontFamily: theme.manakin.defaultTitleFont
    },
    expansionPanelRoot: {
      padding: 0,
      boxShadow: "none"
    },
    summary: {
      fontWeight: "bold",
      alignItems: "center",
      display: "flex",
      "& svg": {
        marginRight: "1rem",
        transform: "translateX(0)",
        transition: "transform .2s"
      },
      "&:hover": {
        "& svg": {
          transform: "translateX(.6rem)"
        }
      }
    },
    icon: {
      display: "none",
      cursor: "pointer",
      marginTop: ".6rem"
    },
    question: {
      fontSize: "2.5rem"
    },
    showIcon: {
      display: "block"
    }
  };
};