import React from "react";
import MuiDialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Grid from "@material-ui/core/Grid";
import SlidePreview from "./SlidePreview";
import Button from "@material-ui/core/Button";

class ImageDialog extends React.PureComponent {
  render() {
    const {
      classes,
      imageDialogState,
      closeImageDialog,
      selectedDialogImage,
      handleImageDialogClick,
      slideshowCreator = {},
      onImageClick
    } = this.props;
    const selected =
      selectedDialogImage &&
      selectedDialogImage.asset &&
      selectedDialogImage.asset.url
        ? selectedDialogImage.asset.url
        : "";

    return (
      <MuiDialog
        className={classes.imageDialog}
        open={imageDialogState}
        fullWidth
        onClose={closeImageDialog}
      >
        <DialogTitle>Kies een afbeelding</DialogTitle>
        <DialogContent>
          <div className={classes.imageDialogOverflowHelper}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container justify="flex-start" spacing={4}>
                  {slideshowCreator.assets &&
                    slideshowCreator.assets.map((image, idx) => (
                      <Grid key={idx} item xs={6} sm={4}>
                        <SlidePreview
                          {...this.props}
                          {...classes}
                          slideType="image"
                          slideThumb={image.asset.url}
                          active={image.asset.url === selected}
                          blur={selected ? image.asset.url !== selected : null}
                          onClick={() => handleImageDialogClick(image)}
                        />
                      </Grid>
                    ))}
                </Grid>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions className={classes.imageDialogActions}>
          <Button color="primary" variant="contained" onClick={onImageClick}>
            Afbeelding toevoegen
          </Button>
          <Button color="primary" onClick={closeImageDialog}>
            Annuleren
          </Button>
        </DialogActions>
      </MuiDialog>
    );
  }
}

export default ImageDialog;
