import React from "react";
import { TextField, RadioField, SelectField, DatePicker } from "@manakin/core";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import FormHelperText from "@material-ui/core/FormHelperText";
import moment from "moment";

const styles = theme => ({
  root: {
    width: "100%"
  },
  groupElements: {
    "@media (min-width: 700px)": {
      display: "flex",
      justifyContent: "space-between",
      "&>*": {
        flex: "0 0 auto",
        width: "300px"
      }
    }
  },
  paragraph: {
    textAlign: "center",
    width: "100%",
    margin: "0 auto 3em",
    maxWidth: "560px"
  },
  heading: {}
});

class PersonalData extends React.Component {
  componentDidMount() {
    const { config, form } = this.props;
    const appRegistration =
      (config && config.pages && config.pages.appRegistration) || {};
    if (appRegistration.registerCodeRequired)
      form.onRequiredChange("registerCode", true);
    if (appRegistration.showRegisterType)
      form.onRequiredChange("registerType", true);
    if (appRegistration.showBirthDate)
      form.onRequiredChange("dateOfBirth", true);
  }

  render() {
    const { classes, form, config } = this.props;
    const { fields: fieldsData } = form;

    const appRegistration =
      (config && config.pages && config.pages.appRegistration) || null;

    return (
      <div className={classes.root}>
        <Typography variant="h2" className={classes.heading}>
          Persoonlijke
          <br />
          gegevens
        </Typography>
        <RadioField
          form={form}
          name="gender"
          legendName="Geslacht"
          color="primary"
          options={[
            { label: "Man", value: "MALE" },
            { label: "Vrouw", value: "FEMALE" },
            { label: "Onbekend", value: "UNKNOWN" }
          ]}
          initialValue={fieldsData.gender || ""}
        />
        <div className={classes.groupElements}>
          <TextField
            form={form}
            name="firstName"
            label="Voornaam"
            initialValue={fieldsData.firstName || ""}
            shrink={false}
          />
          <TextField
            form={form}
            name="middleName"
            label="Tussenvoegsel"
            initialValue={fieldsData.middleName || ""}
            shrink={false}
          />
        </div>
        <div className={classes.groupElements}>
          <TextField
            form={form}
            name="lastName"
            label="Achternaam"
            initialValue={fieldsData.lastName || ""}
            shrink={false}
          />
          <span />
        </div>
        {appRegistration && (
          <div className={classes.groupElements}>
            {appRegistration.showBirthDate && (
              <DatePicker
                form={form}
                name="dateOfBirth"
                label="Geboortedatum"
                initialValue={fieldsData.dateOfBirth}
                defaultValue="2000-01-01"
                maxDate={moment().format("YYYY-MM-DD")}
              />
            )}

            {appRegistration.showPhoneNumber && (
              <TextField
                form={form}
                name="phonenumber"
                label="Telefoonnummer"
                initialValue={fieldsData.phonenumber}
                shrink={false}
                className={classes.phoneField}
              />
            )}
          </div>
        )}
        {appRegistration && (
          <div className={classes.groupElements}>
            {appRegistration.showRegisterType && (
              <SelectField
                label={
                  config.literals.APP_CHOOSE_REGISTER || "Kies jouw register"
                }
                options={[
                  {
                    id: "Algemeen_fysiotherapeut",
                    name: "Algemeen fysiotherapeut"
                  },
                  {
                    id: "Keurmerk_fysiotherapie",
                    name: "Keurmerk Fysiotherapie"
                  },
                  {
                    id: "Kwaliteitsdeel",
                    name: "Kwaliteitsdeel"
                  },
                  {
                    id: "Manueeltherapeut",
                    name: "Manueeltherapeut"
                  },
                  {
                    id: "Sportfysiotherapeut",
                    name: "Sportfysiotherapeut"
                  }
                ]}
                form={form}
                name="registerType"
                shrink={false}
                initialValue={fieldsData.registrationType}
              />
            )}
            {appRegistration.showRegisterCode && (
              <div>
                <TextField
                  form={form}
                  name="registerCode"
                  label={config.literals.APP_REGISTERCODE || "Registratiecode"}
                  initialValue={fieldsData.registerCode}
                  shrink={false}
                />
                <FormHelperText>
                  {config.literals.APP_REGISTERCODE_HELPERTEXT ||
                    "Om accreditatie toe te kennen."}
                </FormHelperText>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles, { name: "AppRegistrationPersonalData" })(
  PersonalData
);
