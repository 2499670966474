let itemMargin = 13;

export const styles = theme => ({
  wrapper: {
    ...theme.manakin.defaultWrapper,
    [theme.breakpoints.up('md')]: {
      padding: 0
    }
  },
  answersRoot: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '-13px -13px 13px -13px'
  },
  card: {
    height: '250px',
    position: 'relative',
    flex: '0 1 calc(33.33% - ' + itemMargin * 2 + 'px)',
    margin: itemMargin + 'px',
    [theme.breakpoints.down('sm')]: {
      flex: '0 1 calc(100% - 26px)',
      height: '150px'
    }
  },
  innerCard: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    cursor: 'pointer'
  },
  textWrapper: {
    width: '50%',
    [theme.breakpoints.up('md')]: {
      width: '100%',
      minHeight: '25%',
      paddingTop: '15px'
    }
  },
  text: {
    paddingLeft: '20px',
    transform: 'translateY(-50%)',
    top: '50%',
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      textAlign: 'center',
      paddingLeft: '0',
      transform: 'none',
      top: '0',
      position: 'unset'
    }
  },
  image: {
    width: '100%',
    display: 'flex',
    height: '100%',
    position: 'relative',
    zIndex: '2',
    flexDirection: 'row',
    '& img': {
      transition: 'transform .2s'
    },
    '& img:hover': {
      transform: 'scale(1.2)'
    },

    [theme.breakpoints.up('md')]: {
      flexDirection: 'column'
    }
  },
  backgroundImage: {
    objectFit: 'cover',
    width: '100%',
    height: '100%',
    transform: 'scale(1)',
    transition: 'transform .2s',

    [theme.breakpoints.up('md')]: {
      width: '100%',
      height: '100%'
    }
  },
  relative: {
    position: 'relative'
  },
  checked: {
    position: 'absolute',
    width: 'calc(100% + 14px)',
    height: 'calc(100% + 14px)',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%) scale(.85)',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: 0,
    display: 'flex',
    pointerEvents: 'none',
    border: '2px solid black',
    transition: 'transform .2s ease, opacity .2s ease',
    backfaceVisibility: 'hidden'
  },
  iconRoot: {
    width: '1.3rem',
    height: '1.3rem'
  },
  showIcon: {
    opacity: 1,
    transform: 'translate(-50%, -50%) scale(1)'
  },
  feedback: {
    position: 'relative',
    left: '0',
    zIndex: '2',
    opacity: '0',
    height: 0,
    width: '100%',
    overflow: 'hidden',
    pointerEvents: 'none',
    transition: 'opacity .3s'
  },
  activeFeedback: {
    opacity: '1',
    pointerEvents: 'auto',
    height: 'auto'
  },
  imageWrapper: {
    display: 'flex',
    height: '75%',
    position: 'relative',
    overflow: 'hidden',

    [theme.breakpoints.down('sm')]: {
      width: '50%',
      height: '100%'
    }
  },
  buttons: {
    position: 'relative',
    marginTop: '2rem',
    display: 'inline-block',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      marginTop: '0px'
    }
  },
  button: {
    '& span': {
      justifyContent: 'space-between',
      paddingLeft: '2rem',
      paddingRight: '2rem'
    }
  },
  longArrow: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block'
    }
  }
});
