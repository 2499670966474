'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

exports.setAppBarBackgroundColor = setAppBarBackgroundColor;
exports.setAppBarDropDown = setAppBarDropDown;
exports.hideAppBar = hideAppBar;
exports.saveAppBar = saveAppBar;
exports.goBackAppBar = goBackAppBar;
exports.setBackButton = setBackButton;
var SET_APP_BAR_BACKGROUND_COLOR = exports.SET_APP_BAR_BACKGROUND_COLOR = '@manakin/app-core/SET_APP_BAR_BACKGROUND_COLOR';
var SET_APP_BAR_DROP_DOWN = exports.SET_APP_BAR_DROP_DOWN = '@manakin/app-core/SET_APP_BAR_DROP_DOWN';
var HIDE_APP_BAR = exports.HIDE_APP_BAR = '@manakin/app-core/HIDE_APP_BAR';
var SAVE_APP_BAR = exports.SAVE_APP_BAR = '@manakin/app-core/SAVE_APP_BAR';
var GO_BACK_APP_BAR = exports.GO_BACK_APP_BAR = '@manakin/app-core/GO_BACK_APP_BAR';
var SET_BACK_BUTTON = exports.SET_BACK_BUTTON = '@manakin/app-core/SET_BACK_BUTTON';

function setAppBarBackgroundColor() {
	var color = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

	return {
		type: SET_APP_BAR_BACKGROUND_COLOR,
		payload: _extends({}, color)
	};
}

function setAppBarDropDown() {
	var dropDown = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

	return {
		type: SET_APP_BAR_DROP_DOWN,
		payload: _extends({}, dropDown)
	};
}

function hideAppBar(data) {
	return {
		type: HIDE_APP_BAR,
		payload: _extends({}, data)
	};
}

function saveAppBar(data) {
	return {
		type: SAVE_APP_BAR,
		payload: data
	};
}

function goBackAppBar(data) {
	return {
		type: GO_BACK_APP_BAR,
		payload: data
	};
}

function setBackButton(data) {
	return {
		type: SET_BACK_BUTTON,
		payload: data
	};
}