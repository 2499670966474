"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _IconButton = require("@material-ui/core/IconButton");

var _IconButton2 = _interopRequireDefault(_IconButton);

var _ArrowBack = require("@material-ui/icons/ArrowBack");

var _ArrowBack2 = _interopRequireDefault(_ArrowBack);

var _recompose = require("recompose");

var _reactRedux = require("react-redux");

var _styles = require("@material-ui/core/styles");

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var styles = function styles(theme) {
  return {
    root: {
      marginRight: "2rem",
      pointerEvents: "visible"
    },
    backButtonRoot: {
      border: "1px solid " + theme.manakin.defaultBorderColor[500],
      borderRadius: 0,
      backgroundColor: "transparent"
    },
    menuText: _defineProperty({
      fontFamily: theme.manakin.defaultContentFont,
      color: theme.manakin.navigationColor[500],
      display: "none",
      cursor: "pointer"
    }, theme.breakpoints.up("md"), {
      display: "inline",
      fontSize: "1.8rem",
      lineHeight: "2.4rem",
      marginLeft: "1.6rem",
      fontweight: "500"
    }),
    secondaryColors: {
      "& $menuText": {
        color: theme.manakin.secondaryNavigationColor[500]
      }
    }
  };
};

var BackButton = function BackButton(props) {
  var classes = props.classes,
      _props$config = props.config,
      config = _props$config === undefined ? {} : _props$config,
      _props$secondaryColor = props.secondaryColor,
      secondaryColor = _props$secondaryColor === undefined ? false : _props$secondaryColor;
  var _config$literals = config.literals,
      literals = _config$literals === undefined ? {} : _config$literals;


  var handleClick = function handleClick() {
    if (props.onClick) props.onClick();
  };

  return _react2.default.createElement(
    "div",
    {
      className: (0, _classnames2.default)(classes.root, _defineProperty({}, classes.secondaryColors, secondaryColor))
    },
    _react2.default.createElement(
      _IconButton2.default,
      {
        classes: { root: classes.backButtonRoot },
        onClick: handleClick
      },
      _react2.default.createElement(_ArrowBack2.default, null)
    ),
    _react2.default.createElement(
      "span",
      { className: classes.menuText, onClick: handleClick },
      literals.APP_BACK || "Terug"
    )
  );
};

exports.default = (0, _recompose.compose)((0, _reactRedux.connect)(function (state) {
  return { config: state.config };
}), (0, _styles.withStyles)(styles))(BackButton);