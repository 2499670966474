import gql from 'graphql-tag';

export const GQL_FETCH_CMS_BOX = gql`
  query($id: ID!) {
    box(id: $id) {
      id
      name
      description
      elements {
        id
        title
        backgroundColor
        introText
        image {
          id
          bynderId
          url
          thumbnailUrl
        }
        type
        fullWidth
        workforms {
          type
          id
        }
      }
    }
  }
`;

export const GQL_FETCH_ELEMENT_RESULTS = gql`
  query($box: ID!, $program: ID!, $user: ID!) {
    elementResults(box: $box, program: $program, user: $user) {
      finished
      element {
        id
        type
        title
      }
      correct
    }
  }
`;

export const GQL_UPDATE_USER = gql`
  mutation($id: ID!, $programId: String, $boxId: String, $elementId: String) {
    updateAppUser(
      input: {
        id: $id
        programId: $programId
        boxId: $boxId
        elementId: $elementId
      }
    ) {
      user {
        id
      }
    }
  }
`;
