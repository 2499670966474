export function parseSearch(location, expectedObject) {
    // Putting default object inline causes minify error
    if (expectedObject === null || expectedObject === undefined) expectedObject = {
        elementId: "",
        elementType: "",
        boxId: "",
        programId: ""
    };
    if (location && location.search) {
        const searchSplit = location.search.split('?');
        if (searchSplit[1] !== undefined) {
            expectedObject = searchSplit[1].split('&').reduce(function (acc, curr) {
                const keyVal = curr.split('=');
                if (keyVal.length === 2 && expectedObject[keyVal[0]] !== undefined) {
                    acc[keyVal[0]] = Number.isInteger(acc[keyVal[0]])
                        ? parseInt(keyVal[1], 10) : "" + decodeURIComponent(keyVal[1]);
                }

                return acc;
            }, expectedObject);
        }
    }

    for (var key in expectedObject) {
        if (expectedObject.hasOwnProperty(key)) {
            if (expectedObject[key].length === 0) delete expectedObject[key];
        }
    }

    return expectedObject;
}

export function createQuery(params) {
    return "?" + Object.keys(params)
        .filter(key => (params[key] !== null && params[key] !== undefined && params[key] !== ""))
        .map(key => `${key}=${params[key]}`).join("&");
}
