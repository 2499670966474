import React from 'react';
import ImageMPC from './ImageMPC';
import shuffle from 'shuffle-array';

class ImageMPCContainer extends React.PureComponent {

  state = {
    buttonDisabled: false
  };

  componentDidMount() {
    this.setStartState();
  }

  componentDidUpdate(prevProps) {
    if (
      (!prevProps.data.done && this.props.data.done) ||
      prevProps.data.id != this.props.data.id
    ) {
      this.setStartState(true);
      this.setState({ buttonDisabled: false });
    }
  }

  setStartState = second => {
    const { answers = [], shuffleAnswers = true } = this.props.data;
    const { options = {}, data } = this.props;

    const _answers = answers.map(answer => ({
      ...answer,
      selected: answer.selected == undefined ? false : answer.selected
    }));

    if (shuffleAnswers && !second) {
      shuffle(_answers);
    }

    this.setState({
      ...this.props.data,
      correct: this.checkAnswer(_answers),
      answers: _answers,
      feedback: data.finished && options && options.showFeedback ? true : false,
      done: data.finished,
      options: options
    });
  };

  checkAnswer = answers => {
    let correct = true;

    answers.forEach(answer => {
      if (answer.selected && !answer.correct) correct = false;
      if (!answer.selected && answer.correct) correct = false;
    });

    return correct;
  };

  handleChange = index => {
    let answers = [...this.state.answers];
    answers = answers.map(answer => ({
      ...answer,
      selected: false
    }));

    answers[index].selected = true;
    const correct = this.checkAnswer(answers);

    this.setState({
      answers: answers,
      correct: correct
    });
  };

  handleSubmit = () => {
    const { options = {} } = this.props;

    if (options && options.showFeedback) {
      this.setState({ feedback: true, options: options });
    } else {
      this.setState({ buttonDisabled: true });
      this.handleFinish();
    }
  };

  handleFinish = () => {
    this.props.onFinished({
      correct: this.state.correct,
      workform: { ...this.state }
    });
  };

  render() {
    const { direction = 'default' } = this.props;
    const { answers = [] } = this.state;

    return (
      <ImageMPC
        data={{ ...this.state }}
        onChange={this.handleChange}
        onFinished={this.handleFinish}
        onSubmit={this.handleSubmit}
        direction={direction}
        disabled={!answers.some(item => item.selected) || this.state.buttonDisabled}
      />
    );
  }
}

export default ImageMPCContainer;
