import React from "react";
import DownloadButton from "./DownloadButton";
import download from "downloadjs";

const DownloadButtonContainer = props => {
  const { withNext = false } = props;

  const handleClick = () => {
    const { data } = props;
    if (data.openAction == "current") {
      download(data.file && data.file.url);
    } else {
      window.open(data.file && data.file.url, "_blank");
    }
  };

  const handleFinished = () => {
    if (props.onFinished) props.onFinished();
  };

  return (
    <DownloadButton
      data={{ ...props.data }}
      onClick={handleClick}
      withNext={withNext}
      onFinished={handleFinished}
    />
  );
};

export default DownloadButtonContainer;
