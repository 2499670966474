'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});
exports.reducer = undefined;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _actions = require('./actions');

var initialState = {
	backgroundColor: {
		path: '',
		backgroundColor: '',
		color: ''
	},
	dropDown: {
		path: '',
		dropDown: []
	},
	data: {
		path: ''
	},
	save: {},
	back: {},
	backButtonData: {
		show: false,
		path: ''
	}
};

var reducer = exports.reducer = function reducer() {
	var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
	var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

	switch (action.type) {
		case _actions.SET_APP_BAR_BACKGROUND_COLOR:
			{
				return _extends({}, state, { backgroundColor: action.payload });
			}
		case _actions.HIDE_APP_BAR:
			{
				return _extends({}, state, { data: action.payload });
			}
		case _actions.SET_APP_BAR_DROP_DOWN:
			{
				return _extends({}, state, { dropDown: action.payload });
			}
		case _actions.SAVE_APP_BAR:
			{
				return _extends({}, state, { save: action.payload });
			}
		case _actions.GO_BACK_APP_BAR:
			{
				return _extends({}, state, { back: action.payload });
			}
		case _actions.SET_BACK_BUTTON:
			{
				return _extends({}, state, { backButtonData: action.payload });
			}
		default:
			return state;
	}
};

exports.default = reducer;