import React from 'react';
import { compose } from 'redux';
import Paper from '../Paper';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const styles = theme => ({
  root: {
    width: '100vw',
    height: '100vh',
    backgroundImage: 'url(' + theme.manakin.loginBackgroundImage + ')',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
});

const LoginFallback = props => {
  const { classes } = props;

  return (
    <div className={classes.root}>
      <Paper className={classes.content}>
          <Typography variant="body1">
            Login through <a className={classes.link} href={'https://driscolls.okta.com'}> https://driscolls.okta.com</a>
          </Typography>
      </Paper>
    </div>
  );
};

export default compose(withStyles(styles))(LoginFallback);
