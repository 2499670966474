import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import DeleteImageIcon from "@material-ui/icons/Delete";
import InsertPhoto from "@material-ui/icons/InsertPhoto";
import InputLabel from "@material-ui/core/InputLabel";
import { connect } from "react-redux";
import { openAssetsViewer, resetAssetViewer } from "@manakin/core/actions";
import { compose } from "recompose";
import { getImageData } from "@manakin/core/AssetsViewer/selectors";
import classNames from "classnames";
import { withRouter } from "react-router-dom";
import createUuid from "uuid/v4";
var _ = require("lodash");

const styles = theme => ({
  root: {
    flex: "1 0 auto",
    transition: "background-color 200ms linear",
    backgroundColor: "transparent",
    "& *": { opacity: 1, pointerEvents: "auto" },
    flexGrow: 1,
    height: "auto",
    margin: theme.manakin.normalMargin
  },
  loading: {
    height: theme.spacing(6),
    backgroundColor: theme.palette.grey[100],
    "& *": { opacity: 0, pointerEvents: "none" }
  },
  label: {
    fontSize: "18px",
    color: theme.manakin.defaultLabelColor[500],
    fontWeight: "bold",
    marginTop: 16,
    display: "block"
  },
  container: {
    position: "relative",
    display: "flex",
    paddingBottom: 8,
    marginTop: 28,
    borderBottom: "1px solid"
  },
  fileLabel: {
    display: "flex",
    cursor: "pointer"
  },
  image: {
    width: 75,
    height: 75,
    borderRadius: 4
  },
  thumbnail: {
    width: 75,
    height: 75
  },
  select: {
    display: "inline-flex"
  },
  icon: {
    color: "#47525E",
    alignSelf: "center"
  },
  deleteIcon: {
    cursor: "pointer",
    color: theme.manakin.defaultLabelColor[500],
    position: "absolute",
    right: theme.spacing(1),
    alignSelf: "center"
  },
  text: {
    alignSelf: "center",
    color: theme.manakin.defaultLabelColor[500],
    fontWeight: 500,
    marginLeft: 16,
    marginRight: 16
  },
  pickerField: {
    width: "0.1px",
    height: "0.1px",
    opacity: "0",
    overflow: "hidden",
    position: "absolute",
    zIndex: "-1"
  },
  disabledLabel: {
    color: theme.manakin.disabledFieldLabelColor
  },
  disabledInput: {
    color: theme.manakin.disabledFieldTextColor
  },
  disabledBorder: {
    borderBottom: `1px dotted ${theme.manakin.disabledFieldBorderColor}`
  }
});

const mapStateToProps = state => ({
  config: state.config,
  imageData: getImageData(state)
});

const mapDispatchToProps = dispatch => ({
  openAssets: (name, multi, assets = []) => dispatch(openAssetsViewer(name, multi, assets)),
  resetAssets: () => dispatch(resetAssetViewer())
});

class SelectImageField extends React.Component {
  state = {
    uuid: createUuid()
  };

  componentDidUpdate(prevProps) {
    const { uuid } = this.state;
    const prevId = prevProps.imageData[uuid]
      ? prevProps.imageData[uuid].id
      : undefined;
    const curId = this.props.imageData[uuid]
      ? this.props.imageData[uuid].id
      : undefined;
    if (prevProps.loading && !this.props.loading && this.props.initialValue) {
      const { form, name, initialValue } = this.props;

      form.onFieldChange({
        key: name,
        value: {
          ...initialValue
        }
      });
    } else if (prevId != curId) {
      this.handleChange();
    } else if (
      this.props.multi &&
      !_.isEqual(prevProps.imageData[uuid], this.props.imageData[uuid])
    ) {
      this.handleChange();
    }

    if (prevProps.initialValue != this.props.initialValue) {
      const { multi = false, form, name, initialValue = "" } = this.props;

      form.onFieldChange({
        key: name,
        value: multi
          ? [...initialValue]
          : {
            ...initialValue
          }
      });
    }
  }

  componentDidMount() {
    if (this.props.initialValue) {
      const { form, name, initialValue, multi = false } = this.props;
      form.onFieldChange({
        key: name,
        value: multi
          ? [...initialValue]
          : {
            ...initialValue
          }
      });
    }
  }

  componentWillUnmount() {
    this.props.resetAssets();
  }

  handleOpenAssetViewer = (fields = {}) => {
    const { openAssets, readOnly = false, multi = false } = this.props;
    if (!readOnly) {
      const { uuid } = this.state;
      if (fields.assets) {
        openAssets(uuid, multi, fields.assets);
      } else {
        openAssets(uuid, multi);
      }
    }
  };

  handleChange = () => {
    const { form, name, imageData } = this.props;
    const { uuid } = this.state;

    if (imageData[uuid]) {
      if (this.props.multi) {
        let arr = [];
        const obj = { ...imageData[uuid] };
        for (var key in obj) {
          if (obj[key].derivative && obj[key].derivative != "none")
            arr.push({ asset: obj[key] });
        }
        form.onFieldChange({
          key: name,
          value: [...arr]
        });
      } else {
        form.onFieldChange({
          key: name,
          value: {
            bynderId: imageData[uuid].id,
            derivative: imageData[uuid].size,
            url: imageData[uuid].url
          }
        });
      }
    }
  };

  handleDeleteImage = event => {
    const { config = {} } = this.props;
    const { literals = {} } = config;
    event.stopPropagation();

    if (
      window.confirm(
        literals.CMS_REMOVE_IMAGE || "Wilt u deze afbeelding echt verwijderen?"
      )
    ) {
      const { form, name } = this.props;

      form.onFieldChange({
        key: name,
        value: {
          bynderId: null,
          derivative: null
        }
      });
    }
  };

  removeImage = data => () => {
    // const { form } = this.props
    // console.log("data", data)
    // console.log('DELETE!', form)
  }

  render() {
    const {
      classes,
      label: _label,
      description: _description,
      name,
      readOnly = false,
      loading,
      form,
      type,
      className: classNameProp,
      multi = false,
      config = {}
    } = this.props;
    const { literals = {} } = config;

    const label = _label
      ? _label
      : literals.CMS_CHOOSE_IMAGE || "Kies een afbeelding";
    const description = _description
      ? _description
      : literals.CMS_SELECT_IMAGE || "Selecteer een afbeelding";

    const className = classNames(
      classes.root,
      { [classes.loading]: loading },
      classNameProp
    );
    const { fields } = form;
    const image = fields[name]
      ? fields[name].url
      : this.props.initialValue
        ? this.props.initialValue.url
        : "";
    const inputId = parseInt(Math.random() * Number.MAX_SAFE_INTEGER);

    return (
      <div className={className}>
        {multi && (
          <div>
            <InputLabel
              classes={{
                root: classNames(classes.label, {
                  [classes.disabledLabel]: readOnly
                })
              }}
            >
              {label}
            </InputLabel>
            <div
              className={classNames(classes.container, {
                [classes.disabledBorder]: readOnly
              })}
            >
              {fields[name] &&
                fields[name].length &&
                fields[name].map(image => (
                  <div
                    key={image.asset.bynderId}
                    className={classes.image}
                    style={{
                      backgroundImage: `url("${image.asset.url}")`,
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center"
                    }}
                    onClick={this.removeImage(image)}
                  />
                ))}
              <label className={classes.fileLabel} htmlFor={inputId}>
                <div className={classes.select} onClick={() => this.handleOpenAssetViewer(fields)}>
                  <Typography
                    className={classNames(classes.text, {
                      [classes.disabledInput]: readOnly
                    })}
                  >
                    {literals.CMS_SELECT_IMAGES || "Selecteer afbeeldingen"}
                  </Typography>
                </div>
              </label>
            </div>
          </div>
        )}
        {type !== "IMAGES_QUESTION" && !multi && (
          <div>
            <InputLabel
              classes={{
                root: classNames(classes.label, {
                  [classes.disabledLabel]: readOnly
                })
              }}
            >
              {label}
            </InputLabel>
            <div
              className={classNames(classes.container, {
                [classes.disabledBorder]: readOnly
              })}
              onClick={this.handleOpenAssetViewer}
            >
              <label className={classes.fileLabel} htmlFor={inputId}>
                <div
                  className={classes.image}
                  style={{
                    backgroundImage: `url("${image}")`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center"
                  }}
                >
                  <svg
                    style={{
                      display: image && image.length > 0 ? "none" : "block"
                    }}
                    className={classes.thumbnail}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="#f3f3f3"
                      d="M23 24H1c-.6 0-1-.4-1-1V1c0-.6.4-1 1-1h22c.6 0 1 .4 1 1v22c0 .6-.4 1-1 1z"
                    />
                    <path
                      fill="#a4a4a4"
                      d="M12.6 9.4l-1.5 2 1.1 1.5c.2.2.1.5-.1.7-.2.2-.5.1-.7-.1-.5-.7-1.2-1.5-1.5-2.1-.2-.3-.6-.3-.8 0l-2 2.7c-.2.3 0 .8.4.8h9c.4 0 .6-.5.4-.8l-3.5-4.7c-.2-.3-.6-.3-.8 0z"
                    />
                  </svg>
                </div>
                <div className={classes.select}>
                  <Typography
                    className={classNames(classes.text, {
                      [classes.disabledInput]: readOnly
                    })}
                  >
                    {description}
                  </Typography>
                </div>
              </label>
              {!readOnly && image && image.length > 0 && (
                <DeleteImageIcon
                  onClick={this.handleDeleteImage}
                  className={classes.deleteIcon}
                />
              )}
            </div>
          </div>
        )}
        {type === "IMAGES_QUESTION" && (
          <div>
            <div
              className={classes.select}
              onClick={this.handleOpenAssetViewer}
              style={{ marginTop: "-10px" }}
            >
              {fields.image ? (
                <InsertPhoto className={classes.icon} />
              ) : this.props.initialValue ? (
                <InsertPhoto className={classes.icon} />
              ) : (
                    <UploadImageIcon className={classes.icon} />
                  )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withStyles(styles)
)(SelectImageField);
