import { createSelector } from 'reselect';
import { getCoreState } from '../selectors';

export const DIALOG_STATE_ROOT = 'dialogs';

const getState = createSelector(
    [getCoreState],
    coreState => coreState[DIALOG_STATE_ROOT]
);

export const isOpen = dialogName =>
    createSelector(
        [getState],
        state => state[dialogName] !== undefined && state[dialogName].open
    );

export const getData = dialogName =>
    createSelector(
        [getState],
        state => state[dialogName] !== undefined && state[dialogName].data
    );