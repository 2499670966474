import React from 'react';
import {
	withWorkforms,
	ElementOverlay
} from '@manakin/app-core';
import { compose } from "recompose";
import BookCheck from './BookCheck';
const queryString = require('query-string');

class BookCheckPreview extends React.Component {
	state = {
		step : 1
	};

	componentDidMount() {
		const {match, workformsData} = this.props;
		const query = match.params.query;
		const data = queryString.parse(query);
		const obj = {
			...data,
			headerImage: {
				url: data.headerImage
			},
			image: {
				url: data.image
			},
			workforms: [
				...data.items.map((item, idx) => ({
					id: item,
					type: data.itemTypes[idx]
				}))
			]
		}

		workformsData.loadWorkforms({...obj})
	}

	handleFinish = () => {
		this.setState(prevState => ({
			step: prevState.step < 3 ? ++prevState.step : 2
		}))
	}

	handleClick = () => {
		this.setState(prevState => ({
			step: prevState.step < 3 ? ++prevState.step : 1
		}))
	}

	render() {
		const {workformsData} = this.props;
		const {rawData = {} } = workformsData;

		return (
			<ElementOverlay controls={false} background={rawData.borderColor || false} title={'Boekcheck' || null}>
				<BookCheck {...workformsData} {...this.state} onExit={this.handleFinish} onClick={this.handleClick} />
			</ElementOverlay>
		)
	}
}

export default compose(
	withWorkforms()
)(BookCheckPreview);