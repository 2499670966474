import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { ElementOverlay, LoadImage, VideoPlayer } from "@manakin/app-core";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { styles } from "./styles";
import classNames from "classnames";
import renderHTML from "react-render-html";
import PlayIcon from "@material-ui/icons/PlayArrow";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";

const DefaultFrontPage = props => {
  const {
    classes,
    controls = true,
    preTitle = "Instaptoets",
    image = {},
    playButton = false,
    factCards = false,
    scenes = false,
    background = "",
    disabled: disabledProp = false,
    disabledMessage = false
  } = props;

  //state hooks
  const [disabled, setIsDisabled] = useState(false);
  const [doReplay, setDoReplay] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [videoId, setVideoId] = useState(null);
  const [openMessage, setOpenMessage] = useState(false);

  //functions
  const handleClick = () => {
    if (disabledProp) {
      setOpenMessage(true);
    } else {
      setIsDisabled(true);
      if (props.onClick) props.onClick();
    }
  };

  const handleReplay = workform => {
    setDoReplay(true);
    setVideoId(workform.video);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleReplayEnd = () => {
    setDialogOpen(false);
  };

  const handleCloseMessage = () => {
    setOpenMessage(false);
  };

  return (
    <div className={classes.root}>
      <Dialog
        open={openMessage}
        onClose={handleCloseMessage}
        PaperProps={{ className: classes.dialogPaper }}
      >
        <Typography variant="body1">
          {disabledMessage
            ? "Maak eerst het Toezicht van dit jaar af."
            : "Je hebt voor dit jaar aan de nascholingsverplichting voldaan."}
        </Typography>
        <Button
          color="primary"
          variant="contained"
          onClick={handleCloseMessage}
        >
          Oke
        </Button>
      </Dialog>

      <ElementOverlay
        title={preTitle}
        fullWidth={true}
        controls={controls}
        customHeight={true}
        background={background}
      >
        <div
          className={classNames(classes.innerRoot, {
            [classes.showContent]: factCards.length || scenes.length
          })}
        >
          <LoadImage
            src={image ? image.url || "" : ""}
            placeholder={image ? image.thumbnailUrl || "" : ""}
            withOverlay={true}
            className={classes.backgroundImage}
          />
          <div className={classes.wrapper}>
            <div className={classes.content}>
              <Typography
                component="h1"
                variant="h1"
                className={classNames(
                  classes.fontStyle,
                  classes.title,
                  {
                    [classes.secondaryFontStyle]:
                      props.titleColor == "SECONDARY"
                  },
                  {
                    [classes.primaryFontStyle]: props.titleColor == "PRIMARY"
                  }
                )}
              >
                {props.introTitle || ""}
              </Typography>
              <Typography
                component="div"
                variant="body1"
                className={classNames(
                  classes.fontStyle,
                  {
                    [classes.secondaryFontStyle]:
                      props.titleColor == "SECONDARY"
                  },
                  {
                    [classes.primaryFontStyle]: props.titleColor == "PRIMARY"
                  }
                )}
              >
                {renderHTML(props.introText || "")}
              </Typography>
              {playButton && (
                <div onClick={handleClick} className={classes.playButton}>
                  <PlayIcon />
                </div>
              )}
              {!playButton && (
                <Button
                  disabled={disabled}
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={handleClick}
                >
                  {props.buttonText || "Start"}
                </Button>
              )}
            </div>
          </div>
        </div>
      </ElementOverlay>

      {factCards.length ? (
        <div className={classes.contentWrapper}>
          <div className={classes.contentBlock}>
            <Typography
              component="h2"
              variant="h2"
              className={classes.contentHeading}
              children="Factcards"
            />
            <Grid container spacing={0}>
              <div className={classes.gridGutterHelper}>
                {factCards.map((factcard, idx) => (
                  <Grid key={idx} item xs={4} className={classes.gridBlock}>
                    <Link
                      className={classes.cardhover}
                      to={`/factcard/${factcard.id}`}
                    >
                      {factcard.image && factcard.image.thumbnailUrl && (
                        <img
                          className={classes.gridBlockThumbnail}
                          src={factcard.image.thumbnailUrl}
                          alt="Factcard afbeelding"
                        />
                      )}
                      <Typography
                        component="h4"
                        variant="h2"
                        className={classes.gridBlockTitle}
                        children={factcard.title}
                      />
                      {factcard.text && (
                        <Typography
                          className={classes.gridBlockText}
                          children={factcard.text}
                        />
                      )}
                    </Link>
                  </Grid>
                ))}
              </div>
            </Grid>
          </div>
        </div>
      ) : (
        ""
      )}

      {scenes.length ? (
        <div className={classes.contentWrapper}>
          <Typography
            component="h2"
            variant="h2"
            className={classes.contentHeading}
            children="Behandel scenes"
          />
          <Grid className={classes.scenesGrid} container spacing={0}>
            <div className={classes.gridGutterHelper}>
              {scenes.map((workform, idx) => {
                if (workform.type === "Scene") {
                  return (
                    <Grid key={idx} item xs={12} className={classes.gridBlock}>
                      <Grid container className={classes.scenesGrid}>
                        <div className={classes.gridGutterHelper}>
                          <Grid item xs={8} className={classes.gridBlock}>
                            <Typography
                              className={classes.gridBlockSceneTitle}
                              component="h3"
                              variant="h2"
                            >
                              {workform.title}
                            </Typography>
                            <Typography
                              component="div"
                              className={classes.gridBlockText}
                            >
                              {renderHTML(workform.text)}
                            </Typography>
                          </Grid>
                          <Grid item xs={4} className={classes.gridBlock}>
                            <div className={classes.playContainer}>
                              <PlayIcon
                                className={classNames(classes.scenePlayButton, {
                                  [classes.hasImage]: workform.image
                                    ? true
                                    : false
                                })}
                                onClick={() => handleReplay(workform)}
                              />
                              {workform.image && (
                                <img
                                  className={classes.sceneThumbnail}
                                  src={workform.image.thumbnailUrl}
                                />
                              )}
                            </div>
                          </Grid>
                        </div>
                      </Grid>
                    </Grid>
                  );
                }
              })}
            </div>
          </Grid>
        </div>
      ) : (
        ""
      )}
      {doReplay && (
        <Dialog
          open={dialogOpen}
          maxWidth={"lg"}
          fullWidth={true}
          onClose={handleDialogClose}
          PaperComponent="div"
        >
          <VideoPlayer videoId={videoId} handleEnded={handleReplayEnd} />
        </Dialog>
      )}
    </div>
  );
};

export default withStyles(styles, { name: "AppDefaultFrontPage" })(
  DefaultFrontPage
);
