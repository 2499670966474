"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _AccountWrapper = require("../AccountWrapper");

var _AccountWrapper2 = _interopRequireDefault(_AccountWrapper);

var _core = require("@manakin/core");

var _appCore = require("@manakin/app-core");

var _recompose = require("recompose");

var _styles = require("@material-ui/core/styles");

var _Grid = require("@material-ui/core/Grid");

var _Grid2 = _interopRequireDefault(_Grid);

var _Button = require("@material-ui/core/Button");

var _Button2 = _interopRequireDefault(_Button);

var _IconButton = require("@material-ui/core/IconButton");

var _IconButton2 = _interopRequireDefault(_IconButton);

var _ProfilePicture = require("./parts/ProfilePicture");

var _ProfilePicture2 = _interopRequireDefault(_ProfilePicture);

var _graphql = require("../graphql");

var _reactApollo = require("react-apollo");

var _reactRedux = require("react-redux");

var _actions = require("@manakin/app-core/actions");

var _Edit = require("@manakin/core/icons/Edit");

var _Edit2 = _interopRequireDefault(_Edit);

var _reduxFirstHistory = require("redux-first-history");

var _selectors = require("@manakin/authentication/selectors");

var _Typography = require("@material-ui/core/Typography");

var _Typography2 = _interopRequireDefault(_Typography);

var _Dialog = require("@material-ui/core/Dialog");

var _Dialog2 = _interopRequireDefault(_Dialog);

var _actions2 = require("@manakin/authentication/actions");

var _styles2 = require("./styles");

var _actions3 = require("@manakin/core/actions");

var _jsCookie = require("js-cookie");

var _jsCookie2 = _interopRequireDefault(_jsCookie);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

var formBundle = {
  password: {
    required: true
  },
  firstName: {
    required: true
  },
  lastName: {
    required: true
  }
};

var timer = null;

var Account = function Account(props) {
  var onSetAppBarColor = props.onSetAppBarColor,
      location = props.location,
      goToChangePasswordPage = props.goToChangePasswordPage,
      form = props.form,
      data = props.data,
      classes = props.classes,
      APP_USER = props.APP_USER,
      _props$LOCALES = props.LOCALES,
      LOCALES = _props$LOCALES === undefined ? {} : _props$LOCALES,
      _props$config = props.config,
      config = _props$config === undefined ? {} : _props$config;
  var localesLoading = LOCALES.loading,
      _LOCALES$locales = LOCALES.locales,
      locales = _LOCALES$locales === undefined ? {} : _LOCALES$locales;
  var loading = APP_USER.loading,
      _APP_USER$appUser = APP_USER.appUser,
      currentAppUser = _APP_USER$appUser === undefined ? {} : _APP_USER$appUser;
  var _currentAppUser$userD = currentAppUser.userDataCbd,
      userDataCbd = _currentAppUser$userD === undefined ? {} : _currentAppUser$userD;

  var fieldProps = { xs: 12, sm: 6, md: 6, lg: 6, xl: 6 };
  var _config$literals = config.literals,
      literals = _config$literals === undefined ? {} : _config$literals,
      _config$pages = config.pages,
      pages = _config$pages === undefined ? {} : _config$pages,
      _config$general = config.general,
      general = _config$general === undefined ? {} : _config$general;
  var _pages$appAccount = pages.appAccount,
      accountConfig = _pages$appAccount === undefined ? {} : _pages$appAccount;

  var editName = general.editNameReadOnly || false;
  var userFunction = userDataCbd && userDataCbd.function ? userDataCbd.function === "Assistent drogist" ? "Assistent Drogist" : "Drogist" : "";
  var locOptions = [];

  locales.locales && locales.locales.map(function (item) {
    if (item.enabled) {
      locOptions.push({
        id: item.code,
        label: item.title
      });
    }
  });

  var otherFunction = userFunction === "Drogist" ? "Assistent Drogist" : "Drogist";

  //config variables
  var hideSchool = accountConfig.hideSchool;
  var hideSchoolClass = accountConfig.hideSchoolClass;
  var hideOrganisation = accountConfig && accountConfig.hideOrganisation;
  var hideFunction = accountConfig && accountConfig.hideFunction;
  var hideDelete = accountConfig && accountConfig.hideDelete;
  var switchFunction = accountConfig && accountConfig.switchFunction;

  //state hooks

  var _useState = (0, _react.useState)(false),
      _useState2 = _slicedToArray(_useState, 2),
      open = _useState2[0],
      setOpen = _useState2[1];

  var _useState3 = (0, _react.useState)([]),
      _useState4 = _slicedToArray(_useState3, 2),
      schools = _useState4[0],
      setSchools = _useState4[1];

  var _useState5 = (0, _react.useState)([]),
      _useState6 = _slicedToArray(_useState5, 2),
      organisations = _useState6[0],
      setOrganisations = _useState6[1];

  var _useState7 = (0, _react.useState)(false),
      _useState8 = _slicedToArray(_useState7, 2),
      openFunction = _useState8[0],
      setOpenFunction = _useState8[1];

  var _useState9 = (0, _react.useState)(false),
      _useState10 = _slicedToArray(_useState9, 2),
      openDepartment = _useState10[0],
      setOpenDepartment = _useState10[1];

  var _useState11 = (0, _react.useState)([]),
      _useState12 = _slicedToArray(_useState11, 2),
      options = _useState12[0],
      setOptions = _useState12[1];

  var _useState13 = (0, _react.useState)(null),
      _useState14 = _slicedToArray(_useState13, 2),
      selectedOrganisation = _useState14[0],
      setSelectedOrganisation = _useState14[1];

  (0, _react.useEffect)(function () {
    onSetAppBarColor({
      backgroundColor: "transparent",
      color: "secondary",
      path: location.pathname
    });
  }, []);

  (0, _react.useEffect)(function () {
    if (!data.loading) {
      if (data.groups && data.groups.groups) {
        var arr = data.groups.groups.filter(function (item) {
          return item.enabled;
        });
        setOptions(arr);
      }
    }
  }, [data.loading]);

  (0, _react.useEffect)(function () {
    if (!loading) {
      var _schools = [];
      var _organisations = [];
      currentAppUser.schoolClasses && currentAppUser.schoolClasses.forEach(function (item) {
        if (item.schools.length) _schools = [].concat(_toConsumableArray(_schools), _toConsumableArray(item.schools.map(function (s) {
          return s.name;
        })));
        item.schools.forEach(function (school) {
          if (school.organisations && school.organisations.length) {
            _organisations = school.organisations;
          }
        });
      });
      setSchools(_schools);

      if (!currentAppUser.schoolClasses.length && currentAppUser.school) {
        if (currentAppUser.school.organisations && currentAppUser.school.organisations.length) {
          _organisations = currentAppUser.school.organisations;
        }
      }

      setOrganisations(_organisations);
    }
  }, [loading, currentAppUser]);

  (0, _react.useEffect)(function () {
    var _form$fields = form.fields,
        fields = _form$fields === undefined ? {} : _form$fields;

    if (currentAppUser) {
      if (fields.firstName && currentAppUser.firstName != fields.firstName) {
        handleSave();
      }
      if (fields.lastName && currentAppUser.lastName != fields.lastName) {
        handleSave();
      }
      if (fields.middleName && currentAppUser.middleName != fields.middleName) {
        handleSave();
      }
    }
  }, [form.fields, currentAppUser]);

  //functions
  var handleSave = function handleSave() {
    clearTimeout(timer);
    timer = setTimeout(function () {
      form.onSubmit().then(function (data) {
        if (data) {
          props.onUpdate({
            id: currentAppUser.id,
            firstName: data.firstName,
            lastName: data.lastName || "",
            middleName: data.middleName || ""
          });
        }
      });
    }, 800);
  };

  var handleChangePassword = function handleChangePassword() {
    goToChangePasswordPage();
  };

  var openConfirmationDialog = function openConfirmationDialog() {
    setOpen(true);
  };

  var closeConfirmationDialog = function closeConfirmationDialog() {
    setOpen(false);
  };

  var handleClick = function handleClick() {
    setOpenFunction(true);
  };

  var closeFunction = function closeFunction() {
    setOpenFunction(false);
  };

  var handleDepartmentClick = function handleDepartmentClick() {
    setOpenDepartment(true);
  };

  var closeDepartment = function closeDepartment() {
    setOpenDepartment(false);
  };

  var handleNewsLetterChange = function handleNewsLetterChange(event) {
    props.onUpdateNewsLetter({
      id: currentAppUser.id,
      newsLetter: event.target.checked
    });
  };

  var handleAppUserDelete = function handleAppUserDelete() {
    form.onSubmit().then(function (formData) {
      if (formData) {
        props.client.mutate({
          mutation: _graphql.GQL_DELETE_USER,
          variables: {
            id: props.appUser.id,
            password: formData.password
          }
        }).then(function (result) {
          if (!result.errors) {
            props.onLogout();
          }
        });
      }
    });
  };

  var handleFunctionChange = function handleFunctionChange() {
    if (currentAppUser && currentAppUser.id) {
      props.changeFunction({ id: currentAppUser.id }).then(function (result) {
        if (!result.errors) {
          props.onChangeFunctionSucces();
        } else {
          props.onChangeFunctionFailed();
        }

        setOpenFunction(false);
      }).catch(function (e) {
        props.onChangeFunctionFailed();
        setOpenFunction(false);
      });
    }
  };

  var handleOrganisationChange = function handleOrganisationChange(data) {
    if (data.value != selectedOrganisation) {
      setSelectedOrganisation(data.value || null);
    }
  };

  var handleDepartmentChange = function handleDepartmentChange() {
    var _form$fields2 = form.fields,
        fields = _form$fields2 === undefined ? {} : _form$fields2;


    if (!fields.affiliate) {
      props.onChangeDepartmentIncomplete();
    } else {
      if (currentAppUser && currentAppUser.id) {
        props.changeDepartment({ id: currentAppUser.id, group: fields.affiliate }).then(function (result) {
          if (!result.errors) {
            props.onChangeFunctionSucces();
          } else {
            props.onChangeFunctionFailed();
          }
          closeDepartment();
        }).catch(function (e) {
          props.onChangeFunctionFailed();
          closeDepartment();
        });
      }
    }
  };

  var handleChangeLanguage = function handleChangeLanguage(value) {
    if (value && value.value) {
      _jsCookie2.default.set("locale", value.value === config.defaultLang ? "" : value.value);
      props.onUpdate({ id: currentAppUser.id, locale: value.value });
    }
  };

  if (loading) {
    return _react2.default.createElement(_appCore.Loader, { fullScreen: true });
  }
  return _react2.default.createElement(
    "div",
    null,
    _react2.default.createElement(
      _AccountWrapper2.default,
      {
        title: literals.APP_PROFILE_ACCOUNT_TITLE || "Account gegevens",
        introduction: literals.APP_PROFILE_ACCOUNT_INTRO || ""
      },
      _react2.default.createElement(
        _appCore.ContentHeader,
        { title: literals.APP_PROFILE || "Profiel" },
        _react2.default.createElement(_ProfilePicture2.default, {
          profilePicture: currentAppUser.profilePicture,
          user: props.appUser
        }),
        _react2.default.createElement(
          _Grid2.default,
          {
            container: true,
            spacing: 2,
            alignItems: "center",
            classes: {
              container: classes.profileFields
            }
          },
          accountConfig && accountConfig.showInitials && _react2.default.createElement(
            _Grid2.default,
            _extends({ item: true }, fieldProps),
            _react2.default.createElement(_core.TextField, {
              name: "initials",
              label: literals.APP_INITIALS || "Voorletters",
              readOnly: true,
              loading: false,
              initialValue: currentAppUser.initials || "",
              form: form
            })
          ),
          _react2.default.createElement(
            _Grid2.default,
            _extends({ item: true }, fieldProps),
            _react2.default.createElement(_core.TextField, {
              name: "firstName",
              label: literals.APP_FIRST_NAME || "Voornaam",
              readOnly: editName,
              loading: false,
              initialValue: currentAppUser.firstName || "",
              form: form,
              inputProps: {
                maxLength: 35
              }
            })
          ),
          _react2.default.createElement(
            _Grid2.default,
            _extends({ item: true }, fieldProps),
            _react2.default.createElement(_core.TextField, {
              name: "middleName",
              label: literals.APP_MIDDLE_NAME || "Tussenvoegsel",
              readOnly: editName,
              loading: false,
              initialValue: currentAppUser.middleName || "",
              form: form,
              inputProps: {
                maxLength: 35
              }
            })
          ),
          _react2.default.createElement(
            _Grid2.default,
            _extends({ item: true }, fieldProps),
            _react2.default.createElement(_core.TextField, {
              name: "lastName",
              label: literals.APP_LAST_NAME || "Achternaam",
              readOnly: editName,
              loading: false,
              initialValue: currentAppUser.lastName || "",
              form: form,
              inputProps: {
                maxLength: 35
              }
            })
          ),
          _react2.default.createElement(
            _Grid2.default,
            _extends({ item: true }, fieldProps),
            _react2.default.createElement(_core.TextField, {
              name: "email",
              label: literals.APP_EMAIL || "E-mail adres",
              readOnly: true,
              loading: false,
              initialValue: currentAppUser.email || "",
              form: form
            })
          ),
          accountConfig && !accountConfig.hidePassword && _react2.default.createElement(
            _Grid2.default,
            _extends({ item: true }, fieldProps),
            _react2.default.createElement(
              "div",
              { className: classes.changePasswordRoot },
              _react2.default.createElement(
                "div",
                { className: classes.changePasswordInput },
                _react2.default.createElement(_core.TextField, {
                  name: "password",
                  label: "Password",
                  readOnly: true,
                  loading: false,
                  initialValue: "********",
                  form: form
                })
              ),
              _react2.default.createElement(
                _IconButton2.default,
                {
                  onClick: handleChangePassword,
                  classes: { root: classes.changePasswordIcon }
                },
                _react2.default.createElement(_Edit2.default, null)
              )
            )
          ),
          accountConfig && accountConfig.showLanguageSelect && _react2.default.createElement(
            _Grid2.default,
            _extends({ item: true }, fieldProps),
            _react2.default.createElement(
              "div",
              { className: classes.selectFieldRoot },
              _react2.default.createElement(_core.SelectField, {
                loading: loading || localesLoading,
                options: locOptions,
                name: "locale",
                onChange: handleChangeLanguage,
                label: literals.APP_LANGUAGE || "Taal",
                initialValue: currentAppUser.locale ? {
                  id: currentAppUser.locale.code,
                  label: currentAppUser.locale.title
                } : {
                  id: "",
                  label: "English"
                },
                form: form
              })
            )
          )
        ),
        accountConfig && accountConfig.showNewsLetter && _react2.default.createElement(
          _Grid2.default,
          { container: true, spacing: 9, alignItems: "center" },
          _react2.default.createElement(
            _Grid2.default,
            { item: true, xs: 12 },
            _react2.default.createElement(_core.CheckboxField, {
              className: classes.checkbox,
              name: "newsLetter",
              label: literals.APP_ACCOUNT_NEWS_LETTER || "Nieuwsbrief ontvangen",
              initialValue: currentAppUser.newsLetter || false,
              loading: loading,
              form: form,
              onChange: handleNewsLetterChange
            })
          )
        )
      ),
      accountConfig && accountConfig.showEducation && _react2.default.createElement(
        _appCore.ContentHeader,
        { title: literals.APP_EDUCATION || "Opleiding" },
        _react2.default.createElement(
          _Grid2.default,
          { container: true, spacing: 2, alignItems: "center" },
          !hideSchool && _react2.default.createElement(
            _Grid2.default,
            _extends({ item: true }, fieldProps),
            _react2.default.createElement(_core.TextField, {
              name: "school",
              label: literals.APP_SCHOOL || "School",
              readOnly: true,
              loading: false,
              initialValue: currentAppUser.group && currentAppUser.group.name || schools.length && schools.join(",") || currentAppUser.school && currentAppUser.school.name || "",
              form: form
            })
          ),
          !hideOrganisation && _react2.default.createElement(
            _Grid2.default,
            _extends({ item: true }, fieldProps),
            _react2.default.createElement(_core.TextField, {
              name: "organisations",
              label: literals.APP_ORGANISATION || "Organisatie",
              readOnly: true,
              loading: false,
              initialValue: organisations && organisations.map(function (x) {
                return x.name;
              }).join(",") || "",
              form: form
            })
          ),
          !hideSchoolClass && _react2.default.createElement(
            _appCore.AccessControl,
            { not: ["SCHOOL_MANAGER", "TEACHER"] },
            _react2.default.createElement(
              _Grid2.default,
              _extends({ item: true }, fieldProps),
              _react2.default.createElement(_core.TextField, {
                name: "schoolClass",
                label: literals.APP_CLASS_NAME_ACCOUNT_LABEL || "Klas",
                readOnly: true,
                loading: false,
                initialValue: currentAppUser.schoolClasses && currentAppUser.schoolClasses.map(function (x) {
                  return x.name;
                }).join(",") || "",
                form: form
              })
            )
          ),
          !hideFunction && _react2.default.createElement(
            _Grid2.default,
            _extends({ item: true }, fieldProps),
            _react2.default.createElement(_core.TextField, {
              name: "userFunction",
              label: "Functie",
              readOnly: true,
              loading: false,
              initialValue: userFunction || "",
              form: form
            })
          )
        )
      ),
      !hideDelete && _react2.default.createElement(
        _react2.default.Fragment,
        null,
        _react2.default.createElement(_Button2.default, {
          className: classes.deleteButton,
          children: "Account verwijderen",
          onClick: openConfirmationDialog
        }),
        _react2.default.createElement(
          _Dialog2.default,
          {
            open: open,
            onClose: closeConfirmationDialog,
            PaperProps: { className: classes.dialogPaper }
          },
          _react2.default.createElement(
            _Typography2.default,
            { variant: "h3", className: classes.dialogTitle },
            "Weet je zeker dat je je account wilt verwijderen?"
          ),
          _react2.default.createElement(_core.PasswordField, {
            name: "password",
            label: "Bevestig met uw wachtwoord",
            shrink: true,
            placeholder: "Voer uw wachtwoord in",
            form: form
          }),
          _react2.default.createElement(
            "div",
            { className: classes.dialogActions },
            _react2.default.createElement(
              _Button2.default,
              {
                variant: "contained",
                className: classes.confirmDeleteButton,
                onClick: handleAppUserDelete
              },
              "Ja"
            ),
            _react2.default.createElement(
              _Button2.default,
              {
                variant: "contained",
                className: classes.cancelDeleteButton,
                onClick: closeConfirmationDialog
              },
              "Annuleer"
            )
          )
        )
      ),
      switchFunction && _react2.default.createElement(
        _react2.default.Fragment,
        null,
        _react2.default.createElement(
          "div",
          { className: classes.buttonBundle },
          _react2.default.createElement(
            _Button2.default,
            {
              onClick: handleClick,
              color: "primary",
              variant: "contained",
              className: classes.mainButtonStyle
            },
            "Aanvragen functie wisseling"
          ),
          _react2.default.createElement(
            _Button2.default,
            {
              onClick: handleDepartmentClick,
              color: "primary",
              variant: "contained",
              className: classes.mainButtonStyle
            },
            "Aanvragen Filiaal wisseling"
          )
        ),
        _react2.default.createElement(
          _Dialog2.default,
          {
            open: openDepartment,
            onClose: closeDepartment,
            PaperProps: { className: classes.dialogPaper }
          },
          _react2.default.createElement(
            _Typography2.default,
            { variant: "h3", className: classes.dialogTitle },
            "Wilt u wisselen van filiaal?"
          ),
          _react2.default.createElement(
            _Typography2.default,
            { variant: "body1", className: classes.dialogContent },
            "Kies de organisatie en filiaal waar u naar toe wilt wisselen."
          ),
          _react2.default.createElement(
            "div",
            { className: classes.organisationFields },
            _react2.default.createElement(_core.SelectField, {
              options: options,
              name: "mainOrganisation",
              label: "Kies organisatie",
              form: form,
              initialValue: {
                id: form.fields && form.fields.mainOrganisation
              },
              onChange: handleOrganisationChange,
              alpha: true
            }),
            selectedOrganisation && _react2.default.createElement(_appCore.SchoolclassFromMainOrganisation, {
              id: selectedOrganisation,
              form: form
            })
          ),
          _react2.default.createElement(
            "div",
            { className: classes.dialogActions },
            _react2.default.createElement(
              _Button2.default,
              {
                color: "primary",
                variant: "contained",
                onClick: handleDepartmentChange
              },
              "Ja"
            ),
            _react2.default.createElement(
              _Button2.default,
              {
                variant: "contained",
                className: classes.cancelDeleteButton,
                onClick: closeDepartment
              },
              "Annuleer"
            )
          )
        ),
        _react2.default.createElement(
          _Dialog2.default,
          {
            open: openFunction,
            onClose: closeFunction,
            PaperProps: { className: classes.dialogPaper }
          },
          _react2.default.createElement(
            _Typography2.default,
            { variant: "h3", className: classes.dialogTitle },
            "Wilt u wisselen van functie?"
          ),
          _react2.default.createElement(
            _Typography2.default,
            { variant: "body1", className: classes.dialogContent },
            "Weet u zeker dat u van functie wil wisselen? Bij deze functie horen andere nascholingsverplichtingen"
          ),
          _react2.default.createElement(
            _Typography2.default,
            { variant: "body1", className: classes.dialogContent },
            "U ben nu ",
            _react2.default.createElement(
              "strong",
              null,
              userFunction
            ),
            " wilt u wisselen naar",
            " ",
            _react2.default.createElement(
              "strong",
              null,
              otherFunction,
              " ?"
            )
          ),
          _react2.default.createElement(
            "div",
            { className: classes.dialogActions },
            _react2.default.createElement(
              _Button2.default,
              {
                color: "primary",
                variant: "contained",
                onClick: handleFunctionChange
              },
              "Ja"
            ),
            _react2.default.createElement(
              _Button2.default,
              {
                variant: "contained",
                className: classes.cancelDeleteButton,
                onClick: closeFunction
              },
              "Annuleer"
            )
          )
        )
      )
    )
  );
};

exports.default = (0, _recompose.compose)((0, _styles.withStyles)(_styles2.styles, { name: "AppAccount" }), (0, _core.withForm)(formBundle), _reactApollo.withApollo, (0, _reactRedux.connect)(function (state) {
  return {
    config: state.config,
    appUser: (0, _selectors.getAppUser)(state)
  };
}, function (dispatch) {
  return {
    onSetAppBarColor: function onSetAppBarColor(color) {
      return dispatch((0, _actions.setAppBarBackgroundColor)(color));
    },
    onSetAppBarDropDown: function onSetAppBarDropDown(dropDown) {
      return dispatch((0, _actions.setAppBarDropDown)(dropDown));
    },
    goToChangePasswordPage: function goToChangePasswordPage() {
      return dispatch((0, _reduxFirstHistory.push)("/account/change-password"));
    },
    onLogin: function onLogin(user) {
      dispatch((0, _actions2.login)("/account", user));
    },
    onLogout: function onLogout() {
      return dispatch((0, _actions2.logout)());
    },
    onChangeDepartmentIncomplete: function onChangeDepartmentIncomplete() {
      return dispatch((0, _actions3.showSnackbarMessage)({
        text: "Filiaal wissel mislukt, U heeft nog geen filiaal gekozen",
        variant: "error"
      }));
    },
    onChangeFunctionSucces: function onChangeFunctionSucces() {
      return dispatch((0, _actions3.showSnackbarMessage)({ text: "Aanvraag gelukt", variant: "success" }));
    },
    onChangeFunctionFailed: function onChangeFunctionFailed() {
      return dispatch((0, _actions3.showSnackbarMessage)({
        text: "Aanvraag mislukt, probeer het nog eens",
        variant: "error"
      }));
    },
    processUpdateSuccess: function processUpdateSuccess(literals) {
      dispatch((0, _actions3.showSnackbarMessage)({
        text: literals.APP_SAVE_SUCCES || "Gegevens zijn aangepast.",
        variant: "success"
      }));
      // dispatch(push("/"));
    },
    processUpdateSuccessNewsLetter: function processUpdateSuccessNewsLetter(literals) {
      dispatch((0, _actions3.showSnackbarMessage)({
        text: literals.APP_LANGUAGE_SAVE_SUCCES || "Nieuwsbrief voorkeuren aangepast.",
        variant: "success"
      }));
    },
    processUpdateFailure: function processUpdateFailure(literals) {
      dispatch((0, _actions3.showSnackbarMessage)({
        text: literals.APP_LANGUAGE_SAVE_FAIL || "App gebruiker aanpassen mislukt.",
        variant: "error"
      }));
    }
  };
}), (0, _reactApollo.graphql)(_graphql.GQL_FETCH_CURRENT_APP_USER, {
  name: "APP_USER",
  options: function options(props) {
    return {
      variables: {
        id: props.appUser && props.appUser.id
      }
    };
  }
}), (0, _reactApollo.graphql)(_graphql.GQL_FETCH_GROUPS, {
  options: function options(props) {
    return {
      variables: {
        page: 0,
        pagesize: 3000,
        filter: {
          types: ["MainOrganisation"]
        }
      }
    };
  }
}), (0, _reactApollo.graphql)(_graphql.GQL_UPDATE_APP_USER, { name: "updateAppUser" }), (0, _reactApollo.graphql)(_graphql.GQL_CHANGE_FUNCTION), (0, _reactApollo.graphql)(_graphql.GQL_FETCH_LOCALES, { name: "LOCALES" }), (0, _reactApollo.graphql)(_graphql.GQL_CHANGE_DEPARTMENT, { name: "CHANGE_DEPARTMENT" }), (0, _recompose.withHandlers)({
  onUpdateNewsLetter: function onUpdateNewsLetter(_ref) {
    var updateAppUser = _ref.updateAppUser,
        processUpdateSuccessNewsLetter = _ref.processUpdateSuccessNewsLetter,
        processUpdateFailure = _ref.processUpdateFailure,
        _ref$config = _ref.config,
        config = _ref$config === undefined ? {} : _ref$config;
    return function (event) {
      return updateAppUser({
        variables: _extends({}, event),
        context: {
          hasUpload: true
        }
      }).then(function (result) {
        var _config$literals2 = config.literals,
            literals = _config$literals2 === undefined ? {} : _config$literals2;

        if (!result.errors) {
          processUpdateSuccessNewsLetter(literals);
        } else {
          processUpdateFailure(literals);
        }
      });
    };
  },
  onUpdate: function onUpdate(_ref2) {
    var updateAppUser = _ref2.updateAppUser,
        processUpdateSuccess = _ref2.processUpdateSuccess,
        processUpdateFailure = _ref2.processUpdateFailure,
        onLogin = _ref2.onLogin,
        _ref2$config = _ref2.config,
        config = _ref2$config === undefined ? {} : _ref2$config;
    return function (event) {
      return updateAppUser({
        variables: _extends({}, event)
      }).then(function (result) {
        var _config$literals3 = config.literals,
            literals = _config$literals3 === undefined ? {} : _config$literals3;

        if (!result.errors) {
          onLogin(result.data.updateAppUser.user);
          processUpdateSuccess(literals);
        } else {
          processUpdateFailure(literals);
        }
      }).catch(function (e) {});
    };
  },
  changeFunction: function changeFunction(_ref3) {
    var mutate = _ref3.mutate;
    return function (event) {
      return mutate({
        variables: _extends({}, event)
      });
    };
  },
  changeDepartment: function changeDepartment(_ref4) {
    var CHANGE_DEPARTMENT = _ref4.CHANGE_DEPARTMENT;
    return function (event) {
      return CHANGE_DEPARTMENT({
        variables: _extends({}, event)
      });
    };
  }
}))(Account);