'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GQL_FETCH_APP_USER_FOR_STORE = exports.GQL_DELETE_LICENSE = exports.GQL_FETCH_PRODUCTS = exports.GQL_CLAIM_LICENSE = exports.GQL_GENERATE_LICENSES = exports.GQL_FETCH_LICENSE_TO_CLAIM = exports.GQL_FETCH_LICENSE = exports.GQL_FETCH_LICENSES = undefined;

var _templateObject = _taggedTemplateLiteral(['\n  query(\n    $page: Int!\n    $pagesize: Int!\n    $search: String\n    $direction: String\n    $sortProperties: [String]\n  ) {\n    licenseGroups(\n      page: $page\n      pagesize: $pagesize\n      search: $search\n      direction: $direction\n      sortProperties: $sortProperties\n    ) {\n      licenseGroups {\n        id\n        name\n        createdOn\n        daysValid\n        product {\n          name\n        }\n      }\n      count\n    }\n  }\n'], ['\n  query(\n    $page: Int!\n    $pagesize: Int!\n    $search: String\n    $direction: String\n    $sortProperties: [String]\n  ) {\n    licenseGroups(\n      page: $page\n      pagesize: $pagesize\n      search: $search\n      direction: $direction\n      sortProperties: $sortProperties\n    ) {\n      licenseGroups {\n        id\n        name\n        createdOn\n        daysValid\n        product {\n          name\n        }\n      }\n      count\n    }\n  }\n']),
    _templateObject2 = _taggedTemplateLiteral(['\n  query($id: ID!) {\n    licenseGroup(id: $id) {\n      id\n      name\n      product {\n        id\n        name\n      }\n      createdOn\n      daysValid\n      validUntil\n      licenses {\n        code\n        claimedOn\n        validUntil\n        user {\n          id\n          fullName\n        }\n      }\n    }\n  }\n'], ['\n  query($id: ID!) {\n    licenseGroup(id: $id) {\n      id\n      name\n      product {\n        id\n        name\n      }\n      createdOn\n      daysValid\n      validUntil\n      licenses {\n        code\n        claimedOn\n        validUntil\n        user {\n          id\n          fullName\n        }\n      }\n    }\n  }\n']),
    _templateObject3 = _taggedTemplateLiteral(['\n  query($code: String!) {\n    license(code: $code) {\n      code\n      claimedOn\n      validUntil\n    }\n  }\n'], ['\n  query($code: String!) {\n    license(code: $code) {\n      code\n      claimedOn\n      validUntil\n    }\n  }\n']),
    _templateObject4 = _taggedTemplateLiteral(['\n  mutation(\n    $product: ID!\n    $amount: Int!\n    $name: String!\n    $validUntil: String\n    $daysValid: Int\n  ) {\n    generateLicenses(\n      input: {\n        product: $product\n        amount: $amount\n        name: $name\n        validUntil: $validUntil\n        daysValid: $daysValid\n      }\n    ) {\n      licenseGroup {\n        id\n      }\n    }\n  }\n'], ['\n  mutation(\n    $product: ID!\n    $amount: Int!\n    $name: String!\n    $validUntil: String\n    $daysValid: Int\n  ) {\n    generateLicenses(\n      input: {\n        product: $product\n        amount: $amount\n        name: $name\n        validUntil: $validUntil\n        daysValid: $daysValid\n      }\n    ) {\n      licenseGroup {\n        id\n      }\n    }\n  }\n']),
    _templateObject5 = _taggedTemplateLiteral(['\n  mutation claimLicense($code: String!) {\n    claimLicense(input: { code: $code }) {\n      result\n    }\n  }\n'], ['\n  mutation claimLicense($code: String!) {\n    claimLicense(input: { code: $code }) {\n      result\n    }\n  }\n']),
    _templateObject6 = _taggedTemplateLiteral(['\n  query($page: Int!, $pagesize: Int!, $search: String) {\n    products(page: $page, pagesize: $pagesize, search: $search) {\n      products {\n        id\n        name\n        prefix\n        priceCents\n        program {\n          id\n          name\n        }\n      }\n      count\n    }\n  }\n'], ['\n  query($page: Int!, $pagesize: Int!, $search: String) {\n    products(page: $page, pagesize: $pagesize, search: $search) {\n      products {\n        id\n        name\n        prefix\n        priceCents\n        program {\n          id\n          name\n        }\n      }\n      count\n    }\n  }\n']),
    _templateObject7 = _taggedTemplateLiteral(['\n  mutation($id: ID!) {\n    deleteLicenseGroup(id: $id) {\n      result\n    }\n  }\n'], ['\n  mutation($id: ID!) {\n    deleteLicenseGroup(id: $id) {\n      result\n    }\n  }\n']),
    _templateObject8 = _taggedTemplateLiteral(['\n  query {\n    currentAppUser {\n      id\n      firstName\n      lastName\n      licenses {\n        code\n        validUntil\n        licenseGroup {\n          id\n          product {\n            id\n            program {\n              id\n              name\n            }\n          }\n        }\n      }\n      locale {\n        code\n      }\n      group {\n        id\n        name\n      }\n      roles {\n        id\n        name\n      }\n    }\n  }\n'], ['\n  query {\n    currentAppUser {\n      id\n      firstName\n      lastName\n      licenses {\n        code\n        validUntil\n        licenseGroup {\n          id\n          product {\n            id\n            program {\n              id\n              name\n            }\n          }\n        }\n      }\n      locale {\n        code\n      }\n      group {\n        id\n        name\n      }\n      roles {\n        id\n        name\n      }\n    }\n  }\n']);

var _graphqlTag = require('graphql-tag');

var _graphqlTag2 = _interopRequireDefault(_graphqlTag);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteral(strings, raw) { return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var GQL_FETCH_LICENSES = exports.GQL_FETCH_LICENSES = (0, _graphqlTag2.default)(_templateObject);

var GQL_FETCH_LICENSE = exports.GQL_FETCH_LICENSE = (0, _graphqlTag2.default)(_templateObject2);

var GQL_FETCH_LICENSE_TO_CLAIM = exports.GQL_FETCH_LICENSE_TO_CLAIM = (0, _graphqlTag2.default)(_templateObject3);

var GQL_GENERATE_LICENSES = exports.GQL_GENERATE_LICENSES = (0, _graphqlTag2.default)(_templateObject4);

var GQL_CLAIM_LICENSE = exports.GQL_CLAIM_LICENSE = (0, _graphqlTag2.default)(_templateObject5);

var GQL_FETCH_PRODUCTS = exports.GQL_FETCH_PRODUCTS = (0, _graphqlTag2.default)(_templateObject6);

var GQL_DELETE_LICENSE = exports.GQL_DELETE_LICENSE = (0, _graphqlTag2.default)(_templateObject7);

var GQL_FETCH_APP_USER_FOR_STORE = exports.GQL_FETCH_APP_USER_FOR_STORE = (0, _graphqlTag2.default)(_templateObject8);