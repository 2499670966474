export const styles = theme => ({
  wrapper: {
    ...theme.manakin.largeWrapper
  },
  smallWrapper: {
    ...theme.manakin.defaultWrapper,
    marginBottom: '3rem',
    [theme.breakpoints.up('md')]: {
      marginBottom: '8rem'
    }
  },
  fontStyle: {},
  bar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '3rem'
  },
  root: {
    opacity: 0,
    '& $fontStyle': {
      margin: 0
    }
  },
  animate: {
    transition: 'opacity .4s .6s',
    opacity: 1
  },
  loading: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  content: {
    display: 'flex',
    flexWrap: 'wrap',
    '& >*': {
      width: '100%'
    },
    [theme.breakpoints.up('sm')]: {
      '& >*': {
        width: 'calc(50% - .5rem)'
      },
      '& >*:nth-child(odd)': {
        marginRight: '.5rem'
      },
      '& >*:nth-child(even)': {
        marginLeft: '.5rem'
      }
    },
    [theme.breakpoints.up('md')]: {
      '& >*': {
        width: 'calc(50% - 1rem)'
      },
      '& >*:nth-child(odd)': {
        marginRight: '1rem'
      },
      '& >*:nth-child(even)': {
        marginLeft: '1rem'
      }
    },
    [theme.breakpoints.up('lg')]: {
      '& >*': {
        width: 'calc(50% - 2.5rem)'
      },
      '& >*:nth-child(odd)': {
        marginRight: '2.5rem'
      },
      '& >*:nth-child(even)': {
        marginLeft: '2.5rem'
      }
    }
  },
  link: {
    textDecoration: 'none'
  },
  linkText: {
    fontWeight: 600,
    color: theme.manakin.primaryColor[500],
    display: 'flex',
    alignItems: 'center',
    transition: 'color .2s',
    '&:hover': {
      color: theme.manakin.defaultContentHover[500]
    }
  }
});
