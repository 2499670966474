'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styles = require('@material-ui/core/styles');

var _Typography = require('@material-ui/core/Typography');

var _Typography2 = _interopRequireDefault(_Typography);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var styles = function styles(theme) {
  return {
    listItem: {
      width: 200,
      display: 'flex',
      cursor: 'pointer',
      padding: theme.spacing(2) + 'px 0'
    },
    listItemLine: {
      width: '40%',
      height: 3,
      marginRight: '10%',
      alignSelf: 'center'
    },
    listItemLineSelected: {
      backgroundColor: '#F28F7F'
    },
    listItemTitle: {
      fontWeight: 'normal',
      margin: 0
    },
    listItemTitleSelected: {
      color: '#F28F7F'
    }
  };
};

var MenuItem = function MenuItem(props) {
  var idx = props.idx,
      option = props.option,
      classes = props.classes;

  //functions

  var handleMenuClick = function handleMenuClick() {
    if (props.onClick && option.value) props.onClick(option.value);
  };

  return _react2.default.createElement(
    'div',
    { className: classes.listItem, key: idx, onClick: handleMenuClick },
    _react2.default.createElement('div', {
      className: (0, _classnames2.default)(classes.listItemLine, _defineProperty({}, classes.listItemLineSelected, option.selected))
    }),
    _react2.default.createElement(
      _Typography2.default,
      {
        classes: {
          subtitle1: (0, _classnames2.default)(classes.listItemTitle, _defineProperty({}, classes.listItemTitleSelected, option.selected))
        },
        align: 'center',
        variant: 'subtitle1'
      },
      option.label
    )
  );
};

exports.default = (0, _styles.withStyles)(styles)(MenuItem);