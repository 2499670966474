import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { compose } from 'recompose';

const styles = theme => ({
  root: {
    width: '100%',
    backgroundColor: 'black',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row'
    }
  },
  button: {
    fontFamily: `${theme.manakin.defaultTitleFont}!important`,
    fontSize: '2rem',
    height: '10rem',
    display: 'none',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    textDecoration: 'none',
    transition: 'background-color .3s',
    cursor: 'pointer',
    backgroundColor: theme.manakin.LessonButtonColor[500],
    color: theme.palette.getContrastText(theme.manakin.LessonButtonColor[500]),
    padding: theme.spacing(2),
    letterSpacing: '4px',
    [theme.breakpoints.up('md')]: {
      fontSize: '4rem',
      height: '40rem',
      fontWeight: '600'
    },
    '&:hover': {
      backgroundColor: theme.manakin.LessonButtonColor[700]
    }
  },
  backToBoxButton: {
    display: 'flex'
  },
  redoQuestionButton: {
    width: '100%',
    margin: 0
  },
  nextQuestionButton: {
    display: 'none',
    width: '100%',
    textDecoration: 'none',
    margin: 0,
    color: theme.palette.getContrastText(theme.manakin.LessonButtonColor[50]),
    backgroundColor: theme.manakin.LessonButtonColor[50],
    '&:hover': {
      backgroundColor: theme.manakin.LessonButtonColor[100]
    }
  }
});

class Bottom extends React.PureComponent {
  state = {
    next: null
  };

  handleClick = id => {
    if (this.props.onClick) this.props.onClick(id);
  };

  render() {
    const { classes, box, lessonData, config = {} } = this.props;
    const { next } = this.state;

    box &&
      box.elements &&
      lessonData.id &&
      box.elements.forEach((item, idx) => {
        if (item.id == lessonData.id) {
          if (idx + 1 < box.elements.length) {
            if (box.elements[idx + 1].type !== 'Bridge') {
              this.setState({
                next: `/${box.elements[idx + 1].type}/${box.elements[idx + 1].id}`
              });
            }
          }
        }
      });
    return (
      <div className={classes.root}>
        <Link
          className={classNames(classes.button, classes.backToBoxButton)}
          to={`/box/${box.id}`}
        >
          {config.literals && config.literals.APP_LESSON_BACK_TO_BOX}
        </Link>
        <div
          className={classNames(classes.button, classes.redoQuestionButton)}
          onClick={() => this.handleClick(lessonData.id)}
        >
          {config.literals && config.literals.APP_LESSON_REDO_QUESTION}
        </div>
        {next && (
          <Link to={next} className={classes.nextQuestionButton}>
            <div
              variant="contained"
              className={classNames(classes.button, classes.nextQuestionButton)}
            >
              {config.literals && config.literals.APP_LESSON_NEXT_QUESTION}
            </div>
          </Link>
        )}
      </div>
    );
  }
}

export default compose(
  withStyles(styles, { name: 'AppLessonBottom' }),
  connect(({ config }) => ({
    config
  }))
)(Bottom);
