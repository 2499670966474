import React from 'react';
import TextField from '@material-ui/core/TextField';
import classNames from 'classnames';
import { LoadImage } from '@manakin/app-core';
import CircularProgress from '@material-ui/core/CircularProgress';

class Slide extends React.PureComponent {
  render() {
  
    const { classes, handleDataChange, slideIndex, slideType, slideData, slide } = this.props;
    let storedSlideData;

    slideData && slideData.map(slide => {
      if( slideIndex === slide.idx ) {
        storedSlideData = slide.data
      } 
    })
  
    if( slideType === 'text' ) {
      return (
        <React.Fragment>
          <div className={classes.textSlide}>
            <div className={classes.inputWrap}>
              <TextField classes={{ root: classes.slideTextRoot }}
                multiline
                rowsMax={Infinity} 
                defaultValue={storedSlideData ? storedSlideData.title : null}
                autoFocus={true}
                onChange={() => handleDataChange(slideIndex, 'title', event)} 
                InputProps={{ 
                  disableUnderline: true,  
                  placeholder: 'Voeg titel toe',
                }}
                inputProps={{classes: {textarea: classes.slideTitleInput}}}             
              />
            </div>
            
            <div className={classNames(classes.inputWrap, classes.textSlideContent)}>
              <TextField classes={{ root: classes.slideTextRoot }}
                multiline
                rows={8}
                rowsMax={Infinity} 
                defaultValue={storedSlideData ? storedSlideData.text : null}
                onChange={() => handleDataChange(slideIndex, 'text', event)} 
                InputProps={{ 
                  disableUnderline: true, 
                  classes: { root: classes.slideTextFieldInput },
                  placeholder: 'Voeg tekst toe'   
                }}
                inputProps={{ classes: { textarea: classes.slideTextFieldInput } }}             
              />
            </div>
          </div>
        </React.Fragment>
      )
    } else if( slideType === 'image' ) {
      return (
        <div className={classes.imageSlide}>
          <LoadImage 
            src={slide.image.asset.url}
            loader={<CircularProgress />} 
          />
        </div>
      )
    }
  }
}

export default Slide;