import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Media from "react-media";

const styles = theme => ({
    container: {
        width: '100%',
    },
    left: {
        width: '50%',
        float: 'left'
    },
    right: {
        width: '50%',
        float: 'left'
    },
    title: {
        width: '383px',
        color: '#000000',
        fontSize: '40px',
        fontWeight: '800',
        lineHeight: '54px',
        paddingLeft: theme.spacing(8),
        paddingTop: theme.spacing(8)
    },
    text: {
        width: '502px',
        color: '#000000',
        fontSize: '18px',
        lineHeight: '36px',
        paddingLeft: theme.spacing(8),
        paddingTop: theme.spacing(4)
    },
    titleMobile: {
        color: '#000000',
        fontSize: '40px',
        fontWeight: '800',
        lineHeight: '54px',
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingTop: theme.spacing(3)
    },
    textMobile: {
        color: '#000000',
        fontSize: '18px',
        lineHeight: '36px',
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingTop: theme.spacing(4)
    },
    imageMobile: {
        paddingTop: theme.spacing(4)
    }
});

class TitleTextImage extends React.Component {
    render() {
        const { classes, title, text, backgroundColor, image, imagePosition } = this.props;

        return (
            <Media query="(max-width: 860px)">
            {matches => matches ? (
                <div className={classes.container} style={{backgroundColor: backgroundColor}}>
                    <Typography className={classes.titleMobile}>
                        {title}
                    </Typography>

                    <Typography className={classes.textMobile}>
                        {text}
                    </Typography>

                    <img src={image} className={classes.imageMobile} />
                </div>
            ) : (
                <div className={classes.container} style={{backgroundColor: backgroundColor}}>
                    <div className={classes.left}>
                        {imagePosition.toLowerCase() === 'left' ? (
                            <img src={image} />
                        ) : (
                            <div>
                                <Typography className={classes.title}>
                                    {title}
                                </Typography>

                                <Typography className={classes.text}>
                                    {text}
                                </Typography>
                            </div>
                        )}
                    </div>

                    <div className={classes.right}>
                        {imagePosition.toLowerCase() === 'right' ? (
                            <img src={image} />
                        ) : (
                            <div>
                                <Typography className={classes.title}>
                                    {title}
                                </Typography>

                                <Typography className={classes.text}>
                                    {text}
                                </Typography>
                            </div>
                        )}
                    </div>

                    <div style={{clear: 'both'}}></div>
                </div>
            )}
            </Media>
        )
    }
}

export default withStyles(styles, { withTheme: true })(TitleTextImage);
