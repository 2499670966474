import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { graphql } from 'react-apollo';
import { GQL_CHANGE_PASSWORD } from './graphql/graphql';
import ConfirmReset from '@manakin/authentication/ConfirmReset';
import Cookies from 'js-cookie';

const styles = theme => ({
  root: {
    backgroundColor: theme.manakin.defaultBackgroundColor[500],
    [theme.breakpoints.up('md')]: {
      height: '100vh',
      minHeight: '900px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center'
    }
  },
  content: {
    height: 'auto'
  }
});

const AppConfirmResetPassword = graphql(GQL_CHANGE_PASSWORD)(ConfirmReset);

const ConformResetPasswordView = props => {
  const { classes } = props;

  useEffect(() => {
    Cookies.set('first_time', '1', { expires: 365 });
  }, []);

  return (
    <div className={classes.root}>
      <AppConfirmResetPassword />
    </div>
  );
};

export default withStyles(styles, { name: "AppConfomResetPassword" })(ConformResetPasswordView);
