import React from 'react';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import { TextField, PasswordField } from '@manakin/core';
import { withStyles } from '@material-ui/core/styles';
import { withForm } from '@manakin/core';

const styles = theme => ({
  root: {
    display: 'flex',
    flexFlow: 'column nowrap'
  },
  submit: {
    marginTop: theme.spacing(2)
  },
  progress: {
    margin: `${theme.spacing(4)}px auto`,
    display: 'block',
    width: 25,
    height: 25,
    color: 'white'
  },
  nativeInput: {},
  input: {},
  label: {}
});

const form = {
  password: {
    required: true
  },
  email: {
    required: true,
    format: 'email'
  }
};

class LoginForm extends React.PureComponent {
  handleSubmit = () => {
    const { form } = this.props;
    const { onSubmit } = form;
    const hasEmail = !!(form.fields ? form.fields.email : '');
    const hasPassword = !!(form.fields ? form.fields.password : '');
    setTimeout(onSubmit, hasPassword && hasEmail ? 0 : 750);
  };

  render() {
    const { classes, form, styles } = this.props;
    const { onSubmit } = form;

    return (
      <div className={classes.root}>
        <TextField
          autoFocus
          name="email"
          label="E-mailadres"
          type="email"
          shrink={false}
          hideAsterisk
          form={form}
          margin={'dense'}
          inputProps={{
            className: styles ? styles.nativeInput : classes.nativeInput
          }}
          classes={{
            input: styles ? styles.input : classes.input,
            label: styles ? styles.label : classes.label
          }}
        />
        <PasswordField
          name="password"
          label="Wachtwoord"
          onEnterPressed={onSubmit}
          form={form}
          inputProps={{
            className: styles ? styles.nativeInput : classes.nativeInput
          }}
          classes={{
            input: styles ? styles.input : classes.input,
            label: styles ? styles.label : classes.label
          }}
        />
        {/* <FormControl margin="dense"> */}
        <Button
          className={classes.submit}
          color="primary"
          variant="contained"
          fullWidth
          onClick={this.handleSubmit}
        >
          Login
        </Button>
        {/* </FormControl> */}
      </div>
    );
  }
}

export default withForm(form)(
  withStyles(styles, { name: 'AuthenticationLoginForm' })(LoginForm)
);
