'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _nukaCarousel = require('nuka-carousel');

var _nukaCarousel2 = _interopRequireDefault(_nukaCarousel);

var _styles = require('@material-ui/styles');

var _useMediaQuery = require('@material-ui/core/useMediaQuery');

var _useMediaQuery2 = _interopRequireDefault(_useMediaQuery);

var _Typography = require('@material-ui/core/Typography');

var _Typography2 = _interopRequireDefault(_Typography);

var _styles2 = require('@material-ui/core/styles');

var _ArrowForward = require('@material-ui/icons/ArrowForward');

var _ArrowForward2 = _interopRequireDefault(_ArrowForward);

var _ArrowBack = require('@material-ui/icons/ArrowBack');

var _ArrowBack2 = _interopRequireDefault(_ArrowBack);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var styles = function styles(theme) {
  return {
    title: {},
    arrow: {
      position: 'relative',
      width: '1.2rem',
      height: '1.2rem',
      color: theme.manakin.defaultTitleColor[500]
    },
    root: {
      '& $title': {
        marginBottom: '4rem',
        padding: '0 75px 0 0'
      },
      '& .slider-control-centerleft': {
        top: '-60px!important',
        left: 'auto!important',
        right: '25px'
      },
      '& .slider-control-centerright': {
        top: '-60px!important',
        right: '0px!important'
      }
    },
    line: {
      width: '1px',
      height: '100%',
      backgroundColor: theme.manakin.defaultTitleColor[500],
      position: 'absolute',
      left: '2.4rem',
      top: '6px'
    }
  };
};

var renderControl = function renderControl(action, classes, type) {
  return _react2.default.createElement(
    'div',
    { className: classes.arrow, onClick: action },
    type === 'left' && _react2.default.createElement(_ArrowBack2.default, null),
    type === 'left' && _react2.default.createElement('div', { className: classes.line }),
    type === 'right' && _react2.default.createElement(_ArrowForward2.default, null)
  );
};

var _Carousel = function _Carousel(props) {
  var children = props.children,
      _props$dragging = props.dragging,
      dragging = _props$dragging === undefined ? true : _props$dragging,
      _props$slidesToShow = props.slidesToShow,
      slidesToShow = _props$slidesToShow === undefined ? 1 : _props$slidesToShow,
      _props$slidesToShowSM = props.slidesToShowSM,
      slidesToShowSM = _props$slidesToShowSM === undefined ? 1 : _props$slidesToShowSM,
      _props$slidesToShowMD = props.slidesToShowMD,
      slidesToShowMD = _props$slidesToShowMD === undefined ? 2 : _props$slidesToShowMD,
      _props$slidesToScroll = props.slidesToScrollSM,
      slidesToScrollSM = _props$slidesToScroll === undefined ? 1 : _props$slidesToScroll,
      _props$slidesToScroll2 = props.slidesToScrollMD,
      slidesToScrollMD = _props$slidesToScroll2 === undefined ? 2 : _props$slidesToScroll2,
      _props$transitionMode = props.transitionMode,
      transitionMode = _props$transitionMode === undefined ? 'scroll' : _props$transitionMode,
      _props$withoutControl = props.withoutControls,
      withoutControls = _props$withoutControl === undefined ? true : _props$withoutControl,
      _props$title = props.title,
      title = _props$title === undefined ? false : _props$title,
      classes = props.classes;


  var theme = (0, _styles.useTheme)();
  var matchesMD = (0, _useMediaQuery2.default)(theme.breakpoints.up('md'));
  var matchesSM = (0, _useMediaQuery2.default)(theme.breakpoints.up('sm'));

  return _react2.default.createElement(
    'div',
    { className: classes.root },
    title && _react2.default.createElement(
      _Typography2.default,
      { component: 'h3', variant: 'h3', className: classes.title },
      title
    ),
    _react2.default.createElement(
      'div',
      { className: classes.carousel },
      _react2.default.createElement(
        _nukaCarousel2.default,
        {
          slidesToShow: matchesMD ? slidesToShowMD : matchesSM ? slidesToShowSM : slidesToShow,
          slidesToScroll: matchesMD ? slidesToScrollMD : matchesSM ? slidesToScrollSM : 1,
          dragging: dragging,
          transitionMode: transitionMode,
          frameOverflow: 'visible',
          cellAlign: 'left',
          withoutControls: withoutControls,
          disableEdgeSwiping: true,
          renderBottomCenterControls: null,
          renderCenterLeftControls: function renderCenterLeftControls(_ref) {
            var previousSlide = _ref.previousSlide;
            return renderControl(previousSlide, classes, 'left');
          },
          renderCenterRightControls: function renderCenterRightControls(_ref2) {
            var nextSlide = _ref2.nextSlide;
            return renderControl(nextSlide, classes, 'right');
          }
        },
        children
      )
    )
  );
};

exports.default = (0, _styles2.withStyles)(styles)(_Carousel);