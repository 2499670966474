import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import classNames from 'classnames';

const styles = theme => ({
    progress: {
        margin: theme.spacing(2)
    },
    center: { 
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)'
    }
});

class Loader extends React.Component {
    render() {
        const { classes, center = false, ...other } = this.props;
        return (
            <div className={classNames({[classes.center]: center})}>
                <CircularProgress className={classes.progress} {...other} />
            </div>
        );
    }
}

export default withStyles(styles)(Loader);
