import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";

const style = theme => ({
  root: {
    padding: "80px 0 0",
    backgroundColor: theme.manakin.primaryColor[500],
    height: "100vh",
    display: "flex"
  },
  stepperRoot: {
    width: "100%",
    alignItems: "center",
    justifyContent: "center"
  },
  stepRoot: {
    flex: "0 0 auto"
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    maxWidth: "900px",
    alignItems: "center",
    margin: "0 auto",
    alignSelf: "center",
    [theme.breakpoints.down("sm")]: {
      width: "80%"
    }
  },
  buttonGroup: {
    zIndex: 0,
    textAlign: "center",
    width: "100%",
    "& a": {
      textDecoration: "none"
    }
  },
  buttonRoot: {
    display: "block",
    margin: "0 auto"
  },
  content: {
    width: "100%",
    margin: "0 0 80px"
  },
  regiatrationAppear: {
    display: "block"
  },
  registrationEnter: {
    opacity: 0,
    position: "relative",
    transform: "translateX(100px)"
  },
  registrationEnterActive: {
    opacity: 1,
    transform: "translateX(0)"
  },
  paragraph: {
    textAlign: "center",
    width: "100%",
    margin: "0 auto 3em",
    maxWidth: "560px"
  },
  smallParagraph: {
    textAlign: "center",
    width: "100%",
    margin: "0 auto",
    marginBottom: "0.5em",
    maxWidth: "560px"
  },
  Link: {
    textDecoration: "none"
  },
  heading: {}
});

const AppRegistrationSuccess = props => {
  const { classes, config } = props;

  return (
    <div className={classes.root}>
      <form className={classes.container}>
        <div className={classes.content}>
          <Typography
            classes={{ body1: classes.smallParagraph }}
            variant="body1"
            gutterBottom
          >
            Bedankt!
          </Typography>
          <Typography className={classes.heading} variant="h2">
            Je registratie is gelukt
          </Typography>
          <Typography
            classes={{ body1: classes.paragraph }}
            variant="body1"
            gutterBottom
          >
            {config &&
              config.literals &&
              config.literals.APP_REGISTRATION_ACTIVATED_SUCCESS_MESSAGE}
          </Typography>
        </div>
        <div className={classes.buttonGroup}>
          <Link to={"/"}>
            <Button
              variant="contained"
              classes={{ root: classes.buttonRoot }}
              color="primary"
            >
              Inloggen
            </Button>
          </Link>
        </div>
      </form>
    </div>
  );
};

export default compose(
  withStyles(style, { name: "AppRegistrationSuccess" }),
  connect(({ config }) => ({
    config
  }))
)(AppRegistrationSuccess);
