export const styles = theme => ({
  wrapper: {
    ...theme.manakin.defaultWrapper,
    [theme.breakpoints.up('md')]: {
      padding: 0
    }
  },
  card: {
    backgroundColor: theme.manakin.primaryColor[500],
    color: theme.palette.getContrastText(theme.manakin.primaryColor[500]),
    minHeight: '90px',
    marginBottom: '1rem',
    marginTop: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
    opacity: 1
  },
  animatePrev: {
    transform: 'translateY(calc(100% + 1rem))',
    transition: 'transform .3s',
    '& $arrows': {
      opacity: '0'
    }
  },
  animateNext: {
    transform: 'translateY(calc(-100% - 1rem))',
    transition: 'transform .3s',
    '& $arrows': {
      opacity: '0'
    }
  },
  animatePrevGap: {
    transform: 'translateY(calc(200% + 1rem))',
    transition: 'transform .3s',
    '& $arrows': {
      opacity: '0'
    }
  },
  animateNextGap: {
    transform: 'translateY(calc(-200% - 1rem))',
    transition: 'transform .3s',
    '& $arrows': {
      opacity: '0'
    }
  },
  isCorrect: {
    backgroundColor: theme.manakin.correctAnswer[500]
  },
  dragRoot: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: '7rem',
      position: 'relative'
    }
  },
  text: {
    display: 'block',
    width: '75%',
    marginLeft: '15px'
  },
  number: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
      position: 'absolute',
      left: 0,
      top: '50%',
      transform: 'translateY(-50%)',
      lineHeight: '2.6rem',
      letterSpacing: '.4rem',
      fontWeight: 'bold',
      fontFamily: theme.manakin.defaultTitleFont
    }
  },
  isDragging: {
    opacity: 0
  },
  isFalseDragging: {
    opacity: 1
  },
  mobileHandlers: {
    display: 'block'
  },
  hide: {
    display: 'none'
  },
  arrows: {
    cursor: 'pointer',
    transition: 'opacity .1s',
    opacity: '1',
    paddingRight: '15px'
  },
  showIcon: {
    width: '3rem',
    height: '3rem',
    borderRadius: '100%',
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  iconRoot: {
    width: '1.3rem',
    height: '1.3rem'
  },
  feedback: {
    position: 'relative',
    zIndex: '2',
    opacity: '0',
    height: 0,
    width: '100%',
    overflow: 'hidden',
    pointerEvents: 'none',
    transition: 'opacity .3s'
  },
  activeFeedback: {
    opacity: '1',
    pointerEvents: 'auto',
    height: 'auto'
  },
  buttons: {
    display: 'inline-block',
    width: '100%',
    position: 'relative',
    marginTop: '2rem',
    [theme.breakpoints.up('md')]: {
      paddingLeft: '7rem'
    }
  },
  button: {
    '& span': {
      justifyContent: 'space-between',
      paddingLeft: '2rem',
      paddingRight: '2rem'
    }
  },
  longArrow: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block'
    }
  },
  correctIcon: {
    width: '40px',
    height: '40px',
    backgroundColor: 'white',
    padding: theme.spacing(1)
  }
});
