import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ChangePasswordForm from '../ChangePasswordForm';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { compose, withHandlers } from 'recompose';
import { showSnackbarMessage } from '@manakin/core/actions';
import { login } from '../actions';

const styles = theme => ({
  root: {
    paddingTop: '6rem',
    background: theme.manakin.primaryColor[500],
    width: '100vw',
    height: '100vh',
    minHeight: '100%'
  },
  link: {
    textDecoration: 'none',
    display: 'block',
    textAlign: 'center',
    color: theme.manakin.defaultContentColor,
    marginTop: theme.spacing(3),
    fontSize: '18px',
    '&:visited': {
      color: theme.manakin.defaultContentColor
    }
  },
  heading: {
    textAlign: 'center',
    width: '100%',
    marginBottom: '1em'
  },
  paragraph: {
    textAlign: 'center',
    width: '100%',
    margin: '0 auto 3em',
    maxWidth: '560px'
  },
  wrapper: {
    maxWidth: theme.manakin.wrapper['small'],
    position: 'relative',
    width: '100%',
    margin: '0 auto'
  },
  smallWrapper: {
    maxWidth: theme.manakin.wrapper['extraSmall'],
    width: '90%',
    margin: '0 auto',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  footNote: {
    marginTop: theme.spacing(4),
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column'
  }
});

class ConfirmReset extends React.Component {
  handleSubmit = event => {
    this.props.onChangePassword({
      ...event,
      token: this.props.match.params.token
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.smallWrapper}>
          <Typography classes={{ h3: classes.heading }} variant="h3">
            Wachtwoord wijzigen
          </Typography>
          <ChangePasswordForm
            submitLabel="Wachtwoord aanpassen"
            onSubmit={this.handleSubmit}
          />
        </div>
      </div>
    );
  }
}

export default compose(
  connect(
    ({ config }) => ({
      config
    }),
    dispatch => ({
      processSuccess: () => {
        dispatch(login());
        dispatch(
          showSnackbarMessage({
            text: 'Uw wachtwoord is aangepast.',
            variant: 'success'
          })
        );
      }
    })
  ),
  withHandlers({
    onChangePassword: ({ mutate, processSuccess, processError }) => event =>
      mutate({
        variables: {
          password: event.password,
          token: event.token
        }
      }).then(data => processSuccess())
  }),
  withRouter,
  withStyles(styles, { name: 'AuthenticationConfirmReset' })
)(ConfirmReset);
