import React from 'react';
import { connect } from 'react-redux';
import { setBreadcrumb } from './actions';

const mapDispatchToProps = (dispatch) => ({
    onMount: (Breadcrumbs, location) => dispatch(setBreadcrumb(location.pathname, Breadcrumbs))
});

const withCustomBreadcrumb = Breadcrumbs => WrappedComponent => {
    return connect(
        null,
        mapDispatchToProps
    )(
        class extends React.Component {
            componentDidMount() {
                const {onMount, location} = this.props;
                onMount(Breadcrumbs, location);
              if (this.someFunction) {
                  this.someFunction()
              }
            }

            render() {
                return (
                    <WrappedComponent {...this.props} />
                )
            }
        }
    )
}
 
export default withCustomBreadcrumb;
