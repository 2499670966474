import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import ResetPasswordForm from '../ResetPasswordForm';
import { withStyles } from '@material-ui/core/styles';
import { compose, withHandlers } from 'recompose';
import { showSnackbarMessage } from '@manakin/core/actions';
import 'url-search-params-polyfill';

const styles = theme => ({
  root: {
    paddingTop: '6rem',
    minHeight: '100%',
    width: '100vw',
    height: '100vh',
    backgroundColor: theme.manakin.primaryColor
      ? theme.manakin.primaryColor[500]
      : 'white'
  },
  heading: {
    textAlign: 'center'
  },
  paragraph: {
    textAlign: 'center',
    width: '100%',
    margin: '0 auto 3em',
    maxWidth: '560px'
  },
  wrapper: {
    maxWidth: theme.manakin.wrapper['small'],
    position: 'relative',
    width: '100%',
    margin: '0 auto'
  },
  smallWrapper: {
    maxWidth: theme.manakin.wrapper['extraSmall'],
    width: '90%',
    margin: '0 auto',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  footNote: {
    marginTop: theme.spacing(4),
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column'
  },
  link: {
    textDecoration: 'none',
    display: 'block',
    textAlign: 'center',
    color: theme.manakin.defaultContentColor
      ? theme.manakin.defaultContentColor[500]
      : 'black',
    marginTop: theme.spacing(3),
    fontSize: '18px',
    '&:visited': {
      color: theme.manakin.defaultContentColor
        ? theme.manakin.defaultContentColor[500]
        : 'black'
    }
  }
});

class ResetPassword extends React.Component {
  handleSubmit = event => {
    const params = new URLSearchParams(this.props.location.search);
    const redirect = params.get('redirect');
    this.props.onResetPassword({ ...event, redirect });
  };

  render() {
    const { classes, config = {} } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.smallWrapper}>
          <Typography className={classes.heading} variant="h2">
            {config.literals &&
              (config.literals.APP_FORGOT_PASSWORD_TITLE ||
                'Wachtwoord vergeten?')}
          </Typography>
          <Typography
            classes={{ body1: classes.paragraph }}
            variant="body1"
            gutterBottom
          >
            {config.literals &&
              (config.literals.APP_FORGOT_PASSWORD_MESSAGE || '')}
          </Typography>
          <ResetPasswordForm onSubmit={this.handleSubmit} />
          <Link className={classes.link} to={`login`}>
            Terug naar inloggen
          </Link>
        </div>
      </div>
    );
  }
}
export default compose(
  connect(
    ({ config }) => ({
      config
    }),
    dispatch => ({
      processSuccess: () =>
        dispatch(
          showSnackbarMessage({
            text:
              'Een e-mail is verstuurd waarmee u uw wachtwoord kunt aanpassen.',
            variant: 'success'
          })
        )
    })
  ),
  withHandlers({
    onResetPassword: ({ mutate, processSuccess }) => event =>
      mutate({
        variables: {
          email: event.email
        }
      }).then(data => {
        processSuccess();
      })
  }),
  withRouter,
  withStyles(styles, { name: 'AuthenticationResetPassword' })
)(ResetPassword);
