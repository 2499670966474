"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _AccountWrapper = require("../AccountWrapper");

var _AccountWrapper2 = _interopRequireDefault(_AccountWrapper);

var _core = require("@manakin/core");

var _moment = require("moment");

var _moment2 = _interopRequireDefault(_moment);

require("moment/min/locales");

var _Button = require("@material-ui/core/Button");

var _Button2 = _interopRequireDefault(_Button);

var _appCore = require("@manakin/app-core");

var _styles = require("@material-ui/core/styles");

var _Grid = require("@material-ui/core/Grid");

var _Grid2 = _interopRequireDefault(_Grid);

var _graphql = require("../graphql");

var _reactApollo = require("react-apollo");

var _recompose = require("recompose");

var _actions = require("@manakin/core/actions");

var _actions2 = require("@manakin/authentication/actions");

var _reactRedux = require("react-redux");

var _actions3 = require("@manakin/app-core/actions");

var _selectors = require("@manakin/authentication/selectors");

var _reactRouterDom = require("react-router-dom");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var styles = function styles(theme) {
  return {
    profileFields: {
      margin: "3rem 0 6rem 0"
    },
    productLabel: {
      textAlign: "left"
    },
    smallWrapper: _extends({}, theme.manakin.smallWrapper, {
      marginTop: "6rem",
      width: "60%"
    }),
    body: {
      color: theme.manakin.primaryColor[500]
    },
    center: {
      margin: "0 auto"
    }
  };
};

var formBundle = {
  code: {}
};

var Licenses = function (_React$PureComponent) {
  _inherits(Licenses, _React$PureComponent);

  function Licenses() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, Licenses);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = Licenses.__proto__ || Object.getPrototypeOf(Licenses)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
      hasInvalidEndDate: false
    }, _this.handleClaimLicense = function () {
      var _this$props = _this.props,
          form = _this$props.form,
          onClaim = _this$props.onClaim,
          processClaimError = _this$props.processClaimError,
          onDone = _this$props.onDone,
          client = _this$props.client,
          appUser = _this$props.appUser;


      form.onValidate().then(function () {
        return form.onSubmit().then(function (data) {
          if (data) onClaim(_extends({}, data)).then(function () {
            client.query({
              query: _graphql.GQL_FETCH_APP_USER_FOR_STORE,
              variables: {
                id: appUser && appUser.id
              }
            }).then(function (result) {
              if (result) {
                if (!result.errors) {
                  onDone(result.data.appUser);
                }
              }
            });
          });
        });
      }, function () {
        return processClaimError();
      });
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(Licenses, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      var _props = this.props,
          onSetAppBarColor = _props.onSetAppBarColor,
          location = _props.location;

      onSetAppBarColor({
        backgroundColor: "transparent",
        color: "secondary",
        path: location.pathname
      });
    }
  }, {
    key: "render",
    value: function render() {
      var _props2 = this.props,
          classes = _props2.classes,
          form = _props2.form,
          APP_USER = _props2.APP_USER,
          location = _props2.location,
          config = _props2.config;
      var _APP_USER$appUser = APP_USER.appUser,
          appUser = _APP_USER$appUser === undefined ? {} : _APP_USER$appUser;

      var loading = APP_USER ? APP_USER.loading : false;
      var fieldProps = { xs: 12, sm: 6, md: 6, lg: 6, xl: 6 };
      var wideFieldProps = { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 };
      var literals = config.literals || {};

      return _react2.default.createElement(
        "div",
        null,
        _react2.default.createElement(
          _AccountWrapper2.default,
          {
            title: "Licentie beheer",
            introduction: literals.APP_PROFILE_LICENSE_INTRO || ""
          },
          _react2.default.createElement(
            _appCore.ContentHeader,
            { title: "Gekochte licenties" },
            loading && _react2.default.createElement(_core.Loader, null),
            appUser && appUser.licenses && appUser.licenses.map(function (license) {
              return _react2.default.createElement(
                _Grid2.default,
                {
                  container: true,
                  spacing: 2,
                  alignItems: "center",
                  key: license.code,
                  classes: {
                    container: classes.profileFields
                  }
                },
                _react2.default.createElement(
                  _Grid2.default,
                  _extends({ item: true }, wideFieldProps),
                  _react2.default.createElement(
                    "h5",
                    { className: classes.productLabel },
                    license.licenseGroup.product.name
                  ),
                  _react2.default.createElement(_core.TextField, {
                    name: "code" + license.code,
                    label: "Licentienummer",
                    readOnly: true,
                    loading: loading,
                    initialValue: license.code || "",
                    form: form
                  })
                ),
                _react2.default.createElement(
                  _Grid2.default,
                  _extends({ item: true }, fieldProps),
                  _react2.default.createElement(_core.TextField, {
                    name: "Product" + license.code,
                    label: "Product",
                    readOnly: true,
                    loading: loading,
                    initialValue: license.licenseGroup.product.name || "",
                    form: form
                  })
                ),
                _react2.default.createElement(
                  _Grid2.default,
                  _extends({ item: true }, fieldProps),
                  _react2.default.createElement(_core.TextField, {
                    name: "validUntil" + license.code,
                    label: "Geldig tot",
                    readOnly: true,
                    loading: loading,
                    initialValue: license.validUntil ? (0, _moment2.default)(license.validUntil).locale("nl").format("D MMMM YYYY") || "" : "Onbeperkt",
                    form: form
                  })
                )
              );
            })
          ),
          _react2.default.createElement(
            _appCore.ContentHeader,
            { title: "Licentie toevoegen" },
            loading && _react2.default.createElement(_core.Loader, null),
            _react2.default.createElement(
              _Grid2.default,
              { container: true, spacing: 2, alignItems: "center" },
              _react2.default.createElement(
                _Grid2.default,
                _extends({ item: true }, fieldProps),
                _react2.default.createElement(_core.TextField, {
                  name: "code",
                  label: "Licentie code",
                  placeholder: "Voer licentie code in",
                  loading: loading,
                  initialValue: "",
                  form: form
                })
              ),
              _react2.default.createElement(
                _Grid2.default,
                _extends({ item: true }, fieldProps),
                _react2.default.createElement(
                  _Button2.default,
                  {
                    className: classes.button,
                    variant: "contained",
                    color: "primary",
                    fullWidth: true,
                    onClick: this.handleClaimLicense
                  },
                  "Toevoegen"
                )
              )
            )
          )
        )
      );
    }
  }]);

  return Licenses;
}(_react2.default.PureComponent);

exports.default = (0, _recompose.compose)((0, _core.withForm)(formBundle), _reactApollo.withApollo, (0, _styles.withStyles)(styles), (0, _reactApollo.graphql)(_graphql.GQL_CLAIM_LICENSE), (0, _reactRedux.connect)(function (state) {
  return {
    config: state.config,
    appUser: (0, _selectors.getAppUser)(state)
  };
}, function (dispatch) {
  return {
    processSuccess: function processSuccess() {
      dispatch((0, _actions.showSnackbarMessage)({
        text: "Licentie toegevoegd",
        variant: "success"
      }));
    },
    processFailure: function processFailure() {
      dispatch((0, _actions.showSnackbarMessage)({
        text: "Licentie toevoegen mislukt",
        variant: "error"
      }));
    },
    processClaimError: function processClaimError() {
      return dispatch((0, _actions.showSnackbarMessage)({
        text: "Vul a.u.b. een licentie code in",
        variant: "error"
      }));
    },
    onSetAppBarColor: function onSetAppBarColor(color) {
      return dispatch((0, _actions3.setAppBarBackgroundColor)(color));
    },
    onSetAppBarDropDown: function onSetAppBarDropDown(dropDown) {
      return dispatch((0, _actions3.setAppBarDropDown)(dropDown));
    },
    onDone: function onDone(user) {
      return dispatch((0, _actions2.login)("/", user));
    }
  };
}), (0, _reactApollo.graphql)(_graphql.GQL_FETCH_CURRENT_APP_USER, {
  name: "APP_USER",
  options: function options(props) {
    return {
      variables: {
        id: props.appUser && props.appUser.id
      }
    };
  }
}), (0, _recompose.withHandlers)({
  onClaim: function onClaim(_ref2) {
    var mutate = _ref2.mutate,
        processSuccess = _ref2.processSuccess,
        processFailure = _ref2.processFailure;
    return function (event) {
      return mutate({
        variables: _extends({}, event)
      }).then(function (result) {
        if (!result.errors) {
          processSuccess();
        } else {
          processFailure();
        }
      });
    };
  }
}), _reactRouterDom.withRouter)(Licenses);