import React from 'react';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ArrowIcon from '@material-ui/icons/ArrowRightAlt';
import ClockIcon from '@material-ui/icons/AccessTime';
import { LoadImage } from '@manakin/app-core';
import scrollToComponent from 'react-scroll-to-component';

const styles = theme => ({
  root: {
    width: '100%',
    minHeight: '430px',
    backgroundColor: theme.manakin.primaryColor,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    position: 'relative',
    padding: '100px 0',
    opacity: 0,
    animation: 'fadeIn .3s ease 1s forwards',
    [theme.breakpoints.up('md')]: {
      minHeight: '80vh'
    },
    height: 0
  },
  content: {
    textAlign: 'center',
    opacity: 0,
    animation: 'show .3s ease 1.3s forwards'
  },
  fontStyle: {
    color: 'white'
  },
  heading: {
    color: 'white',
    marginBottom: '3rem',
    hyphens: 'auto',
    wordBreak: 'break-word',
    [theme.breakpoints.down('xs')]: {
      fontSize: '3rem',
      lineHeight: '4rem',
      letterSpacing: '.4rem'
    }
  },
  wrapper: {
    ...theme.manakin.defaultWrapper
  },
  icon: {
    position: 'absolute',
    color: 'white',
    transformOrigin: 'center',
    transform: 'translate(-50%, 0) rotate(90deg)',
    bottom: '4.5rem',
    left: '50%',
    display: 'none',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    [theme.breakpoints.up('md')]: {
      transition: 'transform .3s',
      display: 'flex'
    },
    '&:hover': {
      [theme.breakpoints.up('md')]: {
        transform: 'translate(-50%, 10px) rotate(90deg)'
      }
    }
  },
  iconRoot: {
    width: '3rem',
    height: '3rem'
  },
  timing: {
    display: 'flex',
    fontSize: '1.8rem',
    color: 'white',
    alignItems: 'center',
    justifyContent: 'center'
  },
  clockIcon: {
    fontFamily: theme.manakin.defaultContentFont,
    width: '2rem',
    height: '2rem',
    marginRight: '2rem'
  }
});

class Header extends React.PureComponent {
  handleClick = () => {
    scrollToComponent(this.Header, { offset: 70, align: 'top', duration: 500 });
  };

  render() {
    const { classes, data, prefix } = this.props;
    const image = data.image ? data.image.url : '';
    const headerImage = data.headerImage ? data.headerImage.url : '';
    const imagePlaceholder = data.image ? data.image.thumbnailUrl : '';
    const placeholder = data.headerImage ? data.headerImage.thumbnailUrl : '';
    const hasTime = data.time ? data.time > 0 : false;

    return (
      <div className={classes.root} color="light">
        <LoadImage
          src={headerImage || image}
          placeholder={placeholder || imagePlaceholder}
          withOverlay={true}
        />
        <div className={classes.wrapper}>
          <div className={classes.content}>
            <Typography
              classes={{ subtitle1: classes.fontStyle }}
              component="p"
              variant="subtitle1"
            >
              {prefix || ''}
            </Typography>
            <Typography
              classes={{ h1: classes.heading }}
              component="h1"
              variant="h1"
            >
              {data.introTitle || ''}
            </Typography>
            {hasTime && (
              <div className={classes.timing}>
                <ClockIcon classes={{ root: classes.clockIcon }} />
                {data.time} min
              </div>
            )}
          </div>
        </div>
        <div
          className={classes.icon}
          onClick={this.handleClick}
          ref={section => {
            this.Header = section;
          }}
        >
          <ArrowIcon classes={{ root: classes.iconRoot }} />
        </div>
      </div>
    );
  }
}

export default compose(withStyles(styles, { name: 'AppLessonHeader' }))(Header);
