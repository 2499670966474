import gql from 'graphql-tag';

export const GQL_FETCH_SLIDESHOW = gql`
    query($id: ID!) {
        slideshow(id: $id) {
            id
            title
            slides {
                id
                title
                text
                asset {
                    id
                    bynderId
                    derivative
                    url
                }
            }
        }
    }
`;