import React from "react";
import { validateEntity, Failure } from "@manakin/validation";
import { connect } from 'react-redux';
import { compose } from 'recompose';

const withForm = schema => WrappedComponent => {
  return compose(
    connect(
      state => ({ config: state.config })
    ),
  )(
    class WithForm extends React.Component {
      state = {
        schema,
        fields: Object.keys(schema).reduce((fields, key) => {
          fields[key] = null;
          return fields;
        }, {}),
        errors: Object.keys(schema).reduce((errors, key) => {
          errors[key] = [];
          return errors;
        }, {})
      };

      addField = event => {
        const newFields = Object.keys(event).reduce((fields, key) => {
          fields[key] = null;
          return fields;
        }, {});
        const newErrorFields = Object.keys(event).reduce((errors, key) => {
          errors[key] = [];
          return errors;
        }, {});

        this.setState(prevState => ({
          schema: { ...prevState.schema, ...event },
          fields: { ...prevState.fields, ...newFields },
          errors: { ...prevState.errors, ...newErrorFields }
        }));
      };

      removeField = event => {
        let newState = { ...this.state };
        event.map(item => {
          delete newState.fields[item];
          delete newState.schema[item];
          delete newState.errors[item];
        });

        this.setState(newState);
      };

      handleValidate = fieldName => {
        return new Promise((resolve, reject) => {
          this.setState(
            prevState => {
              const fieldsToValidate =
                fieldName !== undefined
                  ? [fieldName]
                  : Object.keys(prevState.fields);

              const validationResults = validateEntity(
                Object.keys(prevState.schema).reduce((s, key) => {
                  if (fieldsToValidate.includes(key)) {
                    s[key] = prevState.schema[key];
                  }
                  return s;
                }, {}),
                prevState.fields
              );

              if (Failure.hasInstance(validationResults)) {
                return {
                  errors: validationResults.value.reduce(
                    (result, { propertyName, errors }) => {
                      const currentResult = result[propertyName] || [];
                      result[propertyName] = currentResult.concat(
                        errors.map(e =>
                          this.createErrorMessage(
                            prevState.schema[propertyName],
                            e
                          )
                        )
                      );

                      return result;
                    },
                    {}
                  )
                };
              }

              return {
                errors: {}
              };
            },
            () => {
              if (
                Object.keys(this.state.errors).reduce(
                  (hasErrors, key) =>
                    hasErrors || this.state.errors[key].length > 0,
                  false
                )
              ) {
                reject(this.state.errors);
              } else {
                resolve(true);
              }
            }
          );
        });
      };

      handleSubmit = event => {
        return this.handleValidate().then(
          () => {
            if (this.props.onSubmit) {
              this.props.onSubmit(this.state.fields);
            }
            return this.state.fields;
          },
          () => {
            return false;
          }
        );
      };

      handleFieldChange = event => {
        this.setState(prevState => ({
          fields: { ...prevState.fields, [event.key]: event.value },
          errors: { ...prevState.errors, [event.key]: [] }
        }));
      };

      handleRequiredChange = (key, required) => {
        this.setState(prevState => ({
          schema: {
            ...prevState.schema,
            [key]: {
              ...prevState.schema[key],
              required: required
            }
          }
        }));
      };

      createErrorMessage = (schemaProps, key) => {
        const { config = {} } = this.props;
        const { literals = {} } = config

        if (key === "required") {
          return literals.APP_REQUIRED || "Dit veld is verplicht";
        }

        if (key === "maxLength") {
          return (literals.APP_MAX_LENGTH || "Maximum lengte van ") + schemaProps[key];
        }

        if (key === "minLength") {
          return (literals.APP_MIN_LENGTH || "Minimum lengte van ") + schemaProps[key];
        }

        if (key === "format" && schemaProps[key] === "email") {
          return literals.APP_INVALID_EMAIL || "Ongeldig E-mailadres";
        }

        if (key === "format" && schemaProps[key] === "password") {
          return literals.APP_PASSWORD_REQUIREMENTS || "Het wachtwoord moet bestaan uit minimaal 8 tekens, waarvan minimaal 1 letter, 1 cijfer, 1 hoofdletter en 1 speciaal teken";
        }

        if (key === "format" && schemaProps[key] === "phoneNumber") {
          return literals.APP_INVALID_PHONE || "Ongeldig telefoonnummer";
        }

        if (key === "format" && schemaProps[key] === "date") {
          return literals.APP_INVALID_DATE || "Ongeldige datum";
        }

        if (key === "couple") {
          return (literals.APP_INPUT_NO_MATCH || "Komt niet overeen met ") + schemaProps.coupleLabel;
        }

        if (
          key === "type" &&
          schemaProps[key] === "number" &&
          schemaProps.required
        ) {
          return literals.APP_NO_NUMBER || "Geen nummer ingevuld";
        }

        if (key === "type" && schemaProps[key] === "integer") {
          return literals.APP_NO_INTEGER || "Vul een geldig getal in tussen 0 en 2.147.483.647";
        }

        return literals.APP_UNKOWN_VALIDATION_ERROR || "Onbekende validatie fout";
      };

      render() {
        const { ...otherProps } = this.props;

        return (
          <WrappedComponent
            {...otherProps}
            form={{
              ...this.state,
              onFieldChange: this.handleFieldChange,
              onValidate: this.handleValidate,
              onSubmit: this.handleSubmit,
              onAddField: this.addField,
              onRemoveField: this.removeField,
              onRequiredChange: this.handleRequiredChange
            }}
          />
        );
      }
    }
  )
};

export default withForm;
