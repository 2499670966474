import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { LoadImage } from '@manakin/app-core';
import Typography from '@material-ui/core/Typography';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import { Link, withRouter } from 'react-router-dom';

const styles = theme => ({
  root: {
    backgroundColor: 'white',
    position: 'fixed',
    bottom: 0,
    right: 0,
    zIndex: '99',
    width: '30rem',
    transform: 'translateX(50%)',
    opacity: 0,
    transition: 'transform .3s 0s, opacity .3s 0s',
    pointerEvents: 'none',
    textDecoration: 'none',
    [theme.breakpoints.up('md')]: {
      width: '40rem',
      right: '5rem',
      bottom: '11rem'
    }
  },
  animate: {
    opacity: 1,
    transform: 'translateX(0)',
    pointerEvents: 'visible'
  },
  imageContainer: {
    height: '13rem',
    width: '100%',
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      height: '15rem'
    }
  },
  content: {
    padding: '2rem',
    [theme.breakpoints.up('md')]: {
      padding: '4rem 5rem'
    }
  },
  preTitle: {
    fontWeight: '400',
    marginBottom: '1rem'
  },
  text: {
    marginBottom: 0
  },
  closeButton: {
    borderRadius: 0,
    boxShadow: 'none',
    height: '6rem',
    width: 'rem',
    position: 'absolute',
    right: 0,
    padding: 0,
    top: 0,
    '& svg': {
      transition: 'transform .3s',
      transform: 'rotate(0deg)'
    },
    '&:hover': {
      '& svg': {
        [theme.breakpoints.up('md')]: {
          transform: 'rotate(90deg)'
        }
      }
    }
  }
});

const FactCardReferral = props => {
  const { classes, factCard = {}, config = {}, idx } = props;
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    setAnimate(true);
  }, []);

  const handleClick = e => {
    e.preventDefault();
    setAnimate(false);
  };

  return (
    <Link
      to={{
        pathname: `/factcard/${factCard.referrals[0].factCardId}`,
        state: { url: props.location.pathname, idx: idx }
      }}
      className={classNames(classes.root, { [classes.animate]: animate })}
    >
      <Button
        variant="contained"
        color="primary"
        aria-label="Close"
        className={classes.closeButton}
        onClick={handleClick}
      >
        <CloseIcon />
      </Button>
      {factCard.backgroundImage && factCard.backgroundImage.url && (
        <div className={classes.imageContainer}>
          <LoadImage src={factCard.backgroundImage.url} />
        </div>
      )}
      <div className={classes.content}>
        <Typography variant="body2" className={classes.preTitle}>
          {config.literals && config.literals.APP_GENERAL_FACTCARD}
        </Typography>
        <Typography variant="h6" className={classes.text}>
          {factCard.title || ''}
        </Typography>
      </div>
    </Link>
  );
};

export default compose(
  withStyles(styles),
  connect(({ config }) => ({
    config
  })),
  withRouter
)(FactCardReferral);
