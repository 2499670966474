import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import createUuid from "uuid/v4";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  root: {
    flex: "1 0 auto",
    transition: "background-color 200ms linear",
    backgroundColor: "transparent",
    "& >*": { opacity: 1, pointerEvents: "auto" }
  },
  loading: {
    height: theme.spacing(6),
    backgroundColor: theme.palette.grey[100],
    "& >*": { opacity: 0, pointerEvents: "none" }
  }
});

class CheckboxField extends React.Component {
  state = {
    uuid: createUuid()
  };

  handleFieldChange = event => {
    const { form, name } = this.props;
    form.onFieldChange({ key: name, value: event.target.checked });
    if (this.props.onChange) this.props.onChange(event);
  };

  componentDidMount() {
    const { form, name, initialValue } = this.props;
    form.onFieldChange({ key: name, value: initialValue });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loading && !this.props.loading) {
      const { form, name, initialValue } = this.props;
      form.onFieldChange({ key: name, value: initialValue });
    }
  }

  render() {
    const {
      classes,
      name,
      label,
      color = "default",
      fullWidth = true,
      form,
      className: classNameProp,
      loading = false
    } = this.props;
    const { uuid } = this.state;
    const { errors, schema, fields } = form;

    const className = classNames(
      classes.root,
      { [classes.loading]: loading },
      classNameProp
    );

    return (
      <FormControl fullWidth={fullWidth} margin="normal" className={className}>
        <FormControlLabel
          control={
            <Checkbox
              name={name}
              id={uuid}
              checked={fields[name] ? fields[name] : false}
              onChange={this.handleFieldChange}
              color={color}
            />
          }
          label={label}
        />
      </FormControl>
    );
  }
}

export default withStyles(styles)(CheckboxField);
