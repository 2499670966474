import React from 'react';
import {
	withWorkforms,
	ElementOverlay
} from '@manakin/app-core';
import { compose } from "recompose";
import Trainer from './Trainer';
const queryString = require('query-string');

class TrainerPreview extends React.Component {
	state = {
		step : 1
	};

	componentDidMount() {
		const {match, workformsData} = this.props;
		const query = match.params.query;
		const data = queryString.parse(query);
		const obj = {
			...data,
			headerImage: {
				url: data.headerImage
			},
			image: {
				url: data.image
			},
			workforms: [
				...data.items.map((item, idx) => ({
					id: item,
					type: data.itemTypes[idx]
				}))
			]
		}

		workformsData.loadWorkforms({...obj})
	}

	handleFinish = () => {
		this.setState(prevState => ({
			step: 3
		}))
	}

	handleClick = () => {
		this.setState(prevState => ({
			step: 2
		}))
	}

	render() {
		const {workformsData} = this.props;
		const {rawData = {} } = workformsData;

		return (
			<Trainer {...workformsData} {...this.state} preview={true} controls={false} onExit={this.handleFinish} onClick={this.handleClick} />
		)
	}
}

export default compose(
	withWorkforms()
)(TrainerPreview);