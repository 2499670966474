'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GQL_FETCH_ELEMENT_REFERRAL = exports.GQL_FETCH_HOTSPOTMAP = exports.GQL_FETCH_SCENE = exports.GQL_FETCH_HOTSPOT = exports.GQL_FETCH_IMAGE_AND_TEXT_MPC = exports.GQL_FETCH_CHAT_BUBBLE = exports.GQL_FETCH_SORT_QUESTION = exports.GQL_FETCH_CMS_WORK_FORM_FILE_DOWNLOAD = exports.GQL_FETCH_TITLE_AND_TEXT_IMAGE = exports.GQL_FETCH_TITLE_AND_TEXT = exports.GQL_FETCH_WHAT_IS_WHAT_QUESTION = exports.GQL_FETCH_IMAGE_WITH_SUBTITLE = exports.GQL_FETCH_CHOICE_QUESTION = exports.GQL_FETCH_STATEMENT_QUESTION = exports.GQL_FETCH_TEXT_IMAGE_CHECK_QUESTION = exports.GQL_FETCH_IMAGE_CHECK_QUESTION = exports.GQL_FETCH_TEXT_CHECK_QUESTION = exports.GQL_FETCH_MOODBOARD = exports.GQL_FETCH_ORDER_QUESTION = exports.GQL_FETCH_FACTCARD_REFERRAL = exports.GQL_FETCH_IMAGE_MPC = exports.GQL_FETCH_VIDEO_WITH_TITLE = exports.GQL_FETCH_SHORT_TEXT = exports.GQL_FETCH_TEXT_MPC = exports.GQL_FETCH_WORKFORM_RESULT = exports.GQL_CREATE_WORKFORM_RESULT = exports.GQL_FETCH_ELEMENT_RESULTS = exports.GQL_CREATE_ELEMENT_RESULT = exports.GQL_DELETE_WORKFORM_RESULT = exports.GQL_FETCH_GLOBAL_SETTINGS = undefined;

var _templateObject = _taggedTemplateLiteral(['\n  query {\n    settings {\n      settings {\n        name\n        value\n        file {\n          id\n          bynderId\n          derivative\n          url\n          thumbnailUrl\n          name\n        }\n      }\n    }\n  }\n'], ['\n  query {\n    settings {\n      settings {\n        name\n        value\n        file {\n          id\n          bynderId\n          derivative\n          url\n          thumbnailUrl\n          name\n        }\n      }\n    }\n  }\n']),
    _templateObject2 = _taggedTemplateLiteral(['\n  mutation($user: ID!, $program: ID!, $box: ID!, $element: ID!) {\n    deleteWorkformResults(\n      input: { user: $user, program: $program, box: $box, element: $element }\n    ) {\n      result\n    }\n  }\n'], ['\n  mutation($user: ID!, $program: ID!, $box: ID!, $element: ID!) {\n    deleteWorkformResults(\n      input: { user: $user, program: $program, box: $box, element: $element }\n    ) {\n      result\n    }\n  }\n']),
    _templateObject3 = _taggedTemplateLiteral(['\n  mutation(\n    $user: ID!\n    $program: ID!\n    $box: ID!\n    $element: ID!\n    $finished: Boolean\n    $bestTime: Int\n    $correct: Boolean\n  ) {\n    createElementResult(\n      input: {\n        user: $user\n        program: $program\n        box: $box\n        element: $element\n        finished: $finished\n        bestTime: $bestTime\n        correct: $correct\n      }\n    ) {\n      result {\n        finished\n      }\n    }\n  }\n'], ['\n  mutation(\n    $user: ID!\n    $program: ID!\n    $box: ID!\n    $element: ID!\n    $finished: Boolean\n    $bestTime: Int\n    $correct: Boolean\n  ) {\n    createElementResult(\n      input: {\n        user: $user\n        program: $program\n        box: $box\n        element: $element\n        finished: $finished\n        bestTime: $bestTime\n        correct: $correct\n      }\n    ) {\n      result {\n        finished\n      }\n    }\n  }\n']),
    _templateObject4 = _taggedTemplateLiteral(['\n  query($box: ID!, $program: ID!, $user: ID!, $element: ID!) {\n    elementResult(\n      box: $box\n      program: $program\n      user: $user\n      element: $element\n    ) {\n      finished\n      correct\n    }\n  }\n'], ['\n  query($box: ID!, $program: ID!, $user: ID!, $element: ID!) {\n    elementResult(\n      box: $box\n      program: $program\n      user: $user\n      element: $element\n    ) {\n      finished\n      correct\n    }\n  }\n']),
    _templateObject5 = _taggedTemplateLiteral(['\n  mutation(\n    $user: ID!\n    $program: ID!\n    $box: ID!\n    $element: ID!\n    $workform: ID!\n    $answers: [WorkformResultAnswerInput]\n    $finished: Boolean\n    $statement: Boolean\n  ) {\n    createWorkformResult(\n      input: {\n        user: $user\n        program: $program\n        box: $box\n        element: $element\n        workform: $workform\n        answers: $answers\n        finished: $finished\n        statement: $statement\n      }\n    ) {\n      result {\n        finished\n        statement\n        answers {\n          id\n          selected\n        }\n      }\n    }\n  }\n'], ['\n  mutation(\n    $user: ID!\n    $program: ID!\n    $box: ID!\n    $element: ID!\n    $workform: ID!\n    $answers: [WorkformResultAnswerInput]\n    $finished: Boolean\n    $statement: Boolean\n  ) {\n    createWorkformResult(\n      input: {\n        user: $user\n        program: $program\n        box: $box\n        element: $element\n        workform: $workform\n        answers: $answers\n        finished: $finished\n        statement: $statement\n      }\n    ) {\n      result {\n        finished\n        statement\n        answers {\n          id\n          selected\n        }\n      }\n    }\n  }\n']),
    _templateObject6 = _taggedTemplateLiteral(['\n  query($user: ID!, $program: ID!, $box: ID!, $element: ID!, $workform: ID!) {\n    workformResult(\n      user: $user\n      program: $program\n      box: $box\n      element: $element\n      workform: $workform\n    ) {\n      finished\n      statement\n      answers {\n        id\n        selected\n      }\n    }\n  }\n'], ['\n  query($user: ID!, $program: ID!, $box: ID!, $element: ID!, $workform: ID!) {\n    workformResult(\n      user: $user\n      program: $program\n      box: $box\n      element: $element\n      workform: $workform\n    ) {\n      finished\n      statement\n      answers {\n        id\n        selected\n      }\n    }\n  }\n']),
    _templateObject7 = _taggedTemplateLiteral(['\n  query($id: ID!) {\n    textMPC(id: $id) {\n      id\n      type\n      introduction\n      question\n      buttonText\n      feedbackCorrect\n      feedbackInCorrect\n      hintText\n      shuffleAnswers\n      answers {\n        id\n        correct\n        title\n        text\n      }\n      split\n    }\n  }\n'], ['\n  query($id: ID!) {\n    textMPC(id: $id) {\n      id\n      type\n      introduction\n      question\n      buttonText\n      feedbackCorrect\n      feedbackInCorrect\n      hintText\n      shuffleAnswers\n      answers {\n        id\n        correct\n        title\n        text\n      }\n      split\n    }\n  }\n']),
    _templateObject8 = _taggedTemplateLiteral(['\n  query($id: ID!) {\n    shortText(id: $id) {\n      type\n      id\n      text\n    }\n  }\n'], ['\n  query($id: ID!) {\n    shortText(id: $id) {\n      type\n      id\n      text\n    }\n  }\n']),
    _templateObject9 = _taggedTemplateLiteral(['\n  query($id: ID!) {\n    videoWithTitle(id: $id) {\n      id\n      type\n      workTitle\n      introduction\n      video\n      posterImage {\n        id\n        url\n      }\n    }\n  }\n'], ['\n  query($id: ID!) {\n    videoWithTitle(id: $id) {\n      id\n      type\n      workTitle\n      introduction\n      video\n      posterImage {\n        id\n        url\n      }\n    }\n  }\n']),
    _templateObject10 = _taggedTemplateLiteral(['\n  query($id: ID!) {\n    imageMPC(id: $id) {\n      id\n      type\n      introduction\n      question\n      buttonText\n      feedbackCorrect\n      feedbackInCorrect\n      hintText\n      shuffleAnswers\n      answers {\n        id\n        correct\n        image {\n          id\n          url\n          thumbnailUrl\n        }\n      }\n    }\n  }\n'], ['\n  query($id: ID!) {\n    imageMPC(id: $id) {\n      id\n      type\n      introduction\n      question\n      buttonText\n      feedbackCorrect\n      feedbackInCorrect\n      hintText\n      shuffleAnswers\n      answers {\n        id\n        correct\n        image {\n          id\n          url\n          thumbnailUrl\n        }\n      }\n    }\n  }\n']),
    _templateObject11 = _taggedTemplateLiteral(['\n  query($id: ID!) {\n    factCardReferral(id: $id) {\n      id\n      type\n      title\n      backgroundImage {\n        id\n        url\n      }\n      referrals {\n        id\n        title\n        text\n        actionTitle\n        factCardId\n        factCard\n        image {\n          id\n          url\n        }\n      }\n    }\n  }\n'], ['\n  query($id: ID!) {\n    factCardReferral(id: $id) {\n      id\n      type\n      title\n      backgroundImage {\n        id\n        url\n      }\n      referrals {\n        id\n        title\n        text\n        actionTitle\n        factCardId\n        factCard\n        image {\n          id\n          url\n        }\n      }\n    }\n  }\n']),
    _templateObject12 = _taggedTemplateLiteral(['\n  query($id: ID!) {\n    orderQuestion(id: $id) {\n      id\n      introduction\n      type\n      question\n      feedbackCorrect\n      feedbackInCorrect\n      buttonText\n      hintText\n      answers {\n        id\n        title\n        text\n      }\n    }\n  }\n'], ['\n  query($id: ID!) {\n    orderQuestion(id: $id) {\n      id\n      introduction\n      type\n      question\n      feedbackCorrect\n      feedbackInCorrect\n      buttonText\n      hintText\n      answers {\n        id\n        title\n        text\n      }\n    }\n  }\n']),
    _templateObject13 = _taggedTemplateLiteral(['\n  query($id: ID!) {\n    moodboard(id: $id) {\n      id\n      type\n      boards {\n        id\n        title\n        text\n        image {\n          id\n          url\n        }\n      }\n    }\n  }\n'], ['\n  query($id: ID!) {\n    moodboard(id: $id) {\n      id\n      type\n      boards {\n        id\n        title\n        text\n        image {\n          id\n          url\n        }\n      }\n    }\n  }\n']),
    _templateObject14 = _taggedTemplateLiteral(['\n  query($id: ID!) {\n    textCheckQuestion(id: $id) {\n      id\n      type\n      title\n      introduction\n      hintText\n      question\n      buttonText\n      feedbackCorrect\n      feedbackInCorrect\n      hintText\n      shuffleAnswers\n      answers {\n        id\n        title\n        correct\n        text\n      }\n    }\n  }\n'], ['\n  query($id: ID!) {\n    textCheckQuestion(id: $id) {\n      id\n      type\n      title\n      introduction\n      hintText\n      question\n      buttonText\n      feedbackCorrect\n      feedbackInCorrect\n      hintText\n      shuffleAnswers\n      answers {\n        id\n        title\n        correct\n        text\n      }\n    }\n  }\n']),
    _templateObject15 = _taggedTemplateLiteral(['\n  query($id: ID!) {\n    imageCheckQuestion(id: $id) {\n      id\n      type\n      introduction\n      question\n      buttonText\n      feedbackCorrect\n      feedbackInCorrect\n      hintText\n      shuffleAnswers\n      answers {\n        id\n        correct\n        image {\n          id\n          url\n        }\n      }\n    }\n  }\n'], ['\n  query($id: ID!) {\n    imageCheckQuestion(id: $id) {\n      id\n      type\n      introduction\n      question\n      buttonText\n      feedbackCorrect\n      feedbackInCorrect\n      hintText\n      shuffleAnswers\n      answers {\n        id\n        correct\n        image {\n          id\n          url\n        }\n      }\n    }\n  }\n']),
    _templateObject16 = _taggedTemplateLiteral(['\n  query($id: ID!) {\n    imageAndTextCheckQuestion(id: $id) {\n      id\n      type\n      introduction\n      question\n      buttonText\n      feedbackCorrect\n      feedbackInCorrect\n      hintText\n      shuffleAnswers\n      answers {\n        id\n        correct\n        image {\n          id\n          url\n        }\n        title\n      }\n    }\n  }\n'], ['\n  query($id: ID!) {\n    imageAndTextCheckQuestion(id: $id) {\n      id\n      type\n      introduction\n      question\n      buttonText\n      feedbackCorrect\n      feedbackInCorrect\n      hintText\n      shuffleAnswers\n      answers {\n        id\n        correct\n        image {\n          id\n          url\n        }\n        title\n      }\n    }\n  }\n']),
    _templateObject17 = _taggedTemplateLiteral(['\n  query($id: ID!) {\n    statementQuestion(id: $id) {\n      id\n      type\n      title\n      statement\n      introduction\n      answer\n      answerOne\n      answerTwo\n      feedbackCorrect\n      feedbackInCorrect\n      backgroundColor\n      image {\n        id\n        url\n        thumbnailUrl\n      }\n    }\n  }\n'], ['\n  query($id: ID!) {\n    statementQuestion(id: $id) {\n      id\n      type\n      title\n      statement\n      introduction\n      answer\n      answerOne\n      answerTwo\n      feedbackCorrect\n      feedbackInCorrect\n      backgroundColor\n      image {\n        id\n        url\n        thumbnailUrl\n      }\n    }\n  }\n']),
    _templateObject18 = _taggedTemplateLiteral(['\n  query($id: ID!) {\n    choiceQuestion(id: $id) {\n      id\n      type\n      title\n      statement\n      introduction\n      answer\n      answerOne\n      answerTwo\n      feedbackCorrect\n      feedbackInCorrect\n      backgroundColor\n    }\n  }\n'], ['\n  query($id: ID!) {\n    choiceQuestion(id: $id) {\n      id\n      type\n      title\n      statement\n      introduction\n      answer\n      answerOne\n      answerTwo\n      feedbackCorrect\n      feedbackInCorrect\n      backgroundColor\n    }\n  }\n']),
    _templateObject19 = _taggedTemplateLiteral(['\n  query($id: ID!) {\n    imageWithSubtitle(id: $id) {\n      id\n      subtitle\n      type\n      image {\n        id\n        url\n        thumbnailUrl\n      }\n      zoom\n    }\n  }\n'], ['\n  query($id: ID!) {\n    imageWithSubtitle(id: $id) {\n      id\n      subtitle\n      type\n      image {\n        id\n        url\n        thumbnailUrl\n      }\n      zoom\n    }\n  }\n']),
    _templateObject20 = _taggedTemplateLiteral(['\n  query($id: ID!) {\n    whatIsWhatQuestion(id: $id) {\n      id\n      type\n      introduction\n      hintText\n      question\n      feedbackCorrect\n      feedbackInCorrect\n      backgroundColor\n      buttonText\n      variant\n      items {\n        id\n        firstText\n        secondText\n        firstImage {\n          id\n          url\n        }\n        secondImage {\n          id\n          url\n        }\n      }\n    }\n  }\n'], ['\n  query($id: ID!) {\n    whatIsWhatQuestion(id: $id) {\n      id\n      type\n      introduction\n      hintText\n      question\n      feedbackCorrect\n      feedbackInCorrect\n      backgroundColor\n      buttonText\n      variant\n      items {\n        id\n        firstText\n        secondText\n        firstImage {\n          id\n          url\n        }\n        secondImage {\n          id\n          url\n        }\n      }\n    }\n  }\n']),
    _templateObject21 = _taggedTemplateLiteral(['\n  query($id: ID!) {\n    titleAndText(id: $id) {\n      id\n      type\n      backgroundColor\n      workTitle\n      text\n    }\n  }\n'], ['\n  query($id: ID!) {\n    titleAndText(id: $id) {\n      id\n      type\n      backgroundColor\n      workTitle\n      text\n    }\n  }\n']),
    _templateObject22 = _taggedTemplateLiteral(['\n  query($id: ID!) {\n    titleAndTextImage(id: $id) {\n      id\n      type\n      backgroundColor\n      workTitle\n      text\n      image {\n        id\n        url\n      }\n      imagePosition\n    }\n  }\n'], ['\n  query($id: ID!) {\n    titleAndTextImage(id: $id) {\n      id\n      type\n      backgroundColor\n      workTitle\n      text\n      image {\n        id\n        url\n      }\n      imagePosition\n    }\n  }\n']),
    _templateObject23 = _taggedTemplateLiteral(['\n  query($id: ID!) {\n    fileDownload(id: $id) {\n      id\n      title\n      text\n      type\n      workTitle\n      introduction\n      file {\n        id\n        url\n        thumbnailUrl\n        name\n        contentType\n      }\n      openAction\n    }\n  }\n'], ['\n  query($id: ID!) {\n    fileDownload(id: $id) {\n      id\n      title\n      text\n      type\n      workTitle\n      introduction\n      file {\n        id\n        url\n        thumbnailUrl\n        name\n        contentType\n      }\n      openAction\n    }\n  }\n']),
    _templateObject24 = _taggedTemplateLiteral(['\n  query($id: ID!) {\n    sortQuestion(id: $id) {\n      id\n      type\n      introduction\n      question\n      questions {\n        id\n        image {\n          id\n          url\n        }\n        text\n      }\n      contraIndicationTitle\n      indicationTitle\n      indications\n      contraIndications\n      hintText\n    }\n  }\n'], ['\n  query($id: ID!) {\n    sortQuestion(id: $id) {\n      id\n      type\n      introduction\n      question\n      questions {\n        id\n        image {\n          id\n          url\n        }\n        text\n      }\n      contraIndicationTitle\n      indicationTitle\n      indications\n      contraIndications\n      hintText\n    }\n  }\n']),
    _templateObject25 = _taggedTemplateLiteral(['\n  query($id: ID!) {\n    chatBubble(id: $id) {\n      id\n      type\n      leftBubble {\n        id\n        url\n        thumbnailUrl\n      }\n      rightBubble {\n        id\n        url\n        thumbnailUrl\n      }\n      bubbles {\n        id\n        chatText\n        position\n        characteristic\n        question {\n          title\n          isCorrect\n          feedbackCorrect\n          feedbackInCorrect\n        }\n      }\n    }\n  }\n'], ['\n  query($id: ID!) {\n    chatBubble(id: $id) {\n      id\n      type\n      leftBubble {\n        id\n        url\n        thumbnailUrl\n      }\n      rightBubble {\n        id\n        url\n        thumbnailUrl\n      }\n      bubbles {\n        id\n        chatText\n        position\n        characteristic\n        question {\n          title\n          isCorrect\n          feedbackCorrect\n          feedbackInCorrect\n        }\n      }\n    }\n  }\n']),
    _templateObject26 = _taggedTemplateLiteral(['\n  query($id: ID!) {\n    imageAndTextMPC(id: $id) {\n      id\n      type\n      introduction\n      question\n      buttonText\n      feedbackCorrect\n      feedbackInCorrect\n      shuffleAnswers\n      answers {\n        id\n        correct\n        title\n        image {\n          id\n          url\n        }\n        text\n      }\n    }\n  }\n'], ['\n  query($id: ID!) {\n    imageAndTextMPC(id: $id) {\n      id\n      type\n      introduction\n      question\n      buttonText\n      feedbackCorrect\n      feedbackInCorrect\n      shuffleAnswers\n      answers {\n        id\n        correct\n        title\n        image {\n          id\n          url\n        }\n        text\n      }\n    }\n  }\n']),
    _templateObject27 = _taggedTemplateLiteral(['\n  query($id: ID!) {\n    hotspot(id: $id) {\n      id\n      type\n      introduction\n      question\n      feedbackCorrect\n      feedbackInCorrect\n      hintText\n      spot {\n        radius\n        x\n        y\n      }\n      image {\n        id\n        url\n      }\n      answers {\n        id\n        title\n        correct\n        image {\n          id\n          url\n        }\n        text\n      }\n    }\n  }\n'], ['\n  query($id: ID!) {\n    hotspot(id: $id) {\n      id\n      type\n      introduction\n      question\n      feedbackCorrect\n      feedbackInCorrect\n      hintText\n      spot {\n        radius\n        x\n        y\n      }\n      image {\n        id\n        url\n      }\n      answers {\n        id\n        title\n        correct\n        image {\n          id\n          url\n        }\n        text\n      }\n    }\n  }\n']),
    _templateObject28 = _taggedTemplateLiteral(['\n  query($id: ID!) {\n    scene(id: $id) {\n      id\n      type\n      video\n      title\n      introduction\n      text\n      image {\n        id\n        thumbnailUrl\n        url\n      }\n    }\n  }\n'], ['\n  query($id: ID!) {\n    scene(id: $id) {\n      id\n      type\n      video\n      title\n      introduction\n      text\n      image {\n        id\n        thumbnailUrl\n        url\n      }\n    }\n  }\n']),
    _templateObject29 = _taggedTemplateLiteral(['\n  query($id: ID!) {\n    hotspotMap(id: $id) {\n      id\n      title\n      introduction\n      type\n      question\n      imageOrientation\n      text\n      mapSpots {\n        id\n        title\n        text\n        popupOrientation\n        radius\n        x\n        y\n      }\n      image {\n        id\n        url\n        thumbnailUrl\n      }\n    }\n  }\n'], ['\n  query($id: ID!) {\n    hotspotMap(id: $id) {\n      id\n      title\n      introduction\n      type\n      question\n      imageOrientation\n      text\n      mapSpots {\n        id\n        title\n        text\n        popupOrientation\n        radius\n        x\n        y\n      }\n      image {\n        id\n        url\n        thumbnailUrl\n      }\n    }\n  }\n']),
    _templateObject30 = _taggedTemplateLiteral(['\n  query($id: ID!) {\n    referralElement(id: $id) {\n      id\n      title\n      introduction\n      workTitle\n      type\n      backgroundImage {\n        id\n        url\n      }\n      element {\n        id\n        title\n        type\n      }\n      text\n      backgroundColor\n      imagePosition\n      image {\n        id\n        url\n      }\n    }\n  }\n'], ['\n  query($id: ID!) {\n    referralElement(id: $id) {\n      id\n      title\n      introduction\n      workTitle\n      type\n      backgroundImage {\n        id\n        url\n      }\n      element {\n        id\n        title\n        type\n      }\n      text\n      backgroundColor\n      imagePosition\n      image {\n        id\n        url\n      }\n    }\n  }\n']);

var _graphqlTag = require('graphql-tag');

var _graphqlTag2 = _interopRequireDefault(_graphqlTag);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteral(strings, raw) { return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var GQL_FETCH_GLOBAL_SETTINGS = exports.GQL_FETCH_GLOBAL_SETTINGS = (0, _graphqlTag2.default)(_templateObject);

var GQL_DELETE_WORKFORM_RESULT = exports.GQL_DELETE_WORKFORM_RESULT = (0, _graphqlTag2.default)(_templateObject2);

var GQL_CREATE_ELEMENT_RESULT = exports.GQL_CREATE_ELEMENT_RESULT = (0, _graphqlTag2.default)(_templateObject3);

var GQL_FETCH_ELEMENT_RESULTS = exports.GQL_FETCH_ELEMENT_RESULTS = (0, _graphqlTag2.default)(_templateObject4);

var GQL_CREATE_WORKFORM_RESULT = exports.GQL_CREATE_WORKFORM_RESULT = (0, _graphqlTag2.default)(_templateObject5);

var GQL_FETCH_WORKFORM_RESULT = exports.GQL_FETCH_WORKFORM_RESULT = (0, _graphqlTag2.default)(_templateObject6);

var GQL_FETCH_TEXT_MPC = exports.GQL_FETCH_TEXT_MPC = (0, _graphqlTag2.default)(_templateObject7);

var GQL_FETCH_SHORT_TEXT = exports.GQL_FETCH_SHORT_TEXT = (0, _graphqlTag2.default)(_templateObject8);

var GQL_FETCH_VIDEO_WITH_TITLE = exports.GQL_FETCH_VIDEO_WITH_TITLE = (0, _graphqlTag2.default)(_templateObject9);

var GQL_FETCH_IMAGE_MPC = exports.GQL_FETCH_IMAGE_MPC = (0, _graphqlTag2.default)(_templateObject10);

var GQL_FETCH_FACTCARD_REFERRAL = exports.GQL_FETCH_FACTCARD_REFERRAL = (0, _graphqlTag2.default)(_templateObject11);

var GQL_FETCH_ORDER_QUESTION = exports.GQL_FETCH_ORDER_QUESTION = (0, _graphqlTag2.default)(_templateObject12);

var GQL_FETCH_MOODBOARD = exports.GQL_FETCH_MOODBOARD = (0, _graphqlTag2.default)(_templateObject13);

var GQL_FETCH_TEXT_CHECK_QUESTION = exports.GQL_FETCH_TEXT_CHECK_QUESTION = (0, _graphqlTag2.default)(_templateObject14);

var GQL_FETCH_IMAGE_CHECK_QUESTION = exports.GQL_FETCH_IMAGE_CHECK_QUESTION = (0, _graphqlTag2.default)(_templateObject15);

var GQL_FETCH_TEXT_IMAGE_CHECK_QUESTION = exports.GQL_FETCH_TEXT_IMAGE_CHECK_QUESTION = (0, _graphqlTag2.default)(_templateObject16);

var GQL_FETCH_STATEMENT_QUESTION = exports.GQL_FETCH_STATEMENT_QUESTION = (0, _graphqlTag2.default)(_templateObject17);

var GQL_FETCH_CHOICE_QUESTION = exports.GQL_FETCH_CHOICE_QUESTION = (0, _graphqlTag2.default)(_templateObject18);

var GQL_FETCH_IMAGE_WITH_SUBTITLE = exports.GQL_FETCH_IMAGE_WITH_SUBTITLE = (0, _graphqlTag2.default)(_templateObject19);

var GQL_FETCH_WHAT_IS_WHAT_QUESTION = exports.GQL_FETCH_WHAT_IS_WHAT_QUESTION = (0, _graphqlTag2.default)(_templateObject20);

var GQL_FETCH_TITLE_AND_TEXT = exports.GQL_FETCH_TITLE_AND_TEXT = (0, _graphqlTag2.default)(_templateObject21);

var GQL_FETCH_TITLE_AND_TEXT_IMAGE = exports.GQL_FETCH_TITLE_AND_TEXT_IMAGE = (0, _graphqlTag2.default)(_templateObject22);

var GQL_FETCH_CMS_WORK_FORM_FILE_DOWNLOAD = exports.GQL_FETCH_CMS_WORK_FORM_FILE_DOWNLOAD = (0, _graphqlTag2.default)(_templateObject23);

var GQL_FETCH_SORT_QUESTION = exports.GQL_FETCH_SORT_QUESTION = (0, _graphqlTag2.default)(_templateObject24);

var GQL_FETCH_CHAT_BUBBLE = exports.GQL_FETCH_CHAT_BUBBLE = (0, _graphqlTag2.default)(_templateObject25);

var GQL_FETCH_IMAGE_AND_TEXT_MPC = exports.GQL_FETCH_IMAGE_AND_TEXT_MPC = (0, _graphqlTag2.default)(_templateObject26);

var GQL_FETCH_HOTSPOT = exports.GQL_FETCH_HOTSPOT = (0, _graphqlTag2.default)(_templateObject27);

var GQL_FETCH_SCENE = exports.GQL_FETCH_SCENE = (0, _graphqlTag2.default)(_templateObject28);

var GQL_FETCH_HOTSPOTMAP = exports.GQL_FETCH_HOTSPOTMAP = (0, _graphqlTag2.default)(_templateObject29);

var GQL_FETCH_ELEMENT_REFERRAL = exports.GQL_FETCH_ELEMENT_REFERRAL = (0, _graphqlTag2.default)(_templateObject30);