'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.selectProgram = selectProgram;
var SELECT_PROGRAM = exports.SELECT_PROGRAM = '@manakin/app-core/SELECT_PROGRAM';

function selectProgram() {
    var program = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    return {
        type: SELECT_PROGRAM,
        payload: program
    };
}