import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { graphql } from "react-apollo";
import { Route } from "react-router-dom";
import { compose } from "recompose";
import { GQL_LOGOUT } from "../graphql";
import Login from "@manakin/app-views/Login";
import LoginFallback from "../LoginFallback";
import ResetPassword from "@manakin/app-views/ResetPassword";
import ConfirmReset from "@manakin/app-views/ResetPassword/ConfirmReset";
import Sso from "../Sso";
import { TransitionRouter } from "@manakin/app-core";
import Logout from "@manakin/authentication/Logout";

const AppLogout = graphql(GQL_LOGOUT)(Logout);

const styles = theme => ({});

const AppLogin = Login;

class AppAuthenticator extends React.Component {
  render() {
    const { match } = this.props;

    return (
      <div>
        <TransitionRouter>
          <Route path={`${match.url}/sso/:userId`} component={Sso} />
          <Route path={`${match.url}/logout`} component={AppLogout} />
          <Route path={`/admin`} component={AppLogin} />
          <Route path={`${match.url}/reset`} component={ResetPassword} />
          <Route
            path={`${match.url}/confirm-reset/:token`}
            component={ConfirmReset}
          />
          <Route path={`${match.url}/`} component={LoginFallback} />
        </TransitionRouter>
      </div>
    );
  }
}

export default compose(
  withStyles(styles, { name: "AuthenticationAppAuthenticator" })
)(AppAuthenticator);
