"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _TextField = require("@material-ui/core/TextField");

var _TextField2 = _interopRequireDefault(_TextField);

var _core = require("@manakin/core");

var _recompose = require("recompose");

var _styles = require("@material-ui/core/styles");

var _Button = require("@material-ui/core/Button");

var _Button2 = _interopRequireDefault(_Button);

var _reactApollo = require("react-apollo");

var _reduxFirstHistory = require("redux-first-history");

var _reactRedux = require("react-redux");

var _graphql = require("../graphql");

var _Grid = require("@material-ui/core/Grid");

var _Grid2 = _interopRequireDefault(_Grid);

var _Done = require("@material-ui/icons/Done");

var _Done2 = _interopRequireDefault(_Done);

var _Close = require("@material-ui/icons/Close");

var _Close2 = _interopRequireDefault(_Close);

var _Add = require("@material-ui/icons/Add");

var _Add2 = _interopRequireDefault(_Add);

var _Delete = require("@material-ui/icons/Delete");

var _Delete2 = _interopRequireDefault(_Delete);

var _v = require("uuid/v4");

var _v2 = _interopRequireDefault(_v);

var _actions = require("@manakin/authentication/actions");

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

var _actions2 = require("@manakin/core/actions");

var _styles2 = require("./styles");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _ = require("lodash");

var form = {
  code: {
    required: true,
    minLength: 1
  }
};

var ClaimLicense = function (_React$Component) {
  _inherits(ClaimLicense, _React$Component);

  function ClaimLicense() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, ClaimLicense);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = ClaimLicense.__proto__ || Object.getPrototypeOf(ClaimLicense)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
      validCode: false,
      loading: false,
      focus: true,
      textFields: []
    }, _this.timer = null, _this.handleChange = function (event) {
      var value = event.target.value;
      var idx = event.target.id;
      var target = event.target;
      clearTimeout(_this.timer);
      _this.timer = setTimeout(function () {
        _this.setState({
          loading: true
        }, function () {
          return _this.checkLicense(value, idx, target);
        });
      }, 1000);
    }, _this.checkLicense = function (value, id, target) {
      _this.props.client.query({
        query: _graphql.GQL_FETCH_LICENSE_TO_CLAIM,
        variables: {
          code: value || ""
        }
      }).then(function (result) {
        _this.getIdx(id).then(function (idx) {
          var textFields = _.cloneDeep(_this.state.textFields);
          textFields[idx].value = value;
          if (result.data && result.data.license) {
            textFields[idx].validCode = result.data.license.claimedOn ? false : true;
          } else {
            textFields[idx].validCode = false;
          }

          // event.target.focus();
          _this.setState(function (prevState) {
            return {
              textFields: textFields,
              validCode: textFields.every(function (value) {
                return value.validCode == true;
              }),
              loading: false
            };
          }, function () {
            if (target) target.focus();
          });
        });
      });
    }, _this.getIdx = function (id) {
      return new Promise(function (resolve) {
        _this.state.textFields.forEach(function (field, idx) {
          if (field.uuid == id) resolve(idx);
        });
      });
    }, _this.claimLicense = function () {
      var textFields = _this.state.textFields;

      return new Promise(function (resolve) {
        textFields.map(function (item, idx) {
          _this.props.onClaim({ code: item.value }).then(function (result) {
            if (result.errors) _this.props.errorMessage(result.errors[0].message);else {
              if (idx == textFields.length - 1) {
                resolve(true);
              }
            }
          }).catch(function (e) {
            _this.props.errorMessage("De licentiecode kon niet worden geclaimd");
          });
        });
      });
    }, _this.handleDelete = function (event) {
      var textFields = _.cloneDeep(_this.state.textFields);
      textFields.splice(event, 1);
      _this.setState({
        textFields: textFields,
        validCode: textFields.every(function (v) {
          return v.validCode == true;
        })
      });
    }, _this.handleLogout = function () {
      _this.props.onLogout();
    }, _this.handleClick = function (event) {
      var _this$props$config = _this.props.config,
          config = _this$props$config === undefined ? {} : _this$props$config;
      var _config$literals = config.literals,
          literals = _config$literals === undefined ? {} : _config$literals;

      _this.setState(function (prevState) {
        return {
          validCode: false,
          textFields: [].concat(_toConsumableArray(prevState.textFields), [{
            uuid: (0, _v2.default)(),
            label: literals.APP_LICENSE_CODE || "Licentiecode"
          }])
        };
      });
    }, _this.handleNext = function () {
      _this.claimLicense().then(function (result) {
        var _this$props = _this.props,
            redirect = _this$props.redirect,
            data = _this$props.data,
            client = _this$props.client;

        if (result) {
          client.query({
            query: _graphql.GQL_FETCH_APP_USER_FOR_STORE,
            fetchPolicy: "no-cache"
          }).then(function (result) {
            var _result$data = result.data,
                data = _result$data === undefined ? {} : _result$data;

            data.currentAppUser && data.currentAppUser.id && _this.props.processSucces(redirect, data.currentAppUser);
          });
        } else {
          console.log("er ging iets mis");
        }
      });
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(ClaimLicense, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      var _props$config = this.props.config,
          config = _props$config === undefined ? {} : _props$config;
      var _config$literals2 = config.literals,
          literals = _config$literals2 === undefined ? {} : _config$literals2;


      this.setState({
        textFields: [{
          uuid: (0, _v2.default)(),
          label: literals.APP_LICENSE_CODE || "Licentiecode"
        }]
      });
    }
  }, {
    key: "render",
    value: function render() {
      var _this2 = this;

      var _props = this.props,
          classes = _props.classes,
          _props$config2 = _props.config,
          config = _props$config2 === undefined ? {} : _props$config2;
      var _config$literals3 = config.literals,
          literals = _config$literals3 === undefined ? {} : _config$literals3;
      var _state = this.state,
          validCode = _state.validCode,
          textFields = _state.textFields,
          loading = _state.loading;


      var appRegistration = config && config.pages && config.pages.appRegistration || {};
      return _react2.default.createElement(
        "div",
        { className: classes.smallWrapper },
        textFields.map(function (option, idx) {
          return _react2.default.createElement(
            _Grid2.default,
            { container: true, spacing: 1, alignItems: "flex-end", key: option.uuid },
            _react2.default.createElement(
              _Grid2.default,
              { item: true, xs: 2 },
              loading && _react2.default.createElement(
                "div",
                { className: classes.icon },
                _react2.default.createElement(_core.Loader, null)
              ),
              option.validCode !== undefined && (option.validCode ? _react2.default.createElement(_Done2.default, { color: "secondary", className: classes.icon }) : _react2.default.createElement(_Close2.default, { color: "secondary", className: classes.icon }))
            ),
            _react2.default.createElement(
              _Grid2.default,
              { item: true, xs: 8 },
              _react2.default.createElement(_TextField2.default, {
                id: option.uuid,
                label: option.label,
                className: classes.textField,
                onChange: _this2.handleChange,
                margin: "normal",
                disabled: loading,
                autoFocus: true
              })
            ),
            _react2.default.createElement(
              _Grid2.default,
              { item: true, xs: 2 },
              idx > 0 && _react2.default.createElement(
                _Button2.default,
                {
                  color: "primary",
                  fullWidth: true,
                  onClick: _this2.handleDelete.bind(_this2, idx)
                },
                _react2.default.createElement(_Delete2.default, null)
              )
            )
          );
        }),
        appRegistration.addMoreLicenses && _react2.default.createElement(
          _Button2.default,
          {
            classes: {
              root: classes.addAnotherLicenseButton
            },
            color: "primary",
            fullWidth: true,
            onClick: this.handleClick
          },
          _react2.default.createElement(_Add2.default, null),
          "Nog een licentie toevoegen"
        ),
        _react2.default.createElement(
          _Button2.default,
          {
            fullWidth: true,
            variant: "contained",
            onClick: this.handleNext,
            color: "primary",
            disabled: !validCode,
            className: (0, _classnames2.default)(classes.nextButton, _defineProperty({}, classes.more, appRegistration.addMoreLicenses))
          },
          literals.APP_CONTINUE || "Doorgaan"
        ),
        _react2.default.createElement(
          _Button2.default,
          { className: classes.linkButton, onClick: this.handleLogout },
          literals.APP_LOGOUT || "Uitloggen"
        )
      );
    }
  }]);

  return ClaimLicense;
}(_react2.default.Component);

exports.default = (0, _recompose.compose)((0, _core.withForm)(form), _reactApollo.withApollo, (0, _reactApollo.graphql)(_graphql.GQL_CLAIM_LICENSE), (0, _reactApollo.graphql)(_graphql.GQL_FETCH_APP_USER_FOR_STORE), (0, _reactRedux.connect)(function (_ref2) {
  var config = _ref2.config;
  return {
    config: config
  };
}, function (dispatch) {
  return {
    onLogout: function onLogout() {
      return dispatch((0, _actions.logout)());
    },
    processSucces: function processSucces(redirect, user) {
      dispatch((0, _actions.login)(redirect, user));
    },
    errorMessage: function errorMessage(data) {
      dispatch((0, _actions2.showSnackbarMessage)({
        text: "Er ging iets fout: " + data,
        variant: "error"
      }));
    }
  };
}), (0, _recompose.withHandlers)({
  onClaim: function onClaim(_ref3) {
    var mutate = _ref3.mutate;
    return function (event) {
      return mutate({
        variables: {
          code: event.code
        }
      });
    };
  }
}), (0, _styles.withStyles)(_styles2.styles, { name: "ClaimLicense" }))(ClaimLicense);