import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { compose } from 'recompose';
import renderHTML from 'react-render-html';

const styles = theme => ({
  wrapper: {
    ...theme.manakin.smallWrapper,
    maxWidth: '800px',
    paddingTop: theme.manakin.defaultPadding,
    marginBottom: '3rem',
    opacity: 0,
    animation: 'showBackwards .3s ease 1.3s forwards',
    [theme.breakpoints.up('md')]: {
      minHeight: '240px',
      paddingTop: '12rem',
      marginBottom: '8rem'
    }
  },
  content: {
    margin: 0,
    fontFamily: theme.manakin.secondaryTitleFont,
    fontSize: '2.4rem',
    lineHeight: '4rem',
    color: theme.manakin.defaultContentColor[500],
    marginBottom: '1.4rem',
    fontWeight: '600',
    [theme.breakpoints.up('md')]: {
      marginBottom: '4rem',
      '&:first-letter': {
        fontSize: '20rem',
        float: 'left',
        lineHeight: '16rem',
        margin: '0 3rem 0 0'
      }
    }
  }
});

class Introduction extends React.PureComponent {
  render() {
    const { classes, data } = this.props;

    if (!data.introduction) {
      return '';
    }
    return (
      <div className={classes.root}>
        <div className={classes.wrapper}>
          <Typography
            component="div"
            variant="body1"
            classes={{ body1: classes.content }}
          >
            {renderHTML(data.introduction || '')}
          </Typography>
        </div>
      </div>
    );
  }
}

export default compose(withStyles(styles, { name: 'AppLessonIntroduction' }))(
  Introduction
);
