"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _AccountWrapper = require("../AccountWrapper");

var _AccountWrapper2 = _interopRequireDefault(_AccountWrapper);

var _recompose = require("recompose");

var _styles = require("@material-ui/core/styles");

var _graphql = require("./graphql");

var _reactApollo = require("react-apollo");

var _selectors = require("@manakin/authentication/selectors");

var _reactRedux = require("react-redux");

var _Table = require("@material-ui/core/Table");

var _Table2 = _interopRequireDefault(_Table);

var _TableBody = require("@material-ui/core/TableBody");

var _TableBody2 = _interopRequireDefault(_TableBody);

var _TableCell = require("@material-ui/core/TableCell");

var _TableCell2 = _interopRequireDefault(_TableCell);

var _TableHead = require("@material-ui/core/TableHead");

var _TableHead2 = _interopRequireDefault(_TableHead);

var _TableRow = require("@material-ui/core/TableRow");

var _TableRow2 = _interopRequireDefault(_TableRow);

var _Paper = require("@material-ui/core/Paper");

var _Paper2 = _interopRequireDefault(_Paper);

var _PictureAsPdf = require("@material-ui/icons/PictureAsPdf");

var _PictureAsPdf2 = _interopRequireDefault(_PictureAsPdf);

var _IconButton = require("@material-ui/core/IconButton");

var _IconButton2 = _interopRequireDefault(_IconButton);

var _moment = require("moment");

var _moment2 = _interopRequireDefault(_moment);

require("moment/min/locales");

var _core = require("@material-ui/core");

var _appCore = require("@manakin/app-core");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var styles = function styles(theme) {
  return {
    body: {
      margin: 0
    },
    bodyFirst: _defineProperty({
      margin: 0
    }, theme.breakpoints.down('md'), {
      color: theme.palette.getContrastText(theme.manakin.primaryColor[500])
    }),
    table: _defineProperty({}, theme.breakpoints.down('md'), {
      display: 'block'
    }),
    tableHead: _defineProperty({}, theme.breakpoints.down('md'), {
      display: 'none'
    }),
    tableBody: _defineProperty({}, theme.breakpoints.down('md'), {
      display: 'block',
      width: '100%'
    }),
    tableRow: _defineProperty({}, theme.breakpoints.down('md'), {
      display: 'block',
      width: '100%'
    }),
    tableCell: _defineProperty({}, theme.breakpoints.down('md'), {
      display: 'block'
    }),
    tableCellFirst: _defineProperty({}, theme.breakpoints.down('md'), {
      background: theme.manakin.primaryColor[500],
      display: 'block'
    })
  };
};

var Invoices = function Invoices(props) {
  var classes = props.classes,
      _props$data = props.data,
      data = _props$data === undefined ? {} : _props$data;

  var _data$idealOrders = data.idealOrders,
      _idealOrders = _data$idealOrders === undefined ? {} : _data$idealOrders,
      loading = data.loading;

  var _idealOrders$idealOrd = _idealOrders.idealOrders,
      idealOrders = _idealOrders$idealOrd === undefined ? [] : _idealOrders$idealOrd;


  var handleClick = function handleClick(item) {
    return function () {
      if (item && item.idealStatus === "Success") {
        window.open(item.invoicePdfUrl, "_blank");
      }
    };
  };

  if (loading) {
    return _react2.default.createElement(
      "div",
      null,
      _react2.default.createElement(_appCore.Loader, { fullScreen: true })
    );
  }
  return _react2.default.createElement(
    "div",
    { className: classes.root },
    _react2.default.createElement(
      _AccountWrapper2.default,
      {
        title: "Betalingen",
        introduction: "Download hier een pdf versie van uw facturen",
        wrapper: "medium"
      },
      _react2.default.createElement(
        "div",
        { className: classes.invoiceRoot },
        idealOrders && idealOrders.length ? _react2.default.createElement(
          _Paper2.default,
          { className: classes.root },
          _react2.default.createElement(
            _Table2.default,
            { className: classes.table, "aria-label": "simple table" },
            _react2.default.createElement(
              _TableHead2.default,
              null,
              _react2.default.createElement(
                _TableRow2.default,
                { className: classes.tableHead },
                _react2.default.createElement(
                  _TableCell2.default,
                  null,
                  "Factuurnummer"
                ),
                _react2.default.createElement(
                  _TableCell2.default,
                  null,
                  "Gebruiker"
                ),
                _react2.default.createElement(
                  _TableCell2.default,
                  null,
                  "Datum"
                ),
                _react2.default.createElement(
                  _TableCell2.default,
                  null,
                  "prijs"
                ),
                _react2.default.createElement(
                  _TableCell2.default,
                  null,
                  "Download"
                )
              )
            ),
            _react2.default.createElement(
              _TableBody2.default,
              { className: classes.tableBody },
              idealOrders && idealOrders.map(function (item) {
                return _react2.default.createElement(
                  _TableRow2.default,
                  { key: item.orderID, className: classes.tableRow },
                  _react2.default.createElement(
                    _TableCell2.default,
                    { align: "left", className: classes.tableCellFirst },
                    _react2.default.createElement(
                      _core.Typography,
                      { className: classes.bodyFirst, variant: "body1" },
                      item.invoiceNumber
                    )
                  ),
                  _react2.default.createElement(
                    _TableCell2.default,
                    { align: "left", className: classes.tableCell },
                    _react2.default.createElement(
                      _core.Typography,
                      { className: classes.body, variant: "body1" },
                      item.name || ""
                    )
                  ),
                  _react2.default.createElement(
                    _TableCell2.default,
                    { align: "left", className: classes.tableCell },
                    _react2.default.createElement(
                      _core.Typography,
                      { className: classes.body, variant: "body1" },
                      item.orderDate ? (0, _moment2.default)(item.orderDate).locale("nl").format("D MMMM YYYY") : "Datum niet bekend"
                    )
                  ),
                  _react2.default.createElement(
                    _TableCell2.default,
                    { align: "left", className: classes.tableCell },
                    _react2.default.createElement(
                      _core.Typography,
                      { className: classes.body, variant: "body1" },
                      item.totalPrice || "Niet bekend"
                    )
                  ),
                  _react2.default.createElement(
                    _TableCell2.default,
                    { align: "center", className: classes.tableCell },
                    _react2.default.createElement(
                      _IconButton2.default,
                      {
                        "aria-label": "Download factuur",
                        onClick: handleClick(item)
                      },
                      _react2.default.createElement(_PictureAsPdf2.default, null)
                    )
                  )
                );
              })
            )
          )
        ) : _react2.default.createElement(
          _Paper2.default,
          { className: classes.root },
          _react2.default.createElement(
            _core.Typography,
            { variant: "body1" },
            "U heeft geen facturen"
          )
        )
      )
    )
  );
};

exports.default = (0, _recompose.compose)((0, _reactRedux.connect)(function (state) {
  return {
    appUser: (0, _selectors.getAppUser)(state)
  };
}), (0, _reactApollo.graphql)(_graphql.GQL_FETCH_ORDERS, {
  options: function options(props) {
    return {
      variables: {
        filter: { users: [props.appUser.id || ""] }
      }
    };
  }
}), (0, _styles.withStyles)(styles))(Invoices);