import gql from 'graphql-tag';

export const GQL_FETCH_LESSON = gql`
    query($id: ID!) {
        lesson(id: $id) {
            id
            introTitle
            time
            type
            introduction
            preTitle
            image {
                id
                url
                thumbnailUrl
            }
            headerImage {
                id
                url
                thumbnailUrl
            }
            workforms {
                id
                type
                correctWorkforms {
                    id
                    title
                    type
                }
                inCorrectWorkforms {
                    id
                    title
                    type
                }
            }
        }
    }
`;

export const GQL_FETCH_CMS_BOX = gql`
    query($id: ID!) {
        box(id: $id) {
            id
            elements {
                id
                type
            }
        }
    }
`;

export const GQL_FETCH_GLOBAL_SETTINGS = gql`
    query{
        settings {
            settings {
                name
                value
                file {
                    id
                    bynderId
                    derivative
                    url
                    thumbnailUrl
                    name
                }
            }
        }
    }
`;

