import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import MoveIcon from '@manakin/core/icons/Move';
import MenuIcon from '@manakin/core/icons/Menu';
import RedirectIcon from '@manakin/core/icons/Redirect';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { compose } from 'recompose';
import { connect } from 'react-redux';

const styles = theme => ({
  cardRoot: {
    width: '100%'
  },
  card: {
    display: 'flex',
    height: '120px',
    border: '1px solid',
    borderColor: theme.palette.grey['400'],
    borderRadius: '10px',
    boxShadow: 'none',
    position: 'relative',
    cursor: 'pointer'
  },
  cardContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  thumbnail: {
    width: '120px',
    minWidth: '120px',
    height: '100%',
    objectFit: 'cover',
    fontFamily: "'object-fit: cover'"
  },
  cardContainerChild: {
    marginBottom: '5px'
  },
  cardHeader: {
    paddingBottom: 0,
    fontSize: '16px',
    marginBottom: '.2rem'
  },
  cardContent: {
    paddingTop: 0
  },
  cardDrag: {
    opacity: 0
  },
  icons: {
    display: 'flex',
    position: 'absolute',
    top: '10px',
    right: '10px'
  },
  moveButtonContainer: {
    display: 'inline-flex',
    flex: '0 0 auto',
    width: 48,
    color: 'rgba(0, 0, 0, 0.54)',
    height: 48,
    padding: 0,
    fontSize: '1.5rem',
    textAlign: 'center',
    transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'move'
  },
  moveButton: {
    display: 'flex'
  }
});

class DraggableCard extends React.PureComponent {
  state = {
    anchorEl: null
  };

  handleClick = event => {
    event.stopPropagation();
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = event => {
    event.stopPropagation();
    this.setState({ anchorEl: null });
  };

  handleRemove = event => {
    event.stopPropagation();
    const { index, onRemove } = this.props;
    this.setState({ anchorEl: null });
    onRemove(index);
  };

  handleItemClick = () => {
    const { onItemClicked, id } = this.props;
    if (onItemClicked) onItemClicked(id);
  };

  render() {
    const {
      readOnly = false,
      classes,
      image,
      subHeader,
      type,
      children,
      style,
      extraContent,
      config = {}
    } = this.props;
    const { literals = {} } = config;
    const { anchorEl } = this.state;
    return (
      <div className={classes.cardRoot} style={{ ...style }}>
        <div>
          <Card className={classNames(classes.card)}>
            <img className={classes.thumbnail} src={image} />
            <div className={classes.cardContentContainer}>
              <CardHeader
                className={classes.cardHeader}
                action={
                  <div className={classes.icons}>
                    <IconButton onClick={this.handleItemClick}>
                      <RedirectIcon />
                    </IconButton>
                    {!readOnly && (
                      <div>
                        <IconButton
                          onClick={this.handleClick}
                          aria-owns={anchorEl ? 'simple-menu' : null}
                          aria-haspopup="true"
                        >
                          <MenuIcon />
                        </IconButton>
                        <Menu
                          id="simple-menu"
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClose={this.handleClose}
                        >
                          <MenuItem onClick={this.handleRemove}>
                            {literals.CMS_DELETE || 'Verwijderen'}
                          </MenuItem>
                        </Menu>
                      </div>
                    )}
                    {!readOnly && (
                      <div>
                        <div className={classes.moveButtonContainer}>
                          <div className={classes.moveButton}>
                            <MoveIcon />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                }
                subheader={subHeader || type}
              />
              <CardContent className={classes.cardContent}>
                {children}
              </CardContent>
            </div>
          </Card>
        </div>
        {extraContent}
      </div>
    );
  }
}

export default compose(
  connect(state => ({ config: state.config })),
  withStyles(styles)
)(DraggableCard);
