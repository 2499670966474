import React from 'react';
import OrderQuestion from './OrderQuestion';
import shuffle from 'shuffle-array';

class OrderQuestionContainer extends React.PureComponent {

  state = {
    animationSettings: { gap: false },
    buttonDisabled: false
  };

  componentDidMount() {
    this.setStartState();
  }

  componentDidUpdate(prevProps) {
    if (
      (!prevProps.data.done && this.props.data.done) ||
      prevProps.data.id != this.props.data.id
    ) {
      this.setStartState();
      this.setState({ buttonDisabled: false })
    }
  }

  setStartState = () => {
    const { data, options = {} } = this.props;
    const _answers = [...data.answers];
    const answers = data.finished ? [..._answers] : shuffle(_answers);

    this.setState(
      {
        ...this.props.data,
        answers: answers,
        animateNext: '',
        animatePrev: -1,
        options: options
      },
      () => {
        this.setState({
          correct: data.finished ? data.correct : this.checkOrder(),
          feedback:
            data.finished && options && options.showFeedback ? true : false,
          done: data.finished
        });
      }
    );
  };

  swapItems = (_array, a, b) => {
    let array = [..._array];
    let temp = array[a];

    array[a] = array[b];
    array[b] = temp;
    return array;
  };

  handleOnMoveDown = (idx, id) => {
    const { answers: _answers, correctAnswer } = this.state;
    if (idx === _answers.length - 1 || this.state.finished) return;
    const index = _answers[idx + 1].id == correctAnswer ? idx + 2 : idx + 1;
    const gap = _answers[idx + 1].id == correctAnswer ? true : false;
    if (index < _answers.length) {
      const answers = this.swapItems(_answers, idx, index);
      this.setMoveState({
        answers: answers,
        animatePrev: id,
        animateNext: _answers[index].id,
        settings: {
          gap: gap
        }
      });
    }
  };

  handleOnMoveUp = (idx, id) => {
    if (idx === 0 || this.state.finished) return;
    const { answers: _answers, correctAnswer } = this.state;
    const index = _answers[idx - 1].id == correctAnswer ? idx - 2 : idx - 1;
    const gap = _answers[idx - 1].id == correctAnswer ? true : false;
    if (index >= 0) {
      const answers = this.swapItems(_answers, idx, index);
      this.setMoveState({
        answers: answers,
        animateNext: id,
        animatePrev: _answers[index].id,
        settings: {
          gap: gap
        }
      });
    }
  };

  setMoveState = obj => {
    this.setState(
      {
        animateNext: obj.animateNext,
        animatePrev: obj.animatePrev,
        animationSettings: { ...obj.settings }
      },
      () => {
        setTimeout(() => {
          this.setState(
            {
              answers: obj.answers,
              animateNext: null,
              animatePrev: null
            },
            () => {
              this.setState({
                correct: this.checkOrder()
              });
            }
          );
        }, 300);
      }
    );
  };

  checkOrder = () => {
    const { data } = this.props;
    const { answers } = this.state;
    let correct = true;

    answers.forEach((answer, idx) => {
      if (answer.id != data.answers[idx].id) correct = false;
    });
    return correct;
  };

  setOneCorrect = () => {
    const { data } = this.props;
    const { answers } = this.state;
    let correctAnswer = null;

    answers.forEach((answer, idx) => {
      if (answer.id == data.answers[idx].id) {
        correctAnswer = answer.id;
      }
    });
    if (!correctAnswer) {
      const random = 0 + Math.floor(Math.random() * answers.length);
      const id = data.answers[random].id;
      let idx;
      answers.forEach((item, i) => {
        if (item.id == id) {
          idx = i;
          correctAnswer = item.id;
        }
      });
      const arr = this.swapItems(answers, idx, random);
      this.setState(
        {
          answers: arr
        },
        () => {
          correct: this.checkOrder();
        }
      );
    }
    this.setState({ correctAnswer: correctAnswer }, () => {
      correct: this.checkOrder();
    });
  };

  handleSubmit = () => {
    const { options = {}, data } = this.props;
    const { correct } = this.state;

    if (options && options.showFeedback) {
      this.setState({ feedback: true, options: options });
      data.hintText && !correct && this.setOneCorrect();
    } else {
      this.setState({ buttonDisabled: true })
      this.handleFinish();
    }
  };

  handleFinish = () => {
    this.props.onFinished({
      correct: this.state.correct,
      workform: { ...this.state }
    });
  };

  render() {
    return (
      <OrderQuestion
        data={{ ...this.state }}
        onMoveDown={this.handleOnMoveDown}
        onMoveUp={this.handleOnMoveUp}
        onSubmit={this.handleSubmit}
        onFinished={this.handleFinish}
        disabled={this.state.buttonDisabled}
      />
    );
  }
}

export default OrderQuestionContainer;
