import { createSelector } from 'reselect';
import { getCoreState } from '../selectors';

export const SNACKBAR_MESSENGER_STATE_ROOT = 'snackbarMessenger';

const getState = createSelector(
    [getCoreState],
    coreState => coreState[SNACKBAR_MESSENGER_STATE_ROOT]
);

export const getCurrent = createSelector(
    [getState],
    state => state.current
);

export const isOpen = createSelector(
    [getState],
    state => state.open
);
