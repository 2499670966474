import React, { useState, useEffect } from "react";
import { ElementOverlay, withWorkforms, Loader } from "@manakin/app-core";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { GQL_FETCH_SUMMARY } from "./graphql";
import { graphql } from "react-apollo";
import { compose } from "recompose";
import renderHTML from "react-render-html";

const styles = theme => ({
  smallWrapper: {
    ...theme.manakin.smallWrapper,
    maxWidth: "75rem"
  },
  wrapper: {
    ...theme.manakin.smallWrapper
  },
  contentColor: {
    color: theme.manakin.primaryColor[500]
  },
  content: {
    padding: "2rem 0 8rem",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      padding: "5rem 0 10rem"
    },
    [theme.breakpoints.up("lg")]: {
      paddingTop: "12rem",
      paddingBottom: "12rem"
    }
  },
  heading: {
    [theme.breakpoints.up("md")]: {
      fontSize: "5.6rem",
      lineHeight: "7rem"
    }
  },
  marginBottom: {
    marginBottom: "2rem"
  },
  list: {
    paddingLeft: "2rem"
  },
  body: {
    color: theme.palette.primary[500],
    "& ul": {
      padding: "0 0 0 2rem"
    }
  }
});

const RecapContainer = props => {
  const { data, workformsData, classes } = props;
  const { loading = true, summary = {} } = data;

  //effect hooks
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!loading) {
      const options = {
        showFeedback: false,
        nextButton: false
      };

      if (summary.workforms) {
        workformsData.loadWorkforms({
          ...summary,
          options: options,
          elementId: props.match.params.elementId
        });
        workformsData.loadElementResult(props.match.params.elementId);
      }
    }
  }, [props.data.loading]);

  if (loading) {
    return (
      <div>
        <Loader fullScreen={true} />
      </div>
    );
  }
  return (
    <div className={classes.root}>
      <ElementOverlay controls={true}>
        <div className={classes.content}>
          <div className={classes.smallWrapper}>
            <Typography variant="h1" className={classes.heading}>
              {summary.introTitle || "Samenvatting"}
            </Typography>
          </div>
          <div className={classes.smallWrapper}>
            <Typography
              variant="body1"
              component="div"
              className={classes.body}
            >
              <div className={classes.marginBottom}>
                {renderHTML(summary.introText || "")}
              </div>
              {workformsData.workforms &&
                workformsData.workforms.map(item => (
                  <React.Fragment key={item.id}>
                    {renderHTML(item.text || "")}
                  </React.Fragment>
                ))}
            </Typography>
          </div>
        </div>
      </ElementOverlay>
    </div>
  );
};

export default compose(
  graphql(GQL_FETCH_SUMMARY, {
    options: props => ({ variables: { id: props.match.params.elementId } })
  }),
  withWorkforms(),
  withStyles(styles)
)(RecapContainer);
