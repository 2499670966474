import React from 'react';
import Dialog from '@manakin/core/Dialog';
import MuiDialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { TextField } from '@manakin/core';
import { withForm } from '@manakin/core';
import { compose } from 'recompose';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
	deleteButton: {
		color: 'red',
		marginTop: '5rem'
	}
})

const form = {
};

class CreateDialog extends React.PureComponent {
	handleSubmit = onClose => () => {
		const { form = {} } = this.props;
		this.props.onSave({
			id: this.props.mileStone,
			text: form.fields.text
		})
		onClose()
	};

	handleDelete = onClose => () => {
		this.props.onSave({
			id: this.props.mileStone,
			text: ''
		})
		onClose()
	}

	render() {
		const { form, mileStone, mileStones, classes } = this.props;

		return (
			<Dialog
				name="appMilestoneDialogDialog"
				render={({ open, onClose }) => (
					<MuiDialog open={open} onClose={onClose} fullWidth>
						<DialogTitle>Periode toevoegen</DialogTitle>
						<DialogContent>
							<TextField
								name="text"
								label="Periode"
								placeholder="Periode"
								form={form}
								initialValue={mileStones[mileStone] && mileStones[mileStone].text || ''}
							/>
						</DialogContent>
						<DialogActions>
							<Button
								onClick={this.handleSubmit(onClose)}
								color="primary"
								variant="contained"
							>
								Aanmaken
							</Button>
							<Button
								onClick={onClose}
								color='primary'
							>
								Annuleren
							</Button>
							<Button
								onClick={this.handleDelete(onClose)}
								color='primary'
								className={classes.deleteButton}
							>
								Verwijderen
							</Button>
						</DialogActions>
					</MuiDialog>
				)}
			/>
		);
	}
}

export default compose(
	withForm(form),
	withStyles(styles),
)(CreateDialog);
