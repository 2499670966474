'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GQL_FETCH_MAIN_ORGANISATION = undefined;

var _templateObject = _taggedTemplateLiteral(['\n  query($id: ID!) {\n    mainOrganisation(id: $id) {\n      id\n      name\n      organisations {\n        id\n        schools {\n          id\n          classes {\n            id\n            name\n          }\n        }\n      }\n    }\n  }\n'], ['\n  query($id: ID!) {\n    mainOrganisation(id: $id) {\n      id\n      name\n      organisations {\n        id\n        schools {\n          id\n          classes {\n            id\n            name\n          }\n        }\n      }\n    }\n  }\n']);

var _graphqlTag = require('graphql-tag');

var _graphqlTag2 = _interopRequireDefault(_graphqlTag);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteral(strings, raw) { return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var GQL_FETCH_MAIN_ORGANISATION = exports.GQL_FETCH_MAIN_ORGANISATION = (0, _graphqlTag2.default)(_templateObject);