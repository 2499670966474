import React from 'react';
import { graphql } from 'react-apollo';
import { compose } from "recompose";
import BookCheck from './BookCheck';
import {GQL_FETCH_BOOKCHECK} from './graphql/graphql';
import {
	withWorkforms
} from '@manakin/app-core';
import {getBoxId} from '@manakin/app-core/Boxes/selectors';

class BookCheckContainer extends React.PureComponent{
	componentDidUpdate(prevProps) {
		if(prevProps.data.loading && !this.props.data.loading) {
			const {data, workformsData} = this.props;

			const options = {
				showFeedback: true,
				nextButton: true
			}

			if(data.bookCheck) {
				data.bookCheck.workforms && workformsData.loadWorkforms({...data.bookCheck, options: options, elementId: this.props.match.params.elementId});
				workformsData.loadElementResult(this.props.match.params.elementId);
			}
		}
	}

	handleExit = (count) => {
		this.props.workformsData.saveElement({
			elementId: this.props.match.params.elementId
		}).then(result => {
			this.props.history.push(`${this.props.match.url}/outro/`);
		});		
	}

	handleClick = event => {
		if(event) {
			this.props.history.push(`${this.props.match.url}`);
		}else {
			this.props.history.push(`${this.props.match.url}/questions`);
		}
	}

	render() {
		const {workformsData, data} = this.props;

		return (
			<React.Fragment>
				<BookCheck {...workformsData} onExit={this.handleExit} onClick={this.handleClick}/>
			</React.Fragment>
		)
	}
}

export default compose(
	graphql(GQL_FETCH_BOOKCHECK, {
		options: props => ({ variables: { id: props.match.params.elementId } })
	}),
	withWorkforms()
)(BookCheckContainer);