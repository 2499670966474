import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { FeedbackBox } from "@manakin/app-workforms";
import { VideoPlayer } from "@manakin/app-core";
import classNames from "classnames";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";

const styles = theme => ({
  smallWrapper: {
    ...theme.manakin.smallWrapper
  },
  wrapper: {
    ...theme.manakin.defaultWrapper,
    maxWidth: "1200px"
  },
  subHeader: {
    textAlign: "center"
  },
  header: {
    fontWeight: "bold",
    marginBottom: "7rem",
    fontSize: "4rem",
    marginTop: "1.8rem",
    wordBreak: "break-word",
    hyphens: "auto",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      fontSize: "2.4rem",
      lineHeight: "4rem",
      marginBottom: "4rem"
    }
  },
  choices: {
    [theme.breakpoints.up("md")]: {
      display: "flex"
    }
  },
  choice: {
    width: "100%",
    marginBottom: "3rem",
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      margin: "0 2.5rem 5rem"
    }
  },
  button: {
    maxWidth: "30rem"
  },
  video: {
    marginBottom: "3rem"
  },
  active: {
    backgroundColor: `${theme.manakin.primaryColor[500]}`,
    borderColor: `${theme.manakin.primaryColor[500]}`
  }
});

const ChoiceQuestion = props => {
  const {
    classes,
    data,
    onFinished,
    disabled,
    options,
    correct,
    config = {}
  } = props;
  const { literals = {} } = config;

  //function
  const handleSubmit = userAnswer => {
    if (props.onSubmit) props.onSubmit(userAnswer);
  };

  return (
    <div className={classes.root}>
      <div className={classes.smallWrapper}>
        <Typography
          component="p"
          variant="subtitle1"
          className={classNames("smallUnderlineCenter", classes.subHeader)}
        >
          {data.introduction || literals.CMS_WATCH_VIDEOS || "Bekijk video's"}
        </Typography>
        <Typography component="h2" variant="h3" className={classes.header}>
          {data.title ||
            literals.APP_WHAT_WOULD_YOU_SAY ||
            "Wat zou jij zeggen?"}
        </Typography>
      </div>
      <div className={classes.wrapper}>
        <div className={classes.choices}>
          <div className={classes.choice}>
            <div className={classes.video}>
              <VideoPlayer videoId={data.answerOne || null} playing={false} />
            </div>
            <Button
              className={classNames(classes.button, {
                [classes.active]: data.agree
              })}
              variant="outlined"
              color="primary"
              disabled={data.done || disabled}
              fullWidth
              onClick={() => handleSubmit(true)}
            >
              {literals.APP_ANSWER_A || "Antwoord A"}
            </Button>
          </div>
          <div className={classes.choice}>
            <div className={classes.video}>
              <VideoPlayer videoId={data.answerTwo || null} playing={false} />
            </div>
            <Button
              className={classNames(classes.button, {
                [classes.active]: data.agree != null && !data.agree
              })}
              variant="outlined"
              color="primary"
              disabled={data.done || disabled}
              fullWidth
              onClick={() => handleSubmit(false)}
            >
              {literals.APP_ANSWER_B || "Antwoord B"}
            </Button>
          </div>
        </div>
        {props.feedback && (
          <div
            className={classNames(classes.feedback, {
              [classes.activeFeedback]: props.feedback
            })}
          >
            <FeedbackBox
              data={{ ...data, options: options, correct: correct }}
              onFinished={onFinished}
              outlined={true}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default compose(
  connect(state => ({ config: state.config })),
  withStyles(styles, { name: "AppChoiceQuestion" })
)(ChoiceQuestion);
