export const styles = theme => ({
  wrapper: {
    [theme.breakpoints.up('md')]: {
      ...theme.manakin.defaultWrapper,
      maxWidth: '500px',
      paddingLeft: '0',
      margin: '0'
    }
  },
  hotspot: {
    position: 'relative',
    display: 'none',
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      display: 'block'
    }
  },
  mobileHotspot: {
    position: 'relative',
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  image: {
    width: 'auto',
    position: 'relative',
    display: 'inline-block'
  },
  spot: {
    height: '0',
    borderRadius: '100%',
    border: `10px solid black`,
    position: 'absolute',
    transform: 'translate(-50%, -50%)'
  },
  formControlRoot: {
    width: '100%',
    minHeight: '10rem',
    padding: '0 3.4rem',
    borderTop: `1px solid ${theme.manakin.defaultBorderColor[500]}`,
    margin: '0',
    '&:last-child': {
      borderBottom: `1px solid ${theme.manakin.defaultBorderColor[500]}`
    },
    [theme.breakpoints.up('md')]: {
      borderLeft: `1px solid ${theme.manakin.defaultBorderColor[500]}`,
      borderRight: `1px solid ${theme.manakin.defaultBorderColor[500]}`
    }
  },
  active: {
    backgroundColor: theme.manakin.primaryColor
  },
  formControlLabel: {
    color: theme.manakin.defaultContentColor[500],
    fontFamily: theme.manakin.defaultAnswerFont,
    fontSize: '1.8rem',
    lineHeight: '3.8rem',
    margin: '0 0 0 3rem'
  },
  formControl: {
    marginBottom: '2rem'
  },
  buttons: {
    position: 'relative'
  },
  button: {
    margin: '20px',
    width: 'calc(100% - 40px)!important',
    [theme.breakpoints.up('md')]: {
      width: '100%!important',
      margin: '0'
    }
  },
  feedback: {
    position: 'absolute',
    bottom: '-20px',
    left: '0',
    zIndex: '2',
    opacity: '0',
    height: 0,
    overflow: 'hidden',
    pointerEvents: 'none',
    transition: 'opacity .3s, bottom .3s',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      position: 'relative',
      top: '0px',
      transition: 'opacity .3s, top .3s'
    }
  },
  activeFeedback: {
    opacity: '1',
    bottom: '0',
    pointerEvents: 'auto',
    height: 'auto',
    [theme.breakpoints.up('md')]: {
      top: '-100px'
    }
  }
});
