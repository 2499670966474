'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.getProgram = exports.SELECT_PROGRAM_ROOT = undefined;

var _reselect = require('reselect');

var _selectors = require('../selectors');

var SELECT_PROGRAM_ROOT = exports.SELECT_PROGRAM_ROOT = 'selectProgram';

var getState = (0, _reselect.createSelector)([_selectors.getCoreState], function (coreState) {
    return coreState[SELECT_PROGRAM_ROOT];
});

var getProgram = exports.getProgram = (0, _reselect.createSelector)([getState], function (state) {
    return state.program;
});