import React, { useEffect, useState } from "react";
import { compose } from "recompose";
import { withStyles, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import { getAppUser } from "@manakin/authentication/selectors";
import { BestTimes, ElementOverlay } from "@manakin/app-core";
import { PointsInfo } from "../../parts";
import { setBackButton } from "@manakin/app-core/AppBar/actions";

const styles = theme => ({
  root: {
    backgroundColor: theme.manakin.defaultBackgroundColor[500],
    padding: "10rem 0",
    [theme.breakpoints.up("lg")]: {
      padding: "16rem 0 0"
    }
  },
  points: {
    position: "absolute",
    bottom: "2rem",
    left: "2rem",
    [theme.breakpoints.up("lg")]: {
      bottom: "10rem",
      left: "auto",
      right: "10rem"
    }
  },
  preTitle: {
    color: theme.palette.secondary[500],
    fontWeight: "bold",
    [theme.breakpoints.up("md")]: {
      textAlign: "center"
    }
  },
  title: {
    [theme.breakpoints.up("md")]: {
      textAlign: "center"
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "14rem",
      lineHeight: "24rem"
    }
  },
  body: {
    [theme.breakpoints.up("md")]: {
      textAlign: "center",
      maxWidth: "55rem",
      margin: "0 auto 3rem"
    },
    [theme.breakpoints.up("lg")]: {
      margin: "0 auto 7rem"
    }
  },
  wrapper: {
    ...theme.manakin.largeWrapper
  }
});

const ResultsPage = props => {
  const { points, classes, appUser, elementId, config = {} } = props;
  const { literals = {} } = config;

  const handleBackButtonClick = () => {
    if (props.onBackButtonClick) props.onBackButtonClick();
  };

  console.log("points", points);

  return (
    <div className={classes.root}>
      <ElementOverlay
        fullWidth={true}
        controls={true}
        background="TRANSPARENT"
        backButton={points ? false : true}
        onBackButtonClick={handleBackButtonClick}
      >
        <div className={classes.wrapper}>
          <Typography variant="body2" className={classes.preTitle}>
            {literals.APP_BETTING_GAME_TITLE || "The betting game"}
          </Typography>
          <Typography variant="h1" className={classes.title}>
            {literals.APP_LEADERBOARD || "Leaderboard"}
          </Typography>
          <Typography variant="body1" className={classes.body}>
            {literals.APP_LEADERBOARD_MESSAGE ||
              "Welcome to the leaderboard. Do you have enough points to be mentioned on the board? ;-)"}
          </Typography>
          <BestTimes
            elementId={elementId}
            variant="points"
            max="6"
            noTitle={true}
            noPadding={true}
          />
        </div>
      </ElementOverlay>
    </div>
  );
};

export default compose(
  withStyles(styles, { name: "AppBettingGameResultsPage" }),
  connect(
    state => ({
      config: state.config,
      appUser: getAppUser(state)
    }),
    dispatch => ({
      setBackButton: data => dispatch(setBackButton(data))
    })
  )
)(ResultsPage);
