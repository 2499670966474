import gql from 'graphql-tag';

export const GQL_RESET_PASSWORD = gql`
    mutation forgotPasswordApp($email: String!) {
        forgotPasswordApp(input: { email: $email }) {
            result
        }
    }
`;

export const GQL_CHANGE_PASSWORD = gql`
    mutation changePassword($password: String!, $token: String!) {
        changePassword(input: { password: $password, token: $token }) {
            user {
                id
            }
        }
    }
`;