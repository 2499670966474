import React from 'react';
import { withApollo } from 'react-apollo';
import { compose } from "recompose";
import { openDialog } from '@manakin/core/actions';
import { isRefresh } from '@manakin/core/lib/selectors';
import { connect } from 'react-redux';
import { withQuery } from '@manakin/core';
var _ = require('lodash');

const mapStateToProps = state => {
  return {
    hasChanged: isRefresh(state)
  };
};

const mapDispatchToProps = dispatch => ({
  onDelete: data => dispatch(openDialog(data.dialog, data.selection))
});

const withTableService = data => WrappedComponent => {
  return compose(
    withApollo,
    withQuery,
    connect(
      mapStateToProps,
      mapDispatchToProps
    )
  )(
    class WithTableService extends React.PureComponent {
      state = {
        loading: true
      };
      componentDidUpdate(prevProps) {
        if (!_.isEqual(prevProps.query, this.props.query)) {
          this.handleLoadData(this.state.dataQuery, this.state.filter);
        }
        if (prevProps.hasChanged.switch != this.props.hasChanged.switch) {
          this.handleLoadData(this.state.dataQuery, this.state.filter);
        }
      }
      handleRowClick = row => {
        const { match, history } = this.props;
        history.push(`${match.url}/${row.id}`);
      };
      handleDelete = data => {
        this.props.onDelete({ ...data });
      };

      handleLoadData = (data, filters = false) => {
        const { query, client } = this.props;
        let _filter = {};

        if (filters && filters.length) {
          filters.forEach(filter => {
            if (query[filter]) {
              const isArray = Array.isArray(JSON.parse(atob(query[filter])));
              _filter[filter] = isArray
                ? (JSON.parse(atob(query[filter])) || []).map(x => x.value)
                : JSON.parse(atob(query[filter]));
            }
          });
        }

        this.setState(
          { dataQuery: data, loading: true, filter: filters },
          () => {
            client
              .query({
                query: data,
                variables: {
                  page: Math.floor(query.offset / query.limit),
                  pagesize: query.limit,
                  search: query.search,
                  ...(query.order &&
                    query.orderBy && {
                      direction: query.order,
                      sortProperties: [query.orderBy]
                    }),
                  filter: { ..._filter }
                }
              })
              .then(result => {
                this.setState({
                  ...result,
                  loading: false
                });
              });
          }
        );
      };

      render() {
        const { ...otherProps } = this.props;

        return (
          <WrappedComponent
            {...otherProps}
            {...this.state}
            onRowClick={this.handleRowClick}
            onDelete={this.handleDelete}
            loadData={this.handleLoadData}
            query={this.props.query}
            onQueryChange={this.props.onQueryChange}
          />
        );
      }
    }
  );
};

export default withTableService;
