import React from "react";
import { compose } from "recompose";
import { withStyles, Typography, Button } from "@material-ui/core";
import { styles } from "./styles";
import renderHTML from "react-render-html";
import { PointsInfo } from "../../parts";
import { connect } from "react-redux";

const Heading = props => {
  const { classes, points, data = {}, config = {} } = props;
  const { literals = {} } = config;

  //functions
  const handleClick = () => {
    if (props.onClick) props.onClick();
  };
  const handleHighscoreClick = () => {
    if (props.onHighscoreClick) props.onHighscoreClick();
  };

  return (
    <div className={classes.headingRoot}>
      <div className={classes.wrapper}>
        <div className={classes.title}>
          <Typography variant="subtitle1" className={classes.preTitle}>
            {data.introTitle || "Take the quiz"}
          </Typography>
          <Typography variant="h1" className={classes.titleFont}>
            {literals.APP_BETTING_GAME_TITLE || "The betting game"}
          </Typography>
        </div>
        <div className={classes.introduction}>
          <Typography variant="body1" className={classes.body} component="div">
            {renderHTML(data.introText || "")}
          </Typography>
        </div>
        <div className={classes.buttons}>
          <Button
            variant="contained"
            color="secondary"
            className={classes.firstButton}
            onClick={handleClick}
          >
            {literals.APP_START_GAME || "Start game!"}
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.secondButton}
            onClick={handleHighscoreClick}
          >
            {literals.APP_HIGH_SCORES || "Highscores"}
          </Button>
        </div>
      </div>
      <div className={classes.points}>
        <PointsInfo points={300} />
      </div>
    </div>
  );
};

export default compose(
  connect(state => ({ config: state.config })),
  withStyles(styles, { name: "AppBettingGameFrontpageHeading" })
)(Heading);
