'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _recompose = require('recompose');

var _styles = require('@material-ui/core/styles');

var _Typography = require('@material-ui/core/Typography');

var _Typography2 = _interopRequireDefault(_Typography);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var styles = function styles(theme) {
	return {
		root: {
			display: 'flex',
			alignItems: 'center'
		},
		outer: {
			width: '10rem',
			height: '.8rem',
			backgroundColor: '#e6e6e6',
			position: 'relative',
			marginRight: '2rem'
		},
		inner: {
			backgroundColor: theme.manakin.secondaryColor[500],
			position: 'absolute',
			height: '.8rem',
			left: 0,
			top: 0,
			transition: 'width .3s'
		},
		content: {
			margin: 0,
			minWidth: '3rem'
		}
	};
};

var PercentageBar = function (_React$PureComponent) {
	_inherits(PercentageBar, _React$PureComponent);

	function PercentageBar() {
		_classCallCheck(this, PercentageBar);

		return _possibleConstructorReturn(this, (PercentageBar.__proto__ || Object.getPrototypeOf(PercentageBar)).apply(this, arguments));
	}

	_createClass(PercentageBar, [{
		key: 'render',
		value: function render() {
			var _props = this.props,
			    classes = _props.classes,
			    _props$percentage = _props.percentage,
			    percentage = _props$percentage === undefined ? 0 : _props$percentage;

			var perc = percentage > 0 ? percentage : 0;

			return _react2.default.createElement(
				'div',
				{ className: classes.root },
				_react2.default.createElement(
					'div',
					{ className: classes.outer },
					_react2.default.createElement('div', { className: classes.inner, style: { width: perc + '%' } })
				),
				_react2.default.createElement(
					_Typography2.default,
					{
						component: 'p',
						variant: 'body1',
						className: classes.content
					},
					perc,
					'%'
				)
			);
		}
	}]);

	return PercentageBar;
}(_react2.default.PureComponent);

exports.default = (0, _recompose.compose)((0, _styles.withStyles)(styles, { name: 'percentageBar' }))(PercentageBar);