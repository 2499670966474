import gql from 'graphql-tag';

export const GQL_FETCH_FACT_CARD = gql`
  query($id: ID!) {
    factCard(id: $id) {
      id
      title
      backgroundImage {
        id
        bynderId
        derivative
        url
      }
      items {
        id
        title
        text
        image {
          id
          bynderId
          derivative
          url
        }
      }
    }
  }
`;

export const GQL_FETCH_PREVIEW = gql`
  query {
    settings {
      settings {
        name
        value
      }
    }
  }
`;
