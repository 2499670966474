import React from 'react';
import { connect } from 'react-redux';
import { compose, withHandlers } from 'recompose';
import ChangePasswordForm from '../ChangePasswordForm';
import Paper from '../Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { showSnackbarMessage } from '@manakin/core/actions';
import { push } from 'redux-first-history';

const styles = theme => ({
  root: {},
  link: {
    marginTop: theme.spacing(2),
    display: 'inline-block'
  }
});

class ConfirmInvitation extends React.Component {
  handleSubmit = event => {
    this.props.onChangePassword({
      ...event,
      token: this.props.match.params.token
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <Paper className={classes.root}>
        <Typography variant="h5" component="h1">
          Bevestig registratie
        </Typography>
        <ChangePasswordForm
          submitLabel="Registratie bevestigen"
          onSubmit={this.handleSubmit}
        />
      </Paper>
    );
  }
}

export default compose(
  connect(
    null,
    dispatch => ({
      processSuccess: () => {
        dispatch(push('/'));
        dispatch(
          showSnackbarMessage({
            text: 'Registratie bevestigd.',
            variant: 'success'
          })
        );
      },
      processFailure: () => {
        dispatch(
          showSnackbarMessage({
            text: 'Registratie mislukt, mogelijk is de link verlopen.',
            variant: 'error'
          })
        );
      }
    })
  ),
  withHandlers({
    onChangePassword: ({ mutate, processSuccess, processFailure }) => event =>
      mutate({
        variables: {
          password: event.password,
          token: event.token
        }
      }).then(result => {
        if (!result.errors) {
          processSuccess();
        } else {
          processFailure();
        }
      })
  }),
  withRouter,
  withStyles(styles, { name: 'AuthenticationConfirmInvitation' })
)(ConfirmInvitation);
