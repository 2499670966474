'use strict';

Object.defineProperty(exports, "__esModule", {
				value: true
});
exports.GQL_FETCH_BEST_TIMES = exports.GQL_FETCH_APP_USER = undefined;

var _templateObject = _taggedTemplateLiteral(['\n\tquery($id: ID!) {\n\t\tappUser(id: $id) {\n            id\n            firstName\n            lastName\n            fullName\n            schoolClasses {\n                id\n                name\n            }\n        }\n    }\n'], ['\n\tquery($id: ID!) {\n\t\tappUser(id: $id) {\n            id\n            firstName\n            lastName\n            fullName\n            schoolClasses {\n                id\n                name\n            }\n        }\n    }\n']),
    _templateObject2 = _taggedTemplateLiteral(['\n    query(\n\t\t$schoolClass: ID!\n\t\t$box: ID!\n\t\t$program: ID!\n\t\t$user: ID!\n\t\t$element: ID!\n    ) {\n        bestTimes(\n\t\t\tschoolClass: $schoolClass\n\t\t\tbox: $box\n\t\t\tprogram: $program\n\t\t\tuser: $user\n\t\t\telement: $element\n        ) {\n\t\t\tid\n\t\t\ttime\n\t\t\tuser {\n\t\t\t\tid\n\t\t\t\tfullName\n\t\t\t\tprofilePicture {\n\t\t\t\t\tid\n\t\t\t\t\turl\n\t\t\t\t\tthumbnailUrl\n\t\t\t\t}\n\t\t\t}\n        }\n    }\n'], ['\n    query(\n\t\t$schoolClass: ID!\n\t\t$box: ID!\n\t\t$program: ID!\n\t\t$user: ID!\n\t\t$element: ID!\n    ) {\n        bestTimes(\n\t\t\tschoolClass: $schoolClass\n\t\t\tbox: $box\n\t\t\tprogram: $program\n\t\t\tuser: $user\n\t\t\telement: $element\n        ) {\n\t\t\tid\n\t\t\ttime\n\t\t\tuser {\n\t\t\t\tid\n\t\t\t\tfullName\n\t\t\t\tprofilePicture {\n\t\t\t\t\tid\n\t\t\t\t\turl\n\t\t\t\t\tthumbnailUrl\n\t\t\t\t}\n\t\t\t}\n        }\n    }\n']);

var _graphqlTag = require('graphql-tag');

var _graphqlTag2 = _interopRequireDefault(_graphqlTag);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteral(strings, raw) { return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var GQL_FETCH_APP_USER = exports.GQL_FETCH_APP_USER = (0, _graphqlTag2.default)(_templateObject);

var GQL_FETCH_BEST_TIMES = exports.GQL_FETCH_BEST_TIMES = (0, _graphqlTag2.default)(_templateObject2);