import React from "react"
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import {withStyles} from "@material-ui/core/styles";

const styles = theme => ({
    listItem: {
        width: "100%",
        paddingLeft: 0
    },
    listItemTitle: {
        color: "#242445",
        fontWeight: 400
    },
    listItemValue: {
        textAlign: "right",
        marginRight: 10,
        color: "#242445",
        fontWeight: 600
    },
    listItemSecondary: {
        color: "#7A7A91"
    }
});

/**
 * List item that contains a title, description and edit button
 */
class InfoSideBarEditField extends React.Component {
    render() {
        const {classes, key, title, description, onEditClick} = this.props;

        return (
            <ListItem key={key} dense button className={classes.listItem}>
                <ListItemText
                    classes={{primary: classes.listItemTitle}}
                    primary={title}/>
                <ListItemText
                    classes={{primary: classes.listItemValue}}
                    primary={description}/>
                <ListItemSecondaryAction>
                    <IconButton
                        onClick={() => onEditClick()}>
                        <EditIcon/>
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
        );
    }
}

export default withStyles(styles)(InfoSideBarEditField);