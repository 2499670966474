'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styles = require('@material-ui/core/styles');

var _Typography = require('@material-ui/core/Typography');

var _Typography2 = _interopRequireDefault(_Typography);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var styles = function styles(theme) {
	return {
		root: {
			textAlign: "center"
		},
		title: {
			fontSize: '2.4rem',
			lineHeight: '4.4rem',
			marginBottom: '6rem',
			position: 'relative',
			letterSpacing: "0.5rem"
		},
		underline: {
			width: '50px',
			height: '2px',
			position: 'absolute',
			bottom: 0,
			left: '50%',
			backgroundColor: theme.manakin.defaultContentColor[500],
			transform: 'translate(-50%, 3rem)'
		}
	};
};

var ContentHeader = function (_React$PureComponent) {
	_inherits(ContentHeader, _React$PureComponent);

	function ContentHeader() {
		_classCallCheck(this, ContentHeader);

		return _possibleConstructorReturn(this, (ContentHeader.__proto__ || Object.getPrototypeOf(ContentHeader)).apply(this, arguments));
	}

	_createClass(ContentHeader, [{
		key: 'render',
		value: function render() {
			var _props = this.props,
			    classes = _props.classes,
			    children = _props.children,
			    title = _props.title;

			return _react2.default.createElement(
				'div',
				{ className: classes.root },
				_react2.default.createElement(
					_Typography2.default,
					{
						align: 'center',
						variant: 'h2',
						classes: { h2: classes.title }
					},
					title,
					_react2.default.createElement('span', { className: classes.underline })
				),
				children
			);
		}
	}]);

	return ContentHeader;
}(_react2.default.PureComponent);

exports.default = (0, _styles.withStyles)(styles, { name: 'AppContentHeader' })(ContentHeader);