import React, { useState, useEffect } from "react";
import { WorkformViewer, ElementOverlay, LoadImage } from "@manakin/app-core";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import FactCardReferral from "./FactCardReferral";

const styles = theme => ({
  root: {
    width: "100%",
    display: "flex",
    padding: "4.8rem 0",
    opacity: 1,
    position: "relative",
    transform: "translateX(20px)",
    transition: "transform .4s, opacity .2s",
    opacity: 0,
    [theme.breakpoints.up("md")]: {
      padding: "50px"
    },
    [theme.breakpoints.up("lg")]: {
      padding: "100px"
    },
    "&>div": {
      backgroundColor: "white",
      width: "100%",
      height: "100%"
    }
  },
  sceneBackground: {
    backgroundColor: theme.manakin.primaryColor[500],
    position: "absolute",
    height: "100%",
    top: 0,
    left: 0,
    width: "100%",
    display: "flex"
  },
  scene: {
    padding: 0,
    position: "static"
  },
  animateIn: {
    transform: "translateX(0)",
    opacity: 1
  },
  animate: {
    opacity: 0
  },
  factCard: {
    height: "100px"
  }
});

const Questions = props => {
  const { classes, workforms = [], options, background = "" } = props;
  //state hooks
  const [step, setStep] = useState(0);
  const [jump, setJump] = useState(false);
  const [currentWorkform, setCurrentWorkform] = useState({});
  const [prevScene, setPrevScene] = useState({});
  const [animate, setAnimate] = useState(false);
  const [animateIn, setAnimateIn] = useState(true);

  //effect hooks

  useEffect(() => {
    if (props.location && props.location.state) {
      handleClick(props.location.state.idx);
    }
  }, []);

  useEffect(() => {
    if (animate) {
      setTimeout(() => {
        setAnimate(false);
        setCurrentWorkform({ ...workforms[step] });
        setTimeout(() => {
          if (workforms[step].type === "Scene") setPrevScene(workforms[step]);
          setAnimateIn(true);
        }, 200);
      }, 300);
    }
  }, [animate]);

  useEffect(() => {
    setCurrentWorkform({ ...workforms[step] });
  }, [workforms]);

  //functions
  const handleNext = data => {
    if (jump || (currentWorkform.split && !data.correct)) handleClick(2);
    else handleClick(1);
  };

  const handleClick = amount => {
    if (currentWorkform.split && amount === 1) setJump(true);
    else setJump(false);
    const idx = amount + step;
    if (workforms[idx]) {
      setStep(idx);
      setAnimate(true);
      setAnimateIn(false);
    } else {
      if (props.onFinish) props.onFinish(workforms);
    }
  };

  return (
    <ElementOverlay
      fullWidth={currentWorkform.type === "Scene" ? true : false}
      background={background}
      controls={true}
    >
      {currentWorkform && currentWorkform.id && (
        <div
          className={classNames(classes.root, {
            [classes.scene]: currentWorkform.type === "Scene",
            [classes.animate]: animate,
            [classes.animateIn]: animateIn
          })}
        >
          {currentWorkform.type === "FactCardReferral" ? (
            <div className={classes.factCard}>
              <WorkformViewer
                type={currentWorkform.type}
                item={currentWorkform}
                onFinish={handleNext}
                options={options}
                customLink={{
                  withId: true,
                  state: { url: props.location.pathname, idx: step + 1 }
                }}
              />
            </div>
          ) : (
              <WorkformViewer
                type={currentWorkform.type}
                item={currentWorkform}
                onFinish={handleNext}
                options={options}
              />
            )}
        </div>
      )}
    </ElementOverlay>
  );
};

export default withStyles(styles)(Questions);
