import React from "react";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import renderHTML from "react-render-html";
import classNames from "classnames";

const styles = theme => ({
  root: {
    backgroundColor: theme.manakin.secondaryColor[500],
    padding: "5rem 0",
    [theme.breakpoints.up("lg")]: {
      padding: "15rem 0rem"
    }
  },
  primary: {
    backgroundColor: theme.manakin.primaryColor[500],
    "& $content": {
      color: theme.palette.getContrastText(theme.manakin.primaryColor[500])
    },
    "& $header": {
      color: theme.palette.getContrastText(theme.manakin.primaryColor[500]),
      "&.smallUnderline": {
        "&:after": {
          backgroundColor: theme.palette.getContrastText(
            theme.manakin.primaryColor[500]
          )
        }
      }
    }
  },
  secondary: {
    backgroundColor: theme.manakin.secondaryColor[500],
    "& $content": {
      color: theme.palette.getContrastText(theme.manakin.secondaryColor[500])
    },
    "& $header": {
      color: theme.palette.getContrastText(theme.manakin.secondaryColor[500]),
      "&.smallUnderline": {
        "&:after": {
          backgroundColor: theme.palette.getContrastText(
            theme.manakin.secondaryColor[500]
          )
        }
      }
    }
  },
  noBG: {
    backgroundColor: "white",
    "& $content": {
      color: "black"
    },
    "& $header": {
      color: "black",
      "&.smallUnderline": {
        "&:after": {
          backgroundColor: "black"
        }
      }
    }
  },
  wrapper: {
    ...theme.manakin.extraLargeWrapper,
    [theme.breakpoints.up("md")]: {
      padding: "0 6rem"
    },
    [theme.breakpoints.up("lg")]: {
      padding: "0 15rem"
    }
  },
  columns: {
    [theme.breakpoints.up("md")]: {
      columns: "2",
      columnGap: "10rem"
    }
  },
  header: {
    ...theme.manakin.workformTitleFont,
    paddingBottom: "3rem",
    marginBottom: "3rem",
    color: theme.palette.getContrastText(theme.manakin.secondaryColor[500]),
    [theme.breakpoints.down("xs")]: {
      fontSize: "3rem",
      lineHeight: "4rem"
    },
    "&.smallUnderline": {
      "&:after": {
        backgroundColor: theme.palette.getContrastText(
          theme.manakin.secondaryColor[500]
        )
      }
    }
  },
  content: {
    color: theme.palette.getContrastText(theme.manakin.secondaryColor[500]),
    "& ul, & ol": {
      listStylePosition: "inside"
    },
    fontSmoothing: "auto",
    color: theme.manakin.defaultContentColor[500],
    fontWeight: 400,
    fontSize: "1.8rem",
    lineHeight: "3.6rem",
    fontFamily: theme.manakin.defaultContentFont,
    "& p": {
      marginBottom: "2rem"
    }
  }
});

const TitleAndText = props => {
  const { data, classes } = props;
  const backgroundColor = data.backgroundColor || null;

  return (
    <div
      className={classNames(
        classes.root,
        { [classes.primary]: backgroundColor == "PRIMARY" },
        { [classes.secondary]: backgroundColor == "SECONDARY" },
        { [classes.noBG]: backgroundColor == "None" }
      )}
    >
      <div className={classes.wrapper}>
        <div className={classNames(classes.columns, classes.content)}>
          <Typography
            component="h2"
            variant="h2"
            className={classNames("smallUnderline", classes.header)}
          >
            {data.workTitle || ""}
          </Typography>
          {renderHTML(data.text || "")}
        </div>
      </div>
    </div>
  );
}

export default compose(withStyles(styles, { name: "AppTitleAndText" }))(
  TitleAndText
);
