import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { Loader, ResumeButton } from '@manakin/app-core';
import { connect } from 'react-redux';
import { compose } from 'recompose';

const styles = theme => ({
  contentColor: {},
  root: {
    width: '100%',
    minHeight: '500px',
    height: 'auto',
    backgroundColor: theme.manakin.primaryColor[500],
    padding: '15rem 0 50rem',
    '& $contentColor': {
      color: theme.palette.getContrastText(theme.manakin.primaryColor[500])
    },
    [theme.breakpoints.up('md')]: {
      padding: '18rem 0 50rem'
    }
  },
  wrapper: {
    ...theme.manakin.defaultWrapper
  },
  content: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '2.3rem'
    }
  },
  title: {
    [theme.breakpoints.up('md')]: {
      maxWidth: '410px',
      width: '100%'
    }
  },
  heading: {
    hyphens: 'auto',
    wordBreak: 'break-word'
  },
  body: {
    marginBottom: '2.8rem'
  },
  introduction: {
    [theme.breakpoints.up('md')]: {
      maxWidth: '450px',
      width: '100%'
    }
  }
});

const Header = props => {
  const { classes, user, data, loading, config = {} } = props;
  const { literals = {}, pages = {} } = config;

  const appDashboardConfig = (config.pages && config.pages.appDashboard) || {};
  const showName =
    appDashboardConfig.showName != undefined
      ? appDashboardConfig.showName
      : true;

  return (
    <React.Fragment>
      {!loading ? (
        <div className={classes.root}>
          <div className={classes.wrapper}>
            <div className={classes.content}>
              <div className={classes.title}>
                <Typography
                  component="h1"
                  variant="h2"
                  className={classes.contentColor}
                  classes={{ h2: classes.heading }}
                >
                  {literals.APP_ALT_DASHBOARD_TITLE ||
                    `Welkom ${(showName && user.firstName) || ''}`}
                </Typography>
              </div>
              <div className={classes.introduction}>
                {data.description && (
                  <Typography
                    component="p"
                    variant="body2"
                    classes={{ body2: classes.body }}
                    className={classes.contentColor}
                  >
                    {data.description}
                  </Typography>
                )}
              </div>
            </div>
            {/* <ResumeButton /> */}
          </div>
        </div>
      ) : (
          <Loader />
        )}
    </React.Fragment>
  );
};

export default compose(
  withStyles(styles, { name: 'AppDashboardHeader' }),
  connect(({ config }) => ({ config }))
)(Header);
