'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GQL_FETCH_GLOBAL_SETTINGS = undefined;

var _templateObject = _taggedTemplateLiteral(['\n  query {\n    settings {\n      settings {\n        name\n        value\n        file {\n          id\n          bynderId\n          derivative\n          url\n          thumbnailUrl\n          name\n        }\n      }\n    }\n  }\n'], ['\n  query {\n    settings {\n      settings {\n        name\n        value\n        file {\n          id\n          bynderId\n          derivative\n          url\n          thumbnailUrl\n          name\n        }\n      }\n    }\n  }\n']);

var _graphqlTag = require('graphql-tag');

var _graphqlTag2 = _interopRequireDefault(_graphqlTag);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteral(strings, raw) { return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var GQL_FETCH_GLOBAL_SETTINGS = exports.GQL_FETCH_GLOBAL_SETTINGS = (0, _graphqlTag2.default)(_templateObject);