import React from "react";
import {withStyles} from "@material-ui/core/styles";
import {
    TextField
} from "@manakin/core";
import withForm from "../form/withForm";
import {compose} from "recompose";

const styles = theme => ({
    root: {}
});

const formBundle = {
    search: {}
};

class AssetViewerSearchBar extends React.PureComponent {
    handleSearch = (value) => {
        const {onSubmit} = this.props;
        onSubmit(value);
    };

    render() {
        const {classes, form, initialValue} = this.props;

        return (
            <div className={classes.root}>
                <TextField
                    name="search"
                    label="Voer een zoekterm in"
                    placeholder="Voer een zoekterm in"
                    initialValue={initialValue}
                    form={form}
                    onEnterPressed={this.handleSearch}
                />
            </div>
        );
    }
}

export default compose(
    withForm(formBundle),
    withStyles(styles)
)(AssetViewerSearchBar);
