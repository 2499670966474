import React from "react";
import CoreAppMenu from "@manakin/app-core/AppMenu";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { withStyles } from "@material-ui/core/styles";
import { NavLink } from "react-router-dom";
import { compose } from "recompose";
import { connect } from "react-redux";

const styles = theme => ({
  root: {},
  link: {
    color: theme.manakin.mainMenuColor[500],
    textDecoration: "none",
    fontSize: "1.8rem",
    [theme.breakpoints.up("md")]: {
      fontSize: "3rem"
    }
  },
  activeLink: {
    color: theme.manakin.mainMenuColor[500],
    borderBottom: ".2rem solid"
  },
  listItemRoot: {
    paddingTop: theme.manakin.mainMenuListItemPaddingTop,
    paddingBottom: theme.manakin.mainMenuListItemPaddingBottom,
    paddingLeft: "0",
    paddingRight: "0"
  },
  listItemTextRoot: {
    padding: "0"
  },
  privacyRoot: {
    position: "absolute",
    left: "3rem",
    bottom: 0,
    [theme.breakpoints.up("md")]: {
      left: "5rem"
    }
  },
  privacy: {
    color: "white",
    cursor: "pointer",
    "&:hover": {
      color: theme.manakin.defaultContentHover[500]
    }
  }
});

const AppMenu = props => {
  const { classes, config = {} } = props;
  const { literals = {} } = config;

  return (
    <CoreAppMenu
      renderGeneral={() => (
        <div>
          <List>
            <ListItem classes={{ root: classes.listItemRoot }}>
              <NavLink
                className={classes.link}
                activeClassName={classes.activeLink}
                to={"/"}
                exact
              >
                <ListItemText
                  classes={{
                    primary: classes.link,
                    root: classes.listItemTextRoot
                  }}
                  primary={literals.APP_PROGRAMS || "Lesprogramma's"}
                />
              </NavLink>
            </ListItem>
            <ListItem classes={{ root: classes.listItemRoot }}>
              <NavLink
                className={classes.link}
                activeClassName={classes.activeLink}
                to={"/factcards"}
                exact
              >
                <ListItemText
                  classes={{
                    primary: classes.link,
                    root: classes.listItemTextRoot
                  }}
                  primary={literals.APP_FACTCARDS || "Factcards"}
                />
              </NavLink>
            </ListItem>
            <ListItem classes={{ root: classes.listItemRoot }}>
              <NavLink
                className={classes.link}
                activeClassName={classes.activeLink}
                to={"/faq"}
                exact
              >
                <ListItemText
                  classes={{
                    primary: classes.link,
                    root: classes.listItemTextRoot
                  }}
                  primary={literals.APP_FAQ || "Veelgestelde vragen"}
                />
              </NavLink>
            </ListItem>
          </List>
        </div>
      )}
    />
  );
};

export default compose(
  connect(state => ({
    config: state.config
  })),
  withStyles(styles)
)(AppMenu);
