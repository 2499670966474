import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  LayoutButtons,
  Boxes,
  AccessControl,
  ResumeButton
} from '@manakin/app-core';
import classNames from 'classnames';
import ShuffleIcon from '@material-ui/icons/Shuffle';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'recompose';

const styles = theme => ({
  root: {
    marginTop: '-51rem',
    backgroundColor: 'transparent',
    transition: 'background-color .3s'
  },
  wrapper: {
    ...theme.manakin.defaultWrapper
  },
  listView: {
    backgroundColor: theme.manakin.primaryColor[500]
  },
  editLink: {
    cursor: 'pointer',
    fontFamily: theme.manakin.defaultTitleFont,
    fontSize: '1.4rem',
    fontWeight: 'bold',
    letterSpacing: '.4rem'
  },
  wrapper: {
    ...theme.manakin.defaultWrapper,
    textAlign: 'right',
    paddingBottom: '3rem',
    [theme.breakpoints.up('md')]: {
      paddingBottom: '4.8rem'
    }
  },
  shuffle: {
    marginRight: '1.1rem',
    top: '.6rem',
    position: 'relative'
  },
  link: {
    textDecoration: 'none',
    color: theme.manakin.defaultContentColor[500],
    transition: 'color .2s',
    '&:hover': {
      color: theme.manakin.defaultContentHover[500]
    }
  },
  footerLogo: {}
});

class BoxLayout extends React.PureComponent {
  render() {
    const {
      classes,
      listView,
      data,
      loading,
      config = {},
      percentage = {},
      listViewOnly = false,
      user = {},
      resume = null,
      option = false
    } = this.props;

    return (
      <div
        className={classNames(classes.root, {
          [classes.listView]: listView || listViewOnly
        })}
      >
        <AccessControl role={['SCHOOL_MANAGER', 'TEACHER']} subRole={['GROUPS_WRITE']}>
          <div className={classes.wrapper}>
            <Link to={'/teacherProgram'} className={classes.link}>
              <span className={classes.shuffle}>
                <ShuffleIcon />
              </span>
              <span className={classes.editLink}>Les Aanpassen</span>
            </Link>
          </div>
        </AccessControl>
        {resume && (
          <div className={classes.wrapper}>
            <ResumeButton {...resume} />
          </div>
        )}
        {!listViewOnly && <LayoutButtons config={config} />}
        <Boxes
          data={data}
          percentage={percentage}
          loading={loading}
          user={user}
          resume={resume}
          listViewOnly={listViewOnly}
          option={option}
        />
        {config.pages &&
          config.pages.appLogin &&
          config.pages.appLogin.footerLogo && (
            <div className={classes.footerLogo} />
          )}
      </div>
    );
  }
}

export default compose(
  withStyles(styles, { name: 'AppBoxLayout' }),
  connect(({ config }) => ({ config }))
)(BoxLayout);
