'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GQL_FETCH_CMS_FACT_CARD = exports.GQL_FETCH_BOX_RESULTS = exports.GQL_FETCH_FACTCARDREFERRAL = exports.GQL_FETCH_MASTERCLASS = undefined;

var _templateObject = _taggedTemplateLiteral(['\n  query($id: ID!) {\n    masterClass(id: $id) {\n      id\n      factCards {\n        id\n        image {\n          id\n          url\n          thumbnailUrl\n        }\n      }\n    }\n  }\n'], ['\n  query($id: ID!) {\n    masterClass(id: $id) {\n      id\n      factCards {\n        id\n        image {\n          id\n          url\n          thumbnailUrl\n        }\n      }\n    }\n  }\n']),
    _templateObject2 = _taggedTemplateLiteral(['\n  query($id: ID!) {\n    factCardReferral(id: $id) {\n      id\n      referrals {\n        id\n        factCardId\n        image {\n          id\n          url\n          thumbnailUrl\n        }\n      }\n    }\n  }\n'], ['\n  query($id: ID!) {\n    factCardReferral(id: $id) {\n      id\n      referrals {\n        id\n        factCardId\n        image {\n          id\n          url\n          thumbnailUrl\n        }\n      }\n    }\n  }\n']),
    _templateObject3 = _taggedTemplateLiteral(['\n  query($program: ID!, $user: ID!) {\n    boxResults(program: $program, user: $user) {\n      user {\n        id\n      }\n      id\n      lastModifiedOn\n      finishedOn\n      finished\n      rating\n      box {\n        id\n      }\n      text\n    }\n  }\n'], ['\n  query($program: ID!, $user: ID!) {\n    boxResults(program: $program, user: $user) {\n      user {\n        id\n      }\n      id\n      lastModifiedOn\n      finishedOn\n      finished\n      rating\n      box {\n        id\n      }\n      text\n    }\n  }\n']),
    _templateObject4 = _taggedTemplateLiteral(['\n  query($id: ID!) {\n    factCard(id: $id) {\n      id\n      title\n      image {\n        id\n        url\n      }\n      file {\n        id\n        url\n      }\n    }\n  }\n'], ['\n  query($id: ID!) {\n    factCard(id: $id) {\n      id\n      title\n      image {\n        id\n        url\n      }\n      file {\n        id\n        url\n      }\n    }\n  }\n']);

var _graphqlTag = require('graphql-tag');

var _graphqlTag2 = _interopRequireDefault(_graphqlTag);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteral(strings, raw) { return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var GQL_FETCH_MASTERCLASS = exports.GQL_FETCH_MASTERCLASS = (0, _graphqlTag2.default)(_templateObject);

var GQL_FETCH_FACTCARDREFERRAL = exports.GQL_FETCH_FACTCARDREFERRAL = (0, _graphqlTag2.default)(_templateObject2);

var GQL_FETCH_BOX_RESULTS = exports.GQL_FETCH_BOX_RESULTS = (0, _graphqlTag2.default)(_templateObject3);

var GQL_FETCH_CMS_FACT_CARD = exports.GQL_FETCH_CMS_FACT_CARD = (0, _graphqlTag2.default)(_templateObject4);