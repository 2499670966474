export const styles = theme => ({
  root: {
    display: "flex",
    flexFlow: "row wrap"
  },
  referralRoot: {
    display: "flex",
    flexFlow: "row wrap",
    flex: "1 1 450px",
    backgroundColor: theme.manakin.secondaryColor[500],
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      flexFlow: "column nowrap"
    }
  },
  content: {
    padding: "3rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    flex: "1 0 calc(100% - 250px)",
    flexFlow: "row wrap",
    [theme.breakpoints.up("md")]: {
      padding: "3rem 5rem",
      flexFlow: "row nowrap"
    }
  },
  textContent: {
    [theme.breakpoints.up("md")]: {
      flex: 1,
      marginRight: theme.spacing(8)
    }
  },
  multi: {
    "& $body": {
      marginBottom: "1.2rem"
    },
    "& $content": {
      alignItems: "flex-start",
      flexFlow: "column nowrap"
    },
    "& $image": {
      minHeight: "240px",
      flex: "0 1 240px",
      [theme.breakpoints.down("md")]: {
        flex: "1 1 100%"
      }
    },
    "& $title": {
      fontSize: "2.2rem",
      lineHeight: "150%",
      marginBottom: "1.4rem"
    }
  },
  image: {
    backgroundColor: theme.manakin.primaryColor[500],
    position: "relative",
    minHeight: "150px",
    flex: "0 1 150px",
    [theme.breakpoints.down("md")]: {
      flex: "1 1 100%"
    }
  },
  nextButton: {
    marginTop: "3rem"
  },
  body: {
    [theme.breakpoints.up("md")]: {
      margin: 0
    }
  },
  title: {
    fontSize: "28px",
    lineHeight: "normal",
    marginBottom: theme.spacing(2)
  },
  imageSrc: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    fontFamily: "'object-fit: cover'",
    position: "absolute",
    left: "0",
    right: "0"
  },
  button: {}
});
