"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _useGetConfig = require("./useGetConfig");

Object.defineProperty(exports, "useGetConfig", {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_useGetConfig).default;
  }
});

var _useSetting = require("./useSetting");

Object.defineProperty(exports, "useSetting", {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_useSetting).default;
  }
});

var _useArrayToObject = require("./useArrayToObject");

Object.defineProperty(exports, "useArrayToObject", {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_useArrayToObject).default;
  }
});

var _useGetBoxResult = require("./useGetBoxResult");

Object.defineProperty(exports, "useGetBoxResult", {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_useGetBoxResult).default;
  }
});

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }