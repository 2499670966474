'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GQL_FETCH_ORDERS = undefined;

var _templateObject = _taggedTemplateLiteral(['\n  query($filter: IdealOrderFilter) {\n    idealOrders(filter: $filter) {\n      idealOrders {\n        orderID\n        userID\n        invoiceID\n        invoiceNumber\n        invoicePdfUrl\n        name\n        orderDate\n        totalPrice\n        idealStatus\n      }\n      count\n    }\n  }\n'], ['\n  query($filter: IdealOrderFilter) {\n    idealOrders(filter: $filter) {\n      idealOrders {\n        orderID\n        userID\n        invoiceID\n        invoiceNumber\n        invoicePdfUrl\n        name\n        orderDate\n        totalPrice\n        idealStatus\n      }\n      count\n    }\n  }\n']);

var _graphqlTag = require('graphql-tag');

var _graphqlTag2 = _interopRequireDefault(_graphqlTag);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteral(strings, raw) { return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var GQL_FETCH_ORDERS = exports.GQL_FETCH_ORDERS = (0, _graphqlTag2.default)(_templateObject);