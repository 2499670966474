"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var styles = exports.styles = function styles(theme) {
  var _boxRoot, _boxDescription;

  return {
    root: {
      position: "relative"
    },
    boxRoot: (_boxRoot = {
      height: "44rem",
      width: "100%",
      marginBottom: "2rem",
      padding: "4rem",
      position: "relative",
      transition: "height .3s, background-color .3s",
      cursor: "pointer",
      overflow: "hidden",
      opacity: 0
    }, _defineProperty(_boxRoot, "transition", "opacity .3s, transform .3s"), _defineProperty(_boxRoot, "transform", "translateX(-10rem)"), _defineProperty(_boxRoot, "flexWrap", "wrap"), _defineProperty(_boxRoot, "display", "flex"), _defineProperty(_boxRoot, "flexFlow", "column"), _defineProperty(_boxRoot, "justifyContent", "center"), _defineProperty(_boxRoot, theme.breakpoints.up("md"), {
      marginBottom: "5rem",
      height: "50rem",
      padding: "0 15rem"
    }), _defineProperty(_boxRoot, "&:hover", {
      "& $backgroundImage": {
        transform: "scale(1.1)"
      },
      "& $boxDescription": {
        maxHeight: "300px",
        transform: "none",
        transition: "all .2s"
      },
      "& $boxSubtitle": {
        visibility: "hidden",
        transition: "all .1s"
      }
    }), _boxRoot),
    show: {
      opacity: 1,
      transform: "translateX(0rem)"
    },
    link: {
      textDecoration: "none"
    },
    backgroundImage: {
      position: "absolute",
      zIndex: "0",
      height: "100%",
      width: "100%",
      left: "0",
      top: "0",
      backgroundSize: "cover",
      backgroundPosition: "center",
      transition: "width .3s, transform .3s, background-color .3s"
    },
    themeIcon: {
      backgroundPosition: "center",
      width: "calc(100% - 30px)",
      height: "calc(100% - 30px)",
      top: "50%",
      left: "15px",
      position: "absolute",
      transform: "translateY(-50%)",
      borderRadius: "100%",
      border: "1px solid " + theme.manakin.defaultBorderColor[500]
    },
    boxName: _defineProperty({
      position: "relative",
      display: "inline-block",
      fontFamily: theme.manakin.defaultTitleFont,
      color: theme.manakin.defaultBoxColor[500],
      fontSize: "2.4rem",
      fontWeight: "bold",
      letterSpacing: ".4rem",
      lineHeight: "2.6rem",
      maxWidth: "30rem",
      transition: "padding .3s",
      textDecoration: "none",
      hyphens: "auto",
      wordBreak: "break-word",
      zIndex: 2
    }, theme.breakpoints.up("md"), {
      fontSize: "3.8rem",
      lineHeight: "5.2rem",
      maxWidth: "70%"
    }),
    boxSubtitle: _defineProperty({
      fontFamily: theme.manakin.secondaryTitleFont,
      fontSize: "2rem",
      lineHeight: "2rem",
      position: "relative",
      display: "inline-block",
      color: theme.manakin.defaultBoxColor[500],
      maxWidth: "20rem",
      zIndex: 2,
      transition: "padding .3s",
      textDecoration: "none",
      marginBottom: "1rem"
    }, theme.breakpoints.up("md"), {
      maxWidth: "100%"
    }),
    boxDescription: (_boxDescription = {
      zIndex: "1",
      position: "relative",
      marginTop: "2rem",
      color: theme.manakin.defaultBoxColor[500],
      lineHeight: "2",
      maxHeight: 0,
      overflow: "hidden",
      transition: "all .2s ease"
    }, _defineProperty(_boxDescription, "zIndex", 2), _defineProperty(_boxDescription, theme.breakpoints.up("md"), {
      maxWidth: "50%"
    }), _boxDescription),
    hasLabel: {},
    listView: _defineProperty({
      backgroundColor: "white",
      display: "flex",
      minHeight: "6.8rem",
      height: "auto",
      padding: "1rem 1rem 1rem 9rem",
      alignItems: "center",
      marginBottom: "1rem",
      "& $backgroundImage": _defineProperty({
        width: "7rem"
      }, theme.breakpoints.up("md"), {
        width: "10rem"
      }),
      "& $boxName": _defineProperty({
        color: "black",
        fontSize: "1.4rem",
        maxWidth: "none",
        lineHeight: "2rem",
        letterSpacing: ".17rem",
        transition: "color .2s"
      }, theme.breakpoints.up("md"), {
        fontSize: "1.8rem",
        letterSpacing: ".2rem"
      }),
      "& $hasLabel": _defineProperty({
        marginTop: "-20px"
      }, theme.breakpoints.up("md"), {
        marginTop: 0
      }),
      "& $boxSubtitle": {
        display: "none"
      },
      "& $boxDescription": {
        display: "none"
      },
      "&:hover": {
        "&:hover": {
          borderColor: theme.manakin.defaultBorderHoverColor[500],
          "& $boxName": {
            color: theme.manakin.defaultContentHover[500]
          }
        }
      }
    }, theme.breakpoints.up("md"), {
      height: "10rem",
      padding: "4rem 4rem 4rem 14rem",
      marginBottom: "1.8rem"
    }),
    labelAndlist: _defineProperty({
      padding: "1rem 2rem"
    }, theme.breakpoints.up("md"), {
      padding: "4rem 4rem 4rem 21rem"
    }),
    primaryColor: {
      color: "white"
    },
    iconRoot: {
      width: "auto",
      height: "auto",
      Zindex: 2
    },
    doneIcon: {
      height: "1.5rem",
      width: "1.5rem",
      color: theme.palette.getContrastText(theme.palette.secondary[500])
    },
    done: _extends(_defineProperty({
      position: "absolute",
      width: "30px",
      height: "30px",
      borderRadius: "100%",
      backgroundColor: theme.palette.secondary[500],
      zIndex: "2",
      right: "20px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    }, theme.breakpoints.up("md"), {
      width: "60px",
      height: "60px",
      top: "20px"
    }), theme.manakin.elementCardDone),
    boxCardLabel: _defineProperty({
      position: "absolute",
      top: "46px",
      left: 0,
      zIndex: 1,
      padding: "5px 32px 24px",
      backgroundColor: theme.manakin.secondaryColor[500],
      color: theme.palette.getContrastText(theme.manakin.secondaryColor[500]),
      borderRadius: "0 32px 32px 0",
      fontWeight: 600,
      fontSize: "13px",
      lineHeight: "1",
      letterSpacing: "2px",
      textTransform: "uppercase"
    }, theme.breakpoints.up("md"), {
      padding: "24px 32px",
      top: "20px"
    }),
    percentage: _defineProperty({
      color: theme.manakin.defaultContentColor[500],
      backgroundColor: "white",
      width: "4.2rem",
      height: "4.2rem",
      position: "absolute",
      top: "1.3rem",
      right: "2rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "100%",
      fontFamily: theme.manakin.defaultTitleFont,
      fontSize: "1rem",
      fontWeight: "bold",
      letterSpacing: "2.4px"
    }, theme.breakpoints.up("md"), {
      width: "6.2rem",
      height: "6.2rem",
      fontSize: "1.2rem",
      top: "2rem"
    }),
    percentageDone: {
      backgroundColor: theme.palette.secondary[500],
      color: theme.palette.getContrastText(theme.palette.secondary[500])
    },
    percentageDoneIcon: {},
    longArrow: {
      "& .arrow": {}
    }
  };
};