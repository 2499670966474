import React from "react";
import FactCards from "./FactCards";
import { withApollo } from "react-apollo";
import { compose } from "recompose";
import { GQL_FETCH_FACT_CARD } from "./graphql";
import { Loader } from "@manakin/app-core";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { setBackButton } from "@manakin/app-core/AppBar/actions";

const styles = theme => ({});

class BoxCardsContainer extends React.PureComponent {
  state = {
    factCards: [],
    loading: true
  };

  componentDidMount() {
    const { location = {}, client } = this.props;
    this.props.setBackButton({
      show: true,
      location: location.pathname || "/boxCards"
    });
    if (location.state && location.state.length) {
      const { state } = location;
      const arr = [...new Set(state)];
      let count = 0;
      arr.forEach(item => {
        client
          .query({
            query: GQL_FETCH_FACT_CARD,
            variables: {
              id: item
            }
          })
          .then(result => {
            count++;
            if (!result.errors) {
              this.setState(prevState => ({
                factCards: [...prevState.factCards, result.data.factCard],
                loading: count < arr.length
              }));
            }
          });
      });
    }
  }

  render() {
    const { classes } = this.props;
    const { loading, factCards } = this.state;

    if (loading) {
      return (
        <div className={classes.loader}>
          <Loader />
        </div>
      );
    }
    return (
      <div>
        <FactCards FactCardsData={{ factCards: factCards }} fromBox={true} />
      </div>
    );
  }
}

export default compose(
  withApollo,
  withStyles(styles),
  connect(null, dispatch => ({
    setBackButton: data => dispatch(setBackButton(data))
  }))
)(BoxCardsContainer);
