import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const styles = theme => ({
  root: {
    padding: '8rem 0 4rem',
    opacity: 0,
    transform: 'translateX(-30px)',
    transition: 'opacity .3s .3s, transform .3s .3s',
    [theme.breakpoints.up('md')]: {
      padding: '18rem 0 8rem'
    },
    ...theme.manakin.boxOverviewStyle
  },
  wrapper: {
    ...theme.manakin.smallWrapper
  },
  body: {},
  show: {
    transform: 'translateX(0)',
    opacity: 1
  },
  content: {
    textAlign: 'center'
  },
  heading: {
    wordBreak: 'break-word',
    hyphens: 'auto'
  }
});

class Header extends React.PureComponent {
  _isMounted = false;
  componentWillUnmount() {
    this._isMounted = false;
  }

  state = {
    show: false
  };

  componentDidMount() {
    this._isMounted = true;
    setTimeout(() => {
      if (this._isMounted) {
        this.setState({ show: true });
      }
    }, 500);
  }

  render() {
    const { classes, description = '', title = 'Box' } = this.props;
    const { show } = this.state;

    return (
      <div className={classNames(classes.root, { [classes.show]: show })}>
        <div className={classes.wrapper}>
          <div className={classes.content}>
            <Typography
              component="h1"
              variant="h2"
              className="underline"
              classes={{ h2: classes.heading }}
            >
              {title}
            </Typography>
            <Typography
              component="p"
              variant="body1"
              classes={{ body1: classes.body }}
            >
              {description}
            </Typography>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles, { name: 'AppBoxHeader' })(Header);
