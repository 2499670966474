import React from "react";
import FactCard from "./FactCard";
import FactCardTitles from "./FactCardTitles";
import { GQL_FETCH_FACT_CARD } from "./graphql/graphql";
import { compose } from "recompose";
import { graphql } from "react-apollo";
import { ElementOverlay } from "@manakin/app-core";

const FactCardContainer = props => {
  const { data } = props;
  const { factCard, loading } = data;
  let noImages = true;

  factCard &&
    factCard.items.forEach(item => {
      if (item.image != null) noImages = false;
    });

  //functions
  const handleCloseClick = () => {
    if (props.location && props.location.state && props.location.state.url) {
      props.history.push({
        pathname: `${props.location.state.url}`,
        state: { idx: props.location.state.idx }
      });
    } else {
      props.history.goBack();
    }
  };

  if (loading) {
    return <div />;
  } else if (noImages) {
    return (
      <ElementOverlay
        onClick={handleCloseClick}
        controls={false}
        customControls={true}
      >
        <FactCardTitles {...factCard} />
      </ElementOverlay>
    );
  } else {
    return (
      <ElementOverlay
        onClick={handleCloseClick}
        controls={false}
        customControls={true}
      >
        <FactCard {...factCard} />
      </ElementOverlay>
    );
  }
};

export default compose(
  graphql(GQL_FETCH_FACT_CARD, {
    options: props => ({ variables: { id: props.match.params.factCardId } })
  })
)(FactCardContainer);
