import React from "react";
import { TextField, RadioField } from "@manakin/core";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { compose } from "recompose";

const styles = theme => ({
  root: {
    width: "100%"
  },
  groupElements: {
    "@media (min-width: 700px)": {
      display: "flex",
      justifyContent: "space-between",
      "&>*": {
        flex: "0 0 auto",
        width: "300px"
      }
    }
  },
  paragraph: {
    textAlign: "center",
    width: "100%",
    margin: "0 auto 3em",
    maxWidth: "560px"
  },
  termsOfService: {
    marginTop: theme.spacing(4),
    "& a": {
      color: "inherit"
    }
  },
  heading: {}
});

const AuthData = props => {
  const { classes, form, config } = props;
  const { fields: fieldsData } = form;

  return (
    <div className={classes.root}>
      <form>
        <Typography variant="h2" className={classes.heading}>
          E-mail &<br />
          Wachtwoord
        </Typography>
        <Typography
          classes={{ body1: classes.paragraph }}
          variant="body1"
          gutterBottom
        >
          {config &&
            config.literals &&
            config.literals.APP_REGISTRATION_EMAIL_PASSWORD_MESSAGE}
        </Typography>
        <div className={classes.groupElements}>
          <TextField
            form={form}
            name="email"
            label="E-mailadres"
            initialValue={fieldsData.email}
          />
          <TextField
            form={form}
            name="password"
            label="Wachtwoord"
            initialValue={fieldsData.password}
            type="password"
          />
        </div>
        <div className={classes.groupElements}>
          <TextField
            form={form}
            name="secondEmail"
            label="Herhaal e-mailadres"
            initialValue={fieldsData.secondEmail}
          />
          <TextField
            form={form}
            name="secondPassword"
            label="Herhaal wachtwoord"
            initialValue={fieldsData.secondPassword}
            type="password"
          />
        </div>
        {/* <div className={classes.termsOfService}>
        <RadioField
          form={form}
          fullWidth={true}
          fullLabelWidth={true}
          legendName="Algemene voorwaarden"
          name="acceptTerms"
          options={[
            {
              label: (
                <span>
                  Ik ga akkoord met de{' '}
                  {/* <a target="_blank" href={theme.manakin.terms || '#'}>
                    algemene voorwaarden
                  </a> 
                </span>
              ),
              value: 'ACCEPT'
            }
          ]}
          initialValue={fieldsData.acceptTerms}
        />
      </div> */}
      </form>
    </div>
  );
};

export default compose(withStyles(styles, { name: "AppRegistrationAuthData" }))(
  AuthData
);
