import React, { useEffect, useState } from "react";
import { withStyles, Typography } from "@material-ui/core";
import { LoadImage, Loader } from "@manakin/app-core";
import { getProgram } from "@manakin/app-core/ProgramsDropdown/selectors";
import { getAppUser } from "@manakin/authentication/selectors";
import { compose } from "recompose";
import { connect } from "react-redux";
import { GQL_FETCH_ELEMENT_RESULTS } from "../graphql";
import { graphql } from "react-apollo";
import { useGetBoxResult } from "@manakin/hooks";
import classNames from "classnames";

const styles = theme => ({
  root: {
    backgroundColor: "white",
    position: "relative",
    paddingLeft: "7.5rem",
    width: "100%",
    minHeight: "5rem",
    display: "flex",
    alignItems: "center",
    paddingRight: "7rem",
    [theme.breakpoints.up("md")]: {
      paddingLeft: "12.5rem",
      paddingRight: "9rem",
      minHeight: "10rem"
    }
  },
  image: {
    position: "absolute",
    height: "100%",
    width: "5rem",
    left: 0,
    top: 0,
    [theme.breakpoints.up("md")]: {
      width: "10rem"
    }
  },
  body: {
    margin: 0
  },
  score: {
    position: "absolute",
    margin: 0,
    right: "3.6rem",
    top: "50%",
    transform: "translateY(-50%)",
    fontWeight: "500"
  },
  active: {
    color: theme.palette.primary[500]
  }
});

const BoxInfo = props => {
  const { classes, box = {}, data } = props;
  const { image = {} } = box;
  const { loading } = data;

  //state hooks
  const [percentage, setPercentage] = useState(0);

  //effect hooks
  useEffect(() => {
    setPercentage(useGetBoxResult(data, box));
  }, [loading]);

  useEffect(() => {
    if (percentage >= 100) {
      if (props.onSetPoints) props.onSetPoints(50);
    }
  }, [percentage]);

  if (loading) {
    return <Loader fullScreen={true} />;
  }
  return (
    <div className={classes.root}>
      {image && image.url && (
        <div className={classes.image}>
          <LoadImage
            placeholder={image.thumbnailUrl || image.url}
            src={image.url}
          />
        </div>
      )}
      <Typography variant="body1" className={classes.body}>
        {box.name || ""}
      </Typography>
      <Typography
        variant="body1"
        className={classNames(classes.score, {
          [classes.active]: percentage >= 100
        })}
      >
        {percentage >= 100 ? "+50 pts" : "0 pts"}
      </Typography>
    </div>
  );
};

export default compose(
  connect(state => ({
    program: getProgram(state),
    appUser: getAppUser(state)
  })),
  withStyles(styles, { name: "AppBoxInfo" }),
  graphql(GQL_FETCH_ELEMENT_RESULTS, {
    options: props => ({
      variables: {
        program: props.program,
        user: props.appUser && props.appUser.id,
        box: props.box.id
      }
    })
  })
)(BoxInfo);
