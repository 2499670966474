import React, { useState, useEffect } from 'react';
import { getAppUser } from '@manakin/authentication/selectors';
import { getGroup } from '@manakin/app-core/GroupsDropdown/selectors';
import {
  GQL_FETCH_SCHOOL,
  GQL_FETCH_GROUPS,
  GQL_FETCH_SCHOOL_CLASS,
  GQL_FETCH_ELEMENT_RESULTS
} from './graphql';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core';
import { styles } from './styles';
import { graphql } from 'react-apollo';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import ArrowRightIcon from '@material-ui/icons/ArrowRightAlt';
import { Manager, Teacher } from './statisticsContent';
import { withApollo } from 'react-apollo';

const StatisticsBox = props => {
  const { classes, config = {}, data, MANAGERGROUPS, role } = props;
  const { loading, groups = {} } = data;
  const { loading: loadingManagers = {}, groups: managerGroups = {} } = MANAGERGROUPS;
  const { literals = {} } = config;

  //state hooks
  const [schoolStudents, setSchoolStudents] = useState([]);
  const [classStudents, setClassStudents] = useState([]);
  const [teachers, setTeachers] = useState(0);
  const [schoolClasses, setSchoolclasses] = useState([]);
  const [sessionAverage, setSessionAverage] = useState(0);

  //effect hooks
  useEffect(() => {
    if (!loading && managerGroups.groups) {
      let _teachers = 0;
      let _classStudents = [];
      let _schoolClasses = [];
      let _schoolStudents = [];

      if (role === 'SCHOOL_MANAGER') {
        managerGroups.groups.map(group => {
          if (group.type == 'School') {
            loadSchool(group.id).then(result => {
              if (result.data && result.data.school) {
                const { school = {} } = result.data;
                const { classes = [], students = [] } = school;

                if (result.data.school.students) {
                  const arr = []
                  const newStudents = []

                  classes.forEach(item => {
                    item.students && item.students.forEach(student => {
                      if (students && students.some(i => i.id === student.id)) {
                        arr.push({ id: student.id })
                      }
                    })
                  })

                  students.forEach(s => {
                    if (!arr.some(i => i.id == s.id)) {
                      newStudents.push(s)
                    }
                  })

                  _schoolStudents = [...newStudents];
                }
                if (result.data.school.classes) {
                  _schoolClasses = [
                    ..._schoolClasses,
                    ...result.data.school.classes
                  ];
                  result.data.school.classes.forEach(item => {
                    if (item.students) {
                      _classStudents = [..._classStudents, ...item.students];
                    }
                  });
                }
                if (result.data.school.managers) {
                  result.data.school.managers.forEach(user => {
                    if (user.roles.some(item => item.name === 'TEACHER')) {
                      _teachers++;
                    }
                  });
                }
              }
              setSchoolStudents(_schoolStudents);
              setSchoolclasses(_schoolClasses);
              setTeachers(_teachers);
              setClassStudents(_classStudents);
            });
          }
        })
      }
    }
  }, [loadingManagers])

  useEffect(() => {
    if (!loading && groups.groups) {
      let _classStudents = [];
      let _sessionTotal = 0;
      let _schoolClasses = [];
      if (role === 'TEACHER') {
        groups.groups.map(group => {
          if (group.type == 'SchoolClass') {
            loadSchoolClass(group.id).then(result => {
              if (result.data && result.data.schoolClass) {
                _schoolClasses = [..._schoolClasses, result.data.schoolClass];
                if (result.data.schoolClass.students) {
                  _classStudents = [
                    ..._classStudents,
                    ...result.data.schoolClass.students
                  ];
                  result.data.schoolClass.students.forEach(item => {
                    _sessionTotal += item.sessionAverageDuration || 0;
                  });
                }
              }
              setClassStudents(_classStudents);
              setSessionAverage(_sessionTotal);
              setSchoolclasses(_schoolClasses);
            });
          }
        });
      }
    }
  }, [loading]);

  //functions
  const loadSchool = group => {
    return props.client.query({
      query: GQL_FETCH_SCHOOL,
      variables: {
        id: group
      }
    });
  };

  const loadSchoolClass = group => {
    return props.client.query({
      query: GQL_FETCH_SCHOOL_CLASS,
      variables: {
        id: group
      }
    });
  };

  const everage = sessionAverage / classStudents.length;

  return (
    <div className={classes.root}>
      <div className={classes.headingContent}>
        <div className={classes.right}>
          <Link className={classes.link} to={'/rapportage'}>
            <Typography
              className={classes.linkContent}
              component="p"
              variant="body1"
            >
              {literals.CLASSES_OVERVIEW || 'Klassenoverzicht'}
              <span>
                <ArrowRightIcon />
              </span>
            </Typography>
          </Link>
        </div>
      </div>
      <div className={classes.statistics}>
        {role == 'SCHOOL_MANAGER' && (
          <Manager
            schoolStudents={schoolStudents.length || 0}
            classStudents={classStudents.length || 0}
            schoolClasses={schoolClasses.length || 0}
            teachers={teachers}
            literals={literals}
          />
        )}
        {role == 'TEACHER' && (
          <Teacher
            classStudents={classStudents.length || 0}
            schoolClasses={schoolClasses.length || 0}
            sessionAverage={everage || 0}
          />
        )}
      </div>
    </div>
  );
};

export default compose(
  withStyles(styles),
  connect(state => ({
    config: state.config,
    group: getGroup(state),
    appUser: getAppUser(state)
  })),
  graphql(GQL_FETCH_GROUPS, {
    options: props => ({
      variables: {
        filter: {
          teacher: props.appUser.id,
        }
      }
    })
  }),
  graphql(GQL_FETCH_GROUPS, {
    name: "MANAGERGROUPS",
    options: props => ({
      variables: {
        filter: {
          manager: props.appUser.id
        }
      }
    })
  }),
  withApollo
)(StatisticsBox);
