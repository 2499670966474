'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRouterDom = require('react-router-dom');

var _recompose = require('recompose');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var withStepper = function withStepper(schema) {
  return function (WrappedComponent) {
    return (0, _recompose.compose)(_reactRouterDom.withRouter)(function (_React$Component) {
      _inherits(WithStepper, _React$Component);

      function WithStepper() {
        var _ref;

        var _temp, _this, _ret;

        _classCallCheck(this, WithStepper);

        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }

        return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = WithStepper.__proto__ || Object.getPrototypeOf(WithStepper)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
          step: 0,
          items: [],
          url: '',
          count: 0,
          options: {}
        }, _this.handleSetOptions = function (options) {
          _this.setState({ options: options });
        }, _this.handleNextStep = function () {
          var _this$state = _this.state,
              items = _this$state.items,
              step = _this$state.step,
              options = _this$state.options;
          var _options$isTrainer = options.isTrainer,
              isTrainer = _options$isTrainer === undefined ? true : _options$isTrainer;


          var allCorrect = true;
          var end = items.length == step + 1;

          items.forEach(function (item) {
            if (item.correct === false || item.correct === null) allCorrect = false;
          });

          var getNext = function getNext(items, position) {
            for (var i = position; i < items.length; i++) {
              if (items[i].correct === false) return i;
            }

            for (var _i = 0; _i < position; _i++) {
              if (items[_i].correct === false) {
                return _i;
              }
            }
          };

          return new Promise(function (resolve, reject) {
            _this.setState(function (prevState) {
              return {
                step: end && !isTrainer ? prevState.step : prevState.step += 1
              };
            }, function () {
              if (end && !isTrainer) {
                resolve(-1);
              } else if (allCorrect) {
                _this.setState({
                  step: 0
                }, function () {
                  resolve(-1);
                });
              } else if (items[_this.state.step] == undefined) {
                _this.setState({
                  step: getNext(items, _this.state.step)
                }, function () {
                  resolve(-1);
                });
              } else {
                if (items[_this.state.step].correct === true) {
                  resolve('REDO');
                } else {
                  resolve(_this.state.step);
                }
              }
            });
          });
        }, _this.handleSubmit = function (correct) {
          var items = [].concat(_toConsumableArray(_this.state.items));
          if (items[_this.state.step]) items[_this.state.step].correct = correct;

          return _this.handleNextStep().then(function (step) {
            if (step === 'REDO') {
              return _this.handleNextStep().then(function (redoStep) {
                if (redoStep === 'REDO') {
                  return -1;
                }
                return step;
              });
            } else {
              return step;
            }
          });
        }, _this.handleResetetItems = function (items) {
          _this.setState({
            items: items,
            step: 0
          });
        }, _this.handleSetItems = function (items) {
          var _items = items.map(function (item) {
            return _extends({}, item, {
              correct: null
            });
          });

          _this.setState({
            items: [].concat(_toConsumableArray(_items)),
            step: 0
          });
        }, _this.startCounting = function () {
          _this.setState({ count: 0 }, function () {
            return _this.startRealCount();
          });
        }, _this.startRealCount = function () {
          var count = 0;

          schema.timer = setInterval(function () {
            _this.setState({
              count: count++
            });
          }, 1000);
        }, _this.stopCounting = function () {
          clearTimeout(schema.timer);
        }, _temp), _possibleConstructorReturn(_this, _ret);
      }

      _createClass(WithStepper, [{
        key: 'render',
        value: function render() {
          var otherProps = _objectWithoutProperties(this.props, []);

          var _state = this.state,
              step = _state.step,
              items = _state.items,
              url = _state.url,
              count = _state.count;


          return _react2.default.createElement(WrappedComponent, _extends({}, otherProps, {
            stepper: {
              step: step,
              count: count,
              onSubmit: this.handleSubmit,
              setItems: this.handleSetItems,
              resetItems: this.handleResetetItems,
              startCounting: this.startCounting,
              stopCounting: this.stopCounting,
              setOptions: this.handleSetOptions,
              items: items
            }
          }));
        }
      }]);

      return WithStepper;
    }(_react2.default.Component));
  };
};

exports.default = withStepper;