import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { ChatBubble, TextMPC, CheckQuestion, ImageWithSubtitle } from '@manakin/app-core';
import { compose } from 'recompose';
import classNames from 'classnames';
import renderHTML from 'react-render-html';
import Typography from '@material-ui/core/Typography';
import scrollToComponent from 'react-scroll-to-component';
import { getBoxId } from '@manakin/app-core/Boxes/selectors';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { graphql } from 'react-apollo';
import { GQL_CREATE_ELEMENT_RESULT } from '../graphql';
import { getProgram } from '@manakin/app-core/ProgramsDropdown/selectors';
import { getAppUser } from '@manakin/authentication/selectors';
import {withApollo} from 'react-apollo';


const styles = theme => ({
	root: {
		[theme.breakpoints.up('md')]: {
			width: '100%',
			display: 'flex'
		}
	},
	left: {
		[theme.breakpoints.up('md')]: {
			width: 'calc(100% - 280px)'
		},
		[theme.breakpoints.up('lg')]: {
			width: 'calc(100% - 350px)'
		}
	},
	chatBubble: {
		width: '100%',
		maxWidth: '860px',
	},
	chatBubbles: {
		padding: '7rem 0'
	},
	question: {
		paddingTop: '2rem',
		paddingBottom: '2rem',
		borderTop: `1px solid ${theme.manakin.defaultBorderColor[500]}`,
		[theme.breakpoints.up('md')]: {
			...theme.manakin.defaultWrapper,
			paddingTop: '3rem',
			paddingBottom: '3rem',
		}
	},
	content: {
		width: '100%',
		height: 'auto',
		[theme.breakpoints.up('md')]: {
			display: 'flex',
			justifyContent: 'center'
		}
	},
	sidebar: {
		display: 'none',
		[theme.breakpoints.up('md')]: {
			padding: '4rem',
			display: 'block',
			width: '280px',
			backgroundColor: theme.manakin.secondaryColor[300]
		},
		[theme.breakpoints.up('lg')]: {
			width: '350px',
		}
	},
	body: {
		margin: 0,
        "@global ul": {
            paddingLeft: '2.25rem',
        },
	},
	hide: {
		display: 'none'
	}
});

const mapStateToProps = state => ({
    program: getProgram(state),
    boxId: getBoxId(state),
    appUser: getAppUser(state)
});

class Layout extends React.PureComponent {
	state = {
		step: -1,
		characteristic: []
	};

	static defaultProps = {
		renderContent: () => {},
		renderQuestion: () => {},
		renderSideBar: () => {}
	};

	componentDidUpdate(prevProps) {
		if (!prevProps.showContent && this.props.showContent) {
			this.setState({
				step: 0
			});
		}
	}

	handleNext = characteristic => {
		const { data } = this.props;
		const { step } = this.state;
		const workforms = data.workforms || [];
		const workformLength = workforms.length || 0;

		if (step < workformLength - 1) {
			this.setState(
				{
					step: step + 1
				},
				() => {
					if (workforms[this.state.step].type != 'ChatBubble') {
						setTimeout(() => {
							scrollToComponent(this.question, {
								offset: 0,
								align: 'bottom',
								duration: 500
							});
						}, 500);
					}
				}
			);
		} else {
			const {program, boxId, match, appUser, client} = this.props;
			const programId = program;
			const elementId = match.params.elementId;
			const userId = appUser.id;
			if(programId && elementId && boxId && userId) {
				client.mutate({
					mutation: GQL_CREATE_ELEMENT_RESULT,
					variables: {
						box: boxId,
						program: programId,
						user: userId,
						element: elementId,
						finished: true
					}
				}).then(result => {
					this.props.history.push(`/box/${this.props.boxId}`)
				})
			}else {
				this.props.history.push(`/box/${this.props.boxId}`)
			}
		}

		if(characteristic && Array.isArray(characteristic)) {
			let _characteristic = [...this.state.characteristic];
			const merge = _characteristic.concat(characteristic);
			this.setState({characteristic: merge})
		}
	};

	render() {
		const { classes, data, showContent, loading } = this.props;
		const { step, characteristic } = this.state;
		const workforms = data.workforms || [];

		return (
			<div
				className={classes.root}
				ref={section => {
					this.Root = section;
				}}
			>
				{!loading && (
					<div className={classes.left}>
						<div className={classes.content}>
							<div className={classes.chatBubble}>
								<div className={classes.chatBubbles}>
									{workforms.map((item, idx) => (
										<div key={item.id} >
											{item.type == 'ChatBubble' && (
												<ChatBubble
													workformId={item.id}
													animate={step >= idx}
													onNext={this.handleNext}
												/>
											)}
										</div>
									))}
								</div>
								{workforms.map((item, idx) => (
									<div key={item.id} ref={section => { this.question = section }}>
										{item.type == 'TextMPC' && step == idx && (
											<div className={classNames(classes.question) } >
												<TextMPC
													workformId={item.id}
													onNext={this.handleNext}
												/>
											</div>
										)}
										{item.type == 'TextCheckQuestion' && step == idx && (
											<div className={classNames(classes.question) } >
												<CheckQuestion
													workformId={item.id}
													onNext={this.handleNext}
												/>
											</div>
										)}
										{item.type == 'ImageWithSubtitle' && step == idx && (
											<div className={classNames(classes.question) } >
												<ImageWithSubtitle
													workformId={item.id}
													onNext={this.handleNext}
													buttonText="Ga verder"
												/>
											</div>
										)}
									</div>
								))}
							</div>
						</div>
					</div>
				)}
				<div className={classes.sidebar}>
					<Typography component="h4" variant="h6">
						KENMERKEN
					</Typography>
					<ul>
						{characteristic &&
							characteristic.map((item, idx) => (
								<li key={idx}>
									<Typography
										component="div"
										variant="body1"
										classes={{ body1: classes.body }}
									>
										{renderHTML(item)}
									</Typography>
								</li>
							))}
					</ul>
				</div>
			</div>
		);
	}
}

export default compose(
	withRouter,
	withStyles(styles),
	connect(
		mapStateToProps
	),
	withApollo
)(Layout);
