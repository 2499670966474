import React from 'react';
import {ElementOverlay, TransitionRouter, withStepper, Stepper} from '@manakin/app-core';
import {Route, withRouter} from 'react-router-dom';
import {compose} from 'recompose';
import { connect } from 'react-redux';
import Header from './parts/Header';
import Outro from './parts/Outro';

const BOOKCHECK_ROOT_PATH = 'BookCheck';

class BookCheck extends React.PureComponent {
	componentDidMount(prevProps) {
		if(!this.props.loading) {
			const {stepper, workforms} = this.props;
			stepper.setItems(workforms || []);
		}
	}
	componentDidUpdate(prevProps) {
		const {step = false} = this.props;
		if (prevProps.workforms != this.props.workforms || step == 1 && prevProps.step != 1) {
			if(!this.props.loading) {
				const {stepper, workforms} = this.props;
				stepper.setItems(workforms || []);
			}
		}
	}

	handleClick = event => {
		if(this.props.onClick) this.props.onClick(event)
	}

	handleExit = (count) => {
		if(this.props.onExit) this.props.onExit(count);
	}

	render() {
		const {rawData = {}, loading, options = {}, workforms = [], stepper, step = false, config = {}} = this.props;
		const {literals = {}} = config
		if(step) {
			return (
				<React.Fragment> 
					{step == 1 && (
						 <Header data={rawData} loading={loading} onClick={this.handleClick} />
					)}
					{step == 2 && (
						<Stepper stepper={stepper} options={options} onExit={this.handleExit} variant='bookCheck' />
					)}
					{step == 3 && (
						<Outro data={rawData} onClick={this.handleClick} />
					)}
				</React.Fragment>	 
			)
		}else {
			return (
				<ElementOverlay background={rawData.borderColor || false} title={literals.APP_BOOKCHECK || 'Boekcheck'}> 
					<TransitionRouter>
						<Route exact path={`/${BOOKCHECK_ROOT_PATH}/:elementId`} 
							render={() => <Header data={rawData} loading={loading} onClick={this.handleClick} />}
						 />
						<Route exact path={`/${BOOKCHECK_ROOT_PATH}/:elementId/questions`} 
							render={() => <Stepper stepper={stepper} options={options} onExit={this.handleExit} variant='bookCheck' />}
						 />
						<Route exact path={`/${BOOKCHECK_ROOT_PATH}/:elementId/outro`} 
							render={() => <Outro data={rawData} onClick={this.handleClick} />}
						 />
					</TransitionRouter>
				</ElementOverlay>	 
			)
		}
	}
}

export default compose(
	connect(state => ({config: state.config})),
	withStepper()
)(BookCheck)