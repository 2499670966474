import React from 'react';
import UiContext from '../UiContext';

class UiProvider extends React.Component {
    render() {
        const { ui, children } = this.props;
        return <UiContext.Provider value={ui}>{children}</UiContext.Provider>;
    }
}

export default UiProvider;
