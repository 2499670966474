import gql from "graphql-tag";

export const GQL_FETCH_FACT_CARD = gql`
  query($id: ID!) {
    factCard(id: $id) {
      id
      title
      text
      tags {
        id
        name
      }
      image {
        id
        url
      }
      backgroundImage {
        id
        url
      }
    }
  }
`;

export const GQL_FETCH_FACT_CARDS = gql`
  query($page: Int!, $pagesize: Int!) {
    factCards(page: $page, pagesize: $pagesize) {
      factCards {
        id
        title
        text
        tags {
          id
          name
        }
        image {
          id
          url
        }
        backgroundImage {
          id
          url
        }
      }
    }
  }
`;

export const GQL_FETCH_BOX = gql`
  query($id: ID!) {
    box(id: $id) {
      name
    }
  }
`;
