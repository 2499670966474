import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import Button from "@material-ui/core/Button";
import classNames from "classnames";
import { WorkformLayout, AppRadioGroup } from "@manakin/app-core";
import { FeedbackBox } from "@manakin/app-workforms";
import { styles } from "./styles";
import { connect } from "react-redux";

class Hotspot extends React.PureComponent {
  handleChange = event => {
    if (this.props.onChange) this.props.onChange(event);
  };

  handleSubmit = event => {
    const { data } = this.props;
    if (this.props.onSubmit) this.props.onSubmit(data);
  };

  render() {
    const { data, classes, onFinished, disabled, config = {} } = this.props;
    const { literals = {} } = config;
    const { answers = [], initialValue = "" } = data;

    let position;
    if (data.spot != undefined) {
      position = {
        left: `${data.spot.x}%`,
        top: `${data.spot.y}%`,
        width: `${data.spot.radius}vw`,
        height: `${data.spot.radius}vw`
      };
    }

    return (
      <WorkformLayout
        question={data.question || ""}
        instruction={
          data.introduction ||
          literals.CMS_LOOK_AT_IMAGE ||
          "Bekijk de afbeelding"
        }
        loading={false}
        renderQuestion={() => (
          <div className={classes.wrapper}>
            <div className={classes.mobileHotspot}>
              <div className={classes.image}>
                <div className={classes.spot} style={position} />
                <img src={(data.image && data.image.url) || ""} />
              </div>
            </div>
            <div className={classes.answers}>
              <AppRadioGroup
                done={data.done || false}
                answers={answers}
                onChange={this.handleChange}
                initialValue={initialValue}
              />
              <div className={classes.buttons}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  className={classes.button}
                  onClick={() => this.handleSubmit()}
                  disabled={disabled}
                >
                  {data.buttonText ||
                    literals.APP_CHECK_ANSWER ||
                    "Check mijn antwoord"}
                </Button>
                {data.feedback && (
                  <div
                    className={classNames(classes.feedback, {
                      [classes.activeFeedback]: data.feedback
                    })}
                  >
                    <FeedbackBox
                      data={data}
                      onFinished={onFinished}
                      outlined={true}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        renderAnswers={() => (
          <div className={classes.hotspot}>
            <div className={classes.image}>
              <div className={classes.spot} style={position} />
              <img src={(data.image && data.image.url) || ""} />
            </div>
          </div>
        )}
      />
    );
  }
}

export default compose(
  connect(state => ({ config: state.config })),
  withStyles(styles)
)(Hotspot);
