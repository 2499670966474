import React from "react";
import { Route, withRouter, Redirect } from "react-router-dom";
import Dashboard from "../Dashboard";
import altDashboard from "../AltDashboard";
import Box from "../Box";
import { BookCheckContainer } from "../BookCheck";
import { MasterclassContainer } from "../MasterAndVideo";
import { FilmContainer } from "../MasterAndVideo";
import { LessonContainer } from "../Lesson";
import { RecapContainer } from "../Recap";
import Rapportage from "../Rapportage";
import { TrainerContainer } from "../Trainer";
import TeacherProgram from "../TeacherProgram";
import Case from "../Case";
import Faq from "../Faq";
import SlideshowSetup from "../SlideshowSetup";
import Slideshow from "../Slideshow";
import { TransitionRouter } from "@manakin/app-core";
import { FactCardContainer } from "../FactCard";
import { FactCardsContainer } from "../FactCards";
import { BoxCardContainer } from "../FactCards";
import { EntryTestContainer } from "../EntryTest";
import { TestContainer } from "../Test";
import { getAppUser } from "@manakin/authentication/selectors";
import { getProgram } from "@manakin/app-core/ProgramsDropdown/selectors";
import { connect } from "react-redux";
import NoLicenses from "../NoLicenses/NoLicenses";
import { getBoxId } from "@manakin/app-core/Boxes/selectors";
import BettingGame from "../BettingGame";

export const DASHBOARD_ROOT_PATH = "dashboard";
export const BOX_ROOT_PATH = "box";
export const BOOKCHECK_ROOT_PATH = "BookCheck";
export const MASTERCLASS_ROOT_PATH = "MasterClass";
export const FILM_ROOT_PATH = "Film";
export const LESSON_ROOT_PATH = "Lesson";
export const TRAINER_ROOT_PATH = "Trainer";
export const CASE_ROOT_PATH = "Case";
export const FACT_CARD_ROOT_PATH = "factcard";
export const FACT_CARDS_ROOT_PATH = "factcards";
export const BOX_CARDS_PATH = "boxCards";
export const ENTRY_TEST_ROOT_PATH = "entryTest";
export const TEST_ROOT_PATH = "test";
export const RAPPORTAGE_ROOT_PATH = "rapportage";
export const TEACHER_PROGRAM_ROOT_PATH = "teacherProgram";
export const BETTING_GAME_ROOT_PATH = "BettingGame";

class Router extends React.PureComponent {
  render() {
    const { config = {}, appUser = {} } = this.props;
    const useAltDashboard = config.pages && config.pages.showDashboard;

    return (
      <TransitionRouter>
        <Route path={"/no-license"} component={NoLicenses} />
        <Route
          exact
          path={`/`}
          component={useAltDashboard ? altDashboard : Dashboard}
        />
        <Route exact path={`/${DASHBOARD_ROOT_PATH}`} component={Dashboard} />
        <Route exact path={`/${DASHBOARD_ROOT_PATH}/:dashboardOption`} component={Dashboard} />
        <Route
          path={`/${FACT_CARD_ROOT_PATH}/:factCardId`}
          component={FactCardContainer}
        />
        <Route
          path={`/${FACT_CARDS_ROOT_PATH}`}
          component={FactCardsContainer}
        />
        {this.props.boxStyle ? (
          <Route
            exact
            path={`/${BOX_ROOT_PATH}/:boxid`}
            render={props => <Box {...props} boxStyle={this.props.boxStyle} />}
          />
        ) : (
            <Route exact path={`/${BOX_ROOT_PATH}/:boxid`} component={Box} />
          )}
        <Route path={`/${BOX_CARDS_PATH}`} component={BoxCardContainer} />
        <Route path={`/${RAPPORTAGE_ROOT_PATH}`} component={Rapportage} />
        <Route
          path={`/${TEACHER_PROGRAM_ROOT_PATH}`}
          component={TeacherProgram}
        />
        <Route path={`/faq`} component={Faq} />
        <Route
          path={`/${BOOKCHECK_ROOT_PATH}/:elementId`}
          component={BookCheckContainer}
        />
        <Route
          path={`/${MASTERCLASS_ROOT_PATH}/:elementId`}
          component={MasterclassContainer}
        />
        <Route
          path={`/${FILM_ROOT_PATH}/:elementId`}
          component={FilmContainer}
        />
        <Route
          path={`/${LESSON_ROOT_PATH}/:elementId`}
          component={LessonContainer}
        />
        <Route
          path={`/${TRAINER_ROOT_PATH}/:elementId`}
          component={TrainerContainer}
        />
        <Route
          path={`/${ENTRY_TEST_ROOT_PATH}/:elementId`}
          component={EntryTestContainer}
        />
        <Route
          path={`/${TEST_ROOT_PATH}/:elementId/:status`}
          component={TestContainer}
        />
        <Route
          path={`/${TEST_ROOT_PATH}/:elementId`}
          component={TestContainer}
        />
        <Route
          path={`/${BETTING_GAME_ROOT_PATH}/:elementId`}
          component={BettingGame}
        />
        <Route path={`/${CASE_ROOT_PATH}/:elementId`} component={Case} />
        <Route
          path={`/SlideshowCreator/:elementId/:slideId`}
          component={SlideshowSetup}
        />
        <Route path={`/recap/:elementId`} component={RecapContainer} />
        <Route path={`/Summary/:elementId`} component={RecapContainer} />
        <Route path={`/slideshow/:elementId`} component={Slideshow} />
        <Redirect from="/login" to="/" />
      </TransitionRouter>
    );
  }
}

const mapStateToProps = state => ({
  appUser: getAppUser(state),
  program: getProgram(state),
  boxId: getBoxId(state),
  config: state.config
});

export default withRouter(connect(mapStateToProps)(Router));
