export const styles = theme => ({
    wrapper: {
        ...theme.manakin.defaultWrapper,
        [theme.breakpoints.up('md')]: {
            padding: '0'
        }
    },
    content: {
        display: 'flex',
        [theme.breakpoints.up('lg')]: {
            flexDirection: 'column'
        }
    },
    questions: {
        width: '50%',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('lg')]: {
            width: '100%',
            flexDirection: 'row',
        }
    },
    answers: {
        width: '50%',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('lg')]: {
            width: '100%',
            flexDirection: 'row',
        }
    },
    buttons: {
        position: 'relative',
        marginTop: '2rem'
    },
    button: {
        opacity: 1,
        transition: 'opacity .3s'
    },
    hide: {
        opacity: 0
    },
    feedback: {
      position: 'relative',
      zIndex: '2',
      opacity: '0',
      height: 0,
      width: '100%',
      overflow: 'hidden',
      pointerEvents: 'none',
      transition: 'opacity .3s',
    },
    activeFeedback: {
        opacity: '1',
        pointerEvents: 'auto',
        height: 'auto',
    },
});