import gql from 'graphql-tag';

export const GQL_FETCH_ENTRYTEST = gql`
  query($id: ID!) {
    entryTest(id: $id) {
      id
      type
      introTitle
      introText
      preTitle
      titleColor
      image {
        id
        url
        thumbnailUrl
      }
      workforms {
        id
        type
      }
    }
  }
`;
