import React from 'react';
import { compose } from 'recompose';
import { graphql } from 'react-apollo';
import { GQL_FETCH_CURRENT_CMS_USER } from './graphql';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { showSnackbarMessage } from '@manakin/core/actions';
import { logout } from '@manakin/authentication/actions';

const styles = theme => ({
  loading: {
    // display: 'none'
    opacity: '0'
  }
});

const checkAccess = (
  currentUser,
  renderRules,
  processDifferentPrivileges,

  processNotLoggedIn,
  error
) => {
  let giveAccess = false;

  if (currentUser) {
    let allUserPrivileges = [];

    renderRules.forEach(rule => {
      currentUser.roles.forEach(item => {
        item.privileges.forEach(privilege => {
          if (privilege.id == rule) giveAccess = true;
          allUserPrivileges.push(privilege.id);
        });
      });
    });

    // Get the privileges from the JWT token and compare it against the user's privileges
    let token = document.cookie.match(
      `(?:(?:^|.*; *)jwt_bespeak *= *([^;]*).*$)|^.*$`
    )[1];
    if (token) {
      let base64Url = token.split('.')[1];
      if (base64Url) {
        let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        let decodedTokenBody = JSON.parse(window.atob(base64));

        // Other user logging in, don't compare two different tokens
        if (decodedTokenBody.username != currentUser.id) return giveAccess;

        let allTokenPrivileges = decodedTokenBody.privileges
          ? [...new Set(decodedTokenBody.privileges.sort())]
          : [];
        allUserPrivileges = [...new Set(allUserPrivileges.sort())];

        if (
          allUserPrivileges.length !== allTokenPrivileges.length &&
          processDifferentPrivileges
        )
          processDifferentPrivileges();

        for (let i = 0; i < allUserPrivileges.length; i++) {
          if (allUserPrivileges[i] != allTokenPrivileges[i]) {
            if (processDifferentPrivileges) processDifferentPrivileges();
            break;
          }
        }
      }
    }
  } /* else if ((currentUser === undefined && error !== undefined) || (currentUser === null && error === undefined)) {
        processNotLoggedIn()
    }*/

  return giveAccess;
};

const AccessControl = props => {
  const {
    children,
    data,
    classes,
    renderNoAccess = () => '',
    renderRules,
    hasAccess,
    processDifferentPrivileges,
    processNotLoggedIn,
    disable
  } = props;
  const { loading } = data;

  if (disable) {
    return (
      <div className={classNames(classes.noAccess, { [classes.loading]: loading })}>
        {renderNoAccess()}
      </div>
    )
  }
  if (
    hasAccess != undefined &&
    checkAccess(
      data.currentCmsUser,
      renderRules,
      processDifferentPrivileges,
      processNotLoggedIn,
      data.error
    )
  ) {
    return hasAccess();
  } else if (
    checkAccess(
      data.currentCmsUser,
      renderRules,
      processDifferentPrivileges,
      processNotLoggedIn,
      data.error
    )
  ) {
    return (
      <div className={classNames(classes.root, { [classes.loading]: loading })}>
        {children}
      </div>
    );
  }
  return (
    <div
      className={classNames(classes.noAccess, { [classes.loading]: loading })}
    >
      {renderNoAccess()}
    </div>
  );
};

export default compose(
  connect(
    null,
    dispatch => ({
      processDifferentPrivileges: () => {
        dispatch(logout());
        dispatch(
          showSnackbarMessage({
            text:
              'Uw permissies zijn gewijzigd. Log a.u.b. opnieuw in om de wijzigingen van kracht te laten gaan.',
            variant: 'warning'
          })
        );
      },
      processNotLoggedIn: () => {
        dispatch(logout());
      }
    })
  ),
  graphql(GQL_FETCH_CURRENT_CMS_USER),
  withStyles(styles)
)(AccessControl);
