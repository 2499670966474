import React, { useEffect } from 'react';
import { compose } from 'recompose';
import { GQL_FETCH_APP_USER } from '../graphql';
import { graphql } from 'react-apollo';
import { login, logout } from '@manakin/authentication/actions';
import { connect } from 'react-redux';
import Cookies from 'js-cookie';

const Sso = (props) => {
    const { data, processSuccess, processFail, config = {} } = props;
    const { loading } = data;

    useEffect(() => {
        if (!loading) {
            const { appUser = {} } = data;

            if (appUser && appUser.locale && appUser.locale.code) {
                const lang =
                    appUser.locale.code === config.defaultLang
                        ? ''
                        : appUser.locale.code || '';
                Cookies.set('locale', lang);
            }

            if (appUser && appUser.id) {
                processSuccess('/', appUser);
            } else {
                processFail();
            }
        }
    }, [loading]);

    if (loading) {
        return <div>Loading</div>;
    }

    return <div>Redirecting</div>;
};

export default compose(
    connect(
        (state) => ({ config: state.config }),
        (dispatch) => ({
            processSuccess: (redirect, user) => dispatch(login(redirect, user)),
            processFail: () => dispatch(logout()),
        })
    ),
    graphql(GQL_FETCH_APP_USER, {
        options: (props) => ({
            variables: {
                id: props.match.params.userId,
            },
        }),
    })
)(Sso);
