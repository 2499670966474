'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.GQL_FETCH_FACTCARD_REFERRAL = undefined;

var _templateObject = _taggedTemplateLiteral(['\n    query($id: ID!) {\n        factCardReferral(id: $id) {\n            id\n            referrals {\n                id\n                title\n                text\n                actionTitle\n                factCardId\n                factCard\n                image {\n                    id\n                    url\n                }\n            }\n        }\n    }\n'], ['\n    query($id: ID!) {\n        factCardReferral(id: $id) {\n            id\n            referrals {\n                id\n                title\n                text\n                actionTitle\n                factCardId\n                factCard\n                image {\n                    id\n                    url\n                }\n            }\n        }\n    }\n']);

var _graphqlTag = require('graphql-tag');

var _graphqlTag2 = _interopRequireDefault(_graphqlTag);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteral(strings, raw) { return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var GQL_FETCH_FACTCARD_REFERRAL = exports.GQL_FETCH_FACTCARD_REFERRAL = (0, _graphqlTag2.default)(_templateObject);