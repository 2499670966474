'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var styles = exports.styles = function styles(theme) {
  return {
    body: {},
    heading: {},
    root: _defineProperty({
      textDecoration: 'none',
      '& $body': {
        fontSize: '1.6rem',
        fontWeight: 300,
        lineHeight: '3.2rem'
      }
    }, theme.breakpoints.up('md'), {
      '& $heading': {
        marginBottom: '2.5rem'
      }
    }),

    figure: _defineProperty({
      width: '100%',
      height: '18rem',
      marginBottom: '3rem',
      position: 'relative'
    }, theme.breakpoints.up('md'), {
      height: '27rem'
    })
  };
};