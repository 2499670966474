import React from 'react';
import Lesson from './Lesson';
import { withWorkforms } from '@manakin/app-core';
import { compose } from "recompose";
const queryString = require('query-string');

class PreviewContainer extends React.Component {
  componentDidMount() {
    const { match, workformsData } = this.props;
    const query = match.params.query;
    const lessonData = queryString.parse(query);
    const obj = {
      ...lessonData,
      headerImage: {
        url: lessonData.headerImage
      },
      image: {
        url: lessonData.image
      },
      workforms: [
        ...lessonData.items.map((item, idx) => ({
          id: item,
          type: lessonData.itemTypes[idx]
        }))
      ],
      options: {
        showFeedback: true,
        nextButton: false
      }
    };
    workformsData.loadWorkforms({ ...obj });
  }

  handleFinish = () => {
    return true;
  };

  render() {
    const { workformsData } = this.props;

    return (
      <Lesson
        {...workformsData}
        lessonData={{ ...workformsData.rawData }}
        preview={true}
        workforms={workformsData.workforms}
        onFinish={this.handleFinish}
      />
    );
  }
}

export default compose(withWorkforms())(PreviewContainer);
