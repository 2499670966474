import gql from "graphql-tag";

export const GQL_FETCH_FAQ = gql`
    query {
        faq {
            title
            description
            headerImage {
                id
                url
                thumbnailUrl
            }
            items {
                id
                question
                answer
            }
        }
    }
`;
