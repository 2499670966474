import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ElementCard from './cards/ElementCard';
import ProgramCard from './cards/ProgramCard';
import { withStyles } from '@material-ui/core/styles';

const WORK_FORM_TYPES = [
  { id: 'TitleAndText', label: 'Titel en tekst' },
  { id: 'TitleAndTextImage', label: 'Titel en tekst met afbeelding' },
  { id: 'ImageWithSubtitle', label: 'Afbeelding met subtitel' },
  { id: 'Moodboard', label: 'Moodboard' },
  { id: 'FactCardReferral', label: 'Factcard verwijzing' },
  { id: 'OrderQuestion', label: 'Volgordevraag' },
  { id: 'Roadmap', label: 'Stappenplan' },
  { id: 'Hotspot', label: 'Hotspot vraag' },
  { id: 'HotspotMap', label: 'Hotspot map' },
  { id: 'Scene', label: 'Scene' },
  { id: 'StatementQuestion', label: 'Stellingsvraag' },
  { id: 'ImageMPC', label: 'Meerkeuzevraag met afbeeldingen' },
  { id: 'TextMPC', label: 'Meerkeuzevraag met tekst' },
  { id: 'ImageAndTextMPC', label: 'Meerkeuzevraag met afbeelding en tekst' },
  { id: 'WhatIsWhatQuestion', label: 'Wat hoort bij wat vraag' },
  { id: 'SortQuestion', label: 'Sorteervraag' },
  { id: 'ImageCheckQuestion', label: 'Vinkvraag met afbeelding' },
  { id: 'TextCheckQuestion', label: 'Vinkvraag met tekst' },
  {
    id: 'ImageAndTextCheckQuestion',
    label: 'Vinkvraag met afbeelding en tekst'
  },
  { id: 'ShortText', label: 'Korte tekst' },
  { id: 'VideoWithTitle', label: 'Video met titel' },
  { id: 'ChatBubble', label: 'Chat bubbel' },
  { id: 'FileDownload', label: 'Bestand koppelen' },
  { id: 'ChoiceQuestion', label: 'Keuze vraag' },
  { id: 'ReferralElement', label: 'Element verwijzing' }
];

const WORK_FORM_TYPES_NAMES = WORK_FORM_TYPES.map(item => ({
  id: item.id,
  name: item.label
}));

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%'
  }
});

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const Dropable = props => {
  const [items, setItems] = useState(props.items || []);
  const [checkedItems, setCheckedItems] = useState([]);
  const [checkedUnboxItems, setCheckedUnboxItems] = useState([]);

  ///effects
  useEffect(() => {
    setItems(props.items);
  }, [props.items]);

  useEffect(() => {
    if (!props.grouping) {
      const copied = [];
      const newItems = [];
      let first = -1;

      items.forEach((item, index) => {
        if (checkedItems[index]) {
          copied.push(item[0]);
          if (first < 0) first = index;
        } else {
          newItems.push(item);
        }
      });

      if (copied.length) {
        newItems.splice(first, 0, copied);
        handleItems(newItems);
      }

      setCheckedItems([]);
    }
  }, [props.grouping]);

  //functions
  const handleItems = _items => {
    setItems(_items);
    props.onSetItems(_items);
  };

  const onDragEnd = result => {
    if (!result.destination) return;

    if (result.type === 'droppable') {
      const _items = reorder(
        items,
        result.source.index,
        result.destination.index
      );
      handleItems(_items);
    }
    if (result.type === 'innerDrop') {
      let idx = 0;
      items.forEach((item, i) => {
        if (item[0].id === result.source.droppableId) idx = i;
      });

      const _items = reorder(
        items[idx],
        result.source.index,
        result.destination.index
      );

      const copy = [...items];
      copy[idx] = _items;
      handleItems(copy);
    }
  };

  const handleRemove = index => {
    let _items = [...items];
    _items.splice(index, 1);
    handleItems(_items);
  };

  const handleSwitch = (element, index) => {
    let _items = [...items];

    _items[index] = {
      ...items[index],
      switch: element.target.checked,
      split: element.target.checked,
      correctWorkforms: [],
      inCorrectWorkforms: []
    };

    handleItems(_items);
  };

  const handleCheckboxChange = (index, checked) => {
    const _checkedItems = [...checkedItems];
    _checkedItems[index] = checked;
    setCheckedItems([..._checkedItems]);
  };

  const handleUnGroupingChange = (index, checked) => {
    const _checkedUnboxItems = [...checkedUnboxItems];
    _checkedUnboxItems[index] = checked;
    setCheckedUnboxItems([..._checkedUnboxItems]);
  };

  const handleUnGroupCheckbox = (index, checked) => {
    if (props.onHandleUnGrouping) props.onHandleUnGrouping(index, checked);
  };

  const handleRemoveAlt = (index, idx) => {
    if (props.onRemoveAlt) props.onRemoveAlt(index, idx);
  };

  const handleRemoveAltItem = (index, idx) => {
    const _its = [...items];
    _its.forEach((_items, i) =>
      _items.forEach((item, ind) => {
        if (item.id === idx) _its[i].splice(ind, 1);
      })
    );

    handleItems(_its);
  };

  const handleUnGrouping = (index, unGrouping) => {
    if (!unGrouping) {
      const item = items[index];
      const _items = [...items];
      const copied = [];
      let newItem = [];
      item.forEach((i, idx) => {
        if (checkedUnboxItems[idx]) copied.push([item[idx]]);
        else newItem.push(item[idx]);
      });
      if (newItem.length === 1) copied.push(newItem);
      if (copied.length) {
        if (copied.length === item.length) {
          _items.splice(index, 1, ...copied);
        } else {
          _items.splice(index, 1, newItem);
          _items.splice(index, 0, ...copied);
        }
        setCheckedUnboxItems([]);
        handleItems(_items);
      }
    }
  };

  const getSubHeaderName = id => {
    let label = '';
    WORK_FORM_TYPES_NAMES.forEach(workForm => {
      if (workForm.id == id) label = workForm.name;
    });

    return label;
  };

  const {
    style = 'element',
    readOnly = false,
    switchLabel = false,
    splitCards = false,
    classes,
    useSwitch = false,
    dropId = 'droppableItem',
    type = 'droppable',
    grouping = false,
    custom = false,
    children
  } = props;

  if (type === 'innerDrop') {
    return (
      <Droppable droppableId={dropId} type={type}>
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className={classes.root}
          >
            {items.map((item, index) => (
              <React.Fragment key={index}>
                {(style === 'program' || style === 'programItem') && (
                  <ProgramCard
                    provided={provided}
                    snapshot={snapshot}
                    index={index}
                    item={item}
                    style={style}
                    onCheckboxChange={handleUnGroupCheckbox}
                    unGrouping={props.unGrouping}
                    readOnly={props.readOnly}
                    onRemove={handleRemoveAlt}
                  />
                )}
              </React.Fragment>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    );
  } else {
    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={dropId} type={type}>
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className={classes.root}
            >
              {custom && children(provided, snapshot)}
              {!custom &&
                items.map((item, index) => (
                  <React.Fragment key={index}>
                    {style === 'element' && (
                      <ElementCard
                        readOnly={readOnly}
                        item={item}
                        provided={provided}
                        snapshot={snapshot}
                        subHeader={getSubHeaderName(item.type)}
                        index={index}
                        onItemClicked={props.onItemClicked}
                        onRemove={handleRemove}
                        useSwitch={
                          useSwitch &&
                          !(
                            (items[index - 1] && items[index - 1].switch) ||
                            (items[index - 2] && items[index - 2].switch)
                          )
                        }
                        switchLabel={switchLabel}
                        onSwitch={handleSwitch}
                        item={item}
                        small={
                          splitCards &&
                          ((items[index - 1] && items[index - 1].switch) ||
                            (items[index - 2] && items[index - 2].switch))
                        }
                      />
                    )}
                    {(style === 'program' || style === 'programItem') && (
                      <ProgramCard
                        provided={provided}
                        snapshot={snapshot}
                        index={index}
                        item={item}
                        style={style}
                        grouping={grouping}
                        checkedItems={checkedItems}
                        onCheckboxChange={handleCheckboxChange}
                        onCheckboxReset={() => setCheckedItems([])}
                        onUnGroupingChange={handleUnGroupingChange}
                        onUnGrouping={handleUnGrouping}
                        readOnly={props.readOnly}
                        onRemove={handleRemove}
                        onRemoveAlt={handleRemoveAltItem}
                      />
                    )}
                  </React.Fragment>
                ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
};

export default withStyles(styles)(Dropable);
