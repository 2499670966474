import React from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import ListItem from '@material-ui/core/ListItem';
import MenuList from '@material-ui/core/MenuList'; 
import SlidePreview from './SlidePreview';
import ImageDialog from "./ImageDialog";
import createUuid from "uuid/v4";

class NewSlidePopper extends React.PureComponent {

  render() {
    
    const { classes, anchorEl, placement, onClickAway, addSlide, addImageDialog, imageDialogState, closeImageDialog } = this.props;

    return (
      <div> 
        <ImageDialog 
          {...this.props}  
          open={imageDialogState} 
          closeImageDialog={closeImageDialog}
          onImageClick={() => addSlide('image', createUuid())}
        />
        <Popper
          className={classes.newSlidePopper}
          placement={placement ? placement : 'bottom-start'}
          anchorEl={anchorEl}  
          open={Boolean(anchorEl)}
          transition
          disablePortal
          modifiers={{ 
            offset: {
              offset: '0, 10px'
            } 
          }}
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              id="menu-list-grow"
              style={{ transformOrigin: ( placement === 'top' ? 'center bottom' : 'center top' ) }}
            >
            <Paper>
              <ClickAwayListener onClickAway={onClickAway}>
                <MenuList 
                  disableListWrap={true} 
                  className={classes.newSlideMenu}
                > 
                  <ListItem className={classes.newSlideMenuItem}> 
                    {/* onClick={() => addSlide('image', createUuid()) */}
                    <SlidePreview 
                      {...this.props} 
                      slideType='image' 
                      onClick={() => addImageDialog()} 
                    />
                    Foto slide
                  </ListItem> 
                  <ListItem className={classes.newSlideMenuItem}>
                    <SlidePreview 
                      {...this.props}
                      slideType='text'
                      onClick={() => addSlide('text', createUuid())}
                    />
                    Tekst slide
                  </ListItem>                        
                </MenuList>
              </ClickAwayListener>
            </Paper> 
          </Grow>
          )}
        </Popper>
      </div>
    )
  }
}

export default NewSlidePopper;