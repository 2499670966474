'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});
exports.getBackButtonData = exports.getGoBack = exports.getSave = exports.getDropDown = exports.getHide = exports.getColor = exports.APP_BAR_APP_ROOT = undefined;

var _reselect = require('reselect');

var _selectors = require('../selectors');

var APP_BAR_APP_ROOT = exports.APP_BAR_APP_ROOT = 'appBar';

var getState = (0, _reselect.createSelector)([_selectors.getCoreState], function (coreState) {
	return coreState[APP_BAR_APP_ROOT];
});

var getColor = exports.getColor = (0, _reselect.createSelector)([getState], function (state) {
	return state.backgroundColor;
});

var getHide = exports.getHide = (0, _reselect.createSelector)([getState], function (state) {
	return state.data;
});

var getDropDown = exports.getDropDown = (0, _reselect.createSelector)([getState], function (state) {
	return state.dropDown;
});

var getSave = exports.getSave = (0, _reselect.createSelector)([getState], function (state) {
	return state.save;
});

var getGoBack = exports.getGoBack = (0, _reselect.createSelector)([getState], function (state) {
	return state.back;
});

var getBackButtonData = exports.getBackButtonData = (0, _reselect.createSelector)([getState], function (state) {
	return state.backButtonData;
});