import React, { useState, useEffect } from "react";
import AppLogin from "@manakin/authentication/AppLogin";
import classNames from "classnames";
import { connect } from "react-redux";
import renderHTML from "react-render-html";
import useStyles from "./styles";
import { compose } from "recompose";

const handleGoToLink = link => {
  const withHttp = url => (!/^https?:\/\//i.test(url) ? `https://${url}` : url);
  window.open(withHttp(link), "_blank");
};

const Header = props => {
  const [mounted, setIsMounted] = useState(false);
  const { config = {} } = props;
  const classes = useStyles();
  const { literals = {}, pages = {} } = config;
  const { appLogin = {} } = pages;

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        {appLogin.visualTitle && (
          <span className={classes.visualTitle}>
            {renderHTML(literals.APP_LOGIN_VISUAL_TITLE) || ""}
          </span>
        )}
        <div className={classes.visual} />
      </div>
      <div
        className={classNames(classes.login, { [classes.mounted]: mounted })}
      >
        {appLogin.showLogo && (
          <div className={classes.logo} />
        )}
        <AppLogin search={props.search}>
          {appLogin.showHeaderTitle && (
            <div className={classes.headerTitle}>
              {appLogin.headerTitleIsHtml
                ? renderHTML(literals.APP_LOGIN_HEADER_TITLE)
                : literals.APP_LOGIN_HEADER_TITLE}
            </div>
          )}
        </AppLogin>
        {appLogin.showFootNote && (
          <div className={classes.footNote}>
            {literals.APP_LOGIN_FOOT_NOTE_TEXT}
            {literals.APP_LOGIN_FOOT_NOTE_LINK && (
              <div
                className={classes.link}
                onClick={() =>
                  handleGoToLink(literals.APP_LOGIN_FOOT_NOTE_LINK)
                }
              >
                {literals.APP_LOGIN_FOOT_NOTE_LINK}
              </div>
            )}
          </div>
        )}
        {appLogin.footerLogo && <div className={classes.footerLogo} />}
      </div>
    </div>
  );
};

export default compose(connect(({ config }) => ({ config })))(Header);
