import React, { useState, useEffect } from "react";
import classNames from "classnames";
import {
  Dialog,
  DialogContent,
  Typography,
  withStyles
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { SliderInput } from "@manakin/core";
import { compose } from "recompose";
import renderHTML from "react-render-html";
import { connect } from "react-redux";

const styles = theme => ({
  popupRoot: {
    width: "100%",
    padding: "50px",
    "&:first-child": {
      padding: "50px"
    }
  },
  dialogPaper: {
    padding: 0,
    width: "100%",
    fontSize: "2.2rem",
    position: "relative"
  },
  heading: {
    width: "100%",
    minHeight: "9rem"
  },
  points: {
    top: "-18px",
    right: "-20px",
    backgroundSize: "contain",
    backgroundImage: `url(${theme.manakin.pinkDotImage})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    textAlign: "center",
    display: "inline-flex",
    flexFlow: "column nowrap",
    width: "90px",
    height: "90px",
    justifyContent: "center",
    position: "absolute",
    [theme.breakpoints.up("md")]: {
      right: "50px",
      top: "32px"
    }
  },
  pointsTitle: {
    color: "white",
    fontSize: "2.2rem",
    lineHeight: 1,
    margin: 0
  },
  pointsBody: {
    color: "white",
    lineHeight: 1,
    margin: 0,
    fontWeight: 600,
    fontSize: "1.8rem"
  },
  nextQuestion: {
    margin: "0 0 4px",
    fontSize: "2.2rem",
    fontWeight: "normal"
  },
  nextCategory: {
    color: theme.palette.secondary[400],
    fontSize: "2.2rem",
    fontWeight: 600
  },
  slider: {
    marginBottom: "50px"
  }
});

const PointsPopup = props => {
  const {
    open = false,
    classes,
    stepper,
    correct = null,
    onRealClose,
    points,
    config = {}
  } = props;

  const { literals = {} } = config;

  //state hooks
  const [val, setVal] = useState(0);
  const [hintText, setHintText] = useState(false);

  //effect hooks
  useEffect(() => {
    if (open) {
      setVal(0);
      if (correct === null) {
        setHintText(stepper.items[stepper.step].hintText || false);
      } else {
        setHintText(stepper.items[stepper.step + 1].hintText || false);
      }
    }
  }, [open]);

  //functions
  const handleClick = () => {
    props.onChange(val);
    if (correct === null) {
      props.onStart();
    } else {
      stepper.onSubmit(correct).then(data => {
        if (data < 0) {
          props.onFinish(correct);
        }
      });
    }
    onRealClose();
  };

  const handleChange = value => {
    setVal(value);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={props.onClose}
        PaperProps={{ classes: { root: classes.dialogPaper } }}
      >
        <DialogContent className={classes.popupRoot}>
          <div className={classes.points}>
            <Typography variant="h3" className={classes.pointsTitle}>
              {points}
            </Typography>
            <Typography variant="body1" className={classes.pointsBody}>
              points
            </Typography>
          </div>
          <div className={classes.heading}>
            {hintText && (
              <React.Fragment>
                <Typography className={classes.nextQuestion} variant="body2">
                  {literals.APP_NEXT_QUESTION_ABOUT ||
                    "The next question is about..."}
                </Typography>
                <Typography
                  variant="body2"
                  className={classes.nextCategory}
                  component="div"
                >
                  {renderHTML(hintText)}
                </Typography>
              </React.Fragment>
            )}
          </div>
          <div className={classes.slider}>
            <SliderInput
              label={literals.APP_BET || "How many points do you want to bet?"}
              onChange={handleChange}
              max={points > 100 ? 100 : points}
            />
          </div>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            className={classes.button}
            onClick={handleClick}
          >
            {literals.APP_MAKE_BET || "Make the bet"}
          </Button>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default compose(
  connect(state => ({ config: state.config })),
  withStyles(styles)
)(PointsPopup);
