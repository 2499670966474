import React from "react";
import FactCard from "./FactCard";
import { ElementOverlay } from "@manakin/app-core";
import { GQL_FETCH_PREVIEW } from "./graphql/graphql";
import { graphql } from "react-apollo";
import { compose } from "recompose";

const queryString = require("query-string");

const FactCardPreview = props => {
  const { data } = props;
  const { settings = [] } = data;
  let query;
  let factcardData;

  settings.settings &&
    settings.settings.forEach(setting => {
      if (setting.name == props.match.params.userId) {
        query = setting.value;
      }
    });

  if (query != undefined) {
    factcardData = JSON.parse(query);
  }

  if (!factcardData) {
    return "loading";
  }
  return (
    <ElementOverlay
      onClick={() => console.log("can not close in preview")}
      controls={false}
      customControls={true}
    >
      <FactCard {...factcardData} />
    </ElementOverlay>
  );
};

export default compose(graphql(GQL_FETCH_PREVIEW))(FactCardPreview);
