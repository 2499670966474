'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styles = require('@material-ui/core/styles');

var _appCore = require('@manakin/app-core');

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _recompose = require('recompose');

var _reactApollo = require('react-apollo');

var _graphql = require('./graphql');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var styles = function styles(theme) {
  var _root;

  return {
    root: (_root = {
      padding: '45px 0 0'
    }, _defineProperty(_root, theme.breakpoints.up('sm'), {
      padding: '45px 0'
    }), _defineProperty(_root, theme.breakpoints.up('md'), {
      padding: '86px 0 45px'
    }), _root),
    newsItem: _defineProperty({
      padding: '0px 12px 25px'
    }, theme.breakpoints.up('md'), {
      padding: '0px 25px 25px'
    }),
    first: _defineProperty({
      padding: '0 12px 25px 0'
    }, theme.breakpoints.up('md'), {
      padding: '0px 25px 25px 0'
    }),
    carouselRoot: _extends({}, theme.manakin.extraLargeWrapper, {
      overflow: 'hidden'
    })
  };
};

var NewsCarousel = function NewsCarousel(props) {
  var classes = props.classes,
      _props$data = props.data,
      data = _props$data === undefined ? {} : _props$data;
  var _data$loading = data.loading,
      loading = _data$loading === undefined ? true : _data$loading,
      _data$newsList = data.newsList,
      newsList = _data$newsList === undefined ? {} : _data$newsList;


  if (loading) {
    return _react2.default.createElement(_appCore.Loader, null);
  }
  if (newsList.count === 0) {
    return '';
  }
  return _react2.default.createElement(
    'div',
    { className: classes.root },
    _react2.default.createElement(
      'div',
      { className: classes.carouselRoot },
      _react2.default.createElement(
        _appCore.Carousel,
        {
          slidesToShow: 1.2,
          slidesToShowSM: 2.2,
          slidesToShowMD: 2.6,
          title: 'Nieuws voor jou',
          withoutControls: false
        },
        newsList.news && newsList.news.map(function (news, idx) {
          return _react2.default.createElement(
            'div',
            {
              key: news.id,
              className: (0, _classnames2.default)(classes.newsItem, _defineProperty({}, classes.first, idx === 0))
            },
            _react2.default.createElement(_appCore.NewsItem, news)
          );
        })
      )
    )
  );
};

exports.default = (0, _recompose.compose)((0, _styles.withStyles)(styles), (0, _reactApollo.graphql)(_graphql.GQL_FETCH_NEWS_LIST, {
  options: function options(props) {
    return {
      variables: {
        page: 0,
        pagesize: 10,
        filter: {
          category: [].concat(_toConsumableArray(props.category))
        }
      }
    };
  }
}))(NewsCarousel);