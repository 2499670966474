import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';
import { WorkformLayout, LongArrow } from '@manakin/app-core';
import { FeedbackBox } from '@manakin/app-workforms';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import { styles } from './styles';
import ArrowUp from '@material-ui/icons/ExpandLess';
import ArrowDown from '@material-ui/icons/ExpandMore';
import { connect } from 'react-redux';

class OrderQuestion extends React.PureComponent {
  moveCard = (dragIndex, hoverIndex) => {
    if (this.props.onMoveCard) this.props.onMoveCard(dragIndex, hoverIndex);
  };

  handleSubmit = () => {
    if (this.props.onSubmit) this.props.onSubmit();
  };

  handleOnFinish = () => {
    if (this.props.onFinished) this.props.onFinished();
  };

  handleClick = () => {
    const answers = [...this.state.answers];
    setTimeout(() => {
      this.setState(
        {
          answers: answers
        },
        300
      );
    });
  };

  handleMoveDown(idx, id) {
    if (this.props.onMoveDown) this.props.onMoveDown(idx, id);
  }

  handleMoveUp(idx, id) {
    if (this.props.onMoveDown) this.props.onMoveUp(idx, id);
  }

  render() {
    const { classes, disabled, data = {}, config = {} } = this.props;
    const { literals = {} } = config;
    const { answers = [], animateNext, animatePrev, animationSettings } = data;

    return (
      <WorkformLayout
        question={data.question || ''}
        instruction={
          data.introduction || literals.APP_MPC_QUESTION || 'Meerkeuze vraag'
        }
        loading={false}
        renderAnswers={() => (
          <div className={classes.wrapper}>
            {answers &&
              answers.map((answer, i) => (
                <div className={classes.dragRoot} key={answer.id}>
                  <span className={classes.number}>
                    {i <= 10 && '0'}
                    {i + 1}
                  </span>

                  <div
                    className={classNames(
                      classes.card,
                      {
                        [classes.isCorrect]:
                          data.correctAnswer && data.correctAnswer == answer.id
                      },
                      {
                        [classes.animateNext]:
                          animateNext == answer.id && !animationSettings.gap
                      },
                      {
                        [classes.animatePrev]:
                          animatePrev == answer.id && !animationSettings.gap
                      },
                      {
                        [classes.animateNextGap]:
                          animateNext == answer.id && animationSettings.gap
                      },
                      {
                        [classes.animatePrevGap]:
                          animatePrev == answer.id && animationSettings.gap
                      }
                    )}
                  >
                    <div className={classes.text}>{answer.title}</div>
                    {(!data.correctAnswer ||
                      (data.correctAnswer &&
                        data.correctAnswer != answer.id)) && (
                      <div className={classes.arrows}>
                        <span>
                          {i != 0 && (
                            <ArrowUp
                              onClick={() => this.handleMoveUp(i, answer.id)}
                            />
                          )}
                          {i != answers.length - 1 && (
                            <ArrowDown
                              onClick={() => this.handleMoveDown(i, answer.id)}
                            />
                          )}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            <div className={classes.buttons}>
              {!data.feedback ? (
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  className={classNames(classes.button)}
                  onClick={this.handleSubmit}
                  disabled={disabled}
                >
                  {data.buttonText ||
                    literals.APP_CHECK_ANSWER ||
                    'Check mijn antwoord'}
                  <LongArrow className={classes.longArrowRoot} />
                </Button>
              ) : (
                <div
                  className={classNames(classes.feedback, {
                    [classes.activeFeedback]: data.feedback
                  })}
                >
                  <FeedbackBox
                    data={data}
                    onFinished={this.handleOnFinish}
                    outlined={true}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      />
    );
  }
}

export default compose(
  connect(state => ({ config: state.config })),
  withStyles(styles, { name: 'AppOrderQuestion' })
)(OrderQuestion);
