import React from "react";
import {withStyles} from "@material-ui/core/styles";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
	expansionPanel: {
		background: "none",
		boxShadow: "none",
	},
	expansionPanelDetails: {
		paddingLeft: 0,
		marginLeft: theme.spacing(3),
		width: "100%"
	},
	heading: {
		//fontSize: theme.typography.pxToRem(15),
	}
});

/**
 * DropDown that can be supplied with a list of items
 */
class ContentExpansionPanel extends React.Component {
	/**
	 * {Boolean} expanded Expanded state of the panel
	 * {Callback} onExpansion Callback fired when expansionPanel is collapsed / expanded
	 * {String} title Text to display as panel title
	 * {String} titleColor Desired color (E.g. grey, blue) (optional)
	 * {String} titleStyle Desired title style (E.g. uppercase, lowercase) (optional)
	 * {String} fontWeight Desired font weight (E.g. normal, bold) (optional)
	 * {String} contentPadding Choose preferred padding (optional)
	 * {String} content The contents of the panel, which can be checkboxes for example
	 *
	 * @returns {*}
	 */
	render() {
		const {
			classes,
			expanded,
			onExpansion,
			title,
			backgroundColor,
			stackingMode,
			titleFontFamily,
			titleFontWeight,
			borderColor,
			verticalCenterText,
			titleColor,
			titleStyle,
			fontWeight,
			contentPadding,
			content,
			isInfoPanel = false // We need custom styles for the info side bar
		} = this.props;

		return (
			<div className={classes.root}>
				<ExpansionPanel
					expanded={expanded}
					className={classes.expansionPanel}
					onChange={() => onExpansion()}>
					<ExpansionPanelSummary
						className={classes.expansionPanelSummary}
						expandIcon={<ExpandMoreIcon/>}>
						<Typography
              variant='subtitle1'
							className={classes.heading}
						>{title}</Typography>
					</ExpansionPanelSummary>
					<ExpansionPanelDetails
						className={classes.expansionPanelDetails}
					>
						{content}
					</ExpansionPanelDetails>
				</ExpansionPanel>
			</div>
		);
	}
}

export default withStyles(styles)(ContentExpansionPanel);
