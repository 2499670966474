'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Dialog = require('@manakin/core/Dialog');

var _Dialog2 = _interopRequireDefault(_Dialog);

var _Dialog3 = require('@material-ui/core/Dialog');

var _Dialog4 = _interopRequireDefault(_Dialog3);

var _DialogTitle = require('@material-ui/core/DialogTitle');

var _DialogTitle2 = _interopRequireDefault(_DialogTitle);

var _DialogContent = require('@material-ui/core/DialogContent');

var _DialogContent2 = _interopRequireDefault(_DialogContent);

var _DialogActions = require('@material-ui/core/DialogActions');

var _DialogActions2 = _interopRequireDefault(_DialogActions);

var _recompose = require('recompose');

var _Button = require('@material-ui/core/Button');

var _Button2 = _interopRequireDefault(_Button);

var _styles = require('@material-ui/core/styles');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var styles = function styles(theme) {
  return {
    input: {
      marginBottom: '4rem!important',
      marginTop: '0!important'
    },
    content: {
      marginBottom: '2rem',
      display: 'block'
    }
  };
};
var interval = void 0;
var time = 20;

var MessageDialog = function (_React$PureComponent) {
  _inherits(MessageDialog, _React$PureComponent);

  function MessageDialog() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, MessageDialog);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = MessageDialog.__proto__ || Object.getPrototypeOf(MessageDialog)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
      seconds: time
    }, _this.handleLogout = function (close) {
      clearInterval(interval);
      _this.props.onClose(true);
    }, _this.handleClose = function (close) {
      clearInterval(interval);
      _this.setState({
        seconds: time
      }, function () {
        _this.props.onClose();
        close();
      });
    }, _this.getTime = function () {
      var seconds = _this.state.seconds;
      interval = setInterval(function () {
        if (seconds > 0) {
          seconds = seconds - 1;
          _this.setState({
            seconds: seconds
          });
        } else {
          clearInterval(interval);
          _this.props.onClose(true);
        }
      }, 1000);
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(MessageDialog, [{
    key: 'componentDidUpdate',
    value: function componentDidUpdate(prevProps) {
      if (!prevProps.dialog && this.props.dialog) {
        this.getTime();
      }
    }
  }, {
    key: 'render',
    value: function render() {
      var _this2 = this;

      var classes = this.props.classes;


      return _react2.default.createElement(_Dialog2.default, {
        name: 'appIdleDialog',
        render: function render(_ref2) {
          var open = _ref2.open,
              onClose = _ref2.onClose;
          return _react2.default.createElement(
            _Dialog4.default,
            { open: open, onClose: onClose },
            _react2.default.createElement(
              _DialogTitle2.default,
              null,
              'U wordt uitgelogd'
            ),
            _react2.default.createElement(
              _DialogContent2.default,
              null,
              _react2.default.createElement(
                'span',
                { className: classes.content },
                'U wordt over ',
                _this2.state.seconds,
                ' seconden uitgelogd, ingelogd blijven?'
              )
            ),
            _react2.default.createElement(
              _DialogActions2.default,
              null,
              _react2.default.createElement(
                _Button2.default,
                {
                  onClick: function onClick() {
                    return _this2.handleClose(onClose);
                  },
                  color: 'primary',
                  variant: 'contained'
                },
                'Ja'
              ),
              _react2.default.createElement(
                _Button2.default,
                {
                  onClick: function onClick() {
                    return _this2.handleLogout(onClose);
                  },
                  color: 'primary'
                },
                'Uitloggen'
              )
            )
          );
        }
      });
    }
  }]);

  return MessageDialog;
}(_react2.default.PureComponent);

exports.default = (0, _recompose.compose)((0, _styles.withStyles)(styles))(MessageDialog);