import React from 'react';
import ImageMapper from './ImageMapper';
import { openAssetsViewer, resetAssetViewer } from '@manakin/core/actions';
import { getImageData } from '@manakin/core/AssetsViewer/selectors';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import createUuid from 'uuid/v4';

const mapStateToProps = state => ({
  imageData: getImageData(state)
});

const mapDispatchToProps = dispatch => ({
  openAssets: name => dispatch(openAssetsViewer(name)),
  resetAssets: () => dispatch(resetAssetViewer())
});

class ImageMapperContainer extends React.Component {
  state = {
    uuid: createUuid()
  };

  componentDidMount() {
    if (this.props.initialValue) {
      const { initialValue } = this.props;
      let hotspots = {};
      initialValue.forEach(val => {
        hotspots[val.id] = { ...val };
      });

      this.setState({ hotspots: hotspots }, () => this.saveComponent());
    }

    if (this.props.initialImage) {
      this.setState({ image: this.props.initialImage }, () => {
        this.props.form.onFieldChange({
          key: this.props.imageName,
          value: this.props.initialImage
        });
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { uuid } = this.state;
    const prevId = prevProps.imageData[uuid]
      ? prevProps.imageData[uuid].id
      : undefined;
    const curId = this.props.imageData[uuid]
      ? this.props.imageData[uuid].id
      : undefined;
    if (prevId != curId) {
      const { imageData } = this.props;
      if (imageData[uuid]) {
        this.setState(
          {
            image: imageData[uuid],
            hotspots: {}
          },
          () => this.saveComponent()
        );
        this.saveImage(imageData);
      }
    }
    if (prevProps.initialImage != this.props.initialImage) {
      this.setState({ image: this.props.initialImage }, () => {
        this.props.form.onFieldChange({
          key: this.props.imageName,
          value: this.props.initialImage
        });
      });
    }
    if (
      this.props.initialValue &&
      prevProps.initialValue != this.props.initialValue
    ) {
      const { initialValue } = this.props;
      let hotspots = {};
      initialValue.forEach(val => {
        hotspots[val.id] = { ...val };
      });

      this.setState({ hotspots: hotspots }, () => this.saveComponent());
    }
  }

  saveComponent = () => {
    const { form, name } = this.props;
    if (this.state.hotspots) {
      const { hotspots } = this.state;
      const hotspotsArr = Object.keys(hotspots).map(key => {
        const { open, ...otherSpots } = hotspots[key];
        return {
          ...otherSpots,
          radius: 1
        };
      }, []);
      form.onFieldChange({ key: name, value: hotspotsArr });
    } else {
      form.onFieldChange({ key: name, value: [] });
    }
  };

  saveImage = imageData => {
    const { uuid } = this.state;
    const { form, imageName } = this.props;
    form.onFieldChange({
      key: imageName,
      value: {
        ...imageData[uuid],
        bynderId: imageData[uuid].id,
        derivative: imageData[uuid].size,
        url: imageData[uuid].url
      }
    });
  };

  handleImageClick = event => {
    const coords = { x: event.nativeEvent.layerX, y: event.nativeEvent.layerY };
    const imageHeight = event.nativeEvent.target.clientHeight;
    const imageWidth = event.nativeEvent.target.width;
    const x = Math.round((coords.x / imageWidth) * 100);
    const y = Math.round((coords.y / imageHeight) * 100);
    const _id = createUuid();

    this.setState(
      prevState => ({
        hotspots: {
          ...Object.keys(prevState.hotspots).map(key => ({
            ...prevState.hotspots[key],
            open: false
          })),
          [_id]: {
            x: x,
            y: y,
            title: '',
            text: '',
            popupOrientation: 'RIGHT_BOTTOM',
            open: true
          }
        }
      }),
      () => this.saveComponent()
    );
  };

  handleClick = () => {
    const { openAssets, readOnly = false } = this.props;

    if (!readOnly) {
      const { uuid } = this.state;
      openAssets(uuid);
    }
  };

  handleChange = data => {
    this.setState(
      prevState => ({
        hotspots: {
          ...prevState.hotspots,
          [data.id]: {
            ...prevState.hotspots[data.id],
            [data.name]: data.value
          }
        }
      }),
      () => this.saveComponent()
    );
  };

  handleHotspotClick = name => {
    this.setState(prevState => ({
      hotspots: {
        ...Object.keys(prevState.hotspots).map(key => ({
          ...prevState.hotspots[key],
          open: false
        })),
        [name]: {
          ...prevState.hotspots[name],
          open: true
        }
      }
    }));
  };

  handleDelete = id => {
    const hotspots = { ...this.state.hotspots };
    delete hotspots[id];

    this.setState(
      {
        hotspots: { ...hotspots }
      },
      () => this.saveComponent()
    );
  };

  handleClose = () => {
    this.setState(prevState => ({
      hotspots: {
        ...Object.keys(prevState.hotspots).map(key => ({
          ...prevState.hotspots[key],
          open: false
        }))
      }
    }));
  };

  render() {
    return (
      <div>
        <ImageMapper
          {...this.state}
          onHotspotClick={this.handleHotspotClick}
          onClick={this.handleClick}
          onImageClick={this.handleImageClick}
          onClose={this.handleClose}
          onChange={this.handleChange}
          onDelete={this.handleDelete}
        />
      </div>
    );
  }
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ImageMapperContainer);
