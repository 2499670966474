import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import renderHTML from "react-render-html";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";

const styles = theme => ({
  root: {
    display: "flex",
    flexFlow: "column nowrap",
    [theme.breakpoints.up("lg")]: {
      flexFlow: "row wrap"
    }
  },
  contentColor: {},
  secondary: {
    backgroundColor: theme.manakin.secondaryColor[500],
    "& $contentColor": {
      color: theme.palette.getContrastText(theme.manakin.secondaryColor[500])
    }
  },
  primary: {
    backgroundColor: theme.manakin.primaryColor[500],
    "& $contentColor": {
      color: theme.palette.getContrastText(theme.manakin.primaryColor[500])
    }
  },
  contentBody: {
    position: "relative",
    padding: theme.manakin.defaultPadding,
    width: "100%",
    "& ul, & ol": {
      listStylePosition: "inside"
    },
    [theme.breakpoints.up("md")]: {
      padding: "4.5rem"
    },
    [theme.breakpoints.up("lg")]: {
      padding: "15rem",
      width: "50%"
    }
  },
  contentWrapper: {
    position: "relative",
    top: 0,
    left: 0,
    display: "flex",
    flexFlow: "column",
    justifyContent: "flex-start",
    height: "100%",
    overflow: "auto",
    WebkitOverflowScrolling: "touch",
    [theme.breakpoints.up("lg")]: {
      position: "absolute",
      padding: "8% 15%"
    }
  },
  content: {
    margin: "auto"
  },
  header: {
    ...theme.manakin.workformTitleFont,
    marginBottom: "2.2rem",
    paddingBottom: "3.4rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "3rem",
      lineHeight: "4rem"
    }
  },
  body: {
    margin: 0,
    "@global ul": {
      paddingLeft: "2.25rem"
    }
  },
  button: {
    marginTop: "3rem"
  },
  imageWrapper: {
    position: "relative",
    width: "100%",
    height: 0,
    paddingTop: "50%",
    [theme.breakpoints.up("lg")]: {
      width: "50%"
    }
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
    position: "absolute",
    top: "0",
    left: "0"
  },
  left: {
    order: 1
  },
  right: {
    order: 2
  }
});

const TitleAndTextImage = props => {
  const { classes, data, withNext = false, config = {} } = props;
  const { literals = {} } = config
  const backgroundColor = data.backgroundColor || null;
  let style;

  //function
  const handleClick = () => {
    if (props.onFinished) props.onFinished();
  };

  return (
    <div>
      <div
        className={classNames(
          classes.root,
          { [classes.primary]: backgroundColor == "PRIMARY" },
          { [classes.secondary]: backgroundColor == "SECONDARY" }
        )}
      >
        <div
          className={classNames(classes.contentBody, {
            [classes.right]: data.imagePosition == "links"
          })}
          style={style}
        >
          <div className={classes.contentWrapper}>
            <div className={classes.content}>
              <Typography
                component="h2"
                variant="h2"
                classes={{ h2: classes.header }}
                className={classNames("smallUnderline", classes.contentColor)}
              >
                {data.workTitle || ""}
              </Typography>
              <Typography
                component="div"
                variant="body1"
                classes={{ body1: classes.body }}
                className={classes.contentColor}
              >
                {renderHTML(data.text || "")}
              </Typography>
            </div>
          </div>
        </div>
        <div className={classes.imageWrapper}>
          <img
            className={classNames(classes.image, {
              [classes.left]: data.imagePosition == "links"
            })}
            src={data.image && data.image.url}
          />
        </div>
      </div>
      {withNext && (
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={handleClick}
        >
          {config.APP_NEXT || "Volgende"}
        </Button>
      )}
    </div>
  );
};

export default compose(
  connect(state => ({ config: state.config })),
  withStyles(styles, { name: "AppTitleAndTextImage" })
)(TitleAndTextImage);
