"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _styles = require("@material-ui/core/styles");

var _recompose = require("recompose");

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

var _FormControl = require("@material-ui/core/FormControl");

var _FormControl2 = _interopRequireDefault(_FormControl);

var _FormControlLabel = require("@material-ui/core/FormControlLabel");

var _FormControlLabel2 = _interopRequireDefault(_FormControlLabel);

var _Radio = require("@material-ui/core/Radio");

var _Radio2 = _interopRequireDefault(_Radio);

var _RadioGroup = require("@material-ui/core/RadioGroup");

var _RadioGroup2 = _interopRequireDefault(_RadioGroup);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var styles = function styles(theme) {
  return {
    formControlRoot: _defineProperty({
      width: "100%",
      minHeight: "10rem",
      padding: "1rem",
      borderTop: "1px solid " + theme.manakin.defaultBorderColor[500],
      margin: "0",
      "&:last-child": {
        borderBottom: "1px solid " + theme.manakin.defaultBorderColor[500]
      }
    }, theme.breakpoints.up("md"), {
      padding: "1rem 3.4rem",
      borderLeft: "1px solid " + theme.manakin.defaultBorderColor[500],
      borderRight: "1px solid " + theme.manakin.defaultBorderColor[500]
    }),
    active: {
      backgroundColor: theme.manakin.primaryColor[500],
      "& $formControlLabel": {
        color: theme.palette.getContrastText(theme.manakin.primaryColor[500])
      }
    },
    formControlLabel: _defineProperty({
      color: theme.manakin.defaultContentColor[500],
      fontFamily: theme.manakin.defaultAnswerFont,
      fontSize: "1.6rem",
      lineHeight: "3.2rem",
      margin: "0 0 0 1rem"
    }, theme.breakpoints.up("md"), {
      margin: "0 0 0 3rem",
      fontSize: "1.8rem",
      lineHeight: "3.8rem"
    }),
    formControl: {
      marginBottom: "2rem"
    }
  };
};

var AppRadioGroup = function (_React$PureComponent) {
  _inherits(AppRadioGroup, _React$PureComponent);

  function AppRadioGroup() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, AppRadioGroup);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = AppRadioGroup.__proto__ || Object.getPrototypeOf(AppRadioGroup)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
      value: ""
    }, _this.handleChange = function (event) {
      _this.setState({ value: event.target.value || "" });
      _this.props.onChange(event);
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(AppRadioGroup, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      if (this.props.initialValue) {
        this.setState({ value: this.props.initialValue || "" });
      }
    }
  }, {
    key: "componentDidUpdate",
    value: function componentDidUpdate(prevProps) {
      if (prevProps.initialValue != this.props.initialValue) {
        this.setState({ value: this.props.initialValue || "" });
      }
    }
  }, {
    key: "render",
    value: function render() {
      var _props = this.props,
          classes = _props.classes,
          answers = _props.answers,
          _props$done = _props.done,
          done = _props$done === undefined ? false : _props$done;
      var _state$value = this.state.value,
          value = _state$value === undefined ? "" : _state$value;


      return _react2.default.createElement(
        _FormControl2.default,
        {
          component: "fieldset",
          className: classes.formControl,
          disabled: done
        },
        _react2.default.createElement(
          _RadioGroup2.default,
          { name: "textMPC", onChange: this.handleChange, value: value },
          answers && answers.map(function (item) {
            return _react2.default.createElement(_FormControlLabel2.default, {
              key: item.id,
              classes: {
                root: classes.formControlRoot,
                label: classes.formControlLabel
              },
              className: (0, _classnames2.default)(_defineProperty({}, classes.active, value == item.id)),
              control: _react2.default.createElement(_Radio2.default, { color: "primary" }),
              label: item.title,
              value: item.id
            });
          })
        )
      );
    }
  }]);

  return AppRadioGroup;
}(_react2.default.PureComponent);

exports.default = (0, _recompose.compose)((0, _styles.withStyles)(styles, { name: "AppRadioGroup" }))(AppRadioGroup);