import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { GQL_FETCH_SCENE } from "./graphql/graphql";
import { compose } from "recompose";
import { graphql } from "react-apollo";
import { Loader } from "@manakin/app-core";
import { VideoPlayer } from "@manakin/app-core";

const styles = theme => ({
  root: {
    position: "relative",
    width: "100%",
    backgroundColor: "white"
  },
  loader: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
  }
});

const Scene = props => {
  const {
    classes,
    data,
    showSkipButton = true,
    freeze = false,
    playing = true
  } = props;
  const { loading, scene: sceneData = {} } = data;

  const handleEnded = () => {
    if (props.onNext) {
      props.onNext({ correct: true, workform: { ...sceneData } });
    }
  };

  return (
    <div className={classes.root}>
      {!loading ? (
        <VideoPlayer
          videoId={sceneData.video}
          showSkipButton={showSkipButton}
          handleEnded={handleEnded}
          freeze={freeze}
          playing={playing}
        />
      ) : (
          <div className={classes.loader}>
            {" "}
            <Loader color="light" />{" "}
          </div>
        )}

    </div>
  );
};

export default compose(
  withStyles(styles),
  graphql(GQL_FETCH_SCENE, {
    options: props => ({ variables: { id: props.workformId } })
  })
)(Scene);
