import React, { useEffect } from "react";
import AppViews from "@manakin/app-views/Router";
import { AppBar, SearchBar, HeartBeat, HelpButton } from "@manakin/app-core";
import { AppAccount } from "@manakin/app-account";
import { compose } from "recompose";
import AppMenu from "../AppMenu";
import { loadConfig } from "../../config/config";
import loadConfiguration from "../../config";
import Cookies from "js-cookie";
import { connect } from "react-redux";

const App = props => {
  const locale = Cookies.get("locale") || "";
  const { loadConfig } = props;

  useEffect(() => {
    loadConfiguration(locale).then(configuration =>
      loadConfig(configuration)
    );
    // eslint-disable-next-line
  }, [locale]);

  return (
    <HeartBeat>
      <div className="App">
        <AppBar />
        <AppAccount />
        <AppViews />
        <AppMenu />
        <SearchBar />
        <HelpButton locale={locale} />
      </div>
    </HeartBeat>
  );
};

export default compose(
  connect(null, dispatch => ({
    loadConfig: data => dispatch(loadConfig(data))
  }))
)(App);
