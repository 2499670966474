"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _styles = require("@material-ui/core/styles");

var _appCore = require("@manakin/app-core");

var _Typography = require("@material-ui/core/Typography");

var _Typography2 = _interopRequireDefault(_Typography);

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

var _reactRouterDom = require("react-router-dom");

var _moment = require("moment");

var _moment2 = _interopRequireDefault(_moment);

require("moment/min/locales");

var _recompose = require("recompose");

var _reactRedux = require("react-redux");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var styles = function styles(theme) {
  return {
    body1: {},
    title: {},
    longArrow: {
      transform: "translateX(0)",
      transition: "transform .2s",
      "& .arrow": {
        background: theme.palette.secondary[500]
      }
    },
    root: _defineProperty({
      cursor: "pointer",
      backgroundColor: "white",
      width: "100%",
      minHeight: "7rem",
      display: "flex",
      alignItems: "center",
      padding: "1.8rem",
      position: "relative",
      textDecoration: "none",
      "& $body1": _defineProperty({
        margin: 0,
        marginRight: "1.4rem",
        textAlign: "left",
        transition: "color .2s"
      }, theme.breakpoints.up("md"), {
        marginRight: "3.6rem"
      }),
      "& $title": {
        fontWeight: "900",
        textAlign: "left"
      },
      "&:hover": {
        "& $longArrow": {
          transform: "translateX(5px)"
        },
        "& $body1": {
          color: theme.manakin.defaultContentHover[500]
        }
      }
    }, theme.breakpoints.up("md"), {
      padding: "1.8rem 22.8rem 1.8rem 1.8rem",
      minHeight: "10rem"
    }),
    themeIcon: {
      width: "7.4rem",
      height: "7.4rem",
      marginRight: "3.6rem"
    },
    time: {},
    end: _defineProperty({
      position: "absolute",
      right: "4rem",
      top: "50%",
      transform: "translateY(-50%)",
      alignItems: "center",
      display: "none"
    }, theme.breakpoints.up("md"), {
      display: "flex"
    }),
    buttonText: {},
    imageContainer: _defineProperty({
      display: "none",
      width: "100px",
      marginRight: "17px"
    }, theme.breakpoints.up("sm"), {
      display: "block"
    }),
    image: _defineProperty({
      display: "none",
      width: "100px"
    }, theme.breakpoints.up("sm"), {
      display: "block"
    })
  };
};

var ResumeButton = function ResumeButton(props) {
  var classes = props.classes,
      _props$config = props.config,
      config = _props$config === undefined ? {} : _props$config;
  var _config$literals = config.literals,
      literals = _config$literals === undefined ? {} : _config$literals;


  var time = _moment2.default.unix(props.time).locale(literals.LOCALE || "en").calendar();

  return _react2.default.createElement(
    _reactRouterDom.Link,
    { className: classes.root, to: "/box/" + props.id },
    props.theme && _react2.default.createElement(_appCore.ThemeIcon, { variant: props.theme, className: classes.themeIcon }),
    props.image && props.image.url && _react2.default.createElement(
      "div",
      { className: classes.imageContainer },
      _react2.default.createElement(_appCore.LoadImage, {
        src: props.image.url || "",
        placeholder: props.image.thumbnailUrl || "",
        className: classes.image,
        align: "right"
      })
    ),
    _react2.default.createElement(
      _Typography2.default,
      { className: (0, _classnames2.default)(classes.body1, classes.time) },
      time
    ),
    _react2.default.createElement(
      _Typography2.default,
      { className: (0, _classnames2.default)(classes.body1, classes.title) },
      props.name || ""
    ),
    _react2.default.createElement(
      "div",
      { className: classes.end },
      _react2.default.createElement(
        _Typography2.default,
        { className: (0, _classnames2.default)(classes.body1, classes.buttonText) },
        literals.APP_RESUME_BUTTON || "Ga verder"
      ),
      _react2.default.createElement(_appCore.LongArrow, { className: classes.longArrow })
    )
  );
};

exports.default = (0, _recompose.compose)((0, _reactRedux.connect)(function (state) {
  return {
    config: state.config
  };
}), (0, _styles.withStyles)(styles, { name: "AppResumeButton" }))(ResumeButton);