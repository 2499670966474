import React, { useEffect, useState } from 'react';
import ChoiceQuestion from './ChoiceQuestion';

const ChoiceQuestionContainer = props => {
  const { data, options } = props;

  //state hooks
  const [feedback, setFeedback] = useState(
    data.finished && options && options.showFeedback ? true : false
  );
  const [agree, setAgree] = useState(
    data.finished ? (data.correct ? data.answer : !data.answer) : null
  );
  const [correct, setIsCorrect] = useState(data.correct ? data.correct : null);
  const [prevCorrect, setPrevCorrect] = useState(
    data.correct ? data.correct : null
  );

  //effect hooks
  useEffect(() => {
    if (correct != prevCorrect) {
      setPrevCorrect(correct);
      if (options && options.showFeedback) {
        setFeedback(true);
      } else {
        handleFinish();
      }
    }
  }, [correct]);

  //functions
  const handleSubmit = userAnswer => {
    setAgree(userAnswer);
    setIsCorrect(data.answer === userAnswer);
  };

  const handleFinish = () => {
    props.onFinished({
      correct: correct,
      workform: { ...data, done: data.finished }
    });
  };

  return (
    <ChoiceQuestion
      {...props}
      agree={agree}
      correct={correct}
      feedback={feedback}
      onSubmit={handleSubmit}
      onFinished={handleFinish}
    />
  );
};

export default ChoiceQuestionContainer;
