import React from "react";
import { GQL_FETCH_CASE, GQL_FETCH_GLOBAL_SETTINGS } from "./graphql";
import { graphql } from "react-apollo";
import { compose } from "recompose";
import { ElementOverlay, withWorkforms } from "@manakin/app-core";
import Case from "./Case";
import { getBoxId } from "@manakin/app-core/Boxes/selectors";
import { connect } from "react-redux";

const mapStateToProps = state => ({
  config: state.config,
  boxId: getBoxId(state)
});

class CaseContainer extends React.Component {
  state = {
    showContent: false,
    step: -1,
    characteristic: [],
    closeChatBubble: false
  };

  componentDidUpdate(prevProps) {
    if (prevProps.data.loading && !this.props.data.loading) {
      const { data, workformsData, location } = this.props;

      const options = {
        showFeedback: true,
        showFeedbackTitle: false,
        nextButton: true
      };

      if (data.case) {
        data.case.workforms &&
          workformsData.loadWorkforms({
            ...data.case,
            options: options,
            elementId: this.props.match.params.elementId
          });
      }

      if (location.state && (location.state.idx || location.state.idx === 0)) {
        setTimeout(() => {
          this.setState({
            showContent: true,
            step: location.state.idx,
            characteristic: location.state.characteristic
          });
        }, 2000);
      }
    }

    if (prevProps.SETTINGS.loading && !this.props.SETTINGS.loading) {
      const { SETTINGS } = this.props;
      const { settings = {} } = SETTINGS;
      settings.settings &&
        settings.settings.forEach(item => {
          if (item.name == "casePrefix") {
            this.setState({
              prefix: item.value
            });
          }
        });
    }
  }

  handleClick = (initial = 0) => {
    this.setState({ showContent: true, step: initial });
  };

  handleNext = data => {
    const { workformsData } = this.props;
    const { workforms } = workformsData;
    const { step } = this.state;
    const workformLength = workforms.length || 0;

    if (step < workformLength - 1) {
      this.setState({
        step: step + 1
      });
    } else {
      this.handleFinish();
    }

    if (data && data.characteristic && data.characteristic.length) {
      let _characteristic = [...this.state.characteristic];
      const merge = _characteristic.concat(data.characteristic);
      this.setState({ characteristic: merge });
    }
  };

  handleFinish = () => {
    this.props.workformsData
      .saveElement({
        elementId: this.props.match.params.elementId
      })
      .then(() => {
        const { workformsData = {}, boxId } = this.props;
        if (
          workformsData.workforms &&
          workformsData.workforms[workformsData.workforms.length - 1].type ===
            "ChatBubble"
        ) {
          this.setState({ closeChatBubble: true });
        } else {
          this.props.history.push(`/box/${boxId}`);
        }
      });
  };

  handleClose = () => {
    const { boxId } = this.props;
    this.props.history.push(`/box/${boxId}`);
  };

  render() {
    const { workformsData = {}, config = {} } = this.props;
    const { prefix = null } = this.state;
    const { rawData = {} } = workformsData;

    return (
      <ElementOverlay
        fullWidth={true}
        title={rawData.preTitle || prefix || "Case"}
      >
        <Case
          {...workformsData}
          stateData={{ ...this.state }}
          onExit={this.handleFinish}
          onClick={this.handleClick}
          onNext={this.handleNext}
          onClose={this.handleClose}
        />
      </ElementOverlay>
    );
  }
}

export default compose(
  connect(mapStateToProps),
  graphql(GQL_FETCH_CASE, {
    options: props => ({ variables: { id: props.match.params.elementId } })
  }),
  graphql(GQL_FETCH_GLOBAL_SETTINGS, {
    name: "SETTINGS"
  }),
  withWorkforms()
)(CaseContainer);
