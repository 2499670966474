import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { compose } from 'recompose';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';

const style = theme => ({
  preHeading: {
    textAlign: 'center',
    width: '100%',
    margin: '0 auto 1em',
    maxWidth: '560px'
  },
  wrapper: {
    textAlign: 'center'
  },
  link: {
    textDecoration: 'none'
  },
  root: {
    height: '100vh',
    padding: '80px 0 0',
    backgroundColor: theme.manakin.primaryColor[500],
    display: 'flex'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    height: '100%',
    maxWidth: '900px',
    alignItems: 'center',
    margin: '0 auto',
    alignSelf: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '80%'
    }
  },
  content: {
    width: '100%',
    margin: '0 0 80px',
    textAlign: 'center',
    position: 'relative',
    zIndex: 1
  },

  heading: {
    textAlign: 'center',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      letterSpacing: '0.1rem'
    }
  },
  paragraph: {
    textAlign: 'center',
    width: '100%',
    margin: '0 auto 3em',
    maxWidth: '560px'
  },
  smallParagraph: {
    textAlign: 'center',
    width: '100%',
    margin: '0 auto',
    marginBottom: '0.5em',
    maxWidth: '560px'
  }
});

class AppConfirmRegistration extends React.Component {
  state = {
    licenses: []
  };

  componentDidMount() {
    const { mutate, token } = this.props;
    mutate({
      variables: {
        token: token
      }
    }).then(data => {
      if (data && !data.errors) {
        const licenses = data.data.loginTokenApp
          ? data.data.loginTokenApp.user.licenses
          : [];

        this.setState({
          licenses: licenses
        });
      }
    });
  }

  render() {
    const { classes, config } = this.props;
    const { licenses } = this.state;

    return (
      <div className={classes.root}>
        <form className={classes.container}>
          <div className={classes.content}>
            <Typography
              classes={{ body1: classes.smallParagraph }}
              variant="body1"
              gutterBottom
            >
              Bedankt!
            </Typography>
            <Typography classes={classes.heading} variant="h2">
              Je account is geactiveerd
            </Typography>
            <Typography
              classes={{ body1: classes.paragraph }}
              variant="body1"
              gutterBottom
            >
              {config &&
                config.literals &&
                config.literals.APP_REGISTRATION_SUCCESS_MESSAGE}
            </Typography>

            {licenses.length > 0 && (
              <Link className={classes.link} to={'/dashboard'}>
                <Button variant="contained" color="primary">
                  Ga verder
                </Button>
              </Link>
            )}
            {licenses.length == 0 && (
              <Link className={classes.link} to={'/auth/no-licenses'}>
                <Button variant="contained" color="primary">
                  Ga verder
                </Button>
              </Link>
            )}
          </div>
        </form>
      </div>
    );
  }
}

export default compose(
  withStyles(style, { name: 'appConfirmRegistration' }),
  connect(({ config }) => ({
    config
  }))
)(AppConfirmRegistration);
