"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _Fab = require("@material-ui/core/Fab");

var _Fab2 = _interopRequireDefault(_Fab);

var _Clear = require("@material-ui/icons/Clear");

var _Clear2 = _interopRequireDefault(_Clear);

var _ArrowRightAlt = require("@material-ui/icons/ArrowRightAlt");

var _ArrowRightAlt2 = _interopRequireDefault(_ArrowRightAlt);

var _styles = require("@material-ui/core/styles");

var _Paper = require("@material-ui/core/Paper");

var _Paper2 = _interopRequireDefault(_Paper);

var _Typography = require("@material-ui/core/Typography");

var _Typography2 = _interopRequireDefault(_Typography);

var _appCore = require("@manakin/app-core");

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

var _styles2 = require("./styles");

var _reactRouterDom = require("react-router-dom");

var _reactRouter = require("react-router");

var _recompose = require("recompose");

var _reactRedux = require("react-redux");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var HelpButton = function HelpButton(props) {
  var classes = props.classes,
      onClick = props.onClick,
      show = props.show,
      settings = props.settings,
      location = props.location,
      _props$config = props.config,
      config = _props$config === undefined ? {} : _props$config;
  var _config$literals = config.literals,
      literals = _config$literals === undefined ? {} : _config$literals;

  var pathname = location.pathname;

  return _react2.default.createElement(
    "div",
    { className: classes.root },
    _react2.default.createElement(
      _Paper2.default,
      {
        className: (0, _classnames2.default)(classes.paper, _defineProperty({}, classes.show, show)),
        elevation: 20
      },
      _react2.default.createElement(
        _Typography2.default,
        { component: "h3", variant: "h6", className: classes.heading },
        settings.helpTitle
      ),
      settings.helpIntroduction && _react2.default.createElement(
        _Typography2.default,
        {
          component: "p",
          variant: "body1",
          classes: {
            body1: classes.body
          }
        },
        pathname.indexOf("/auth") === -1 && settings.helpIntroduction
      ),
      _react2.default.createElement(
        "div",
        { className: classes.panels },
        settings.helpFAQTitle && pathname.indexOf("/auth") === -1 && _react2.default.createElement(_appCore.AppExpansionPanel, {
          summary: _react2.default.createElement(
            "div",
            { className: classes.summary },
            _react2.default.createElement(_ArrowRightAlt2.default, null),
            settings.helpFAQTitle
          ),
          details: _react2.default.createElement(
            "div",
            { onClick: onClick },
            (literals.APP_HELP_FAQ_TITLE_FRONT || "Vragen? check onze") + " ",
            _react2.default.createElement(
              _reactRouterDom.Link,
              { to: "/faq" },
              literals.APP_FAQ_SHORT || "FAQ"
            )
          )
        }),
        settings.helpPhoneTitle && _react2.default.createElement(_appCore.AppExpansionPanel, {
          summary: _react2.default.createElement(
            "div",
            { className: classes.summary },
            _react2.default.createElement(_ArrowRightAlt2.default, null),
            settings.helpPhoneTitle
          ),
          details: _react2.default.createElement(
            "div",
            { onClick: onClick },
            "" + (literals.APP_HELP_CALL || "Bel ons op"),
            " ",
            settings.helpPhoneNumber || ""
          )
        }),
        settings.helpMailTitle && _react2.default.createElement(_appCore.AppExpansionPanel, {
          summary: _react2.default.createElement(
            "div",
            { className: classes.summary },
            _react2.default.createElement(_ArrowRightAlt2.default, null),
            settings.helpMailTitle
          ),
          details: _react2.default.createElement(
            "div",
            { onClick: onClick, className: classes.content },
            literals.APP_HELP_MAIL || "Mail ons op",
            " ",
            _react2.default.createElement(
              "a",
              { href: "mailto:" + (settings.helpMailEmail || "") },
              settings.helpMailEmail || ""
            )
          )
        })
      )
    ),
    _react2.default.createElement(
      _Fab2.default,
      {
        color: "primary",
        "aria-label": "Add",
        className: classes.fab,
        onClick: onClick
      },
      _react2.default.createElement(
        "span",
        {
          className: (0, _classnames2.default)(classes.icon, classes.question, _defineProperty({}, classes.showIcon, !show))
        },
        "?"
      ),
      _react2.default.createElement(
        "span",
        {
          className: (0, _classnames2.default)(classes.icon, _defineProperty({}, classes.showIcon, show))
        },
        _react2.default.createElement(_Clear2.default, null)
      )
    )
  );
};

exports.default = (0, _recompose.compose)((0, _reactRedux.connect)(function (state) {
  return { config: state.config };
}), (0, _styles.withStyles)(_styles2.styles, { name: "HelpButton" }), _reactRouter.withRouter)(HelpButton);