export default {
  users: {
    read: 'USERS_READ',
    write: 'USERS_WRITE',
    delete: 'USERS_DELETE'
  },
  groups: {
    read: 'GROUPS_READ',
    write: 'GROUPS_WRITE',
    delete: 'GROUPS_DELETE'
  },
  programs: {
    read: 'PROGRAMS_READ',
    write: 'PROGRAMS_WRITE',
    delete: 'PROGRAMS_DELETE'
  },
  products: {
    read: 'PRODUCTS_READ',
    write: 'PRODUCTS_WRITE',
    delete: 'PRODUCTS_DELETE'
  },
  licenses: {
    read: 'LICENSES_READ',
    write: 'LICENSES_WRITE',
    delete: 'LICENSES_DELETE'
  },
  learningTargets: {
    read: 'LEARNING_TARGETS_READ',
    write: 'LEARNING_TARGETS_WRITE',
    delete: 'LEARNING_TARGETS_DELETE'
  },
  boxes: {
    read: 'BOXES_READ',
    write: 'BOXES_WRITE',
    delete: 'BOXES_DELETE'
  },
  workforms: {
    read: 'WORKFORMS_READ',
    write: 'WORKFORMS_WRITE',
    delete: 'WORKFORMS_DELETE'
  },
  elements: {
    read: 'ELEMENTS_READ',
    write: 'ELEMENTS_WRITE',
    delete: 'ELEMENTS_DELETE'
  },
  settings: {
    read: 'SETTINGS_READ',
    write: 'SETTINGS_WRITE',
    delete: 'SETTINGS_DELETE'
  },
  factCards: {
    read: 'FACT_CARDS_READ',
    write: 'FACT_CARDS_WRITE',
    delete: 'FACT_CARDS_DELETE'
  },
  faq: {
    read: 'FAQ_READ',
    write: 'FAQ_WRITE',
    delete: 'FAQ_DELETE'
  },
  organisations: {
    read: 'ORGANISATIONS_READ',
    write: 'ORGANISATIONS_WRITE',
    delete: 'ORGANISATIONS_DELETE'
  },
  messages: {
    read: 'MESSAGE_READ',
    write: 'MESSAGE_WRITE',
    delete: 'MESSAGE_DELETE'
  },
  notifications: {
    read: 'NOTIFICATION_READ',
    write: 'NOTIFICATION_WRITE',
    delete: 'NOTIFICATION_DELETE'
  }
};
