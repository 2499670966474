import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { WorkformLayout, LongArrow } from "@manakin/app-core";
import { FeedbackBox } from "@manakin/app-workforms";
import classNames from "classnames";
import Button from "@material-ui/core/Button";
import { styles } from "./styles";
import { connect } from "react-redux";

class ImageAndTextCheckQuestion extends React.PureComponent {
  handleClick = idx => {
    if (this.props.onChange && !this.props.data.done) this.props.onChange(idx);
  };

  handleSubmit = () => {
    if (this.props.onSubmit) this.props.onSubmit();
  };

  render() {
    const {
      classes,
      data,
      onFinished,
      disabled = false,
      config = {}
    } = this.props;
    const { literals = {} } = config;

    return (
      <WorkformLayout
        question={data.question || ""}
        instruction={
          data.introduction ||
          literals.CMS_SELECT_MULTIPLE_ANSWERS ||
          "Selecteer meerder antwoorden"
        }
        loading={false}
        renderAnswers={() => (
          <div className={classes.wrapper}>
            <div className={classes.answersRoot}>
              {data.answers &&
                data.answers.map((answer, i) => (
                  <div
                    key={answer.id}
                    className={classes.card}
                    onClick={() => this.handleClick(i)}
                  >
                    <div
                      className={classNames(classes.checked, {
                        [classes.showIcon]: answer.selected
                      })}
                    />
                    <div className={classes.innerCard}>
                      <div className={classes.image}>
                        <div className={classes.imageWrapper}>
                          <img
                            src={(answer.image && answer.image.url) || ""}
                            className={classes.backgroundImage}
                          />
                        </div>
                        {answer.title && (
                          <div className={classes.textWrapper}>
                            <div className={classes.text}>{answer.title}</div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            <div className={classes.buttons}>
              {!data.feedback ? (
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  className={classNames(classes.button, {
                    [classes.hide]: data.feedback || false
                  })}
                  onClick={this.handleSubmit}
                  disabled={disabled}
                >
                  {data.buttonText ||
                    literals.APP_CHECK_ANSWER ||
                    "Check mijn antwoord"}
                  <LongArrow className={classes.longArrowRoot} />
                </Button>
              ) : (
                <div
                  className={classNames(classes.feedback, {
                    [classes.activeFeedback]: data.feedback
                  })}
                >
                  <FeedbackBox
                    data={data}
                    onFinished={onFinished}
                    outlined={true}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      />
    );
  }
}

export default compose(
  connect(state => ({ config: state.config })),
  withStyles(styles, { name: "AppImageAndTextCheckQuestion" })
)(ImageAndTextCheckQuestion);
