'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var styles = exports.styles = function styles(theme) {
  return {
    title: {},
    subTitle: {},
    content: {},
    messagesRoot: {
      '& $title': {
        fontFamily: theme.manakin.defaultContentFont,
        lineHeight: '2.6rem',
        margin: 0
      },
      '& $subTitle': {
        margin: 0
      },
      '& $content': {
        margin: 0
      }
    },
    deleteIcon: {
      top: '.6rem',
      marginRight: '2rem',
      position: 'relative',
      opacity: 0,
      pointerEvents: 'none',
      transition: 'opacity .2s'
    },
    panel: {
      '&:hover': {
        '& $deleteIcon': {
          opacity: 1,
          pointerEvents: 'visible'
        }
      }
    },
    date: {
      fontFamily: theme.manakin.defaultContentFont,
      fontSize: '1.6rem',
      position: 'absolute',
      right: '2.4rem',
      top: '50%',
      transform: 'translateY(-50%)',
      padding: '0!important'
    },
    new: {
      position: 'absolute',
      top: '2.2rem',
      left: 0,
      width: '1.2rem',
      height: '1.2rem',
      backgroundColor: 'red',
      borderRadius: '100%'
    },
    read: {
      display: 'none'
    }
  };
};