import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Paper from "../Paper";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import { compose, withHandlers } from "recompose";
import { Link } from "react-router-dom";
import LoginForm from "../LoginForm";
import { GQL_LOGIN_APP } from "../graphql";
import { graphql } from "react-apollo";
import { login } from "../actions";
import { showSnackbarMessage } from "@manakin/core/actions";
import "url-search-params-polyfill";
import Cookies from "js-cookie";

const style = theme => ({
  root: {
    backgroundColor: "rgba(0,0,0,0)",
    boxShadow: "none"
  },
  registrationLink: {
    display: "block",
    textDecoration: "none",
    marginTop: theme.spacing(2)
  },
  link: {
    textDecoration: "none",
    display: "block",
    textAlign: "center",
    color: theme.manakin.defaultContentColor[500],
    marginTop: theme.spacing(3),
    fontSize: "18px",
    "&:visited": {
      color: theme.manakin.defaultContentColor[500]
    }
  },
  button: {}
});

class Import extends React.Component {
  handleSubmit = event => {
    const redirect = "/";
    this.props.onLogin({ ...event, redirect });
  };

  render() {
    const { classes, children } = this.props;
    return (
      <Paper className={classes.root}>
        {children}
        <LoginForm onSubmit={this.handleSubmit} />
        <Link className={classes.registrationLink} to={"registration"}>
          <Button
            variant="outlined"
            color="primary"
            className={classes.button}
            fullWidth
          >
            Account aanmaken
          </Button>
        </Link>
        <Link className={classes.link} to={`reset`}>
          Wachtwoord vergeten
        </Link>
      </Paper>
    );
  }
}

export default compose(
  connect(null, dispatch => ({
    processSuccess: (redirect, user) => dispatch(login(redirect, user)),
    processLoginFailed: message =>
      dispatch(
        showSnackbarMessage({
          text:
            message ||
            "Inloggen mislukt, mogelijk zijn er onjuiste gegevens ingevuld.",
          variant: "error"
        })
      )
  })),
  graphql(GQL_LOGIN_APP),
  withHandlers({
    onLogin: ({ mutate, processSuccess, processLoginFailed }) => event => {
      mutate({
        variables: {
          ...event
        }
      })
        .then(data => {
          if (!data.errors) {
            if (data.data.loginApp.user && data.data.loginApp.user.locale) {
              Cookies.set(
                "locale",
                data.data.loginApp.user.locale.code || "en_US"
              );
            }
            processSuccess(event.redirect, data.data.loginApp.user);
          } else {
            let message = null;
            if (
              data.errors.some(item => item.message === "APP user is disabled")
            )
              message =
                "Om administratieve redenen staat u account op inactief.";
            processLoginFailed(message);
          }
        })
        .catch(e => {
          processLoginFailed();
        });
    }
  }),
  withStyles(style, { name: "AppLogin" })
)(Import);
