import React from "react";
import { Route } from "react-router-dom";
import { PreviewContainer } from "../Lesson";
import { BookCheckPreview } from "../BookCheck";
import { TrainerPreview } from "../Trainer";
import { FactCardPreview } from "../FactCard";
import { TransitionRouter } from "@manakin/app-core";

class Router extends React.PureComponent {
  render() {
    return (
      <TransitionRouter>
        <Route path={"/preview/Lesson/:query"} component={PreviewContainer} />
        <Route
          path={"/preview/BookCheck/:query"}
          component={BookCheckPreview}
        />
        <Route path={"/preview/Trainer/:query"} component={TrainerPreview} />
        <Route
          path={"/preview/Factcard/:factCardId/:userId"}
          component={FactCardPreview}
        />
      </TransitionRouter>
    );
  }
}

export default Router;
