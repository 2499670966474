import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { login } from '../actions';
import jwtDecode from 'jwt-decode';
import { withApollo, graphql } from 'react-apollo';
import Cookies from 'js-cookie';
import {
	GQL_FETCH_APP_USER,
} from '../graphql';
import {push} from 'redux-first-history';

class AppImpersonateUser extends React.PureComponent {
    componentDidMount() {
        const token = Cookies.get('jwt_bespeak');
        if(token != undefined) {
            const decoded = token == 'deleted' ? {} : jwtDecode(token);
            if(decoded.username) {
                this.props.client.query({
                    query: GQL_FETCH_APP_USER,
                    variables: {
                        id: decoded.username,
                    }
                }).then(result => {
                    if(result.data.appUser) {
                        this.props.processSuccess('/dashboard', result.data.appUser)
                    }else this.props.processWrong()
                })
            } else this.props.processWrong()
        } else this.props.processWrong()
    }

    render() {
        return 'loading'
    }
}

export default compose(
	connect(
		null,
		dispatch => ({
            processSuccess: (redirect, user) => dispatch(login(redirect, user)),
            processWrong: () => dispatch(push("/desktop"))
		})
    ),
    withApollo
)(AppImpersonateUser)