import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    root: {}
});

class Module extends React.PureComponent {
    render() {
        const { children, classes, loading } = this.props;

        return (
            <div className={classes.root}>
                {children}
            </div>
        );
    }
}

export default withStyles(styles, { name: 'ManakinCoreModule' })(Module);
