import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  Typography,
  withStyles
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { compose } from "recompose";
import { connect } from "react-redux";

const styles = theme => ({
  popupRoot: {
    width: "100%",
    padding: "50px!important",
    [theme.breakpoints.up("md")]: {
      padding: "0 0 5rem!important",
      minWidth: "50rem"
    }
  },
  dialogPaper: {
    padding: 0,
    width: "100%",
    fontSize: "2.2rem",
    position: "relative"
  },
  heading: {
    width: "100%",
    minHeight: "9rem"
  },
  nextCategory: {
    color: theme.palette.secondary[400],
    fontSize: "2.2rem",
    fontWeight: 600
  },
  title: {
    fontSize: "3rem",
    fontWeight: "bold",
    color: theme.palette.secondary[500],
    textAlign: "left",
    [theme.breakpoints.up("md")]: {
      fontSize: "5rem",
      lineHeight: "6.4rem"
    }
  },
  body: {
    textAlign: "center"
  },
  points: {
    textAlign: "center",
    color: theme.palette.primary[500],
    fontSize: "2.2rem",
    marginBottom: "3.9rem"
  },
  slider: {
    marginBottom: "50px"
  }
});

const RowBonusPopup = props => {
  const { open = false, classes, config = {} } = props;
  const { literals = {} } = config;

  //functions
  const handleClick = () => {
    props.onRealClose();
  };

  return (
    <div>
      <Dialog open={open} onClose={props.onClose}>
        <DialogContent className={classes.popupRoot}>
          <div className={classes.heading}>
            <Typography variant="body2" className={classes.title}>
              {literals.APP_ON_FIRE || "You're on fire"}
            </Typography>
            <Typography variant="body2" className={classes.body}>
              {literals.APP_THREE_CORRECT || "3 correct answers in a row!"}
            </Typography>
            <Typography variant="body2" className={classes.points}>
              +50 {literals.APP_BONUS_POINTS || "bonuspoints"}
            </Typography>
          </div>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            className={classes.button}
            onClick={handleClick}
          >
            {literals.APP_LESSON_NEXT_QUESTION || "Next question"}
          </Button>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default compose(
  connect(state => ({ config: state.config })),
  withStyles(styles)
)(RowBonusPopup);
