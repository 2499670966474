import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Shifter from '../AppMenu/Shifter';

const styles = theme => ({
    root: {}
});

const AppContent = ({
    children,
    classes,
    className: classNameProp
}) => {
    const className = classNames(
        classes.root,
        classNameProp
    );

    return (
        <Shifter
            className={className}
            render={() => <React.Fragment>{children}</React.Fragment>}
        />
    );
};

export default withStyles(styles, { name: 'ManakinCoreAppContent' })(AppContent);
