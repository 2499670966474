import React from "react";
import { WorkformLayout, VideoPlayer } from "@manakin/app-core";
import { compose } from "recompose";
import { connect } from "react-redux";

class VideoWithTitle extends React.PureComponent {
  render() {
    const { data, config = {} } = this.props;
    const { literals = {} } = config;

    return (
      <WorkformLayout
        question={data.workTitle || ""}
        instruction={
          data.introduction || literals.CMS_WATCH_VIDEO || "Bekijk de video"
        }
        quarterQuestion={true}
        loading={false}
        mirror={true}
        renderAnswers={() => (
          <VideoPlayer videoId={data.video} playing={false} />
        )}
      />
    );
  }
}

export default compose(connect(state => ({ config: state.config })))(
  VideoWithTitle
);
