import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import MoreIcon from '@material-ui/icons/MoreVert';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

const styles = theme => ({
	root: {
		position: 'absolute',
		width: '30px',
		height: '30px',
		transform: 'translate(-50%, -50%)',
		backgroundColor: 'white',
		zIndex: '2',
		borderRadius: '100%'
	},
	fields: {
		position: 'absolute',
		left: '100%',
		transform: 'translateX(10px)',
		padding: '10px',
		zIndex: '2'
	},
	navigation: {
		backgroundColor: 'white',
		width: '150px',
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)'
	},
	field: {
		minWidth: '300px',
		margin: '10px 30px'
	}
})

class Hotspot extends React.PureComponent {
	state = {
		edit: false,
	}

	handleClick = () => {
		this.setState(prevState => ({
			edit: !prevState.edit,
		}), () => this.props.onClose())
	}

	handleChange = name => event => {
		if(this.props.onChange){
			this.props.onChange({name: name, id: this.props.id, value: event.target.value});	
		} 
	}

	handleHotspotClick = id => event => {
		if(this.props.onHotspotClick) this.props.onHotspotClick(id)
	}

	render() {
		const { hotspot, classes, id, onChange, onDelete, onClose } = this.props;

		return (
			<div className={classes.root} style={{left: `${hotspot.x}%`, top: `${hotspot.y}%`}} >
		        <Fab size="small" color="secondary" aria-label="Add" className={classes.hotspot} onClick={this.handleHotspotClick(id)}>
		          <MoreIcon />
		        </Fab>
		        {hotspot.open && (
			        <div className={classes.navigation}>
						<List component="nav">
							<ListItem button onClick={this.handleClick}>
								<ListItemText primary="Wijzigen" />
							</ListItem>
							<ListItem button onClick={() => onDelete(id)}>
								<ListItemText primary="Verwijderen" />
							</ListItem>
							<ListItem button onClick={onClose}>
								<ListItemText primary="Sluiten" />
							</ListItem>
						</List>
					</div>
				)}
				<Dialog open={this.state.edit} aria-labelledby="simple-dialog-title" className={classes.dialog}>
		        	<DialogTitle id="simple-dialog-title">Hotspot wijzigen</DialogTitle>
			        <TextField
			          id="title"
			          label="Titel"
			          className={classes.field}
			          value={hotspot.title}
			          onChange={this.handleChange('title')}
			          margin="normal"
			        />
			        <TextField
			          id="text"
			          label="Text"
			          className={classes.field}
			          value={hotspot.text}
			          onChange={this.handleChange('text')}
			          margin="normal"
			        />
			        <FormControl className={classes.field}>
						<InputLabel shrink htmlFor="popupOrientation-placeholder">
						 Orientatie
						</InputLabel>
						<Select
							value={hotspot.popupOrientation}
							onChange={this.handleChange('popupOrientation')}
							input={<Input name="popupOrientation" id="popupOrientation-placeholder" />}
							name='Orientatie'
						>
							<MenuItem value={'LEFT_TOP'}>LEFT_TOP</MenuItem>
							<MenuItem value={'LEFT_BOTTOM'}>LEFT_BOTTOM</MenuItem>
							<MenuItem value={'RIGHT_BOTTOM'}>RIGHT_BOTTOM</MenuItem>
							<MenuItem value={'RIGHT_TOP'}>RIGHT_TOP</MenuItem>
						</Select>
					</FormControl>
					<DialogActions>
			            <Button onClick={this.handleClick} color="primary" autoFocus>
			              	Sluiten
			            </Button>
			        </DialogActions>
		      	</Dialog>
			</div>
		)
	}
}

export default withStyles(styles)(Hotspot);