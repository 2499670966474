import gql from 'graphql-tag';

export const GQL_FETCH_APP_USERS = gql`
  query {
    appUsers {
      users {
        id
        fullName
      }
    }
  }
`;

export const GQL_FETCH_APP_USER = gql`
  query($id: ID!) {
    appUser(id: $id) {
      id
      fullName
      school {
        id
        name
      }
    }
  }
`;

export const GQL_FETCH_GROUPS = gql`
  query($filter: GroupFilter) {
    groups(filter: $filter) {
      groups {
        id
        name
        type
      }
    }
  }
`;

export const GQL_FETCH_SCHOOL_CLASS = gql`
  query($id: ID!) {
    schoolClass(id: $id) {
      id
      name
      schools {
        id
        name
        students {
          id
          fullName
        }
        classes {
          students {
            id
            fullName
          }
        }
        managers {
          id
          fullName
          roles {
            name
          }
        }
        products {
          id
          name
        }
      }
      product {
        id
        name
        program {
          id
          name
          boxes {
            id
            name
            elements {
              id
              title
              type
            }
          }
        }
      }
      teachers {
        id
        fullName
      }
      students {
        id
        firstName
        middleName
        lastName
        fullName
        email
        initials
        group {
          name
        }
        roles {
          name
        }
      }
    }
  }
`;

export const GQL_FETCH_SCHOOL = gql`
  query($id: ID!) {
    school(id: $id) {
      id
      name
      products {
        id
        name
      }
      classes {
        id
        name
        product {
          id
          name
          program {
            id
            name
            boxes {
              id
              name
              elements {
                id
                title
                type
              }
            }
          }
        }
        students {
          id
          firstName
          middleName
          lastName
          fullName
          email
          initials
          group {
            name
          }
          roles {
            name
          }
        }
      }
      managers {
        id
        fullName
        roles {
          name
        }
      }
      students {
        id
        fullName
        roles {
          name
        }
      }
    }
  }
`;

export const GQL_CREATE_SCHOOLCLASS = gql`
  mutation(
    $name: String!
    $schools: [ID]
    $students: [ID]
    $teachers: [ID]
    $product: ID
  ) {
    createSchoolClass(
      input: {
        name: $name
        schools: $schools
        students: $students
        teachers: $teachers
        product: $product
      }
    ) {
      group {
        id
        name
      }
    }
  }
`;

export const GQL_UPDATE_SCHOOLCLASS = gql`
  mutation(
    $id: ID!
    $students: [ID]
    $teachers: [ID]
    $product: ID
    $name: String
  ) {
    updateSchoolClass(
      input: {
        id: $id
        students: $students
        teachers: $teachers
        product: $product
        name: $name
      }
    ) {
      group {
        id
      }
    }
  }
`;

export const GQL_UPDATE_SCHOOL = gql`
  mutation($id: ID!, $students: [ID]) {
    updateSchool(input: { id: $id, students: $students }) {
      group {
        id
      }
    }
  }
`;

export const GQL_FETCH_SCHOOLS = gql`
  query($filter: GroupFilter) {
    groups(filter: $filter) {
      groups {
        id
        name
      }
    }
  }
`;
