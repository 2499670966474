import React from 'react';
import { AccessControl } from '@manakin/app-core';
import { styles } from '../styles';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';

const Manager = props => {
  const { literals = {}, classes } = props;
  return (
    <div className={classes.row}>
      <div className={classes.specificStatisticItem}>
        <Typography
          classes={{ h2: classNames(classes.statisticsTitle) }}
          component="p"
          variant="h2"
        >
          {props.schoolStudents || 0}
        </Typography>
        <Typography
          classes={{ body1: classNames(classes.stasticsBody) }}
          component="p"
          variant="body1"
        >
          {literals.STUDENT_WITHOUT_CLASS || 'Leerlingen zonder klas'}
        </Typography>
      </div>
      <div className={classes.specificStatisticItem}>
        <Typography
          classes={{ h2: classNames(classes.statisticsTitle) }}
          component="p"
          variant="h2"
        >
          {props.classStudents || 0}
        </Typography>
        <Typography
          classes={{ body1: classNames(classes.stasticsBody) }}
          component="p"
          variant="body1"
        >
          {literals.STUDENT_IN_CLASS || 'Aantal leerlingen in klas'}
        </Typography>
      </div>
      <div className={classes.specificStatisticItem}>
        <Typography
          classes={{ h2: classNames(classes.statisticsTitle) }}
          component="p"
          variant="h2"
        >
          {props.teachers || 0}
        </Typography>
        <Typography
          classes={{ body1: classNames(classes.stasticsBody) }}
          component="p"
          variant="body1"
        >
          Aantal <br />
          docenten
        </Typography>
      </div>
      <div className={classes.specificStatisticItem}>
        <Typography
          classes={{ h2: classNames(classes.statisticsTitle) }}
          component="p"
          variant="h2"
        >
          {props.schoolClasses || 0}
        </Typography>
        <Typography
          classes={{ body1: classNames(classes.stasticsBody) }}
          component="p"
          variant="body1"
        >
          Aantal <br /> klassen
        </Typography>
      </div>
    </div>
  );
};

export default withStyles(styles)(Manager);
