import React from "react";
import {withStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import classnames from "classnames"
import TextField from "@material-ui/core/TextField";
import UploadImageIcon from "@material-ui/icons/Publish";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';

const styles = theme => ({
    general: {
        padding: theme.spacing(2)
    },
    input: {
        margin: `${theme.spacing(1.5)}px 0`
    },
    programInput: {
        width: "100%"
    },
    programInputTitle: {
        zoom: 1.25
    },
    programInputImageLabel: {
        fontSize: "15px",
        color: "#47525E !important",
        fontWeight: 500,
        marginTop: 16,
        display: "block"
    },
    textField: {
        marginTop: "20px !important",
        "&:after": {
            borderColor: "#47525E",
        },
    },
    textFieldLabel: {
        color: "#47525E !important",
        fontSize: "19px",
        fontWeight: 500,
    },
    programInputImage: {
        display: "grid",
        gridTemplateColumns: "auto fit-content(100%)",
        borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
        paddingBottom: 8,
        marginTop: 8,
        cursor: "pointer"
    },
    programInputImageThumbnail: {
        width: 75,
        height: 75
    },
    programInputImageSelect: {
        display: "grid",
        gridTemplateColumns: "auto auto"
    },
    programInputImageIcon: {
        color: "#47525E",
        alignSelf: "center"
    },
    programInputImageText: {
        alignSelf: "center",
        color: "#47525E",
        fontWeight: 500,
        marginLeft: 16,
        marginRight: 16
    },
});

class Detail extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    /**
     * title, inputFields and imageField have the following structure:
     *
     * title: {
     *     label: "Title",
     *     placeholder: "Placeholder"
     * }
     * inputFields: [
     *     {
     *         id: 1,
     *         label: "Label",
     *         placeholder: "Placeholder",
     *         type: input | dropdown
     *         // DropDown only
     *         options: [{
     *             id: 2,
     *             label: "Label
     *         }]
     *     }
     * ]
     * imageField: {
     *     label: ""
     * }
     */
    render() {
        const {
            classes,
            title = {},
            onTitleChange,
            inputFields,
            onInputFieldChange,
            imageField = {},
            onSelectImage
        } = this.props;

        return (
            <div className={classes.general}>
                {title && title.label ?
                    <TextField
                        id="program-title"
                        label={title.label}
                        placeholder={title.placeholder}
                        className={classnames(classes.programInput, classes.input)}
                        onChange={event => onTitleChange(event.target.value)}
                        margin="normal"
                        InputLabelProps={{
                            FormLabelClasses: {
                                root: classes.textFieldLabel,
                                focused: classes.textFieldLabel
                            },
                            shrink: true,
                        }}
                        InputProps={{
                            classes: {
                                root: classnames(classes.textField, classes.programInputTitle),
                                focused: classes.textField
                            }
                        }}
                    /> : ""}

                {inputFields && inputFields.map(field => {
                    return (
                        field.type && field.type === "dropdown" ?
                            <FormControl
                                className={classes.formControl}
                                margin="normal"
                                fullWidth>
                                <InputLabel
                                    htmlFor={field.id}
                                    classes={{
                                        root: classes.textFieldLabel,
                                    }}
                                    shrink>{field.label}</InputLabel>
                                <Select
                                    value={field.value || -1}
                                    className={classnames(classes.programInput, classes.input)}
                                    onChange={event => onInputFieldChange(event.target.value, field.id)}
                                    input={
                                        <Input
                                            name={field.label}
                                            id={field.id.toString()}
                                            classes={{
                                                root: classes.textField,
                                                focused: classes.textField,
                                            }}/>
                                    }>
                                    <MenuItem value={-1}><em>{field.placeholder}</em></MenuItem>
                                    {field.options && field.options.map(option => {
                                        return (<MenuItem value={option.id}>{option.label}</MenuItem>);
                                    })}
                                </Select>
                            </FormControl>
                            :
                            <TextField
                                id="program-description"
                                key={field.id}
                                label={field.label}
                                placeholder={field.placeholder}
                                className={classnames(classes.programInput, classes.input)}
                                onChange={event => onInputFieldChange(event.target.value, field.id)}
                                margin="normal"
                                InputLabelProps={{
                                    FormLabelClasses: {
                                        root: classes.textFieldLabel,
                                        focused: classes.textFieldLabel
                                    },
                                    shrink: true,
                                }}
                                InputProps={{
                                    classes: {
                                        root: classes.textField,
                                        focused: classes.textField
                                    }
                                }}
                            />);
                })}

                {imageField && imageField.label ?
                    <div>
                        <InputLabel
                            classes={{root: classes.programInputImageLabel}}>
                            {imageField.label}
                        </InputLabel>
                        <div
                            className={classes.programInputImage}
                            onClick={() => onSelectImage()}>
                            {/* TODO should eventually be replaced with a custom icon */}
                            <svg
                                className={classes.programInputImageThumbnail}
                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                <path fill="#f3f3f3"
                                      d="M23 24H1c-.6 0-1-.4-1-1V1c0-.6.4-1 1-1h22c.6 0 1 .4 1 1v22c0 .6-.4 1-1 1z"/>
                                <path fill="#a4a4a4"
                                      d="M12.6 9.4l-1.5 2 1.1 1.5c.2.2.1.5-.1.7-.2.2-.5.1-.7-.1-.5-.7-1.2-1.5-1.5-2.1-.2-.3-.6-.3-.8 0l-2 2.7c-.2.3 0 .8.4.8h9c.4 0 .6-.5.4-.8l-3.5-4.7c-.2-.3-.6-.3-.8 0z"/>
                            </svg>
                            <div className={classes.programInputImageSelect}>
                                <UploadImageIcon className={classes.programInputImageIcon}/>
                                <Typography
                                    className={classes.programInputImageText}>
                                    Selecteer afbeelding
                                </Typography>
                            </div>
                        </div>
                    </div> : ""}
            </div>
        );
    }
}

export default withStyles(styles)(Detail);
