import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';
import { graphql } from 'react-apollo';
import { GQL_FETCH_NEWS_LIST } from '../graphql';
import renderHTML from 'react-render-html';

const styles = theme => ({
  buttonText: {},
  body: {},
  root: {
    width: '100%',
    '& $buttonText': {
      marginBottom: 0,
      color: theme.palette.getContrastText(theme.palette.secondary[500])
    },
    '& $body': {
      margin: 0
    }
  },
  rightArrow: {
    width: '11px',
    height: '11px',
    position: 'absolute',
    right: '2rem',
    color: theme.palette.primary[500],
    [theme.breakpoints.up('md')]: {
      right: '4rem'
    }
  },
  content: {
    padding: '2rem',
    backgroundColor: theme.manakin.defaultBackgroundColor[500],
    position: 'relative',
    '& $rightArrow': {
      bottom: '4rem'
    },
    [theme.breakpoints.up('md')]: {
      padding: '5rem 7rem 5rem 5rem',
      '& $rightArrow': {
        bottom: '5rem'
      }
    }
  },
  button: {
    backgroundColor: theme.palette.secondary[500],
    height: '5rem',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '0 2rem',
    position: 'relative',
    textDecoration: 'none',
    '& $rightArrow': {
      top: '11px',
      color: theme.palette.getContrastText(theme.palette.secondary[500])
    },
    [theme.breakpoints.up('md')]: {
      padding: '0 5rem'
    }
  },
  linkStyle: {
    textDecoration: 'none'
  }
});

const News = props => {
  const { classes, data = {} } = props;
  const { newsList = {} } = data;
  let news = {};

  if (newsList.news && newsList.news.length) {
    news = newsList.news[0];
  }

  if (!news.id) {
    return '';
  }
  return (
    <div className={classes.root}>
      <Link to={`/news/${news.id}`} className={classes.linkStyle}>
        <div className={classes.content}>
          <Typography variant="h6">{news.title || ''}</Typography>
          <Typography variant="body1" className={classes.body} component="div">
            {renderHTML(news.introText || '')}
          </Typography>
          <div className={classes.rightArrow}>
            <ArrowRight />
          </div>
        </div>
      </Link>
      <Link to="/account/news" className={classes.button}>
        <Typography variant="body1" className={classes.buttonText}>
          Bekijk ongelezen berichten
        </Typography>
        <div className={classes.rightArrow}>
          <ArrowRight />
        </div>
      </Link>
    </div>
  );
};

export default compose(
  withStyles(styles, { name: 'AppImpNews' }),
  graphql(GQL_FETCH_NEWS_LIST, {
    options: () => ({
      variables: {
        page: 0,
        pagesize: 1,
        filter: {
          category: ['URGENT']
        }
      }
    })
  })
)(News);
