"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _recompose = require("recompose");

var _styles = require("@material-ui/core/styles");

var _graphql = require("./graphql");

var _reactApollo = require("react-apollo");

var _appCore = require("@manakin/app-core");

var _moment = require("moment");

var _moment2 = _interopRequireDefault(_moment);

var _Typography = require("@material-ui/core/Typography");

var _Typography2 = _interopRequireDefault(_Typography);

var _AccessTime = require("@material-ui/icons/AccessTime");

var _AccessTime2 = _interopRequireDefault(_AccessTime);

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

var _styles2 = require("./styles");

var _reactRedux = require("react-redux");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var BestTimes = function (_React$PureComponent) {
  _inherits(BestTimes, _React$PureComponent);

  function BestTimes() {
    _classCallCheck(this, BestTimes);

    return _possibleConstructorReturn(this, (BestTimes.__proto__ || Object.getPrototypeOf(BestTimes)).apply(this, arguments));
  }

  _createClass(BestTimes, [{
    key: "render",
    value: function render() {
      var _props = this.props,
          classes = _props.classes,
          data = _props.data,
          _props$noData = _props.noData,
          noData = _props$noData === undefined ? false : _props$noData,
          user = _props.user,
          _props$variant = _props.variant,
          variant = _props$variant === undefined ? "default" : _props$variant,
          _props$max = _props.max,
          max = _props$max === undefined ? false : _props$max,
          _props$noPadding = _props.noPadding,
          noPadding = _props$noPadding === undefined ? false : _props$noPadding,
          _props$noTitle = _props.noTitle,
          noTitle = _props$noTitle === undefined ? false : _props$noTitle,
          _props$config = _props.config,
          config = _props$config === undefined ? {} : _props$config;
      var _config$literals = config.literals,
          literals = _config$literals === undefined ? {} : _config$literals;
      var _data$bestTimes = data.bestTimes,
          bestTimes = _data$bestTimes === undefined ? [] : _data$bestTimes;

      var _bestTimes = [].concat(_toConsumableArray(bestTimes));

      if (bestTimes.length) {
        if (variant === "points" && max) {
          _bestTimes = _bestTimes.sort(function (a, b) {
            return b.time - a.time;
          }).splice(0, max);
        } else if (variant === "points") _bestTimes.sort(function (a, b) {
          return b.time - a.time;
        });else if (max) _bestTimes.splice(0, max);
      }

      if (noData || !bestTimes.length) {
        return _react2.default.createElement(
          "div",
          {
            className: (0, _classnames2.default)(classes.root, _defineProperty({}, classes.noPadding, noPadding))
          },
          _react2.default.createElement(
            "div",
            { className: classes.wrapper },
            _react2.default.createElement(
              _Typography2.default,
              {
                component: "h2",
                variant: "h2",
                className: (0, _classnames2.default)("", classes.heading)
              },
              literals.NO_SCORES_YET || "Nog geen Topscores bekend"
            )
          )
        );
      } else {
        return _react2.default.createElement(
          "div",
          {
            className: (0, _classnames2.default)(classes.root, _defineProperty({}, classes.noPadding, noPadding))
          },
          _react2.default.createElement(
            "div",
            { className: classes.wrapper },
            !noTitle && _react2.default.createElement(
              _Typography2.default,
              {
                component: "h2",
                variant: "h2",
                className: (0, _classnames2.default)("underline", classes.heading)
              },
              literals.APP_TOP_SCORES || "Topscores"
            ),
            _react2.default.createElement(
              "div",
              { className: classes.overview },
              _bestTimes && _bestTimes.map(function (item, idx) {
                return _react2.default.createElement(
                  "div",
                  {
                    className: (0, _classnames2.default)(classes.user, _defineProperty({}, classes.currentUser, user === item.user.id)),
                    key: item.id
                  },
                  _react2.default.createElement(
                    "div",
                    { className: classes.image },
                    item.user.profilePicture && item.user.profilePicture.url && _react2.default.createElement(_appCore.LoadImage, {
                      src: item.user.profilePicture.url,
                      placeholder: item.user.profilePicture.thumbnailUrl
                    })
                  ),
                  _react2.default.createElement(
                    "div",
                    { className: classes.name },
                    _react2.default.createElement(
                      _Typography2.default,
                      {
                        component: "p",
                        variant: "body1",
                        className: classes.body
                      },
                      idx + 1,
                      ". ",
                      item.user.fullName || ""
                    )
                  ),
                  _react2.default.createElement(
                    "div",
                    { className: classes.time },
                    variant === "default" && _react2.default.createElement(_AccessTime2.default, { classes: { root: classes.clockIcon } }),
                    _react2.default.createElement(
                      _Typography2.default,
                      {
                        component: "p",
                        variant: "body1",
                        className: classes.body
                      },
                      variant === "points" && item.time,
                      variant === "default" && (0, _moment2.default)().minute(0).second(item.time).format("mm:ss")
                    )
                  )
                );
              })
            )
          )
        );
      }
    }
  }]);

  return BestTimes;
}(_react2.default.PureComponent);

exports.default = (0, _recompose.compose)((0, _reactRedux.connect)(function (state) {
  return { config: state.config };
}), (0, _styles.withStyles)(_styles2.styles, { name: "appBestTimes" }), (0, _reactApollo.graphql)(_graphql.GQL_FETCH_BEST_TIMES, {
  options: function options(props) {
    return {
      variables: {
        schoolClass: props.schoolClass,
        box: props.box,
        program: props.program,
        user: props.user,
        element: props.element
      }
    };
  }
}))(BestTimes);