import { CLOSE_DIALOG, OPEN_DIALOG } from './actions';

const initialState = {};

export const reducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case OPEN_DIALOG: {
            return {
                ...state,
                [action.payload.id]: {
                    ...state[action.payload.id],
                    open: true,
                    data: action.payload.data
                }
            };
        }
        case CLOSE_DIALOG: {
            return {
                ...state,
                [action.payload.id]: {
                    ...state[action.payload.id],
                    open: false
                }
            };
        }
        default:
            return state;
    }
};

export default reducer;
