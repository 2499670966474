'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.GQL_DELETE_MESSAGE = exports.GQL_UPDATE_MESSAGE = undefined;

var _templateObject = _taggedTemplateLiteral(['\n    mutation($id: ID, $read: Boolean) {\n        updateMessageReceived(input: { id: $id, read: $read }) {\n            messageReceived {\n                id\n            }\n        }\n    }\n'], ['\n    mutation($id: ID, $read: Boolean) {\n        updateMessageReceived(input: { id: $id, read: $read }) {\n            messageReceived {\n                id\n            }\n        }\n    }\n']),
    _templateObject2 = _taggedTemplateLiteral(['\n    mutation($id: ID!) {\n        deleteMessageReceived(id: $id) {\n            result \n        }\n    }\n'], ['\n    mutation($id: ID!) {\n        deleteMessageReceived(id: $id) {\n            result \n        }\n    }\n']);

var _graphqlTag = require('graphql-tag');

var _graphqlTag2 = _interopRequireDefault(_graphqlTag);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteral(strings, raw) { return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var GQL_UPDATE_MESSAGE = exports.GQL_UPDATE_MESSAGE = (0, _graphqlTag2.default)(_templateObject);

var GQL_DELETE_MESSAGE = exports.GQL_DELETE_MESSAGE = (0, _graphqlTag2.default)(_templateObject2);