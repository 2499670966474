import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { isAuthenticated, getAppUser } from "../selectors";
import { compose } from "recompose";
import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";
import { withApollo, graphql } from "react-apollo";
import { logout } from "@manakin/authentication/actions";
import { GQL_VALID_LICENSE_CHECK } from "../graphql";
import { Loader } from "@manakin/app-core";

const mapStateToProps = (store, props) => {
  return {
    config: store.config,
    authenticated: isAuthenticated(store),
    appUser: getAppUser(store)
  };
};

const PrivateRoute = ({
  component,
  authenticated,
  authPath = "/auth",
  client,
  processSuccess,
  appUser,
  config = {},
  data,
  ...otherProps
}) => {
  const { loading, validLicenseCheck = {} } = data;
  const { general = {} } = config;
  const { isCMS = false } = general;

  const token = Cookies.get("jwt_bespeak");
  let decoded = {};
  if (token) {
    decoded = token && token === "deleted" ? {} : jwtDecode(token);
  }
  let loginParams = otherProps.location.pathname
    ? "?redirect=" + otherProps.location.pathname
    : "";

  const [validLicense, setValidLicense] = useState(true);
  const [isTeacher, setIsTeacher] = useState(false);

  useEffect(() => {
    if (!loading && data) {
      if (
        appUser.roles &&
        appUser.roles.some(
          item => item.name == "SCHOOL_MANAGER" || item.name == "TEACHER"
        )
      ) {
        setIsTeacher(true);
      }
      setValidLicense(validLicenseCheck.hasValidLicense);
    }
  }, [loading]);

  if (loading) return <Loader fullScreen={true} />;
  if (authenticated) {
    if (decoded.cms && !isCMS) logout();
    if (!decoded.cms && isCMS) logout();
    if (!decoded.id) logout();
    if (
      !validLicense &&
      !isTeacher &&
      !isCMS &&
      otherProps.location.pathname !== "/no-license"
    )
      return <Redirect to={"/no-license"} />;
    return <Route {...otherProps} component={component} />;
  } else if (decoded.id) {
    return <Redirect to={authPath + "/sso/" + decoded.id} />;
  } else {
    return <Redirect to={authPath + "/login" + loginParams} />;
  }
};

export default compose(
  connect(mapStateToProps, dispatch => ({
    logout: () => dispatch(logout())
  })),
  graphql(GQL_VALID_LICENSE_CHECK),
  withApollo
)(PrivateRoute);
