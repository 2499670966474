import React, { useState } from "react";
import PersonalData from "./PersonalData";
import SchoolData from "./SchoolData";
import SchoolClassData from "./SchoolClassData";
import AuthData from "./AuthData";
import { withForm } from "@manakin/core";
import { compose, withHandlers } from "recompose";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import { push } from "redux-first-history";
import { connect } from "react-redux";
import { showSnackbarMessage } from "@manakin/core/actions";
import { withRouter } from "react-router-dom";
import { GQL_FETCH_SETTINGS, GQL_CREATE_APP_USER } from "../graphql";
import { useQuery, useMutation } from "@apollo/react-hooks";
import useStyles from "./styles";
import { PersonalDataForm } from "./forms/PersonalDataForm";
import { SchoolDataForm } from "./forms/SchoolDataForm";
import { authDataForm } from "./forms/AuthDataForm";
const formData = [PersonalDataForm, SchoolDataForm, authDataForm];

const form = {
  ...PersonalDataForm,
  ...SchoolDataForm,
  ...authDataForm
};

const AppRegistration = props => {
  const { form, config } = props;
  const { pages = {} } = config;
  const { appRegistration = {} } = pages;
  const classes = useStyles();

  //query hooks
  const { loading, error, data } = useQuery(GQL_FETCH_SETTINGS);

  //mutation hooks
  const [createAppUser, { userData }] = useMutation(GQL_CREATE_APP_USER);

  //state hooks
  const [value, setValue] = useState(0);

  //functions
  const handleNext = () => {
    form.onValidate().then(
      () => {
        setValue(value + 1);
      },
      error => {
        let hasError = false;
        for (const key of Object.keys(formData[value])) {
          if (error[key] != undefined) hasError = true;
        }
        if (!hasError) setValue(value + 1);
      }
    );
  };

  const handleBack = () => {
    if (value == 0) props.history.goBack();
    else setValue(value - 1);
  };

  const handleSubmit = () => {
    const { settings: _settings } = data;
    const { settings = [] } = _settings;
    let role = null;

    settings.forEach(setting => {
      if (setting.name === "standardRole") {
        role = setting.value;
      }
    });

    form.onSubmit().then(data => {
      const e = data.schoolClasses ? [data.schoolClasses] : null;
      const _data = {
        ...data,
        schoolClasses: e,
        sendVerifyMail: true,
        roles: [role],
        acceptTerms: true
      };
      if (data) {
        onCreate({ ..._data });
      }
    });
  };

  const onCreate = event => {
    createAppUser({
      variables: {
        ...event
      }
    }).then(result => {
      if (result.errors && result.errors.length) {
        if (result.errors.find(error => error.code === 409)) {
          props.processEmailAlreadyInUse();
        } else {
          result.errors.map(error => {
            props.processError(error);
          });
        }
      } else {
        props.processSuccess(result);
      }
    });
  };

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Stepper
          classes={{ root: classes.stepperRoot }}
          activeStep={value}
          alternativeLabel
        >
          {formData.map((e, index) => (
            <Step classes={{ root: classes.stepRoot }} key={index}>
              <StepLabel>{index}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <div className={classes.content}>
          {value === 0 && <PersonalData form={form} config={config} />}
          {value === 1 && !appRegistration.showSchoolClass && (
            <SchoolData form={form} config={config} />
          )}
          {value === 1 && appRegistration.showSchoolClass && (
            <SchoolClassData form={form} config={config} />
          )}
          {value === 2 && <AuthData form={form} config={config} />}
        </div>
        <div className={classes.buttonGroup}>
          <Button
            variant="contained"
            classes={{ root: classes.buttonRoot }}
            onClick={value == 2 ? handleSubmit : handleNext}
            color="primary"
          >
            Doorgaan
          </Button>
          <Button
            classes={{ root: classes.buttonRoot }}
            onClick={handleBack}
            color="primary"
          >
            Terug
          </Button>
        </div>
      </div>
    </div>
  );
};

export default compose(
  withRouter,
  withForm(form),
  connect(
    ({ config }) => ({
      config
    }),
    dispatch => ({
      processSuccess: result => {
        dispatch(push("registration-success"));
      },
      processError: error => {
        dispatch(
          showSnackbarMessage({
            text: error.message,
            variant: "error"
          })
        );
      },
      processEmailAlreadyInUse: () => {
        dispatch(
          showSnackbarMessage({
            text: "Er bestaat al een account met dit e-mail adres",
            variant: "error"
          })
        );
      }
    })
  )
)(AppRegistration);
