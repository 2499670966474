'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var styles = exports.styles = function styles(theme) {
	var _root, _bridgeContent, _listView;

	return {
		root: (_root = {
			height: '100%',
			width: 'calc(100% - 3.2rem)',
			position: 'relative',
			backgroundColor: theme.palette.secondary[100],
			overflow: 'hidden',
			display: 'inline-block',
			margin: '0 1.6rem 1.6rem',
			opacity: 0,
			transform: 'translateX(-20px)',
			minHeight: '27rem'
		}, _defineProperty(_root, theme.breakpoints.up('md'), {
			width: 'calc(50% - 50px)',
			minHeight: '50rem',
			margin: '0 2.5rem 5rem'
		}), _defineProperty(_root, '&:hover', {
			'& $backgroundImage': _defineProperty({}, theme.breakpoints.up('md'), {
				transform: 'scale(1.1)'
			})
		}), _root),
		Trainer: {},
		show: {
			opacity: 1,
			transform: 'translateX(0)'
		},
		bookCheck: _defineProperty({
			backgroundColor: theme.palette.secondary[100],
			padding: '3.6rem 3rem',
			'& $content': {
				display: 'none'
			},
			'& $backgroundImage': _defineProperty({
				'display': 'none'
			}, theme.breakpoints.up('md'), {
				'display': 'block',
				'width': '150px',
				height: '100%'
			})
		}, theme.breakpoints.up('md'), {
			height: '14rem', /* anything less than the minHeight below to avoid flex center issues in IE11, see: https://github.com/philipwalton/flexbugs/issues/231 */
			minHeight: '15rem',
			display: 'flex',
			flexDirection: 'row',
			padding: '2.5rem 5rem 2.5rem 20rem'
		}),
		bookCheckContent: _defineProperty({}, theme.breakpoints.up('md'), {
			display: 'flex',
			flexDirection: 'column',
			width: '100%',
			paddingRight: '2rem',
			'& p': {
				marginBottom: 0
			}
		}),
		bookCheckFlex: _defineProperty({}, theme.breakpoints.up('md'), {
			display: 'flex',
			width: '100%',
			alignItems: 'center'
		}),
		bookCheckFlexDone: _defineProperty({}, theme.breakpoints.up('md'), {
			marginRight: "60px"
		}),
		button: {
			width: 'auto',
			minWidth: '260px',
			minHeight: '60px'
		},
		fullWidth: _defineProperty({
			width: 'calc(100% - 3.2rem)'
		}, theme.breakpoints.up('md'), {
			width: 'calc(100% - 5rem)'
		}),
		noMargin: _defineProperty({
			margin: '0 0 1.6rem',
			width: '100%'
		}, theme.breakpoints.up('md'), {
			width: 'calc(100% - 5rem)',
			margin: '0 2.5rem 5rem'
		}),
		backgroundImage: {
			position: 'absolute',
			zIndex: '0',
			height: '100%',
			width: '100%',
			left: '0',
			top: '0',
			transition: 'width .3s, transform .3s, background-color .3s'
		},
		image: {
			width: '100%',
			height: '100%',
			objectFit: 'cover',
			fontFamily: "'object-fit: cover'"
		},
		noBackground: {
			'& $backgroundImage': {
				display: 'none'
			}
		},
		link: {
			textDecoration: 'none'
		},
		linkToSlideShowEdit: {
			display: 'flex',
			color: theme.palette.primary.main,
			textDecoration: 'none'
			//display: 'none'
		},
		linkToSlideShowEditIcon: {
			marginRight: theme.spacing(2)
		},
		slideshowLinks: {
			marginBottom: theme.spacing(4)
		},
		slideshowLinksItem: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			marginBottom: theme.spacing(2)
		},
		slideshowTitle: {
			color: theme.palette.primary.main
		},
		content: _defineProperty({
			position: 'absolute',
			bottom: '26px',
			padding: '0 26px'
		}, theme.breakpoints.up('md'), {
			padding: '0 50px',
			bottom: '50px'
		}),
		type: {
			display: 'block',
			fontFamily: theme.manakin.secondaryTitleFont,
			fontSize: '2rem',
			lineHeight: '2rem',
			color: theme.manakin.typeColor[500],
			marginBottom: '1.4rem'
		},
		secondaryPrefixColor: {
			color: theme.manakin.secondaryTypeColor[500]
		},
		primaryPrefixColor: {
			color: theme.manakin.primaryTypeColor[500]
		},
		name: _defineProperty({
			display: 'block',
			fontSize: '1.9rem',
			letterSpacing: '.633rem',
			fontWeight: 'bold',
			lineHeight: '3rem',
			fontFamily: theme.manakin.defaultTitleFont,
			color: theme.manakin.defaultBoxColor[400]
		}, theme.breakpoints.up('md'), {
			fontSize: '2.4rem',
			letterSpacing: '.8rem',
			lineHeight: '4rem'
		}),
		primaryTitleColor: {
			color: theme.manakin.primaryBoxColor[400]
		},
		secondaryTitleColor: {
			color: theme.manakin.secondaryBoxColor[400]
		},
		primaryBackgroundColor: {
			backgroundColor: theme.manakin.primaryThemeColor[500]
		},
		secondaryBackgroundColor: {
			backgroundColor: theme.manakin.secondaryThemeColor[500]
		},
		slideshowCard: {
			display: 'flex',
			flexFlow: 'column nowrap'
		},
		slideshowBox: _defineProperty({
			display: 'inherit',
			flexFlow: 'inherit',
			flexGrow: 1,
			padding: '26px'
		}, theme.breakpoints.up('md'), {
			padding: '50px'
		}),
		newSlideshowButton: {
			marginTop: 'auto'
		},
		bridgeContent: (_bridgeContent = {
			padding: theme.manakin.defaultPadding
		}, _defineProperty(_bridgeContent, theme.breakpoints.up('md'), {
			padding: '100px'
		}), _defineProperty(_bridgeContent, '& h2', _defineProperty({
			fontFamily: theme.manakin.secondaryTitleFont,
			fontSize: '3rem',
			lineHeight: '4rem',
			fontWeight: '500'
		}, theme.breakpoints.up('md'), {
			fontSize: '3.6rem',
			lineHeight: '5.2rem'
		})), _defineProperty(_bridgeContent, '& $contentColor', {
			'&>ul': {
				paddingLeft: '5rem'
			}
		}), _bridgeContent),
		doneIcon: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			height: '1.5rem',
			width: '1.5rem',
			color: theme.palette.getContrastText(theme.palette.secondary[500])
		},
		done: _extends(_defineProperty({
			position: 'absolute',
			width: '30px',
			height: '30px',
			borderRadius: '100%',
			backgroundColor: theme.palette.secondary[200],
			zIndex: '2',
			right: '20px',
			top: '50%',
			transform: 'translateY(-50%)',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center'
		}, theme.breakpoints.up('md'), {
			width: '60px',
			height: '60px'
		}), theme.manakin.elementCardDone),
		// bookCheckDone: {
		// 	top: "initial"
		// },
		inCorrect: {
			backgroundColor: theme.palette.error['light']
		},
		continue: {
			backgroundColor: 'transparent',
			'& $doneIcon': {
				color: 'black',
				width: '2.5rem',
				height: '2.5rem'
			}
		},
		iconRoot: {
			flex: '1',
			width: 'auto',
			height: 'auto'
		},
		listView: (_listView = {
			height: '6rem',
			minHeight: '6rem',
			margin: '0 1.6rem 1rem',
			border: '1px solid ' + theme.manakin.defaultBorderColor[500]
		}, _defineProperty(_listView, theme.breakpoints.up('md'), {
			minHeight: '10rem',
			height: '10rem',
			margin: '0 2.5rem 2rem'
		}), _defineProperty(_listView, '& $content', _defineProperty({
			padding: '0 0 0 8rem',
			position: 'relative',
			bottom: 0,
			height: '100%',
			display: 'flex',
			width: '100%',
			alignItems: 'center'
		}, theme.breakpoints.up('md'), {
			padding: '0 0 0 14rem'
		})), _defineProperty(_listView, '& $type', {
			display: 'none'
		}), _defineProperty(_listView, '& $backgroundImage', _defineProperty({
			width: '6rem'
		}, theme.breakpoints.up('md'), {
			width: '10rem'
		})), _defineProperty(_listView, '& $name', {
			fontSize: '1.8rem',
			letterSpacing: '0rem',
			lineHeight: '2rem',
			fontWeight: '600',
			transition: 'color .3s',
			'&:hover': {
				color: theme.manakin.defaultContentHover[500]
			}
		}), _listView)

	};
};