import React from "react";
import AppConfirmRegistration from "@manakin/authentication/AppConfirmRegistration";
import { withStyles } from '@material-ui/core/styles';
import { SnackbarMessenger } from '@manakin/core';

const styles = theme => ({
	root: {
		width: "100%",
		minHeight: "100%"
	}
});

class ConfirmRegistration extends React.Component {
	render() {
		const { classes, mutate, match } = this.props;

		return (
			<div className={classes.root}>
				<SnackbarMessenger />
				<div className={classes.smallWrapper}>
					<div className={classes.registrationRoot}>
						<AppConfirmRegistration mutate={mutate} token={match.params.confirm} />
					</div>
				</div>
			</div>
		)
	}
}

export default withStyles(styles, {name: 'AppRegistrationScreen'})(ConfirmRegistration); 
