"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _styles = require("@material-ui/core/styles");

var _recompose = require("recompose");

var _ElementGridCard = require("@manakin/app-core/ElementCard/ElementGridCard");

var _ElementGridCard2 = _interopRequireDefault(_ElementGridCard);

var _EntryTestCard = require("@manakin/app-core/ElementCard/EntryTestCard");

var _EntryTestCard2 = _interopRequireDefault(_EntryTestCard);

var _TestCard = require("@manakin/app-core/ElementCard/TestCard");

var _TestCard2 = _interopRequireDefault(_TestCard);

var _FactCardsCard = require("./FactCardsCard");

var _FactCardsCard2 = _interopRequireDefault(_FactCardsCard);

var _reactBottomScrollListener = require("react-bottom-scroll-listener");

var _reactBottomScrollListener2 = _interopRequireDefault(_reactBottomScrollListener);

var _KeyboardArrowUp = require("@material-ui/icons/KeyboardArrowUp");

var _KeyboardArrowUp2 = _interopRequireDefault(_KeyboardArrowUp);

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

var _scrollTo = require("scroll-to");

var _scrollTo2 = _interopRequireDefault(_scrollTo);

var _Grid = require("@material-ui/core/Grid");

var _Grid2 = _interopRequireDefault(_Grid);

var _graphql = require("./graphql");

var _reactApollo = require("react-apollo");

var _appCore = require("@manakin/app-core");

var _Dialog = require("@material-ui/core/Dialog");

var _Dialog2 = _interopRequireDefault(_Dialog);

var _Typography = require("@material-ui/core/Typography");

var _Typography2 = _interopRequireDefault(_Typography);

var _lib = require("../lib");

var _reactRedux = require("react-redux");

var _selectors = require("@manakin/authentication/selectors");

var _selectors2 = require("../ProgramsDropdown/selectors");

var _selectors3 = require("@manakin/app-core/Boxes/selectors");

var _reactScrollToComponent = require("react-scroll-to-component");

var _reactScrollToComponent2 = _interopRequireDefault(_reactScrollToComponent);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var styles = function styles(theme) {
  return {
    root: _extends({
      backgroundColor: "#001f3f"
    }, theme.manakin.largeWrapper, _defineProperty({
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: "4rem"
    }, theme.breakpoints.up("md"), {
      paddingBottom: "16rem"
    })),
    grid: _extends({}, theme.manakin.largeWrapper, {
      marginBottom: "64px",
      paddingLeft: 0,
      paddingRight: 0
    }),
    up: _defineProperty({
      position: "fixed",
      left: "50%",
      bottom: 0,
      width: "50px",
      height: "50px",
      backgroundColor: theme.palette.primary[500],
      transform: "translateX(-50%) translateY(100%)",
      zIndex: "99",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      transition: "transform .3s",
      color: theme.palette.getContrastText(theme.palette.primary[500])
    }, theme.breakpoints.up("md"), {
      display: "none"
    }),
    show: {
      transform: "translateX(-50%) translateY(0)"
    },
    dialogPaper: {
      padding: "32px"
    },
    body: {
      margin: 0
    }
  };
};

var Elements = function (_React$PureComponent) {
  _inherits(Elements, _React$PureComponent);

  function Elements(props) {
    var _this$state;

    _classCallCheck(this, Elements);

    var _this = _possibleConstructorReturn(this, (Elements.__proto__ || Object.getPrototypeOf(Elements)).call(this, props));

    _this._isMounted = false;
    _this.state = (_this$state = {
      show: false,
      factCards: [],
      image: ""
    }, _defineProperty(_this$state, "show", false), _defineProperty(_this$state, "showCards", false), _defineProperty(_this$state, "showDialog", false), _defineProperty(_this$state, "showTestDialog", false), _defineProperty(_this$state, "percentage", 0), _defineProperty(_this$state, "testPercentage", 0), _defineProperty(_this$state, "boxFinished", false), _this$state);

    _this.handleBottom = function () {
      _this.setState({ show: true });
    };

    _this.handleClick = function () {
      _this.setState({ show: false });
      (0, _scrollTo2.default)(0, 0, {
        duration: 300
      });
    };

    _this.getFactCardsFromElement = function (el) {
      var client = _this.props.client;

      client.query({
        query: _graphql.GQL_FETCH_MASTERCLASS,
        variables: {
          id: el.id
        }
      }).then(function (result) {
        if (result.data && result.data.masterClass) {
          var _result$data$masterCl = result.data.masterClass.factCards,
              factCards = _result$data$masterCl === undefined ? [] : _result$data$masterCl;

          if (factCards.length) {
            var _factCards = factCards.map(function (item) {
              return item.id;
            });
            _this.setState(function (prevState) {
              return {
                factCards: [].concat(_toConsumableArray(prevState.factCards), _toConsumableArray(_factCards)),
                image: factCards[0].image ? factCards[0].image : prevState.image
              };
            });
          }
        }
      });
    };

    _this.getFactCardsFromWorkforms = function (el) {
      var client = _this.props.client;

      if (el.workforms) {
        el.workforms.forEach(function (item) {
          if (item.type == "FactCardReferral") {
            client.query({
              query: _graphql.GQL_FETCH_FACTCARDREFERRAL,
              variables: {
                id: item.id
              }
            }).then(function (result) {
              if (result.data && result.data.factCardReferral) {
                var _result$data$factCard = result.data.factCardReferral.referrals,
                    referrals = _result$data$factCard === undefined ? [] : _result$data$factCard;

                var factCards = referrals.map(function (item) {
                  return item.factCardId;
                });

                client.query({
                  query: _graphql.GQL_FETCH_CMS_FACT_CARD,
                  variables: {
                    id: referrals[0].factCardId
                  }
                }).then(function (r) {
                  if (r.data && r.data.factCard) {
                    var _r$data$factCard = r.data.factCard,
                        factCard = _r$data$factCard === undefined ? {} : _r$data$factCard;

                    _this.setState(function (prevState) {
                      return {
                        factCards: [].concat(_toConsumableArray(prevState.factCards), _toConsumableArray(factCards)),
                        image: factCard.image ? factCard.image : prevState.image
                      };
                    });
                  }
                });
              }
            });
          }
        });
      }
    };

    _this.handleDisableClick = function () {
      _this.setState({ showDialog: true });
    };

    _this.handleDisableTestClick = function () {
      _this.setState({ showTestDialog: true });
    };

    _this.handleClose = function () {
      _this.setState({ showDialog: false, showTestDialog: false });
    };

    _this.ScrollEl = _react2.default.createRef();
    return _this;
  }

  _createClass(Elements, [{
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      this._isMounted = false;
    }
  }, {
    key: "componentDidMount",
    value: function componentDidMount() {
      var _this2 = this;

      this._isMounted = true;
      var _props = this.props,
          items = _props.items,
          _props$config = _props.config,
          config = _props$config === undefined ? {} : _props$config;
      var _config$pages = config.pages,
          pages = _config$pages === undefined ? {} : _config$pages;

      var doScroll = pages.box ? pages.box.scrollTo || false : false;

      items.forEach(function (r) {
        if (r.type === "Lesson") _this2.getFactCardsFromWorkforms(r);
        if (r.type === "MasterClass") _this2.getFactCardsFromElement(r);
      });

      setTimeout(function () {
        if (_this2._isMounted) {
          _this2.setState({ showCards: true });
          if (_this2.ScrollEl && _this2.ScrollEl.current !== null && doScroll) {
            (0, _reactScrollToComponent2.default)(_this2.ScrollEl, { offset: 1000, align: 'top', duration: 0 });
          }
        }
      }, 0);

      var elementResults = this.props.elementResults;

      var percentage = (0, _lib.getPercentage)(elementResults, items);
      var testPercentage = (0, _lib.getTestPercentage)(elementResults, items);
      this.setState({ percentage: percentage, testPercentage: testPercentage });
    }
  }, {
    key: "componentDidUpdate",
    value: function componentDidUpdate(prevProps) {
      var _this3 = this;

      if (prevProps.data.loading && !this.props.data.loading) {
        var _props2 = this.props,
            data = _props2.data,
            box = _props2.box;
        var _data$boxResults = data.boxResults,
            boxResults = _data$boxResults === undefined ? [] : _data$boxResults;


        boxResults.forEach(function (item) {
          if (item.box.id === box && item.finished) {
            _this3.setState({
              boxFinished: true
            });
          }
        });
      }
    }
  }, {
    key: "render",
    value: function render() {
      var _this4 = this;

      var items = [].concat(_toConsumableArray(this.props.items)) || [];
      var _props3 = this.props,
          classes = _props3.classes,
          elementResults = _props3.elementResults,
          boxStyle = _props3.boxStyle,
          data = _props3.data,
          box = _props3.box;
      var _state = this.state,
          factCards = _state.factCards,
          image = _state.image,
          show = _state.show,
          showCards = _state.showCards,
          showDialog = _state.showDialog,
          showTestDialog = _state.showTestDialog,
          testPercentage = _state.testPercentage,
          boxFinished = _state.boxFinished;

      var hasEntryType = false;
      var entryDone = true;

      items.forEach(function (e, index) {
        elementResults && elementResults.forEach(function (a) {
          if (a.element.id == e.id) {
            e.done = a.finished;
            e.correct = a.correct;
          }
        });
        if (e.type === "EntryTest") {
          hasEntryType = index;
        }
        e.new = false;
      });

      if (items.find(function (e) {
        return !e.done;
      })) {
        items.find(function (e) {
          return !e.done;
        })['new'] = true;
      }

      if (items[hasEntryType]) {
        entryDone = items[hasEntryType].done ? items[hasEntryType].done : false;
      }

      return _react2.default.createElement(
        _react2.default.Fragment,
        null,
        _react2.default.createElement(_reactBottomScrollListener2.default, { onBottom: this.handleBottom, debounce: "0" }),
        _react2.default.createElement(
          "div",
          {
            onClick: this.handleClick,
            className: (0, _classnames2.default)(classes.up, _defineProperty({}, classes.show, this.state.show))
          },
          _react2.default.createElement(_KeyboardArrowUp2.default, null)
        ),
        _react2.default.createElement(
          _Grid2.default,
          {
            container: true,
            spacing: 4,
            className: classes.grid,
            ref: function ref(section) {
              _this4.root = section;
            }
          },
          items.filter(function (c) {
            return c.type === "EntryTest";
          }).map(function (item, idx) {
            return _react2.default.createElement(
              _Grid2.default,
              { item: true, xs: 12, md: 12, key: item.id },
              _react2.default.createElement(_EntryTestCard2.default, { idx: 0, show: showCards, item: item })
            );
          }),
          items.filter(function (c) {
            return c.type !== "EntryTest" && c.type !== "Summary" && c.type !== "Test";
          }).map(function (item, idx) {
            return _react2.default.createElement(
              _react2.default.Fragment,
              { key: item.id },
              item.type == "SlideshowCreator" ? _react2.default.createElement(
                _appCore.AccessControl,
                { role: ["TEACHER", "SCHOOL_MANAGER"] },
                _react2.default.createElement(
                  _Grid2.default,
                  {
                    item: true,
                    xs: 12,
                    key: item.id,
                    md: item.fullWidth ? 12 : 6
                  },
                  _react2.default.createElement(_ElementGridCard2.default, {
                    boxStyle: boxStyle,
                    item: item,
                    idx: idx
                  })
                )
              ) : _react2.default.createElement(
                _Grid2.default,
                { item: true, xs: 12, md: item.fullWidth ? 12 : 6 },
                _react2.default.createElement(_ElementGridCard2.default, {
                  ref: item.new && idx > 0 ? function (section) {
                    return _this4.ScrollEl = section;
                  } : null,
                  boxStyle: boxStyle,
                  item: item,
                  idx: idx,
                  disabled: !entryDone,
                  onDisabledClick: _this4.handleDisableClick
                })
              )
            );
          }),
          factCards.length ? _react2.default.createElement(
            _Grid2.default,
            { item: true, xs: 12, md: 12 },
            _react2.default.createElement(_FactCardsCard2.default, {
              image: image,
              factCards: factCards,
              disabled: !entryDone,
              onDisabledClick: this.handleDisableClick
            })
          ) : "",
          items.filter(function (c) {
            return c.type === "Summary";
          }).map(function (item) {
            return _react2.default.createElement(
              _Grid2.default,
              { item: true, xs: 12, md: false ? 12 : 6, key: item.id },
              _react2.default.createElement(_TestCard2.default, {
                item: item,
                secondaryColors: true,
                show: showCards,
                hideIcon: true,
                url: "/recap/" + item.id,
                title: item.title || "",
                content: item.introText || "",
                buttonText: "Bekijk samenvatting",
                disabled: !entryDone,
                onDisabledClick: _this4.handleDisableClick
              })
            );
          }),
          items.filter(function (c) {
            return c.type === "Test";
          }).map(function (item) {
            return _react2.default.createElement(
              _Grid2.default,
              { item: true, xs: 12, md: false ? 12 : 6, key: item.id },
              _react2.default.createElement(_TestCard2.default, {
                item: item,
                show: showCards,
                hideIcon: true,
                url: "/test/" + item.id,
                title: item.title || "",
                content: item.introText || "",
                buttonText: "Start eindtoets",
                boxFinished: boxFinished,
                disabled: boxFinished ? false : testPercentage < 100 || !entryDone,
                onDisabledClick: _this4.handleDisableTestClick
              })
            );
          })
        ),
        _react2.default.createElement(
          _Dialog2.default,
          {
            open: showDialog,
            onClose: this.handleClose,
            PaperProps: { className: classes.dialogPaper }
          },
          _react2.default.createElement(
            _Typography2.default,
            { variant: "h6" },
            "Doe eerst de instaptoets"
          ),
          _react2.default.createElement(
            _Typography2.default,
            { variant: "body1", className: classes.body },
            "Je kunt kunt pas verder als je de instaptoets hebt voltooid"
          )
        ),
        _react2.default.createElement(
          _Dialog2.default,
          {
            open: showTestDialog,
            onClose: this.handleClose,
            PaperProps: { className: classes.dialogPaper }
          },
          _react2.default.createElement(
            _Typography2.default,
            { variant: "h6" },
            "Magazine nog niet helemaal gedaan"
          ),
          _react2.default.createElement(
            _Typography2.default,
            { className: classes.body },
            "Je kunt de toets pas maken als je de rest van het magazine hebt voltooid"
          )
        )
      );
    }
  }]);

  return Elements;
}(_react2.default.PureComponent);

exports.default = (0, _recompose.compose)((0, _reactRedux.connect)(function (state) {
  return {
    program: (0, _selectors2.getProgram)(state),
    appUser: (0, _selectors.getAppUser)(state),
    box: (0, _selectors3.getBoxId)(state),
    config: state.config
  };
}), _reactApollo.withApollo, (0, _styles.withStyles)(styles, { name: "AppElementsRoot" }), (0, _reactApollo.graphql)(_graphql.GQL_FETCH_BOX_RESULTS, {
  options: function options(props) {
    return {
      variables: {
        program: props.program,
        user: props.appUser && props.appUser.id
      }
    };
  }
}))(Elements);