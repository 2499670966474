export const OPEN_ASSETS_VIEWER = '@manakin/core/OPEN_ASSETS_VIEWER';
export const CLOSE_ASSETS_VIEWER = '@manakin/core/CLOSE_ASSETS_VIEWER';
export const PICK_ASSET = '@manakin/core/PICK_ASSET';
export const RESET_ASSETS_VIEWER = '@manakin/core/RESET_ASSETS_VIEWER';

export function openAssetsViewer(name, multi = false, assets = []) {
    return {
        type: OPEN_ASSETS_VIEWER,
        payload: {
            name: name,
            multi: multi,
            assets: assets
        }
    };
}

export function closeAssetsViewer() {
    return {
        type: CLOSE_ASSETS_VIEWER,
        payload: {}
    };
}

export function pickAsset(data) {
    return {
        type: PICK_ASSET,
        payload: {
            videoData: { ...data }
        }
    };
}

export function resetAssetViewer(data) {
    return {
        type: RESET_ASSETS_VIEWER,
        payload: {}
    };
}