import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import Root from "./components/Root";
import { store, history } from "./lib/store";
import graphqlClient from "./lib/graphql-client";
import theme from "./styling/themes/standard";
import "./index.css";

import { loadConfig } from "./config/config";
import loadConfiguration from "./config";

loadConfiguration().then(configuration =>
  store.dispatch(loadConfig(configuration))
);

ReactDOM.render(
  <Root store={store} theme={theme} history={history} client={graphqlClient} />,
  document.getElementById("root")
);
