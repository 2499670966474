import { createMuiTheme } from "@material-ui/core/styles";
import { createTheme, createColor } from "@manakin/theme";
import { green, black, white, beautyTertiary } from "../colors";
import amber from "@material-ui/core/colors/amber";
import blue from "@material-ui/core/colors/blue";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import loginBackgroundImage from "../../assets/login-background.png";
import accountBackgroundImage from "../../assets/account-background.jpg";
import loginVisual from "../../assets/login-visual.svg";
import pdfIcon from "../../assets/pdf.svg";
import logo from "../../assets/logo.svg";
import logoBackgroundImage from "../../assets/logo-background.png";
import boxOverviewLeafLeftBackgroundImage from "../../assets/leafs-left.png";
import boxOverviewLeafRightBackgroundImage from "../../assets/leafs-right.png";
import yellowDotImage from "../../assets/yellow-dot.png";
import pinkDotImage from "../../assets/pink-dot.png";
import elementsOverviewBackgroundImage from "../../assets/raspberries-transparent.png";
import fabricsBackgroundImageSmall from "../../assets/bg-fabrics.jpg";
import fruitGroupLeft from "../../assets/fruit-group-left.png";
import fruitGroupRight from "../../assets/fruit-group-right.png";
import yellowFooter from "../../assets/yellow-footer.png";
import berriesImage from "../../assets/berries.png";
import blueBerriesImage from "../../assets/blueberries.png";

const contentWidthXL = "1600px";
const contentWidthL = "1250px";
const contentWidthM = "1050px";
const contentWidthS = "700px";
const contentWidthXS = "340px";

///////COLORS
//primary and secondary colors
const primaryColor = createColor("#FBF6EA");
const secondaryColor = createColor("#FDDA24");
const tertiaryColor = beautyTertiary;

//Palette colors (Used for buttons, icons, navigation enz)
const primaryPaletteColor = createColor("#C63663");
const secondaryPaletteColor = createColor("#4A773C");

//type colors
const typeColor = tertiaryColor;
const primaryTypeColor = white;
const secondaryTypeColor = black;

//box name colors
const defaultBoxColor = createColor("#796E65");
const primaryBoxColor = white;
const secondaryBoxColor = defaultBoxColor;

//backgroundColors
const defaultBackgroundColor = primaryColor;
const defaultContentColor = createColor("#796E65");
const defaultTitleColor = createColor("#4A773C");
const secondaryBackgroundColor = createColor("#F2F2F2");
const purpleBackgroundColor = createColor("#6F5091");
const blueBackgroundColor = createColor("#0076A5");

//Menu colors
const mainMenuBackground = black;
const mainMenuColor = white;
const mainMenuSecondaryColor = white;

//navigation colors
const navigationColor = createColor("#645E54");
const secondaryNavigationColor = navigationColor;
const linkColor = black;
const defaultContentHover = createColor("#73C3B9");
const LessonButtonColor = black;
const defaultBorderHoverColor = createColor("#73C3B9");

//rest
const defaultBorderColor = createColor("#E6E6E6");
const correctAnswer = createColor("#B9E1DC");
const brown = createColor("#796E65");

//breakpoints
const smartphoneBreakpoint = "@media only screen and (min-width : 375px)";
const tabletPortraitBreakpoint = "@media only screen and (min-width : 768px)";
const tabletLandscapeBreakpoint = "@media only screen and (min-width : 1024px)";
const laptopBreakpoint = "@media only screen and (min-width : 1280px)";
const desktopBreakpoint = "@media only screen and (min-width : 1400px)";
const desktopXLBreakpoint = "@media only screen and (min-width : 2100px)";

//margins and paddings
const mainMenuListItemPaddingTop = "25px";
const mainMenuListItemPaddingBottom = "25px";
const defaultPadding = "25px";

//fonts
const defaultTitleFont = "Blog Script";
const secondaryTitleFont = "MarkOt";
const defaultAnswerFont = "MarkOt";
const defaultContentFont = "MarkOt";

const workformTitleFont = {
  fontFamily: defaultContentFont,
  fontSize: "4rem",
  lineHeight: "130%",
  letterSpacing: 0,
  fontWeight: 800
};

//heights
const defaultMobileMenuHeight = "64px";
const defaultDesktopMenuHeight = "100px";

const defaultWrapper = {
  maxWidth: contentWidthM,
  position: "relative",
  margin: "0 auto",
  width: "100%",
  paddingLeft: "25px",
  paddingRight: "25px"
};
const extraSmallWrapper = {
  ...defaultWrapper,
  maxWidth: contentWidthXS
};
const smallWrapper = {
  ...defaultWrapper,
  maxWidth: contentWidthS
};
const largeWrapper = {
  ...defaultWrapper,
  maxWidth: contentWidthL
};
const extraLargeWrapper = {
  ...defaultWrapper,
  maxWidth: contentWidthXL
};

const breakpoints = createBreakpoints({});

const theme = createMuiTheme({
  // eslint-disable-next-line
  ...theme,
  typography: {
    htmlFontsize: 10,
    fontFamily: [
      defaultContentFont,
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(",")
  },
  palette: {
    primary: primaryPaletteColor,
    secondary: secondaryPaletteColor,
    success: {
      light: green[200],
      main: green[400],
      dark: green[600],
      contrastText: "#ffffff"
    },
    info: {
      light: blue[300],
      main: blue[400],
      dark: blue[500],
      contrastText: "#ffffff"
    },
    warning: {
      light: amber[400],
      main: amber[600],
      dark: amber[700],
      contrastText: "#ffffff"
    }
  },
  manakin: createTheme({
    primaryColor: primaryColor,
    secondaryColor: secondaryColor,
    tertiaryColor: tertiaryColor,
    defaultBackgroundColor: defaultBackgroundColor,
    smartphoneBreakpoint: smartphoneBreakpoint,
    tabletPortraitBreakpoint: tabletPortraitBreakpoint,
    tabletLandscapeBreakpoint: tabletLandscapeBreakpoint,
    laptopBreakpoint: laptopBreakpoint,
    desktopBreakpoint: desktopBreakpoint,
    desktopXLBreakpoint: desktopXLBreakpoint,
    defaultContentColor: defaultContentColor,
    defaultTitleColor: defaultTitleColor,
    defaultWrapper: defaultWrapper,
    largeWrapper: largeWrapper,
    extraLargeWrapper: extraLargeWrapper,
    smallWrapper: smallWrapper,
    extraSmallWrapper: extraSmallWrapper,
    mainMenuBackground: mainMenuBackground,
    mainMenuColor: mainMenuColor,
    mainMenuSecondaryColor: mainMenuSecondaryColor,
    correctAnswer: correctAnswer,
    mainMenuListItemPaddingTop: mainMenuListItemPaddingTop,
    mainMenuListItemPaddingBottom: mainMenuListItemPaddingBottom,
    defaultBorderColor: defaultBorderColor,
    defaultBoxColor: defaultBoxColor,
    secondaryBoxColor: secondaryBoxColor,
    primaryBoxColor: primaryBoxColor,
    defaultTitleFont: defaultTitleFont,
    secondaryTitleFont: secondaryTitleFont,
    defaultAnswerFont: defaultAnswerFont,
    workformTitleFont: workformTitleFont,
    defaultPadding: defaultPadding,
    typeColor: typeColor,
    primaryTypeColor: primaryTypeColor,
    secondaryTypeColor: secondaryTypeColor,
    defaultMobileMenuHeight: defaultMobileMenuHeight,
    defaultDesktopMenuHeight: defaultDesktopMenuHeight,
    defaultContentFont: defaultContentFont,
    loginBackgroundImage: loginBackgroundImage,
    accountBackgroundImage: accountBackgroundImage,
    loginVisual: loginVisual,
    navigationColor: navigationColor,
    secondaryNavigationColor: secondaryNavigationColor,
    pdfIcon: pdfIcon,
    linkColor: linkColor,
    defaultContentHover: defaultContentHover,
    LessonButtonColor: LessonButtonColor,
    secondaryBackgroundColor: secondaryBackgroundColor,
    defaultBorderHoverColor: defaultBorderHoverColor,
    pinkDotImage: pinkDotImage,
    layoutButtonStyle: {
      borderColor: "transparent",
      color: brown[500],
      backgroundColor: white[500]
    },
    layoutButtonStyleSelected: {
      backgroundColor: brown[500],
      border: 0,
      color: white[500]
    },
    underline: {
      width: "50px",
      height: "2px",
      backgroundColor: defaultContentColor[500],
      position: "absolute",
      bottom: 0,
      left: "50%",
      transform: "translate(-50%, 2.5rem)"
    },
    visual: {
      backgroundImage: `url(${loginVisual})`,
      width: "100%",
      height: "100%",
      backgroundSize: "cover",
      display: "none"
    }
  }),
  overrides: {
    MuiSlider: {
      root: {
        marginTop: "26px"
      },
      rail: {
        height: "4px",
        backgroundColor: defaultContentColor[500]
      },
      track: {
        height: "4px"
      },
      thumb: {
        width: "30px",
        height: "30px",
        marginTop: "-13px",
        marginLeft: "-13px"
      },
      valueLabel: {
        backgroundColor: "transparent",
        color: primaryPaletteColor[500],
        left: "calc(-50% + 13px)",
        fontSize: "2.2rem",
        fontWeight: "bold",
        "& *": {
          backgroundColor: "transparent",
          color: "inherit"
        }
      }
    },
    MuiTypography: {
      root: {
        fontSize: "2rem"
      },
      h1: {
        fontFamily: defaultTitleFont,
        fontSize: "4rem",
        letterSpacing: "0rem",
        lineHeight: "5.2rem",
        color: defaultTitleColor[500],
        marginBottom: "1.4rem",
        [breakpoints.up("md")]: {
          fontSize: "8rem",
          lineHeight: "10rem"
        },
        [breakpoints.up("lg")]: {
          fontSize: "10rem",
          lineHeight: "12rem"
        }
      },
      h2: {
        fontFamily: defaultTitleFont,
        fontSize: "3.5rem",
        letterSpacing: ".4rem",
        lineHeight: "1.2",
        color: defaultTitleColor[500],
        marginBottom: "1.4rem",
        [breakpoints.up("md")]: {
          fontSize: "8rem"
          //lineHeight: '13.5rem'
        }
      },
      h3: {
        fontFamily: defaultTitleFont,
        fontSize: "3rem",
        lineHeight: "150%",
        color: defaultTitleColor[500],
        fontWeight: 800,
        marginBottom: "1.4rem",
        [breakpoints.up("md")]: {
          fontSize: "4rem"
        }
      },
      h4: {
        color: defaultTitleColor[500],
        fontFamily: defaultContentFont,
        fontWeight: 500,
        fontSize: "3.6rem",
        lineHeight: "5rem",
        marginBottom: "3.6rem",
        [breakpoints.up("md")]: {
          fontSize: "3.6rem",
          lineHeight: "6rem",
          marginBottom: "4.6rem"
        }
      },
      h5: {
        color: defaultTitleColor[500],
        fontFamily: secondaryTitleFont,
        fontSize: "2.6rem",
        fontWeight: "bold",
        lineHeight: "3.6rem",
        marginBottom: "1.3rem",
        [breakpoints.up("md")]: {
          lineHeight: "5.2rem",
          marginBottom: "0rem"
        }
      },
      h6: {
        color: defaultTitleColor[500],
        fontFamily: defaultTitleFont,
        fontSize: "1.8rem",
        fontWeight: "bold",
        lineHeight: "2rem",
        marginBottom: "1.3rem",
        letterSpacing: ".2rem",
        [breakpoints.up("md")]: {
          marginBottom: "1.6rem"
        }
      },
      subtitle1: {
        fontFamily: secondaryTitleFont,
        fontSize: "2.2rem",
        lineHeight: "5.2rem",
        color: defaultContentColor[500],
        marginBottom: "1.4rem",
        fontWeight: "bold"
      },
      subtitle2: {
        fontFamily: secondaryTitleFont,
        fontSize: "2.4rem",
        lineHeight: "150%",
        color: defaultContentColor[500],
        marginBottom: "1.4rem",
        fontWeight: "500",
        [breakpoints.up("md")]: {
          marginBottom: "4rem"
        }
      },
      body1: {
        fontSize: "1.8rem",
        color: defaultContentColor[500],
        lineHeight: "3.6rem",
        marginBottom: "2rem",
        [breakpoints.up("md")]: {
          marginBottom: "3rem"
        }
      },
      body2: {
        fontSmoothing: "auto",
        color: defaultContentColor[500],
        fontWeight: 600,
        fontSize: "2rem",
        lineHeight: "3rem",
        fontFamily: secondaryTitleFont,
        marginBottom: "2rem",
        [breakpoints.up("md")]: {
          fontWeight: 400
        }
      },
      gutterBottom: {
        marginBottom: "3em"
      }
    },
    AppBar: {
      root: {
        "& $logo": {
          width: "356px",
          height: "100px",
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "center",
          padding: "0 0 29px",
          [breakpoints.down("sm")]: {
            maxWidth: "28vw",
            height: "44px",
            padding: 0
          },
          "&:before": {
            content: '""',
            display: "inline-block",
            background: `url(${logo}) top center / contain no-repeat`,
            maxWidth: "159px",
            width: "100%",
            height: "44px",
            [breakpoints.down("sm")]: {
              backgroundPosition: "center",
              width: "100%",
              height: "100%"
            }
          },
          [breakpoints.up("md")]: {
            background: `url(${logoBackgroundImage}) top center / contain no-repeat`
          }
        }
      },
      programsDropdownRoot: {
        [breakpoints.down("md")]: {
          marginTop: 0
        }
      },
      menuButtonLabel: {
        fontFamily: secondaryTitleFont
      }
    },
    AppBoxLayout: {
      root: {
        display: "flex",
        flexFlow: "column nowrap",
        backgroundImage: `url(${yellowDotImage})`,
        backgroundPosition: "bottom -500px right -420px",
        backgroundRepeat: "no-repeat",
        backgroundSize: "1080px"
      },
      listView: {
        position: "relative",
        backgroundColor: "none",
        "&::after": {
          content: '""',
          backgroundColor: primaryColor[500],
          zIndex: "-1",
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%"
        }
      }
    },
    AppBoxes: {
      smallWrapper: {
        backgroundColor: "none"
      }
    },
    AppStatementQuestion: {
      primaryColors: {
        "& $button": {
          padding: "12px 20px"
        }
      },
      secondaryColors: {
        "& $button": {
          padding: "12px 20px"
        }
      }
    },
    AppImageAndTextMPC: {
      checked: {
        borderColor: primaryPaletteColor[500]
      }
    },
    AppRadioGroup: {
      formControlRoot: {
        borderRadius: "10px",
        backgroundColor: "white",
        "&:nth-child(n+2)": {
          marginTop: "10px"
        },
        "&:last-child": {
          borderBottom: 0
        },
        [breakpoints.up("md")]: {
          borderTop: 0,
          borderBottom: 0,
          borderLeft: 0,
          borderRight: 0
        }
      },
      active: {
        backgroundColor: secondaryColor[500],
        "& $formControlLabel": {
          color: defaultContentColor[500]
        }
      }
    },
    AppCheckQuestion: {
      formControlRoot: {
        borderRadius: "10px",
        backgroundColor: "white",
        "&:nth-child(n+2)": {
          marginTop: "10px"
        },
        "&:last-child": {
          borderBottom: 0
        },
        [breakpoints.up("md")]: {
          borderTop: 0,
          borderBottom: 0,
          borderLeft: 0,
          borderRight: 0
        }
      },
      active: {
        backgroundColor: secondaryColor[500],
        "& $formControlLabel": {
          color: defaultContentColor[500]
        }
      }
    },
    AppSearchResults: {
      root: {
        paddingTop: "32px",
        background: `url(${fabricsBackgroundImageSmall}) center / 320px repeat`
      }
    },
    MuiTablePagination: {
      caption: {
        fontSize: "1.6rem"
      },
      select: {
        fontSize: "1.6rem",
        lineHeight: "1.6rem",
        paddingRight: 32
      }
    },
    MuiStepConnector: {
      root: {
        display: "none"
      }
    },
    MuiStepper: {
      root: {
        backgroundColor: "transparent",
        padding: "0",
        margin: "30px 0"
      }
    },
    MuiSvgIcon: {
      root: {
        width: "2.4rem",
        height: "2.4rem"
      }
    },
    MuiStepIcon: {
      root: {
        transform: "scale(1.7)",
        margin: "0 10px"
      },
      text: {
        fontFamily: defaultContentFont,
        fontSize: "1.2rem"
      }
    },
    HelpButton: {
      fab: {
        backgroundColor: blueBackgroundColor[500]
      },
      icon: {
        marginTop: 0,
        fontWeight: 700
      }
    },
    MuiBadge: {
      dot: {
        width: "8px",
        height: "8px",
        backgroundColor: "red"
      }
    },
    MuiStepLabel: {
      iconContainer: {
        padding: "0 20px 0 0"
      },
      labelContainer: {
        display: "none"
      }
    },
    MuiFormGroup: {
      root: {
        width: "100%",
        flexDirection: "row"
      }
    },
    MuiFormControlLabel: {
      root: {
        marginRight: "20px",
        width: "40%",
        "@media (min-width: 1400px)": {
          width: "auto",
          marginRight: "50px"
        }
      }
    },
    MuiFormLabel: {
      root: {
        fontFamily: defaultContentFont,
        fontSize: "2rem",
        color: "black"
      }
    },
    MuiDialog: {
      paper: {
        margin: "18px",
        [breakpoints.up("md")]: {
          padding: "4.4rem 5rem 1rem"
        }
      },
      paperFullWidth: {
        minHeight: "53rem",
        borderRadius: 0
      }
    },
    MuiDialogTitle: {
      root: {
        [breakpoints.up("md")]: {
          padding: 0
        },
        "&>h2": {
          marginBottom: "4rem"
        }
      }
    },
    MuiDialogContent: {
      root: {
        [breakpoints.up("md")]: {
          padding: 0
        }
      }
    },
    MuiDialogActions: {
      root: {
        justifyContent: "center",
        flexDirection: "column",
        [breakpoints.up("md")]: {
          margin: 0
        }
      },
      action: {
        width: "100%",
        padding: "2rem"
      }
    },
    MuiFormHelperText: {
      root: {
        fontSize: "1.25rem"
      }
    },
    MuiMenuItem: {
      root: {
        fontSize: "1.8rem"
      }
    },
    MuiInput: {
      input: {
        padding: "14px 0",
        fontSize: "1.8rem",
        lineHeight: "3rem"
      }
    },
    MuiInputBase: {
      root: {
        color: defaultContentColor[500]
      },
      inputMultiline: {
        minHeight: "14rem"
      }
    },
    MuiInputLabel: {
      formControl: {
        color: defaultContentColor[500]
      }
    },
    MuiButton: {
      root: {
        zIndex: 1,
        color: "white",
        height: "auto",
        padding: "12px 20px",
        borderRadius: "4rem"
      },
      contained: {
        boxShadow: "none"
      },
      containedPrimary: {
        backgroundColor: primaryPaletteColor[500]
      },
      containedSecondary: {
        backgroundColor: secondaryPaletteColor[500],
        color: "white",
        "&:hover": {
          backgroundColor: secondaryPaletteColor[800]
        }
      },
      label: {
        fontFamily: defaultContentFont,
        fontWeight: "bold",
        position: "relative",
        lineHeight: "3.6rem",
        textTransform: "none",
        zIndex: 1,
        fontSize: "1.8rem",
        textDecoration: "none"
      },
      outlinedPrimary: {
        color: secondaryPaletteColor[500],
        border: `2px solid ${secondaryPaletteColor[500]}`,
        padding: "18px",
        "&:hover": {
          border: `2px solid ${secondaryPaletteColor[500]}`,
          backgroundColor: `rgba(${secondaryPaletteColor[500]}, 0.08)`
        }
      }
    },
    MuiDrawer: {
      paper: {
        padding: "3rem",
        fontFamily: defaultContentFont,
        fontSize: "1.8rem",
        color: mainMenuColor,
        background: primaryPaletteColor[500],
        [breakpoints.up("md")]: {
          padding: "1.8rem 4rem",
          fontSize: "3rem"
        }
      }
    },
    MuiChip: {
      label: {
        fontSize: "14px"
      }
    },
    MuiFormControl: {
      root: {
        width: "100%"
      }
    },
    MuiTableCell: {
      root: {
        padding: "4px 16px 4px 16px"
      },
      head: {
        border: 0,
        fontSize: "1.2rem",
        color: defaultContentColor[500],
        fontFamily: defaultTitleFont,
        lineHeight: "2rem",
        letterSpacing: ".4rem"
      },
      body: {
        paddingTop: "1.5rem",
        paddingBottom: "1.5rem"
      }
    },
    AppLessonBottom: {
      root: {
        backgroundColor: white[500],
        borderTop: `1px solid ${defaultBorderColor[500]}`
      },
      button: {
        minWidth: "300px",
        backgroundColor: primaryPaletteColor[500],
        "&:first-child": {
          margin: "0 1rem 0 0",
          [breakpoints.down("sm")]: {
            margin: "0 0 1rem 0"
          }
        }
      },
      backToBoxButton: {
        width: "100%"
      },
      redoQuestionButton: {
        display: "flex",
        paddingRight: "6rem",
        [breakpoints.up("md")]: {
          paddingRight: "16px"
        }
      }
    },
    AppLogin: {
      button: {
        borderColor: secondaryPaletteColor[500],
        color: secondaryPaletteColor[500]
      }
    },
    appLoginHeader: {
      content: {
        position: "relative"
      },
      headerTitle: {
        backgroundImage: `url(${logo})`,
        height: "12.6rem",
        width: "100%",
        backgroundPosition: "center",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        marginBottom: "0",
        [breakpoints.up("lg")]: {
          marginBottom: "5rem"
        }
      },
      visualTitle: {
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        bottom: "auto",
        "& h1": {
          color: secondaryPaletteColor[500],
          fontSize: "10rem",
          lineHeight: "12rem",
          textAlign: "center",
          maxWidth: "52rem",
          marginBottom: "2.3rem"
        },
        "& h3": {
          fontFamily: defaultContentFont,
          fontSize: "2.6rem",
          fontWeight: "bold",
          letterSpacing: "2.6px",
          lineHeight: "3.3rem",
          color: secondaryPaletteColor[500],
          textAlign: "center",
          textTransform: "uppercase"
        }
      }
    },
    AppDashboardHeader: {
      root: {
        background: `url(${fabricsBackgroundImageSmall}) center / 320px repeat`,
        [breakpoints.up("md")]: {
          background: `url(${boxOverviewLeafLeftBackgroundImage}) bottom 40px left / 245px no-repeat, 
            url(${boxOverviewLeafRightBackgroundImage}) top 175px right / 190px no-repeat,
            url(${fabricsBackgroundImageSmall}) center / 320px repeat`
        },
        "& $heading": {
          color: defaultTitleColor[500]
        },
        "& $content": {
          marginBottom: "5rem"
        },
        "& $contentColor": {
          color: defaultContentColor[500]
        }
      }
    },
    AppAccountWrapper: {
      root: {
        background: `url(${fabricsBackgroundImageSmall}) center / 320px repeat`,
        [breakpoints.up("md")]: {
          background: `url(${boxOverviewLeafLeftBackgroundImage}) bottom 40px left / 245px no-repeat, 
            url(${boxOverviewLeafRightBackgroundImage}) top 175px right / 190px no-repeat,
            url(${fabricsBackgroundImageSmall}) center / 320px repeat`
        }
      }
    },
    AppBookCheckOutro: {
      done: {
        display: "flex",
        alignItems: "center"
      }
    },
    AppBoxCard: {
      boxRoot: {
        borderRadius: ".8rem",
        color: defaultBoxColor[500]
      },
      boxName: {
        letterSpacing: 0,
        fontFamily: defaultContentFont,
        [breakpoints.up("md")]: {
          fontSize: "6rem",
          lineHeight: "6.5rem"
        }
      },
      boxDescription: {
        fontFamily: defaultContentFont
      },
      percentage: {
        fontFamily: defaultContentFont,
        fontSize: "1.6rem",
        letterSpacing: 0,
        color: primaryPaletteColor[500]
      },
      longArrow: {
        marginTop: "32px",
        "& .arrow": {
          color: "currentColor",
          backgroundColor: "currentColor"
        }
      },
      listView: {
        "& $longArrow": {
          display: "none"
        }
      }
    },
    AppResumeButton: {
      buttonText: {
        color: primaryPaletteColor[500],
        fontSize: "1.8rem",
        lineheight: "3.6rem",
        fontWeight: "bold"
      },
      longArrow: {
        "& .arrow": {
          backgroundColor: primaryPaletteColor[500]
        }
      }
    },
    AuthenticationLoginForm: {
      submit: {
        backgroundColor: secondaryPaletteColor[500]
      }
    },
    AppBoxHeader: {
      wrapper: {
        maxWidth: "800px"
      },
      root: {
        [breakpoints.up("lg")]: {
          backgroundImage: `url(${elementsOverviewBackgroundImage})`,
          backgroundSize: "636px",
          backgroundPosition: "bottom right -290px",
          backgroundRepeat: "no-repeat"
        }
      }
    },
    AppProfilePicture: {
      editPicture: {
        backgroundColor: primaryPaletteColor[500],
        color: "white",
        "&:hover": {
          backgroundColor: primaryPaletteColor[400]
        }
      }
    },
    AppBox: {
      root: {
        backgroundImage: `url(${fabricsBackgroundImageSmall})`,
        backgroundRepeat: "repeat",
        backgroundSize: "320px",
        paddingBottom: "1rem"
      }
    },
    AppElementOverlay: {
      title: {
        fontFamily: defaultContentFont,
        color: defaultContentColor[500],
        fontSize: "1.8rem",
        lineHeight: "2rem",
        letterSpacing: "0rem"
      }
    },
    AppElementCard: {
      root: {
        borderRadius: ".8rem"
      },
      bodytitle: {
        color: defaultContentColor[500]
      },
      done: {
        backgroundColor: primaryPaletteColor[500]
      },
      bridgeContent: {
        "& $contentTitle": {
          fontSize: "2.8rem",
          [breakpoints.up("md")]: {
            fontSize: "3.2rem"
          }
        },
        "& ul": {
          listStylePosition: "inside"
        }
      },
      name: {
        fontSize: "2.8rem",
        letterSpacing: "0",
        fontWeight: "bold",
        lineHeight: "3rem",
        fontFamily: defaultContentFont,
        [breakpoints.up("md")]: {
          fontSize: "3.2rem",
          letterSpacing: "0rem",
          lineHeight: "4rem"
        }
      },
      contentTitle: {
        fontFamily: defaultTitleFont
      },
      primaryBackgroundColor: {
        backgroundColor: primaryPaletteColor[500],
        "& $contentColor": {
          color: "white"
        },
        "& $downloadButton": {
          color: "white"
        }
      },
      secondaryBackgroundColor: {
        backgroundColor: secondaryColor[500],
        "& $contentColor": {
          color: defaultContentColor[500]
        },
        "& $downloadButton": {
          color: defaultContentColor[500]
        }
      }
    },
    AppVideoCard: {
      root: {
        borderRadius: ".8rem",
        overflow: "hidden"
      }
    },
    AppFactcardsCard: {
      root: {
        borderRadius: ".8rem",
        overflow: "hidden"
      },
      bodytitle: {
        color: defaultContentColor[500]
      }
    },
    AppFactCard: {
      image: {
        paddingRight: 0
      }
    },
    AppLessonIntroduction: {
      content: {
        fontSize: "2.2rem",
        "&:first-letter": {
          [breakpoints.up("md")]: {
            backgroundImage: `url(${pinkDotImage})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "top center",
            backgroundSize: "contain",
            fontFamily: defaultTitleFont,
            fontSize: "14rem",
            color: "white",
            padding: "2rem 5rem"
          }
        }
      }
    },
    AppLesson: {
      root: {
        backgroundImage: `url(${fabricsBackgroundImageSmall})`,
        backgroundRepeat: "repeat",
        backgroundSize: "320px"
      }
    },
    AppWhatIsWhatItem: {
      item: {
        [breakpoints.up("md")]: {
          border: 0,
          borderRadius: "0 0 10px 10px",
          marginRight: "2.5rem",
          "&:last-child": {
            margin: 0
          }
        }
      },
      isQuestion: {
        [breakpoints.up("md")]: {
          borderRadius: "10px 10px 0 0"
        }
      },
      animatePrev: {
        [breakpoints.up("lg")]: {
          transform: "translate(calc(-100% - 25px), 0)"
        }
      },
      animateNext: {
        [breakpoints.up("lg")]: {
          transform: "translate(calc(100% + 25px), 0)"
        }
      },
      animatePrevGap: {
        [breakpoints.up("lg")]: {
          transform: "translate(calc(-200% - 50px), 0)"
        }
      },
      animateNextGap: {
        [breakpoints.up("lg")]: {
          transform: "translate(calc(200% + 50px), 0)"
        }
      },
      correctIcon: {
        backgroundColor: "white",
        borderRadius: "100%",
        padding: "5px",
        [breakpoints.up("md")]: {
          padding: "5px",
          width: "30px",
          height: "30px"
        }
      }
    },
    AppImageAndTextCheckQuestion: {
      card: {
        backgroundColor: "white",
        borderRadius: "1rem",
        height: "200px"
      },
      innerCard: {
        zIndex: 2,
        backgroundColor: "inherit",
        borderRadius: "inherit",
        overflow: "hidden"
      },
      textWrapper: {
        [breakpoints.up("md")]: {
          minHeight: "auto",
          height: "auto",
          paddingBottom: "15px"
        }
      },
      text: {
        color: defaultContentColor[500]
      },
      checked: {
        transform: "translate(-50%, -50%) scale(.85)",
        borderRadius: "inherit",
        zIndex: 1,
        borderColor: primaryPaletteColor[500]
      },
      showIcon: {
        opacity: 1,
        transform: "translate(-50%, -50%) scale(1)"
      }
    },
    AppReferralElement: {
      contentBody: {
        backgroundColor: "white"
      }
    },
    AppTitleAndTextImage: {
      root: {
        backgroundColor: "white",
        "& $contentColor": {
          color: defaultContentColor[500]
        },
        "& $header": {
          color: secondaryPaletteColor[500]
        }
      },
      primary: {
        backgroundColor: purpleBackgroundColor[500],
        "& $contentColor": {
          color: "white"
        },
        "& $header": {
          color: "white",
          "&:after": {
            backgroundColor: "white"
          }
        }
      },
      secondary: {
        backgroundColor: blueBackgroundColor[500],
        "& $contentColor": {
          color: "white"
        },
        "& $header": {
          color: "white",
          "&:after": {
            backgroundColor: "white"
          }
        }
      },
      image: {
        objectFit: "cover"
      }
    },
    AppTitleAndText: {
      root: {
        backgroundColor: "white",
        "& $content": {
          color: defaultContentColor[500]
        },
        "& $header": {
          color: secondaryPaletteColor[500]
        }
      },
      primary: {
        backgroundColor: purpleBackgroundColor[500],
        "& $content": {
          color: "white"
        },
        "& $header": {
          color: "white",
          "&.smallUnderline": {
            "&:after": {
              backgroundColor: "white"
            }
          }
        }
      },
      secondary: {
        backgroundColor: blueBackgroundColor[500],
        "& $content": {
          color: "white"
        },
        "& $header": {
          color: "white",
          "&.smallUnderline": {
            "&:after": {
              backgroundColor: "white"
            }
          }
        }
      },
      image: {
        objectFit: "cover"
      }
    },
    AppImageCheckQuestion: {
      card: {
        borderRadius: "10px",
        overflow: "hidden",
        [breakpoints.up("md")]: {
          height: "200px"
        }
      },
      selected: {
        borderColor: secondaryColor[500]
      },
      showIcon: {
        transform: "translate(-50%, -50%) scale(.9)",
        zIndex: 99
      }
    },
    HotspotsMap: {
      button: {
        [breakpoints.up("md")]: {
          bottom: "10px",
          right: "10px"
        }
      }
    },
    AppOrderQuestion: {
      card: {
        backgroundColor: "white",
        color: defaultContentColor[500],
        borderRadius: "10px"
      },
      text: {
        [breakpoints.up("md")]: {
          marginLeft: "4rem"
        }
      },
      arrows: {
        color: primaryPaletteColor[500]
      },
      dragRoot: {
        [breakpoints.up("md")]: {
          paddingLeft: "0rem"
        }
      },
      number: {
        display: "none",
        [breakpoints.up("md")]: {
          display: "none"
        }
      },
      buttons: {
        [breakpoints.up("md")]: {
          paddingLeft: "0rem"
        }
      }
    },
    AppSortSlider: {
      questions: {
        backgroundColor: "white"
      },
      controls: {
        backgroundColor: primaryColor[500],
        [breakpoints.up("md")]: {
          backgroundColor: primaryColor[500]
        }
      },
      button: {
        [breakpoints.up("md")]: {
          padding: "5px 0"
        }
      },
      progress: {
        color: "white",
        backgroundColor: secondaryPaletteColor[500]
      }
    },
    AppImageMPC: {
      image: {
        borderRadius: "10px",
        overflow: "hidden"
      },
      checked: {
        borderRadius: "10px",
        borderColor: primaryPaletteColor[500]
      }
    },
    AppMoodboard: {
      text: {
        fontFamily: defaultContentFont,
        fontSize: "1.8rem",
        lineHeight: "3.6rem",
        color: defaultContentColor[500],
        [breakpoints.up("md")]: {
          fontSize: "1.8rem",
          lineHeight: "3.6rem"
        }
      },
      title: {
        [breakpoints.up("md")]: {
          fontSize: "5rem",
          lineHeight: "6.4rem",
          marginBottom: "11px",
          "&:after": {
            display: "none"
          }
        }
      }
    },
    AppBettingGameFrontPageBody: {
      root: {
        [breakpoints.up("md")]: {
          background: `
            url(${fabricsBackgroundImageSmall}) top 0 left 0 / 320px repeat,
            #fbf6ea`
        }
      }
    },
    AppBettingGameFrontpageHeading: {
      headingRoot: {
        [breakpoints.up("md")]: {
          background: `
            url(${fruitGroupLeft}) top 150px left -100px / 472px no-repeat, 
            url(${fruitGroupRight}) top 90px right -90px / 420px no-repeat, 
            url(${fabricsBackgroundImageSmall}) top 0 left 0 / 320px repeat,
            #fbf6ea`
        }
      },
      title: {
        backgroundImage: `url(${yellowDotImage})`,
        backgroundSize: "contain",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat"
      }
    },
    AppBettingGameQuestions: {
      root: {
        background: `
          url(${yellowFooter}) bottom left / 100% no-repeat,
          url(${fabricsBackgroundImageSmall}) top left / 320px repeat,
          #fbf6ea
        `,
        [breakpoints.up("md")]: {
          background: `
            url(${blueBerriesImage}) top 100px right / 240px no-repeat,
            url(${berriesImage}) top 300px left / 110px no-repeat,
            url(${yellowFooter}) bottom left / 100% no-repeat,
            url(${fabricsBackgroundImageSmall}) top left / 320px repeat,
            #fbf6ea
          `
        }
      }
    },
    AppBoxInfo: {
      root: {
        borderRadius: "8px",
        overflow: "hidden"
      }
    },
    AppPointsInfo: {
      startPoints: {
        backgroundImage: `url(${pinkDotImage})`,
        backgroundSize: "contain",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat"
      }
    },
    AppSliderInput: {
      sliderLabel: {
        fontSize: "inherit",
        fontWeight: 600
      }
    },
    AppWorkformLayout: {
      header: {
        fontFamily: defaultContentFont
      }
    },
    appBestTimes: {
      user: {
        backgroundColor: "white",
        borderRadius: "5px",
        marginBottom: "1rem"
      },
      currentUser: {
        backgroundColor: secondaryColor[500]
      },
      wrapper: {
        padding: "0!important"
      }
    },
    AppBettingGameResultsPage: {
      root: {
        background: `
          url(${fabricsBackgroundImageSmall}) top left / 320px repeat,
          #fbf6ea
        `,
        [breakpoints.up("md")]: {
          background: `
            url(${blueBerriesImage}) top 100px right / 240px no-repeat,
            url(${berriesImage}) top 300px left / 110px no-repeat,
            url(${yellowDotImage}) bottom -300px right -200px / 800px no-repeat,
            url(${fabricsBackgroundImageSmall}) top left / 320px repeat,
            #fbf6ea
          `
        }
      }
    },
    AppHotspotMapLandscape: {
      question: {
        fontWeight: "bold",
        marginBottom: "7rem",
        fontSize: "4rem",
        marginTop: "1.8rem",
        wordBreak: "break-word",
        hyphens: "auto",
        color: secondaryPaletteColor[500],
        [breakpoints.down("xs")]: {
          fontSize: "2.4rem",
          lineHeight: "4rem",
          marginBottom: "2rem"
        }
      }
    },
    AppDownloadButton: {
      content: {
        backgroundColor: "white"
      }
    },
    SingleDot: {
      addIcon: {
        borderColor: primaryPaletteColor[500],
        "& svg": {
          background: primaryPaletteColor[500]
        }
      },
      HotspotBlockTitle: {
        color: secondaryPaletteColor[500]
      },
      HotspotBlockText: {
        color: defaultContentColor[500]
      }
    }
  }
});

console.log("theme", theme);

export default theme;
