import React from 'react';
import { graphql } from 'react-apollo';

const withAccessControl = (currentUser) => WrappedComponent => {
    return class WithAccessControl extends React.Component {
    	render() {
    		const { ...otherProps } = this.props;

    		return (
                <WrappedComponent
                    {...otherProps}
                    accessControl={{

                    }}
                />
    		)
    	}
    }
}

export default withAccessControl