import React from 'react';
import { Elements } from '@manakin/app-core';

class ElementsLayout extends React.PureComponent {
  render() {
    const { boxStyle, elementResults, items } = this.props;
    return (
      <Elements
        boxStyle={boxStyle}
        items={items}
        elementResults={elementResults}
      />
    );
  }
}

export default ElementsLayout;
