import React from 'react';
import SortQuestion from './SortQuestion';

class SortQuestionContainer extends React.PureComponent {
	handleFinish = () => {
		this.props.onFinished({correct: true, workform: []})
	}

	render() {
		return (
			<SortQuestion {...this.props} onNext={this.props.onNext} onFinished={this.handleFinish} />
		)
	}
}

export default SortQuestionContainer;