import React, { useState } from "react";
import { ElementOverlay } from "@manakin/app-core";
import Heading from "./Heading";
import Body from "./Body";
import { withStyles } from "@material-ui/core";
import { getBoxId } from "@manakin/app-core/Boxes/selectors";
import { compose } from "recompose";
import { connect } from "react-redux";

const styles = theme => ({
  innerRoot: {
    width: "100%"
  }
});

const FrontPage = props => {
  const { classes, data = {}, boxId, config = {} } = props;
  const { literals = {} } = config;

  //state hooks
  const [points, setPoints] = useState(300);
  const [loading, setLoading] = useState(true);

  //functions
  const handlePoints = _points => {
    setPoints(300 + _points);
  };

  const handleClick = () => {
    if (props.onClick) props.onClick(points);
  };

  const handleHighscoreClick = () => {
    if (props.onHighscoreClick) props.onHighscoreClick();
  };

  const handleStoppedLoading = () => {
    setLoading(false);
  };

  return (
    <div>
      <ElementOverlay
        fullWidth={true}
        controls={true}
        title={literals.APP_BETTING_GAME_TITLE || "The betting game"}
      >
        <div className={classes.innerRoot}>
          {!loading && (
            <Heading
              onClick={handleClick}
              onHighscoreClick={handleHighscoreClick}
              data={data}
            />
          )}
          <Body
            onAddPoints={handlePoints}
            stoppedLoading={handleStoppedLoading}
            boxId={boxId}
          />
        </div>
      </ElementOverlay>
    </div>
  );
};

const mapStateToProps = state => ({
  config: state.config,
  boxId: getBoxId(state)
});

export default compose(connect(mapStateToProps), withStyles(styles))(FrontPage);
