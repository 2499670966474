"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var styles = exports.styles = function styles(theme) {
  var _$backgroundImage2, _bridgeContent, _listView;

  return {
    contentTitle: {},
    root: {
      height: "100%",
      width: "100%",
      position: "relative",
      backgroundColor: theme.manakin.secondaryColor[500],
      overflow: "hidden",
      display: "inline-block",
      opacity: 0,
      transform: "translateX(-20px)",
      "&:hover": {
        "& $backgroundImage": _defineProperty({}, theme.breakpoints.up("md"), {
          transform: "scale(1.1)"
        })
      }
    },
    bodytitle: {},
    Trainer: {},
    show: {
      opacity: 1,
      transform: "translateX(0)"
    },
    bookCheck: _defineProperty({
      backgroundColor: theme.manakin.secondaryColor[500],
      padding: "3.6rem 3rem",
      "& $content": {
        display: "none"
      },
      "& $backgroundImage": {
        display: "none"
      }
    }, theme.breakpoints.up("md"), {
      height: "14rem" /* anything less than the minHeight below to avoid flex center issues in IE11, see: https://github.com/philipwalton/flexbugs/issues/231 */
      , minHeight: "15rem",
      display: "flex",
      flexDirection: "row",
      padding: "2.5rem 5rem"
    }),
    fullWidth: _defineProperty({}, theme.breakpoints.up("md"), {
      "&$bookCheck": {
        "& $backgroundImage": (_$backgroundImage2 = {
          display: "none"
        }, _defineProperty(_$backgroundImage2, "display", "block"), _defineProperty(_$backgroundImage2, "width", "150px"), _defineProperty(_$backgroundImage2, "height", "100%"), _$backgroundImage2),
        padding: "2.5rem 5rem 2.5rem 20rem"
      }
    }),
    body: {},
    bookCheckContent: _defineProperty({}, theme.breakpoints.up("md"), {
      display: "flex",
      flexDirection: "column",
      flex: "1 1 400px",
      paddingRight: "2rem",
      "& p": {
        marginBottom: 0
      },
      "& $body": {
        marginBottom: 0
      }
    }),
    bookCheckFlex: _defineProperty({}, theme.breakpoints.up("md"), {
      display: "flex",
      width: "100%",
      alignItems: "center"
    }),
    button: {
      width: "auto",
      minWidth: "260px",
      minHeight: "60px"
    },
    minHeight: _defineProperty({
      minHeight: "250px",
      "& $done": {
        top: "1rem",
        right: "1rem",
        position: "absolute"
      }
    }, theme.breakpoints.up("md"), {
      minHeight: "500px",
      "& $done": {
        top: "3rem",
        right: "3rem",
        position: "absolute"
      }
    }),
    backgroundImage: {
      position: "absolute",
      zIndex: "0",
      height: "100%",
      width: "100%",
      left: "0",
      top: "0",
      transition: "width .3s, transform .3s, background-color .3s"
    },
    image: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      fontFamily: "'object-fit: cover'"
    },
    noBackground: {
      "& $backgroundImage": {
        display: "none"
      }
    },
    link: {
      textDecoration: "none",
      display: "inline",
      cursor: "pointer"
    },
    linkToSlideShowEdit: {
      display: "flex",
      color: theme.palette.primary.main,
      textDecoration: "none"
      //display: 'none'
    },
    linkToSlideShowEditIcon: {
      marginRight: theme.spacing(2)
    },
    slideshowLinks: {
      marginBottom: theme.spacing(4)
    },
    slideshowLinksItem: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: theme.spacing(2)
    },
    slideshowTitle: {
      color: theme.palette.primary.main,
      lineHeight: "normal",
      margin: 0
    },
    slideshowAuthor: {
      margin: 0,
      lineHeight: "normal",
      fontSize: "16px"
    },
    content: _defineProperty({
      position: "absolute",
      bottom: "26px",
      padding: "0 26px"
    }, theme.breakpoints.up("md"), {
      padding: "0 50px",
      bottom: "50px"
    }),
    type: {
      display: "block",
      fontFamily: theme.manakin.secondaryTitleFont,
      fontSize: "2rem",
      lineHeight: "2rem",
      color: theme.manakin.typeColor[500],
      marginBottom: "1.4rem"
    },
    secondaryPrefixColor: {
      color: theme.manakin.secondaryTypeColor[500]
    },
    primaryPrefixColor: {
      color: theme.manakin.primaryTypeColor[500]
    },
    name: _defineProperty({
      margin: 0,
      color: theme.manakin.defaultBoxColor[400]
    }, theme.breakpoints.up("md"), {}),
    primaryTitleColor: {
      color: theme.manakin.primaryBoxColor[400]
    },
    secondaryTitleColor: {
      color: theme.manakin.secondaryBoxColor[400]
    },
    contentColor: {},
    primaryBackgroundColor: {
      backgroundColor: theme.manakin.primaryColor[500],
      "& $contentColor": {
        color: theme.palette.getContrastText(theme.manakin.primaryColor[500])
      },
      "& $downloadButton": {
        color: theme.palette.getContrastText(theme.manakin.primaryColor[500])
      }
    },
    secondaryBackgroundColor: {
      backgroundColor: theme.manakin.secondaryColor[500],
      "& $contentColor": {
        color: theme.palette.getContrastText(theme.manakin.secondaryColor[500])
      },
      "& $downloadButton": {
        color: theme.palette.getContrastText(theme.manakin.secondaryColor[500])
      }
    },
    slideshowCard: {
      display: "flex",
      flexFlow: "column nowrap"
    },
    slideshowBox: _defineProperty({
      display: "inherit",
      flexFlow: "inherit",
      flexGrow: 1,
      padding: "26px"
    }, theme.breakpoints.up("md"), {
      padding: "50px"
    }),
    newSlideshowButton: {
      marginTop: "auto"
    },
    bridgeContent: (_bridgeContent = {
      padding: theme.manakin.defaultPadding
    }, _defineProperty(_bridgeContent, theme.breakpoints.up("md"), {
      padding: "100px"
    }), _defineProperty(_bridgeContent, "& h2", _defineProperty({
      fontFamily: theme.manakin.secondaryTitleFont,
      fontSize: "3rem",
      lineHeight: "4rem",
      fontWeight: "500"
    }, theme.breakpoints.up("md"), {
      fontSize: "3.6rem",
      lineHeight: "5.2rem"
    })), _defineProperty(_bridgeContent, '& $contentColor', {
      '&>ul': _defineProperty({
        paddingLeft: '3rem'
      }, theme.breakpoints.up('md'), {
        paddingLeft: '5rem'
      })
    }), _bridgeContent),
    doneIcon: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "1.5rem",
      width: "1.5rem",
      color: theme.palette.getContrastText(theme.palette.secondary[500])
    },
    done: _defineProperty({
      marginLeft: theme.spacing(2),
      flexShrink: 0,
      width: "30px",
      height: "30px",
      borderRadius: "100%",
      backgroundColor: theme.palette.secondary[500],
      zIndex: "2",
      right: "34px",
      top: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    }, theme.breakpoints.up("md"), {
      width: "60px",
      height: "60px"
    }),
    bookCheckDone: {
      width: "32px",
      height: "32px"
    },
    inCorrect: {
      backgroundColor: theme.palette.error["light"]
    },
    continue: {
      backgroundColor: "transparent",
      "& $doneIcon": {
        color: "black",
        width: "2.5rem",
        height: "2.5rem"
      }
    },
    iconRoot: {
      flex: "1",
      width: "auto",
      height: "auto"
    },
    listView: (_listView = {
      height: "6rem",
      minHeight: "6rem",
      margin: "0 1.6rem 1rem",
      border: "1px solid " + theme.manakin.defaultBorderColor[500]
    }, _defineProperty(_listView, theme.breakpoints.up("md"), {
      minHeight: "10rem",
      height: "10rem",
      margin: "0 2.5rem 2rem"
    }), _defineProperty(_listView, "& $content", _defineProperty({
      padding: "0 0 0 8rem",
      position: "relative",
      bottom: 0,
      height: "100%",
      display: "flex",
      width: "100%",
      alignItems: "center"
    }, theme.breakpoints.up("md"), {
      padding: "0 0 0 14rem"
    })), _defineProperty(_listView, "& $type", {
      display: "none"
    }), _defineProperty(_listView, "& $backgroundImage", _defineProperty({
      width: "6rem"
    }, theme.breakpoints.up("md"), {
      width: "10rem"
    })), _defineProperty(_listView, "& $name", {
      fontSize: "1.8rem",
      letterSpacing: "0rem",
      lineHeight: "2rem",
      fontWeight: "600",
      transition: "color .3s",
      "&:hover": {
        color: theme.manakin.defaultContentHover[500]
      }
    }), _listView),
    downloadButton: {},
    downloadLinks: {
      "& $iconRoot": {
        flex: 0,
        display: "flex",
        marginRight: "2rem",
        marginTop: "-3px",
        transition: "transform .3s, color .3s"
      },
      "& $downloadButton": {
        cursor: "pointer",
        fontWeight: "bold",
        marginBottom: 0,
        display: "flex",
        transition: "color .3s",
        alignItems: "center",
        "&:hover": {
          color: theme.manakin.defaultContentHover[500],
          "& $iconRoot": {
            transform: "translateX(2px)",
            color: theme.manakin.defaultContentHover[500]
          }
        }
      }
    },
    disabled: {
      "& $show": {
        opacity: ".3"
      }
    }
  };
};