import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/SaveAlt';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Input from '@material-ui/core/Input';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import NewSlidePopper from './parts/NewSlidePopper';
import SlidePreview from './parts/SlidePreview';
import Slide from './parts/Slide';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { styles } from './parts/styles';
import { withStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const SlideshowSetup = props => {
  const {
    classes,
    addSlideMenu,
    addSlideMenuExit,
    addSlide,
    deleteSlide,
    editSlide,
    handleDataChange,
    slides,
    popperPlacement,
    anchorEl,
    activeSlide,
    onDragEnd,
    addImageDialog,
    closeImageDialog,
    onSaveSlideshow,
    onDeleteSlideshow,
    onChange,
    title,
    isPublic,
    onCheckboxChange
  } = props;

  const desktop = useMediaQuery('(min-width:960px)');

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.headerWrapper}>
          <div className={classes.slideShowSetup}>
            <Input
              classes={{ root: classes.slideShowTitle }}
              placeholder="De titel van de slideshow"
              value={title}
              autoFocus
              multiline={true}
              rows={1}
              rowsMax={Infinity}
              disableUnderline
              inputProps={{
                classes: { textarea: classes.slideShowTitleInput }
              }}
              onChange={onChange}
            />

            <Button
              buttonRef={anchorEl => {
                anchorEl;
              }}
              onClick={event => addSlideMenu(event)}
              className={classes.button}
              aria-haspopup="true"
            >
              Slide toevoegen
            </Button>

            <FormControlLabel
              classes={{
                root: classes.formControl,
                label: classes.formControlLabel
              }}
              value="shareWithSchoolTeachers"
              control={
                <Checkbox
                  className={classes.checkbox}
                  color="primary"
                  classes={{ checked: classes.checkboxChecked }}
                  checked={isPublic}
                  onChange={onCheckboxChange}
                />
              }
              label="Deel met alle docenten van mijn school"
              labelPlacement="end"
            />
          </div>
          <div className={classes.slideShowActions}>
            <Button className={classes.saveButton} onClick={onSaveSlideshow}>
              <SaveIcon className={classes.buttonIcon} />
              Slideshow opslaan
            </Button>
            <Button className={classes.deleteButton} onClick={onDeleteSlideshow}>
              <DeleteIcon className={classes.buttonIcon} />
              Verwijder slideshow
            </Button>
          </div>
        </div>
      </div>

      <Grid className={classes.slideSetupWrapper} container>
        <Grid
          className={classes.slidePreviewsWrapper}
          item
          xs="auto"
          zeroMinWidth={true}
        >
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable
              droppableId={'droppableSlides'}
              direction={desktop ? 'vertical' : 'horizontal'}
            >
              {provided => (
                <div
                  className={classes.slidePreviewsList}
                  ref={provided.innerRef}
                >
                  {slides && slides.length > 0 ? (
                    slides.map((slide, idx) => {
                      return (
                        <Draggable
                          className={classes.draggable}
                          key={slide.idx}
                          draggableId={slide.idx}
                          index={idx}
                          disableInteractiveElementBlocking
                        >
                          {provided => (
                            <div
                              className={classes.slidePreviewItem}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <SlidePreview
                                {...props}
                                active={activeSlide === slide.idx}
                                slideIndex={slide.idx}
                                slideType={slide.type}
                                slideData={slides}
                                slide={slide}
                                onClick={() => editSlide(slide.idx)}
                              >
                                <IconButton
                                  className={classes.deleteSlide}
                                  onClick={() => deleteSlide(slide.idx)}
                                >
                                  <DeleteIcon fontSize="inherit" />
                                </IconButton>
                              </SlidePreview>
                            </div>
                          )}
                        </Draggable>
                      );
                    })
                  ) : (
                      <Draggable key={0} draggableId={'0'} index={0}>
                        {provided => (
                          <div
                            className={classes.slidePreviewItem}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <SlidePreview {...props} />
                          </div>
                        )}
                      </Draggable>
                    )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Grid>

        <Grid className={classes.slideSetup} item xs="auto" zeroMinWidth={true}>
          {slides && slides.length > 0 ? (
            slides.map(slide => {
              if (activeSlide === slide.idx) {
                return (
                  <Slide
                    {...props}
                    key={slide.idx}
                    slideIndex={slide.idx}
                    slideType={slide.type}
                    slideData={slides}
                    slide={slide}
                    handleDataChange={handleDataChange}
                  />
                );
              }
            })
          ) : (
              <Button
                buttonRef={anchorEl => {
                  anchorEl;
                }}
                classes={{
                  root: classes.slideSetupButton,
                  label: classes.slideSetupButtonLabel
                }}
                onClick={event => addSlideMenu(event, 'top')}
                placement="top"
                aria-haspopup="true"
                variant="contained"
              >
                Voeg een slide toe
            </Button>
            )}
        </Grid>
      </Grid>

      <NewSlidePopper
        {...props}
        anchorEl={anchorEl}
        onClickAway={addSlideMenuExit}
        addSlide={addSlide}
        slides={slides}
        placement={popperPlacement}
        addImageDialog={addImageDialog}
        closeImageDialog={closeImageDialog}
      />
    </div>
  );
};

export default withStyles(styles)(SlideshowSetup);
