import gql from 'graphql-tag';

export const GQL_FETCH_CURRENT_CMS_USER = gql`
    query {
        currentCmsUser {
            id
            roles {
                name
                privileges {
                    id
                    label
                }
            }
        }
    }
`;