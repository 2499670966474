import React from 'react';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import { PasswordField } from '@manakin/core';
import { withStyles } from '@material-ui/core/styles';
import { withForm } from '@manakin/core';

const styles = theme => ({
    root: {},
    submit: {
        marginTop: theme.spacing(2)
    }
});

const form = {
    password: {
        format: 'password',
        required: true
    }
};

class ChangePasswordForm extends React.Component {
    handleSubmit = event => {
        event.preventDefault();
        this.props.form.onSubmit();
    };

    render() {
        const { classes, submitLabel, form } = this.props;

        const { onSubmit } = this.props.form;

        return (
            <div className={classes.root}>
                <PasswordField
                    name="password"
                    label="Nieuw wachtwoord"
                    onEnterPressed={onSubmit}
                    form={form}
                />

                <FormControl margin="normal">
                    <Button
                        className={classes.submit}
                        color="primary"
                        variant="contained"
                        onClick={onSubmit}>
                        {submitLabel || 'Bevestigen'}
                    </Button>
                </FormControl>
            </div>
        );
    }
}

export default withForm(form)(
    withStyles(styles, { name: 'AuthenticationChangePasswordForm' })(
        ChangePasswordForm
    )
);
