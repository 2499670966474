"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _recompose = require("recompose");

var _selectors = require("../ProgramsDropdown/selectors");

var _actions = require("../ProgramsDropdown/actions");

var _actions2 = require("../GroupsDropdown/actions");

var _selectors2 = require("@manakin/authentication/selectors");

var _selectors3 = require("../GroupsDropdown/selectors");

var _reactRedux = require("react-redux");

var _graphql = require("./graphql");

var _reactApollo = require("react-apollo");

var _cloneDeep = require("lodash/cloneDeep");

var _cloneDeep2 = _interopRequireDefault(_cloneDeep);

var _lib = require("../lib");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var BoxService = function (_React$Component) {
  _inherits(BoxService, _React$Component);

  function BoxService() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, BoxService);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = BoxService.__proto__ || Object.getPrototypeOf(BoxService)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
      boxes: [],
      milestones: [],
      percentagePerBox: {}
    }, _this.handleAppUser = function () {
      var _this$props$data$appU = _this.props.data.appUser,
          appUser = _this$props$data$appU === undefined ? {} : _this$props$data$appU;

      var boxes = [];
      var milestones = [];
      if (appUser) {
        appUser.schoolClasses && appUser.schoolClasses.forEach(function (item) {
          if (item.product && item.product.program) {
            if (item.product.program.id == _this.props.program) {
              if (item.boxes.length) {
                boxes = [].concat(_toConsumableArray(item.boxes));
              }
              if (item.milestones.length) {
                milestones = [].concat(_toConsumableArray(item.milestones));
              }
            }
          }
        });
      }
      _this.setState({ boxes: boxes, milestones: milestones });
    }, _this.handleProgram = function () {
      var group = _this.props.group;


      if (_this.props.program && typeof _this.props.program == "string") {
        _this.fetchBoxes(_this.props.program);
      } else {
        setTimeout(function () {
          if (!group) {
            if (_this.props.onLoadChange) _this.props.onLoadChange(false);
          }
        }, 3000);
      }
    }, _this.getElementResults = function (box, programId) {
      var _this$props$appUser = _this.props.appUser,
          appUser = _this$props$appUser === undefined ? {} : _this$props$appUser;
      var client = _this.props.client;


      return client.query({
        query: _graphql.GQL_FETCH_ELEMENT_RESULTS,
        variables: {
          box: box.id,
          program: programId,
          user: appUser.id
        }
      });
    }, _this.isInArr = function (item, arr) {
      var isInArray = false;
      arr.forEach(function (i) {
        if (i.id === item) isInArray = true;
      });
      return isInArray;
    }, _this.removedeprocated = function (arr1, arr2) {
      var newArr = [];
      arr1.forEach(function (item) {
        if (item.element && item.element.id) {
          if (_this.isInArr(item.element.id, arr2)) newArr.push(item);
        }
      });

      return newArr;
    }, _this.setPercentage = function (box, results, _data, isLast) {
      var _box$elements = box.elements,
          elements = _box$elements === undefined ? [] : _box$elements;
      var _results$data = results.data,
          data = _results$data === undefined ? [] : _results$data;

      if (data) {
        var _data$elementResults = data.elementResults,
            elementResults = _data$elementResults === undefined ? [] : _data$elementResults;
        var _this$props = _this.props,
            _this$props$BOX_RESUL = _this$props.BOX_RESULTS,
            BOX_RESULTS = _this$props$BOX_RESUL === undefined ? {} : _this$props$BOX_RESUL,
            _this$props$config = _this$props.config,
            config = _this$props$config === undefined ? {} : _this$props$config;
        var _BOX_RESULTS$boxResul = BOX_RESULTS.boxResults,
            boxResults = _BOX_RESULTS$boxResul === undefined ? [] : _BOX_RESULTS$boxResul;


        var percentage = (0, _lib.getPercentage)(elementResults, elements);

        if (config.general && config.general.useBoxResults) {
          if (boxResults.some(function (item) {
            return item.box.id === box.id && item.finished;
          })) {
            percentage = 100;
          }
          if (boxResults.some(function (item) {
            return item.box.id === box.id && !item.finished;
          }) && percentage === 100) {
            percentage = 98;
          }
        }

        _this.setState(function (prevState) {
          return {
            percentagePerBox: _extends({}, prevState.percentagePerBox, _defineProperty({}, box.id, Math.floor(percentage)))
          };
        }, function () {
          return isLast && _this.handleResults(_data);
        });
      }
    }, _this.getBoxResults = function (data, programId) {
      if (_this.props.onLoadChange) {
        _this.props.onLoadChange(true);
      }
      var _this$props$appUser2 = _this.props.appUser,
          appUser = _this$props$appUser2 === undefined ? {} : _this$props$appUser2;
      var _this$state$boxes = _this.state.boxes,
          boxes = _this$state$boxes === undefined ? [] : _this$state$boxes;

      var program = (0, _cloneDeep2.default)(data);
      var loopIdx = 0;
      var loopIndex = 0;
      var boxLength = 0;

      if (boxes.length) {
        program.program.boxes = boxes;
      }
      if (program.program.boxes && appUser && appUser.id) {
        program.program.boxes.forEach(function (box, index) {
          loopIndex++;
          boxLength += box.length;
          box.forEach(function (_box, idx) {
            _this.getElementResults(_box, programId).then(function (r) {
              loopIdx++;

              var isLast = loopIndex == program.program.boxes.length && loopIdx == boxLength;

              _this.setPercentage(_box, r, data, isLast);
            }, function (e) {
              if (_this.props.onLoadChange) {
                _this.props.onLoadChange(false);
              }
            });
          });
        });
      } else {
        if (_this.props.onLoadChange) {
          _this.props.onLoadChange(false);
        }
      }
    }, _this.handleResults = function (data) {
      if (_this.props.onLoadChange) {
        _this.props.onLoadChange(false);
      }
      var _this$state = _this.state,
          _this$state$boxes2 = _this$state.boxes,
          boxes = _this$state$boxes2 === undefined ? [] : _this$state$boxes2,
          milestones = _this$state.milestones,
          percentagePerBox = _this$state.percentagePerBox;
      var _data = _this.props.data;
      var _data$appUser = _data.appUser,
          appUser = _data$appUser === undefined ? {} : _data$appUser;

      var resumeBox = null;

      var program = (0, _cloneDeep2.default)(data);
      if (program.program) {
        if (boxes.length) {
          program.program.boxes = boxes;
        }
        if (milestones.length) {
          program.program.milestones = milestones;
        }
        if (_this.props.onNewData) {
          if (appUser && appUser.boxId && appUser.programId) {
            if (appUser.programId === program.program.id) {
              program.program.boxes && program.program.boxes.forEach(function (_box) {
                return _box.forEach(function (box) {
                  if (box.id === appUser.boxId) resumeBox = _extends({}, box, { time: appUser.elementId });
                });
              });
            }
          }
          _this.props.onNewData(program, percentagePerBox, resumeBox);
        }
      }
    }, _this.fetchBoxes = function (programId) {
      if (_this.props.onLoadChange) {
        _this.props.onLoadChange(true);
      }
      var client = _this.props.client;

      client.query({
        query: _graphql.GQL_FETCH_PROGRAM,
        variables: { id: programId }
      }).then(function (result) {
        if (!result.errors && result.data) {
          _this.getBoxResults(result.data, programId);
        } else {
          if (_this.props.onLoadChange) {
            _this.props.onLoadChange(false);
          }
        }
      }, function (error) {
        if (_this.props.onLoadChange) {
          _this.props.onLoadChange(false);
        }
      });
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(BoxService, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      this.handleProgram();
    }
  }, {
    key: "componentDidUpdate",
    value: function componentDidUpdate(prevProps) {
      if (prevProps.data.loading && !this.props.data.loading) {
        this.handleAppUser();
      }

      if (prevProps.program != this.props.program) {
        this.handleProgram();
      }
    }
  }, {
    key: "render",
    value: function render() {
      var children = this.props.children;


      return _react2.default.createElement(
        _react2.default.Fragment,
        null,
        children
      );
    }
  }]);

  return BoxService;
}(_react2.default.Component);

exports.default = (0, _recompose.compose)((0, _reactRedux.connect)(function (state) {
  return {
    program: (0, _selectors.getProgram)(state),
    appUser: (0, _selectors2.getAppUser)(state),
    group: (0, _selectors3.getGroup)(state),
    config: state.config
  };
}, function (dispatch) {
  return {
    selectProgram: function selectProgram(program) {
      return dispatch((0, _actions.selectProgram)(program));
    },
    selectGroup: function selectGroup(group) {
      return dispatch((0, _actions2.selectGroup)(group));
    }
  };
}), (0, _reactApollo.graphql)(_graphql.GQL_FETCH_APP_USER, {
  options: function options(props) {
    return {
      fetchPolicy: "no-cache",
      variables: {
        id: props.appUser && props.appUser.id
      }
    };
  }
}), (0, _reactApollo.graphql)(_graphql.GQL_FETCH_BOX_RESULTS, {
  name: "BOX_RESULTS",
  options: function options(props) {
    return {
      variables: {
        program: props.program,
        user: props.appUser && props.appUser.id
      }
    };
  }
}), _reactApollo.withApollo)(BoxService);