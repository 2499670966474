import React from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { LoadImage } from '@manakin/app-core';
import CircularProgress from '@material-ui/core/CircularProgress';

function SlidePreview(props) {
  const {
    classes,
    slideType,
    slideIndex,
    onClick,
    active,
    slideData,
    slideThumb,
    blur,
    slide
  } = props;
  let storedSlideData;

  slideData &&
    slideData.map(slide => {
      if (slideIndex === slide.idx) {
        storedSlideData = slide.data;
      }
    });

  if (slideType === 'image') {
    return (
      <div
        className={
          classes.slidePreviewWrapper +
          (active ? ' activeSlide' : '') +
          (blur ? ' blurredSlide' : '')
        }
      >
        {props.children}
        <Button
          fullWidth={true}
          classes={{
            root: classes.slidePreview,
            label: classes.slidePreviewLabel
          }}
          onClick={onClick}
        >
          {slideThumb ? (
            <LoadImage
              src={slideThumb}
              loader={<CircularProgress size={24} />}
            />
          ) : (
            <LoadImage
              src={slide && slide.image && slide.image.asset.url}
              alt="Slidepreview"
              loader={<CircularProgress size={24} />}
            />
          )}
        </Button>
      </div>
    );
  }
  if (slideType === 'text') {
    return (
      <div
        className={classes.slidePreviewWrapper + (active ? ' activeSlide' : '')}
      >
        {props.children}
        <Button
          fullWidth={true}
          classes={{
            root: classes.slidePreview,
            label: classes.slidePreviewLabel
          }}
          onClick={onClick}
        >
          <span className={classes.slidePreviewTextWrapper}>
            <Typography variant="h6" className={classes.slidePreviewTitle}>
              {storedSlideData &&
              storedSlideData.title &&
              storedSlideData.title.length > 0
                ? storedSlideData.title
                : 'Voeg titel toe'}
            </Typography>
            <Typography className={classes.slidePreviewText}>
              {storedSlideData &&
              storedSlideData.text &&
              storedSlideData.text.length > 0
                ? storedSlideData.text
                : 'Voeg tekst toe'}
            </Typography>
          </span>
        </Button>
      </div>
    );
  } else {
    return (
      <div className={classes.slidePreviewWrapper}>
        <div className={classes.slidePreview} />
      </div>
    );
  }
}

export default SlidePreview;
