let itemMargin = 13;

export const styles = theme => ({
  wrapper: {
    ...theme.manakin.defaultWrapper,
    //overflow: 'hidden',
    [theme.breakpoints.up("md")]: {
      padding: 0
    }
  },
  answersRoot: {
    display: "flex",
    flexWrap: "wrap",
    margin: "-" + itemMargin + "px"
  },
  card: {
    height: 0,
    margin: itemMargin + "px",
    position: "relative",
    zIndex: "1"
  },
  col2: {
    paddingBottom: `calc(50% - ${itemMargin * 2}px)`,
    flex: `0 1 calc(50% - ${itemMargin * 2}px)`
  },
  col3: {
    paddingBottom: `calc(33.33% - ${itemMargin * 2}px)`,
    flex: `0 1 calc(33.33% - ${itemMargin * 2}px)`
  },
  innerCard: {
    backgroundColor: theme.manakin.primaryColor,
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    cursor: "pointer",
    "&:hover": {
      "& img": {
        transform: "scale(1.2)"
      }
    }
  },
  image: {
    width: "100%",
    display: "flex",
    height: "100%",
    flexDirection: "column",
    position: "relative",
    transition: "transform .2s",
    overflow: "hidden",
    zIndex: "2",
    "& img": {
      transition: "transform .2s!important"
    }
  },
  backgroundImage: {
    objectFit: "cover",
    fontFamily: "'object-fit: cover'",
    width: "100%",
    height: "100%",
    backfaceVisibility: "hidden"
  },
  checked: {
    position: "absolute",
    width: "calc(100% + 14px)",
    height: "calc(100% + 14px)",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%) scale(.85)",
    alignItems: "center",
    justifyContent: "center",
    opacity: 0,
    display: "flex",
    pointerEvents: "none",
    border: "2px solid black",
    transition: "transform .2s ease, opacity .2s ease"
  },
  iconRoot: {
    width: "1.3rem",
    height: "1.3rem"
  },
  showIcon: {
    opacity: 1,
    transform: "translate(-50%, -50%) scale(1)"
  },
  feedback: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    zIndex: 1,
    transform: "translateY(20px)",
    opacity: 0,
    transition: "transform .3s, opacity .3s",
    pointerEvents: "none",
    [theme.breakpoints.up("md")]: {
      position: "relative",
      transition: "transform .3s, opacity .3s"
    }
  },
  activeFeedback: {
    position: "relative",
    transform: "translateY(0)",
    height: "auto",
    opacity: 1,
    pointerEvents: "auto",
    [theme.breakpoints.up("md")]: {
      height: "auto"
    }
  },
  buttons: {
    position: "relative",
    marginTop: "2rem"
  },
  button: {
    "& span": {
      justifyContent: "space-between",
      paddingLeft: "2rem",
      paddingRight: "2rem"
    }
  },
  longArrow: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "block"
    }
  },
  hide: {
    display: "none"
  }
});
