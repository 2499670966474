'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GQL_FETCH_GLOBAL_SETTINGS = exports.GQL_FETCH_FILE = exports.GQL_FETCH_ENTRY_TEST = exports.GQL_FETCH_BOOK_DOWNLOAD = exports.GQL_FETCH_SLIDESHOW = exports.GQL_FETCH_TRAINER = exports.GQL_FETCH_FILM = exports.GQL_FETCH_MASTERCLASS = exports.GQL_FETCH_BETTING_GAME = exports.GQL_FETCH_CASE = exports.GQL_FETCH_LESSON = exports.GQL_FETCH_BOOKCHECK = exports.GQL_FETCH_BRIDGE = undefined;

var _templateObject = _taggedTemplateLiteral(['\n  query($id: ID!) {\n    bridge(id: $id) {\n      id\n      text\n      vimeoId\n      backgroundColor\n    }\n  }\n'], ['\n  query($id: ID!) {\n    bridge(id: $id) {\n      id\n      text\n      vimeoId\n      backgroundColor\n    }\n  }\n']),
    _templateObject2 = _taggedTemplateLiteral(['\n  query($id: ID!) {\n    bookCheck(id: $id) {\n      id\n      cardTitle\n      cardText\n    }\n  }\n'], ['\n  query($id: ID!) {\n    bookCheck(id: $id) {\n      id\n      cardTitle\n      cardText\n    }\n  }\n']),
    _templateObject3 = _taggedTemplateLiteral(['\n  query($id: ID!) {\n    lesson(id: $id) {\n      id\n      preTitle\n      titleColor\n      prefixColor\n    }\n  }\n'], ['\n  query($id: ID!) {\n    lesson(id: $id) {\n      id\n      preTitle\n      titleColor\n      prefixColor\n    }\n  }\n']),
    _templateObject4 = _taggedTemplateLiteral(['\n  query($id: ID!) {\n    case(id: $id) {\n      id\n      preTitle\n      titleColor\n      prefixColor\n    }\n  }\n'], ['\n  query($id: ID!) {\n    case(id: $id) {\n      id\n      preTitle\n      titleColor\n      prefixColor\n    }\n  }\n']),
    _templateObject5 = _taggedTemplateLiteral(['\n  query($id: ID!) {\n    bettingGame(id: $id) {\n      id\n      preTitle\n      titleColor\n      prefixColor\n    }\n  }\n'], ['\n  query($id: ID!) {\n    bettingGame(id: $id) {\n      id\n      preTitle\n      titleColor\n      prefixColor\n    }\n  }\n']),
    _templateObject6 = _taggedTemplateLiteral(['\n  query($id: ID!) {\n    masterClass(id: $id) {\n      id\n      preTitle\n      titleColor\n      prefixColor\n    }\n  }\n'], ['\n  query($id: ID!) {\n    masterClass(id: $id) {\n      id\n      preTitle\n      titleColor\n      prefixColor\n    }\n  }\n']),
    _templateObject7 = _taggedTemplateLiteral(['\n  query($id: ID!) {\n    film(id: $id) {\n      id\n      preTitle\n      titleColor\n      prefixColor\n    }\n  }\n'], ['\n  query($id: ID!) {\n    film(id: $id) {\n      id\n      preTitle\n      titleColor\n      prefixColor\n    }\n  }\n']),
    _templateObject8 = _taggedTemplateLiteral(['\n  query($id: ID!) {\n    trainer(id: $id) {\n      id\n      preTitle\n      titleColor\n      prefixColor\n    }\n  }\n'], ['\n  query($id: ID!) {\n    trainer(id: $id) {\n      id\n      preTitle\n      titleColor\n      prefixColor\n    }\n  }\n']),
    _templateObject9 = _taggedTemplateLiteral(['\n  query($id: ID!) {\n    slideshowCreator(id: $id) {\n      id\n      preTitle\n      titleColor\n      prefixColor\n      text\n      introTitle\n      slideshows {\n        id\n        isPublic\n        title\n        author {\n          id\n          fullName\n        }\n      }\n    }\n  }\n'], ['\n  query($id: ID!) {\n    slideshowCreator(id: $id) {\n      id\n      preTitle\n      titleColor\n      prefixColor\n      text\n      introTitle\n      slideshows {\n        id\n        isPublic\n        title\n        author {\n          id\n          fullName\n        }\n      }\n    }\n  }\n']),
    _templateObject10 = _taggedTemplateLiteral(['\n  query($id: ID!) {\n    bookDownload(id: $id) {\n      id\n      introTitle\n      text\n      workforms {\n        id\n        type\n        introduction\n      }\n    }\n  }\n'], ['\n  query($id: ID!) {\n    bookDownload(id: $id) {\n      id\n      introTitle\n      text\n      workforms {\n        id\n        type\n        introduction\n      }\n    }\n  }\n']),
    _templateObject11 = _taggedTemplateLiteral(['\n  query($id: ID!) {\n    entryTest(id: $id) {\n      id\n      preTitle\n    }\n  }\n'], ['\n  query($id: ID!) {\n    entryTest(id: $id) {\n      id\n      preTitle\n    }\n  }\n']),
    _templateObject12 = _taggedTemplateLiteral(['\n  query($id: ID!) {\n    fileDownload(id: $id) {\n      id\n      file {\n        id\n        url\n      }\n      openAction\n    }\n  }\n'], ['\n  query($id: ID!) {\n    fileDownload(id: $id) {\n      id\n      file {\n        id\n        url\n      }\n      openAction\n    }\n  }\n']),
    _templateObject13 = _taggedTemplateLiteral(['\n  query {\n    settings {\n      settings {\n        name\n        value\n        file {\n          id\n          bynderId\n          derivative\n          url\n          thumbnailUrl\n          name\n        }\n      }\n    }\n  }\n'], ['\n  query {\n    settings {\n      settings {\n        name\n        value\n        file {\n          id\n          bynderId\n          derivative\n          url\n          thumbnailUrl\n          name\n        }\n      }\n    }\n  }\n']);

var _graphqlTag = require('graphql-tag');

var _graphqlTag2 = _interopRequireDefault(_graphqlTag);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteral(strings, raw) { return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var GQL_FETCH_BRIDGE = exports.GQL_FETCH_BRIDGE = (0, _graphqlTag2.default)(_templateObject);

var GQL_FETCH_BOOKCHECK = exports.GQL_FETCH_BOOKCHECK = (0, _graphqlTag2.default)(_templateObject2);

var GQL_FETCH_LESSON = exports.GQL_FETCH_LESSON = (0, _graphqlTag2.default)(_templateObject3);

var GQL_FETCH_CASE = exports.GQL_FETCH_CASE = (0, _graphqlTag2.default)(_templateObject4);

var GQL_FETCH_BETTING_GAME = exports.GQL_FETCH_BETTING_GAME = (0, _graphqlTag2.default)(_templateObject5);

var GQL_FETCH_MASTERCLASS = exports.GQL_FETCH_MASTERCLASS = (0, _graphqlTag2.default)(_templateObject6);

var GQL_FETCH_FILM = exports.GQL_FETCH_FILM = (0, _graphqlTag2.default)(_templateObject7);

var GQL_FETCH_TRAINER = exports.GQL_FETCH_TRAINER = (0, _graphqlTag2.default)(_templateObject8);

var GQL_FETCH_SLIDESHOW = exports.GQL_FETCH_SLIDESHOW = (0, _graphqlTag2.default)(_templateObject9);

var GQL_FETCH_BOOK_DOWNLOAD = exports.GQL_FETCH_BOOK_DOWNLOAD = (0, _graphqlTag2.default)(_templateObject10);

var GQL_FETCH_ENTRY_TEST = exports.GQL_FETCH_ENTRY_TEST = (0, _graphqlTag2.default)(_templateObject11);

var GQL_FETCH_FILE = exports.GQL_FETCH_FILE = (0, _graphqlTag2.default)(_templateObject12);

var GQL_FETCH_GLOBAL_SETTINGS = exports.GQL_FETCH_GLOBAL_SETTINGS = (0, _graphqlTag2.default)(_templateObject13);