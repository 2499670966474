import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {compose} from 'recompose';
import {
    SelectImageField
} from '@manakin/core';
import ImageMapper from 'react-image-mapper';
import Button from '@material-ui/core/Button';
import Hotspot from './Hotspot'

const styles = theme => ({
	uploadBox: {
		position: 'relative',
	},
	hotspot: {
		position: 'absolute',
		width: '10px',
		height: '10px',
		backgroundColor: 'red',
		zIndex: '2'
	},
	image: {
		width: '100%',
		'& img': {
			width: '100%'
		}
	}
})

class _ImageMapper extends React.PureComponent {
	render() {
		const {
			classes, 
			onClick, 
			image = null,
			onImageClick,
			hotspots = {},
			onChange,
			onDelete,
			onHotspotClick,
			onClose
		} = this.props;

		return (
			<div className={classes.root}>
                <Button
                    color="primary"
                    onClick={onClick}
                >
                    Selecteer een afbeelding
                </Button>

				<div className={classes.uploadBox}>	
					{image && (
						<React.Fragment>
							{ Object.keys(hotspots).map(key => (
								<Hotspot hotspot={hotspots[key]} id={key} key={key} onHotspotClick={onHotspotClick} onClose={onClose} onChange={onChange} onDelete={onDelete} />
							))}
							<div className={classes.image}>
								<img src={image.url} onClick={onImageClick} />
							</div>
						</React.Fragment>
					)}
				</div>
			</div>
		)
	}
}

export default compose(
	withStyles(styles)
)(_ImageMapper)