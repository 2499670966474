import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import classNames from 'classnames';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import FileIcon from '@manakin/core/icons/File';

const styles = theme => ({
  root: {
    position: 'relative',
    display: 'inline-block',
    width: '100%'
  },
  input: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    opacity: 0
  },
  uploadBox: {
    width: '100%',
    height: 100,
    padding: theme.spacing(1),
    marginTop: theme.spacing(4),
    border: '2px dashed #ccc',
    marginBottom: 10,
    borderRadius: 10,
    cursor: 'pointer',
    boxShadow: 'none',
    display: 'flex',
    justifyContent: 'center'
  },
  noFileText: {
    color: theme.manakin.defaultTextColor['500'],
    fontSize: '18px',
    alignSelf: 'center',
    overflow: 'hidden',
    textAlign: 'center'
  },
  icon: {
    width: '80px',
    height: '50px',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    color: theme.manakin.defaultTextColor['500'],
    marginRight: '1rem'
  },
  content: {
    padding: '1rem',
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  text: {
    width: '100%',
    '& p': {
      margin: 0,
      maxWidth: '43rem'
    }
  },
  title: {
    fontSize: '1rem',
    lineHeight: '1.2rem',
    marginBottom: '.8rem'
  },
  disabledLabel: {
    color: theme.manakin.disabledFieldLabelColor
  },
  disabledInput: {
    color: theme.manakin.disabledFieldTextColor
  }
});

export class FileUpload extends React.PureComponent {
  handleChange = file => {
    const { form, name } = this.props;
    form.onFieldChange({ key: name, value: file });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.loading && !this.props.loading) {
      const { form, name, initialValue } = this.props;
      form.onFieldChange({ key: name, value: initialValue });
    }

    if (prevProps.enabled !== this.props.enabled) {
      this.refs.fileUpload.click();
    }
  }

  componentDidMount() {
    const { form, name, initialValue } = this.props;
    form.onFieldChange({ key: name, value: initialValue });
  }

  render() {
    const {
      label = '',
      fullWidth = true,
      name,
      form,
      classes,
      readOnly
    } = this.props;
    const { errors, schema } = form;
    const { fields } = form;
    const isRequired = (schema[name] || {}).required;

    let inputLabelProps = {
      required: (schema[name] || {}).required,
      className: classes.label,
      shrink: true
    };

    return (
      <div className={classes.root}>
        <FormControl
          fullWidth={fullWidth}
          error={errors[name] && errors[name].length > 0}
        >
          <InputLabel
            {...inputLabelProps}
            classes={{
              root: classNames(classes.label, {
                [classes.disabledLabel]: readOnly
              })
            }}
          >
            {label}
            {isRequired && ' *'}
          </InputLabel>
          <div className={classes.uploadBox}>
            {fields[name] ? (
              <div className={classes.content}>
                <FileIcon className={classes.icon} />

                <div className={classes.text}>
                  <Typography
                    component="p"
                    variant="h6"
                    classes={{ h6: classes.title }}
                  >
                    {fields[name] && fields[name].name}
                  </Typography>
                </div>
                <Typography component="p" variant="body1">
                  Wijzigen
                </Typography>
              </div>
            ) : (
              <div className={classes.noFileText}>Bestand uploaden</div>
            )}
            <input
              type="file"
              className={classes.input}
              ref="fileUpload"
              required
              onChange={({
                target: {
                  validity,
                  files: [file]
                }
              }) => validity.valid && this.handleChange(file)}
            />
          </div>
          {errors[name] &&
            errors[name].length > 0 &&
            errors[name].map((e, i) => (
              <FormHelperText key={i}>{e}</FormHelperText>
            ))}
        </FormControl>
      </div>
    );
  }
}

export default withStyles(styles)(FileUpload);
