'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styles = require('@material-ui/styles');

var _styles2 = require('@material-ui/core/styles');

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _recompose = require('recompose');

var _reactRedux = require('react-redux');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var styles = function styles(theme) {
  return {
    root: {
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat'
    }
  };
};

var ThemeIcons = function ThemeIcons(props) {
  var _props$variant = props.variant,
      variant = _props$variant === undefined ? false : _props$variant,
      classes = props.classes,
      classNameProp = props.className,
      config = props.config;
  var _config$general = config.general,
      general = _config$general === undefined ? {} : _config$general;

  var _useState = (0, _react.useState)(false),
      _useState2 = _slicedToArray(_useState, 2),
      style = _useState2[0],
      setStyle = _useState2[1];

  var theme = (0, _styles.useTheme)();
  var className = (0, _classnames2.default)(classes.root, classNameProp);

  (0, _react.useEffect)(function () {
    if (variant && !general.hideTheme) {
      var _variant = variant.toLowerCase();
      switch (_variant) {
        case 'pijn en koorts':
          _variant = 'pijnkoorts';
          break;
        case 'overige indicaties':
          _variant = 'overige';
          break;
        case 'maag en darm':
          _variant = 'maagdarm';
          break;
      }

      if (theme.manakin.themeIcons && theme.manakin.themeIcons[_variant]) {}
      setStyle(theme.manakin.themeIcons[_variant]);
    }
  }, [theme]);

  if (style && !general.hideTheme) {
    return _react2.default.createElement('div', { className: className, style: style });
  } else {
    return '';
  }
};

exports.default = (0, _recompose.compose)((0, _reactRedux.connect)(function (state) {
  return { config: state.config };
}), (0, _styles2.withStyles)(styles))(ThemeIcons);