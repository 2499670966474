import React from "react";
import Lesson from "./Lesson";
import { Loader } from "@manakin/app-core";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  loader: {
    width: "100vw",
    height: "100vh",
    position: "fixed",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
});

class LessonView extends React.PureComponent {
  render() {
    const { props } = this;
    const { classes, onReset, preview = false, theposition } = props;

    if (props.loading) {
      return (
        <div className={classes.loader}>
          <Loader />
        </div>
      );
    } else if (props.workforms) {
      return (
        <React.Fragment>
          <Lesson
            prefix={props.title}
            lessonData={{ ...props.lessonData }}
            workforms={[...props.workforms]}
            options={{ ...props.options }}
            onFinish={props.onFinish}
            box={props.box}
            onReset={onReset}
            preview={preview}
            theposition={theposition}
          />
        </React.Fragment>
      );
    } else {
      return "Error";
    }
  }
}

export default withStyles(styles)(LessonView);
