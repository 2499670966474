import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    root: {}
});

const NotFound = ({ classes }) => {
    return <div className={classes.root}>Niet gevonden (styling TODO)</div>;
};

export default withStyles(styles)(NotFound);
