"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var styles = exports.styles = function styles(theme) {
	return {
		icon: {
			marginBottom: "10px",
			color: "#000"
		},
		input: {
			transition: "opacity 200ms linear"
		},
		wrapper: {
			maxWidth: theme.manakin.wrapper['small'],
			position: "relative",
			width: "100%"
		},
		smallWrapper: _extends({}, theme.manakin.defaultWrapper, {
			maxWidth: theme.manakin.wrapper['extraSmall'],
			position: "relative",
			width: "100%"
		}),
		addAnotherLicenseButton: {
			marginTop: theme.spacing(4),
			marginBottom: theme.spacing(4)
		},
		nextButton: {
			marginTop: '2rem'
		},
		more: {
			margin: 0
		},
		linkButton: {
			textDecoration: 'none',
			display: 'block',
			textAlign: 'center',
			marginTop: theme.spacing(2),
			fontSize: '18px',
			color: 'black',
			margin: 'auto',
			'&:hover': {
				textDecoration: 'underline',
				backgroundColor: 'transparent'
			},
			'& span': {
				fontWeight: 'normal'
			}
		}

	};
};