import React from 'react';
import Header from './parts/Header';
import ElementsLayout from './parts/ElementsLayout';
import { withStyles } from '@material-ui/core/styles';
import { compose, withHandlers } from 'recompose';
import { graphql } from 'react-apollo';
import {
  GQL_FETCH_CMS_BOX,
  GQL_FETCH_ELEMENT_RESULTS,
  GQL_UPDATE_USER
} from './graphql/graphql';
import { connect } from 'react-redux';
import { getProgram } from '@manakin/app-core/ProgramsDropdown/selectors';
import { getAppUser } from '@manakin/authentication/selectors';
import { setAppBarBackgroundColor, setBoxId } from '@manakin/app-core/actions';
import { Loader, Dialog } from '@manakin/app-core';
import Cookies from 'js-cookie';
import moment from 'moment';
import { selectProgram } from "@manakin/app-core/actions";

const mapStateToProps = state => ({
  config: state.config,
  appUser: getAppUser(state),
  program: getProgram(state)
});

const styles = theme => ({
  root: {
    position: 'relative'
  },
  loader: {
    backgroundColor: 'white',
    position: 'absolute',
    width: '100vw',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  footerLogo: {}
});

const mapDispatchToProps = dispatch => ({
  onSetAppBarColor: color => dispatch(setAppBarBackgroundColor(color)),
  setBoxId: id => dispatch(setBoxId(id)),
  selectProgram: program => dispatch(selectProgram(program))
});

class Box extends React.PureComponent {
  state = {
    openDialog: false
  };

  componentDidMount() {
    const { onSetAppBarColor, setBoxId, match, appUser, program } = this.props;

    if (match.params.boxid && program && appUser && appUser.id) {
      const time = moment().unix();
      this.props.onUpdate({
        id: appUser.id,
        programId: program,
        boxId: match.params.boxid,
        elementId: time
      });
    }

    onSetAppBarColor({
      backgroundColor: 'transparent',
      color: 'secondary',
      path: this.props.location.pathname
    });
    setBoxId(this.props.match.params.boxid);

    if (this.props.match.params.programId) {
      const { licenses = [] } = appUser
      let hasLicense = false
      licenses.forEach(license => {
        if (license.licenseGroup && license.licenseGroup.product && license.licenseGroup.product.program && license.licenseGroup.product.program.id) {
          if (license.licenseGroup.product.program.id === this.props.match.params.programId) {
            hasLicense = true;
          }
        }
      })
      if (hasLicense) this.props.selectProgram(this.props.match.params.programId)
      else this.props.history.push('/')
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.CMSBOX.loading && !this.props.CMSBOX.loading) {
      window.scrollTo(0, 0);
      const { CMSBOX, config = {}, program, appUser } = this.props;
      const { box = {} } = CMSBOX;
      const check = Cookies.get(`box${program}${appUser.id}popup`);

      let arr = [];
      if (
        config.pages &&
        config.pages.box &&
        config.pages.box.showCompletePopup &&
        !check
      ) {
        config.pages.box.showCompletePopup.forEach((item, idx) => {
          arr = arr.concat(box.elements.filter(i => i.type == item));
          if (idx == config.pages.box.showCompletePopup.length - 1)
            this.handleArr(arr);
        });
      }
    }
  }

  handleArr = arr => {
    const _arr = arr.filter(i => !i.correct);

    if (!_arr.length) {
      Cookies.set(`box${this.props.program}${this.props.appUser.id}popup`, '1');
      this.setState({ openDialog: true });
    }
  };

  handleClose = () => {
    this.setState({
      openDialog: false
    });
  };

  render() {
    const {
      CMSBOX,
      ELEMENTRESULTS,
      match,
      classes,
      boxStyle = 'default',
      config = {}
    } = this.props;
    const { box } = CMSBOX;
    const { elementResults } = ELEMENTRESULTS;
    const loading = CMSBOX.loading || ELEMENTRESULTS.loading;
    const elementsData = box ? box.elements : [];
    const elementResultsData = elementResults || [];
    const boxData = box ? box : {};
    const { literals = {} } = config;

    return (
      <div>
        {!loading ? (
          <div className={classes.root}>
            <Header
              title={boxData.name || ''}
              description={boxData.description || ''}
            />
            <ElementsLayout
              boxStyle={boxStyle}
              items={elementsData}
              elementResults={elementResultsData}
              boxId={match.params.boxid}
            />
            <Dialog
              title={literals.APP_BOX_POPUP_TITLE || ''}
              content={literals.APP_BOX_POPUP_CONTENT || ''}
              onClose={this.handleClose}
              open={this.state.openDialog}
            />
            {config.pages &&
              config.pages.appLogin &&
              config.pages.appLogin.footerLogo && (
                <div className={classes.footerLogo} />
              )}
          </div>
        ) : (
            <div className={classes.loader}>
              {' '}
              <Loader />{' '}
            </div>
          )}
      </div>
    );
  }
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  graphql(GQL_FETCH_CMS_BOX, {
    name: 'CMSBOX',
    options: props => ({ variables: { id: props.match.params.boxid } })
  }),
  graphql(GQL_FETCH_ELEMENT_RESULTS, {
    name: 'ELEMENTRESULTS',
    options: props => ({
      variables: {
        box: props.boxId || props.match.params.boxid,
        program: props.program,
        user: props.appUser.id
      }
    })
  }),
  graphql(GQL_UPDATE_USER),
  withHandlers({
    onUpdate: ({ mutate }) => event =>
      mutate({
        variables: {
          ...event
        }
      })
  }),
  withStyles(styles, { name: 'AppBox' })
)(Box);
