"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _styles = require("@material-ui/core/styles");

var _appCore = require("@manakin/app-core");

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

var _Typography = require("@material-ui/core/Typography");

var _Typography2 = _interopRequireDefault(_Typography);

var _styles2 = require("./styles");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var WorkformLayout = function (_React$PureComponent) {
  _inherits(WorkformLayout, _React$PureComponent);

  function WorkformLayout() {
    _classCallCheck(this, WorkformLayout);

    return _possibleConstructorReturn(this, (WorkformLayout.__proto__ || Object.getPrototypeOf(WorkformLayout)).apply(this, arguments));
  }

  _createClass(WorkformLayout, [{
    key: "render",
    value: function render() {
      var _classNames3;

      var _props = this.props,
          classes = _props.classes,
          loading = _props.loading,
          _props$question = _props.question,
          question = _props$question === undefined ? "" : _props$question,
          _props$instruction = _props.instruction,
          instruction = _props$instruction === undefined ? "" : _props$instruction,
          renderQuestion = _props.renderQuestion,
          renderAnswers = _props.renderAnswers,
          _props$quarterQuestio = _props.quarterQuestion,
          quarterQuestion = _props$quarterQuestio === undefined ? false : _props$quarterQuestio,
          _props$bigAnswer = _props.bigAnswer,
          bigAnswer = _props$bigAnswer === undefined ? false : _props$bigAnswer,
          _props$mirror = _props.mirror,
          mirror = _props$mirror === undefined ? false : _props$mirror,
          _props$backgroundColo = _props.backgroundColor,
          backgroundColor = _props$backgroundColo === undefined ? false : _props$backgroundColo,
          _props$fullWidth = _props.fullWidth,
          fullWidth = _props$fullWidth === undefined ? false : _props$fullWidth,
          _props$direction = _props.direction,
          direction = _props$direction === undefined ? "default" : _props$direction;


      return _react2.default.createElement(
        "div",
        {
          className: (0, _classnames2.default)(classes.root, _defineProperty({}, classes.primaryColors, backgroundColor == "PRIMARY"), _defineProperty({}, classes.secondaryColors, backgroundColor == "SECONDARY"))
        },
        !loading ? _react2.default.createElement(
          "div",
          {
            className: (0, _classnames2.default)(classes.container, (_classNames3 = {}, _defineProperty(_classNames3, classes.mirror, mirror), _defineProperty(_classNames3, classes.fullWidth, fullWidth), _defineProperty(_classNames3, classes.columnDirection, direction === "column"), _classNames3))
          },
          _react2.default.createElement(
            "div",
            {
              className: (0, _classnames2.default)(classes.question, _defineProperty({}, classes.quarterQuestion, quarterQuestion), _defineProperty({}, classes.biggerAnswer, bigAnswer))
            },
            _react2.default.createElement(
              "div",
              { className: classes.wrapper },
              _react2.default.createElement(
                _Typography2.default,
                {
                  component: "p",
                  variant: "subtitle1",
                  className: (0, _classnames2.default)("smallUnderline", classes.subHeader, classes.contentColor)
                },
                instruction
              ),
              _react2.default.createElement(
                _Typography2.default,
                {
                  component: "h2",
                  variant: "h3",
                  className: (0, _classnames2.default)(classes.header, classes.contentColor)
                },
                question
              )
            ),
            renderQuestion()
          ),
          _react2.default.createElement(
            "div",
            {
              className: (0, _classnames2.default)(classes.answers, _defineProperty({}, classes.fullAnswers, quarterQuestion), _defineProperty({}, classes.biggerAnswer, bigAnswer))
            },
            renderAnswers()
          )
        ) : _react2.default.createElement(
          "div",
          { className: classes.loader },
          " ",
          _react2.default.createElement(_appCore.Loader, null),
          " "
        )
      );
    }
  }]);

  return WorkformLayout;
}(_react2.default.PureComponent);

WorkformLayout.defaultProps = {
  renderQuestion: function renderQuestion() {},
  renderAnswers: function renderAnswers() {}
};
exports.default = (0, _styles.withStyles)(_styles2.styles, { name: "AppWorkformLayout" })(WorkformLayout);