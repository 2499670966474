export const PersonalDataForm = {
  gender: {
    required: true
  },
  firstName: {
    required: true,
    minLength: 1
  },
  middleName: {
    required: false
  },
  lastName: {
    required: true,
    minLength: 1
  },
  dateOfBirth: {
    format: "date"
  },
  phonenumber: {},
  registerCode: {},
  registerType: {}
};
