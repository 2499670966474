import { CLEAR_FORM, RESET_FORM, INIT_FORM, DESTROY_FORM } from './actions';
import uuid from 'uuid/v4';

const initialState = {};

export const reducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case RESET_FORM: {
            return {
                ...state,
                [action.payload.id]: {
                    ...state[action.payload.id],
                    key: uuid()
                }
            };
        }
        case CLEAR_FORM: {
            return {
                ...state,
                [action.payload.id]: {
                    ...state[action.payload.id],
                    key: uuid()
                }
            };
        }
        case INIT_FORM: {
            return {
                ...state,
                [action.payload.id]: {
                    key: uuid()
                }
            };
        }
        case DESTROY_FORM: {
            const newState = {...state};
            delete newState[action.payload.id];
            return newState;
        }
        default:
            return state;
    }
};

export default reducer;
