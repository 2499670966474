'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styles = require('@material-ui/core/styles');

var _reactRouterDom = require('react-router-dom');

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _styles2 = require('./styles');

var _ = require('../');

var _Done = require('@material-ui/icons/Done');

var _Done2 = _interopRequireDefault(_Done);

var _recompose = require('recompose');

var _Typography = require('@material-ui/core/Typography');

var _Typography2 = _interopRequireDefault(_Typography);

var _reactRedux = require('react-redux');

var _appCore = require('@manakin/app-core');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var BoxCard = function (_React$PureComponent) {
  _inherits(BoxCard, _React$PureComponent);

  function BoxCard() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, BoxCard);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = BoxCard.__proto__ || Object.getPrototypeOf(BoxCard)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
      mounted: false
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(BoxCard, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      var _this2 = this;

      setTimeout(function () {
        _this2.setState({ mounted: true });
      }, 300);
    }
  }, {
    key: 'render',
    value: function render() {
      var _classNames, _classNames3;

      var _props = this.props,
          content = _props.content,
          listView = _props.listView,
          classes = _props.classes,
          idx = _props.idx,
          config = _props.config,
          _props$percentage = _props.percentage,
          percentage = _props$percentage === undefined ? 0 : _props$percentage;
      var _config$pages = config.pages,
          pages = _config$pages === undefined ? {} : _config$pages;
      var mounted = this.state.mounted;


      var imageUrl = content.image ? content.image.url : '';
      var placeholder = content.image ? content.image.thumbnailUrl : '';

      var delay = {
        transitionDelay: '.' + (idx + 1) * 1 + 's'
      };

      var boxCardConfig = config.pages && config.pages.boxCard || {};
      var showDescription = boxCardConfig.showDescription != undefined ? boxCardConfig.showDescription : true;
      var showArrow = boxCardConfig.showArrow != undefined ? boxCardConfig.showArrow : false;

      var showThemeIconsInList = pages.boxCard && pages.boxCard.showThemeIconsInList;

      var mandatory = content.mandatory === false ? false : true;

      return _react2.default.createElement(
        _reactRouterDom.Link,
        { className: classes.link, to: '/box/' + content.id },
        _react2.default.createElement(
          'div',
          {
            className: (0, _classnames2.default)(classes.boxRoot, (_classNames = {}, _defineProperty(_classNames, classes.listView, listView), _defineProperty(_classNames, classes.labelAndlist, listView && content.label ? true : false), _defineProperty(_classNames, classes.show, mounted), _classNames)),
            style: delay
          },
          content.label && _react2.default.createElement(
            _Typography2.default,
            { className: classes.boxCardLabel },
            content.label
          ),
          content.done && mandatory && _react2.default.createElement(
            'div',
            { className: classes.done },
            _react2.default.createElement(
              'span',
              { className: classes.doneIcon },
              _react2.default.createElement(_Done2.default, { classes: { root: classes.iconRoot } })
            )
          ),
          _react2.default.createElement(
            'div',
            { className: classes.backgroundImage },
            listView && showThemeIconsInList && mandatory && _react2.default.createElement(
              _react2.default.Fragment,
              null,
              content.themes && content.themes.map(function (theme) {
                return _react2.default.createElement(_appCore.ThemeIcon, {
                  key: theme.id,
                  variant: theme.title,
                  className: classes.themeIcon
                });
              })
            ),
            !showThemeIconsInList && _react2.default.createElement(_.LoadImage, {
              align: 'right',
              src: imageUrl,
              placeholder: placeholder
            })
          ),
          content.preTitle && _react2.default.createElement(
            'span',
            {
              className: (0, _classnames2.default)(classes.boxSubtitle, _defineProperty({}, classes.primaryColor, content.prefixColor && content.prefixColor === 'PRIMARY'))
            },
            content.preTitle
          ),
          _react2.default.createElement(
            'span',
            {
              className: (0, _classnames2.default)(classes.boxName, (_classNames3 = {}, _defineProperty(_classNames3, classes.primaryColor, content.titleColor && content.titleColor === 'PRIMARY'), _defineProperty(_classNames3, classes.hasLabel, content.label ? true : false), _classNames3))
            },
            content.name
          ),
          percentage > 0 && mandatory && _react2.default.createElement(
            'div',
            {
              className: (0, _classnames2.default)(classes.percentage, _defineProperty({}, classes.percentageDone, percentage >= 100))
            },
            percentage >= 100 ? _react2.default.createElement(_Done2.default, { className: classes.percentageDoneIcon }) : _react2.default.createElement(
              'span',
              null,
              percentage,
              '%'
            )
          ),
          content.description && showDescription && _react2.default.createElement(
            'span',
            {
              className: (0, _classnames2.default)(classes.boxDescription, _defineProperty({}, classes.primaryColor, content.titleColor === 'PRIMARY'))
            },
            content.description
          ),
          showArrow && _react2.default.createElement(_appCore.LongArrow, { className: (0, _classnames2.default)(classes.longArrow, _defineProperty({}, classes.primaryColor, content.titleColor === 'PRIMARY')) })
        )
      );
    }
  }]);

  return BoxCard;
}(_react2.default.PureComponent);

exports.default = (0, _recompose.compose)((0, _styles.withStyles)(_styles2.styles, { name: 'AppBoxCard' }), (0, _reactRedux.connect)(function (_ref2) {
  var config = _ref2.config;
  return {
    config: config
  };
}))(BoxCard);