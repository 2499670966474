import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ClaimLicense from "@manakin/licenses/ClaimLicense";
import { hideAppBar } from "@manakin/app-core/actions";
import { compose } from "recompose";
import { connect } from "react-redux";
import renderHTML from "react-render-html";
import { GQL_VALID_LICENSE_CHECK } from "./graphql";
import { Redirect } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";

const styles = theme => ({
  root: {
    paddingTop: "6rem",
    paddingBottom: "6rem",
    width: "100%",
    height: "100%",
    minHeight: "100%"
  },
  paragraph: {
    textAlign: "center",
    width: "100%",
    margin: "0 auto 3em",
    maxWidth: "560px"
  },
  wrapper: {
    ...theme.manakin.defaultWrapper,
    maxWidth: theme.manakin.wrapper["small"],
    position: "relative",
    width: "100%",
    margin: "0 auto",
    paddingTop: "10rem"
  },
  smallWrapper: {
    maxWidth: theme.manakin.wrapper["extraSmall"],
    position: "relative",
    zIndex: 1,
    width: "90%",
    margin: "0 auto"
  },
  footNote: {
    marginTop: theme.spacing(4),
    textAlign: "center",
    display: "flex",
    flexDirection: "column"
  },
  link: {
    marginTop: theme.spacing(1),
    textDecoration: "none",
    color: "#009AC3",
    cursor: "pointer"
  },
  heading: {
    textAlign: "center"
  }
});

const NoLicenses = props => {
  const { classes, config, onHideAppBar } = props;
  const { literals = {} } = config;
  const [hasValidLicense, setHasValidLicense] = useState(false)

  //queries
  const { loading, error, data } = useQuery(
    GQL_VALID_LICENSE_CHECK
  );

  useEffect(() => {
    onHideAppBar({ path: props.location.pathname });
  }, []);

  useEffect(() => {
    if (!loading && data.validLicenseCheck) {
      if (data.validLicenseCheck.hasValidLicense) {
        setHasValidLicense(true)
      }
    }
  }, [data])


  const handleGoToLink = link => {
    const withHttp = url =>
      !/^https?:\/\//i.test(url) ? `https://${url}` : url;
    window.open(withHttp(link), "_blank");
  };

  if (loading) {
    return 'loading'
  }
  if (error) {
    return 'ERROR'
  }
  if (hasValidLicense) {
    return <Redirect to={"/"} />
  }
  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <Typography className={classes.heading} variant="h2">
          {literals.APP_LICENSE_NO_LICENSE_HEADING ||
            "Nog geen lesprogramma's beschikbaar"}
        </Typography>
        <Typography
          classes={{ body1: classes.paragraph }}
          variant="body1"
          gutterBottom
        >
          {literals.APP_LICENSE_NO_LICENSE_INTRO ||
            "Om er voor te zorgen dat je de juiste informatie van ons krijgt dien je je licentiecode hieronder in te voeren. Deze heb je ontvangen bij het aanschaffen van je lesprogramma(s). Op deze manier kun je direct aan de slag."}
        </Typography>
        <ClaimLicense redirect="/dashboard" />
        {config &&
          config.pages &&
          config.pages.appNoLicense &&
          config.pages.appNoLicense.showFootNote && (
            <Typography
              component="div"
              classes={{ body1: classes.footNote }}
              variant="body1"
            >
              {renderHTML(literals.APP_LICENSE_NO_LICENSE_FOOT_NOTE_TEXT)}
              {literals.APP_LICENSE_NO_LICENSE_FOOT_NOTE_LINK && (
                <div
                  className={classes.link}
                  onClick={() =>
                    handleGoToLink(literals.APP_LOGIN_FOOT_NOTE_LINK)
                  }
                >
                  {literals.APP_LICENSE_NO_LICENSE_FOOT_NOTE_LINK}
                </div>
              )}
            </Typography>
          )}
      </div>
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  onHideAppBar: data => dispatch(hideAppBar(data))
});

export default compose(
  withStyles(styles, { name: "AppRegistrationScreen" }),
  connect(
    ({ config }) => ({
      config
    }),
    mapDispatchToProps
  )
)(NoLicenses);
