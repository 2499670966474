'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _AccountWrapper = require('../AccountWrapper');

var _AccountWrapper2 = _interopRequireDefault(_AccountWrapper);

var _styles = require('@material-ui/core/styles');

var _recompose = require('recompose');

var _reactRedux = require('react-redux');

var _graphql = require('./graphql');

var _reactApollo = require('react-apollo');

var _selectors = require('@manakin/app-core/BoxService/selectors');

var _selectors2 = require('@manakin/app-core/ProgramsDropdown/selectors');

var _selectors3 = require('@manakin/authentication/selectors');

var _appCore = require('@manakin/app-core');

var _YearContent = require('./YearContent');

var _YearContent2 = _interopRequireDefault(_YearContent);

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var styles = function styles(theme) {
  return {};
};
var mapStateToProps = function mapStateToProps(state) {
  return {
    appUserData: (0, _selectors3.getAppUser)(state),
    newBoxResults: (0, _selectors.getBoxResults)(state),
    program: (0, _selectors2.getProgram)(state),
    config: state.config
  };
};

var Results = function Results(props) {
  var data = props.data,
      _props$BOX_RESULTS = props.BOX_RESULTS,
      BOX_RESULTS = _props$BOX_RESULTS === undefined ? {} : _props$BOX_RESULTS,
      classes = props.classes,
      newBoxResults = props.newBoxResults,
      _props$config = props.config,
      config = _props$config === undefined ? {} : _props$config;
  var loading = data.loading,
      _data$appUser = data.appUser,
      appUser = _data$appUser === undefined ? {} : _data$appUser;

  //state hooks

  var _useState = (0, _react.useState)([]),
      _useState2 = _slicedToArray(_useState, 2),
      yearsArray = _useState2[0],
      setYearsArray = _useState2[1];

  var _useState3 = (0, _react.useState)([]),
      _useState4 = _slicedToArray(_useState3, 2),
      progressArray = _useState4[0],
      setProgressArray = _useState4[1];

  var _useState5 = (0, _react.useState)([]),
      _useState6 = _slicedToArray(_useState5, 2),
      drogistProgressArray = _useState6[0],
      setdrogistProgressArray = _useState6[1];

  var _useState7 = (0, _react.useState)({}),
      _useState8 = _slicedToArray(_useState7, 2),
      functionObj = _useState8[0],
      setFunctionObj = _useState8[1];

  //effect hooks


  (0, _react.useEffect)(function () {
    if (!loading) {
      handleSetYears();
    }
  }, [loading]);

  (0, _react.useEffect)(function () {
    if (yearsArray.length && BOX_RESULTS.boxResults) {
      var arr = [];
      var DrogistArr = [];
      var _BOX_RESULTS$boxResul = BOX_RESULTS.boxResults,
          boxResults = _BOX_RESULTS$boxResul === undefined ? [] : _BOX_RESULTS$boxResul;


      boxResults.sort(function (a, b) {
        return (0, _moment2.default)(a.finishedOn).valueOf() - (0, _moment2.default)(b.finishedOn).valueOf();
      }).forEach(function (result, idx) {
        if (!arr.some(function (item) {
          return item.box.id === result.box.id;
        }) && result.finished && (result.text === '' || result.text === null)) {
          arr.push(result);
        }

        if (!arr.some(function (item) {
          return item.box.id === result.box.id;
        }) && result.finished && result.text != '' && result.text != null) {
          DrogistArr.push(result);
        }
      });
      setProgressArray(arr);
      setdrogistProgressArray(DrogistArr);
    }
  }, [yearsArray, BOX_RESULTS.boxResults]);

  //functions
  var handleSetYears = function handleSetYears() {
    if (appUser.userDataCbd) {
      var arr = [];
      var _obj = {};
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = Object.entries(appUser.userDataCbd)[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var _ref = _step.value;

          var _ref2 = _slicedToArray(_ref, 2);

          var key = _ref2[0];
          var value = _ref2[1];

          if (key.indexOf('trainingMandatory') !== -1 && value) {
            arr.push({ year: getSecondPart(key, 'trainingMandatory') });
          }
          if (key.indexOf('function20') !== -1 && value) {
            _obj[getSecondPart(key, 'function')] = value || appUser.userDataCbd.function || 'Assistent drogist';
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      setFunctionObj(_obj);
      setYearsArray(arr);
    }
  };

  var getSecondPart = function getSecondPart(str, firstPart) {
    return str.split(firstPart)[1];
  };

  var literals = config.literals || {};
  if (loading) {
    return _react2.default.createElement(
      'div',
      null,
      _react2.default.createElement(_appCore.Loader, { fullScreen: true })
    );
  }
  return _react2.default.createElement(
    'div',
    null,
    _react2.default.createElement(
      _AccountWrapper2.default,
      {
        title: 'Resultaten',
        introduction: literals.APP_RESULTS_ACCOUNT_INTRO || '',
        wrapper: 'medium'
      },
      yearsArray && yearsArray.map(function (item, index) {
        return _react2.default.createElement(_YearContent2.default, _extends({
          key: item.year
        }, item, {
          progressArray: progressArray,
          drogistProgressArray: drogistProgressArray,
          userFunction: functionObj[item.year],
          index: index,
          appUser: appUser
        }));
      })
    )
  );
};

exports.default = (0, _recompose.compose)((0, _styles.withStyles)(styles), (0, _reactRedux.connect)(mapStateToProps), (0, _reactApollo.graphql)(_graphql.GQL_FETCH_APP_USER, {
  options: function options(props) {
    return {
      variables: {
        id: props.appUserData.id
      }
    };
  }
}), (0, _reactApollo.graphql)(_graphql.GQL_FETCH_BOX_RESULTS, {
  name: 'BOX_RESULTS',
  options: function options(props) {
    return {
      variables: {
        program: props.program,
        user: props.appUserData.id
      }
    };
  }
}))(Results);