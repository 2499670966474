import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import SingleDot from "./SingleDot";
import Button from "@material-ui/core/Button";
import { LoadImage } from "@manakin/app-core";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import { connect } from "react-redux";

const styles = theme => ({
  container: {
    width: "100%"
  },
  image: {
    position: "relative",
    width: "100%"
  },
  button: {
    position: "absolute",
    bottom: 0,
    right: 0,
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "block"
    }
  },
  question: {
    textAlign: "center",
    marginBottom: "3.7rem",
    fontWeight: "bold",
    fontSize: "4rem",
    marginTop: "1.8rem",
    wordBreak: "break-word",
    hyphens: "auto",
    [theme.breakpoints.down("xs")]: {
      fontSize: "2.4rem",
      lineHeight: "4rem"
    }
  },
  subHeader: {
    textAlign: "center",
    "&:after": {
      display: "none"
    }
  }
});

class HotspotsMap extends React.PureComponent {
  state = {
    isVisible: false
  };

  openAll = () => {
    this.setState(prevState => ({
      isVisible: !prevState.isVisible
    }));
  };

  render() {
    const {
      classes,
      question = "",
      introduction: _introduction = false,
      image = {},
      mapSpots = [],
      config = {}
    } = this.props;
    const { literals = {} } = config;

    const introduction = _introduction
      ? _introduction
      : literals.CMS_CLICK_PLUS_FOR_INFO ||
        "Klik op de plusjes voor meer uitleg";

    return (
      <div className={classes.container}>
        <Typography
          component="p"
          variant="subtitle1"
          className={classNames("smallUnderline", classes.subHeader)}
        >
          {introduction}
        </Typography>
        <Typography component="h3" variant="h3" className={classes.question}>
          {question}
        </Typography>
        <div className={classes.image}>
          <LoadImage
            relative={true}
            src={image ? image.url || "" : ""}
            placeholder={image ? image.thumbnailUrl || "" : ""}
          />
          {mapSpots &&
            mapSpots.map(hotSpots => (
              <SingleDot {...this.state} {...hotSpots} key={hotSpots.id} />
            ))}

          <Button
            variant="contained"
            className={classes.button}
            onClick={this.openAll}
            color="primary"
          >
            {this.state.isVisible
              ? literals.APP_CLOSE_ALL || "Sluit alles"
              : literals.APP_OPEN_ALL || "Open Alles"}
          </Button>
        </div>
      </div>
    );
  }
}

export default compose(
  connect(state => ({ config: state.config })),
  withStyles(styles, { name: "AppHotspotMapLandscape" })
)(HotspotsMap);
