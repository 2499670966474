'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GQL_FETCH_SCHOOL = exports.GQL_FETCH_GROUPS = undefined;

var _templateObject = _taggedTemplateLiteral(['\n  query($filter: GroupFilter) {\n    groups(filter: $filter) {\n      groups {\n        id\n      }\n    }\n  }\n'], ['\n  query($filter: GroupFilter) {\n    groups(filter: $filter) {\n      groups {\n        id\n      }\n    }\n  }\n']),
    _templateObject2 = _taggedTemplateLiteral(['\n  query($id: ID!) {\n    school(id: $id) {\n      id\n      name\n      classes {\n        id\n        name\n        managers {\n          id\n        }\n        teachers {\n          id\n        }\n        product {\n          id\n          name\n          program {\n            id\n            name\n          }\n        }\n      }\n    }\n  }\n'], ['\n  query($id: ID!) {\n    school(id: $id) {\n      id\n      name\n      classes {\n        id\n        name\n        managers {\n          id\n        }\n        teachers {\n          id\n        }\n        product {\n          id\n          name\n          program {\n            id\n            name\n          }\n        }\n      }\n    }\n  }\n']);

var _graphqlTag = require('graphql-tag');

var _graphqlTag2 = _interopRequireDefault(_graphqlTag);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteral(strings, raw) { return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var GQL_FETCH_GROUPS = exports.GQL_FETCH_GROUPS = (0, _graphqlTag2.default)(_templateObject);

var GQL_FETCH_SCHOOL = exports.GQL_FETCH_SCHOOL = (0, _graphqlTag2.default)(_templateObject2);