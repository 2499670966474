"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _reactRouterDom = require("react-router-dom");

var _reactTransitionGroup = require("react-transition-group");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var TransitionRouter = function (_React$PureComponent) {
	_inherits(TransitionRouter, _React$PureComponent);

	function TransitionRouter() {
		_classCallCheck(this, TransitionRouter);

		return _possibleConstructorReturn(this, (TransitionRouter.__proto__ || Object.getPrototypeOf(TransitionRouter)).apply(this, arguments));
	}

	_createClass(TransitionRouter, [{
		key: "render",
		value: function render() {
			var _props = this.props,
			    children = _props.children,
			    location = _props.location;

			var timeout = 300;

			return _react2.default.createElement(
				_react2.default.Fragment,
				null,
				_react2.default.createElement(
					_reactTransitionGroup.TransitionGroup,
					{ className: "fullWidth" },
					_react2.default.createElement(
						_reactTransitionGroup.CSSTransition,
						{
							key: location.key,
							classNames: "fade",
							timeout: timeout
						},
						_react2.default.createElement(
							_reactRouterDom.Switch,
							{ location: location },
							children
						)
					)
				)
			);
		}
	}]);

	return TransitionRouter;
}(_react2.default.PureComponent);

exports.default = (0, _reactRouterDom.withRouter)(TransitionRouter);