'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _AccountWrapper = require('../AccountWrapper');

var _AccountWrapper2 = _interopRequireDefault(_AccountWrapper);

var _styles = require('@material-ui/core/styles');

var _styles2 = require('./styles');

var _reactRedux = require('react-redux');

var _recompose = require('recompose');

var _ExpansionPanel = require('@material-ui/core/ExpansionPanel');

var _ExpansionPanel2 = _interopRequireDefault(_ExpansionPanel);

var _ExpansionPanelSummary = require('@material-ui/core/ExpansionPanelSummary');

var _ExpansionPanelSummary2 = _interopRequireDefault(_ExpansionPanelSummary);

var _ExpansionPanelDetails = require('@material-ui/core/ExpansionPanelDetails');

var _ExpansionPanelDetails2 = _interopRequireDefault(_ExpansionPanelDetails);

var _Typography = require('@material-ui/core/Typography');

var _Typography2 = _interopRequireDefault(_Typography);

var _Delete = require('@material-ui/icons/Delete');

var _Delete2 = _interopRequireDefault(_Delete);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var ExpansionPanel = (0, _styles.withStyles)({
  root: {
    border: 0,
    boxShadow: 'none'
  }
})(_ExpansionPanel2.default);

var ExpansionPanelSummary = (0, _styles.withStyles)(function (theme) {
  return {
    root: {
      backgroundColor: 'transparent',
      transition: 'background-color .3s .3s',
      margin: 0,
      padding: '0 15rem 0 2.4rem',
      position: 'relative'
    },
    content: {
      flexDirection: 'column',
      margin: '1.7rem 0'
    },
    expanded: {
      margin: 0,
      transition: 'background-color .3s .4s',
      backgroundColor: theme.palette.secondary[100]
    }
  };
})(function (props) {
  return _react2.default.createElement(_ExpansionPanelSummary2.default, props);
});
ExpansionPanelSummary.muiName = 'ExpansionPanelSummary';

var ExpansionPanelDetails = (0, _styles.withStyles)(function (theme) {
  return {
    root: {
      padding: '3rem 24px'
    }
  };
})(_ExpansionPanelDetails2.default);

var Messages = function Messages(props) {
  var classes = props.classes,
      config = props.config,
      _props$messagesReceiv = props.messagesReceived,
      messagesReceived = _props$messagesReceiv === undefined ? [] : _props$messagesReceiv;

  var _useState = (0, _react.useState)(false),
      _useState2 = _slicedToArray(_useState, 2),
      _expanded = _useState2[0],
      setIsExpanded = _useState2[1];

  var handleChange = function handleChange(panel) {
    setIsExpanded(_expanded == panel ? false : panel);
    if (props.onChange) props.onChange(panel);
  };

  var handleDelete = function handleDelete(panel) {
    event.stopPropagation();
    if (props.onDelete) props.onDelete(panel);
  };

  var literals = config.literals || {};

  return _react2.default.createElement(
    'div',
    { className: classes.root },
    _react2.default.createElement(
      _AccountWrapper2.default,
      {
        title: 'Berichten',
        introduction: literals.APP_MESSAGES_ACCOUNT_INTRO || '',
        wrapper: 'medium'
      },
      _react2.default.createElement(
        'div',
        { className: classes.messagesRoot },
        messagesReceived && messagesReceived.map(function (item) {
          return _react2.default.createElement(
            ExpansionPanel,
            {
              square: true,
              expanded: _expanded === item.id,
              onChange: function onChange() {
                return handleChange(item.id);
              },
              className: classes.panel,
              key: item.id
            },
            _react2.default.createElement(
              ExpansionPanelSummary,
              null,
              _react2.default.createElement('span', {
                className: (0, _classnames2.default)(classes.new, _defineProperty({}, classes.read, item.readOn))
              }),
              _react2.default.createElement(
                _Typography2.default,
                { variant: 'h6', className: classes.title },
                item.messageSend.sender.fullName
              ),
              _react2.default.createElement(
                _Typography2.default,
                {
                  variant: 'body1',
                  component: 'p',
                  className: classes.subTitle
                },
                item.messageSend.title
              ),
              _react2.default.createElement(
                'span',
                { className: classes.date },
                _react2.default.createElement(
                  'span',
                  { className: classes.deleteIcon },
                  _react2.default.createElement(_Delete2.default, { onClick: function onClick() {
                      return handleDelete(item.id);
                    } })
                ),
                (0, _moment2.default)(item.messageSend.sentOn).format('DD-MM-YYYY')
              )
            ),
            _react2.default.createElement(
              ExpansionPanelDetails,
              null,
              _react2.default.createElement(
                _Typography2.default,
                {
                  variant: 'body1',
                  component: 'p',
                  className: classes.content
                },
                item.messageSend.text
              )
            )
          );
        })
      )
    )
  );
};

exports.default = (0, _recompose.compose)((0, _styles.withStyles)(_styles2.styles), (0, _reactRedux.connect)(function (state) {
  return {
    config: state.config
  };
}))(Messages);