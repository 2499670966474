import React from 'react';
import HotspotsMap from './HotspotsMap';
import HotspotMapLandscape from './HotspotMapLandscape';

class HotspotsMapContainer extends React.PureComponent {
	render() {
		const {data = {}} = this.props;

		if(data.imageOrientation == "LANDSCAPE") {
			return <HotspotMapLandscape {...this.props.data} />
		}else {
			return <HotspotsMap {...this.props.data} />;
		}
	}
}

export default HotspotsMapContainer;