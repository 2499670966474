"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _appWorkforms = require("@manakin/app-workforms");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var WorkformViewer = function (_React$Component) {
  _inherits(WorkformViewer, _React$Component);

  function WorkformViewer() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, WorkformViewer);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = WorkformViewer.__proto__ || Object.getPrototypeOf(WorkformViewer)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
      animate: false
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(WorkformViewer, [{
    key: "componentDidUpdate",
    value: function componentDidUpdate(prevProps) {
      var _this2 = this;

      if (!prevProps.animate && this.props.animate) {
        setTimeout(function () {
          _this2.setState({ animate: true });
        }, 300);
      }
      if (prevProps.animate && !this.props.animate) {
        this.setState({ animate: false });
      }
    }
  }, {
    key: "render",
    value: function render() {
      var _props = this.props,
          item = _props.item,
          onFinish = _props.onFinish,
          _props$options = _props.options,
          options = _props$options === undefined ? {} : _props$options,
          _props$type = _props.type,
          type = _props$type === undefined ? "" : _props$type,
          _props$contentStyle = _props.contentStyle,
          contentStyle = _props$contentStyle === undefined ? "default" : _props$contentStyle,
          _props$direction = _props.direction,
          direction = _props$direction === undefined ? "default" : _props$direction,
          _props$customLink = _props.customLink,
          customLink = _props$customLink === undefined ? false : _props$customLink,
          _props$withNext = _props.withNext,
          withNext = _props$withNext === undefined ? false : _props$withNext;
      var _state$animate = this.state.animate,
          animate = _state$animate === undefined ? false : _state$animate;


      return _react2.default.createElement(
        _react2.default.Fragment,
        null,
        type == "ReferralElement" && _react2.default.createElement(_appWorkforms.ReferralElement, { data: item }),
        type === "ChatBubble" && _react2.default.createElement(_appWorkforms.ChatBubble, { data: item, animate: animate }),
        type == "FactCardReferral" && _react2.default.createElement(_appWorkforms.FactCardReferral, {
          data: item,
          options: options,
          onFinished: onFinish,
          customLink: customLink,
          withNext: withNext
        }),
        type == "FileDownload" && _react2.default.createElement(_appWorkforms.DownloadButton, {
          data: item,
          withNext: withNext,
          onFinished: onFinish
        }),
        type == "Hotspot" && _react2.default.createElement(_appWorkforms.Hotspot, { data: item, options: options, onFinished: onFinish }),
        type == "HotspotMap" && _react2.default.createElement(_appWorkforms.HotspotMap, { data: item, options: options, onFinished: onFinish }),
        type == "ImageAndTextMPC" && _react2.default.createElement(_appWorkforms.ImageAndTextMPC, {
          data: item,
          options: options,
          onFinished: onFinish,
          direction: direction
        }),
        type == "ImageAndTextCheckQuestion" && _react2.default.createElement(_appWorkforms.ImageAndTextCheckQuestion, {
          data: item,
          options: options,
          onFinished: onFinish
        }),
        type == "ImageCheckQuestion" && _react2.default.createElement(_appWorkforms.ImageCheckQuestion, {
          data: item,
          options: options,
          onFinished: onFinish
        }),
        type == "ImageMPC" && _react2.default.createElement(_appWorkforms.ImageMPC, {
          data: item,
          options: options,
          onFinished: onFinish,
          direction: direction
        }),
        type == "ImageWithSubtitle" && _react2.default.createElement(_appWorkforms.ImageWithSubtitle, { data: item, onFinished: onFinish }),
        type == "Moodboard" && _react2.default.createElement(_appWorkforms.Moodboard, { data: item }),
        type == "OrderQuestion" && _react2.default.createElement(_appWorkforms.OrderQuestion, { data: item, options: options, onFinished: onFinish }),
        type == "ShortText" && _react2.default.createElement(_appWorkforms.ShortText, { data: item, contentStyle: contentStyle }),
        type == "SortQuestion" && _react2.default.createElement(_appWorkforms.SortQuestion, { data: item, options: options, onFinished: onFinish }),
        type === "ChoiceQuestion" && _react2.default.createElement(_appWorkforms.ChoiceQuestion, { data: item, options: options, onFinished: onFinish }),
        type == "StatementQuestion" && _react2.default.createElement(_appWorkforms.StatementQuestion, {
          data: item,
          options: options,
          onFinished: onFinish,
          direction: direction
        }),
        type == "TextCheckQuestion" && _react2.default.createElement(_appWorkforms.TextCheckQuestion, {
          data: item,
          options: options,
          onFinished: onFinish
        }),
        type == "TextMPC" && _react2.default.createElement(_appWorkforms.TextMPC, {
          data: item,
          options: options,
          onFinished: onFinish,
          direction: direction
        }),
        type == "TitleAndText" && _react2.default.createElement(_appWorkforms.TitleAndText, { data: item }),
        type == "TitleAndTextImage" && _react2.default.createElement(_appWorkforms.TitleAndTextImage, {
          data: item,
          withNext: withNext,
          onFinished: onFinish
        }),
        type == "VideoWithTitle" && _react2.default.createElement(_appWorkforms.VideoWithTitle, { data: item }),
        type == "WhatIsWhatQuestion" && _react2.default.createElement(_appWorkforms.WhatIsWhatQuestion, {
          data: item,
          options: options,
          onFinished: onFinish
        }),
        type == "Scene" && _react2.default.createElement(_appWorkforms.Scene, { workformId: item.id, onNext: onFinish })
      );
    }
  }]);

  return WorkformViewer;
}(_react2.default.Component);

exports.default = WorkformViewer;