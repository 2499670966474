import gql from 'graphql-tag';

export const GQL_FETCH_TRAINER = gql`
  query($id: ID!) {
    trainer(id: $id) {
      id
      type
      introTitle
      introText
      preTitle
      titleColor
      image {
        id
        url
        thumbnailUrl
      }
      workforms {
        id
        type
      }
    }
  }
`;

export const GQL_FETCH_ELEMENT_RESULTS = gql`
  query($box: ID!, $program: ID!, $user: ID!, $element: ID!) {
    elementResult(
      box: $box
      program: $program
      user: $user
      element: $element
    ) {
      bestTime {
        id
        time
      }
    }
  }
`;

export const GQL_CREATE_ELEMENT_RESULT = gql`
  mutation(
    $user: ID!
    $program: ID!
    $box: ID!
    $element: ID!
    $finished: Boolean
    $bestTime: Int
  ) {
    createElementResult(
      input: {
        user: $user
        program: $program
        box: $box
        element: $element
        finished: $finished
        bestTime: $bestTime
      }
    ) {
      result {
        finished
      }
    }
  }
`;

export const GQL_FETCH_GLOBAL_SETTINGS = gql`
  query {
    settings {
      settings {
        name
        value
        file {
          id
          bynderId
          derivative
          url
          thumbnailUrl
          name
        }
      }
    }
  }
`;
