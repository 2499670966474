import React, { useState, useEffect } from "react";
import { ElementOverlay, LoadImage, Loader } from "@manakin/app-core";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import Button from "@material-ui/core/Button";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { renderCertificate } from "@manakin/app-core/renderCertificate";
import { useSetting } from "@manakin/hooks";
import {
  GQL_FETCH_GLOBAL_SETTINGS,
  GQL_FETCH_BOX,
  GQL_DELETE_BOX_RESULTS,
  GQL_FETCH_APP_USER
} from "./graphql";
import { graphql } from "react-apollo";
import { getBoxId } from "@manakin/app-core/Boxes/selectors";
import { getAppUser } from "@manakin/authentication/selectors";
import { connect } from "react-redux";
import moment from "moment";
import { savable } from "@manakin/app-core/lib";

const mapStateToProps = state => ({
  boxId: getBoxId(state),
  appUser: getAppUser(state)
});

const styles = theme => ({
  root: {
    backgroundColor: theme.manakin.secondaryBackgroundColor[500],
    width: "100%",
    paddingTop: "8rem",
    paddingBottom: "8rem"
  },
  content: {
    maxWidth: "500px",
    margin: "0 auto 3.6rem",
    color: theme.manakin.defaultTitleColor[500]
  },
  wrapper: {
    ...theme.manakin.smallWrapper,
    textAlign: "center"
  },
  feedback: {
    marginBottom: "6rem"
  },
  feedbackBlock: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
    flexWrap: "wrap",
    alignItems: "center",
    padding: "3.6rem",
    marginBottom: "2rem",
    position: "relative",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row"
    }
  },
  questionStyle: {
    textAlign: "left"
  },
  question: {
    width: "100%",
    "& $questionStyle": {
      color: theme.manakin.defaultTitleColor[500],
      fontWeight: "500",
      margin: "0 0 2rem 0"
    },
    [theme.breakpoints.up("md")]: {
      width: "40%",
      "& $questionStyle": {
        margin: "0"
      }
    }
  },
  answer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      width: "60%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      paddingLeft: "4rem"
    }
  },
  img: {
    position: "relative",
    height: "100px",
    width: "100px",
    display: "inline-block",
    textAlign: "left "
  },
  noMargin: {
    margin: 0
  },
  titleStyle: {
    textAlign: "left",
    marginRight: "2rem"
  },
  buttons: {
    display: "flex",
    justifyContent: "center"
  },
  firstButton: {
    marginRight: "2.8rem"
  }
});

const DefaultResultsPage = props => {
  const {
    classes,
    workforms = null,
    variant = "default",
    title = false,
    data,
    hasBoxResults = false,
    boxResults = {},
    BOX = {},
    boxId,
    APPUSER
  } = props;
  const { box = {}, loading } = BOX;
  const { loading: _loading } = data;
  const { loading: appUserLoading, appUser } = APPUSER;
  const [finishedWorkforms, setFinishedWorkforms] = useState([]);
  const [boxResult, setBoxResult] = useState({});
  const minGrade = parseFloat(useSetting(data.settings, "minGrade") || 5.5);

  let count = 0;

  useEffect(() => {
    let isNew = false;
    const savableWorkforms = savable(workforms);

    savableWorkforms &&
      savableWorkforms.forEach(workform => {
        if (!workform.finished) isNew = true;
      });

    setFinishedWorkforms(savableWorkforms);
    if (hasBoxResults && boxResults.length) {
      boxResults.forEach(item => {
        if (item.box.id === boxId) {
          setBoxResult(item);
        }
      });
    } else if (isNew) {
      props.history.push(props.url);
    }
  }, [workforms]);

  workforms &&
    workforms.forEach(item => {
      if (item.correct) count++;
    });

  //functions
  const handleReset = () => {
    if (hasBoxResults && boxResult && boxResult.id) {
      props
        .mutate({
          variables: {
            id: boxResult.id
          }
        })
        .then(result => {
          if (!result.errors) {
            props.resetElement(props.rawData.elementId, props.url);
          }
        });
    } else {
      props.resetElement(props.rawData.elementId, props.url);
    }
  };

  const percentage = (partialValue, totalValue) => {
    return (100 * partialValue) / totalValue;
  };

  const handleCertificates = result => {
    renderCertificate(result);
  };

  const handleClose = () => {
    props.history.push(`/box/${props.boxId}`);
  };

  let result = boxResult.rating
    ? boxResult.rating
    : percentage(count, finishedWorkforms.length) / 10;

  if (boxResult.finished && !boxResult.rating) {
    result = 8;
  }

  if (_loading || loading || appUserLoading) {
    return (
      <div>
        <Loader fullScreen={true} />
      </div>
    );
  }
  return (
    <ElementOverlay
      variant="entryTest"
      title={props.preTitle || props.prefix || "Instap toets"}
      controls={true}
    >
      <div className={classes.root}>
        <div className={classes.wrapper}>
          <Typography variant="h2" component="h1">
            {hasBoxResults ? (
              <div>
                {title
                  ? title
                  : result && parseFloat(result) >= minGrade
                  ? "Gefeliciteerd"
                  : "Helaas"}
              </div>
            ) : (
              <div>
                {title
                  ? title
                  : result >= minGrade
                  ? "Gefeliciteerd"
                  : "Helaas"}
              </div>
            )}
          </Typography>
          <Typography variant="subtitle1" component="h2">
            {variant === "test" ? (
              <div>Je hebt een {result} behaald.</div>
            ) : (
              <div>
                Je hebt {count} van de {finishedWorkforms.length} vragen goed
              </div>
            )}
          </Typography>

          <div>
            {count !== finishedWorkforms.length && (
              <Typography
                variant="body1"
                component="p"
                className={classes.content}
              >
                Hieronder lees je wat de juiste antwoorden zijn.
              </Typography>
            )}
          </div>

          <div className={classes.feedback}>
            {finishedWorkforms &&
              finishedWorkforms.map(item => (
                <React.Fragment key={item.id}>
                  {!item.correct && (
                    <div className={classes.feedbackBlock}>
                      <div className={classes.question}>
                        <Typography className={classes.questionStyle}>
                          {item.question || item.statement || ""}
                        </Typography>
                      </div>
                      <div className={classes.answer}>
                        {item.answers &&
                          item.answers.map(answer => (
                            <React.Fragment key={answer.id}>
                              {answer.correct && (
                                <React.Fragment>
                                  {answer.title && (
                                    <Typography
                                      className={classNames(
                                        classes.noMargin,
                                        classes.titleStyle
                                      )}
                                    >
                                      {answer.title || ""}
                                    </Typography>
                                  )}
                                  {answer.image && (
                                    <div className={classes.img}>
                                      <LoadImage src={answer.image.url || ""} />
                                    </div>
                                  )}
                                </React.Fragment>
                              )}
                            </React.Fragment>
                          ))}
                        {item.answerOne && (
                          <React.Fragment>
                            {item.answer ? (
                              <Typography
                                className={classNames(
                                  classes.noMargin,
                                  classes.titleStyle
                                )}
                              >
                                {item.answerOne || ""}
                              </Typography>
                            ) : (
                              <Typography
                                className={classNames(
                                  classes.noMargin,
                                  classes.titleStyle
                                )}
                              >
                                {item.answerTwo || ""}
                              </Typography>
                            )}
                          </React.Fragment>
                        )}
                      </div>
                    </div>
                  )}
                </React.Fragment>
              ))}
          </div>

          {(variant == "default" || result < minGrade) && (
            <div className={classes.buttons}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleClose}
                className={classes.firstButton}
              >
                Terug naar magazine
              </Button>
              <Button variant="contained" color="primary" onClick={handleReset}>
                Opnieuw proberen
              </Button>
            </div>
          )}
          {hasBoxResults &&
            variant != "default" &&
            result &&
            parseFloat(result) >= minGrade && (
              <div className={classes.buttons}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleClose}
                  className={classes.firstButton}
                >
                  Terug naar magazine
                </Button>
                {boxResult.certificatePdfUrl && (
                  <Button
                    variant="contained"
                    color="primary"
                    href={boxResult.certificatePdfUrl}
                    target="_blank"
                  >
                    Bekijk je certificaat
                  </Button>
                )}
              </div>
            )}
          {result >= minGrade && variant != "default" && !hasBoxResults && (
            <div className={classes.buttons}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleClose}
                className={classes.firstButton}
              >
                Terug naar magazine
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  handleCertificates({
                    result: result,
                    titleFont: process.env.PUBLIC_URL + "/fonts/title-font.tff",
                    normalFont:
                      process.env.PUBLIC_URL + "/fonts/normal-font.tff",
                    date: moment().format("DD-MM-YYYY"),
                    boxName: box.name || "",
                    fullName: appUser.fullName,
                    dateOfBirth:
                      moment(appUser.dateOfBirth).format("DD-MM-YYYY") || ""
                  })
                }
              >
                Bekijk je certificaat
              </Button>
            </div>
          )}
        </div>
      </div>
    </ElementOverlay>
  );
};

export default compose(
  connect(mapStateToProps),
  graphql(GQL_DELETE_BOX_RESULTS),
  graphql(GQL_FETCH_GLOBAL_SETTINGS),
  graphql(GQL_FETCH_BOX, {
    name: "BOX",
    options: props => ({ variables: { id: props.boxId } })
  }),
  graphql(GQL_FETCH_APP_USER, {
    name: "APPUSER",
    options: props => ({ variables: { id: props.appUser.id } })
  }),
  withStyles(styles),
  withRouter
)(DefaultResultsPage);
