import gql from "graphql-tag"

export const GQL_FETCH_SLIDESHOW = gql`
    query($id: ID!) {
        slideshowCreator(id: $id) {
            id
            title
            introTitle
            assets {
                asset {
                    id
                    url
                    bynderId
                    derivative
                }
            }
            slideshows {
                id
                title
                isPublic
                slides {
                    id
                    title
                    text
                    asset {
                        id
                        bynderId
                        derivative
                        url
                    }
                }
            }
        }
    }
`;

export const GQL_SAVE_SLIDESHOW = gql`
    mutation(
        $id: ID
        $title: String!
        $introduction: String
        $backgroundImage: AssetInput
        $slideshowCreator: ID
        $slides: [SlideInput]
        $isPublic: Boolean
    ) {
        upsertSlideshow(
            input: {
                id: $id
                title: $title
                introduction: $introduction
                backgroundImage: $backgroundImage 
                slideshowCreator: $slideshowCreator
                slides: $slides
                isPublic: $isPublic
            }
        ) {
            slideshow { 
                id
            }
        }
    }
`;

export const GQL_DELETE_SLIDESHOW = gql`
    mutation(
        $id: ID!
    ) {
        deleteWorkform(
            id: $id
        ) {
            result 
        }
    }
`;
