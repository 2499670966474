import React from 'react';
import withForm from '../form/withForm';
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import ReactQuill, { Quill } from 'react-quill';
import classNames from 'classnames';
import 'react-quill/dist/quill.snow.css';
import BlotFormatter, {
  AlignAction,
  DeleteAction,
  ImageSpec
} from 'quill-blot-formatter';
import AssetsViewerRichText from '../AssetsViewer/AssetsViewerRichText';
import { compose } from 'recompose';

const styles = theme => ({
  root: {
    margin: theme.manakin.normalMargin
  },
  label: {
    color: theme.manakin.defaultTitleColor[500],
    fontSize: '18px'
  },
  error: {
    color: 'red'
  },
  errorMessage: {
    color: 'red',
    marginBottom: '14px'
  },
  loading: {
    height: theme.spacing(6),
    backgroundColor: theme.palette.grey[100],
    '& *': { opacity: 0, pointerEvents: 'none' }
  },
  quillContainer: {
    padding: '20px 0 0'
  },
  disabledLabel: {
    color: theme.manakin.disabledFieldLabelColor
  },
  disabledInput: {
    color: theme.manakin.disabledFieldTextColor
  },
  disabledBorder: {
    border: `none`
  }
});

const imageFormatter = {
  overlay: {
    style: {
      border: '2px solid #999'
    }
  },
  align: {
    aligner: {
      applyStyle: true
    },
    icons: {
      left: `<svg width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path d="M14 15H4c-.55 0-1 .45-1 1s.45 1 1 1h10c.55 0 1-.45 1-1s-.45-1-1-1zm0-8H4c-.55 0-1 .45-1 1s.45 1 1 1h10c.55 0 1-.45 1-1s-.45-1-1-1zM4 13h16c.55 0 1-.45 1-1s-.45-1-1-1H4c-.55 0-1 .45-1 1s.45 1 1 1zm0 8h16c.55 0 1-.45 1-1s-.45-1-1-1H4c-.55 0-1 .45-1 1s.45 1 1 1zM3 4c0 .55.45 1 1 1h16c.55 0 1-.45 1-1s-.45-1-1-1H4c-.55 0-1 .45-1 1z"/></svg>`,
      center: `<svg width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path d="M7 16c0 .55.45 1 1 1h8c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1zm-3 5h16c.55 0 1-.45 1-1s-.45-1-1-1H4c-.55 0-1 .45-1 1s.45 1 1 1zm0-8h16c.55 0 1-.45 1-1s-.45-1-1-1H4c-.55 0-1 .45-1 1s.45 1 1 1zm3-5c0 .55.45 1 1 1h8c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1zM3 4c0 .55.45 1 1 1h16c.55 0 1-.45 1-1s-.45-1-1-1H4c-.55 0-1 .45-1 1z"/></svg>`,
      right: `<svg width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path d="M4 21h16c.55 0 1-.45 1-1s-.45-1-1-1H4c-.55 0-1 .45-1 1s.45 1 1 1zm6-4h10c.55 0 1-.45 1-1s-.45-1-1-1H10c-.55 0-1 .45-1 1s.45 1 1 1zm-6-4h16c.55 0 1-.45 1-1s-.45-1-1-1H4c-.55 0-1 .45-1 1s.45 1 1 1zm6-4h10c.55 0 1-.45 1-1s-.45-1-1-1H10c-.55 0-1 .45-1 1s.45 1 1 1zM3 4c0 .55.45 1 1 1h16c.55 0 1-.45 1-1s-.45-1-1-1H4c-.55 0-1 .45-1 1z"/></svg>`
    },
    toolbar: {
      mainStyle: {
        border: 'none'
      },
      svgStyle: {
        display: 'inline-block',
        width: '24px',
        height: '24px',
        padding: '3px',
        background: 'white',
        border: '1px solid #999999',
        verticalAlign: 'middle',
        boxSizing: 'content-box',
        cursor: 'pointer'
      },
      buttonStyle: {
        border: 'none',
        height: '32px',
        width: '32px'
      }
    }
  },
  resize: {
    handleStyle: {
      backgroundColor: '#FFFFFF',
      opacity: 1,
      width: '16px',
      height: '16px',
      borderRadius: '100%',
      border: '2px solid #999999'
    }
  }
};
const ImageAttributes = [
  'src',
  'height',
  'width',
  'style',
  'align',
  'data-align'
];
let ImageFormat = Quill.import('formats/image');

class ImageUploader {
  constructor(quill, options) {
    this.quill = quill;
    this.options = options;

    if (typeof this.options.upload !== 'function')
      console.warn(
        'Missing upload function that returns a promise is required'
      );

    //Set listener on toolbar item
    let toolbar = this.quill.getModule('toolbar');
    toolbar.addHandler('image', this.selectImage.bind(this));
  }

  selectImage() {
    let range = this.quill.getSelection();

    // Get the image url, call asset viewer
    this.options.upload().then(
      image => {
        this.quill.insertEmbed(range.index, 'image', `${image.url}`);
      },
      error => {
        console.warn(error.message);
      }
    );
  }
}

class Image extends ImageFormat {
  static create(value) {
    let node = super.create();

    if (value && value.url) {
      node.setAttribute('src', value.url);
      if (value.height) node.setAttribute('height', value.height);
      if (value.width) node.setAttribute('width', value.width);
      if (value.style) node.setAttribute('style', value.style);
      if (value.align) node.setAttribute('align', value.align);
      if (value['data-align'])
        node.setAttribute('data-align', value['data-align']);
    } else {
      // We've got no other attributes
      node.setAttribute('src', value);
    }

    return node;
  }

  static value(node) {
    return {
      url: node.getAttribute('src'),
      height: node.getAttribute('height'),
      width: node.getAttribute('width'),
      align: node.getAttribute('align'),
      style: node.getAttribute('style'),
      'data-align': node.getAttribute('data-align')
    };
  }

  // Strip all values except the ones specified in Attributes
  static formats = domNode =>
    ImageAttributes.reduce((formats, attribute) => {
      if (domNode.hasAttribute(attribute))
        formats[attribute] = domNode.getAttribute(attribute);

      return formats;
    }, {});
}

Image.blotName = 'image';
Image.tagName = 'img';

Quill.register('modules/blotFormatter', BlotFormatter);
Quill.register(Image);
Quill.register('modules/imageUploader', ImageUploader);

class RichTextEditor extends React.PureComponent {
  timeout;
  chooseAssetResolve = null;
  chooseAssetReject = null;
  chooseAsset = new Promise((resolve, reject) => {
    this.chooseAssetResolve = resolve;
    this.chooseAssetReject = reject;
  });

  state = {
    value: ''
  };

  handleImagePick = () =>
    new Promise(resolve => {
      const { readOnly = false } = this.props;
      if (!readOnly) {
        this.handleOpenAssetViewer();
        this.chooseAsset.then(image => {
          this.handleCloseAssetViewer();
          resolve(image);
        });
      } else {
        resolve({ url: '' });
      }
    });

  handleOpenAssetViewer = () => {
    this.setState({
      assetViewerOpen: true
    });
  };

  handleCloseAssetViewer = () => {
    this.chooseAssetReject();
    this.setState(
      {
        assetViewerOpen: false
      },
      () => this.handleImagePicked()
    );
  };

  handleImagePicked = () => {
    this.chooseAssetResolve = null;
    this.chooseAssetReject = null;
    this.chooseAsset = new Promise((resolve, reject) => {
      this.chooseAssetResolve = resolve;
      this.chooseAssetReject = reject;
    });
  };

  handleChange = value => {
    const { form, name } = this.props;
    this.setState({ value: value });

    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      form.onFieldChange({ key: name, value: value });
    }, 400);
  };

  componentDidUpdate(prevProps) {
    if (prevProps.initialValue != this.props.initialValue) {
      const { form, name, initialValue = '' } = this.props;
      this.setState({ value: initialValue });
      form.onFieldChange({ key: name, value: initialValue });
    }
  }

  componentDidMount() {
    const { form, name, initialValue = '' } = this.props;
    this.setState({ value: initialValue });
    form.onFieldChange({ key: name, value: initialValue });
  }

  render() {
    const {
      classes,
      name,
      className: classNameProp,
      label = '',
      readOnly = false,
      placeholder,
      editorModules,
      editorFormats,
      loading,
      form
    } = this.props;
    const { value, assetViewerOpen } = this.state;
    const { errors, schema, fields } = form;
    const className = classNames(
      classes.root,
      { [classes.loading]: loading },
      { [classes.error]: errors[name] && errors[name].length > 0 },
      classNameProp
    );

    return (
      <div className={className}>
        <div>
          <InputLabel
            className={classNames(classes.label, {
              [classes.disabledLabel]: readOnly
            })}
          >
            {label}
          </InputLabel>

          <div
            className={classNames(classes.quillContainer, {
              [classes.disabledBorder]: readOnly,
              [classes.disabledInput]: readOnly
            })}
          >
            <ReactQuill
              value={value}
              placeholder={placeholder}
              modules={{
                toolbar: editorModules,
                imageUploader: {
                  upload: this.handleImagePick
                },
                clipboard: {
                  matchVisual: false
                },
                ...(editorFormats.includes('image') && {
                  blotFormatter: imageFormatter
                })
              }}
              readOnly={readOnly}
              formats={editorFormats}
              onChange={this.handleChange}
            />
            {errors[name] &&
              errors[name].length > 0 &&
              errors[name].map((e, i) => (
                <div className={classes.errorMessage} key={i}>
                  {e}
                </div>
              ))}
          </div>
        </div>

        <AssetsViewerRichText
          open={assetViewerOpen}
          onAssetPick={image => this.chooseAssetResolve(image)}
          onClose={this.handleCloseAssetViewer}
        />
      </div>
    );
  }
}

export default compose(withStyles(styles))(RichTextEditor);
