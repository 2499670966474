import React from "react";
import { WorkformLayout, SortSlider } from "@manakin/app-core";
import shuffle from "shuffle-array";
import { compose } from "recompose";
import { connect } from "react-redux";

class SortQuestion extends React.PureComponent {
  state = {
    questions: []
  };

  componentDidMount() {
    this.setStartState();
  }

  componentDidUpdate(prevProps) {
    if (
      (!prevProps.data.done && this.props.data.done) ||
      prevProps.data.id != this.props.data.id
    ) {
      this.setStartState();
    }
  }

  setStartState = () => {
    const { questions = [] } = this.props.data;

    const _questions = [...questions];
    shuffle(_questions);

    this.setState({
      questions: _questions
    });
  };

  onNext = () => {
    if (this.props.onNext) this.props.onNext();
  };

  render() {
    const { data, options = {}, onFinished, config = {} } = this.props;
    const { literals = {} } = config;
    const { questions = [] } = this.state;

    return (
      <WorkformLayout
        question={data.question || ""}
        instruction={
          data.introduction ||
          literals.CMS_CHOOSE_CAT ||
          "Kies de juiste categorie"
        }
        loading={false}
        quarterQuestion={true}
        renderAnswers={() => (
          <SortSlider
            questions={questions}
            data={data}
            options={options}
            leftButton={
              data.indicationTitle || literals.APP_INDICATION || "Indicatie"
            }
            rightButton={
              data.contraIndicationTitle ||
              literals.APP_CONTRA_INDICATION ||
              "Contra-indicatie"
            }
            onNext={onFinished}
          />
        )}
      />
    );
  }
}

export default compose(connect(state => ({ config: state.config })))(
  SortQuestion
);
