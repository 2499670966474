import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { compose, withHandlers } from 'recompose';
import { AccessControl, ProgramOrder } from '@manakin/app-core';
import Typography from '@material-ui/core/Typography';
import { graphql } from 'react-apollo';
import { GQL_FETCH_SCHOOL_CLASS, GQL_UPDATE_BOXES } from './graphql';
import { connect } from 'react-redux';
import { openDialog } from '@manakin/core/actions';
import { getGroup } from '@manakin/app-core/GroupsDropdown/selectors';
import { showSnackbarMessage } from '@manakin/core/actions';
import { getGoBack } from '@manakin/app-core/AppBar/selectors';
import {
  saveAppBar,
  goBackAppBar,
  setBackButton
} from '@manakin/app-core/AppBar/actions';
import MilestoneDialog from './MilestoneDialog';
import { withForm, InputChoiceField } from '@manakin/core';

const form = {};

const styles = theme => ({
  root: {
    minHeight: '100vh',
    backgroundColor: theme.manakin.primaryColor[500]
  },
  wrapper: {
    ...theme.manakin.smallWrapper
  },
  heading: {
    padding: '18rem 0 0rem',
    textAlign: 'center'
  },
  title: {
    fontSize: '4rem',
    lineHeight: '5.2rem',
    letterSpacing: '.4rem'
  },
  contentWrapper: {
    ...theme.manakin.defaultWrapper,
    maxWidth: '880px',
    padding: '0 1rem'
  },
  select: {
    marginBottom: '6rem',
    minWidth: '20rem'
  },
  selectField: {
    marginBottom: '3rem!important',
    '& span': {
      marginBottom: '0!important'
    }
  }
});

class TeacherProgram extends React.Component {
  state = {
    boxes: [],
    value: 'null',
    schoolClass: {},
    mileStone: null,
    mileStones: {},
    showGoBackDialog: false,
    leaveAfterSave: false
  };

  componentDidMount() {
    const { location = {} } = this.props;
    this.props.setBackButton({
      show: true,
      location: location.pathname || '/teacherProgram'
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data.loading && !this.props.data.loading) {
      const { schoolClass = {} } = this.props.data;
      if (schoolClass) {
        const { milestones = [] } = schoolClass;
        milestones.forEach(item => {
          this.handleMileStone({
            ...item,
            id: item.box.id
          });
        });
      }
    }
  }

  handleMileStone = data => {
    this.setState(prevState => ({
      mileStones: {
        ...prevState.mileStones,
        [data.id]: { text: data.text, id: data.id }
      }
    }));
  };

  handleSave = event => {
    const { mileStones } = this.state;
    const { group } = this.props;
    if (event.length) {
      let boxes = [];

      event.forEach(item => {
        const i = item.map(e => e.id);
        boxes.push(i);
      });

      const data = {
        id: group,
        boxes: boxes,
        fixedBoxes: this.props.form.fields.fixedBoxes
      };

      const _temp = Object.keys(mileStones).map(key => ({
        box: mileStones[key].id,
        text: mileStones[key].text
      }));
      const _mileStones = _temp.filter(item => item.text);
      this.props
        .onCreate({ ...data, milestones: [..._mileStones] })
        .then(result => {
          this.props.history.goBack();
        });
    }
  };

  handleClick = (data = []) => {
    if (data[0].id) {
      this.setState(
        {
          mileStone: data[0].id
        },
        () => {
          this.props.onOpenDialog('appMilestoneDialogDialog');
        }
      );
    }
  };


  render() {
    const { classes, data, form, group } = this.props;
    const { schoolClass = {}, loading } = data;
    const { mileStone, mileStones } = this.state;
    let program = {};
    let programName = 'Deze klas heeft geen programma';
    let programDescription = '';

    if (schoolClass) {
      program =
        schoolClass.product && schoolClass.product.program
          ? schoolClass.product.program
          : {};
      programName =
        schoolClass.product === null
          ? 'Deze klas heeft geen programma'
          : program.name || 'Kies een lesprogramma';
      programDescription =
        schoolClass.product === null
          ? ''
          : program.description ||
          'Kies een klas uit de onderstaande dropdown. Klik op de opslaan knop rechtsboven om de wijzigingen op te slaan';
    }

    if (loading) {
      return <div>loading</div>;
    }
    if (!schoolClass) {
      return <div>Gebruiker heeft geen klas</div>
    }
    return (
      <AccessControl role={['SCHOOL_MANAGER', 'TEACHER']}>
        <div className={classes.root}>
          <MilestoneDialog
            mileStone={mileStone}
            mileStones={mileStones}
            onSave={this.handleMileStone}
          />
          <div className={classes.heading}>
            <div className={classes.wrapper}>
              <Typography
                component="h1"
                variant="h2"
                classes={{ h2: classes.title }}
              >
                {programName || ''}
              </Typography>
              <Typography component="p" variant="body1">
                {programDescription || ''}
              </Typography>
            </div>
          </div>
          <div className={classes.contentWrapper}>
            {schoolClass.product && schoolClass.product.program && (
              <div>
                <ProgramOrder
                  program={schoolClass.product.program}
                  schoolClass={schoolClass}
                  onClick={this.handleClick}
                  mileStones={mileStones}
                  disable={schoolClass.fixedBoxes}
                  onSave={this.handleSave}
                />
              </div>
            )}
          </div>
        </div>
      </AccessControl>
    );
  }
}

export default compose(
  withStyles(styles, { name: 'AppTeacherProgram' }),
  connect(
    state => ({
      group: getGroup(state),
      isGoBack: getGoBack(state)
    }),
    dispatch => ({
      onOpenDialog: data => dispatch(openDialog(data)),
      setBackButton: data => dispatch(setBackButton(data)),
      processSuccess: result => {
        dispatch(
          showSnackbarMessage({
            text: 'Klas aangepast.',
            variant: 'success'
          })
        );
      },
      processFailure: () => {
        dispatch(
          showSnackbarMessage({
            text: 'Les volgorde aanpassen uitgeschakeld door schoolbeheerder',
            variant: 'error'
          })
        );
      },
      onGoBack: data => dispatch(goBackAppBar(data)),
      onSave: data => dispatch(saveAppBar(data))
    })
  ),
  graphql(GQL_UPDATE_BOXES),
  graphql(GQL_FETCH_SCHOOL_CLASS, {
    options: props => ({
      fetchPolicy: 'no-cache',
      variables: {
        id: props.group || 0
      }
    })
  }),
  withHandlers({
    onCreate: ({ mutate, processSuccess, processFailure }) => event => {
      return mutate({
        variables: {
          ...event
        }
      }).then(result => {
        if (!result.errors) {
          processSuccess(result);
          return true;
        } else {
          processFailure();
          return false;
        }
      });
    }
  }),
  withForm(form)
)(TeacherProgram);
