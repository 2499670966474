'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styles = require('@material-ui/core/styles');

var _appCore = require('@manakin/app-core');

var _Typography = require('@material-ui/core/Typography');

var _Typography2 = _interopRequireDefault(_Typography);

var _styles2 = require('./styles');

var _reactRouterDom = require('react-router-dom');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var NewsItemContainer = function NewsItemContainer(props) {
  var classes = props.classes,
      _props$image = props.image,
      image = _props$image === undefined ? {} : _props$image,
      _props$title = props.title,
      title = _props$title === undefined ? '' : _props$title,
      _props$introText = props.introText,
      introText = _props$introText === undefined ? '' : _props$introText,
      id = props.id;


  return _react2.default.createElement(
    _reactRouterDom.Link,
    { to: '/news/' + id, className: classes.root },
    _react2.default.createElement(
      'div',
      { className: classes.figure },
      _react2.default.createElement(_appCore.LoadImage, {
        src: image ? image.url || process.env.PUBLIC_URL + '/dummy-news.png' : process.env.PUBLIC_URL + '/dummy-news.png',
        placeholder: image ? image.thumbnailUrl || '' : ''
      })
    ),
    _react2.default.createElement(
      _Typography2.default,
      { component: 'h5', variant: 'h5', className: classes.heading },
      title
    ),
    _react2.default.createElement(
      _Typography2.default,
      { component: 'p', variant: 'body1', className: classes.body },
      introText
    )
  );
};

exports.default = (0, _styles.withStyles)(_styles2.styles)(NewsItemContainer);