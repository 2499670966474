'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styles = require('@material-ui/core/styles');

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _ = require('../');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var styles = function styles(theme) {
  return {
    backgroundImage: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
      position: 'absolute',
      left: 0,
      top: 0
    },
    overlay: {
      backgroundColor: 'rgba(0,0,0,0.3)',
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%'
    },
    relativeBackground: {
      position: 'relative'
    }
  };
};

var LoadImage = function (_React$PureComponent) {
  _inherits(LoadImage, _React$PureComponent);

  function LoadImage() {
    _classCallCheck(this, LoadImage);

    return _possibleConstructorReturn(this, (LoadImage.__proto__ || Object.getPrototypeOf(LoadImage)).apply(this, arguments));
  }

  _createClass(LoadImage, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          classes = _props.classes,
          classNameProp = _props.className,
          src = _props.src,
          _props$placeholder = _props.placeholder,
          placeholder = _props$placeholder === undefined ? false : _props$placeholder,
          _props$withOverlay = _props.withOverlay,
          withOverlay = _props$withOverlay === undefined ? false : _props$withOverlay,
          _props$relative = _props.relative,
          relative = _props$relative === undefined ? false : _props$relative,
          _props$loader = _props.loader,
          loader = _props$loader === undefined ? false : _props$loader,
          _props$align = _props.align,
          align = _props$align === undefined ? 'default' : _props$align,
          _props$objectFitConta = _props.objectFitContain,
          objectFitContain = _props$objectFitConta === undefined ? false : _props$objectFitConta;

      var className = (0, _classnames2.default)(classes.backgroundImage, _defineProperty({}, classes.relativeBackground, relative), classNameProp);

      return _react2.default.createElement(
        'div',
        { className: className },
        _react2.default.createElement(_.ProgressiveImage, {
          preview: placeholder,
          image: src,
          loader: loader,
          align: align,
          objectFitContain: objectFitContain
        }),
        withOverlay && _react2.default.createElement('div', { className: classes.overlay })
      );
    }
  }]);

  return LoadImage;
}(_react2.default.PureComponent);

exports.default = (0, _styles.withStyles)(styles, { name: 'AppLoadImage' })(LoadImage);