import {DragDropContext} from "react-dnd";
import MultiBackend from 'react-dnd-multi-backend';
import HTML5toTouch from 'react-dnd-multi-backend/lib/HTML5toTouch';
import React from "react";

let defaultContext;

export default function getDndContext() {
    if (defaultContext) return defaultContext;
    defaultContext = new DragDropContext(MultiBackend(HTML5toTouch));
    return defaultContext;
}
