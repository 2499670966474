import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import ArrowRightAlt from "@material-ui/icons/ArrowRightAlt";
import Typography from '@material-ui/core/Typography';
import Media from "react-media";

const styles = theme => ({
    container: {
        width: '100%',
        maxWidth: '667px'
    },
    containerSmall: {
        width: '100%',
        maxWidth: '444px'
    },
    button: {
        display: 'inline',
        width: '594px',
        height: '80px',
        backgroundColor: '#000000',
        color: '#FFFFFF',
        border: '1px solid #FDC228',
        textAlign: 'left',
        float: 'left',
        marginLeft: '24px'
    },
    buttonMobile: {
        display: 'inline',
        width: '313px',
        height: '80px',
        backgroundColor: '#000000',
        color: '#FFFFFF',
        border: '1px solid #FDC228',
        textAlign: 'left',
        marginTop: '16px',
        marginBottom: '32px !important'
    },
    buttonSmall: {
        display: 'inline',
        width: '388px',
        height: '80px',
        backgroundColor: '#000000',
        color: '#FFFFFF',
        border: '1px solid #FDC228',
        textAlign: 'left',
        float: 'left',
        marginLeft: '24px'
    },
    buttonMarkup: {
        height: '20px',
        color: '#FFFFFF',
        fontSize: '14px',
        fontWeight: 'bold',
        letterSpacing: '1px',
        lineHeight: '20px',
        marginLeft: '40px'
    },
    imageOut: {
        float: 'left',
        width: '198px',
        height: '198px',
        padding: '0',
        margin: '0',
    },
    imageIn: {
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '100%',
        height: '100%',
    },
    imageOutMobile: {
        width: '100%',
        height: '171px',
        float: 'left',
        backgroundColor: '#FFFFFF',
        border: '1px solid #E8E8E8'
    },
    imageInMobile: {
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    images: {
        display: 'inline-block',
        paddingLeft: theme.spacing(3),
        width: '100%',
    },
    imagesMobile: {
        display: 'inline-block',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        width: '100%',
    },
    seperator: {
        height: theme.spacing(1)
    },
    imageText: {
        fontWeight: 'bold',
        textAlign: 'center',
        userSelect: 'none',
        fontSize: '18px',
        lineHeight: '36px',
        marginTop: '-32px'
    },
    arrow: {
        width: '40px',
        height: '40px',
        backgroundColor: '#FFFFFF',
        border: '1px solid #E6E6E6',
        fontSize: '18px',
        fontWeight: 'bold',
        lineHeight: '50px',
        marginTop: '6px',
        marginLeft: '6px',
        marginRight: '6px'
    },
    arrowMobile: {
        width: '40px',
        height: '40px',
        backgroundColor: '#FFFFFF',
        border: '1px solid #E6E6E6',
        fontSize: '18px',
        fontWeight: 'bold',
        lineHeight: '50px',
        marginLeft: '-50px',
        marginTop: '32px',
        marginBottom: '32px'
    },
    imageTextMobile: {
        fontSize: '18px',
        lineHeight: '36px',
        paddingLeft: theme.spacing(2)
    },
    regularText: {
        display: 'flex',
        width: '100%',
        height: '152px',
        justifyContent: 'center',
        alignItems: 'center'
    },
    answerDivs: {
        float: 'left',
        width: '50%',
        backgroundColor: 'yellow'
    },
    imageDivs: {
        float: 'left',
        width: '50%',
        backgroundColor: 'red'
    }
});

class WhatIsWhatQuestion extends React.Component {
    state = {
        imageStateWin: new Map(),
        imageStateReal: new Map()
    };

    setImageStates = () => {
        // this.props.questions.forEach(e => {
        //     if(e.couple) {
        //         this.state.imageStateWin.set(e.id, e.couple);
        //     }
        //     else {
        //         this.state.imageStateReal.set(e.id - 3, e.id);
        //     }
        // });
    }

    moveImage = (type, id) => {
        let newId;

        if(type === 'left') {
            newId = id - 1;
            const orgSrc = document.getElementById(newId).style.backgroundImage;

            if(orgSrc) {
                document.getElementById(newId).style.backgroundImage = document.getElementById(id).style.backgroundImage;
                document.getElementById(id).style.backgroundImage = orgSrc;
            }
        }
        else {
            newId = id + 1;

            // Change image
            const orgSrc = document.getElementById(newId).style.backgroundImage;

            if(orgSrc) {
                document.getElementById(newId).style.backgroundImage = document.getElementById(id).style.backgroundImage;
                document.getElementById(id).style.backgroundImage = orgSrc;
            }
        }

        // Change text (if applicable)
        if(document.getElementById('t' + id)) {
            const orgText = document.getElementById('t' + newId).innerText;

            document.getElementById('t' + newId).innerText = document.getElementById('t' + id).innerText;
            document.getElementById('t' + id).innerText = orgText;
        }

        const oldId = this.state.imageStateReal.get(id - 3);

        this.state.imageStateReal.delete(id - 3);
        this.state.imageStateReal.set(id - 3, this.state.imageStateReal.get(newId - 3));
        this.state.imageStateReal.set(newId - 3, oldId);
    }

    handleSubmit = () => {
        let success = true;

        let testVal;
        for (var [key, val] of this.state.imageStateReal) {
            testVal = this.state.imageStateWin.get(key);

            if (testVal !== val || (testVal === undefined && !this.state.imageStateWin.has(key))) {
                success = false;
            }
        }

        return success;
    }

    render() {
        const { classes, buttonText = '', questions = [], data } = this.props;
        const { WhatIsWhatQuestion } = data;
        this.setImageStates();

        let imagesOnly = true;
        questions.map(e => {
            if(e.text) {
                imagesOnly = false;
            }
        });

        let questionsImage = imagesOnly ? questions.filter(e => e.couple) : questions.filter(e => e.image);
        let questionsText = imagesOnly ? questions.filter(e => !e.couple) : questions.filter(e => e.text);

        return (
            <div className={questions.length > 4 ? classes.container : classes.containerSmall}>
                <Media query="(max-width: 860px)">
                {matches => matches ? (
                    // Mobile
                    <div className={classes.imagesMobile}>
                        <div className={classes.answerDivs}>
                            {questionsText.map(e => {
                                return <div className={classes.imageOutMobile} style={imagesOnly ? {border: '0px'} : {}} data-clicked="false">
                                    <div id={e.id} className={classes.imageInMobile} style={{backgroundImage: `url(${e.image})`}}>
                                        <Typography id={'t' + e.id} className={classes.imageTextMobile}>
                                            {e.text}
                                        </Typography>
                                    </div>
                                </div>
                            })}
                        </div>
                        <div className={classes.imageDivs}>
                            {questionsImage.map(e => {
                                return <div className={classes.imageOutMobile} style={{border: '0px',}} data-clicked="false">
                                    <div id={e.id} style={{backgroundImage: `url(${e.image})`}} className={classes.imageInMobile}>
                                        {e.id > 1 ? (
                                            <button className={classes.arrowMobile} onClick={() => this.moveImage('left', e.id + 3)} style={{transform: 'rotate(-90deg)'}}>
                                                <ArrowRightAlt />
                                            </button>
                                        ) : (
                                            <button className={classes.arrowMobile} style={{visibility: 'hidden'}}>
                                                <ArrowRightAlt />
                                            </button>
                                        )}

                                        {e.id < 3 ? (
                                            <button className={classes.arrowMobile} onClick={() => this.moveImage('right', e.id + 3)} style={{transform: 'rotate(90deg)'}}>
                                                <ArrowRightAlt />
                                            </button>
                                        ) : (
                                            <button className={classes.arrowMobile} style={{visibility: 'hidden'}}>
                                                <ArrowRightAlt />
                                            </button>
                                        )}
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                ) : (
                    // Desktop
                    <div className={classes.images}>
                        {questions.map(e => {
                            return <div className={classes.imageOut} data-clicked="false">
                                <div id={e.id} style={{backgroundImage: `url(${e.image})`}} className={classes.imageIn}>
                                    {e.id > 3 &&
                                        <div>
                                            {(e.id < 7 && e.id > 4) &&
                                            <button className={classes.arrow} onClick={() => this.moveImage('left', e.id)} style={{transform: 'scale(-1)'}}>
                                                <ArrowRightAlt />
                                            </button>}

                                            {(e.id > 3 && e.id < 6) &&
                                            <button className={classes.arrow} onClick={() => this.moveImage('right', e.id)} style={{float: 'right'}}>
                                                <ArrowRightAlt />
                                            </button>}

                                            <div className={classes.regularText}>
                                                <Typography id={'t' + e.id} className={classes.imageText}>
                                                    {e.text}
                                                </Typography>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        })}
                    </div>
                )}
                </Media>

                <div className={classes.seperator}></div>

                <Media query="(max-width: 860px)">
                {matches => matches ? (
                    // Mobile
                    <div style={{textAlign: 'center'}}>
                        <button className={classes.buttonMobile} onClick={this.handleSubmit}>
                            <div style={{float: 'left'}}>
                                <p className={classes.buttonMarkup}>{buttonText.toUpperCase()}</p>
                            </div>
                            <div style={{float: 'right'}}>
                                <p style={{marginRight: '40px'}}><ArrowRightAlt /></p>
                            </div>
                        </button>
                    </div>
                ) : (
                    <div style={{textAlign: 'right'}}>
                        <button className={questions.length > 4 ? classes.button : classes.buttonSmall} onClick={this.handleSubmit}>
                            <div style={{float: 'left'}}>
                                <p className={classes.buttonMarkup}>{buttonText.toUpperCase()}</p>
                            </div>
                            <div style={{float: 'right'}}>
                                <p style={{marginRight: '40px'}}><ArrowRightAlt /></p>
                            </div>
                        </button>
                    </div>
                )}
                </Media>
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(WhatIsWhatQuestion);
