import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { WorkformLayout, LongArrow } from "@manakin/app-core";
import { FeedbackBox } from "@manakin/app-workforms";
import classNames from "classnames";
import Button from "@material-ui/core/Button";
import DoneIcon from "@material-ui/icons/Done";
import { styles } from "./styles";
import { connect } from "react-redux";

class ImageAndTextMPC extends React.PureComponent {
  handleClick = index => {
    const done = this.props.data.done ? this.props.data.done : false;
    if (this.props.onChange && !done) this.props.onChange(index);
  };

  handleSubmit = () => {
    if (this.props.onSubmit) this.props.onSubmit();
  };

  render() {
    const {
      classes,
      data,
      onFinished,
      direction = "default",
      disabled = false,
      config = {}
    } = this.props;
    const { literals = {} } = config;
    const { answers = [] } = data;

    return (
      <WorkformLayout
        question={data.question || ""}
        direction={direction}
        instruction={
          data.introduction || literals.APP_MPC_QUESTION || "Meerkeuze vraag"
        }
        loading={false}
        renderAnswers={() => (
          <div className={classes.wrapper}>
            <div className={classes.answersRoot}>
              {answers &&
                answers.map((answer, i) => (
                  <div
                    key={answer.id}
                    className={classes.card}
                    onClick={() => this.handleClick(i)}
                  >
                    <div
                      className={classNames(classes.checked, {
                        [classes.showIcon]: answer.selected
                      })}
                    >
                      <DoneIcon classes={{ root: classes.iconRoot }} />
                    </div>
                    <div className={classes.innerCard}>
                      <div className={classes.image}>
                        <img
                          src={(answer.image && answer.image.url) || ""}
                          className={classes.backgroundImage}
                        />
                      </div>
                    </div>
                    <div className={classes.answerTitleContainer}>
                      <p className={classes.answerTitle}>{answer.title}</p>
                    </div>
                  </div>
                ))}
            </div>
            <div className={classes.buttons}>
              {!data.feedback ? (
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  className={classNames(classes.button, {
                    [classes.hide]: data.feedback || false
                  })}
                  onClick={this.handleSubmit}
                  disabled={disabled}
                >
                  {data.buttonText ||
                    literals.APP_CHECK_ANSWER ||
                    "Check mijn antwoord"}
                  <LongArrow className={classes.longArrowRoot} />
                </Button>
              ) : (
                <div
                  className={classNames(classes.feedback, {
                    [classes.activeFeedback]: data.feedback
                  })}
                >
                  <FeedbackBox
                    data={data}
                    onFinished={onFinished}
                    outlined={true}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      />
    );
  }
}

export default compose(
  connect(state => ({ config: state.config })),
  withStyles(styles, { name: "AppImageAndTextMPC" })
)(ImageAndTextMPC);
