'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GQL_FETCH_NEWS_LIST = undefined;

var _templateObject = _taggedTemplateLiteral(['\n  query($filter: NewsListFilter, $pagesize: Int, $page: Int) {\n    newsList(filter: $filter, pagesize: $pagesize, page: $page) {\n      news {\n        id\n        title\n        introText\n        category\n        factCards\n        content\n        image {\n          id\n          url\n          thumbnailUrl\n        }\n        lastModifiedOn\n        createdOn\n      }\n      count\n    }\n  }\n'], ['\n  query($filter: NewsListFilter, $pagesize: Int, $page: Int) {\n    newsList(filter: $filter, pagesize: $pagesize, page: $page) {\n      news {\n        id\n        title\n        introText\n        category\n        factCards\n        content\n        image {\n          id\n          url\n          thumbnailUrl\n        }\n        lastModifiedOn\n        createdOn\n      }\n      count\n    }\n  }\n']);

var _graphqlTag = require('graphql-tag');

var _graphqlTag2 = _interopRequireDefault(_graphqlTag);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteral(strings, raw) { return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var GQL_FETCH_NEWS_LIST = exports.GQL_FETCH_NEWS_LIST = (0, _graphqlTag2.default)(_templateObject);