"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _recompose = require("recompose");

var _styles = require("@material-ui/core/styles");

var _graphql = require("./graphql");

var _reactApollo = require("react-apollo");

var _lib = require("../lib");

var _appCore = require("@manakin/app-core");

var _Typography = require("@material-ui/core/Typography");

var _Typography2 = _interopRequireDefault(_Typography);

var _TableRow = require("@material-ui/core/TableRow");

var _TableRow2 = _interopRequireDefault(_TableRow);

var _TableCell = require("@material-ui/core/TableCell");

var _TableCell2 = _interopRequireDefault(_TableCell);

var _MailOutline = require("@material-ui/icons/MailOutline");

var _MailOutline2 = _interopRequireDefault(_MailOutline);

var _reactRedux = require("react-redux");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var styles = function styles(theme) {
  return {
    name: {
      margin: 0,
      fontWeight: "500"
    },
    content: {
      margin: 0
    },
    icon: {
      cursor: "pointer",
      transition: "color .3s",
      "&:hover": {
        color: theme.manakin.defaultContentHover[500]
      }
    }
  };
};

var Student = function (_React$PureComponent) {
  _inherits(Student, _React$PureComponent);

  function Student() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, Student);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = Student.__proto__ || Object.getPrototypeOf(Student)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
      allElements: [],
      elements: [],
      results: [],
      percentage: null,
      loadingResults: true
    }, _this.mount = function () {
      var _this$props = _this.props,
          userData = _this$props.userData,
          classData = _this$props.classData,
          client = _this$props.client;
      var product = classData.product;

      var program = product ? product.program || {} : {};
      var boxes = program ? program.boxes || [] : [];
      var allElements = [];
      var results = [];
      if (boxes.length) {
        boxes.map(function (boxContainer) {
          return boxContainer.map(function (box) {
            allElements = allElements.concat(box.elements);
            client.query({
              query: _graphql.GQL_FETCH_ELEMENT_RESULTS,
              variables: {
                box: box.id,
                program: program.id,
                user: userData.id
              }
            }).then(function (result) {
              if (result.data && result.data.elementResults) {
                results = results.concat(result.data.elementResults);
                _this.setState({
                  loadingResults: false,
                  results: results
                });
              } else {
                _this.setState({
                  loadingResults: false
                });
              }
            }).catch(function (e) {
              _this.setState({
                loadingResults: false
              });
            });
          });
        });
      } else {
        _this.setState({
          loadingResults: false
        });
      }
      _this.setState({
        allElements: allElements
      }, function () {
        return _this.getAllWorkformElements();
      });
    }, _this.getAllWorkformElements = function () {
      var allElements = _this.state.allElements;

      var _workformElements = (0, _lib.workformElements)(allElements);
      _this.setState({ elements: _workformElements });
    }, _this.handleClick = function () {
      var _this$props$userData = _this.props.userData,
          userData = _this$props$userData === undefined ? {} : _this$props$userData;

      if (_this.props.onClick) _this.props.onClick(userData);
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(Student, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      this.mount();
    }
  }, {
    key: "componentDidUpdate",
    value: function componentDidUpdate(prevProps) {
      if (prevProps.classData.id != this.props.classData.id) {
        this.mount();
      }
    }
  }, {
    key: "render",
    value: function render() {
      var _props = this.props,
          userData = _props.userData,
          classes = _props.classes,
          classData = _props.classData,
          _props$config = _props.config,
          config = _props$config === undefined ? {} : _props$config;
      var _config$pages = config.pages,
          pages = _config$pages === undefined ? {} : _config$pages;
      var _pages$rapportage = pages.rapportage,
          rapportage = _pages$rapportage === undefined ? {} : _pages$rapportage;

      var hideProgress = rapportage.hideProgress ? rapportage.hideProgress : false;

      var _state = this.state,
          elements = _state.elements,
          _results = _state.results,
          loadingResults = _state.loadingResults;


      var results = _results.filter(function (value) {
        return value.finished;
      });

      var _percentage = Math.floor(results.length / elements.length * 100);
      var percentage = _percentage > 100 ? 100 : _percentage;

      if (loadingResults) {
        return _react2.default.createElement(
          _TableRow2.default,
          null,
          _react2.default.createElement(
            _TableCell2.default,
            { component: "th", scope: "row" },
            _react2.default.createElement(_appCore.Loader, null)
          ),
          _react2.default.createElement(_TableCell2.default, { component: "th", scope: "row" }),
          !hideProgress && _react2.default.createElement(_TableCell2.default, { component: "th", scope: "row" }),
          _react2.default.createElement(_TableCell2.default, { component: "th", scope: "row" })
        );
      }
      return _react2.default.createElement(
        _TableRow2.default,
        null,
        _react2.default.createElement(
          _TableCell2.default,
          { component: "th", scope: "row" },
          _react2.default.createElement(
            _Typography2.default,
            { component: "p", variant: "body1", className: classes.name },
            userData.fullName
          )
        ),
        _react2.default.createElement(
          _TableCell2.default,
          { align: "left" },
          _react2.default.createElement(
            _Typography2.default,
            { component: "p", variant: "body1", className: classes.content },
            classData.name
          )
        ),
        !hideProgress && _react2.default.createElement(
          _TableCell2.default,
          { align: "left" },
          _react2.default.createElement(_appCore.PercentageBar, { percentage: percentage })
        ),
        _react2.default.createElement(
          _TableCell2.default,
          { align: "right" },
          _react2.default.createElement(
            "span",
            { className: classes.icon },
            _react2.default.createElement(_MailOutline2.default, { onClick: this.handleClick })
          )
        )
      );
    }
  }]);

  return Student;
}(_react2.default.PureComponent);

exports.default = (0, _recompose.compose)((0, _reactRedux.connect)(function (state) {
  return { config: state.config };
}), (0, _styles.withStyles)(styles), _reactApollo.withApollo)(Student);