"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _AccountWrapper = require("../AccountWrapper");

var _AccountWrapper2 = _interopRequireDefault(_AccountWrapper);

var _core = require("@manakin/core");

require("moment/min/locales");

var _Button = require("@material-ui/core/Button");

var _Button2 = _interopRequireDefault(_Button);

var _styles = require("@material-ui/core/styles");

var _Grid = require("@material-ui/core/Grid");

var _Grid2 = _interopRequireDefault(_Grid);

var _graphql = require("../graphql");

var _reactApollo = require("react-apollo");

var _recompose = require("recompose");

var _actions = require("@manakin/core/actions");

var _reactRedux = require("react-redux");

var _actions2 = require("@manakin/app-core/actions");

var _reduxFirstHistory = require("redux-first-history");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var styles = function styles(theme) {
  return {
    root: {
      height: "100vh",
      backgroundColor: theme.manakin.primaryColor
    },
    passwordFields: {
      margin: "3rem 0 6rem 0"
    },
    actionFields: {
      margin: "3rem 0 6rem 0"
    },
    productLabel: {
      textAlign: "left"
    }
  };
};

var formBundle = {
  oldPassword: {
    format: "password",
    required: true
  },
  password: {
    format: "password",
    required: true
  },
  repeatPassword: {
    format: "password",
    required: true
  }
};

var ChangePassword = function (_React$PureComponent) {
  _inherits(ChangePassword, _React$PureComponent);

  function ChangePassword() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, ChangePassword);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = ChangePassword.__proto__ || Object.getPrototypeOf(ChangePassword)).call.apply(_ref, [this].concat(args))), _this), _this.handleGoBack = function () {
      var goBackToProfilePage = _this.props.goBackToProfilePage;

      goBackToProfilePage();
    }, _this.handleChangePassword = function () {
      var _this$props = _this.props,
          form = _this$props.form,
          onPasswordChange = _this$props.onPasswordChange,
          processChangeError = _this$props.processChangeError,
          processPasswordsDoNotMatch = _this$props.processPasswordsDoNotMatch,
          _this$props$config = _this$props.config,
          config = _this$props$config === undefined ? {} : _this$props$config;
      var _config$literals = config.literals,
          literals = _config$literals === undefined ? {} : _config$literals;


      if (form.fields.password != form.fields.repeatPassword) {
        processPasswordsDoNotMatch(literals);
      } else {
        form.onValidate().then(function () {
          return form.onSubmit().then(function (data) {
            if (data) onPasswordChange({
              oldPassword: data.oldPassword,
              password: data.password
            });
          });
        }, function () {
          return processChangeError(literals);
        });
      }
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(ChangePassword, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      var onHideAppBar = this.props.onHideAppBar;

      onHideAppBar({ path: this.props.location.pathname });
    }
  }, {
    key: "render",
    value: function render() {
      var _props = this.props,
          classes = _props.classes,
          form = _props.form,
          _props$config = _props.config,
          config = _props$config === undefined ? {} : _props$config;
      var _config$literals2 = config.literals,
          literals = _config$literals2 === undefined ? {} : _config$literals2;

      var fieldProps = { xs: 12, sm: 6, md: 6, lg: 6, xl: 6 };

      return _react2.default.createElement(
        "div",
        { className: classes.root },
        _react2.default.createElement(
          _AccountWrapper2.default,
          {
            title: literals.APP_CHANGE_PASSWORD || "Wachtwoord wijzigen",
            introduction: literals.APP_CHANGE_PASSWORD_INTRO || "Wijzig hier je wachtwoord. Vul eerst je huidige wachtwoord in."
          },
          _react2.default.createElement(
            _Grid2.default,
            {
              container: true,
              spacing: 2,
              alignItems: "center",
              classes: {
                container: classes.passwordFields
              }
            },
            _react2.default.createElement(
              _Grid2.default,
              _extends({ item: true }, fieldProps),
              _react2.default.createElement(_core.PasswordField, {
                name: "oldPassword",
                label: literals.APP_CURRENT_PASSWORD || "Huidig wachtwoord",
                shrink: true,
                placeholder: literals.APP_ENTER_CURRENT_PASSWORD || "Voer uw huidige wachtwoord in",
                form: form
              })
            ),
            _react2.default.createElement(
              _Grid2.default,
              _extends({ item: true }, fieldProps),
              _react2.default.createElement(_core.PasswordField, {
                name: "password",
                label: literals.APP_NEW_PASSWORD || "Nieuw wachtwoord",
                shrink: true,
                placeholder: literals.APP_ENTER_NEW_PASSWORD || "Voer een nieuw wachtwoord in",
                form: form
              })
            ),
            _react2.default.createElement(_Grid2.default, _extends({ item: true }, fieldProps)),
            _react2.default.createElement(
              _Grid2.default,
              _extends({ item: true }, fieldProps),
              _react2.default.createElement(_core.PasswordField, {
                name: "repeatPassword",
                label: literals.REPEAT_NEW_PASSWORD || "Herhaal nieuw wachtwoord",
                shrink: true,
                placeholder: literals.REPEAT_NEW_PASSWORD || "Herhaal het nieuwe wachtwoord",
                form: form
              })
            )
          ),
          _react2.default.createElement(
            _Grid2.default,
            {
              container: true,
              spacing: 2,
              alignItems: "center",
              classes: {
                container: classes.actionFields
              }
            },
            _react2.default.createElement(
              _Grid2.default,
              _extends({ item: true }, fieldProps),
              _react2.default.createElement(
                _Button2.default,
                {
                  className: classes.button,
                  color: "primary",
                  onClick: this.handleGoBack
                },
                literals.APP_BACK_TO_PROFILE || "Terug naar profiel"
              )
            ),
            _react2.default.createElement(
              _Grid2.default,
              _extends({ item: true }, fieldProps),
              _react2.default.createElement(
                _Button2.default,
                {
                  className: classes.button,
                  variant: "contained",
                  color: "primary",
                  onClick: this.handleChangePassword
                },
                literals.APP_SAVE_PASSWORD || "Wachtwoord opslaan"
              )
            )
          )
        )
      );
    }
  }]);

  return ChangePassword;
}(_react2.default.PureComponent);

exports.default = (0, _recompose.compose)((0, _core.withForm)(formBundle), (0, _styles.withStyles)(styles), (0, _reactApollo.graphql)(_graphql.GQL_CHANGE_APP_USER_PASSWORD), (0, _reactRedux.connect)(function (state) {
  return { config: state.config };
}, function (dispatch) {
  return {
    processSuccess: function processSuccess(literals) {
      dispatch((0, _actions.showSnackbarMessage)({
        text: literals.APP_PASSWORD_SAVED || "Wachtwoord gewijzigd",
        variant: "success"
      }));
    },
    processFailure: function processFailure(literals) {
      dispatch((0, _actions.showSnackbarMessage)({
        text: literals.APP_PASSWORD_SAVE_FAIL || "Wachtwoord wijzigen mislukt",
        variant: "error"
      }));
    },
    processChangeError: function processChangeError(literals) {
      return dispatch((0, _actions.showSnackbarMessage)({
        text: literals.APP_FILL_ALL_FIELDS || "Vul a.u.b. alle velden in",
        variant: "error"
      }));
    },
    processPasswordsDoNotMatch: function processPasswordsDoNotMatch(literals) {
      return dispatch((0, _actions.showSnackbarMessage)({
        text: literals.APP_NO_MATCH_PASSWORD || "Nieuwe wachtwoorden komen niet overeen",
        variant: "error"
      }));
    },
    onHideAppBar: function onHideAppBar(data) {
      return dispatch((0, _actions2.hideAppBar)(data));
    },
    goBackToProfilePage: function goBackToProfilePage() {
      return dispatch((0, _reduxFirstHistory.push)("/account"));
    }
  };
}), (0, _recompose.withHandlers)({
  onPasswordChange: function onPasswordChange(_ref2) {
    var mutate = _ref2.mutate,
        processSuccess = _ref2.processSuccess,
        processFailure = _ref2.processFailure,
        _ref2$config = _ref2.config,
        config = _ref2$config === undefined ? {} : _ref2$config;
    return function (event) {
      return mutate({
        variables: _extends({}, event)
      }).then(function (result) {
        var _config$literals3 = config.literals,
            literals = _config$literals3 === undefined ? {} : _config$literals3;

        if (!result.errors) {
          processSuccess(literals);
        } else {
          processFailure(literals);
        }
      });
    };
  }
}))(ChangePassword);