'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _selectors = require('@manakin/authentication/selectors');

var _actions = require('./actions');

var _styles = require('@material-ui/core/styles');

var _reactRedux = require('react-redux');

var _recompose = require('recompose');

var _FormControl = require('@material-ui/core/FormControl');

var _FormControl2 = _interopRequireDefault(_FormControl);

var _Select = require('@material-ui/core/Select');

var _Select2 = _interopRequireDefault(_Select);

var _MenuItem = require('@material-ui/core/MenuItem');

var _MenuItem2 = _interopRequireDefault(_MenuItem);

var _selectors2 = require('./selectors');

var _reactApollo = require('react-apollo');

var _icons = require('@material-ui/icons');

var _graphql = require('./graphql');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var styles = function styles(theme) {
  return {
    selectField: {
      width: '200px'
    }
  };
};

var GroupsDropdown = function (_React$PureComponent) {
  _inherits(GroupsDropdown, _React$PureComponent);

  function GroupsDropdown() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, GroupsDropdown);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = GroupsDropdown.__proto__ || Object.getPrototypeOf(GroupsDropdown)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
      options: [],
      currentGroup: ''
    }, _this.fetchSchool = function (item) {
      return _this.props.client.query({
        query: _graphql.GQL_FETCH_SCHOOL,
        variables: { id: item.id }
      });
    }, _this.handleChange = function (event) {
      _this.setState({
        currentGroup: event.target.value
      }, function () {
        _this.props.selectGroup(event.target.value);
      });
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(GroupsDropdown, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      var group = this.props.group;

      if (group) this.setState({ currentGroup: group });
    }
  }, {
    key: 'componentDidUpdate',
    value: function componentDidUpdate(prevProps) {
      var _this2 = this;

      if (prevProps.data.loading && !this.props.data.loading) {
        var _props = this.props,
            group = _props.group,
            data = _props.data,
            appUser = _props.appUser;

        var currentGroup = group;
        data.groups && data.groups.groups.forEach(function (item) {
          _this2.fetchSchool(item).then(function (result) {
            result.data.school && result.data.school.classes.forEach(function (item) {
              var teacher = true;
              var show = true;
              appUser.roles.forEach(function (role) {
                if (role.name === 'SCHOOL_MANAGER') teacher = false;
              });

              if (teacher) {
                show = false;
                if (item.teachers.length) {
                  item.teachers.forEach(function (teacher) {
                    if (teacher.id === appUser.id) show = true;
                  });
                }
              }

              if (show) {
                _this2.setState(function (prevState) {
                  return {
                    options: [].concat(_toConsumableArray(prevState.options), [_extends({}, item, {
                      selected: item.id == group ? true : false,
                      label: item.name + ' - ' + (item.product && item.product.name || 'Geen product gekoppeld')
                    })])
                  };
                }, function () {
                  _this2.setState(function (prevState) {
                    return {
                      currentGroup: currentGroup ? currentGroup : prevState.options[0].id
                    };
                  });
                  _this2.props.selectGroup(currentGroup ? currentGroup : _this2.state.options[0].id);
                });
              }
            });
          });
        });
      }
    }
  }, {
    key: 'render',
    value: function render() {
      var _props2 = this.props,
          classes = _props2.classes,
          inputProps = _props2.inputProps;
      var _state = this.state,
          options = _state.options,
          currentGroup = _state.currentGroup;


      return _react2.default.createElement(
        _FormControl2.default,
        { className: classes.formControl },
        _react2.default.createElement(
          _Select2.default,
          {
            value: currentGroup,
            onChange: this.handleChange,
            name: 'program',
            className: classes.selectEmpty,
            inputProps: inputProps,
            IconComponent: _icons.KeyboardArrowDown
          },
          options && options.map(function (item) {
            return _react2.default.createElement(
              _MenuItem2.default,
              { selected: item.selected, key: item.id, value: item.id },
              item.label
            );
          })
        )
      );
    }
  }]);

  return GroupsDropdown;
}(_react2.default.PureComponent);

exports.default = (0, _recompose.compose)(_reactApollo.withApollo, (0, _reactRedux.connect)(function (state) {
  return {
    appUser: (0, _selectors.getAppUser)(state),
    group: (0, _selectors2.getGroup)(state)
  };
}, function (dispatch) {
  return {
    selectGroup: function selectGroup(group) {
      return dispatch((0, _actions.selectGroup)(group));
    }
  };
}), (0, _reactApollo.graphql)(_graphql.GQL_FETCH_GROUPS, {
  options: function options(props) {
    return {
      variables: {
        filter: {
          manager: props.appUser.id,
          types: ['School']
        }
      }
    };
  }
}), (0, _styles.withStyles)(styles))(GroupsDropdown);