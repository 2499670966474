import logo from "../assets/logo.svg";
const defaultLang = "en_US";

function getLanguage(lang = defaultLang) {
  return import(`../i18n/${lang}.json`);
}

export default async props =>
  await new Promise(async resolve => {
    const literals = await getLanguage(props || undefined).then(lang => lang);

    const config = {
      whiteLabel: "Driscolls",
      defaultLang: defaultLang,
      files: [ 
        {
          id: "testfile",
          file: logo
        }
      ],
      pages: {
        appDashboard: {
          showName: false
        },
        appLogin: {
          showHeaderTitle: true,
          headerTitleIsHtml: false,
          showFootNote: false,
          visualTitle: true,
          footerLogo: false,
          showNews: false
        },
        appRegistration: {
          showBirthDate: true,
          showPhoneNumber: true,
          showRegisterType: false,
          showRegisterCode: false,
          registerCodeRequired: false,
          addMoreLicenses: true
        },
        appNoLicense: {
          showFootNote: false
        },
        appAccount: {
          showMenu: false,
          showEducation: false,
          hidePassword: true,
          showLanguageSelect: true,
          hideDelete: true
        },
        appElementOverlay: {
          showHeaderTitle: true
        },
        boxCard: {
          showArrow: true
        }
      },
      general: {
        showProgramDropdown: false,
        showLogo: true,
        showMenuLabel: true,
        hideLogout: true,
        hideDelete: true,
        sso: true
      },
      literals: literals,
      getFile: id => config.files.find(file => file.id === id),
      getPage: key => config[key]
    };

    resolve(config);
  });
