import React from "react";
import { styles } from "./styles";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import renderHTML from "react-render-html";
import { ChatBubble } from "@manakin/app-workforms";
import { WorkformViewer } from "@manakin/app-core";
import scrollToComponent from "react-scroll-to-component";
import { connect } from "react-redux";
import { compose } from "recompose";
import Button from "@material-ui/core/Button";
import { withRouter } from "react-router-dom";

const mapStateToProps = state => ({
  config: state.config
});

class Content extends React.PureComponent {
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.step != this.props.step) {
      const { step, workforms } = this.props;
      if (workforms[step].type != "ChatBubble") {
        setTimeout(() => {
          scrollToComponent(this.question, {
            offset: 0,
            align: "top",
            duration: 500
          });
        }, 500);
      }
    }
    if (!prevProps.closeChatBubble && this.props.closeChatBubble) {
      setTimeout(() => {
        scrollToComponent(this.question, {
          offset: 0,
          align: "top",
          duration: 500
        });
      }, 100);
    }
  }

  handleNext = data => {
    this.props.onNext(data);
  };

  render() {
    const {
      classes,
      characteristic = [],
      workforms = [],
      step,
      options = {},
      config,
      closeChatBubble
    } = this.props;
    const { literals = {} } = config;

    return (
      <div className={classes.root}>
        <div
          className={classes.topRoot}
          ref={section => {
            this.Root = section;
          }}
        >
          <div className={classes.left}>
            <div className={classes.content}>
              <div className={classes.chatBubble}>
                <div className={classes.chatBubbles}>
                  {workforms.map((item, idx) => (
                    <div key={item.id}>
                      {item.type == "ChatBubble" && (
                        <div>
                          <ChatBubble
                            data={item}
                            animate={step === idx}
                            show={step > idx}
                            onNext={this.handleNext}
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className={classes.sidebar}>
            <Typography variant="h6" classes={{ h6: classes.title }}>
              {literals.APP_CASE_FEATURES || "Kenmerken"}
            </Typography>
            <ul className={classes.list}>
              {characteristic &&
                characteristic.map((item, idx) => (
                  <li key={idx}>
                    <Typography
                      component="div"
                      variant="body1"
                      classes={{ body1: classes.body }}
                    >
                      {renderHTML(item)}
                    </Typography>
                  </li>
                ))}
            </ul>
          </div>
        </div>
        {workforms.map((item, idx) => (
          <div
            ref={section => {
              this.question = section;
            }}
            key={item.id}
          >
            {step == idx &&
              item.type != "ChatBubble" &&
              item.type != "FactCardReferral" && (
                <div className={classes.question}>
                  <WorkformViewer
                    type={item.type}
                    item={item}
                    onFinish={this.handleNext}
                    options={options}
                    withNext={true}
                  />
                </div>
              )}
            {step == idx && item.type === "FactCardReferral" && (
              <div className={classes.question}>
                <WorkformViewer
                  type={item.type}
                  item={item}
                  onFinish={this.handleNext}
                  options={options}
                  withNext={true}
                  customLink={{
                    withId: true,
                    state: {
                      url: this.props.location.pathname,
                      idx: step,
                      characteristic
                    }
                  }}
                />
              </div>
            )}
          </div>
        ))}
        {closeChatBubble && (
          <div className={classes.question}>
            <Button
              variant="contained"
              color="primary"
              aria-label="Close"
              className={classes.closeButton}
              onClick={this.props.onClose}
            >
              Afsluiten
            </Button>
          </div>
        )}
      </div>
    );
  }
}

export default compose(
  withRouter,
  withStyles(styles, { name: "AppCaseContent" }),
  connect(mapStateToProps)
)(Content);
