import React from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import MoveIcon from '@material-ui/icons/OpenWith';
import { compose } from 'recompose';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  root: {
    padding: theme.spacing(2)
  },
  items: {
    flexGrow: 1,
    marginTop: theme.spacing(1)
  },
  item: {
    width: '100%',
    border: '1px solid #E7E8EE',
    backgroundColor: '#FFFFFF',
    cursor: 'pointer',
    borderRadius: 5,
    boxSizing: 'border-box'
  },
  card: {
    backgroundColor: '#FFECC2',
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
    flexGrow: 1,
    display: 'flex',
    borderRadius: 4,
    cursor: 'move'
  },
  createRoadmapItem: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(3),
    backgroundColor: '#FFFFFF',
    border: '1px dashed #CCC',
    cursor: 'pointer'
  },
  title: {
    flexGrow: 1,
    fontWeight: 400,
    lineHeight: '30px',
    overflow: 'hidden',
    wordBreak: 'break-word',
    height: 30
  },
  header: {
    display: 'flex',
    padding: theme.spacing(1),
    paddingLeft: 0,
    width: '100%'
  },
  actionIcons: {
    display: 'flex'
  },
  icon: {
    alignSelf: 'center',
    cursor: 'pointer',
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(1),
    color: '#47525E',
    verticalAlign: 'middle'
  },
  moveIcon: {
    cursor: 'move !important'
  },
  expansionPanel: {
    background: 'none',
    boxShadow: 'none'
  },
  expansionPanelSummary: {
    margin: 0,
    borderBottom: 'none',
    borderRadius: 4,
    padding: 0
  },
  expansionPanelSummaryContainer: {
    padding: theme.spacing(2)
  },
  expansionPanelSummaryExpanded: {
    minHeight: '0 !important',
    borderRadius: '4px 4px 0px 0px'
  },
  expansionPanelSummaryContent: {
    margin: '0 !important'
  },
  expansionPanelDetails: {
    padding: `0 ${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(2)}px`,
    width: '100%',
    display: 'block',
    boxSizing: 'border-box'
  },
  dragging: {
    opacity: 0
  }
});

const formBundle = {
  title: {
    required: true,
    minLength: 1
  }
};

class DragableExpansionPanel extends React.PureComponent {
  state = {
    expanded: false
  };

  componentDidMount() {
    const { item } = this.props;
    if (item.title || item.name || item.question || item.chatText) {
      this.setState({ expanded: false });
    } else {
      this.setState({ expanded: true });
    }
  }

  handleExpansion = event => {
    this.setState(oldState => ({
      expanded: !oldState.expanded
    }));
  };

  handleDeleteStep = (event, index) => {
    this.props.onDeleteItem(index);
  };

  render() {
    const {
      children,
      classes,
      item,
      index,
      accentColor,
      isDragging,
      readOnly = false
    } = this.props;
    const { expanded } = this.state;
    return (
      <div
        className={classNames(classes.item, { [classes.dragging]: isDragging })}
      >
        <ExpansionPanel
          expanded={expanded}
          className={classes.expansionPanel}
          onChange={this.handleExpansion}
        >
          <ExpansionPanelSummary
            className={classes.expansionPanelSummary}
            classes={{
              content: classes.expansionPanelSummaryContent,
              expanded: classes.expansionPanelSummaryExpanded
            }}
            style={{
              ...(accentColor && {
                backgroundColor:
                  (accentColor.length === 6 ? '#' : '') + accentColor
              })
            }}
            expandIcon={<ExpandMoreIcon />}
          >
            <div
              className={classNames(
                classes.header,
                classes.expansionPanelSummaryContainer
              )}
            >
              <Typography className={classes.title} variant="h6">
                {index + 1} {item.title || item.name || item.question || ''}
              </Typography>
              <div className={classes.actionIcons}>
                {!readOnly && (
                  <div className={classNames(classes.icon, classes.moveIcon)}>
                    <MoveIcon
                      className={classNames(classes.icon, classes.moveIcon)}
                    />
                    <DeleteIcon
                      onClick={event => this.handleDeleteStep(event, index)}
                      className={classes.icon}
                    />
                  </div>
                )}
              </div>
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.expansionPanelDetails}>
            {children}
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    );
  }
}

export default compose(withStyles(styles))(DragableExpansionPanel);
