export const styles = theme => ({
  topRoot: {
    [theme.breakpoints.up('md')]: {
      width: '100%',
      display: 'flex'
    }
  },
  left: {
    [theme.breakpoints.up('md')]: {
      width: 'calc(100% - 280px)'
    },
    [theme.breakpoints.up('lg')]: {
      width: 'calc(100% - 350px)'
    }
  },
  chatBubble: {
    width: '100%',
    maxWidth: '860px'
  },
  chatBubbles: {
    padding: '7rem 0 4rem',
    [theme.breakpoints.up('md')]: {
      padding: '10rem 0'
    },
    [theme.breakpoints.up('lg')]: {
      padding: '11.6rem 0px'
    }
  },
  question: {
    paddingTop: '2rem',
    paddingBottom: '2rem',
    borderTop: `1px solid ${theme.manakin.defaultBorderColor[500]}`,
    [theme.breakpoints.up('md')]: {
      ...theme.manakin.defaultWrapper,
      padding: '5rem'
    },
    [theme.breakpoints.up('lg')]: {
      borderColor: 'transparent',
      padding: '13rem 5rem'
    }
  },
  content: {
    width: '100%',
    height: 'auto',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'center'
    }
  },
  sidebar: {
    display: 'none',
    padding: '4rem',
    display: 'block',
    width: '280px',
    backgroundColor: theme.manakin.secondaryColor[300],
    [theme.breakpoints.up('md')]: {
      display: 'block'
    },
    [theme.breakpoints.up('lg')]: {
      width: '400px'
    }
  },
  body: {
    margin: 0,
    '@global ul': {
      paddingLeft: '2.25rem'
    }
  },
  hide: {
    display: 'none'
  },
  title: {
    fontFamily: theme.manakin.defaultTitleFont,
    fontSize: '1.4rem',
    letterSpacing: '.4rem',
    lineHeight: '3.2rem'
  },
  list: {
    paddingLeft: '2rem'
  }
});
