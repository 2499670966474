'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _recompose = require('recompose');

var _reactApollo = require('react-apollo');

var _graphql = require('./graphql');

var _AccountWrapper = require('../AccountWrapper');

var _AccountWrapper2 = _interopRequireDefault(_AccountWrapper);

var _reactRedux = require('react-redux');

var _styles = require('@material-ui/core/styles');

var _NewsItem = require('./NewsItem');

var _NewsItem2 = _interopRequireDefault(_NewsItem);

var _core = require('@manakin/core');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var styles = function styles(theme) {
  return {};
};

var NewsContainer = function NewsContainer(props) {
  var classes = props.classes,
      _props$config = props.config,
      config = _props$config === undefined ? {} : _props$config,
      _props$data = props.data,
      data = _props$data === undefined ? {} : _props$data;
  var _data$loading = data.loading,
      loading = _data$loading === undefined ? true : _data$loading,
      _data$newsList = data.newsList,
      newsList = _data$newsList === undefined ? {} : _data$newsList;

  var literals = config.literals || {};

  return _react2.default.createElement(
    _AccountWrapper2.default,
    {
      title: literals.APP_NEWS_ACCOUNT_TITLE || 'Nieuws berichten',
      introduction: literals.APP_NEWS_ACCOUNT_INTRO || '',
      wrapper: 'medium'
    },
    _react2.default.createElement(
      'div',
      { className: classes.root },
      !loading ? _react2.default.createElement(
        'div',
        null,
        newsList.news && newsList.news.map(function (news) {
          return _react2.default.createElement(
            'div',
            { key: news.id },
            _react2.default.createElement(_NewsItem2.default, news)
          );
        })
      ) : _react2.default.createElement(_core.Loader, null)
    )
  );
};

exports.default = (0, _recompose.compose)((0, _styles.withStyles)(styles), (0, _reactApollo.graphql)(_graphql.GQL_FETCH_NEWS_LIST, {
  options: function options() {
    return {
      variables: {
        page: 0,
        pagesize: 99,
        filter: {
          category: ['URGENT']
        }
      }
    };
  }
}), (0, _reactRedux.connect)(function (state) {
  return {
    config: state.config
  };
}))(NewsContainer);