import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { LoadImage } from '@manakin/app-core';
import { compose } from 'recompose';
import { connect } from 'react-redux';

const styles = theme => ({
  root: {
    padding: '84px 30px',
    minHeight: 'calc(100vh - 64px)',
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      height: 'calc(100vh - 100px)',
      minHeight: '100%',
      display: 'flex',
      padding: '0',
      alignItems: 'center',
      width: '100%'
    },
    [theme.breakpoints.up('lg')]: {
      justifyContent: 'space-between'
    }
  },
  content: {
    position: 'relative',
    zIndex: '1',
    [theme.breakpoints.up('md')]: {
      padding: '20px 20px 20px 80px',
      width: '70%'
    },
    [theme.breakpoints.up('lg')]: {
      padding: '20px 20px 20px 130px',
      maxWidth: '600px'
    }
  },
  bgImage: {
    position: 'absolute',
    width: '50vw',
    bottom: 0,
    right: 0,
    [theme.breakpoints.up('md')]: {
      position: 'relative',
      width: '40%',
      height: '100%',
      display: 'flex',
      alignItems: 'center'
    },
    '& img': {
      flex: '1'
    }
  },
  buttonsContainer: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row'
    }
  },
  link: {
    textDecoration: 'none'
  },
  button: {
    backgroundColor: 'white',
    [theme.breakpoints.up('md')]: {
      width: 'auto!important'
    }
  },
  done: {
    backgroundColor: theme.palette.secondary[500],
    textAlign: 'center',
    padding: '17px 20px',
    borderRadius: '4rem',
    margin: '0 2rem 0 0',
    [theme.breakpoints.up('md')]: {
      width: 'auto'
    }
  },
  doneTitle: {
    lineHeight: '3.2rem',
    marginBottom: 0
  }
});

class Outro extends React.PureComponent {
  handleClick = () => {
    this.props.onClick(true);
  };

  useWhiteOrBlackTextColor = hex => {
    if (hex && hex.length >= 7) {
      const hexToRGB = hex => [
        ('0x' + hex[1] + hex[2]) | 0,
        ('0x' + hex[3] + hex[4]) | 0,
        ('0x' + hex[5] + hex[6]) | 0
      ];
      const rgb = hexToRGB(hex);
      return (rgb[0] * 299 + rgb[1] * 587 + rgb[1] * 114) / 1000 > 125
        ? '#000000'
        : '#FFFFFF';
    } else {
      return '#FFFFFF';
    }
  };

  render() {
    const { classes, data, config = {} } = this.props;
    const image = data.image ? data.image.url : null;
    const placeholder = data.image ? data.image.thumbnailUrl : null;
    const doneColor = this.useWhiteOrBlackTextColor(data.backgroundColor);

    return (
      <div className={classes.root}>
        <div className={classes.content}>
          <Typography component="h1" variant="h2">
            {config.literals && config.literals.APP_BOOKCHECK_OUTRO_TITLE}
          </Typography>
          <Typography component="p" variant="body2">
            {config.literals && config.literals.APP_BOOKCHECK_OUTRO_CONTENT}
          </Typography>
          <div className={classes.buttonsContainer}>
            <div className={classes.done}>
              <Typography
                style={{ color: doneColor }}
                className={classes.doneTitle}
                align="center"
                variant="body1"
              >
                {config.literals &&
                  config.literals.APP_BOOKCHECK_OUTRO_DONE_TITLE}
              </Typography>
            </div>

            <Button
              variant="outlined"
              color="primary"
              fullWidth
              className={classes.button}
              onClick={this.handleClick}
            >
              {config.literals &&
                config.literals.APP_BOOKCHECK_OUTRO_REDO_TITLE}
            </Button>
          </div>
        </div>
        {image && (
          <div className={classes.bgImage}>
            <LoadImage src={image} placeholder={placeholder} />
          </div>
        )}
      </div>
    );
  }
}

export default compose(
  withStyles(styles, { name: 'AppBookCheckOutro' }),
  connect(({ config }) => ({
    config
  }))
)(Outro);
