"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _styles = require("@material-ui/core/styles");

var _Typography = require("@material-ui/core/Typography");

var _Typography2 = _interopRequireDefault(_Typography);

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

var _appCore = require("@manakin/app-core");

var _TableCell = require("@material-ui/core/TableCell");

var _TableCell2 = _interopRequireDefault(_TableCell);

var _TableRow = require("@material-ui/core/TableRow");

var _TableRow2 = _interopRequireDefault(_TableRow);

var _actions = require("@manakin/core/actions");

var _moment = require("moment");

var _moment2 = _interopRequireDefault(_moment);

var _reactRedux = require("react-redux");

var _recompose = require("recompose");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var styles = function styles(theme) {
  return {
    root: {
      marginBottom: "9rem"
    },
    title: {
      textAlign: "center",
      "&:after": {
        left: "50%",
        transform: "translateX(-50%)"
      }
    },
    tableStyle: {
      margin: 0
    },
    link: {
      color: theme.manakin.secondaryColor[500],
      cursor: "pointer"
    },
    name: _defineProperty({}, theme.breakpoints.up("md"), {
      width: "350px"
    }),
    noResults: {
      textAlign: "center"
    },
    head: _defineProperty({
      display: "none"
    }, theme.breakpoints.up("md"), {
      display: "table-header-group"
    }),
    row: _defineProperty({
      display: "block",
      margin: "0 auto 50px",
      width: "260px"
    }, theme.breakpoints.up("md"), {
      display: "table-row",
      width: "auto",
      margin: 0
    }),
    tableCell: _defineProperty({
      padding: "4px 0",
      display: "block"
    }, theme.breakpoints.up("md"), {
      display: "table-cell",
      padding: "4px 16px"
    })
  };
};

var YearContent = function YearContent(props) {
  var classes = props.classes,
      progressArray = props.progressArray,
      drogistProgressArray = props.drogistProgressArray,
      index = props.index;

  var pa = [].concat(_toConsumableArray(progressArray));
  var progress = pa.splice(index * 5, 5);

  return _react2.default.createElement(
    "div",
    { className: classes.root },
    _react2.default.createElement(
      "div",
      { className: classes.heading },
      _react2.default.createElement(
        _Typography2.default,
        {
          variant: "h3",
          className: (0, _classnames2.default)(classes.title, "smallUnderline")
        },
        props.year || ""
      )
    ),
    _react2.default.createElement(
      "div",
      { className: classes.tableRoot },
      drogistProgressArray.length || progress.length ? _react2.default.createElement(_appCore.SimpleTable, {
        className: classes.head,
        renderHead: function renderHead() {
          return _react2.default.createElement(
            _react2.default.Fragment,
            null,
            _react2.default.createElement(
              _TableCell2.default,
              { className: classes.tableCell, align: "left" },
              "Datum"
            ),
            _react2.default.createElement(
              _TableCell2.default,
              { className: classes.tableCell, align: "left" },
              "Magazine"
            ),
            _react2.default.createElement(
              _TableCell2.default,
              { className: classes.tableCell, align: "left" },
              "Voortgang"
            ),
            _react2.default.createElement(
              _TableCell2.default,
              { className: classes.tableCell, align: "left" },
              "Download"
            )
          );
        },
        renderBody: function renderBody() {
          return _react2.default.createElement(
            _react2.default.Fragment,
            null,
            drogistProgressArray && drogistProgressArray.map(function (item) {
              return _react2.default.createElement(
                _react2.default.Fragment,
                { key: item.id },
                parseFloat(item.text) === parseFloat(props.year) && _react2.default.createElement(
                  _TableRow2.default,
                  { className: classes.row, key: item.id },
                  _react2.default.createElement(
                    _TableCell2.default,
                    { className: classes.tableCell, align: "left" },
                    _react2.default.createElement(
                      _Typography2.default,
                      {
                        variant: "body1",
                        className: classes.tableStyle
                      },
                      (0, _moment2.default)(item.finishedOn).format("DD-MM-YYYY")
                    )
                  ),
                  _react2.default.createElement(
                    _TableCell2.default,
                    { className: classes.tableCell, align: "left" },
                    _react2.default.createElement(
                      _Typography2.default,
                      {
                        variant: "body1",
                        className: (0, _classnames2.default)(classes.tableStyle, classes.name)
                      },
                      item.box.name
                    )
                  ),
                  _react2.default.createElement(
                    _TableCell2.default,
                    { className: classes.tableCell, align: "left" },
                    _react2.default.createElement(
                      _Typography2.default,
                      {
                        variant: "body1",
                        className: classes.tableStyle
                      },
                      "100%"
                    )
                  ),
                  _react2.default.createElement(
                    _TableCell2.default,
                    { className: classes.tableCell, align: "left" },
                    _react2.default.createElement(
                      _Typography2.default,
                      {
                        variant: "body1",
                        className: (0, _classnames2.default)(classes.tableStyle, classes.link),
                        component: "div"
                      },
                      item.certificatePdfUrl && _react2.default.createElement(
                        "a",
                        {
                          className: classes.certificateUrl,
                          href: item.certificatePdfUrl,
                          target: "_blank"
                        },
                        "Download certificaat"
                      )
                    )
                  )
                )
              );
            }),
            progress && progress.map(function (item) {
              return _react2.default.createElement(
                _TableRow2.default,
                { className: classes.row, key: item.id },
                _react2.default.createElement(
                  _TableCell2.default,
                  { className: classes.tableCell, alitn: "left" },
                  _react2.default.createElement(
                    _Typography2.default,
                    {
                      variant: "body1",
                      className: classes.tableStyle
                    },
                    (0, _moment2.default)(item.finishedOn).format("DD-MM-YYYY")
                  )
                ),
                _react2.default.createElement(
                  _TableCell2.default,
                  { className: classes.tableCell, align: "left" },
                  _react2.default.createElement(
                    _Typography2.default,
                    {
                      variant: "body1",
                      className: (0, _classnames2.default)(classes.tableStyle, classes.name)
                    },
                    item.box.name
                  )
                ),
                _react2.default.createElement(
                  _TableCell2.default,
                  { className: classes.tableCell, align: "left" },
                  _react2.default.createElement(
                    _Typography2.default,
                    {
                      variant: "body1",
                      className: classes.tableStyle
                    },
                    "100%"
                  )
                ),
                _react2.default.createElement(
                  _TableCell2.default,
                  { className: classes.tableCell, align: "left" },
                  _react2.default.createElement(
                    _Typography2.default,
                    {
                      variant: "body1",
                      className: (0, _classnames2.default)(classes.tableStyle, classes.link),
                      component: "div"
                    },
                    item.certificatePdfUrl && _react2.default.createElement(
                      "a",
                      {
                        className: classes.certificateUrl,
                        href: item.certificatePdfUrl,
                        target: "_blank"
                      },
                      "Download certificaat"
                    )
                  )
                )
              );
            })
          );
        }
      }) : _react2.default.createElement(
        _Typography2.default,
        { variant: "body1", className: classes.noResults },
        "Nog geen resultaten"
      )
    )
  );
};

exports.default = (0, _recompose.compose)((0, _reactRedux.connect)(null, function (dispatch) {
  return {
    onDownloadError: function onDownloadError() {
      return dispatch((0, _actions.showSnackbarMessage)({
        text: "Er ging iets mis bij het ophalen van de licentie.",
        variant: "error"
      }));
    }
  };
}), (0, _styles.withStyles)(styles))(YearContent);