import React from "react";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import SelectedIcon from "@material-ui/icons/CheckCircle";
import { graphql, Query, Mutation } from "react-apollo";
import { compose } from "recompose";
import AssetsViewerItemBar from "./AssetsViewerItemBar";
import Grid from "@material-ui/core/Grid";
import TablePagination from "@material-ui/core/TablePagination";
import { GQL_FETCH_CMS_ASSETS } from "./graphql";
import Typography from "@material-ui/core/Typography";
import AssetViewerSearchBar from "./AssetViewerSearchBar";

const styles = theme => ({
  root: {},
  tile: {
    height: 200,
    display: "flex",
    flexDirection: "column"
  },
  assetImage: {
    cursor: "pointer",
    width: "100%",
    height: "100%",
    backgroundSize: "cover",
    backgroundPosition: "center",
    borderRadius: "4px",
    position: "relative"
  },
  icon: {},
  progress: {
    margin: `${theme.spacing(4)}px auto`,
    display: "block"
  },
  pagination: {
    marginTop: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    float: "right"
  },
  selectedOverlay: {
    position: "absolute",
    width: "100%",
    height: "100%",
    border: "4px solid #000000",
    boxSizing: "border-box",
    backgroundColor: "rgba(45, 45, 45, 0.4)",
    zIndex: 1,
    borderRadius: "4px"
  },
  selectedOverlayText: {
    color: "white",
    fontFamily: `"Arial", sans-serif`,
    fontWeight: "bold",
    top: 0,
    right: 0,
    position: "absolute",
    display: "flex",
    alignItems: "center"
  },
  selectedIcon: {
    color: "white"
  },
  noResult: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    opacity: 0.3,
    width: "100%"
  },
  searchBarParent: {
    position: "relative"
  },
  searchBar: {
    zIndex: 100,
    paddingTop: theme.spacing(2),
    backgroundColor: "white",
    left: theme.spacing(3),
    right: theme.spacing(3),
    marginTop: `-${theme.spacing(3)}px`
  },
  hiddenSearchBarAsPadding: {
    visibility: "hidden",
    marginBottom: theme.spacing(2)
  }
});
class AssetsDialog extends React.PureComponent {
  state = {
    loading: false,
    clickedImage: null
  };

  handleClickedImage = assetId => {
    this.setState({
      clickedImage: assetId
    });
  };

  handleMenuClosed = () => {
    this.setState({ clickedImage: null });
  };

  handlePickAsset = (assetId, size, asset) => {
    if (this.props.onAssetPick) this.props.onAssetPick(assetId, size, asset);
  };

  handleChangePage = (event, page) => {
    if (event === null) return;
    this.props.onPageChange(page);
  };

  handleChangeItemsPerPage = event => {
    this.props.onItemsPerPageChange(event.target.value);
    this.props.onPageChange(0);
  };

  getLabelDisplayedRows = ({ from, to, count }) =>
    count > 0 ? `${from}-${to} van ${count}` : "";

  render() {
    const {
      classes,
      data,
      page = 0,
      pageSize = 12,
      pickedAssetId,
      pickedAssetSize,
      open,
      search,
      onSearch,
      assets = {},
      multi = false
    } = this.props;
    const { clickedImage } = this.state;
    const { loading } = data;
    const hasData =
      data && data.mediaList && data.mediaList.mediaList && !loading;

    return (
      <div className={classes.root}>
        {open && (
          <div>
            {!data || !data.mediaList || loading ? (
              <div>
                <CircularProgress className={classes.progress} />
              </div>
            ) : (
              ""
            )}

            {hasData && (
              <div>
                <div className={classes.searchBarParent}>
                  <div className={classes.searchBar}>
                    <AssetViewerSearchBar
                      initialValue={search}
                      onSubmit={onSearch}
                    />
                  </div>
                </div>

                <Grid container spacing={3}>
                  {hasData &&
                    data.mediaList.mediaList.map(asset => {
                      let thumbnails = asset.thumbnails;
                      let thumbnail = "";
                      if (Object.keys(thumbnails || {}).length > 0) {
                        thumbnail = thumbnails.thul
                          ? thumbnails.thul
                          : thumbnails[Object.keys(thumbnails)[0]];
                        if (
                          asset.id === pickedAssetId &&
                          thumbnails.hasOwnProperty(pickedAssetSize)
                        )
                          thumbnail = thumbnails[pickedAssetSize];
                      }

                      return (
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={4}
                          lg={3}
                          xl={2}
                          key={asset.id}
                        >
                          <div className={classes.tile}>
                            <div
                              style={{
                                backgroundImage: "url(" + thumbnail + ")"
                              }}
                              className={classes.assetImage}
                              onClick={this.handleClickedImage.bind(
                                this,
                                asset.id
                              )}
                            >
                              {multi &&
                                assets[asset.id] &&
                                assets[asset.id].derivative != "none" && (
                                  <div className={classes.selectedOverlay}>
                                    <span
                                      className={classes.selectedOverlayText}
                                    >
                                      {assets[asset.id].derivative
                                        ? assets[
                                            asset.id
                                          ].derivative.toUpperCase()
                                        : ""}

                                      <IconButton>
                                        <SelectedIcon
                                          className={classes.selectedIcon}
                                        />
                                      </IconButton>
                                    </span>
                                  </div>
                                )}

                              {!multi && asset.id === pickedAssetId ? (
                                <div className={classes.selectedOverlay}>
                                  <span className={classes.selectedOverlayText}>
                                    {pickedAssetSize
                                      ? pickedAssetSize.toUpperCase()
                                      : ""}

                                    <IconButton>
                                      <SelectedIcon
                                        className={classes.selectedIcon}
                                      />
                                    </IconButton>
                                  </span>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>

                            <AssetsViewerItemBar
                              key={asset.id}
                              id={asset.id}
                              asset={asset}
                              menuOpen={clickedImage === asset.id}
                              menuClosed={this.handleMenuClosed}
                              onSizePick={size =>
                                this.handlePickAsset(asset.id, size, asset)
                              }
                            />
                          </div>
                        </Grid>
                      );
                    })}

                  {hasData && data.mediaList.mediaList.length === 0 && (
                    <Typography
                      className={classes.noResult}
                      align="center"
                      variant="subtitle1"
                    >
                      Geen resultaten gevonden
                    </Typography>
                  )}
                </Grid>
                <TablePagination
                  component="div"
                  count={data.mediaList.count}
                  rowsPerPage={pageSize}
                  page={page}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeItemsPerPage}
                  labelDisplayedRows={this.getLabelDisplayedRows}
                  rowsPerPageOptions={[12, 24, 48, 96]}
                  labelRowsPerPage={"Items per pagina"}
                />
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default compose(
  graphql(GQL_FETCH_CMS_ASSETS, {
    options: props => ({
      variables: {
        page: props.page || 0,
        pagesize: props.pageSize || 12,
        ...(props.customerName && { customerName: props.customerName || "" }),
        ...(props.projectName && { projectName: props.projectName || "" }),
        search: props.search || "",
        type: "image"
      }
    })
  }),
  withStyles(styles)
)(AssetsDialog);
