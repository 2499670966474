import React from 'react';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import { TextField } from '@manakin/core';
import { withStyles } from '@material-ui/core/styles';
import { withForm } from '@manakin/core';

const styles = theme => ({
    root: {},
    submit: {
        marginTop: theme.spacing(2)
    }
});

const form = {
    email: {
        format: 'email',
        required: true
    }
};

class ResetPasswordForm extends React.Component {
    render() {
        const { classes, form } = this.props;
        const { onSubmit } = form;

        return (
            <div className={classes.root}>
                <TextField
                    name="email"
                    label="E-mailadres"
                    type="email"
                    form={form}
                    shrink={false}
                    hideAsterisk
                    onEnterPressed={onSubmit}
                />
                <FormControl margin="normal">
                    <Button
                        className={classes.submit}
                        color="primary"
                        variant="contained"
                        onClick={onSubmit}
                    >
                        Reset wachtwoord
                    </Button>
                </FormControl>
            </div>
        );
    }
}

export default withForm(form)(
    withStyles(styles, { name: 'AuthenticationResetPasswordForm' })(
        ResetPasswordForm
    )
);
