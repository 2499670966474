'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _core = require('@manakin/core');

var _styles = require('@material-ui/core/styles');

var _recompose = require('recompose');

var _appCore = require('@manakin/app-core');

var _OpenWith = require('@material-ui/icons/OpenWith');

var _OpenWith2 = _interopRequireDefault(_OpenWith);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var styles = function styles(theme) {
	return {
		root: _defineProperty({
			padding: '1rem 1rem 0',
			width: '100%',
			border: '1px dashed black',
			display: 'flex',
			alignItems: 'center',
			marginBottom: '5rem'
		}, theme.breakpoints.up('md'), {
			padding: '2rem 2rem 0'
		}),
		boxes: {
			width: '100%'
		},
		children: _defineProperty({
			padding: '0 0.1rem 0 1rem'
		}, theme.breakpoints.up('md'), {
			padding: '0 0.1rem 0 2rem'
		}),
		move: {
			cursor: 'move'
		}
	};
};

var MultiBoxCard = function (_React$PureComponent) {
	_inherits(MultiBoxCard, _React$PureComponent);

	function MultiBoxCard() {
		var _ref;

		var _temp, _this, _ret;

		_classCallCheck(this, MultiBoxCard);

		for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
			args[_key] = arguments[_key];
		}

		return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = MultiBoxCard.__proto__ || Object.getPrototypeOf(MultiBoxCard)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
			data: []
		}, _this.swapItems = function (_array, a, b) {
			var array = [].concat(_toConsumableArray(_array));
			var temp = array[a];

			array[a] = array[b];
			array[b] = temp;
			return array;
		}, _this.moveCard = function (dragIndex, hoverIndex) {
			var _this$props$disable = _this.props.disable,
			    disable = _this$props$disable === undefined ? false : _this$props$disable;

			if (disable) return;
			var data = _this.state.data;


			if (data.length) {
				var _data = _this.swapItems(data, dragIndex, hoverIndex);

				_this.setState({
					data: _data
				});
			}
		}, _temp), _possibleConstructorReturn(_this, _ret);
	}

	_createClass(MultiBoxCard, [{
		key: 'componentDidMount',
		value: function componentDidMount() {
			var data = this.props.data;

			this.setState(_defineProperty({ data: data }, 'data', data));
		}
	}, {
		key: 'render',
		value: function render() {
			var _this2 = this;

			var _props = this.props,
			    children = _props.children,
			    classes = _props.classes;
			var data = this.state.data;


			return _react2.default.createElement(
				'div',
				{ className: classes.root },
				_react2.default.createElement(
					'div',
					{ className: classes.boxes },
					_react2.default.createElement(
						_core.DragContainer,
						null,
						data && data.map(function (item, i) {
							return _react2.default.createElement(
								_core.DragCard,
								{ moveCard: _this2.moveCard, index: i, id: 'sub-' + item.id, key: 'sub-' + item.id },
								function (connectDragSource, connectDragPreview, connectDropTarget, isDragging) {
									return connectDragPreview(connectDropTarget(_react2.default.createElement(
										'div',
										null,
										_react2.default.createElement(
											_appCore.SingleBoxCard,
											{ data: item, variant: 'small' },
											connectDragSource(_react2.default.createElement(
												'div',
												{ className: classes.move },
												_react2.default.createElement(_OpenWith2.default, null)
											))
										)
									)));
								}
							);
						})
					)
				),
				_react2.default.createElement(
					'div',
					{ className: classes.children },
					children
				)
			);
		}
	}]);

	return MultiBoxCard;
}(_react2.default.PureComponent);

exports.default = (0, _recompose.compose)((0, _styles.withStyles)(styles))(MultiBoxCard);